import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const TitleComp = ({ title, id, fatherId, ean }) => {
	const theme = useTheme();
	return (
		<StyledTableCell>
			<Link to={`/wishlist/list/details/${id}/${fatherId}/${ean}`} style={{ textDecoration: 'none' }}>
				<div style={{ maxWidth: 200, color: theme.palette.primary.main }}>{utils.capitalize(title)}</div>
			</Link>
		</StyledTableCell>
	);
};

const Title = memo(
	({ item }) => <TitleComp title={item.title} ean={item.ean} fatherId={item.fatherId} id={item.id} />,
	(p, n) => p.item.title === n.item.title && p.item.ean === n.item.ean
);

export default Title;
