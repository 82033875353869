import k from 'k';
import utils from 'services/utils';

export default ({ file, id }) => {
	const requestOptions = {
		method: 'POST',
		body: file,
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/user-groups/${id}/users/import`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
