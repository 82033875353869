import k from 'k';
import utils from 'services/utils';

export default keyword => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = {
		keyword,
		strict: false
	};
	if (keyword) params.keyword = keyword;
	const qParams = utils.createQueryParams(params, false);
	return utils
		.fetch(`${k.endpoints.apis}/users/retrieve?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
