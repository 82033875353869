import React, { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import GlobalPreload from 'components/Preload';
import Restock from 'components/Restock';
import utils from 'components/utils';

const PreloadComp = ({ classes, depots, width }) => {
	const mainDepot = utils.useUserData('mainDepot', null);
	const availableDepots = utils.useUserData('availableDepots', []);
	if (!depots) depots = [];
	const [firstDepot] = utils.extractDepots({
		mainDepot,
		bookDepots: depots,
		userDepots: availableDepots
	});
	const w = {
		width,
		maxWidth: width,
		minWidth: width
	};
	return (
		<TableCell style={w}>
			<Grid container spacing={0}>
				{firstDepot && firstDepot.futureDisp !== 0 && (
					<Grid item xs={6} style={{ alignSelf: 'center' }}>
						<Restock value={firstDepot.futureDisp} />
					</Grid>
				)}
				{firstDepot && firstDepot.preload !== 0 && (
					<Grid item xs={6} style={{ alignSelf: 'center' }}>
						<GlobalPreload value={firstDepot.preload} />
					</Grid>
				)}
			</Grid>
		</TableCell>
	);
};

const Preload = memo(
	({ item, width }) => <PreloadComp depots={item.depots} width={width} />,
	(p, n) => p.item.depots === n.item.depots
);

export default Preload;
