import React from 'react';
import Sortable from './Sortable';
import Empty from './Empty';
import Selection from './Selection';

const allCols = [
	{
		id: 'selection',
		comp: _ => <Selection key="headCell_selection_ranking" />
	},
	{
		id: 'id',
		comp: _ => <Sortable id="id" key="headCell_id_ranking" label="rankings.tables.id" />
	},
	{
		id: 'sapName',
		comp: _ => <Sortable id="nameOrder" key="headCell_sapName_ranking" label="rankings.tables.name" />
	},
	{
		id: 'status',
		comp: _ => <Sortable id="enabled" key="headCell_status_ranking" label="rankings.tables.status" />
	},
	{
		id: 'startDate',
		comp: _ => <Sortable id="startDate" key="headCell_startDate_ranking" label="rankings.tables.startDate" />
	},
	{
		id: 'endDate',
		comp: _ => <Sortable id="endDate" key="headCell_endDate_ranking" label="rankings.tables.endDate" />
	},
	{
		id: 'edit',
		comp: _ => <Empty id="edit" key="headCell_edit_ranking" label="rankings.tables.edit" />
	}
];

export default allCols;
