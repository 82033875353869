import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import AutocompleteMUI from '@mui/lab/Autocomplete';
import { CircularProgress, Box } from '@mui/material';
import TextFieldMUI from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';

const styles = theme => ({
	autocompleteInput: {
		'&:first-child': {
			paddingTop: 4
		}
	},
	autocompleteLoader: {
		padding: theme.spacing(1, 1, 0, 1)
	}
});

const Autocomplete = props => {
	const { classes, path } = props;
	const disabled = get(props, 'disabled', false);
	const translate = utils.useI18N();
	const open = useStoreState(state => state.catalog.autocomplete.open);
	const loading = useStoreState(state => state.catalog.autocomplete.loading);
	const realm = useStoreState(state => state.catalog.autocomplete.realm);
	const filter = useStoreState(state => state.catalog.filters.list[path]);
	const list = useStoreState(state => state.catalog.autocomplete.list);
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const setRealm = useStoreActions(dispatch => dispatch.catalog.autocomplete.setRealm);
	const setOpen = useStoreActions(dispatch => dispatch.catalog.autocomplete.setOpen);
	const label = translate(`catalog.filters.${path}`);
	const testId = `${path}.field`;
	const load = useStoreActions(dispatch => dispatch.catalog.autocomplete.load);
	const [mustLoadAC, setMustLoadAC] = useState(false);
	const debouncedLoad = useCallback(
		debounce(_ => {
			load();
		}, 400),
		[]
	);
	useEffect(
		_ => {
			if (mustLoadAC) {
				debouncedLoad();
			}
		},
		[filter, debouncedLoad, mustLoadAC]
	);
	useEffect(
		_ => {
			if (realm === path && list && list.length > 0) setOpen(path);
		},
		[list, setOpen, path, realm]
	);
	const onInputChange = (event, value, reason) => {
		if (reason === 'reset') return; // when opening adv filters this gets called, dunno why
		if (realm !== path) setRealm(path);
		if (!mustLoadAC) setMustLoadAC(true);
		setFilters({
			[path]: value
		});
	};
	const onChange = (event, value, reason) => {
		setMustLoadAC(false);
		setFilters({
			[path]: value
		});
		setOpen(null);
	};
	const onClose = (event, reason) => {
		if (reason === 'blur') setOpen(null);
	};
	const showLoader = loading && realm === path;
	return (
		<AutocompleteMUI
			id={`${path}-autocomplete`}
			sx={{ width: '100%' }}
			freeSolo
			disableClearable
			disabled={disabled}
			open={open === path}
			inputValue={filter}
			options={list}
			loading={showLoader}
			onChange={onChange}
			onInputChange={onInputChange}
			onClose={onClose}
			renderInput={params => (
				<TextFieldMUI
					{...params}
					label={label}
					name={path}
					InputProps={{
						...params.InputProps,
						'data-testid': testId,
						endAdornment: (
							<Fragment>
								{showLoader ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</Fragment>
						)
					}}
				/>
			)}
			renderOption={(props, option) => (
				<Box component="li" {...props}>
					{option}
				</Box>
			)}
		/>
	);
};

export default withStyles(styles, { withTheme: true })(Autocomplete);
