import { useEffect } from 'react';
import moment from 'moment';

const useUpdatedData = (updateUserFn, updateDateFn, data) => {
	useEffect(
		_ => {
			if (!data || (!data.lastImport && !data.lastInsert)) return;
			if (data.lastImport && data.lastImport.lastImportDate && !data.lastInsert.lastInsertDate) {
				updateDateFn(moment(data.lastImport.lastImportDate).format('DD-MM-YYYY HH:mm:ss'));
				updateUserFn(data.lastImport.userCode);
			}
			if (!data.lastImport && data.lastInsert && data.lastInsert.lastInsertDate) {
				updateDateFn(moment(data.lastInsert.lastInsertDate).format('DD-MM-YYYY HH:mm:ss'));
				updateUserFn(data.lastInsert.userCode);
			}
			if (
				data.lastImport &&
				data.lastImport.lastImportDate &&
				data.lastInsert &&
				data.lastInsert.lastInsertDate
			) {
				const firstDate = moment(data.lastImport.lastImportDate).format('DD-MM-YYYY HH:mm:ss');
				const secondDate = moment(data.lastInsert.lastInsertDate).format('DD-MM-YYYY HH:mm:ss');
				const validDate = new Date(firstDate) > new Date(secondDate) ? firstDate : secondDate;
				const validUser =
					new Date(firstDate) > new Date(secondDate) ? data.lastImport.userCode : data.lastInsert.userCode;
				updateDateFn(validDate);
				updateUserFn(validUser);
			}
		},
		[updateUserFn, updateDateFn, data]
	);
};

export default useUpdatedData;
