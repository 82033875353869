import { action, thunk } from 'easy-peasy';

export default {
	healthInfo: [],
	save: action((state, payload) => {
		state.healthInfo = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	loadHealtInfo: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.health
			.healthInfo(payload)
			.then(data => {
				actions.save(data.healthinfo);
			})
			.catch(e => {
				//getStoreActions().error('health.errorHealthinfo');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),

	allHealthInfo: [],
	saveAll: action((state, payload) => {
		state.allHealthInfo = payload;
	}),
	loadingAll: false,
	setLoadingAll: action((state, payload) => {
		state.loadingAll = payload;
	}),
	loadAllHealthInfo: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingAll(true);
		services.health
			.allHealthInfo(payload)
			.then(data => {
				actions.saveAll(data.healthinfo);
			})
			.catch(e => {
				getStoreActions().error('health.errorAllHealthinfo');
			})
			.finally(_ => {
				actions.setLoadingAll(false);
			});
	}),

	loadingCreate: false,
	setLoadingCreate: action((state, payload) => {
		state.loadingCreate = payload;
	}),
	createNewStatus: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingCreate(true);
		return services.health
			.createStatus(payload)
			.then(_ => {
				getStoreActions().success('health.createStatus');
				actions.loadAllHealthInfo();
			})
			.catch(_ => {
				getStoreActions().error('health.errorCreateStatus');
			})
			.finally(_ => {
				actions.setLoadingCreate(false);
			});
	}),

	loadingDelete: false,
	setLoadingDelete: action((state, payload) => {
		state.loadingDelete = payload;
	}),
	deleteStatus: thunk((actions, payload, { getStoreActions, getStoreState, injections }) => {
		const { services } = injections;
		actions.setLoadingDelete(true);
		return services.health
			.deleteStatus(payload)
			.then(_ => {
				getStoreActions().success('health.deleteStatus');
				actions.loadAllHealthInfo();
			})
			.catch(_ => {
				getStoreActions().error('health.errorDeleteStatus');
			})
			.finally(_ => {
				actions.setLoadingDelete(false);
			});
	})
};
