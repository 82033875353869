import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import SearchIcon from '@mui/icons-material/Search';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import styles from './styles';
import InputBase from '@mui/material/InputBase/InputBase';

const SearchBar = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const token = useStoreState(state => state.purchasings.assignUsers.token);
	const setToken = useStoreActions(dispatch => dispatch.purchasings.assignUsers.setToken);

	return (
		<div className={classes.search}>
			<div className={classes.searchIcon}>
				<SearchIcon />
			</div>
			<InputBase
				placeholder={translate('common.search.search')}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput
				}}
				value={token}
				onChange={e => setToken(e.target.value)}
				inputProps={{ 'aria-label': 'search', 'data-testid': 'promo.search' }}
			/>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(SearchBar);
