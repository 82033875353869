import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Pagination from 'components/Pagination';

const DetailItemsAssignPagination = props => {
	const currentPage = useStoreState(state => state.proposals.detailItemsAssign.pagination.current);
	const step = useStoreState(state => state.proposals.detailItemsAssign.pagination.step);
	const pageCount = useStoreState(state => state.proposals.detailItemsAssign.pagination.pageCount);
	const totalItems = useStoreState(state => state.proposals.detailItemsAssign.pagination.totalItems);
	const setCurrentPage = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.setPaginationCurrent);
	const setStep = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.setPaginationStep);

	return (
		<Pagination
			currentPage={currentPage}
			step={step}
			pageCount={pageCount}
			setCurrentPage={setCurrentPage}
			setStep={setStep}
			totalItems={totalItems}
		/>
	);
};

export default DetailItemsAssignPagination;
