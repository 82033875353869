export default _ => {
	return Promise.resolve([
		{
			title: 'adelphi-borse',
			image: require('./mockedImages/adelphi-borse.jpg'),
			url:
				'https://docs.google.com/forms/d/e/1FAIpQLSce8x5AYn4iOi5KCjb3CpGdC5Dj_KzJQcq_KK_z5-jqzv_QQQ/viewform?usp=sf_link'
		},
		{
			title: 'Blackie',
			image: require('./mockedImages/Blackie.jpg'),
			url:
				'https://docs.google.com/forms/d/e/1FAIpQLScN-JrrE7X-TPN_he8cDxwiC_A-pc1oETcTCyjV_rKC0G5PUg/viewform?usp=sf_link'
		},
		{
			title: 'einaudi-zaino',
			image: require('./mockedImages/einaudi-zaino.jpg'),
			url:
				'https://docs.google.com/forms/d/e/1FAIpQLSdOUGdig3mF7BqF89ehA6gPdAotexecL-h_Q2Fv3FaN24D_qA/viewform?usp=sf_link'
		},
		{
			title: 'EL-storie-illustrate-5',
			image: require('./mockedImages/EL-storie-illustrate-5.jpg'),
			url:
				'https://docs.google.com/forms/d/e/1FAIpQLSfAJZfnt5unt1R16DhCASDSQbGgarej5fJc4YH290WMfR38PQ/viewform?usp=sf_link'
		},
		{
			title: 'garzanti-elefanti',
			image: require('./mockedImages/garzanti-elefanti.jpg'),
			url:
				'https://docs.google.com/forms/d/e/1FAIpQLSfZKtpPmuPHZNvMyxI475GBEw_iEfBLfyxRTFWKcZlkUNQJXQ/viewform?usp=sf_link'
		},
		{
			title: 'primo-volo',
			image: require('./mockedImages/primo-volo.jpg'),
			url:
				'https://docs.google.com/forms/d/e/1FAIpQLSfRea6KPQcT0LQFUeXFY64ASEPCG0E6T_vVEExkcqiVULbXLg/viewform?usp=sf_link'
		},
		{
			title: 'salani-dama',
			image: require('./mockedImages/salani-dama.jpg'),
			url:
				'https://docs.google.com/forms/d/e/1FAIpQLScRgGiORI8apCsmN5fQvts_Qbg7Xa7R-xPOZQnOcVwWQlU23A/viewform?usp=sf_link'
		}
	]);
};
