import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const NetComp = ({ text, isAdded }) => {
	return <StyledTableCell>{isAdded ? '' : `${text}€`}</StyledTableCell>;
};

const Net = memo(
	({ item }) => <NetComp text={item.netprui} isAdded={item.isAdded} />,
	(p, n) => p.item.netprui === n.item.netprui && p.item.isAdded === n.item.isAdded
);

export default Net;
