export default theme => ({
	text: {
		borderRadius: 8,
		backgroundColor: 'rgb(255 255 255 / 15%)',
		border: '1px solid rgb(255 255 255 / 15%)',
		height: 45,
		width: 75,
		marginTop: 10,
		paddingTop: 10,
		textAlign: 'center'
		// [theme.breakpoints.down('xs')]: {
		// 	backgroundColor: '#00529b',
		// 	color: '#fff',
		// 	textAlign: 'center',
		// 	margin: '4px 4px 0 4px',
		// 	borderRadius: 0
		// }
	}
});
