import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';

const ListItemLink = props => {
	let match = false;
	const location = useLocation();
	const wishlistModal = useStoreState(state => state.wishlist.details.openModal);
	const setWishlistModal = useStoreActions(dispatch => dispatch.wishlist.details.setOpenModal);
	const catalogModal = useStoreState(state => state.catalog.details.modalOpen);
	const setCatalogModal = useStoreActions(dispatch => dispatch.catalog.details.setModalOpen);
	const bestSellersModal = useStoreState(state => state.cms.bestSellers.modalOpen);
	const setBestSellersModal = useStoreActions(dispatch => dispatch.cms.bestSellers.setModalOpen);
	const ordersModal = useStoreState(state => state.order.create.openModal);
	const setOrdersModal = useStoreActions(dispatch => dispatch.order.create.setOpenModal);
	const [, p1, p2] = location.pathname.split('/');

	if (p1 !== 'admin') {
		match = p1 === props.to.split('/')[1];
	} else {
		match = p2 === props.to.split('/')[2];
	}
	const backgroundColor = match ? 'rgb(236 236 236)' : '#ffffff';
	const onClick = () => {
		if (wishlistModal === true) {
			setWishlistModal(false);
		}
		if (catalogModal === true) {
			setCatalogModal(false);
		}
		if (bestSellersModal === true) {
			setBestSellersModal(false);
		}
		if (ordersModal === true) {
			setOrdersModal(false);
		}
	};
	return <ListItem button style={{ backgroundColor }} onClick={onClick} component={Link} {...props} />;
};

export default ListItemLink;
