import React, { Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { Box, Typography, Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import styles from './styles';
import WishlistInfo from '../CommonSummary/WishlistInfo';
import WishlistSumCost from '../CommonSummary/WishlistSumCost';
import WishlistResume from './components/WishlistResume';
import Pagination from './components/Pagination';
import utils from 'components/utils';
import Alert from '@mui/lab/Alert';
import CustomNoResults from '../CustomNoResults';

const Accepted = ({ classes }) => {
	const translate = utils.useI18N();
	const items = useStoreState(state => state.conditionRequest.refusedList.items);
	const loading = useStoreState(state => state.conditionRequest.refusedList.loading);
	const expanded = useStoreState(state => state.conditionRequest.refusedList.expanded);
	const setExpanded = useStoreActions(dispatch => dispatch.conditionRequest.refusedList.setExpanded);
	const load = useStoreActions(dispatch => dispatch.conditionRequest.refusedList.load);
	const handleChange = index => (event, isExpanded) => {
		setExpanded(isExpanded ? index : -1);
	};
	// prettier-ignore
	useEffect(_ => { load({ reset: true }) }, [load]);
	return (
		<Fragment>
			{!loading && items.length === 0 && (
				<Grid item container justify="center" xs={12}>
					<CustomNoResults />
				</Grid>
			)}
			{!loading && items.length > 0 && (
				<Fragment>
					<Box sx={{ margin: '10px 0 !important', width: '100%' }}>
						<Pagination />
					</Box>

					{items.map((item, index) => (
						<Accordion
							key={index}
							expanded={expanded === index}
							onChange={handleChange(index)}
							sx={{ marginBottom: '20px', width: '100%' }}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel-header">
								<Box display="flex" justifyContent="space-between" flex={1}>
									<WishlistInfo item={item} />
									<WishlistSumCost item={item} />
								</Box>
							</AccordionSummary>
							<AccordionDetails>
								{expanded === index && (
									<Grid container item xs={12} spacing={1}>
										<Grid
											item
											container
											xs={12}
											sx={{ display: { xs: 'none', md: 'inherit', sm: 'none' } }}
										>
											<Grid item container xs={6}>
												<Typography variant="body2" style={{ alignSelf: 'center' }}>
													<strong>{translate('wishlist.summary.name')}</strong>:&nbsp;
													{utils.capitalizeAll(item.wishlistName)}
												</Typography>
											</Grid>
											<Grid item container justify="flex-end" xs={6}>
												<Typography variant="body2" style={{ alignSelf: 'center' }}>
													<strong>{translate('wishlist.summary.condition')}</strong>
													:&nbsp;
													{utils.capitalizeAll(item.promotionName)}
												</Typography>
											</Grid>
										</Grid>
										{item.responsableNote && (
											<Grid item container xs={12}>
												<Alert
													elevation={3}
													severity="info"
													variant="standard"
													style={{ marginBottom: 8 }}
												>
													<Typography variant="caption">{item.responsableNote}</Typography>
												</Alert>
											</Grid>
										)}
										<Grid item xs={12} style={{ width: '80vw' }}>
											<WishlistResume id={item.wishlistId} />
										</Grid>
									</Grid>
								)}
							</AccordionDetails>
						</Accordion>
					))}
				</Fragment>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Accepted);
