import React, { Fragment } from 'react';
import { useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useHistory, Link } from 'react-router-dom';
import styles from './styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Nazionale from './images/nazionale.png';
import NazionaleDisabled from './images/nazionale_disabled.png';
import Regionale from './images/regionale.png';
import RegionaleDisabled from './images/regionale_disabled.png';
import Provinciale from './images/provinciale.png';
import ProvincialeDisabled from './images/provinciale_disabled.png';
import NazionalePro from './images/nazionalePro.png';
import NazionaleProDisabled from './images/nazionalePro_disabled.png';
import RegionalePro from './images/regionalePro.png';
import RegionaleProDisabled from './images/regionalePro_disabled.png';
import ProvincialePro from './images/provincialePro.png';
import ProvincialeProDisabled from './images/provincialePro_disabled.png';

const Badge = ({ classes, item, size }) => {
	const search = useStoreActions(dispatch => dispatch.catalog.search.performSearch);
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const resetFilters = useStoreActions(dispatch => dispatch.catalog.filters.reset);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const setCatalogOneModalOpen = useStoreActions(dispatch => dispatch.catalog.details.setModalOpen);
	const setOrder = useStoreActions(dispatch => dispatch.catalog.order.setOrder);
	const history = useHistory();
	const srcActive = item.active ? '' : 'Disabled';
	const srcImages = item.image + srcActive;
	const textProReg = item.region ? ' (' + item.region + ')' : item.province ? ' (' + item.province + ')' : '';
	const textPosition = item.position !== -1 ? ' #' + item.position : '';
	const textTooltip = item.ranking + textProReg + textPosition;

	const searchBadge = idRanking => {
		resetFilters();
		setFilters({ ranking: idRanking, badgeRanking: true, badgeRankingName: item.ranking + textProReg });
		setOrder('ranking');
		search();
		setStep(25);
		setCatalogOneModalOpen(false);
		history.push(`/catalog/search/`);
	};

	const components = {
		nazionale: Nazionale,
		nazionaleDisabled: NazionaleDisabled,
		regionale: Regionale,
		regionaleDisabled: RegionaleDisabled,
		provinciale: Provinciale,
		provincialeDisabled: ProvincialeDisabled,
		nazionalePro: NazionalePro,
		nazionaleProDisabled: NazionaleProDisabled,
		regionalePro: RegionalePro,
		regionaleProDisabled: RegionaleProDisabled,
		provincialePro: ProvincialePro,
		provincialeProDisabled: ProvincialeProDisabled
	};

	const icoStyle = {
		nazionale: '#91A410',
		nazionaleDisabled: '#ccc',
		regionale: '#46A6B6',
		regionaleDisabled: '#ccc',
		provinciale: '#CA852A',
		provincialeDisabled: '#ccc',
		nazionalePro: '#F34F32',
		nazionaleProDisabled: '#ccc',
		regionalePro: '#D3C932',
		regionaleProDisabled: '#ccc',
		provincialePro: '#BF8FB3',
		provincialeProDisabled: '#ccc'
	};

	return (
		<Fragment>
			<Tooltip title={textTooltip}>
				<IconButton
					className={classes.wrapperBadge}
					component={Link}
					to={'/catalog/search'}
					onClick={_ => {
						searchBadge(item.id);
					}}
				>
					<img
						src={components[srcImages]}
						className={classes.icoImage}
						width={size}
						//style={{ backgroundColor: icoStyle[srcImages] }}
						alt={textTooltip}
					/>
				</IconButton>
			</Tooltip>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Badge);
