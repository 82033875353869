import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import moment from 'moment';

const EndDateComp = ({ endDate }) => {
	return <StyledTableCell>{moment(endDate).format('L HH:mm')}</StyledTableCell>;
};

const EndDate = memo(
	({ item }) => <EndDateComp endDate={item.endDate} />,
	(p, n) => p.item.endDate === n.item.endDate
);

export default EndDate;
