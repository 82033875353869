import { action, thunk, thunkOn } from 'easy-peasy';

export default {
	loading: -1,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendRequest: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		actions.setLoading(payload);
		return services.cart
			.updateFavouriteCart(payload)
			.then(data => {
				//			getStoreActions().success('cart.setFavourite');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('cart.setFavourite');
			})
			.finally(_ => {
				actions.setLoading(-1);
			});
	}),
	item: null,
	save: action((state, payload) => {
		state.item = payload;
	}),
	loadFavCart: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.cart
			.getFavourite()
			.then(data => {
				actions.save(data);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('order.cartList');
			});
	}),
	retrieve: thunkOn(
		(actions, storeActions) => [
			storeActions.catalog.search.performSearch.successType,
			actions.sendRequest.successType
		],
		async (actions, target, helpers) => {
			actions.loadFavCart();
		}
	)
};
