import React from 'react';
import TableCell from '@mui/material/TableCell';
import Preload from 'components/Preload';
import Restock from 'components/Restock';

const Restocking = props => {
	const { depot } = props;
	return (
		<TableCell align="left">
			{depot.futureDisp !== 0 && <Restock value={depot.futureDisp} />}
			{depot.preload !== 0 && <Preload value={depot.preload} />}
		</TableCell>
	);
};

export default Restocking;
