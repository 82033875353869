import React, { useEffect, useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Stack,
	Switch,
	IconButton,
	Popover,
	FormControl,
	FormGroup,
	FormControlLabel,
	Typography,
	Box,
	Button
} from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';

const FilterProfile = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const useType = utils.useType();
	const profiles = useStoreState(state => state.proposals.filters.list.profiles);
	const setProfiles = useStoreActions(dispatch => dispatch.proposals.filters.setProfiles);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const profileList = [
		{ id: 'personale', label: 'personal', value: 'PERSONAL' },
		{ id: 'sede', label: 'sede', value: 'SEDE' },
		{ id: 'agente', label: 'agente', value: 'AGENT' }
	];

	const getOwnerLabel = profile => {
		const label = profileList.filter(p => p.id === profile);
		if (label.length > 0) return label[0].label;
		else return '';
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = event => {
		if (event.target.checked) setProfiles([...profiles, event.target.name]);
		else setProfiles(profiles.filter(p => p != event.target.name));
	};

	return (
		<Fragment>
			<IconButton onClick={handleClick}>
				<PersonSearchIcon sx={{ fontSize: '28px' }} />
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
			>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					sx={{
						width: '100%',
						maxWidth: { xs: '340px', md: '500px', sm: '500px' },
						minWidth: '340px',
						padding: '10px'
					}}
				>
					<Stack
						direction="column"
						justifyContent="flex-start"
						alignItems="flex-start"
						sx={{ width: '100%' }}
					>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={2}
							sx={{ width: '100%' }}
						>
							<Box sx={{ width: '90%' }}>
								<Typography variant="titleSection" sx={{ color: theme.palette.primary.main }}>
									{translate('proposals.createdBy')}
								</Typography>
							</Box>
							<Box sx={{ width: '10%' }}>
								<IconButton onClick={handleClose}>
									<CloseIcon />
								</IconButton>
							</Box>
						</Stack>
					</Stack>
					<FormControl
						component="fieldset"
						variant="standard"
						sx={{
							width: '100%',
							padding: '10px 5px 5px 5px',
							borderWidth: '1px 0 0 0',
							borderStyle: 'solid',
							borderColor: theme.palette.primary.main
						}}
					>
						<FormGroup>
							{profileList.map(p => (
								<FormControlLabel
									key={p.id}
									label={
										<Typography variant="text" sx={{ textTransform: 'capitalize' }}>
											{translate('proposals.' + getOwnerLabel(p.id))}
										</Typography>
									}
									control={
										<Switch
											name={p.value}
											checked={profiles.includes(p.value)}
											onChange={handleSelect}
										/>
									}
								/>
							))}
						</FormGroup>
					</FormControl>
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<Button variant="light" onClick={handleClose}>
							{translate('common.close')}
						</Button>
					</Box>
				</Stack>
			</Popover>
		</Fragment>
	);
};

export default FilterProfile;
