import React from 'react';
import Ean from './Ean';
import Title from './Title';
import Delete from './Delete';

const allCells = [
	{
		id: 'ean',
		comp: ({ item }) => <Ean key={`bodyCell_ean_${item.id}`} item={item} />
	},
	{
		id: 'title',
		comp: ({ item }) => <Title key={`bodyCell_title_${item.id}`} item={item} />
	},
	{
		id: 'delete',
		comp: ({ item }) => <Delete key={`bodyCell_delete_${item.id}`} item={item} />
	}
];

export default allCells;
