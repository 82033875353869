export default theme => ({
	notSticky: {
		margin: theme.spacing(0, 0, 2, 0),
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(2, 0)
		}
	},
	stickyMenuClose: {
		top: 63,
		left: 57,
		borderBottom: '1px solid #e0e0e0',
		zIndex: 1101,
		position: 'fixed',
		backgroundColor: '#fafafa',
		width: `calc(100% - 57px)`,
		padding: theme.spacing(1.5, 2.5, 1.5, 3),
		boxShadow: '0px 2px 9px -2px rgba(179,179,179,0.7)',
		[theme.breakpoints.down('sm')]: {
			top: 40,
			left: 0,
			padding: theme.spacing(1),
			width: '100%',
			textAlign: 'center'
		}
	},
	stickyMenuOpen: {
		top: 63,
		left: 200,
		borderBottom: '1px solid #e0e0e0',
		zIndex: 1101,
		position: 'fixed',
		backgroundColor: '#fafafa',
		width: `calc(100% - 200px)`,
		padding: theme.spacing(1.5, 2.5, 1.5, 3),
		boxShadow: '0px 2px 9px -2px rgba(179,179,179,0.7)',
		[theme.breakpoints.down('sm')]: {
			top: 40,
			padding: theme.spacing(1),
			textAlign: 'center'
		}
	}
});
