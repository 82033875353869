import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const SapNameComp = ({ name }) => {
	return <StyledTableCell>{name}</StyledTableCell>;
};

const SapName = memo(
	({ item }) => <SapNameComp name={item.name} />,
	(p, n) => p.item.name === n.item.name
);

export default SapName;
