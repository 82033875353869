import { alpha } from '@mui/material/styles';

export default theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(0, 3, 3, 3),
		minWidth: 350
	},
	modalLabels: {
		marginBottom: theme.spacing()
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	buttonsAlignment: {
		margin: theme.spacing(1, 0),
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center'
		}
	},
	elements: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	dividerAria: {
		margin: theme.spacing(2, 2)
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 1, 0, 0)
		}
	},
	search: {
		display: 'flex',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.black, 0.25)
		},
		width: '100%'
	},
	searchIcon: {
		padding: theme.spacing(1, 0, 0, 1),
		height: '100%',
		pointerEvents: 'none'
	},
	searchButton: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			marginLeft: theme.spacing(0)
		}
	},
	autocompleteInput: {
		'&:first-child': {
			paddingTop: 4
		}
	},
	noResults: {
		marginTop: `calc(1em + ${theme.spacing(4)}px)`,
		maxWidth: '400px'
	},
	cardMessageContent: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	gridSpacing: {
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 0, 0, 0)
		}
	}
});
