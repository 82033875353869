import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import TableContainerMUI from '@mui/material/TableContainer';
import TableMUI from '@mui/material/Table';
import { Stack, Grid, Typography, Divider, CircularProgress, Button, Paper } from '@mui/material';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import CartSelector from 'components/CartSelector';
import WishlistSelector from 'components/WishlistSelector';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody';
import AddIcon from '@mui/icons-material/Add';
import WishlistCreate from 'components/WishlistCreate';
import Tooltip from '@mui/material/Tooltip';
import CreateCart from 'routes/Orders7/CreateCart';
//import CreateCart from 'components/CreateCart';
import HeadlessCard from 'components/Card/HeadlessCard';
import Popover from 'components/Popover';
import ResetButton from '../ResetButton';
import styles from './styles';
import utils from 'components/utils';

const ImportComp = ({ classes, maxTableHeight }) => {
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const items = useStoreState(state => state.importt.items);
	const loadingCreateOrder = useStoreState(state => state.order.createMultiple.loading);
	const loadingAddToWishlist = useStoreState(state => state.wishlist.add.loading);
	const loadingWlLight = useStoreState(state => state.wishlist.listLight.loading);
	const loadingCartLight = useStoreState(state => state.cart.listLight.loading);
	const realUser = useStoreState(state => state.me.data);
	const anchorWlEl = useStoreState(state => state.selection.anchorWlEl);
	const anchorCartEl = useStoreState(state => state.selection.anchorCartEl);
	const createOrder = useStoreActions(dispatch => dispatch.order.createMultiple.sendRequest);
	const addToWishlistAction = useStoreActions(dispatch => dispatch.wishlist.add.sendRequest);
	const loadCartList = useStoreActions(dispatch => dispatch.cart.listLight.load);
	const stepBack = useStoreActions(dispatch => dispatch.importt.stepBack);
	const cartList = useStoreState(state => state.cart.listLight.items);
	const loadWlList = useStoreActions(dispatch => dispatch.wishlist.listLight.load);
	const setAnchorWlEl = useStoreActions(dispatch => dispatch.selection.setAnchorWlEl);
	const setAnchorCartEl = useStoreActions(dispatch => dispatch.selection.setAnchorCartEl);
	const wList = useStoreState(state => state.wishlist.listLight.items);
	const promoSearch = useStoreState(state => state.catalog.filters.promoSearch);
	const allFasthub = useStoreState(state => state.importt.allFasthub);
	const noType100 = useStoreState(state => state.importt.noType100);
	const noZ1 = useStoreState(state => state.importt.noZ1);
	const [wlName, setWlName] = useState('');
	const [wlNotes, setWlNotes] = useState('');
	useEffect(loadWlList, [loadWlList]);
	useEffect(loadCartList, [loadCartList]);
	const openCart = Boolean(anchorCartEl);
	const openWl = Boolean(anchorWlEl);
	const translate = utils.useI18N();
	const history = useHistory();
	const total = items.map(item => item.fullPrice * item.quantity).reduce((a, b) => a + b, 0);

	const onAddToCart = ({ fcd }) => {
		const cart = cartList.find(item => item.selected);
		const cartId = cart ? cart.id : null;
		const orders = items.map(item => {
			const selectedDepot = item.depots.find(itm => itm.id === item.selectedDepotCode);

			const order = {
				catalog: item.ean,
				depot: item.selectedDepotCode,
				cartId,
				quantity: item.quantity,
				promoCode: promoSearch && item.promo && item.promo.length > 0 ? item.promo[0].sapId : null,
				realUserCode: realUser.id,
				totalPrice: total,
				sla: null,
				shippingType: null,
				supplier: null
			};

			if (selectedDepot && selectedDepot.shippingTypes && selectedDepot.shippingTypes.length) {
				const sla = selectedDepot.shippingTypes[0]?.sla;
				const shippingType = selectedDepot.shippingTypes[0]?.code;
				const supplier = selectedDepot.shippingTypes[0]?.supplier;

				order.sla = sla;
				order.shippingType = shippingType;
				order.supplier = supplier;
			}

			return order;
		});
		const successMessage = translate('catalog.selection.addToSelectedCart') + ' ' + cart.name;
		createOrder({ orders, successMessage, history, redirect: '/orders/list', fcd });
	};
	const addToWl = _ => {
		const wishlistItem = wList.find(item => item.selected);
		const wlistId = wishlistItem ? wishlistItem.id : null;
		const orders = items.map(item => {
			const depotCode = item.selectedDepotCode;
			const depot = item.depots.find(d => d.id === depotCode);
			const depotDescription = depot.description;
			return {
				ean: item.ean,
				depotCode: depotCode,
				depotDescription: depotDescription,
				promotionSapId: promoSearch && item.promo && item.promo.length > 0 ? item.promo[0].sapId : null,
				quantity: item.quantity,
				shippingType: depot.shippingTypes[0]?.code
			};
		});
		const wishlist = wlistId;
		const successMessage = translate('catalog.selection.addToSelectedWishlist') + ' ' + wishlistItem.name;
		addToWishlistAction({ orders, wishlist, successMessage, history, redirect: '/wishlist/list' });
	};
	const resetOnWlClose = _ => {
		setWlNotes('');
		setWlName('');
	};
	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item container xs={6} justify="flex-start" style={{ marginTop: 16 }}>
					<ResetButton stepBack={stepBack} />
				</Grid>
				<Grid item container xs={12}>
					<TableContainerMUI component={Paper} sx={{ maxHeight: { xs: '400px', md: '800px', sm: '600px' } }}>
						<TableMUI size="small" stickyHeader aria-label="sticky table">
							<TableHead />
							<TableBody />
						</TableMUI>
					</TableContainerMUI>
				</Grid>
				<Grid item container xs={12} className={classes.elements} justify="flex-end">
					<Typography variant="text" component="div" sx={{ fontWeight: '600' }}>
						{`${translate('catalog.selection.totalSelection')}: ${utils.formatPrice(total)}`}
					</Typography>
					<Divider className={classes.dividerAria} />
				</Grid>
				{items.length > 0 && (
					<Grid item container spacing={1}>
						{/* CREATE CART COMPONENT */}
						<Grid item xs={6}>
							{(loadingCreateOrder || loadingCartLight) && <CircularProgress size={16} />}
							{!loadingCreateOrder && !loadingCartLight && (
								<Grid item container xs={12} spacing={1}>
									<Grid item>
										<Button
											variant="dark"
											onClick={_ => onAddToCart({ fcd: false })}
											endIcon={<AddShoppingCartIcon />}
										>
											carrello
										</Button>
									</Grid>
									{iCan('VIEW_FCD_BUTTON') && noType100 && noZ1 && allFasthub && (
										<Grid item>
											<Button
												variant="light"
												onClick={_ => onAddToCart({ fcd: true })}
												endIcon={<EyeIcon />}
											>
												conto visione
											</Button>
										</Grid>
									)}
									<Grid item style={{ paddingTop: 5 }}>
										<CartSelector />
									</Grid>
									<Grid item>
										<Tooltip title={translate('orders.createCart')} placement="top">
											<IconButton
												onClick={e => {
													setAnchorCartEl(e.currentTarget);
												}}
												sx={{ color: theme.palette.primary.main, marginTop: '-5px' }}
											>
												<AddCircleIcon sx={{ fontSize: '1.2em' }} />
											</IconButton>
										</Tooltip>
										<Popover
											open={openCart}
											anchorEl={anchorCartEl}
											onClose={_ => {
												setAnchorCartEl(null);
											}}
										>
											<CreateCart
												showSave={true}
												showClose={true}
												onClose={_ => {
													setAnchorCartEl(null);
												}}
											/>
										</Popover>
									</Grid>
								</Grid>
							)}
						</Grid>
						{/* CREATE WISHLIST COMPONENT */}
						<Grid item xs={6}>
							{(loadingAddToWishlist || loadingWlLight) && <CircularProgress size={16} />}
							{!loadingAddToWishlist && !loadingWlLight && (
								<Grid item container xs={12} spacing={1} justify="flex-end">
									<Grid item>
										<Button variant="dark" onClick={addToWl} endIcon={<FavoriteIcon />}>
											wishlist
										</Button>
									</Grid>
									<Grid item style={{ paddingTop: 5 }}>
										<WishlistSelector />
									</Grid>
									<Grid item>
										<Tooltip title={translate('wishlist.createWl')} placement="top">
											<IconButton
												onClick={e => {
													setAnchorWlEl(e.currentTarget);
												}}
												sx={{ color: theme.palette.primary.main, marginTop: '-5px' }}
											>
												<AddCircleIcon sx={{ fontSize: '1.2em' }} />
											</IconButton>
										</Tooltip>
									</Grid>
									<Popover
										open={openWl}
										anchorEl={anchorWlEl}
										onClose={_ => {
											setAnchorWlEl(null);
											resetOnWlClose();
										}}
									>
										<HeadlessCard
											style={{ marginTop: 0, maxWidth: 400 }}
											bodyStyle={{ paddingTop: '10px' }}
										>
											<WishlistCreate
												resetOnSave={resetOnWlClose}
												onClose={_ => {
													setAnchorWlEl(null);
													resetOnWlClose();
												}}
												onNoteChange={e => {
													setWlNotes(e.target.value);
												}}
												defaultNotesValue={wlNotes}
												defaultNameValue={wlName}
												onNameChange={e => {
													setWlName(e.target.value);
												}}
											/>
										</HeadlessCard>
									</Popover>
								</Grid>
							)}
						</Grid>
					</Grid>
				)}
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(ImportComp);
