import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Chip from '@mui/material/Chip';
import utils from 'components/utils';

const SubScopeChip = ({ filter }) => {
	const translate = utils.useI18N();
	const resetSingleFilter = useStoreActions(dispatch => dispatch.catalog.filters.resetSingle);
	const resetSingleNoTrigger = useStoreActions(dispatch => dispatch.catalog.filters.resetSingleNoTrigger);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const resetOrder = useStoreActions(dispatch => dispatch.catalog.order.reset);
	const scopes = useStoreState(state => state.catalog.scopes.list);
	const selectScope = useStoreActions(dispatch => dispatch.catalog.scopes.select);
	const [scopeSelected, setScopeSelected] = useState('');

	useEffect(
		_ => {
			if (scopes.length === 0) return;
			const selected = scopes.find(s => s.selected);
			setScopeSelected(selected.id);
		},
		[scopes]
	);

	const resetBadgeRankingFilters = _ => {
		resetOrder();
		//resetSingleFilter('selectedScope'); -> tolto per poter impostare il numero di righe a 25 con setStep
		resetSingleNoTrigger('selectedScope');
		setStep(25);
		selectScope('full'); //Reset della select box dei filtri avanzati
	};

	return (
		<Chip
			size="small"
			label={`${translate(`catalog.searchType`)}: ${filter.value.label}`}
			onDelete={_ => resetBadgeRankingFilters()}
		/>
	);
};

export default SubScopeChip;
