import React, { useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import pick from 'lodash/pick';
import { Stack, Stepper, Step, StepLabel, Typography, Box, IconButton, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SelectUsers from './SelectUsers';
import SelectCart from './SelectCart';
import SelectDepots from './SelectDepots';
import utils from 'components/utils';

const EvasionCart = props => {
	const { onClose } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const caUsers = useStoreState(state => state.cart.evasion.caUsers);
	const items = useStoreState(state => state.cart.evasion.items);
	const listCA = useStoreState(state => state.ca.list.items);
	const depots = useStoreState(state => state.cart.evasion.depots);
	const showEvasionButton = useStoreState(state => state.cart.evasion.showEvasionButton);
	const setShowEvasionButton = useStoreActions(dispatch => dispatch.cart.evasion.setShowEvasionButton);
	const checkEvasion = useStoreActions(dispatch => dispatch.cart.evasion.checkEvasion);
	const loadingEvasion = useStoreState(state => state.cart.evasion.loadingEvasion);
	const sendEvasion = useStoreActions(dispatch => dispatch.cart.evasion.sendEvasion);
	const firstStep = iCan('OPERATE_ON_MULTIPLE_CA') ? 0 : 1;
	const [step, changeStep] = useState(firstStep);

	const steps = [
		translate('orders.evasion.selectUsers'),
		translate('orders.evasion.selectCart'),
		translate('orders.evasion.selectDepot')
	];

	const getTitle = _ => {
		if (step === 0) return translate('orders.evasion.selectUsers');
		if (step === 1) return translate('orders.evasion.selectCart');
		else return translate('orders.evasion.selectDepot');
	};

	const getContinueDisabled = _ => {
		if (step === 0) return listCA.filter(i => i.selected).length === 0;
		if (step === 1) return items.filter(i => i.selected).length === 0;
		if (step === 2)
			return !showEvasionButton || depots.length === 0 || depots.filter(d => d.selected === true).length === 0;
		else return depots.filter(i => i.showDeliveries).length === 0;
	};

	const onContinueClick = _ => {
		if (step < 2) changeStep(step + 1);
		else {
			const payload = {
				depots: depots
					.filter(d => d.showDeliveries)
					.map(d => ({
						depotCode: d.id,
						shippingType: d.deliveries.find(del => del.selected).shippingRule.code
					})),
				carts: items
					.filter(item => item.selected)
					.map(item => ({
						...pick(item, ['cig', 'chapter', 'cause', 'purchaseOrderDate', 'purchaseNumber']),
						orderType: item.fcd ? 'ZF07' : 'ZF01'
					}))
			};
			if (caUsers.length > 0) {
				payload.ownerUserCode = caUsers.find(i => i.selected).id;
			}
			//checkEvasion(payload);
			sendEvasion(payload);
		}
	};

	const onBackClick = _ => {
		if (step > firstStep) {
			changeStep(step - 1);
			setShowEvasionButton(true);
		}
	};

	const handleClose = event => {
		onClose(event);
		setShowEvasionButton(true);
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			sx={{
				width: '100%',
				padding: '10px',
				minWidth: { xs: '340px', md: '550px', sm: '500px' }
			}}
		>
			<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ width: '100%' }}>
				<Box sx={{ width: '90%' }}>
					<Typography
						variant="titleSection"
						sx={{ color: theme.palette.primary.main, textTransform: 'capitalize' }}
					>
						{translate('orders.continue')}
					</Typography>
				</Box>
				<Box sx={{ width: '10%' }}>
					<IconButton onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</Stack>
			<Box
				sx={{
					width: '100%',
					borderWidth: '1px 0 0 0',
					borderStyle: 'solid',
					borderColor: theme.palette.primary.main,
					paddingTop: '10px',
					marginBottom: '15px'
				}}
			>
				<Stepper activeStep={step} alternativeLabel>
					{steps.map(label => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</Box>
			<Stack direction="row" justifyContent="center" alignItems="center" spacing={3} sx={{ width: '100%' }}>
				<Box sx={{ width: '50%', textAlign: 'left' }}>
					<Button
						variant="textAction"
						startIcon={<KeyboardDoubleArrowLeftIcon />}
						disabled={step === firstStep}
						onClick={onBackClick}
					>
						{translate('common.back')}
					</Button>
				</Box>
				<Box sx={{ width: '50%', textAlign: 'right' }}>
					{loadingEvasion && <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />}
					{!loadingEvasion && (
						<Button
							variant="textAction"
							endIcon={<KeyboardDoubleArrowRightIcon />}
							disabled={getContinueDisabled()}
							onClick={onContinueClick}
						>
							{translate('common.continue')}
						</Button>
					)}
				</Box>
			</Stack>
			<Box
				sx={{
					width: '100%',
					marginTop: '10px !important',
					marginBottom: '10px !important',
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: theme.palette.primary.main,
					borderRadius: '5px'
				}}
			>
				{step === 0 && <SelectUsers />}
				{step === 1 && <SelectCart />}
				{step === 2 && <SelectDepots />}
			</Box>
			<Stack direction="row" justifyContent="center" alignItems="center" spacing={3} sx={{ width: '100%' }}>
				<Box sx={{ width: '50%', textAlign: 'left' }}>
					<Button
						variant="textAction"
						startIcon={<KeyboardDoubleArrowLeftIcon />}
						disabled={step === firstStep}
						onClick={onBackClick}
					>
						{translate('common.back')}
					</Button>
				</Box>
				<Box sx={{ width: '50%', textAlign: 'right' }}>
					{loadingEvasion && <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />}
					{!loadingEvasion && (
						<Button
							variant="textAction"
							endIcon={<KeyboardDoubleArrowRightIcon />}
							disabled={getContinueDisabled()}
							onClick={onContinueClick}
						>
							{translate('common.continue')}
						</Button>
					)}
				</Box>
			</Stack>
		</Stack>
	);
};

export default EvasionCart;
