import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: {
		usersCentralPurchasing: []
	},
	resetDetails: action((state, payload) => {
		state.data = {
			usersCentralPurchasing: []
		};
	}),
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		getStoreActions().purchasings.assignUsers.resetSearch();
		return services.purchasings
			.getDetails(payload)
			.then(data => {
				actions.save(data.item);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('purchasings.errorGetDetails');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	save: action((state, payload) => {
		state.data = payload;
	}),
	setData: action((state, payload) => {
		state.data.items = payload;
	}),
	setName: action((state, payload) => {
		state.data.name = payload;
	}),
	setReferent: action((state, payload) => {
		state.data.referentCode = payload;
	}),
	setEnabled: action((state, payload) => {
		state.data.enabled = payload;
	})
};
