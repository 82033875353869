import React, { Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import CtaModal from './CtaModal';

const CtaPromotion = ({ slide }) => {
	return (
		<Button
			component={Link}
			to={`/catalog/search/by-promotion/${slide.ctaTypeValue}`}
			style={{ maxHeight: 40, color: `${slide.textBackgroundColor.hex}` }}
			disableRipple
			variant="contained"
			color="primary"
		>
			{slide.ctaText}
		</Button>
	);
};
const CtaCatalog = ({ slide }) => {
	return (
		<Button
			component={Link}
			to={`/catalog/search/details/${slide.ctaTypeValue}`}
			style={{ maxHeight: 40, color: `${slide.textBackgroundColor.hex}` }}
			disableRipple
			variant="contained"
			color="primary"
		>
			{slide.ctaText}
		</Button>
	);
};
const CtaUrl = ({ slide }) => {
	return (
		<a href={slide.ctaTypeValue} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
			<Button
				style={{ maxHeight: 40, color: `${slide.textBackgroundColor.hex}` }}
				disableRipple
				variant="contained"
				color="primary"
			>
				{slide.ctaText}
			</Button>
		</a>
	);
};
const CtaPopUp = ({ slide }) => {
	const modalOpen = useStoreState(state => state.cms.promotionSlide.modalOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.cms.promotionSlide.setModalOpen);
	const style = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	};

	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				style={style}
				open={modalOpen}
				onClose={_ => {
					setModalOpen(false);
				}}
			>
				<div className="modal-content">
					<Fragment>
						<Fade in={modalOpen}>
							<CtaModal modalMessage={slide.ctaTypeValue} modalColor={slide.popupBackgroundColor.hex} />
						</Fade>
					</Fragment>
				</div>
			</Modal>
			<Button
				style={{ maxHeight: 40, color: `${slide.textBackgroundColor.hex}` }}
				disableRipple
				variant="contained"
				color="primary"
				onClick={_ => {
					setModalOpen(true);
				}}
			>
				{slide.ctaText}
			</Button>
		</Fragment>
	);
};

export const CtaDetails = ({ slide }) => {
	return (
		<Fragment>
			{slide.ctaType.title === 'promotion' && <CtaPromotion slide={slide} />}
			{slide.ctaType.title === 'buy_product' && <CtaCatalog slide={slide} />}
			{slide.ctaType.title === 'url_link' && <CtaUrl slide={slide} />}
			{slide.ctaType.title === 'details_text' && <CtaPopUp slide={slide} />}
		</Fragment>
	);
};
