import { action, thunk } from 'easy-peasy';
import head from 'lodash.head';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	scope: '',
	setScope: action((state, payload) => {
		state.scope = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const { successMessage, history, redirect, callback } = payload;
		return services.order
			.createMultiple(payload)
			.then(data => {
				const first = head(data.items);
				if (!first) return Promise.resolve(data);
				if (first.budgetDateErrorMessage && first.budgetDateErrorMessage !== '') {
					getStoreActions().errorCustom(first.budgetDateErrorMessage);
				} else if (first.budgetResidualErrorMessage && first.budgetResidualErrorMessage !== '') {
					getStoreActions().errorCustom(first.budgetResidualErrorMessage);
				} else {
					getStoreActions().successCustom(successMessage);
					if (typeof callback === 'function') {
						callback();
					}
					if (redirect && redirect !== '') history.push(redirect);
				}
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('order.create');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
