import { thunk, thunkOn } from 'easy-peasy';

export default {
	request: thunk((actions, payload, { injections, getStoreActions, getState }) => {
		const { services } = injections;
		return services.connect.requestToken(payload).then(data => {
			data.bearer = data.access_token;
			data.refreshToken = data.refresh_token;
			data.idToken = data.id_token;
			return Promise.resolve({
				bearer: data.access_token,
				refreshToken: data.refresh_token,
				idToken: data.id_token
			});
		});
	}),
	loadOnTokenRequest: thunkOn(
		(actions, storeActions) => [actions.request.successType],
		(actions, target, helpers) => {
			const {
				injections: { services },
				getStoreActions
			} = helpers;
			services.me.getAlertAccepted().then(data => getStoreActions().me.saveAlertAccepted(data));
			services.me.getAbilitation().then(data => getStoreActions().me.saveAbilitations(data));
			services.me
				.getMe()
				.then(data => {
					data.isLoggedIn = true;
					getStoreActions().me.save(data);
				})
				.catch(e => {
					getStoreActions().error('me.getMe');
				});
		}
	)
};
