import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const InvoiceComp = ({ num, d }) => {
	const a = num && d ? num + ' - ' + d : '';

	return <StyledTableCell>{a}</StyledTableCell>;
};

const Invoice = memo(
	({ item }) => <InvoiceComp num={item.docNumRif} d={item.docDateRif} />,
	(p, n) => p.item.docNumRif === n.item.docNumRif && p.item.docDateRif === n.item.docDateRif
);

export default Invoice;
