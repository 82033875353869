import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import RichTextEditor from 'react-rte';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import Card from 'components/Card';
import TextEditor from 'components/TextEditor';
import styles from './styles';

const Contact = ({ classes }) => {
	const translate = utils.useI18N();
	const referent = useStoreState(state => state.notifications.contact.referent);
	const loadingReferent = useStoreState(state => state.notifications.contact.loadingReferent);
	const loadingSend = useStoreState(state => state.notifications.contact.loadingSend);
	const getReferent = useStoreActions(dispatch => dispatch.notifications.contact.getReferent);
	const send = useStoreActions(dispatch => dispatch.notifications.contact.send);
	const setCondOfSaleScope = useStoreActions(dispatch => dispatch.users.conditionsOfSale.setScope);
	const setWishlistScope = useStoreActions(dispatch => dispatch.wishlist.list.setScope);
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState(RichTextEditor.createEmptyValue());
	const [sendDisabled, setSendDisabled] = useState(true);
	// prettier-ignore
	useEffect(_ => { setCondOfSaleScope('contact'); }, [setCondOfSaleScope]);
	// prettier-ignore
	useEffect(_ => { setWishlistScope('contact'); }, [setWishlistScope]);
	// prettier-ignore
	useEffect(getReferent, [getReferent]);
	const onSend = _ => {
		send({
			referentEmail: referent.email,
			subject,
			body: message.toString('html')
		});
	};
	useEffect(
		_ => {
			const noMessage =
				message
					.getEditorState()
					.getCurrentContent()
					.getPlainText()
					.trim() === '';
			setSendDisabled && setSendDisabled(!subject || noMessage);
		},
		[subject, message, setSendDisabled]
	);
	return (
		<Card type="primary" title="Contatta il tuo agente">
			{loadingReferent && (
				<Grid container spacing={2} justify="center">
					<Grid item xs={12}>
						<CircularProgress size={48} />
					</Grid>
				</Grid>
			)}
			{!loadingReferent && (
				<Grid container spacing={2} justify="space-between">
					<Grid item xs={12}>
						<TextField
							value={subject}
							onChange={e => {
								setSubject(e.target.value);
							}}
							fullWidth={true}
							label="Soggetto"
						/>
					</Grid>
					<Grid item container xs={12} md={2} spacing={1} className={classes.referentData}>
						<Grid item xs={12}>
							<Typography className={classes.title} color="inherit" variant="body2">
								<b>{translate('sendNotification.name')}:&nbsp;</b>
								{referent.name}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.title} color="inherit" variant="body2">
								<b>{translate('sendNotification.phone')}:&nbsp;</b>
								{referent.phoneNumber}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.title} color="inherit" variant="body2">
								<b>{translate('sendNotification.email')}:&nbsp;</b>
								{referent.email}
							</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={12} md={10}>
						<Grid item xs={12}>
							<Typography className={classes.title} color="inherit" variant="h6">
								{translate('sendNotification.message')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextEditor value={message} onChange={value => setMessage(value)} />
						</Grid>
						<Grid container item xs={12} justify="flex-end" style={{ marginTop: 16 }}>
							{loadingSend && <CircularProgress size={14} />}
							{!loadingSend && (
								<Button
									variant="contained"
									color="primary"
									disabled={sendDisabled}
									className={classes.buttonsMargin}
									onClick={_ => {
										onSend();
										setSubject('');
										setMessage(RichTextEditor.createEmptyValue());
									}}
								>
									{translate('sendNotification.send')}
								</Button>
							)}
						</Grid>
					</Grid>
				</Grid>
			)}
		</Card>
	);
};

export default withStyles(styles, { withTheme: true })(Contact);
