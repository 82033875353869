import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const QuantityRequestComp = ({ amount }) => {
	return <StyledTableCell>{amount}</StyledTableCell>;
};

const QuantityRequest = memo(
	({ item }) => <QuantityRequestComp amount={item.deliveredQuantityEff} />,
	(p, n) => p.item.deliveredQuantityEff === n.item.deliveredQuantityEff
);

export default QuantityRequest;
