import React, { Fragment } from 'react';
import { orange } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import styles from './styles';
import utils from 'components/utils';
import WarningIcon from '@mui/icons-material/Warning';

const DepotWarnings = ({ item }) => {
	const translate = utils.useI18N();
	return (
		<Fragment>
			{item.warning1000 && (
				<Fragment>
					<WarningIcon fontSize="small" style={{ color: orange[500], marginRight: 8 }} />
					<Typography variant="text" color="error">
						Non è possibile ordinare più di 999 copie con una sola richiesta
					</Typography>
				</Fragment>
			)}
			{item.warningFES && (
				<Fragment>
					<Grid item container xs={12}>
						<WarningIcon fontSize="small" style={{ color: orange[500], marginRight: 8 }} />
						<Typography variant="text" color="error">
							{translate('catalog.selection.warningFES')}
						</Typography>
					</Grid>
				</Fragment>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(DepotWarnings);
