import React, { Fragment, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
	Box,
	CircularProgress,
	IconButton,
	Card,
	Popover,
	CardContent,
	Stack,
	Alert,
	Button,
	Badge,
	Typography,
	Slide,
	Skeleton,
	ButtonBase,
	Tooltip
} from '@mui/material';
import { Notifications } from '@mui/icons-material';
import utils from 'components/utils';
import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';

const Notification = props => {
	const { style } = props;
	const translate = utils.useI18N();
	const notifications = useStoreState(state => state.notifications.listLight.items);
	const loading = useStoreState(state => state.notifications.count.loading);
	const count = useStoreState(state => state.notifications.count.value);
	const loadCount = useStoreActions(dispatch => dispatch.notifications.count.load);
	const loadNotifications = useStoreActions(dispatch => dispatch.notifications.listLight.load);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};
	useEffect(_ => {
		loadCount();
		loadNotifications();
	}, []);
	return (
		<Box>
			{loading && <Skeleton variant="circular" sx={{ width: '40px', height: '40px' }} />}
			{!loading && (
				<Fragment>
					<IconButton onClick={event => setAnchorEl(event.currentTarget)}>
						<Badge badgeContent={count} color="error" invisible={count === 0}>
							<Notifications sx={{ borderRadius: '20%', fontSize: '25px' }} />
						</Badge>
					</IconButton>
					<Popover
						TransitionComponent={Slide}
						disableScrollLock
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						elevation={style.elevation}
						anchorOrigin={{
							vertical: style.anchorOrigin.vertical,
							horizontal: style.anchorOrigin.horizontal
						}}
						transformOrigin={{
							vertical: style.transformOrigin.vertical,
							horizontal: style.transformOrigin.horizontal
						}}
					>
						{/* <NotificationsBox /> */}
						<Card sx={{ borderRadius: '8px', width: '400px' }}>
							<CardContent>
								<Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
									{notifications.map(item => (
										<Stack
											direction="column"
											alignItems="center"
											justifyContent="center"
											key={item.id}
											sx={{
												width: '100%',
												textDecoration: 'none'
												// ':hover': { transition: '0.3s', backgroundColor: grey[200] }
											}}
											component={Link}
											to={'/notifications'}
										>
											<Alert variant="outlined" severity="warning">
												{item.subject}
											</Alert>
										</Stack>
									))}
									{notifications.length === 0 && (
										<Typography variant="text">{translate('common.noNotifications')}</Typography>
									)}

									<Button component={Link} variant="light" to="/notifications">
										{translate('notifications.viewAll')}
									</Button>
								</Stack>
							</CardContent>
						</Card>
					</Popover>
				</Fragment>
			)}
		</Box>
	);
};

export default Notification;
