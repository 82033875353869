import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const withColumns = WrappedComponent => {
	const desktopCols = [
		'id',
		'externalNotificationStatus',
		'internalNotificationStatus',
		'createdBy',
		'title',
		'body',
		'groups',
		'creationDate'
	];
	const mobileCols = ['createdBy', 'title', 'groups', 'creationDate'];
	return props => {
		const theme = useTheme();
		let colCount = mobileCols;
		if (useMediaQuery(theme.breakpoints.up('sm'))) colCount = desktopCols;
		return <WrappedComponent {...props} visibleCols={colCount} />;
	};
};

export default withColumns;
