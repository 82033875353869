import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Popper from '@mui/material/Popper';
import Alert from '@mui/lab/Alert';

const SingleFormat = ({ item }) => {
	const download = useStoreActions(dispatch => dispatch.documents.download.getFile);
	return (
		<Grid
			container
			item
			xs={12}
			onClick={_ => {
				download(item.code);
			}}
		>
			<Grid item style={{ width: 24 }}>
				<GetAppOutlinedIcon style={{ cursor: 'pointer' }} fontSize="small" />
			</Grid>
			<Grid item xs>
				<Typography variant="body2">{item.label}</Typography>
			</Grid>
		</Grid>
	);
};

const Marc = ({ formats, onClose }) => {
	const paperStyle = {
		backgroundColor: '#FFF',
		border: '2px solid #000',
		padding: '16px',
		width: 520
	};
	return (
		<Paper style={paperStyle}>
			<Grid container>
				<Grid container item xs={12} justify="flex-end">
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Grid>
				<Grid container spacing={1} item xs={4} alignContent="flex-start">
					{formats.marc.map(f => (
						<SingleFormat key={`format_marc_${f.code}`} item={f} />
					))}
				</Grid>
				<Grid container spacing={1} item xs={4} alignContent="flex-start">
					{formats.mods.map(f => (
						<SingleFormat key={`format_mods_${f.code}`} item={f} />
					))}
				</Grid>
				<Grid container spacing={1} item xs={4} alignContent="flex-start">
					{formats.dublin.map(f => (
						<SingleFormat key={`format_dublin_${f.code}`} item={f} />
					))}
				</Grid>
			</Grid>
		</Paper>
	);
};

const DownloadOptions = props => {
	const loading = useStoreState(state => state.documents.download.loading);
	const fileLoadingPdf = useStoreState(state => state.documents.download.fileLoadingPdf);
	const fileLoadingXls = useStoreState(state => state.documents.download.fileLoadingXls);
	const fileLoadingCsv = useStoreState(state => state.documents.download.fileLoadingCsv);
	const formats = useStoreState(state => state.documents.download.formats);
	const hasMarc = useStoreState(state => state.documents.download.hasMarc);
	const getAvailableFormat = useStoreActions(dispatch => dispatch.documents.download.getAvailableFormat);
	const download = useStoreActions(dispatch => dispatch.documents.download.getFile);
	useEffect(
		_ => {
			getAvailableFormat();
		},
		[getAvailableFormat]
	);
	const [anchorEl, setAnchorEl] = useState(null);
	const [marcOpen, setMarcOpen] = useState(false);
	const toggleMarc = _ => {
		setMarcOpen(!marcOpen);
	};
	const onMarcClick = event => {
		setAnchorEl(event.currentTarget);
		toggleMarc();
	};
	useEffect(
		_ => {
			const onResize = _ => {
				setMarcOpen(false);
				setAnchorEl(null);
			};
			window.addEventListener('resize', onResize);
			return _ => {
				window.removeEventListener('resize', onResize);
			};
		},
		[setMarcOpen, setAnchorEl]
	);
	const p = formats.pdf ? 1 : 0;
	const x = formats.xls ? 2 : 0;
	const m = hasMarc ? 4 : 0;
	const c = formats.csv ? 8 : 0;

	const combined = p + c + x + m;
	const size = [0, 12, 12, 6, 12, 6, 6, 4, 12, 6, 6, 4, 6][combined];
	return (
		<Grid container>
			{loading && (
				<Grid container item xs={12} justify="flex-end">
					<CircularProgress size={36} />
				</Grid>
			)}
			{!loading && size === 0 && (
				<Grid container item xs={12}>
					<Alert severity="error">
						<Typography>Non ci sono download disponibili per questo documento</Typography>
					</Alert>
				</Grid>
			)}
			{!loading && combined > 0 && size > 0 && (
				<Fragment>
					{formats.pdf && fileLoadingPdf && <CircularProgress size={16} />}
					{formats.pdf && !fileLoadingPdf && (
						<Grid container item xs={size} justify="center">
							<Button
								onClick={_ => {
									download('pdf');
								}}
								variant="contained"
								color="primary"
								endIcon={<PictureAsPdfIcon />}
							>
								pdf
							</Button>
						</Grid>
					)}
					{formats.xls && fileLoadingXls && <CircularProgress size={16} />}
					{formats.xls && !fileLoadingXls && (
						<Grid container item xs={size} justify="center">
							<Button
								onClick={_ => {
									download('xls');
								}}
								variant="contained"
								color="primary"
								endIcon={<GetAppOutlinedIcon />}
							>
								xls
							</Button>
						</Grid>
					)}
					{formats.csv && fileLoadingCsv && <CircularProgress size={16} />}
					{formats.csv && !fileLoadingCsv && (
						<Grid container item xs={size} justify="center">
							<Button
								onClick={_ => {
									download('csv');
								}}
								variant="contained"
								color="primary"
								endIcon={<GetAppOutlinedIcon />}
							>
								csv
							</Button>
						</Grid>
					)}
					{hasMarc && (
						<Grid container item xs={size} justify="center">
							<Button variant="outlined" color="primary" onClick={onMarcClick}>
								formati marc
							</Button>
							<Popper
								style={{ zIndex: 1301 }}
								open={marcOpen}
								anchorEl={anchorEl}
								placement="right"
								transition
							>
								<Marc formats={formats} onClose={_ => setMarcOpen(false)} />
							</Popper>
						</Grid>
					)}
				</Fragment>
			)}
		</Grid>
	);
};

export default DownloadOptions;
