import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import RichTextEditor from 'react-rte';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import Card from 'components/Card';
import TextEditor from 'components/TextEditor';
import styles from './styles';
import { Stack, Paper, LinearProgress } from '@mui/material';
import { Send } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const Support = ({ classes }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.notifications.ticket.loading);
	const open = useStoreActions(dispatch => dispatch.notifications.ticket.open);
	const setWishlistScope = useStoreActions(dispatch => dispatch.wishlist.list.setScope);
	const setCondOfSaleScope = useStoreActions(dispatch => dispatch.users.conditionsOfSale.setScope);
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState(RichTextEditor.createEmptyValue());
	const [sendDisabled, setSendDisabled] = useState(true);
	// prettier-ignore
	useEffect(_ => { setCondOfSaleScope('support'); }, [setCondOfSaleScope]);
	// prettier-ignore
	useEffect(_ => { setWishlistScope('support'); }, [setWishlistScope]);
	const onSend = _ => {
		open({
			subject,
			body: message.toString('html')
		});
	};
	useEffect(
		_ => {
			const noMessage =
				message
					.getEditorState()
					.getCurrentContent()
					.getPlainText()
					.trim() === '';
			setSendDisabled && setSendDisabled(!subject || noMessage);
		},
		[subject, message, setSendDisabled]
	);
	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				{/** HEADER BAR */}
				<Stack
					direction={{ xs: 'row', md: 'row', sm: 'row' }}
					justifyContent="space-between"
					alignItems="center"
					spacing={1}
					variant="wrapperColor"
				>
					<Typography variant="titlePage">Apri un ticket</Typography>
					<Button
						disabled={loading || sendDisabled}
						onClick={_ => {
							onSend();
							setSubject('');
							setMessage(RichTextEditor.createEmptyValue());
						}}
						variant="dark"
						endIcon={<Send />}
					>
						{translate('sendNotification.send')}
					</Button>
				</Stack>
			</Paper>
			<Paper
				sx={{
					width: '100%',
					marginTop: theme.spacing(2),
					padding: { md: theme.spacing(4), xs: theme.spacing(2) }
				}}
			>
				<Stack spacing={2}>
					<TextField
						value={subject}
						onChange={e => {
							setSubject(e.target.value);
						}}
						fullWidth
						label="Oggetto"
						InputLabelProps={{ sx: { zIndex: 0 } }}
					/>
					<Typography variant="h6">{translate('sendNotification.message')}</Typography>
					<TextEditor value={message} onChange={value => setMessage(value)} />
				</Stack>
			</Paper>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(Support);
