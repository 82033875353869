import { thunk } from 'easy-peasy';

export default {
	sendRequest: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		const { target, successMessage } = payload;
		return services.me.updateSapInfo(target).then(data => {
			//	getStoreActions().successCustom(successMessage);
			return Promise.resolve(data);
		});
	})
};
