const openOrderCols = [
	'selection',
	'deletee',
	'catalog',
	'author',
	'epipoli',
	'title', // will always be with preload and availability
	'volume',
	'series',
	'publisher',
	'price',
	'promo',
	'news',
	'depot',
	'evadibleQty',
	'quantity'
	// 'edit'
];

export default openOrderCols;
