import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
//import Default from 'layouts/Default';
import Default from 'layouts/Layuot7';
import Blank from 'layouts/Blank';
// import Home from './Home';
import Home from './HomeV2';
import HomeNoCMS from './HomeNoCMS';
import Perms from './Perms';
import OIDC from './OIDC';
import Logout from './Logout';
// import Me from './Me';
import Me from './Me7';
import Admin from './Admin';
import Catalog from './Catalog';
import Print from './Print';
//import Orders from './Orders';
import Orders from './Orders7';
import Returns from './Returns';
import Wishlist from './Wishlist';
import Notifications from './Notifications';
// import Conditions from './Conditions';
import Conditions from './Conditions7';
import Documents from './Documents';
import Contact from './Contact';
import Support from './Support';
import EvasionCart from './EvasionCart';
import Budget from './Budget7';
import Import from './Import';
import NotLogged from './NotLogged';
import Proposals from './Proposals';
import Contest from './Contest';
import utils from 'components/utils';

const commonRoutes = [
	{
		id: 'notLogged',
		path: '/not-logged',
		component: NotLogged,
		exact: true,
		layout: Default
	},
	{
		id: 'home',
		path: '/',
		component: process.env.REACT_APP_FF_CMS_HOME ? Home : HomeNoCMS,
		exact: true,
		layout: Default
	},
	{
		id: 'promotions',
		path: '/home/promotions/:name',
		component: process.env.REACT_APP_FF_CMS_HOME ? Home : HomeNoCMS,
		exact: true,
		layout: Default
	},
	{
		id: 'bestsellers',
		path: '/home/bestsellers/:id',
		component: process.env.REACT_APP_FF_CMS_HOME ? Home : HomeNoCMS,
		exact: true,
		layout: Default
	},
	{
		id: 'perms',
		path: '/perms',
		component: Perms,
		exact: true,
		layout: Blank,
		title: 'Perms'
	},
	{
		id: 'signin-oidc',
		path: '/signin-oidc',
		component: OIDC,
		layout: Blank
	},
	{
		id: 'logout',
		path: '/logout',
		component: Logout,
		layout: Blank
	},
	{
		id: 'me',
		path: '/me',
		component: Me,
		layout: Default
	},
	{
		id: 'print',
		path: '/print',
		component: Print,
		layout: Blank
	},
	{
		id: 'notifications',
		path: '/notifications',
		component: Notifications,
		layout: Default
	},
	{
		id: 'conditions',
		path: '/conditions',
		component: Conditions,
		layout: Default
	},
	{
		id: 'support',
		path: '/support',
		component: Support,
		layout: Default
	},
	{
		id: 'import',
		path: '/import',
		component: Import,
		layout: Default
	},
	{
		id: 'fulfillment',
		path: '/fulfillment',
		component: EvasionCart,
		layout: Default
	},
	{
		id: 'contest',
		path: '/contest/:id',
		component: Contest,
		layout: Default
	}
];

const reservedRoutes = {
	BACKOFFICE: {
		id: 'admin',
		path: '/admin',
		component: Admin,
		layout: Default
	},
	CATALOGS: {
		id: 'catalog',
		path: '/catalog',
		component: Catalog,
		layout: Default
	},
	ORDERS: {
		id: 'orders',
		path: '/orders',
		component: Orders,
		layout: Default
	},
	ORDERS_SEARCH: {
		id: 'orders_search',
		path: '/orders/list/search',
		component: Orders,
		layout: Default
	},
	ORDERS_SHIPPED: {
		id: 'orders_shipped',
		path: '/orders/list/shipped/:id',
		component: Orders,
		layout: Default
	},
	ORDERS_DETAILS: {
		id: 'orders_details',
		path: '/orders/list/details/:orderCode/:idForSelection/:catalog',
		component: Orders,
		layout: Default
	},
	RETURNS: {
		id: 'returns',
		path: '/returns',
		component: Returns,
		layout: Default
	},
	RETURNS_REFUSED: {
		id: 'returns_refused',
		path: '/returns/refused/:id',
		component: Returns,
		layout: Default
	},
	RETURNS_ACCEPTED: {
		id: 'returns_accepted',
		path: '/returns/accepted/:id',
		component: Returns,
		layout: Default
	},
	RETURNS_PENDING: {
		id: 'returns_pending',
		path: '/returns/pending/:id',
		component: Returns,
		layout: Default
	},
	DOCUMENTS: {
		id: 'documents',
		path: '/documents',
		component: Documents,
		layout: Default
	},
	CONTACT_AGENT: {
		id: 'contact',
		path: '/contact',
		component: Contact,
		layout: Default
	},
	WISHLIST: {
		id: 'wishlist',
		path: '/wishlist',
		component: Wishlist,
		layout: Default
	},
	WISHLIST_CREATE: {
		id: 'wishlist_create',
		path: '/wishlist/list/:title',
		component: Wishlist,
		layout: Default
	},
	WISHLIST_DETAILS: {
		id: 'wishlist_details',
		path: '/wishlist/list/details/:id/:fatherId/:ean',
		component: Wishlist,
		layout: Default
	},
	BUDGET: {
		id: 'budget',
		path: '/budget',
		component: Budget,
		layout: Default
	},
	BUDGET_CREATE: {
		id: 'budget_create',
		path: '/budget/create',
		component: Budget,
		layout: Default
	},
	PROPOSALS: {
		id: 'proposals',
		path: '/proposals',
		component: Proposals,
		layout: Default
	},
	PROPOSALS_DETAILS: {
		id: 'proposals_detail',
		path: '/proposals/detail/:id',
		component: Proposals,
		layout: Default
	}
};

export default permissions => {
	const routes = [...commonRoutes];
	if (!permissions) return routes;
	forEach(routes, r => {
		r.title = utils.useI18N()('common.menu.' + r.id);
	});
	forEach(reservedRoutes, r => {
		r.title = utils.useI18N()('common.menu.' + r.id);
	});
	forEach(keys(permissions), key => {
		if (permissions[key] && reservedRoutes[key]) routes.push(reservedRoutes[key]);
	});
	return routes;
};
