import getList from './list';
import getDetails from './get-details';
import search from './search';
import update from './update';
import getReferent from './get-referent';
import createPurchasing from './create-purchasing';
import deletePurchasings from './delete-puchasings';
import getUsers from './get-users';
import getCaUsers from './get-ca-users';
import updateAssignment from './update-assignment';
import getReport from './report';
import importt from './import';

export default {
	getList,
	getDetails,
	search,
	update,
	getReferent,
	createPurchasing,
	deletePurchasings,
	getUsers,
	getCaUsers,
	updateAssignment,
	getReport,
	importt
};
