import { action, actionOn, thunk } from 'easy-peasy';

export default {
	open: false,
	setOpen: action((state, payload) => {
		state.open = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	openCreate: false,
	setOpenCreate: action((state, payload) => {
		state.openCreate = payload;
	}),
	name: '',
	setName: action((state, payload) => {
		state.name = payload;
	}),
	resetNameOnsuccessCreate: actionOn(
		(actions, storeActions) => [storeActions.cart.create.sendRequest.successType],
		state => {
			state.name = '';
		}
	),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.cart
			.create(payload)
			.then(data => {
				actions.setOpen(false);
				getStoreActions().success('cart.create');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('cart.create');
			})
			.finally(_ => {
				actions.setLoading(false);
				actions.setOpenCreate(false);
			});
	})
};
