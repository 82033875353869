import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { AcceptedTable, PendingTable, RefusedTable } from './components/Table';
import NewRequest from './components/NewRequest';
import Detail from './components/Detail';
import styles from './styles';
import { useHistory } from 'react-router-dom';
import {
	Paper,
	Stack,
	Typography,
	Box,
	Backdrop,
	Fab,
	useMediaQuery,
	Popover,
	Button,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	LinearProgress,
	ToggleButton,
	ToggleButtonGroup
} from '@mui/material';
import { Add, CalendarMonth, CalendarMonthSharp } from '@mui/icons-material';
import NewRequestButton from './components/ActionButtons/components/NewRequestButton';
import ShowCalendarButton from './components/ActionButtons/components/ShowCalendarButton';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import utils from 'components/utils';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import AdjustIcon from '@mui/icons-material/Adjust';

const Returns = ({ classes }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const tabSelected = useStoreState(state => state.returns.tabSelected);
	const setTabSelected = useStoreActions(dispatch => dispatch.returns.setTabSelected);
	const requestOpen = useStoreState(state => state.returns.requestOpen);
	const setRequestOpen = useStoreActions(dispatch => dispatch.returns.setRequestOpen);
	const setWishlistScope = useStoreActions(dispatch => dispatch.wishlist.list.setScope);
	const setCondOfSaleScope = useStoreActions(dispatch => dispatch.users.conditionsOfSale.setScope);
	const detailOpen = useStoreState(state => state.returns.detailOpen);
	const setDetailOpen = useStoreActions(dispatch => dispatch.returns.setDetailOpen);
	const loadAccepted = useStoreActions(dispatch => dispatch.returns.accepted.load);
	const loadRefused = useStoreActions(dispatch => dispatch.returns.refused.load);
	const loadPending = useStoreActions(dispatch => dispatch.returns.pending.load);
	const loadDepots = useStoreActions(dispatch => dispatch.depots.load);
	const history = useHistory();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const [isAvailable, setIsAvailable] = useState(false);
	const getDays = useStoreActions(dispatch => dispatch.returns.calendar.getDays);
	const dayList = useStoreState(state => state.returns.calendar.items);
	const colorSelected = theme.palette.primary.main;
	const colorNotSelected = theme.palette.custom.greyborder;
	const loadingPending = useStoreState(state => state.returns.pending.loading);
	const loadingRefused = useStoreState(state => state.returns.refused.loading);
	const loadingAccepted = useStoreState(state => state.returns.accepted.loading);
	const pendingCount = useStoreState(state => state.returns.pending.list.length);
	const refusedCount = useStoreState(state => state.returns.refused.list.length);
	const acceptedCount = useStoreState(state => state.returns.accepted.list.length);

	const [anchorElButtonList, setAnchorElButtonList] = useState(null);
	const openButtonList = Boolean(anchorElButtonList);
	const handleOpenButtonList = event => {
		setAnchorElButtonList(event.currentTarget);
	};
	const handleCloseButtonlist = _ => {
		setAnchorElButtonList(null);
	};

	const [anchorElCalendar, setAnchorElCalendar] = useState(null);
	const openCalendar = Boolean(anchorElCalendar);
	const handleOpenCalendar = event => {
		setAnchorElCalendar(event.currentTarget);
	};
	const handleCloseCalendar = _ => {
		setAnchorElCalendar(null);
	};

	const [anchorElNewRequest, setAnchorElNewRequest] = useState(null);
	const handleOpenNewRequest = event => {
		setRequestOpen(true);
		setAnchorElNewRequest(event.currentTarget);
	};
	const handleCloseNewRequest = event => {
		setRequestOpen(false);
		setAnchorElNewRequest(null);
	};
	useEffect(
		_ => {
			const now = moment();
			getDays({ month: now.month() + 1, year: now.year() });
		},
		[getDays]
	);
	useEffect(
		_ => {
			const now = moment();
			const formattedDay = now.format('DD/MM/YYYY');
			const d = dayList.find(item => item.day === formattedDay);
			setIsAvailable(d && d.available);
		},
		[dayList]
	);

	// prettier-ignore
	useEffect(_ => { setCondOfSaleScope('returns'); }, [setCondOfSaleScope]);
	// prettier-ignore
	useEffect(_ => { setWishlistScope('returns'); }, [setWishlistScope]);
	// prettier-ignore
	useEffect(_ => loadDepots('RETURN'), [loadDepots]);
	useEffect(loadAccepted, [loadAccepted]);
	useEffect(loadRefused, [loadRefused]);
	useEffect(loadPending, [loadPending]);

	const handleTab = (event, newValue) => {
		setTabSelected(newValue);

		if (detailOpen === true) {
			setDetailOpen(false);
			//TO VERIFY
			// if (history.action === 'PUSH') history.goBack();
			// else
			history.push(`/returns`);
		}
	};
	const leftGridMdWidth = requestOpen || detailOpen ? 7 : 12;
	useEffect(
		_ => {
			if (history.location.pathname.includes('/returns/refused/')) {
				setTabSelected(1);
			} else if (history.location.pathname.includes('/returns/accepted/')) {
				setTabSelected(2);
			} else if (history.location.pathname.includes('/returns')) {
				setTabSelected(0);
			}
		},
		[history, setTabSelected]
	);

	const buttonList = [
		{
			label: translate('returns.showCalendar'),
			icon: <CalendarMonth />,
			condition: true,
			onclick: handleOpenCalendar,
			type: 'light',
			disabled: false
		},
		{
			label: translate('returns.request'),
			icon: <Add />,
			condition: iCan('REQUEST_NEW_RETURN'),
			onclick: handleOpenNewRequest,
			type: 'dark',
			disabled: !isAvailable
		}
	];
	const listTab = [
		{
			tab: 0,
			label: translate('returns.pendingTab'),
			count: pendingCount,
			loading: loadingPending
		},
		{
			tab: 1,
			label: translate('returns.refusedTab'),
			count: refusedCount,
			loading: loadingRefused
		},
		{
			tab: 2,
			label: translate('returns.acceptedTab'),
			count: acceptedCount,
			loading: loadingAccepted
		}
	];

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				{/** HEADER BAR */}
				<Stack
					direction={{ xs: 'row', md: 'row', sm: 'row' }}
					justifyContent="space-between"
					alignItems="center"
					spacing={1}
					variant="wrapperColor"
				>
					<Typography variant="titlePage">Resi</Typography>
					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
						spacing={1}
						sx={{
							width: '100%',
							display: {
								xs: buttonList.filter(button => button.condition).length === 1 ? 'inherit' : 'none',
								md: 'inherit',
								sm: 'inherit'
							}
						}}
					>
						{buttonList.map(
							btn =>
								btn.condition && (
									<Button
										key={btn.label}
										variant={btn.type}
										onClick={btn.onclick}
										endIcon={btn.icon}
										disabled={btn.disabled}
									>
										{btn.label}
									</Button>
								)
						)}
					</Stack>
					<Box
						sx={{
							width: '100%',
							justifyContent: 'flex-end',
							display: {
								xs: buttonList.filter(button => button.condition).length > 1 ? 'inherit' : 'none',
								md: 'none',
								sm: 'none'
							}
						}}
					>
						<Backdrop open={openButtonList} />
						<Fab
							aria-label="Mobile function"
							onClick={handleOpenButtonList}
							sx={{
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.custom.white,
								'&:hover': {
									backgroundColor: theme.palette.custom.hover
								}
							}}
							size="small"
						>
							<Add />
						</Fab>
						<Popover
							open={openButtonList}
							anchorEl={anchorElButtonList}
							onClose={handleCloseButtonlist}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							<Paper elevation={24}>
								<List sx={{ padding: '0' }}>
									{buttonList.map(
										btn =>
											btn.condition && (
												<ListItemButton
													key={btn.label}
													disabled={btn.disabled}
													variant="mobileList"
													href={btn.href}
													onClick={btn.onclick}
													sx={{
														backgroundColor:
															btn.type === 'dark'
																? theme.palette.primary.main
																: theme.palette.custom.white,
														'&:hover': {
															backgroundColor:
																btn.type === 'dark'
																	? theme.palette.primary.main
																	: theme.palette.custom.white
														}
													}}
												>
													<ListItemIcon>{btn.icon}</ListItemIcon>
													<ListItemText
														primary={btn.label}
														sx={{
															color:
																btn.type == 'dark'
																	? theme.palette.custom.white
																	: 'inherit'
														}}
													/>
												</ListItemButton>
											)
									)}
								</List>
							</Paper>
						</Popover>
					</Box>
				</Stack>

				{/** TAB BAR */}
				<Stack
					direction={{ xs: 'column', md: 'row', sm: 'row' }}
					justifyContent="flex-stat"
					alignItems="center"
					spacing={1}
					variant="wrapperColor"
					sx={{ padding: { xs: '10px 0 !important', md: '10px 20px !important', sm: '10px !important' } }}
				>
					<Box sx={{ width: { xs: '100%', md: '80%', sm: '100%' } }}>
						<ToggleButtonGroup value={tabSelected} exclusive onChange={handleTab}>
							{listTab.map(item => (
								<ToggleButton
									key={item.tab}
									variant="tab"
									value={item.tab}
									disabled={item.loading || tabSelected == item.tab}
								>
									{tabSelected == item.tab && (
										<AdjustIcon
											sx={{
												color: !item.loading ? colorSelected : colorNotSelected,
												fontSize: { xs: '20px', md: '24px', sm: '24px' }
											}}
										/>
									)}
									{tabSelected != item.tab && (
										<Brightness1Icon
											sx={{
												color: colorNotSelected,
												fontSize: { xs: '20px', md: '24px', sm: '24px' }
											}}
										/>
									)}
									<Stack
										direction={{ xs: 'column', md: 'row', sm: 'row' }}
										spacing={0}
										justifyContent="flex-start"
										alignItems="flex-start"
									>
										<Typography
											variant="titleTab"
											sx={{
												color:
													tabSelected == item.tab ? colorSelected : theme.palette.custom.black
											}}
										>
											{item.label}
										</Typography>
										<Typography
											variant="titleTab"
											sx={{
												color:
													tabSelected == item.tab ? colorSelected : theme.palette.custom.black
											}}
										>
											{` (${item.count})`}
										</Typography>
									</Stack>
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					</Box>
				</Stack>
				{((tabSelected == 0 && loadingPending) ||
					(tabSelected == 1 && loadingRefused) ||
					(tabSelected == 2 && loadingAccepted)) && (
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<LinearProgress sx={{ color: theme.palette.primary.main }} />
					</Box>
				)}
			</Paper>
			{tabSelected == 0 && !loadingPending && <PendingTable />}
			{tabSelected == 1 && !loadingRefused && <RefusedTable />}
			{tabSelected == 2 && !loadingAccepted && <AcceptedTable />}
			<Popover
				open={openCalendar}
				anchorEl={anchorElCalendar}
				onClose={handleCloseCalendar}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<ShowCalendarButton />
			</Popover>
			<Popover
				open={requestOpen}
				anchorEl={anchorElNewRequest}
				onClose={handleCloseNewRequest}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
			>
				{requestOpen && <NewRequest showCloseIcon={true} handleCloseNewRequest={handleCloseNewRequest} />}
			</Popover>
			{detailOpen && <Detail showCloseIcon={true} />}
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(Returns);
