import React, { Fragment, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
	Typography,
	Stack,
	CircularProgress,
	IconButton,
	Grid,
	TextField,
	Divider,
	InputAdornment,
	FormControlLabel,
	Switch,
	TextareaAutosize,
	Button,
	Tooltip,
	useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Add, Close, LanOutlined } from '@mui/icons-material';
import utils from 'components/utils';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Card from 'components7/Card';
import ProgressBar from '@ramonak/react-progress-bar';
import moment from 'moment';
import { grey } from '@mui/material/colors';

const SingleBudget = props => {
	const { budget } = props;
	const translate = utils.useI18N();
	const id = budget.id;
	const loading = useStoreState(state => state.budget.details.loading[id]);
	const load = useStoreActions(dispatch => dispatch.budget.details.load);
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const isEditable = iCan('OPERATE_ON_BUDGETS') && budget.editable;
	const [name, setName] = useState(budget.name);
	const [cig, setCig] = useState(budget.cig);
	const [dateFrom, setDateFrom] = useState(budget.validityDateFrom);
	const [dateTo, setDateTo] = useState(budget.validityDateTo);
	const [amount, setAmount] = useState(budget.amount);
	const [check, setCheck] = useState(budget.checkImmediatelly);
	const [note, setNote] = useState(budget.note || '');
	const [perc, setPerc] = useState(0);
	const [progressBarColor, setProgressBarColor] = useState('#fff');
	const [progressBarLabelAlignment, setProgressBarLabelAlignment] = useState('right');
	const [progressBarLabelColor, setProgressBarLabelColor] = useState('#fff');
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	const items = useStoreState(state => state.budget.list.items);
	const loadList = useStoreActions(dispatch => dispatch.budget.list.load);
	const stateDetail = useStoreState(state => state.budget.details.items[id]);
	const deleteBudget = useStoreActions(dispatch => dispatch.budget.deletee.sendRequest);
	const loadingDelete = useStoreState(state => state.budget.deletee.loading[id]);
	const updateBudget = useStoreActions(dispatch => dispatch.budget.update.sendRequest);
	const loadingUpdate = useStoreState(state => state.budget.update.loading[id]);
	const [newData, setNewData] = useState({
		id: budget.id,
		name: budget.name,
		cig: budget.cig,
		validityDateFrom: budget.validityDateFrom,
		validityDateTo: budget.validityDateTo,
		checkImmediatelly: budget.checkImmediatelly,
		note: budget.note || '',
		amount: budget.amount
	});

	const handleChangeBudget = (field, value) => {
		const newBudget = {
			id: budget.id,
			name: field === 'name' ? value : newData.name,
			cig: field === 'cig' ? value : newData.cig,
			validityDateFrom: field === 'validityDateFrom' ? value : newData.validityDateFrom,
			validityDateTo: field === 'validityDateTo' ? value : newData.validityDateTo,
			checkImmediatelly: field === 'checkImmediatelly' ? value : newData.checkImmediatelly,
			note: field === 'note' ? value : newData.note,
			amount: field === 'amount' ? value : newData.amount
		};
		setNewData(newBudget);
	};

	const [textAreaCount, settextAreaCount] = useState(100);
	const recalculate = e => {
		const charCount = e.target.value.length;
		const charLeft = 100 - charCount;
		settextAreaCount(charLeft);
	};
	const calculateNoteLength = note => {
		const charCount = note.length;
		return 100 - charCount;
	};

	const EndAdornment = ({ handleReset }) => (
		<IconButton sx={{ padding: theme.spacing(0.5) }} onClick={_ => handleChangeBudget(handleReset, '')}>
			<Close fontSize="small" />
		</IconButton>
	);

	const handleUpdateBudget = newBudget => {
		//Trim dei campi
		const newBudgetTrim = {
			id: newBudget.id,
			name: newBudget.name.trim(),
			cig: newBudget.cig.trim(),
			validityDateFrom: newBudget.validityDateFrom,
			validityDateTo: newBudget.validityDateTo,
			checkImmediatelly: newBudget.checkImmediatelly,
			note: newBudget.note.trim(),
			amount: newBudget.amount
		};
		updateBudget({ data: newBudgetTrim, id: newBudgetTrim.id });
	};

	useEffect(
		_ => {
			if (budget) {
				const effectivePerc = 100 - Math.ceil((100 * budget.residual) / budget.amount);
				const perc = Math.min(effectivePerc, 100);
				setPerc(perc);
				setProgressBarColor(perc === 100 ? '#f44336' : '#fff');
				setProgressBarLabelAlignment(perc > 10 ? 'right' : 'outside');
				// setProgressBarLabelColor(perc > 10 ? '#fff' : '#00529b');
			}
		},
		[budget]
	);

	useEffect(() => {
		if (id) load(id);
	}, [id]);

	const formatValue = value => {
		return Intl.NumberFormat('it-IT').format(value);
	};

	return (
		<Card title={budget.cig} backgroundColor="white" margin={`0 0 ${theme.spacing(2)} 0`}>
			{loading && (
				<Grid container spacing={2} justify="center">
					<Grid item xs={12}>
						<CircularProgress size={48} />
					</Grid>
				</Grid>
			)}
			{!loading && (
				<Grid container spacing={2} sx={{ marginTop: theme.spacing(1) }}>
					<Grid item xs={12}>
						<TextField
							id={`${id}_name`}
							value={newData.name}
							onChange={event => handleChangeBudget('name', event.target.value)}
							required
							fullWidth
							size="small"
							label="Nome"
							error={!newData.name}
							helperText={!newData.name && translate('common.formValidation.required')}
							disabled={!isEditable}
							InputProps={{
								endAdornment: <EndAdornment handleReset={'name'} />
							}}
							InputLabelProps={{ sx: { zIndex: 0 } }}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={`${id}_cig`}
							value={newData.cig}
							onChange={event => handleChangeBudget('cig', event.target.value)}
							required
							fullWidth
							size="small"
							label="Cig"
							error={!newData.cig}
							helperText={!newData.cig && translate('common.formValidation.required')}
							disabled={!isEditable}
							InputProps={{
								endAdornment: <EndAdornment handleReset={'cig'} />
							}}
							InputLabelProps={{ sx: { zIndex: 0 } }}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							id={`${id}_netOrderBook`}
							value={stateDetail ? formatValue(stateDetail.netOrderBook) : 0}
							size="small"
							label="Prenotazioni"
							disabled
							InputLabelProps={{ sx: { zIndex: 0 } }}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							id={`${id}_netOrderDelivered`}
							value={stateDetail ? formatValue(stateDetail.netOrderDelivered) : 0}
							size="small"
							label="Ord. consegnati"
							disabled
							InputLabelProps={{ sx: { zIndex: 0 } }}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							id={`${id}_netOrderToDeliver`}
							value={stateDetail ? formatValue(stateDetail.netOrderDelivery) : 0}
							size="small"
							label="Ord. da consegnare"
							disabled
							InputLabelProps={{ sx: { zIndex: 0 } }}
						/>
					</Grid>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<Grid item xs={12} sm={6}>
							<DatePicker
								id={`${id}_dateFrom`}
								value={moment(newData.validityDateFrom)}
								onChange={date => handleChangeBudget('validityDateFrom', date)}
								label="Dalla data: "
								disabled={!isEditable}
								error={!newData.validityDateFrom}
								helperText={!newData.validityDateFrom && translate('common.formValidation.required')}
								slotProps={{
									textField: {
										required: true, // cannot be just "required"
										size: 'small',
										fullWidth: mobile,
										InputLabelProps: { sx: { zIndex: 0 } }
									}
								}}
								sx={{ width: { xs: '100%', md: '149px', sm: '100%' } }}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<DatePicker
								id={`${id}_dateTo`}
								value={moment(newData.validityDateTo)}
								onChange={date => handleChangeBudget('validityDateTo', date)}
								label="Alla data: "
								disabled={!isEditable}
								error={!newData.validityDateTo}
								helperText={!newData.validityDateTo && translate('common.formValidation.required')}
								slotProps={{
									textField: {
										size: 'small',
										fullWidth: mobile,
										InputLabelProps: { sx: { zIndex: 0 } },
										required: true // cannot be just "required"
									}
								}}
								sx={{ width: { xs: '100%', md: '149px', sm: '100%' } }}
							/>
						</Grid>
					</LocalizationProvider>
					<Grid item xs={12} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={`${id}_amount`}
							value={newData.amount}
							onChange={event => handleChangeBudget('amount', event.target.value)}
							disabled={!isEditable}
							error={!newData.amount}
							helperText={!newData.amount && translate('common.formValidation.required')}
							InputProps={{
								startAdornment: <InputAdornment position="start">€</InputAdornment>,
								endAdornment: mobile && <EndAdornment handleReset={'amount'} />,
								inputProps: { min: 0 }
							}}
							required
							InputLabelProps={{ sx: { zIndex: 0 } }}
							type="number"
							fullWidth
							size="small"
							label="Budget iniziale"
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							id={`${id}_residual`}
							value={budget.residual}
							InputProps={{
								startAdornment: <InputAdornment position="start">€</InputAdornment>
							}}
							InputLabelProps={{ sx: { zIndex: 0 } }}
							type="number"
							fullWidth
							size="small"
							label="Budget residuo"
							disabled
						/>
					</Grid>
					<Grid item xs={12}>
						<ProgressBar
							bgColor={progressBarColor}
							borderRadius="20px"
							completed={perc}
							labelAlignment={progressBarLabelAlignment}
							labelColor={grey[900]}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Switch
									checked={newData.checkImmediatelly}
									onChange={event => handleChangeBudget('checkImmediatelly', event.target.checked)}
									disabled={!isEditable}
								/>
							}
							label="Controllo immediato sul budget"
							sx={{ paddingLeft: 2 }}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<TextareaAutosize
							id={`${id}_note`}
							value={newData.note}
							onChange={event => {
								handleChangeBudget('note', event.target.value);
								recalculate(event);
							}}
							maxLength={100}
							maxRows={5}
							minRows={5}
							disabled={!isEditable}
							style={{ width: '100%', resize: 'none' }}
							placeholder="Nota"
						/>
						<Typography variant="text" sx={{ marginTop: theme.spacing(1) }}>
							Inserire al massimo {budget.note ? calculateNoteLength(note) : textAreaCount} caratteri
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
						<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
							<Button variant="important" onClick={_ => deleteBudget(id)} disabled={loadingDelete}>
								{translate('common.delete')}
							</Button>
							<Button
								variant="light"
								onClick={_ => handleUpdateBudget(newData)}
								disabled={
									!newData.name ||
									!newData.cig ||
									!newData.validityDateFrom ||
									!moment(newData.validityDateFrom).isValid() ||
									!newData.validityDateTo ||
									!moment(newData.validityDateTo).isValid() ||
									!newData.amount
								}
							>
								{loadingUpdate ? <CircularProgress size={24} /> : translate('common.save')}
							</Button>
						</Stack>
					</Grid>
				</Grid>
			)}
		</Card>
	);
};

export default SingleBudget;
