import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DropzoneArea } from 'react-mui-dropzone';
import DepotSelector from './DepotSelector';
import utils from 'components/utils';

const styles = theme => ({
	modalLabels: {
		marginBottom: theme.spacing()
	}
});

const FileUploader = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.importt.loading);
	const depot = useStoreState(state => state.importt.depot);
	const upload = useStoreActions(dispatch => dispatch.importt.sendRequest);
	const setDepot = useStoreActions(dispatch => dispatch.importt.setDepot);
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const onConfirm = _ => {
		upload({ file, depot });
	};
	const getFileLimitExceedMessage = filesLimit =>
		translate('upload.fileLimitExceedMessage').replace('_NUM_', filesLimit);
	const getFileAddedMessage = fileName => translate('upload.fileAddedMessage').replace('_NAME_', fileName);
	const getFileRemovedMessage = fileName => translate('upload.fileRemovedMessage').replace('_NAME_', fileName);
	const getDropRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) =>
		translate('upload.dropRejectMessage').replace('_NAME_', rejectedFile.name);
	return (
		<Grid container style={{ marginTop: 16 }}>
			<Grid container item xs={12} style={{ marginBottom: 16, alignItems: 'center' }}>
				<Typography variant="titleSection" style={{ marginBottom: 0, paddingRight: '5px' }}>
					{translate('upload.selectDepot')}
				</Typography>
				<DepotSelector onChange={e => setDepot(e.target.value)} selectedDepotId={depot} />
			</Grid>
			{fileName === '' && (
				<Grid item xs={12}>
					<Typography variant="titleSection">{translate('upload.selectFile')}</Typography>
				</Grid>
			)}
			{fileName !== '' && (
				<Grid item xs={12}>
					<Typography className={classes.modalLabels} variant="body1">
						<strong>{translate('upload.fileAddedMessageSubtitle')}:</strong> {fileName}
					</Typography>
				</Grid>
			)}
			<Grid item xs={12}>
				<DropzoneArea
					acceptedFiles={['.csv']}
					showAlerts={['error']}
					filesLimit={1}
					maxFileSize={3000000}
					dropzoneText=""
					getFileLimitExceedMessage={getFileLimitExceedMessage}
					getFileAddedMessage={getFileAddedMessage}
					getFileRemovedMessage={getFileRemovedMessage}
					getDropRejectMessage={getDropRejectMessage}
					alertSnackbarProps={{
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'top'
						},
						autoHideDuration: 2000
					}}
					previewGridProps={{
						container: { spacing: 0, justify: 'center' },
						item: { xs: 4 }
					}}
					onDrop={async ([file]) => {
						var reader = new FileReader();
						reader.onload = function(e) {
							var contents = e.target.result;
							setFile(contents);
							setFileName(file.name);
						};
						reader.readAsText(file);
					}}
					onDelete={file => {
						setFile(null);
						setFileName('');
					}}
				/>
			</Grid>
			<Grid item xs={12} container justify="center" style={{ marginTop: 16 }}>
				<Grid item xs={12}>
					{loading && <CircularProgress size={24} />}
					{!loading && (
						<Button disabled={!file} disableElevation color="primary" variant="light" onClick={onConfirm}>
							{translate('common.continue')}
						</Button>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(FileUploader);
