import { action, actionOn, computed, debug } from 'easy-peasy';
import utils from './utils';

export default {
	anchorCartEl: null,
	setAnchorCartEl: action((state, payload) => {
		state.anchorCartEl = payload;
	}),
	closeCreateCartPopoverOnSave: actionOn(
		(actions, storeActions) => [storeActions.cart.create.sendRequest.successType],
		(state, target) => {
			state.anchorCartEl = null;
		}
	),
	anchorWlEl: null,
	setAnchorWlEl: action((state, payload) => {
		state.anchorWlEl = payload;
	}),
	closeCreateWlPopoverOnSave: actionOn(
		(actions, storeActions) => [storeActions.wishlist.create.sendRequest.successType],
		(state, target) => {
			state.anchorWlEl = null;
		}
	),
	userDepot: computed(
		[
			(state, storeState) => {
				if (storeState.me.personify.target.id !== '') return storeState.me.personify.target.mainDepot;
				else return storeState.me.data.mainDepot;
			}
		],
		d => d
	),
	availableDepots: computed(
		[
			(state, storeState) => {
				if (storeState.me.personify.target.id !== '') return storeState.me.personify.target.availableDepots;
				else return storeState.me.data.availableDepots;
			}
		],
		d => d
	),
	searchScope: computed([(state, storeState) => storeState.catalog.search.scope], s => s),
	items: [],
	promoList: [],
	warningPromo: false,
	modalOpen: false,
	setModalOpen: action((state, payload) => {
		state.modalOpen = payload;
		if (!payload) {
			state.warningPromo = false;
		}
	}),
	titlesInPromo: [],
	setWarningPromo: action((state, payload) => {
		let list = state.titlesInPromo;
		const promos = state.items.filter(item => item.promo !== null);
		promos.forEach(function(item) {
			list = [...list, ...item.promo];
		});
		state.promoList = promos;
		state.titlesInPromo = list.filter((item, index, arr) => arr.findIndex(elm => elm.id === item.id) === index);
		state.warningPromo = payload;
	}),
	resetWarning: action((state, payload) => {
		state.titlesInPromo = [];
		state.warningPromo = payload;
	}),
	resetPromoList: action((state, payload) => {
		state.promoList = [];
		state.warningPromo = false;
		state.titlesInPromo = [];
		state.items = [];
	}),
	depotForAll: '',
	selectDepotForAllOnUserSave: actionOn(
		(actions, storeActions) => [storeActions.me.save],
		(state, target) => {
			const { payload } = target;
			if (payload.availableDepots && payload.availableDepots.length > 0)
				state.depotForAll = payload.availableDepots[0].id;
		}
	),
	quantityForAll: 1,
	selectDepot: action((state, payload) => {
		const { ean, depot } = payload;
		const i = state.items.findIndex(itm => itm.ean === ean);
		if (i === -1) return;
		const item = state.items[i];
		const depotForAll = false;
		const updatedItem = utils.updateSingleItemDepot({ item, depot, depotForAll });
		state.items = state.items
			.slice(0, i)
			.concat(updatedItem)
			.concat(state.items.slice(i + 1));
	}),
	selectQuantity: action((state, payload) => {
		const { ean, quantity } = payload;
		const i = state.items.findIndex(itm => itm.ean === ean);
		if (i === -1) return;
		const item = debug(state).items[i];
		const qtyForAll = false;
		const updatedItem = utils.updateSingleItemQuantity({ item, quantity, qtyForAll });
		state.items = state.items
			.slice(0, i)
			.concat(updatedItem)
			.concat(state.items.slice(i + 1));
	}),
	selectDepotForAll: action((state, payload) => {
		state.depotForAll = payload;
		const depotForAll = true;
		state.items = state.items.map(item =>
			utils.updateSingleItemDepot({ item, depot: payload, depotForAll, fromAll: state.quantityForAll })
		);
	}),
	selectQuantityForAll: action((state, payload) => {
		state.quantityForAll = payload;
		const qtyForAll = true;
		state.items = state.items.map(item => utils.updateSingleItemQuantity({ item, quantity: payload, qtyForAll }));
	}),
	resetForAll: action((state, payload) => {
		state.quantityForAll = 1;
		state.depotForAll = '';
		state.items = [];
	}),
	remove: action((state, payload) => {
		const i = state.items.findIndex(itm => itm.ean === payload);
		if (i === -1) return;
		state.items = state.items.slice(0, i).concat(state.items.slice(i + 1));
	}),
	closeOnRemoveIfNothingIsSelected: actionOn(
		(actions, storeActions) => [actions.remove],
		(state, target) => {
			if (state.items.length === 0) {
				state.modalOpen = false;
			}
		}
	),
	closeModalOnAdd: actionOn(
		(actions, storeActions) => [
			storeActions.order.createMultiple.sendRequest.successType,
			storeActions.wishlist.add.sendRequest.successType
		],
		(state, target) => {
			state.modalOpen = false;
			state.quantityForAll = 1;
			state.depotForAll = '';
			state.items = [];
		}
	),
	CATALOG_SEARCH__saveSelectedOnAllSelection: actionOn(
		(actions, storeActions) => [
			storeActions.catalog.search.selectAllOrdinable,
			storeActions.catalog.search.deselectAll,
			storeActions.catalog.search.selectAllAdvanced
		],
		state => {
			if (debug(state).searchScope !== 'catalog') return;
			state.items = state.searchItems
				.filter(item => item.isSelected)
				.map(item =>
					utils.decorateSelectionItem({
						item,
						depots: { mainDepot: state.userDepot, availableDepots: state.availableDepots }
					})
				);
		}
	),
	CATALOG_SEARCH__saveSelectedOnToggleSelection: actionOn(
		(actions, storeActions) => [
			storeActions.catalog.search.toggleSelection,
			storeActions.catalog.search.selectItemFromUrl
		],
		(state, target) => {
			if (debug(state).searchScope !== 'catalog') return;
			const { payload } = target;
			const i = state.searchItems.findIndex(itm => itm.ean === payload);
			if (i === -1) return;
			const item = state.searchItems[i];
			if (item.isSelected) {
				state.items.push(
					utils.decorateSelectionItem({
						item,
						depots: { mainDepot: state.userDepot, availableDepots: state.availableDepots }
					})
				);
			} else {
				const idx = state.items.findIndex(itm => itm.ean === payload);
				state.items = state.items.slice(0, idx).concat(state.items.slice(idx + 1));
			}
			const selectedCount = state.searchItems.filter(r => r.isSelected).length;
			if (selectedCount === 0) state.modalOpen = false;
		}
	),
	searchItems: computed([(state, storeState) => storeState.catalog.search.results], results => results),
	allFasthub: computed(state => state.items.every(item => item.selection.depot.id === 'FS01')),
	noType100: computed(state => state.items.every(item => item.type !== 100)),
	noZ1: computed(state => state.items.every(item => item.noticeCode !== 'z1')),
	CATALOG_SEARCH__mergeStockResults: actionOn(
		(actions, storeActions) => [storeActions.catalog.search.mergeStockResults],
		(state, target) => {
			state.items = state.items.map(item => ({
				...item,
				inCart: target.payload[item.ean] ? target.payload[item.ean] : 0
			}));
		}
	)
};
