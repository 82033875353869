import React, { Fragment } from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CtaDetails } from './components/CtaDetails';
import HeadlessCard from 'components/Card/HeadlessCard';
import styles from './styles';

const Banners = ({ classes }) => {
	const loadingBanner = useStoreState(state => state.cms.banners.loading);
	const banners = useStoreState(state => state.cms.banners.data);
	return (
		<Fragment>
			{!loadingBanner && banners && (
				<Grid item container xs={12} className={classes.container}>
					{banners.map((banner, index) => (
						<HeadlessCard
							key={index}
							bodyStyle={{ padding: 20 }}
							style={{ minHeight: 100, backgroundColor: `${banner.textBackgroundColor.hex}` }}
						>
							<Grid container style={{ height: '100%' }}>
								<Grid item xs={3}></Grid>
								<Grid item container xs={6} justify="center">
									<Typography variant="h6" style={{ alignSelf: 'center' }} color="secondary">
										{banner.text}
									</Typography>
								</Grid>
								<Grid item container xs={3} justify="flex-end" style={{ alignContent: 'center' }}>
									{banner.ctaEnabled && <CtaDetails banner={banner} />}
								</Grid>
							</Grid>
						</HeadlessCard>
					))}
				</Grid>
			)}
		</Fragment>
	);
};
export default withStyles(styles, { withTheme: true })(Banners);
