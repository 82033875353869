import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UserGroupsSelector from 'components/UserGroupsSelector';
import utils from 'components/utils';

const UserGroups = props => {
	const { proposalId, onClose } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const loadingListAbilitation = useStoreState(state => state.proposals.detailUserGroup.loadingList);
	const addAbilitation = useStoreActions(dispatch => dispatch.proposals.detailUserGroup.add);
	const removeAbilitation = useStoreActions(dispatch => dispatch.proposals.detailUserGroup.remove);
	const detailsAbilitation = useStoreState(state => state.proposals.detailUserGroup.data);

	const toggleSelectionAbilitation = (id, checked) => {
		if (checked) addAbilitation({ id: proposalId, userGroupId: id });
		else removeAbilitation({ id: proposalId, userGroupId: id });
	};

	const getCbStatusAbilitation = id => {
		return detailsAbilitation.findIndex(item => item.id === id) !== -1;
	};

	const onCloseModal = _ => {
		onClose();
	};

	return (
		<UserGroupsSelector
			toggleSelectionAbilitation={toggleSelectionAbilitation}
			loadingListAbilitation={loadingListAbilitation}
			getCbStatusAbilitation={getCbStatusAbilitation}
			oneColumn={true}
			closeModal={onClose}
		/>
	);
};

export default UserGroups;
