import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DropzoneArea } from 'react-mui-dropzone';
import utils from 'components/utils';
import Alert from '@mui/lab/Alert';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		minWidth: 350
	},
	modalLabels: {
		marginBottom: theme.spacing()
	}
});

const FileUploader = ({ classes, id, onCloseModal }) => {
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.editorials.importt.loading);
	const upload = useStoreActions(dispatch => dispatch.editorials.importt.sendRequest);
	const setUploaderModalOpen = useStoreActions(dispatch => dispatch.editorials.importt.setUploaderModalOpen);
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [clicked, click] = useState(false);

	const onClose = _ => {
		setUploaderModalOpen(false);
		onCloseModal();
	};
	const onConfirm = _ => {
		upload({ file, id });
	};
	const getFileLimitExceedMessage = filesLimit =>
		translate('upload.fileLimitExceedMessage').replace('_NUM_', filesLimit);
	const getFileAddedMessage = fileName => translate('upload.fileAddedMessage').replace('_NAME_', fileName);
	const getFileRemovedMessage = fileName => translate('upload.fileRemovedMessage').replace('_NAME_', fileName);
	const getDropRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) =>
		translate('upload.dropRejectMessage').replace('_NAME_', rejectedFile.name);
	return (
		<Paper className={classes.root}>
			<Typography className={classes.modalLabels} variant="h4">
				{translate('upload.selectFile')}
			</Typography>
			{fileName !== '' && (
				<Typography className={classes.modalLabels} variant="caption">
					<strong>{translate('upload.fileAddedMessageSubtitle')}:</strong>
					<br /> {fileName}
				</Typography>
			)}
			<DropzoneArea
				acceptedFiles={['.csv']}
				showAlerts={['error']}
				filesLimit={1}
				maxFileSize={3000000}
				dropzoneText=""
				getFileLimitExceedMessage={getFileLimitExceedMessage}
				getFileAddedMessage={getFileAddedMessage}
				getFileRemovedMessage={getFileRemovedMessage}
				getDropRejectMessage={getDropRejectMessage}
				alertSnackbarProps={{
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'top'
					},
					autoHideDuration: 2000
				}}
				previewGridProps={{
					container: { spacing: 0, justifyContent: 'center' },
					item: { xs: 4 }
				}}
				onDrop={async ([file]) => {
					var reader = new FileReader();
					reader.onload = function(e) {
						var contents = e.target.result;
						setFile(contents);
						setFileName(file.name);
					};
					reader.readAsText(file);
				}}
			/>
			{clicked ? (
				<Grid style={{ marginTop: 16 }}>
					<Alert severity="error">
						<span>{translate('common.warningUpload')}</span>
					</Alert>
				</Grid>
			) : (
				''
			)}
			<Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
				<Grid item>
					<Button disableElevation variant="outlined" color="primary" onClick={onClose}>
						{translate('common.close')}
					</Button>
				</Grid>
				<Grid item>
					{loading && <CircularProgress size={24} />}
					{!loading && (
						<Button
							disabled={!file}
							disableElevation
							color="primary"
							variant="contained"
							onClick={() => (clicked ? onConfirm() : click(true))}
						>
							{clicked ? translate('common.ok') : translate('common.confirm')}
						</Button>
					)}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles, { withTheme: true })(FileUploader);
