import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import utils from 'components/utils';

const Selection = _ => {
	const iCan = utils.usePermissions();
	const useEpipoli = iCan('MANAGE_EPIPOLI');
	const searchLast = useStoreState(state => state.catalog.search.last);
	const promoSearch = searchLast && searchLast.filters ? searchLast.filters.selectedPromotion : false;
	const results = useStoreState(state => state.catalog.search.results);
	const deselectAll = useStoreActions(dispatch => dispatch.catalog.search.deselectAll);
	const selectAll = useStoreActions(dispatch => dispatch.catalog.search.selectAll);
	const selectAllAdvanced = useStoreActions(dispatch => dispatch.catalog.search.selectAllAdvanced);
	const items = results.filter(r => r.isSelected);
	const ordinables = results.filter(r => r.ordinable === 2);
	const toggleSelection = useStoreActions(dispatch => dispatch.catalog.search.toggleSelection);
	const paymentTypeId = utils.useUserData('paymentTypeId', [], false);
	const onSelectAll = event => {
		if (event.target.checked) {
			/* Non richiamo il toggleSelection ma il selectAll per gestire anche depositi e ordinabilità
			results.map(item => {
				const ean = item.ean;
				return toggleSelection(ean);
			});
			*/
			//selectAll();
			selectAllAdvanced({ paymentTypeId, useEpipoli });
		} else deselectAll();
	};
	return (
		<TableCell padding="checkbox" style={{ backgroundColor: promoSearch ? '#e67272' : '#fafafa' }}>
			<Checkbox
				color="primary"
				indeterminate={items.length > 0 && items.length < results.length}
				checked={results.length > 0 && items.length === ordinables.length}
				onChange={onSelectAll}
				inputProps={{ 'aria-label': 'select all rows' }}
			/>
		</TableCell>
	);
};

export default Selection;
