export default theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	},
	themaRoot: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%'
	}
});
