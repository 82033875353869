import k from 'k';
import moment from 'moment';
import utils from 'services/utils';

export default (period, showInactive) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const date = moment()
		.subtract(period, 'weeks')
		.format('L')
		.replace(new RegExp('/', 'g'), '-');
	const params = {
		date,
		status: showInactive
	};
	const qParams = utils.createQueryParams(params, false);
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/returns/carts?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
