import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Detail from './Detail';

const Proposals = props => {
	return (
		<Switch>
			<Route path="/proposals" exact={true}>
				<Home />
			</Route>
			<Route path="/proposals/detail/:id" exact={true}>
				<Detail />
			</Route>
		</Switch>
	);
};

export default Proposals;
