import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import { green, red } from '@mui/material/colors';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const EnabledCatalogComp = ({ enabledCatalog }) => {
	return (
		<StyledTableCell>
			{enabledCatalog && <CheckCircleOutlineRoundedIcon fontSize="small" style={{ color: green[300] }} />}
			{!enabledCatalog && <HighlightOffRoundedIcon fontSize="small" style={{ color: red[300] }} />}
		</StyledTableCell>
	);
};
const EnabledCatalog = memo(
	({ item }) => <EnabledCatalogComp enabledCatalog={item.enabledCatalog} />,
	(p, n) => p.item.enabledCatalog === n.item.enabledCatalog
);

export default EnabledCatalog;
