import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const DepotDescComp = ({ depot }) => {
	return <StyledTableCell>{utils.capitalizeAll(depot)}</StyledTableCell>;
};

const DepotDesc = memo(
	({ item }) => <DepotDescComp depot={item.selectedDepot} />,
	(p, n) => p.item.depots === n.item.selectedDepot
);

export default DepotDesc;
