import React, { Fragment, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import Popper from '@mui/material/Popper';
import NotificationsBox from './NotificationsBox';

const styles = theme => ({
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	}
});

const NotificationsButton = ({ classes }) => {
	const loading = useStoreState(state => state.notifications.count.loading);
	const count = useStoreState(state => state.notifications.count.value);
	const load = useStoreActions(dispatch => dispatch.notifications.count.load);
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperOpen, setPopperOpen] = useState(false);
	const togglePopper = _ => {
		setPopperOpen(!popperOpen);
	};
	const handleClickPopper = event => {
		setAnchorEl(event.currentTarget);
		togglePopper();
	};
	useEffect(
		_ => {
			const onResize = _ => {
				setPopperOpen(false);
				setAnchorEl(null);
			};
			window.addEventListener('resize', onResize);
			return _ => {
				window.removeEventListener('resize', onResize);
			};
		},
		[setPopperOpen, setAnchorEl]
	);
	// prettier-ignore
	useEffect(load, [load]);
	const handleClickAway = _ => setPopperOpen(false);
	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div style={{ display: 'flex' }}>
				{loading && <CircularProgress size={14} />}
				{!loading && (
					<Fragment>
						<IconButton disableRipple className={classes.noRipple} onClick={handleClickPopper}>
							<Badge badgeContent={count} color="error" invisible={count === 0}>
								<NotificationsIcon />
							</Badge>
						</IconButton>
						<Popper
							style={{ zIndex: 1101 }}
							open={popperOpen}
							anchorEl={anchorEl}
							placement="bottom-end"
							transition
						>
							<NotificationsBox />
						</Popper>
					</Fragment>
				)}
			</div>
		</ClickAwayListener>
	);
};

export default withStyles(styles, { withTheme: true })(NotificationsButton);
