export default theme => ({
	root: {
		display: 'flex'
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	elements: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	dividerAria: {
		margin: theme.spacing(2, 2)
	},
	marginAttributes: {
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(3)
		}
	}
});
