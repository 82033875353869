import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import utils from 'components/utils';

const defaultStyles = {
	paddingLeft: 4,
	paddingRight: 4
};

const Sortable = ({ id, label, additionalStyles }) => {
	const theme = useTheme();
	const order = useStoreState(state => state.conditionRequest.pendingList.orderDirection);
	const orderBy = useStoreState(state => state.conditionRequest.pendingList.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.conditionRequest.pendingList.setOrder);
	const translate = utils.useI18N();
	return (
		<TableCell
			align="left"
			style={{ ...defaultStyles, ...additionalStyles }}
			sortDirection={orderBy === id ? order : false}
			sx={{ background: theme.palette.custom.greytable }}
		>
			<TableSortLabel
				active={orderBy === id}
				direction={orderBy === id ? order : 'asc'}
				onClick={e => setOrder(id)}
			>
				{translate(label)}
			</TableSortLabel>
		</TableCell>
	);
};

export default Sortable;
