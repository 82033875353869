import { action } from 'easy-peasy';
import list from './list';
import all from './all';
import update from './update';
import remove from './remove';

export default {
	editModalOpen: false,
	setEditModalOpen: action((state, payload) => {
		state.editModalOpen = payload;
	}),
	list,
	all,
	update,
	remove
};
