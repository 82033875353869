export default {
	author: '',
	publisher: '',
	pubDateFrom: null,
	pubDateTo: null,
	serie: '',
	synopsis: '',
	genre: '',
	volume: '',
	illustratore: '',
	allWords: true,
	onlySpecial: false,
	onlyNew: false,
	excludeOutOfList: false,
	selectedScope: null,
	selectedSubScope: null,
	selectedPromotion: '',
	dewey: []
};
