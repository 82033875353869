import { action, actionOn, thunk } from 'easy-peasy';

export default {
	data: [],
	reset: action((state, payload) => {
		state.data = [];
	}),
	save: action((state, payload) => {
		state.data = payload;
	}),
	error: action((state, payload) => {}),
	loading: false,
	setLoading: actionOn(
		actions => actions.load.startType,
		state => {
			state.loading = true;
		}
	),
	unsetLoading: actionOn(
		actions => actions.load.type,
		state => {
			state.loading = false;
		}
	),
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		services.alert
			.getMessageTypes()
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('messageType.load');
			});
	})
};
