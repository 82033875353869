import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { Card } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const OpacCheck = ({ ean }) => {
	const loading = useStoreState(state => state.catalog.opacCheck.loading);
	const items = useStoreState(state => state.catalog.opacCheck.items);
	const total = useStoreState(state => state.catalog.opacCheck.total);
	const load = useStoreActions(dispatch => dispatch.catalog.opacCheck.load);
	const reset = useStoreActions(dispatch => dispatch.catalog.opacCheck.reset);
	const [expanded, setExpanded] = useState(false);
	const onAccordionChange = _ => {
		if (expanded) reset();
		else {
			load(ean);
		}
		setExpanded(!expanded);
	};
	return (
		<Card sx={{ marginTop: '16px', width: '100%' }}>
			<Accordion
				TransitionProps={{ unmountOnExit: true }}
				expanded={expanded}
				onChange={_ => onAccordionChange()}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						OPAC Check
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{expanded && loading && <CircularProgress size={24} />}
					{expanded && !loading && items.length === 0 && (
						<Typography variant="body1">Prodotto non presente nel catalogo del circuito</Typography>
					)}
					{expanded && !loading && items.length > 0 && (
						<TableContainer component={Paper}>
							<Table aria-label="opac check">
								<TableHead>
									<TableRow>
										<TableCell>Nome Biblioteca</TableCell>
										<TableCell>Numero Copie</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{items.map(item => (
										<TableRow key={item.key}>
											<TableCell>{item.key}</TableCell>
											<TableCell>{item.value}</TableCell>
										</TableRow>
									))}
									<TableRow style={{ backgroundColor: '#EDEDED' }}>
										<TableCell>Totale</TableCell>
										<TableCell>{total}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</AccordionDetails>
			</Accordion>
		</Card>
	);
};

export default OpacCheck;
