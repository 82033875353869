import { action, thunk, thunkOn, computed } from 'easy-peasy';
import keys from 'lodash/keys';

export default {
	items: [],
	save: action((state, payload) => {
		state.items = payload;
	}),
	reset: action((state, payload) => {
		state.items = [];
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	isAdding: false,
	setIsAdding: action((state, payload) => {
		state.isAdding = payload;
	}),
	totals: computed(state => {
		const { items } = state;
		const getTotal = prop =>
			keys(items)
				.map(k => items[k])
				.map(i => i.userBudgets.map(ub => ub[prop]).reduce((acc, cv) => acc + cv, 0))
				.reduce((acc, cv) => acc + cv, 0);
		return {
			amount: getTotal('amount'),
			residual: getTotal('residual')
		};
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.budget
			.getList()
			.then(data => {
				actions.save(data);
			})
			.catch(e => {
				getStoreActions().error('budget.list');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	reload: thunkOn(
		(actions, storeActions) => [
			storeActions.budget.create.sendRequest.successType,
			storeActions.budget.deletee.sendRequest.successType
		],
		async (actions, target, helpers) => {
			actions.setIsAdding(false);
			actions.reset();
			actions.load();
		}
	)
};
