import keys from 'lodash/keys';

const inner = data => {
	if (keys(data).length === 0) return [];
	const empty = {
		id: -1,
		label: '- Seleziona un elemento -',
		selected: true
	};
	const items = keys(data).map((key, index) => ({
		id: key,
		label: data[key],
		selected: index === 0 ? true : false // selezioni il primo elemento della lista (perchè eliminato elemento vuoto "empty")
	}));
	//	return [empty, ...items]; -> eliminato la selezione vuota per evitare l'errore nella ricerca classifiche
	return items;
};

const transformData = data => {
	return keys(data).map((key, index) => ({
		id: key,
		label: key,
		selected: index === 0,
		children: data[key] ? inner(data[key]) : []
	}));
};

export default transformData;
