import React, { Fragment, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { Hidden } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Drawer from '@mui/material/Drawer';
import ModalDetails from './components/ModalDetails';
import styles from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Promotions = ({ classes }) => {
	const loadingPromo = useStoreState(state => state.cms.promotions.loading);
	const promotions = useStoreState(state => state.cms.promotions.data);
	const modalOpen = useStoreState(state => state.cms.promotions.modalOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.cms.promotions.setModalOpen);
	const setOpenFromUrl = useStoreActions(dispatch => dispatch.cms.promotions.setOpenFromUrl);
	const [selectedItem, setSelectedItem] = useState(null);

	const params = useParams();
	const history = useHistory();

	const style = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	};

	useEffect(
		_ => {
			if (history.location.pathname.includes('/home/promotions') && params.name) {
				if (promotions) {
					const item = promotions.find(value => value.title === params.name);
					if (item) {
						setSelectedItem(item);
						setModalOpen(true);
					}
				}
			} else {
				setModalOpen(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[params, history, promotions]
	);
	const onOpenPromotions = item => {
		setModalOpen(true);
		setSelectedItem(item);
		setOpenFromUrl(false);
	};
	const onClose = () => {
		if (history.location.pathname.includes('/home/promotions')) {
			setModalOpen(false);
			history.push(`/`);
		}
	};
	return (
		<Fragment>
			{!loadingPromo && promotions && (
				<Grid item container xs={12} className={classes.container}>
					{promotions.length > 0 && (
						<Grid item xs={12} className={classes.promotionTitle}>
							<Typography style={{ fontSize: 20 }} variant="button" color="error">
								Promozioni
							</Typography>
						</Grid>
					)}
					{promotions.map((item, index) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							lg={2}
							key={index}
							style={{ marginBottom: 16 }}
							container
							justify="center"
						>
							<Paper elevation={3} className={classes.singlePromotion}>
								<Link to={`/home/promotions/${item.title}`}>
									<CardMedia
										style={{ height: '100%' }}
										onClick={_ => {
											onOpenPromotions(item, false);
										}}
										image={item.image.url}
										title={item.image.title}
									/>
								</Link>
							</Paper>
							<Grid item container xs={12} justify="center">
								<Typography variant="subtitle2" color="primary" style={{ cursor: 'pointer' }}>
									{item.visibleTitle}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
			)}
			<Hidden smDown>
				<Modal
					style={style}
					open={modalOpen}
					onClose={onClose}
					aria-labelledby="promo-modal"
					aria-describedby="promo-modal"
				>
					<div className="modal-content">
						<Fragment>
							{modalOpen && (
								<Fade in={modalOpen}>
									<ModalDetails selectedItem={selectedItem} onClose={onClose} />
								</Fade>
							)}
						</Fragment>
					</div>
				</Modal>
			</Hidden>
			<Hidden mdUp>
				<Drawer anchor="bottom" open={modalOpen} onClose={onClose}>
					<ModalDetails selectedItem={selectedItem} onClose={onClose} />
				</Drawer>
			</Hidden>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Promotions);
