import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';

const styles = theme => ({
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	}
});

const DeleteUserGroupsButton = ({ classes, active }) => {
	const translate = utils.useI18N();
	const deleteOpen = useStoreState(state => state.userGroups.deleteUserGroups.modalDeleteOpen);
	const setDeleteOpen = useStoreActions(dispatch => dispatch.userGroups.deleteUserGroups.openModalDelete);
	return (
		<Button
			className={classes.buttonsMargin}
			variant="outlined"
			onClick={_ => {
				setDeleteOpen(!deleteOpen);
			}}
			disabled={active}
		>
			{translate('userGroups.button.deleteUserGroup')}
		</Button>
	);
};

export default withStyles(styles, { withTheme: true })(DeleteUserGroupsButton);
