import { thunk, thunkOn } from 'easy-peasy';
import keys from 'lodash/keys';
import head from 'lodash/head';
import moment from 'moment';

export default {
	performUpdate: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		return services.me.updateSelectedBudget(payload).then(data => Promise.resolve(data));
	}),
	updateOnDelete: thunkOn(
		(actions, storeActions) => [storeActions.budget.deletee.sendRequest.successType],
		async (actions, target, helpers) => {
			// prettier-ignore
			const { getStoreState } = helpers;
			const { payload } = target;
			const selectedBudget = getStoreState().me.data.selectedBudget;
			if (selectedBudget !== payload) return;
			const budgetList = getStoreState().budget.list.items;
			const key = head(keys(budgetList));
			const cleanBudgetList = budgetList[key].userBudgets;
			const sortedBudgets = cleanBudgetList.sort(
				(a, b) => moment.unix(b.creationDate) - moment.unix(a.creationDate)
			);
			const firstBudget = head(sortedBudgets);
			actions.performUpdate(firstBudget);
		}
	),
	updateOnCreate: thunkOn(
		(actions, storeActions) => [storeActions.budget.create.sendRequest.successType],
		async (actions, target, helpers) => {
			// prettier-ignore
			const { getStoreState } = helpers;
			const { result } = target;
			const selectedBudget = getStoreState().me.data.selectedBudget;
			if (selectedBudget === null) return;
			actions.performUpdate(result);
		}
	)
};
