import { action, thunk, thunkOn } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.wishlist
			.getDepotsStock(payload)
			.then(data => {
				actions.save(data);
				return Promise.resolve(data.items);
			})
			.catch(e => {
				getStoreActions().error('error');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	reloadOnUnavailableDelete: thunkOn(
		(actions, storeActions) => [storeActions.wishlist.removeUnavailableItems.sendRequest.successType],
		async (actions, target, helpers) => {
			actions.load(target.payload.id);
		}
	),
	save: action((state, payload) => {
		state.items = payload;
	})
};
