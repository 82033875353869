import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import { green, red, yellow } from '@mui/material/colors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ExternalNotificationStatusComp = ({ executed }) => {
	const colorMap = {
		COMPLETELY_PERFORMED: green[300],
		PARTIALLY_PERFORMED: yellow[300],
		NOT_PERFORMED: red[300]
	};
	return (
		<StyledTableCell>
			<FiberManualRecordIcon fontSize="small" style={{ color: colorMap[executed] }} />
		</StyledTableCell>
	);
};
const ExternalNotificationStatus = memo(
	({ item }) => <ExternalNotificationStatusComp executed={item.externalNotificationStatus} />,
	(p, n) => p.item.externalNotificationStatus === n.item.externalNotificationStatus
);

export default ExternalNotificationStatus;
