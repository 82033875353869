export default theme => ({
	container: {
		maxWidth: 'calc(100vw - 110px)',
		margin: theme.spacing(4, 0),
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100vw'
		}
	},
	singleVideoContainer: {
		padding: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 0, 1, 0)
		}
	},
	singleVideo: {
		height: 'calc(100vh - 300px)',
		width: 'inherit'
	}
});
