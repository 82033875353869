import React from 'react';
import { useStoreState } from 'easy-peasy';
import TableBodyMUI from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import allCols from './cols';
import withColumns from '../columns';
import utils from 'components/utils';

const getStyle = type => {
	if (type === 100) {
		return {
			backgroundColor: '#D4AF37'
		};
	}
};

const CustomRow = ({ item, children }) => <TableRow style={getStyle(item.type)}>{children}</TableRow>;

const TableBody = ({ rows, visibleCols, status, type }) => {
	const order = useStoreState(state => state.wishlist.details.orderDirection);
	const orderBy = useStoreState(state => state.wishlist.details.orderBy);
	const cells = visibleCols.map(col => allCols.find(c => c.id === col));
	const sortedRows = utils.stableSort(rows, order, orderBy);
	const user = utils.useUserData('', null);
	const personify = useStoreState(state => state.me.personify);
	const isDeleted = status === 'INITIAL'; //Gli item delle WL non INITIAL non possono essere eliminate
	const checkboxEnabled =
		(type !== 'BOOK2C' && isDeleted && personify.target.id !== '') ||
		(personify.target.id === '' && user.userType !== '02');

	let list = [];
	sortedRows.map(item => {
		const deposito = item.depots.filter(d => d.id == item.depotCode && d.fes == true);

		if (deposito.length > 0) {
			let foundElement = false;
			list.map(element => {
				if (element.ean === item.ean && item.depotCode === element.depot) {
					element.quantityTot += item.quantity;
					foundElement = true;
				}
			});
			if (foundElement === false) {
				list.push({
					ean: item.ean,
					depot: item.depotCode,
					quantityTot: item.quantity,
					stock: deposito[0].stock
				});
			}
		}
	});
	console.log('sortedRows', sortedRows);

	return (
		<TableBodyMUI>
			{sortedRows.map((item, index) => (
				<CustomRow item={item} status={status} type={type} key={`row_${index}`}>
					{cells
						.filter(c => checkboxEnabled || (!checkboxEnabled && c.id !== 'selection'))
						.map(cell => cell.comp({ item, status, type, list }))}
				</CustomRow>
			))}
		</TableBodyMUI>
	);
};

export default withColumns(TableBody);
