import k from 'k';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import utils from 'services/utils';

export default ({ depotCode, shippingCode, position, warning, warningEnabled }) => {
	const params = { depotCode, shippingCode, position, warning, warningEnabled };
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify(pickBy(params, identity)),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/shipping/rule/items`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
