import store from 'store';

const returnRefused = history => {
	store.dispatch({
		type: '@action.returns.setTabSelected',
		payload: 1
	});
	history.push('/returns');
};

export default returnRefused;
