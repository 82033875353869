import list from './list';
import assignUsers from './assign-users';
import addUserGroup from './add-usergroup';
import deleteUserGroups from './delete-usergroups';
import detailsUserGroup from './details-usergroup';
import updateUserGroup from './update-usergroup';
import importUsers from './import-users';

export default {
	addUserGroup,
	deleteUserGroups,
	detailsUserGroup,
	list,
	assignUsers,
	updateUserGroup,
	importUsers
};
