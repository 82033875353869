import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const BundleNumberComp = ({ packages }) => {
	return <StyledTableCell>{packages}</StyledTableCell>;
};

const BundleNumber = memo(
	({ item }) => <BundleNumberComp packages={item.packages} />,
	(p, n) => p.item.packages === n.item.packages
);

export default BundleNumber;
