import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import {
	Button,
	Box,
	Card,
	CardContent,
	Checkbox,
	Icon,
	InputBase,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableSortLabel,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	Toolbar,
	Typography
} from '@mui/material';
import { Search, ReportProblem } from '@mui/icons-material';
import styles from './styles';
import { useParams, Link } from 'react-router-dom';
import NoResults from 'components/NoResults';
import Pagination from 'components/Pagination';
import Loader from 'components/Loader';
import StyledTableRow from 'components/Table/StyledTableRow';
import BackToButton from 'components/BackToButton';

const UserGroupsAssign = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const load = useStoreActions(dispatch => dispatch.userGroups.detailsUserGroup.load);
	const data = useStoreState(state => state.userGroups.detailsUserGroup.data);

	const theme = useTheme();
	const params = useParams();

	const search = useStoreActions(dispatch => dispatch.userGroups.assignUsers.search);
	const resetSearch = useStoreActions(dispatch => dispatch.userGroups.assignUsers.resetSearch);
	const users = useStoreState(state => state.userGroups.assignUsers.users);
	const loading = useStoreState(state => state.userGroups.assignUsers.loading);
	const token = useStoreState(state => state.userGroups.assignUsers.token);
	const setToken = useStoreActions(dispatch => dispatch.userGroups.assignUsers.setToken);
	const searchPerformed = useStoreState(state => state.userGroups.assignUsers.searchPerformed);
	const order = useStoreState(state => state.userGroups.assignUsers.orderDirection);
	const orderBy = useStoreState(state => state.userGroups.assignUsers.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.userGroups.assignUsers.setOrder);

	const selectedUsers = users.filter(user => user.isSelected);
	const toggleSelection = useStoreActions(dispatch => dispatch.userGroups.assignUsers.toggleSelection);
	const selectAll = useStoreActions(dispatch => dispatch.userGroups.assignUsers.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.userGroups.assignUsers.deselectAll);

	const [currentPage, setCurrentPage] = useState(1);
	const pageCount = users.length % 25 === 0 ? Math.floor(users.length / 25) : Math.floor(users.length / 25) + 1;

	//let rows = utils.stableSort(users, order, orderBy);
	//non usato utils.stableSort perche non ordina
	const orderUser = _ => {
		const val1 = order === 'asc' ? 1 : -1;
		return [...users].sort((a, b) => (a[orderBy] > b[orderBy] ? val1 : -val1));
	};
	let rows = orderUser(users);

	const onSelectAll = event => {
		event.target.checked ? selectAll() : deselectAll();
	};

	const update = useStoreActions(dispatch => dispatch.userGroups.assignUsers.update);
	const assign = _ => {
		update();
	};

	useEffect(
		_ => {
			resetSearch();
			if (params.id) load(params.id);
		},
		[params]
	);
	const SelectionAll = _ => {
		return (
			<TableCell padding="checkbox" style={{ backgroundColor: '#fafafa' }}>
				<Checkbox
					color="primary"
					indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
					checked={users.length === selectedUsers.length}
					onChange={onSelectAll}
					inputProps={{ 'aria-label': 'select all rows' }}
				/>
			</TableCell>
		);
	};
	const SelectionComp = ({ id, isSelected }) => {
		return (
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					onChange={_ => toggleSelection(id)}
					inputProps={{ 'aria-labelledby': id }}
					checked={isSelected}
				/>
			</TableCell>
		);
	};

	const Sortable = ({ id, label, width }) => {
		return (
			<TableCell align="left" sortDirection={orderBy == id ? order : 'asc'} width={width}>
				<TableSortLabel
					active={orderBy == id}
					direction={orderBy == id ? order : 'asc'}
					onClick={e => setOrder(id)}
				>
					{translate(`userGroups.common.${label}`)}
				</TableSortLabel>
			</TableCell>
		);
	};

	const handleSearchOnClick = event => {
		if (event.key === 'Enter' && token.length >= 3) search();
	};

	return (
		<Grid container spacing={2}>
			<Typography variant="h6">{data.name ? data.name : ''}</Typography>
			{/* 1 - SEARCH */}
			<Grid container item xs={12} className={classes.elements}>
				<Grid container item xs={12} sm={5}>
					<Grid container item xs={12} sm={8}>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<Search />
							</div>
							<InputBase
								placeholder={translate('common.search.search')}
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput
								}}
								value={token}
								onChange={e => setToken(e.target.value)}
								inputProps={{
									'aria-label': 'search',
									'data-testid': 'userGroup.search'
								}}
								onKeyPress={event => handleSearchOnClick(event)}
							/>
						</div>
					</Grid>
					<Grid container item sm={4} md={2}>
						<Button
							style={{ marginLeft: 8 }}
							disableElevation
							variant="contained"
							color="primary"
							disabled={loading || token.length < 3}
							onClick={_ => {
								search();
							}}
						>
							{translate('common.search.search')}
						</Button>
					</Grid>
				</Grid>

				<Grid container item sm={7} justifyContent="flex-end" className={classes.associate}>
					<BackToButton
						to={`/admin/usergroups/usergroup/${data.id}/${data.type}`}
						style={{ marginRight: '12px' }}
						label={`backToDetails`}
					/>
					<Button
						style={{ margin: '0 12px 0 0' }}
						variant="outlined"
						disabled={selectedUsers.length === 0}
						onClick={_ => assign()}
					>
						{translate('userGroups.button.updateAssociations')}
					</Button>
				</Grid>
			</Grid>
			{/* 3 - LOADING */}
			{loading && (
				<Grid item container xs={12} justifyContent="center" spacing={3} className={classes.elements}>
					<Loader />
				</Grid>
			)}
			{/* 4 - UPPER PAGINATION + TABLE */}
			{!loading && users.length > 0 && (
				<Fragment>
					<Grid item container xs={12} className={classes.elements}>
						<Pagination
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							step={25}
							pageCount={pageCount}
							totalItems={users.length}
							listStep={[25]}
						/>
					</Grid>
					<Grid item xs={12} md={12} container justifyContent="center" className={classes.elements}>
						<Paper className={classes.paper}>
							{selectedUsers.length > 0 && (
								<Toolbar>
									<Typography
										className={classes.title}
										color="inherit"
										variant="subtitle2"
										component="div"
									>
										{selectedUsers.length} {translate('common.selected')}
									</Typography>
								</Toolbar>
							)}
							<TableContainer>
								<Table
									stickyHeader
									aria-labelledby="items"
									size="small"
									padding="none"
									aria-label="result table"
								>
									<TableHead>
										<TableRow style={{ height: theme.spacing(5) }}>
											<SelectionAll />
											<Sortable id="usercode" key="usercode" label="userCode" width={'10%'} />
											<Sortable id="username" key="username" label="businessName" width={'40%'} />
											<Sortable id="city" key="city" label="city" width={'20%'} />
											<Sortable id="address" key="address" label="address" />
											<Sortable id="province" key="province" label="province" width={'5%'} />
										</TableRow>
									</TableHead>

									<TableBody>
										{rows
											.slice((currentPage - 1) * 25, (currentPage - 1) * 25 + 25)
											.map((user, index) => (
												<StyledTableRow key={`row_${index}`}>
													<SelectionComp
														id={user.usercode}
														isSelected={user.isSelected}
														isAlreadySelected={user.isAlreadySelected}
													/>
													<TableCell component="th" scope="row" align="left">
														{user.usercode}
													</TableCell>
													<TableCell component="th" scope="row" align="left">
														{user.username ? user.username.toUpperCase() : user.username}
													</TableCell>
													<TableCell component="th" scope="row" align="left">
														{user.city}
													</TableCell>
													<TableCell component="th" scope="row" align="left">
														{user.address}
													</TableCell>
													<TableCell component="th" scope="row" align="left">
														{user.province ? user.province.toUpperCase() : user.province}
													</TableCell>
												</StyledTableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
				</Fragment>
			)}
			{!loading && rows.length === 0 && searchPerformed && (
				<Grid item container xs={12} justifyContent="center" className={classes.elements}>
					<NoResults />
				</Grid>
			)}
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(UserGroupsAssign);
