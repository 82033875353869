import { action, thunk, computed, actionOn, debug } from 'easy-peasy';
import utils from 'store/admin/utils';
import storeUtils from 'store/utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	results: [],
	updatingItems: {
		eansToInsert: null,
		eansToDelete: null
	},
	detailsItems: computed([(state, storeState) => storeState.coupons.detailsItems.data], s => s),
	searchPerformed: false,
	setSearchPerformed: action((state, payload) => {
		state.searchPerformed = payload;
	}),
	token: '',
	setToken: action((state, payload) => {
		state.token = payload;
	}),
	saveResults: action((state, payload) => {
		const detailsItems = debug(state).detailsItems;
		state.results = payload.items.map(item => ({
			...item,
			depots: item.depots.filter(r => r.enable),
			isSelected: detailsItems.findIndex(i => i.ean === item.ean) !== -1,
			wasSelected: detailsItems.findIndex(i => i.ean === item.ean) !== -1,
			touched: false
		}));
	}),
	...storeUtils.createPagination({ step: 25, paginationTrigger: 'saveResults', loadFn: 'performSearch' }),
	performSearch: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const page = getState().pagination.current - 1;
		const step = getState().pagination.step;
		const token = getState().token;
		const { by, direction } = getStoreState().catalog.order;
		const params = {
			token,
			page,
			step,
			order: {
				by,
				direction
			}
		};
		return services.catalog
			.search(params)
			.then(data => {
				actions.setSearchPerformed(true);
				actions.saveResults(data);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('catalog.search');
			})
			.finally(_ => {
				actions.setLoading(false);
				getStoreActions().catalog.autocomplete.close();
			});
	}),
	toggleSelection: action((state, payload) => {
		const i = state.results.findIndex(itm => itm.ean === payload);
		if (i === -1) return;
		const item = state.results[i];
		item.isSelected = !item.isSelected;
		item.touched = item.isSelected !== item.wasSelected;
		state.results = state.results
			.slice(0, i)
			.concat(item)
			.concat(state.results.slice(i + 1));
	}),
	selectAll: action(state => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: true,
			touched: !item.wasSelected
		}));
	}),
	deselectAll: action(state => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: false,
			touched: item.wasSelected
		}));
	}),
	update: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		actions.setLoading(true);
		// prettier-ignore
		const { coupons: { assign: { results, updatingItems } } } = getStoreState();
		utils.assignToGroup(results, updatingItems);
		return services.coupons
			.updateAssignment(updatingItems, getStoreState().coupons.details.data.id)
			.then(_ => {
				updatingItems.eansToDelete = [];
				updatingItems.eansToInsert = [];
				getStoreActions().success('coupons.associated');
			})
			.catch(_ => {
				getStoreActions().error('coupons.errorUpdate');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	remove: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		actions.setLoading(true);
		const obj = {
			eansToInsert: '',
			eansToDelete: payload
		};
		return services.coupons
			.updateAssignment(obj, getStoreState().coupons.details.data.id)
			.then(_ => {
				getStoreActions().coupons.detailsItems.load(getStoreState().coupons.details.data.id);
				getStoreActions().success('coupons.associated');
			})
			.catch(_ => {
				getStoreActions().error('coupons.errorUpdate');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	reset: actionOn(
		(actions, storeActions) => [storeActions.coupons.assign.clearAll],
		state => {
			state.results = [];
		}
	),
	clearAll: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.coupons
			.updateAssignment(payload, getStoreState().coupons.details.data.id)
			.then(_ => {
				getStoreActions().coupons.detailsItems.load(getStoreState().coupons.details.data.id);
				getStoreActions().success('coupons.articlesDeleted');
			})
			.catch(_ => {
				getStoreActions().error('coupons.errorUpdate');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	orderBy: 'ean',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	})
};
