import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Loader from 'components/Loader';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';
import styles from './styles';

const AddModal = props => {
	const { classes, onClose, depotCode } = props;
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.shippingType.all.loading);
	const loadingUpdate = useStoreState(state => state.shippingType.update.loading);
	const list = useStoreState(state => state.shippingType.all.items);
	const load = useStoreActions(dispatch => dispatch.shippingType.all.load);
	const update = useStoreActions(dispatch => dispatch.shippingType.update.sendRequest);
	useEffect(load, [load]);
	return (
		<Paper className={classes.modalPaper}>
			<Grid container spacing={0} justify="space-between">
				<Grid item xs={10}>
					<Typography color="inherit" variant="h4">
						{`${translate('admin.withdrawTypes.addModalTitle')}: ${depotCode}`}
					</Typography>
				</Grid>
				<Grid container item xs={1} justify="flex-end">
					<IconButton className={classes.noRipple} onClick={onClose} style={{ paddingRight: 0 }}>
						<CloseIcon />
					</IconButton>
				</Grid>
			</Grid>
			<Divider classes={{ root: classes.dividerColor }} />
			{loading && (
				<Grid item container xs={12} justify="center" spacing={3} className={classes.elements}>
					<Loader />
				</Grid>
			)}
			{!loading && (
				<Grid item container style={{ marginTop: 40 }}>
					<TableContainer component={Paper} className={classes.container}>
						<Table aria-label="manage evasion" size="small">
							<TableHead>
								<TableRow>
									<TableCell>{translate('admin.withdrawTypes.codeType')}</TableCell>
									<TableCell>{translate('admin.withdrawTypes.descType')}</TableCell>
									<TableCell>&nbsp;</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{list.map(item => (
									<TableRow key={`depot_cell_${item.code}`}>
										<TableCell align="left">{item.code}</TableCell>
										<TableCell align="left">{item.desc}</TableCell>
										<TableCell align="left">
											{loadingUpdate === item.code && <CircularProgress size={20} />}
											{loadingUpdate !== item.code && (
												<IconButton
													style={{ padding: 0 }}
													color="primary"
													onClick={_ => {
														update({ depotCode: depotCode, shippingCode: item.code });
													}}
												>
													<AddIcon style={{ padding: 0 }} />
												</IconButton>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			)}
			<Grid item container xs={12} style={{ marginTop: 40 }}>
				<Grid item xs={12} style={{ textAlign: 'right' }}>
					<Button
						disableRipple
						style={{ marginLeft: 20 }}
						variant="outlined"
						color="primary"
						onClick={onClose}
					>
						{translate('common.cancel')}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};
export default withStyles(styles, { withTheme: true })(AddModal);
