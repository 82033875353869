import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory, useParams } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers';
import { withStyles, makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Card from 'components/Card';
import NewCard from 'components7/Card';
import Import from './components/Import';
import ImportPDF from './components/ImportPDF';
import AssignArticleBtn from './components/AssignArticleBtn';
import AssignUserGroup from './components/AssignUserGroup';
import { UserGroupsTableAbilitation } from './components/Table/UserGroupsTable';
import ItemsTable from './components/Table/ItemsTable';
import utils from 'components/utils';
import styles from './styles';
import BackToButton from 'components/BackToButton';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Stack } from '@mui/material';

const makeDatePickerStyles = makeStyles({
	root: {
		width: 180
	}
});

const CouponsDetails = ({ classes }) => {
	const history = useHistory();
	const translate = utils.useI18N();
	const load = useStoreActions(dispatch => dispatch.coupons.details.load);
	const data = useStoreState(state => state.coupons.details.data);
	const loadingUpdate = useStoreState(state => state.coupons.update.loading);
	const update = useStoreActions(dispatch => dispatch.coupons.update.sendRequest);
	const params = useParams();
	const setName = useStoreActions(dispatch => dispatch.coupons.details.setName);
	const setEnabled = useStoreActions(dispatch => dispatch.coupons.details.setEnabled);
	const setStartDate = useStoreActions(dispatch => dispatch.coupons.details.setStartDate);
	const setEndDate = useStoreActions(dispatch => dispatch.coupons.details.setEndDate);
	const download = useStoreActions(dispatch => dispatch.coupons.importt.download);
	const removePdf = useStoreActions(dispatch => dispatch.coupons.importt.removePdf);

	const datePickerStyles = makeDatePickerStyles();
	const now = moment();
	let isTodayExceeded = now.isAfter(data.endDate);
	const onSaveClick = _ => {
		update({
			history,
			sapId: params.id,
			data
		});
	};
	useEffect(
		_ => {
			load(params.id);
		},
		[load, params]
	);
	return (
		<Fragment>
			<Card type="primary" title={translate('coupons.title')} style={{ marginTop: 0 }}>
				<Grid item container xs={12} spacing={4}>
					{data && (
						<Fragment>
							<Grid item container xs={12} justifyContent={{ md: 'flex-start', xs: 'center' }}>
								<Grid container item xs={12}>
									<AssignUserGroup currentCoupon={data} />
									<Import id={data.id} />
									<ImportPDF id={data.id} />
									<AssignArticleBtn currentCoupon={data} />
									<BackToButton
										to={`/admin/coupons`}
										style={{ margin: '0 1 0 0' }}
										label={'backToCoupons'}
									/>
								</Grid>
							</Grid>
							<Grid xs={12} item>
								<NewCard backgroundColor="white" title={`${translate('rankings.attributes')}`}>
									<CardContent style={{ paddingBottom: 0 }}>
										<Grid container spacing={1}>
											<Grid item container xs={12}>
												<Grid container item md={12} xs={12} justifyContent="flex-start">
													{data.description && (
														<Typography
															align={'left'}
															variant={'overline'}
															style={{ marginBottom: '2%', fontWeight: 'bold' }}
														>
															{translate('coupons.description') + ': ' + data.description}
														</Typography>
													)}
													{data.attachFileId && (
														<Grid
															container
															item
															md={12}
															xs={12}
															justifyContent="flex-start"
														>
															<Typography
																align="left"
																variant="overline"
																style={{
																	marginBottom: '2%',
																	fontWeight: 'bold',
																	cursor: 'pointer'
																}}
																onClick={() => download(data.id)}
															>
																{translate('coupons.attachment') +
																	': ' +
																	data.attachFileId}
															</Typography>
															<DeleteIcon
																style={{ cursor: 'pointer' }}
																onClick={() => removePdf(data.id)}
															/>
														</Grid>
													)}
												</Grid>
												<Grid container item sm={3} xs={6} className={classes.marginAttributes}>
													<TextField
														value={data.name}
														InputLabelProps={{ shrink: true }}
														onChange={e => setName(e.target.value)}
														fullWidth={false}
														label={translate('coupons.name')}
													/>
												</Grid>
												<LocalizationProvider dateAdapter={AdapterMoment}>
													<Grid
														container
														item
														sm={3}
														xs={6}
														className={classes.marginAttributes}
													>
														<DateTimePicker
															className={datePickerStyles.root}
															label={translate('coupons.tables.startDate')}
															value={data.startDate ? moment(data.startDate) : null}
															onChange={setStartDate}
															autoOk
															ampm={false}
															format="D MMMM YYYY HH:mm"
															/*
														disabilito la possibilita di scegliere date passate
														ma non inserisco warning
														*/
															disablePast
															helperText=""
															error={false}
															slotProps={{
																textField: {
																	sx: {
																		width: '240px !important'
																	}
																}
															}}
														/>
													</Grid>
													<Grid
														container
														item
														md={3}
														xs={6}
														className={classes.marginAttributes}
													>
														<DateTimePicker
															className={datePickerStyles.root}
															label={translate('coupons.tables.endDate')}
															value={data.endDate ? moment(data.endDate) : null}
															onChange={setEndDate}
															autoOk
															ampm={false}
															format="D MMMM YYYY HH:mm"
															/*
														disabilito la possibilita di scegliere date passate
														ed inserisco warning con label
														*/
															disablePast
															error={isTodayExceeded}
															helperText={
																isTodayExceeded ? translate('common.dateExpired') : ''
															}
															slotProps={{
																textField: {
																	sx: {
																		width: '240px !important'
																	}
																}
															}}
														/>
													</Grid>
												</LocalizationProvider>

												<Grid
													container
													item
													md={2}
													sm={4}
													xs={6}
													justifyContent="center"
													alignItems={'center'}
												>
													<FormControlLabel
														value="start"
														control={
															<Checkbox
																color="primary"
																size="medium"
																checked={data.enabled}
																onChange={e => setEnabled(e.target.checked)}
															/>
														}
														label={translate('coupons.tables.status')}
														labelPlacement="end"
														style={{ margin: 0 }}
													/>
												</Grid>
											</Grid>
											<Grid container item xs={12} justifyContent="flex-end">
												{loadingUpdate && <CircularProgress size={16} />}
												{!loadingUpdate && (
													<Button variant="contained" color="primary" onClick={onSaveClick}>
														{translate('common.save')}
													</Button>
												)}
											</Grid>
										</Grid>
									</CardContent>
								</NewCard>
							</Grid>
							<Grid item container spacing={2}>
								<Grid item xs={12} md={6}>
									<NewCard backgroundColor="white" title="Gruppi di utenti assegnati">
										<CardContent style={{ padding: '8px 0' }}>
											<UserGroupsTableAbilitation />
										</CardContent>
									</NewCard>
								</Grid>
								<Grid item xs={12} md={6}>
									<NewCard backgroundColor="white" title="Articoli assegnati">
										<CardContent style={{ padding: '8px 0' }}>
											<ItemsTable />
										</CardContent>
									</NewCard>
								</Grid>
							</Grid>
						</Fragment>
					)}
				</Grid>
			</Card>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(CouponsDetails);
