import React, { useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
	Stack,
	Box,
	Typography,
	IconButton,
	TextField,
	Switch,
	FormControlLabel,
	CircularProgress,
	Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';
import moment from 'moment';

const CreateProposal = props => {
	const { onClose } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const useType = utils.useType();
	const loading = useStoreState(state => state.proposals.create.loading);
	const createProposal = useStoreActions(dispatch => dispatch.proposals.create.createProposal);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [dateFrom, setDateFrom] = useState(moment());
	const [dateTo, setDateTo] = useState(moment().add(30, 'd'));
	const [checked, setChecked] = useState(true);
	const isDisable =
		name.trim() === '' ||
		!dateFrom ||
		!moment(dateFrom).isValid() ||
		!dateTo ||
		!moment(dateTo).isValid() ||
		moment(dateFrom).isAfter(moment(dateTo));

	const handleClose = event => {
		onClose(event);
	};

	const handleEnabled = event => {
		setChecked(event.target.checked);
	};

	const onClick = event => {
		createProposal({
			companyId: 0,
			name: name,
			description: description,
			enabled: checked,
			startDate: dateFrom,
			endDate: dateTo,
			createdBy: useType
		});
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{
				width: '100%',
				maxWidth: { xs: '340px', md: '500px', sm: '500px' },
				minWidth: '340px',
				padding: '10px'
			}}
		>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '100%' }}>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: '90%' }}>
						<Typography variant="titleSection" sx={{ color: theme.palette.primary.main }}>
							{translate('proposals.create')}
						</Typography>
					</Box>
					<Box sx={{ width: '10%' }}>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Stack>
			</Stack>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={2}
				sx={{
					width: '100%',
					padding: '10px 5px 5px 5px',
					borderWidth: '1px 0 0 0',
					borderStyle: 'solid',
					borderColor: theme.palette.primary.main
				}}
			>
				<Box sx={{ width: '100%' }}>
					<TextField
						required
						value={name}
						error={!name || name.trim() === ''}
						onChange={e => {
							setName(e.target.value);
						}}
						label={translate('proposals.name')}
						fullWidth
						size="small"
					/>
				</Box>
				<Box sx={{ width: '100%' }}>
					<TextField
						required={false}
						value={description}
						//error={!description || description.trim() === ''}
						onChange={e => {
							setDescription(e.target.value);
						}}
						label={translate('proposals.description')}
						fullWidth
						size="small"
					/>
				</Box>
				{/*
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={2}
					sx={{ width: '100%' }}
				>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							value={dateFrom}
							format="DD MMMM YYYY"
							onChange={d => {
								setDateFrom(d);
							}}
							className="date-picker"
							label={translate('proposals.dateFrom')}
							fullWidth
							error={!dateFrom || !moment(dateFrom).isValid()}
							helperText={translate('common.dateInvalid')}
						/>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							value={dateTo}
							format="DD MMMM YYYY"
							onChange={d => {
								setDateTo(d);
							}}
							className="date-picker"
							label={translate('proposals.dateTo')}
							fullWidth
							error={!dateTo || !moment(dateTo).isValid() || moment(dateFrom).isAfter(moment(dateTo))}
							helperText={translate('common.dateInvalid')}
						/>
					</LocalizationProvider>
				</Stack>
				<Box sx={{ width: '100%' }}>
					<FormControlLabel
						control={<Switch checked={checked} onChange={handleEnabled} />}
						label={translate('proposals.enabled')}
					/>
				</Box>
				*/}
			</Stack>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				sx={{ width: '100%', marginTop: '30px !important' }}
			>
				<Button variant="dark" disabled={isDisable} onClick={onClick}>
					{loading ? (
						<CircularProgress size={24} sx={{ color: theme.palette.custom.white }} />
					) : (
						translate('common.save')
					)}
				</Button>
				<Button variant="light" onClick={handleClose}>
					{translate('common.cancel')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default CreateProposal;
