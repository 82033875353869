import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const MatchNumberComp = ({ match }) => {
	return <StyledTableCell>{match}</StyledTableCell>;
};

const MatchNum = memo(
	({ item }) => <MatchNumberComp match={item.lots} />,
	(p, n) => p.item.lots === n.item.lots
);

export default MatchNum;
