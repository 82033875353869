import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const DescriptionComp = ({ description }) => {
	return <StyledTableCell>{description}</StyledTableCell>;
};

const Description = memo(
	({ item }) => <DescriptionComp description={item.description} />,
	(p, n) => p.item.description === n.item.description
);

export default Description;
