import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styles from './styles';

const MenuToolbar = props => {
	const { classes } = props;
	const toggleMainOpen = useStoreActions(dispatch => dispatch.menu.toggleMainOpen);
	const closeAdmin = useStoreActions(dispatch => dispatch.menu.closeAdmin);
	const onClick = _ => {
		toggleMainOpen();
		closeAdmin();
	};
	return (
		<div className={classes.toolbar}>
			<Typography className={classes.pageMenu} variant="h6" color="inherit">
				Menu
			</Typography>
			<IconButton onClick={onClick}>
				<ChevronLeftIcon />
			</IconButton>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(MenuToolbar);
