import utils from 'utils';

export default theme => ({
	root: {
		flexDirection: 'row'
	},
	modalPaper: {
		justifyContent: 'center',
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	title: {
		margin: theme.spacing(2, 0)
	},
	subtitle: {
		marginBottom: theme.spacing(1)
	},
	elements: {
		padding: `${theme.spacing(2, 1, 2, 1)} !important`,
		alignSelf: 'center'
	},
	dividerAria: {
		margin: theme.spacing(2, 0)
	},
	dividerColor: {
		backgroundColor: `rgba(${utils.colors.hexToRgbString(theme.palette.primary.main)}, .62)`
	},
	container: {
		maxHeight: 300,
		overflowX: 'hidden'
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	textArea: {
		width: '100%',
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(0)
		}
	}
});
