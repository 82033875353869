import k from 'k';
import utils from 'services/utils';

export default (type, token) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};

	return utils
		.fetch(`${k.endpoints.apis}/thema/findlabel/?type=${type}&word=${token}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
