import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Card from 'components/Card';
import Loader from 'components/Loader';
import utils from 'components/utils';
import TableHead from './components/Table/TableHead';
import TableBody from './components/Table/TableBody';
import SearchButton from './components/SearchButton';
import AddCoupon from './components/AddCoupon';
import DeleteCouponsButton from './components/DeleteCouponsButton';
import Filters from './components/Filters';
import styles from './styles';
import CustomNoResult from './components/CustomNoResults';
import AddNewCoupon from './components/AddNewCoupon';
import DeleteCoupons from './components/DeleteCoupons';
import Chip from '@mui/material/Chip';

const Coupons = ({ classes }) => {
	const translate = utils.useI18N();
	const searchOpen = useStoreState(state => state.coupons.filters.searchOpen);
	const addNewOpen = useStoreState(state => state.coupons.add.open);
	const setSearchOpen = useStoreActions(dispatch => dispatch.coupons.filters.setSearchOpen);
	const loading = useStoreState(state => state.coupons.list.loading);
	const results = useStoreState(state => state.coupons.list.items);
	const filters = useStoreState(state => state.coupons.filters.list);
	const resetToken = useStoreActions(dispatch => dispatch.coupons.filters.resetToken);
	const resetStatus = useStoreActions(dispatch => dispatch.coupons.filters.resetStatus);
	const load = useStoreActions(dispatch => dispatch.coupons.list.load);
	const leftGridMdWidth = searchOpen || addNewOpen ? 7 : 12;
	const setSearchScope = useStoreActions(dispatch => dispatch.catalog.search.setScope);
	const items = results.filter(r => r.isSelected);
	// prettier-ignore
	useEffect(_ => {setSearchScope('admin.coupons');}, [setSearchScope]);
	useEffect(
		_ => {
			load();
		},
		[load]
	);
	return (
		<Fragment>
			<Grid item xs={12}>
				<Card
					type="primary"
					title={translate('common.menu.coupons')}
					style={{ marginTop: 0, marginBottom: '40px' }}
				>
					<Grid container spacing={3}>
						<Grid item container xs={12}>
							<SearchButton />
							<AddCoupon />
							<DeleteCouponsButton active={items.length === 0} />
							{filters.token !== '' && (
								<Chip
									size="small"
									label={filters.token}
									onDelete={_ => {
										resetToken();
										load();
									}}
								/>
							)}
							{filters.statusValue && (
								<Chip
									size="small"
									label={filters.statusValue ? translate('coupons.tables.status') + ' attivo' : ''}
									onDelete={_ => {
										resetStatus();
										load();
									}}
								/>
							)}
						</Grid>
						<Grid item container xs={12}>
							{loading && (
								<Grid
									item
									container
									xs={12}
									justifyContent="center"
									spacing={3}
									style={{ padding: '2px, 1px, 2px, 1px' }}
								>
									<Loader />
								</Grid>
							)}
							{/* SEARCH RESULTS */}
							{!loading && results.length > 0 && (
								<Grid item container xs={12} md={leftGridMdWidth}>
									{results.length > 0 && (
										<TableContainer component={Paper} style={{ maxHeight: 800 }}>
											<Table size="small" stickyHeader aria-label="coupons table">
												<TableHead />
												<TableBody rows={results} />
											</Table>
										</TableContainer>
									)}
								</Grid>
							)}
							{/* NO RESULTS */}
							{!loading && results.length === 0 && (
								<Grid item container xs={12} md={leftGridMdWidth} justifyContent="center">
									<CustomNoResult message="Non ci sono elementi" />
								</Grid>
							)}
							{/* DESKTOP FILTERS */}
							{searchOpen && (
								<Grid item md={5} className={classes.elements}>
									<Filters />
								</Grid>
							)}
							{/* DESKTOP FILTERS */}
							{addNewOpen && (
								<Grid item md={5} className={classes.elements}>
									<AddNewCoupon />
								</Grid>
							)}
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<DeleteCoupons />
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Coupons);
