import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { red } from '@mui/material/colors';
import globalUtils from 'utils';
import styles from './styles';
import { useStoreActions } from 'easy-peasy';

const Logout = ({ classes }) => {
	const redTheme = createTheme({ palette: { primary: red } });
	const setPopperOpen = useStoreActions(dispatch => dispatch.me.setPopperOpen);
	const onClick = _ => {
		setPopperOpen(false);
		globalUtils.logout();
	};
	return (
		<ThemeProvider theme={redTheme}>
			<Button onClick={onClick} variant="contained" color="primary" endIcon={<ExitToAppIcon />}>
				esci
			</Button>
		</ThemeProvider>
	);
};

export default withStyles(styles, { withTheme: true })(Logout);
