import React, { useEffect } from 'react';
import {
	Alert,
	Stack,
	Box,
	Button,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableBody,
	Divider,
	TableCell,
	Icon,
	Typography,
	Switch
} from '@mui/material';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import NotificationsIcon from '@mui/icons-material/Notifications';

const NotificationManager = _ => {
	const showAlert = useStoreState(state => state.me.channels.showAlert);
	const setShowAlert = useStoreActions(dispatch => dispatch.me.channels.setShowAlert);
	const subscribe = useStoreActions(dispatch => dispatch.me.channels.subscribe);
	const unsubscribe = useStoreActions(dispatch => dispatch.me.channels.unsubscribe);
	const alertAccepted = useStoreState(state => state.me.data.alertAccepted);
	const getChannels = useStoreActions(dispatch => dispatch.channels.load);
	const getMessageType = useStoreActions(dispatch => dispatch.messageType.load);
	const stateChannels = useStoreState(state => state.channels.data);
	const stateMessageTypes = useStoreState(state => state.messageType.data);
	useEffect(getChannels, [getChannels]);
	useEffect(getMessageType, [getMessageType]);
	const channels = Array.isArray(stateChannels) ? stateChannels : [];
	const messageTypes = Array.isArray(stateMessageTypes) ? stateMessageTypes : [];
	const iconMap = {
		fastlink: <NotificationsIcon />,
		whatsapp: <WhatsAppIcon />,
		email: <EmailIcon />,
		telegram: <TelegramIcon />
	};
	const onSwitch = ({ checked, channelId, messageType }) => {
		if (checked) {
			subscribe({ channelId, messageTypeId: messageType.idAlert });
		} else {
			const thisRow = alertAccepted.filter(al => al.messageTypeId === messageType.idAlert);
			if (thisRow.length > 1) {
				unsubscribe({ channelId, messageTypeId: messageType.idAlert });
			} else {
				setShowAlert(true);
			}
		}
	};
	return (
		<Stack direction="column" divider={<Divider />}>
			{showAlert && <Alert severity="error">Almeno un canale di comunicazione deve essere attivo</Alert>}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell id="Empty cell"></TableCell>
							{channels.map((channel, index) => (
								<TableCell key={index}>
									<Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
										<Icon>{iconMap[channel.iconId]}</Icon>
										<Typography variant="text">{channel.nameChannel}</Typography>
									</Stack>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{messageTypes.map((messageType, index) => (
							<TableRow key={index}>
								<TableCell>{messageType.nameAlert}</TableCell>
								{channels.map((channel, indexChannel) => (
									<TableCell key={indexChannel}>
										<Switch
											color="primary"
											size="small"
											checked={Boolean(
												alertAccepted.find(
													al =>
														al.channelId === channel.idChannel &&
														al.messageTypeId === messageType.idAlert
												)
											)}
											onChange={event =>
												onSwitch({
													checked: event.target.checked,
													channelId: channel.idChannel,
													messageType: messageType
												})
											}
										/>
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Stack>
	);
};
export default NotificationManager;
