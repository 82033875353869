import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import Loader from 'components/Loader';
import NoResults from 'components/NoResults';
import Pagination from './components/Pagination';
import SearchBar from './components/SearchBar';
import TableToolbar from './components/TableToolbar';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody';
import styles from './styles';
import Typography from '@mui/material/Typography';
import BackToButton from 'components/BackToButton';

const PromotionsAssign = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const loadingSearch = useStoreState(state => state.promotions.assign.loading);
	const results = useStoreState(state => state.promotions.assign.results);
	const searchPerformed = useStoreState(state => state.promotions.assign.searchPerformed);
	const search = useStoreActions(dispatch => dispatch.promotions.assign.performSearch);
	const update = useStoreActions(dispatch => dispatch.promotions.assign.update);
	const promo = useStoreState(state => state.promotions.details.data);
	utils.useEnterListener(search);
	const leftGridMdWidth = 12;
	const showToggleButton = !loadingSearch && results.length > 0 && searchPerformed;
	const showPagination = !loadingSearch && results.length > 0;
	const showNoResults = !loadingSearch && results.length === 0 && searchPerformed;
	const showResults = !loadingSearch && results.length > 0;
	const order = useStoreState(state => state.promotions.assign.orderDirection);
	const orderBy = useStoreState(state => state.promotions.assign.orderBy);
	//non usato utils.stableSort perche non ordina
	const orderUser = _ => {
		const val1 = order === 'asc' ? 1 : -1;
		return [...results].sort((a, b) => (a[orderBy] > b[orderBy] ? val1 : -val1));
	};
	let rows = orderUser(results);
	return (
		<Fragment>
			<Grid container spacing={0}>
				<Typography variant="h6">{promo ? promo.name : ''}</Typography>
				{/* 1 - SEARCH */}
				<Grid container item xs={12} className={classes.elements}>
					<Grid container item xs={12} sm={5}>
						<Grid container item xs={12} sm={8}>
							<SearchBar path="search" />
						</Grid>
						<Grid container item sm={4} md={2}>
							<Button
								style={{ marginLeft: 8 }}
								disableElevation
								variant="contained"
								color="primary"
								disabled={loadingSearch}
								onClick={_ => {
									search();
								}}
							>
								{translate('common.search.search')}
							</Button>
						</Grid>
					</Grid>
					<Fragment>
						<Grid container item sm={7} justifyContent="flex-end" className={classes.advancedSearchButton}>
							<BackToButton
								to={`/admin/promotions/${promo.sapPromoId}`}
								style={{ marginRight: '12px' }}
								label="backToDetails"
							/>

							<Button
								style={{ margin: '0 12px 0 0' }}
								variant="outlined"
								onClick={_ => update()}
								disabled={results.length === 0}
							>
								{translate('promotions.updateAssociations')}
							</Button>
						</Grid>
					</Fragment>
				</Grid>
				{/* 3 - UPPER PAGINATION (in its own grid) */}
				{showPagination && (
					<Grid item container xs={12} className={classes.elements}>
						<Pagination />
					</Grid>
				)}
				{/* 4 - LOADING */}
				{loadingSearch && (
					<Grid item container xs={12} justifyContent="center" spacing={3} className={classes.elements}>
						<Loader />
					</Grid>
				)}
				{/* 5 - NO RESULTS */}
				{showNoResults && (
					<Grid item container xs={12} justifyContent="center" className={classes.elements}>
						<NoResults />
					</Grid>
				)}
				{/* 8 - RESULTS */}
				{showResults && !loadingSearch && (
					<Grid
						item
						xs={12}
						md={leftGridMdWidth}
						container
						justifyContent="center"
						className={classes.elements}
					>
						<Paper className={classes.paper}>
							<TableToolbar />
							<TableContainer>
								<Table
									stickyHeader
									className={classes.table}
									aria-labelledby="searchResults"
									size="small"
									padding="none"
									aria-label="result table"
								>
									<TableHead />
									<TableBody rows={rows} />
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
				)}
				{/* 10 - LOWER PAGINATION (in its own grid) */}
				{showPagination && (
					<Grid item container xs={12} className={classes.elements}>
						<Pagination />
					</Grid>
				)}
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(PromotionsAssign);
