import React, { memo } from 'react';
import { useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import utils from 'components/utils';

const SelectionComp = ({ id, active, ordinable, type, wltype, status }) => {
	const toggleSelection = useStoreActions(dispatch => dispatch.wishlist.details.toggleSelection);
	const translate = utils.useI18N();
	const isOrderable = ordinable === 2;
	const isBook2C = wltype === 'BOOK2C';
	const isDeleted = status === 'INITIAL';

	return (
		<TableCell padding="checkbox">
			{//L'icona del prodotto non ordinabile è stata eliminata perchè il titolo deve comunque poter essere eliminato e in caso di trasformazione in carrello sono segnalati eventuali titoli non ordinabili
			//isOrderable && !isBook2C && (
			!isBook2C && isDeleted && (
				<Checkbox
					color="primary"
					onChange={e => toggleSelection(id)}
					checked={active}
					inputProps={{ 'aria-labelledby': id }}
					//disabled={!isOrderable}
				/>
			)}
			{
				//L'icona del prodotto non ordinabile è stata eliminata perchè il titolo deve comunque poter essere eliminato e in caso di trasformazione in carrello sono segnalati eventuali titoli non ordinabili
				/*
				!isOrderable && (
					<Tooltip title={translate('catalog.fastAddUnable')}>
						<IconButton aria-label="product not orderable" style={{ paddingLeft: 2 }}>
							<RemoveShoppingCartIcon fontSize="small" />
						</IconButton>
					</Tooltip>				
				)
				*/
			}
		</TableCell>
	);
};

const Selection = memo(
	({ item, wltype, status }) => (
		<SelectionComp
			id={item.id}
			active={item.isSelected}
			ordinable={item.ordinable}
			type={item.type}
			wltype={wltype}
			status={status}
		/>
	),
	(p, n) =>
		p.item.id === n.item.id &&
		p.item.isSelected === n.item.isSelected &&
		p.selected === n.selected &&
		p.item.type === n.item.type &&
		p.item.ordinable === n.item.ordinable &&
		p.wltype === n.wltype &&
		p.status === n.status
);

export default Selection;
