import getList from './get-list';
import getDetails from './get-details';
import update from './update';
import deletee from './delete';
import create from './create';

export default {
	getList,
	getDetails,
	update,
	deletee,
	create
};
