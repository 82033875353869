const list = {};

const abort = {
	subscribe: id => {
		const controller = new AbortController();
		list[id] = controller;
		return controller;
	},
	unsubscribe: idList => {
		idList.forEach(id => {
			const ctrl = list[id];
			if (!ctrl) {
				console.warn(`Trying to unsubscribe to wrong controller: ${id}`);
				return;
			}
			ctrl.abort();
			delete list[id];
		});
	}
};

export default abort;
