import React, { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Button, IconButton, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SearchIcon from '@mui/icons-material/Search';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CloseIcon from '@mui/icons-material/Close';
import FilterUI from 'routes/Catalog/Search/components/Filters/components/FilterUI';
import utils from 'components/utils';

const DetailItemsAssignFilter = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const loading = useStoreState(state => state.proposals.detailItemsAssign.loading);
	const search = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.performSearch);
	const setOpenFilters = useStoreActions(dispatch => dispatch.proposals.filters.setOpenFilters);
	const resetFilters = useStoreActions(dispatch => dispatch.catalog.filters.reset);
	const selectPromotion = useStoreActions(dispatch => dispatch.catalog.promotions.select);

	const resetFilter = _ => {
		resetFilters();
		selectPromotion('');
		search();
	};

	return (
		<Stack
			direction="column"
			justifyContent="center"
			alignItems="center"
			spacing={1}
			sx={{
				backgroundColor: '#fff',
				width: { xs: '100%', md: '80%', sm: '100%' },
				padding: '10px',
				margin: { xs: '0 auto', md: '20px auto 0', sm: '0 auto' }
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					borderWidth: '0 0 1px 0',
					borderStyle: 'solid',
					borderColor: theme.palette.custom.black
				}}
			>
				<Typography variant="titleTab">{translate('common.filter')}</Typography>
				<IconButton onClick={_ => setOpenFilters(false)} disabled={loading}>
					<CloseIcon />
				</IconButton>
			</Stack>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={2}
				sx={{ width: '100%' }}
			>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<FilterUI />
				</LocalizationProvider>
			</Stack>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={1}
				sx={{ width: '100%', marginTop: '40px !important' }}
			>
				{loading && <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />}
				{!loading && (
					<Button
						variant="dark"
						onClick={_ => {
							search();
						}}
						endIcon={<SearchIcon />}
						sx={{
							height: '55px',
							padding: '7px 16px !important'
						}}
					>
						{translate('common.search.search')}
					</Button>
				)}
				<Button
					variant="light"
					onClick={resetFilter}
					endIcon={<RotateLeftIcon />}
					disabled={loading}
					sx={{
						height: '55px',
						padding: '7px 16px !important'
					}}
				>
					{translate('common.reset')}
				</Button>
				<Button
					variant="light"
					onClick={_ => setOpenFilters(false)}
					endIcon={<CloseIcon />}
					disabled={loading}
					sx={{
						height: '55px',
						padding: '7px 16px !important'
					}}
				>
					{translate('common.close')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default DetailItemsAssignFilter;
