import k from 'k';
import utils from 'services/utils';

export default payload => {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/notification/assistanceAndTecnicalSupport/send`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
