import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/lab/Alert';
import utils from 'components/utils';
import styles from './styles';

const NotificationsBox = ({ classes }) => {
	const items = useStoreState(state => state.notifications.listLight.items);
	const load = useStoreActions(dispatch => dispatch.notifications.listLight.load);
	const translate = utils.useI18N();
	// prettier-ignore
	useEffect(load, [load]);
	return (
		<Card className={classes.card}>
			<CardContent className={classes.content}>
				<Grid container spacing={2}>
					{items.map(item => (
						<Grid item xs={12} key={item.id} className={classes.notificationItem}>
							<Alert
								variant="filled"
								severity="info"
								action={
									<Button component={Link} color="primary" size="small" to="/notifications">
										VAI
									</Button>
								}
							>
								{item.subject}
							</Alert>
						</Grid>
					))}
					{items.length === 0 && (
						<Grid container item xs={12} justify="center">
							<Typography variant="body1">Non ci sono nuove notifiche</Typography>
						</Grid>
					)}
					<Grid container item xs={12} justify="center">
						<Button component={Link} variant="outlined" color="primary" to="/notifications">
							{translate('notifications.viewAll')}
						</Button>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default withStyles(styles, { withTheme: true })(NotificationsBox);
