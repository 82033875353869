import getMe from './get-me';
import updateAvatar from './update-avatar';
import deleteAvatar from './delete-avatar';
import updateMe from './update-me';
import subscribeChannelToMessageType from './subscribe-channel-to-message-type';
import unsubscribeChannelToMessageType from './unsubscribe-channel-to-message-type';
import updateColumns from './update-columns';
import updateRows from './update-rows';
import loadPermissions from './load-permissions';
import personify from './personify';
import listPersonifyUsers from './list-personify-users';
import updateSapInfo from './update-sap-info';
import getLastCreditNote from './get-last-credit-note';
import getLastRiba from './get-last-riba';
import getLastBill from './get-last-bill';
import getLastInvoice from './get-last-invoice';
import getTermsConditions from './get-terms-conditions';
import getLastAccompanyingInvoice from './get-last-accompanying-invoice';
import updateNewsletter from './update-newsletter';
import updateSelectedBudget from './update-selected-budget';
import getAlertAccepted from './get-alert-accepted';
import getAbilitation from './get-abilitation';

export default {
	getMe,
	updateAvatar,
	deleteAvatar,
	updateMe,
	subscribeChannelToMessageType,
	unsubscribeChannelToMessageType,
	updateColumns,
	updateRows,
	loadPermissions,
	personify,
	listPersonifyUsers,
	updateSapInfo,
	getLastCreditNote,
	getLastRiba,
	getLastBill,
	getLastInvoice,
	getTermsConditions,
	getLastAccompanyingInvoice,
	updateNewsletter,
	updateSelectedBudget,
	getAlertAccepted,
	getAbilitation
};
