import { actionOn, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: actionOn(
		actions => actions.performUpdate.startType,
		state => {
			state.loading = true;
		}
	),
	unsetLoading: actionOn(
		actions => actions.performUpdate.type,
		state => {
			state.loading = false;
		}
	),
	performUpdate: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		const { history } = payload;
		return services.me
			.updateMe(payload)
			.then(data => {
				getStoreActions().success('me.updateMe');
				history.push('/');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('me.updateMe');
			});
	})
};
