import k from 'k';
import utils from 'services/utils';

export default ({ orders, successMessage, history, fcd }) => {
	const body = orders.map(o => ({
		...o,
		transferType: fcd ? 'FCD' : null
	}));
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(body),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/order/multiple`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
