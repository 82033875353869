import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.purchasings
			.update(payload)
			.then(data => {
				getStoreActions().success('purchasing.update');
				return Promise.resolve(data.items);
			})
			.catch(e => {
				getStoreActions().error('purchasing.update');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
