import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { PaymentElement, LinkAuthenticationElement, useStripe, useElements } from '@stripe/react-stripe-js';
import utils from 'components/utils';
import './stripe.css';

const CheckoutStripeForm = props => {
	const { payload } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const stripe = useStripe();
	const elements = useElements();
	const clientSecret = useStoreState(state => state.stripe.clientSecret);
	const paymentElementOptions = { layout: 'tabs' };
	const returnUrl = process.env.REACT_APP_STRIPE_RETURN + '/fulfillment';
	const [message, setMessage] = useState(null);
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const createSessionPayload = _ => {
		const sessionPayload = {
			depots: JSON.stringify(payload.depots),
			carts: JSON.stringify(payload.carts),
			ownerUserCode: JSON.stringify(payload.ownerUserCode),
			paymentType: JSON.stringify(payload.paymentType),
			totalFulfillment: JSON.stringify(payload.totalFulfillment)
		};
		return JSON.stringify(sessionPayload);
	};

	useEffect(() => {
		//Se non è stato caricato l'oggetto stripe o non c'è il clientSecret esco
		if (!stripe || !clientSecret) return;

		//Gestione esito pagamento
		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			console.log('**** PAYMENTINTENT', paymentIntent);
			switch (paymentIntent.status) {
				case 'succeeded':
					setMessage('stripe.succeeded');
					break;
				case 'processing':
					setMessage('stripe.processing');
					break;
				case 'requires_payment_method':
					setMessage('stripe.requiresPaymentMethod');
					break;
				default:
					setMessage('stripe.somethingWrong');
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async e => {
		e.preventDefault();

		//Se non sono stati caricati gli oggetti stripe o il payload dell'evasione non fa nulla
		if (!stripe || !elements || !payload) return;

		//Carico client secret e payload in sessione per poterli recuperare al ritorno dal pagamento
		sessionStorage.setItem('client_secret_fulfillment', JSON.stringify(clientSecret));
		const jsonPayload = createSessionPayload();
		console.log('Carico in sessione il payload', jsonPayload);
		sessionStorage.setItem('payload_fulfillment', jsonPayload);

		setIsLoading(true);

		//Gestione ritorno da Stripe: redirect all'url di operazione completata
		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: returnUrl,
				receipt_email: email
			}
		});

		//In caso di errore non fa il redirect (questa sezione è raggiunta solo se ci sono errori altrimenti va al returnUrl)
		if (error.type === 'card_error' || error.type === 'validation_error') {
			console.log('ERRORE', error.message);
			setMessage('stripe.cardError');
		} else setMessage('stripe.somethingWrong');

		setIsLoading(false);
	};

	const handleEmail = e => {
		setEmail(e.value.email);
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			{message && <div id="payment-message">{translate(message)}</div>}
			<LinkAuthenticationElement id="link-authentication-element" onChange={e => handleEmail(e)} />
			<PaymentElement id="payment-element" options={paymentElementOptions} />
			<button className="stripeButton" disabled={isLoading || !stripe || !elements} id="submit">
				<span id="button-text">
					{isLoading ? <div className="spinner" id="spinner"></div> : translate('stripe.payNow')}
				</span>
			</button>
		</form>
	);
};

export default CheckoutStripeForm;
