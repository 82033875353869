import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const AmountSIComp = ({ text }) => {
	return <StyledTableCell>{utils.formatPrice(text)}</StyledTableCell>;
};

const AmountSI = memo(
	({ item }) => <AmountSIComp text={item.netAmount} />,
	(p, n) => p.item.netAmount === n.item.netAmount
);

export default AmountSI;
