import React, { memo } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { orange } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import StyledTableCell from 'components/Table/StyledTableCell';
import WarningIcon from '@mui/icons-material/Warning';
import utils from 'components/utils';
import NumericStepper from 'components/NumericStepper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
	custom: {
		'&:hover': {
			background: 'none'
		}
	}
});

const QuantityComp = ({ classes, ean, selection, inCart, id, depots, type }) => {
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.catalog.search.loadingGetStock);
	const selectQuantity = useStoreActions(dispatch => dispatch.selection.selectQuantity);
	const fesOnDepot = depot => {
		const activeFESOnDepots = depot.find(d => d.fes);
		return activeFESOnDepots ? 1 : 0;
	};
	const getMaxValue = _ => {
		const filteredFESDepots = fesOnDepot(depots);
		if (filteredFESDepots === 1 && selection.quantity >= selection.depot.stock) {
			if (selection.depot.stock > 999) return 999;
			return selection.depot.stock;
		}
		if (type === 200) return 20;

		return 999;
	};

	return (
		<StyledTableCell style={{ minWidth: 130, maxWidth: 130, width: 130 }} id={`popperAnchorableId.${id}`}>
			<Grid container spacing={0}>
				<Grid item style={{ width: 80 }}>
					<NumericStepper
						removeDot
						precision={0}
						value={selection.quantity}
						onChange={numberAsValue => selectQuantity({ ean, quantity: numberAsValue })}
						max={getMaxValue()}
						min={1}
						strict
					/>
					{!loading && (
						<Typography color="inherit" variant="body2">
							{translate('catalog.selection.alreadyOrdered')}: {inCart}
						</Typography>
					)}
					{loading && <CircularProgress style={{ marginLeft: 25 }} size={12} />}
				</Grid>
				{selection.warning1000 && (
					<Grid item container xs={12}>
						<WarningIcon fontSize="small" style={{ color: orange[500], marginRight: 8 }} />
						<Typography variant="subtitle2" color="error">
							{translate('catalog.selection.orderableLimit') + getMaxValue()}
						</Typography>
					</Grid>
				)}
				{selection.warningFES && (
					<Grid item container xs={12}>
						<WarningIcon fontSize="small" style={{ color: orange[500], marginRight: 8 }} />
						<Typography variant="subtitle2" color="error">
							{translate('catalog.selection.warningFES')}
						</Typography>
					</Grid>
				)}
			</Grid>
		</StyledTableCell>
	);
};

const StyledQuantityComp = withStyles(styles, { withTheme: true })(QuantityComp);

const Quantity = memo(
	({ item, id, depots }) => (
		<StyledQuantityComp
			selection={item.selection}
			ean={item.ean}
			inCart={item.inCart}
			id={id}
			depots={depots}
			type={item.type}
		/>
	),
	(p, n) =>
		p.item.selection.quantity === n.item.selection.quantity &&
		p.item.selection.warning1000 === n.item.selection.warning1000 &&
		p.item.selection.warningFES === n.item.selection.warningFES &&
		p.id === n.id &&
		p.item.inCart === n.item.inCart &&
		p.item.type === n.item.type
);

export default Quantity;
