export default theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1)
	},
	title: {
		flex: '1 1 100%'
	},
	gridSpacing: {
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 0, 0, 0)
		}
	},
	findButton: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			marginLeft: theme.spacing(0)
		}
	},
	resetButton: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(0, 0, 0, 1)
		}
	}
});
