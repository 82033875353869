import { alpha } from '@mui/material/styles';

export default theme => ({
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.black, 0.25)
		},
		width: '100%',
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1),
			width: 'auto'
		},
		padding: theme.spacing(0, 2)
	},
	searchIcon: {
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit',
		width: '100%'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%'
	},
	noResults: {
		marginTop: `calc(1em + ${theme.spacing(4)}px)`,
		maxWidth: '400px'
	},
	cardMessageContent: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	mobileCellsPadding: {
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 0),
			textAlign: 'center'
		}
	}
});
