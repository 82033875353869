import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';

const styles = theme => ({
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	}
});

const DeletePurchasingCentersButton = ({ classes, active }) => {
	const translate = utils.useI18N();
	const modalOpen = useStoreState(state => state.purchasings.deletePurchasings.modalDeleteOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.purchasings.deletePurchasings.openModalDelete);
	return (
		<Button
			className={classes.buttonsMargin}
			variant="outlined"
			onClick={_ => {
				setModalOpen(!modalOpen);
			}}
			disabled={active}
		>
			{translate('purchasings.buttons.deletePurchasingCenter')}
		</Button>
	);
};

export default withStyles(styles, { withTheme: true })(DeletePurchasingCentersButton);
