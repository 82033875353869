import React, { Fragment, useEffect, useState } from 'react';
import {
	Grid,
	Typography,
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	useMediaQuery,
	IconButton,
	Skeleton
} from '@mui/material';
import { useStoreActions, useStoreState } from 'easy-peasy';
import utils from 'components/utils';
import { useTheme } from '@mui/material/styles';
import './bannerStyle.css';
import '../styles.css';
import CloseIcon from '@mui/icons-material/Close';
import Loader from 'components/Loader';

const Banners = _ => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const loading = useStoreState(state => state.cms.banners.loading);
	const loadBanner = useStoreActions(dispatch => dispatch.cms.banners.load);
	const banners = useStoreState(state => state.cms.banners.data);
	const targetUser = useStoreState(state => state.me.personify.target);
	const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const [open, setOpen] = useState(false);
	const [openedBanner, setOpenedBanner] = useState({});

	useEffect(
		_ => {
			loadBanner();
		},
		[targetUser]
	);

	const handleOpen = _ => {
		setOpen(true);
	};
	const handleClose = _ => {
		setOpen(false);
	};

	const handleClick = banner => {
		setOpenedBanner(banner);
		switch (banner.ctaType.title) {
			case 'url_link': {
				//Per il contest non inserisco il target blank
				if (banner.ctaTypeValue.startsWith('contest/')) window.open(banner.ctaTypeValue, '_self');
				else window.open(banner.ctaTypeValue, '_blank');
				break;
			}
			case 'details_text': {
				handleOpen();
				break;
			}
		}
	};

	return (
		<Fragment>
			<Grid container>
				{loading && (
					<Grid container>
						<Grid item xs={6} sx={{ paddingRight: theme.spacing(1) }}>
							<Skeleton height={100} />
						</Grid>
						<Grid item xs={6}>
							<Skeleton height={100} />
						</Grid>
					</Grid>
				)}
				{!loading && banners.length > 0 && (
					<Grid item xs={12}>
						<Typography className="sectionTitle">
							In <span className="secondary">Evidenza</span>
						</Typography>
					</Grid>
				)}
				{!loading &&
					banners.length > 0 &&
					banners.map((banner, index) => (
						<Grid key={index} lg={6} xs={12} item sx={{ marginTop: theme.spacing(1) }}>
							<Box
								className={index % 2 == 0 ? 'box-container0' : 'box-container1'}
								sx={{
									width: '100%',
									height: '80px',
									borderRadius: '21px'
								}}
							>
								<Grid container>
									<Grid
										item
										xs={9}
										container
										sx={{
											marginTop: theme.spacing(1)
										}}
									>
										<Typography
											className="banner-text"
											sx={{
												paddingLeft: theme.spacing(2),
												fontSize: isXs ? '20px' : '26px'
											}}
										>
											{banner.title}
										</Typography>
									</Grid>
									<Grid item xs={3} container>
										{banner.ctaEnabled && (
											<Button
												onClick={_ => handleClick(banner)}
												className="banner-button"
												sx={{ marginTop: theme.spacing(2), height: '50px', width: '157px' }}
											>
												<Typography
													className="banner-button-text"
													sx={{ fontSize: isXs && '13px' }}
												>
													{banner.ctaText}
												</Typography>
											</Button>
										)}
									</Grid>
								</Grid>
							</Box>
						</Grid>
					))}
			</Grid>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle disableTypography>
					<Typography variant="h6">Banner</Typography>
					<IconButton
						sx={{
							position: 'absolute',
							right: theme.spacing(1),
							top: theme.spacing(1),
							color: theme.palette.grey[500]
						}}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>{openedBanner.ctaTypeValue}</DialogContentText>
				</DialogContent>
			</Dialog>
		</Fragment>
	);
};

export default Banners;
