import React from 'react';
import PopoverMUI from '@mui/material/Popover';

const Popover = ({ open, anchorEl, onClose, children, id, style }) => {
	return (
		<PopoverMUI
			style={style}
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
		>
			{children}
		</PopoverMUI>
	);
};
export default Popover;
