import React, { useEffect, useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import head from 'lodash/head';
import parse from 'html-react-parser';
import {
	Stack,
	Typography,
	IconButton,
	Button,
	Popover,
	Collapse,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableContainer,
	TableBody,
	TableSortLabel,
	Tooltip
} from '@mui/material';
import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ShippedOrdersItemsDownload from './ShippedOrdersItemsDownload';
import SellIcon from '@mui/icons-material/Sell';
import ShippingTracking from '../ShippingTracking';
import utils from 'components/utils';
import moment from 'moment';
import { minWidth } from '@mui/system';
import routes from 'routes';

const TableList = props => {
	const { rows } = props;
	const theme = useTheme();
	const translate = utils.useI18N();

	const cells = [
		{ id: 'ean', field: 'catalog', label: translate('catalog.details.infoBox.ean'), width: '130px' },
		{ id: 'author', field: 'author', label: translate('catalog.tableHeaders.author') },
		{ id: 'epipoli', filed: 'type', label: '' },
		{ id: 'title', field: 'title', label: translate('catalog.tableHeaders.title'), minWidth: '100px' },
		{ id: 'publisher', field: 'publisher', label: translate('catalog.tableHeaders.publisher') },
		{ id: 'price', field: 'netPrice', label: translate('catalog.tableHeaders.price'), width: '130px' },
		{
			id: 'qta',
			field: 'quantityRequired',
			label: parse(translate('orders.shipped') + ' /<br>' + translate('orders.tables.total')),
			width: '95px'
		},
		{ id: 'note', field: 'note', label: translate('catalog.tableHeaders.note') }
	];

	return (
		<Table size="small" aria-label="ean list">
			<TableHead>
				<TableRow>
					{cells.map((cell, index) => (
						<TableCell
							key={index}
							sx={{
								padding: '10px 5px',
								backgroundColor: theme.palette.custom.greytable,
								width: cell.width ? cell.width : 'inherit',
								minWidth: cell.minWidth ? cell.minWidth : 'inherit'
							}}
						>
							{cell.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row, index) => (
					<TableRow key={index}>
						{cells.map((cell, index) => (
							<TableCell sx={{ padding: '10px 5px' }} key={index}>
								{cell.id == 'price' && row.promoCode && (
									<Tooltip
										variant="light"
										arrow
										followCursor
										placement="top"
										title={
											row.promoDesc
												? row.promoDesc
												: row.promoCode
												? row.promoCode
												: row.promoFromWl
										}
									>
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={1}
											sx={{ color: theme.palette.custom.green }}
										>
											<Typography variant="textTable">
												{utils.formatPrice(row[cell.field])}
											</Typography>
											<SellIcon sx={{ color: theme.palette.custom.green }} />
										</Stack>
									</Tooltip>
								)}
								{cell.id == 'price' && !row.promoCode && (
									<Typography variant="textTable">{utils.formatPrice(row[cell.field])}</Typography>
								)}
								{cell.id == 'qta' && row.quantityShipped + ' / ' + row.quantityRequired}
								{cell.id == 'epipoli' && row.type === 200 && (
									<CardGiftcardIcon sx={{ color: theme.palette.primary.main, fontSize: '22px' }} />
								)}
								{cell.id != 'price' && cell.id != 'qta' && cell.id != 'epipoli' && row[cell.field]}
							</TableCell>
						))}
					</TableRow>
				))}
			</TableBody>

			{/*sortedRows.map((row, index) => (
				<TableRow key={index}>
					{cells.map((cell, idx) => (
						<TableCell sx={{ padding: '10px 5px' }} key={idx}>
							{cell.id == 'price' ? utils.formatPrice(parseFloat(row[cell.field])) : row[cell.field]}
						</TableCell>
					))}
				</TableRow>
                    ))*/}
		</Table>
	);
};

const ShippedOrderShippings = props => {
	const { order } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const orderDirection = useStoreState(state => state.cart.shipped.orderDirection);
	const orderBy = useStoreState(state => state.cart.shipped.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.cart.shipped.setOrder);
	const visibleItems = order.orderItems.filter(item => item.visible);
	const grouped = groupBy(visibleItems, item => item.id);
	const shipList = keys(grouped).map(k => grouped[k]);
	const ks = keys(grouped);
	const composedShippingItems = ks.map(key => ({
		shippingNumber: order.resumeClosedOrder.deliveryResumeOrder[key].shippingNumber,
		date: order.resumeClosedOrder.deliveryResumeOrder[key].deliveredDate,
		depotDescription: order.resumeClosedOrder.deliveryResumeOrder[key].depotDescription,
		processingCopies: order.resumeClosedOrder.deliveryResumeOrder[key].sumOfProcessedCatalog,
		price: order.resumeClosedOrder.deliveryResumeOrder[key].sumCostProcessed,
		colli: null,
		shippingType: order.resumeClosedOrder.deliveryResumeOrder[key].shippingTypeDescription,
		items: grouped[key]
	}));
	const sortedRows = utils.stableSort(composedShippingItems, orderDirection, orderBy);
	const [open, setOpen] = useState(-1);
	const [deliveryID, setDeliveryID] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const openTracking = Boolean(anchorEl);

	const headCells = [
		{ id: 'shippingNumber', sortabled: false, label: 'orders.tables.deliveryNum' },
		{ id: 'date', sortabled: true, label: 'orders.tables.date' },
		{ id: 'depotDescription', sortabled: true, label: 'orders.tables.depotName' },
		{ id: 'processingCopies', sortabled: false, label: 'orders.tables.processingCopies' },
		{ id: 'colli', sortabled: false, label: 'orders.tables.numPackages' },
		{ id: 'shippingType', sortabled: true, label: 'orders.tables.withdrawTypes' }
	];

	const handleOpen = index => {
		setOpen(index != open ? index : -1);
	};

	const handleOpenTracking = (event, shippingNumber) => {
		setDeliveryID(shippingNumber);
		setAnchorEl(event.currentTarget);
	};

	const handleCloseTracking = () => {
		setAnchorEl(null);
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
				<Typography variant="titleSection">{translate('orders.listShipping')}</Typography>
				<ShippedOrdersItemsDownload item={order} />
			</Stack>
			<TableContainer sx={{ width: '100%', margin: '10px 0 !important' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{ padding: '10px 5px' }} />
							{headCells.map((cell, index) => (
								<TableCell sx={{ padding: '10px 5px' }} key={index}>
									{!cell.sortabled && translate(cell.label)}
									{cell.sortabled && (
										<TableSortLabel
											active={orderBy === cell.id}
											direction={orderBy === cell.id ? orderDirection : 'asc'}
											onClick={e => setOrder(cell.id)}
										>
											{translate(cell.label)}
										</TableSortLabel>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedRows.map((item, idx) => (
							<Fragment key={idx}>
								<TableRow>
									<TableCell sx={{ padding: '10px 5px' }}>
										<IconButton size="small" onClick={() => handleOpen(idx)}>
											{open == idx ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									</TableCell>
									{headCells.map((cell, index) => (
										<TableCell key={index} sx={{ padding: '10px 5px' }}>
											{cell.id === 'shippingNumber' && (
												<Typography title={translate('orders.tables.shippingTracking')}>
													<Button
														variant="textAction"
														endIcon={<LocalShippingIcon />}
														onClick={event =>
															handleOpenTracking(event, item.shippingNumber)
														}
														sx={{ color: theme.palette.primary.main, padding: '0 5px 0 0' }}
													>
														{'#' + item.shippingNumber}
													</Button>
												</Typography>
											)}
											{cell.id === 'date' && item.date && item.date.replaceAll('-', '/')}
											{cell.id === 'processingCopies' &&
												item.processingCopies +
													' /     ' +
													utils.formatPrice(parseFloat(item.price))}
											{cell.id !== 'shippingNumber' &&
												cell.id !== 'date' &&
												cell.id !== 'processingCopies' &&
												item[cell.id]}
										</TableCell>
									))}
								</TableRow>

								{/* Elenco ean */}
								<TableRow>
									<TableCell
										colSpan={7}
										sx={{
											borderBottom: open == idx ? '1px solid #c7c7c7' : 'none',
											padding: '0 !important'
										}}
									>
										<Collapse in={open == idx} timeout="auto" unmountOnExit>
											<TableList rows={item.items} />
										</Collapse>
									</TableCell>
								</TableRow>
							</Fragment>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Popover tracking */}
			<Popover
				open={openTracking}
				anchorEl={anchorEl}
				onClose={handleCloseTracking}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
			>
				<ShippingTracking deliveryID={deliveryID} onClose={handleCloseTracking} />
			</Popover>
		</Stack>
	);
};

export default ShippedOrderShippings;
