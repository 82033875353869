import forEach from 'lodash/forEach';

const getSumCost = items =>
	items
		.map(item => item.price * item.quantityRequired)
		.reduce((acc, cur) => acc + cur, 0)
		.toFixed(2);
const getSumCostProcessable = items =>
	items
		.filter(item => item.status === 3)
		.map(item => item.price * item.quantityRequired)
		.reduce((acc, cur) => acc + cur, 0)
		.toFixed(2);
const getSumOfCatalog = items =>
	items
		.map(item => item.quantityRequired)
		.reduce((acc, cur) => acc + cur, 0)
		.toFixed(2);
const getSumOfProcessableCatalog = items =>
	items
		.filter(item => item.status === 3)
		.map(item => parseInt(item.quantityEvadible))
		.reduce((acc, cur) => acc + cur, 0)
		.toFixed(2);

const calcResumeOrder = orderItems => {
	const sumCost = parseFloat(getSumCost(orderItems));
	const sumCostProcessable = parseFloat(getSumCostProcessable(orderItems));
	const sumOfCatalog = parseFloat(getSumOfCatalog(orderItems));
	const sumOfProcessableCatalog = parseFloat(getSumOfProcessableCatalog(orderItems));
	const depotResumeOrder = {};
	const depotKeys = [...new Set(orderItems.map(item => item.depot))];
	forEach(depotKeys, key => {
		const depotItems = orderItems.filter(item => item.depot === key);
		const sumCost = parseFloat(getSumCost(depotItems));
		const sumCostProcessable = parseFloat(getSumCostProcessable(depotItems));
		const sumOfCatalog = parseFloat(getSumOfCatalog(depotItems));
		const sumOfProcessableCatalog = parseFloat(getSumOfProcessableCatalog(depotItems));
		const depotDescription = depotItems[0].depotDesc;
		depotResumeOrder[key] = {
			sumCost,
			sumCostProcessable,
			sumOfCatalog,
			sumOfProcessableCatalog,
			depotDescription,
			depotResumeOrder: null
		};
	});
	return {
		sumCost,
		sumCostProcessable,
		sumOfCatalog,
		sumOfProcessableCatalog,
		depotDescription: null,
		depotResumeOrder
	};
};

export default calcResumeOrder;
