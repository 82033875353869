import getOpen from './get-open';
import getProcessing from './get-processing';
import getShipped from './get-shipped';
import create from './create';
import getCartList from './get-cart-list';
import remove from './remove';
import updateFavouriteCart from './update-favourite-cart';
import importt from './import';
import getDeliveryList from './get-delivery-list';
import sendEvasion from './send-evasion';
import checkEvasion from './check-evasion';
import getCAUsers from './get-ca-users';
import getFavourite from './get-favourite';
import getFulfillmentFailed from './get-fulfillment-failed';

export default {
	getOpen,
	getProcessing,
	getShipped,
	create,
	getCartList,
	remove,
	updateFavouriteCart,
	importt,
	getDeliveryList,
	sendEvasion,
	checkEvasion,
	getCAUsers,
	getFavourite,
	getFulfillmentFailed
};
