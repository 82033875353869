import React, { memo } from 'react';
import moment from 'moment';
import StyledTableCell from 'components/Table/StyledTableCell';

const IssuingDateComp = ({ date }) => {
	return <StyledTableCell>{moment(date).format('L')}</StyledTableCell>;
};

const IssuingDate = memo(
	({ item }) => <IssuingDateComp date={item.issuingDate} />,
	(p, n) => p.item.issuingDate === n.item.issuingDate
);

export default IssuingDate;
