import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import styles from './styles';

const HamburgerMenuButton = ({ classes, children }) => {
	const toggleMainOpen = useStoreActions(dispatch => dispatch.menu.toggleMainOpen);
	const open = useStoreState(state => state.menu.mainOpen);
	return (
		<IconButton
			color="inherit"
			aria-label="open drawer"
			onClick={_ => {
				toggleMainOpen();
			}}
			edge="start"
			className={clsx(classes.menuButton, {
				[classes.hide]: open
			})}
		>
			<MenuIcon />
		</IconButton>
	);
};

export default withStyles(styles, { withTheme: true })(HamburgerMenuButton);
