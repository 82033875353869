import React, { Fragment, useState, memo } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';
import colorUtils from 'utils';
import CloseIcon from '@mui/icons-material/Close';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(0, 3, 3, 3),
		minWidth: 350
	},
	modalLabels: {
		marginBottom: theme.spacing()
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	dividerColor: {
		backgroundColor: `rgba(${colorUtils.colors.hexToRgbString(theme.palette.primary.main)}, .62)`,
		marginBottom: theme.spacing(2)
	}
});

const DeleteComp = ({ classes, id, author, title, publisher, quantity, ean, status, type }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const loading = useStoreState(state => state.wishlist.removeItem.loading);
	const removeItem = useStoreActions(dispatch => dispatch.wishlist.removeItem.sendDelete);
	const [modalOpen, setModalOpen] = useState(false);
	// const onClick = _ => {
	// 	setModalOpen(true);
	// };
	const onClose = _ => {
		setModalOpen(false);
	};
	const onConfirm = _ => {
		removeItem(id);
	};
	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={modalOpen}
				onClose={_ => {
					setModalOpen(false);
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Paper className={classes.root}>
					<Grid container item>
						<Grid container item xs={12} justify="flex-end" style={{ maxHeight: 24 }}>
							<IconButton
								size="small"
								style={{ marginTop: 5, right: theme.spacing(-2) }}
								className={classes.noRipple}
								onClick={_ => {
									setModalOpen(false);
								}}
							>
								<CloseIcon />
							</IconButton>
						</Grid>
						<Typography className={classes.modalLabels} variant="h4">
							{translate('wishlist.removeItem')}
						</Typography>
					</Grid>
					<Divider classes={{ root: classes.dividerColor }} />
					<Typography className={classes.modalLabels} variant="body1">
						<strong>{translate('wishlist.tables.author')}: </strong>
						{utils.capitalizeAll(author)}
					</Typography>
					<Typography className={classes.modalLabels} variant="body1">
						<strong>{translate('wishlist.tables.title')}: </strong>
						{utils.capitalize(title)}
					</Typography>
					<Typography className={classes.modalLabels} variant="body1">
						<strong>{translate('wishlist.tables.ean')}: </strong>
						{ean}
					</Typography>
					<Typography className={classes.modalLabels} variant="body1">
						<strong>{translate('wishlist.tables.quantity')}: </strong>
						{quantity}
					</Typography>
					<Typography className={classes.modalLabels} variant="body1">
						<strong>{translate('wishlist.tables.publisher')}: </strong>
						{utils.capitalizeAll(publisher)}
					</Typography>
					<Grid container justify="space-between" style={{ marginTop: 16 }}>
						<Grid item>
							<Button disableElevation variant="outlined" color="primary" onClick={onClose}>
								{translate('common.close')}
							</Button>
						</Grid>
						<Grid item>
							{loading && <CircularProgress size={24} />}
							{!loading && (
								<Button disableElevation color="primary" variant="contained" onClick={onConfirm}>
									{translate('common.confirm')}
								</Button>
							)}
						</Grid>
					</Grid>
				</Paper>
			</Modal>
			<StyledTableCell>
				{status === 'INITIAL' && type === null && (
					<Tooltip title={translate('wishlist.removeItemTooltip')} placement="top">
						<span>
							<IconButton color="secondary" onClick={onConfirm}>
								<ClearIcon color="error" />
							</IconButton>
						</span>
					</Tooltip>
				)}
				{status !== 'INITIAL' && type === null && (
					<span>
						<IconButton color="secondary" onClick={onConfirm} disabled={true}>
							<ClearIcon />
						</IconButton>
					</span>
				)}
				{type === 'BOOK2C' && (
					<Tooltip title={translate('wishlist.removeItemTooltip')} placement="top">
						<span>
							<IconButton color="secondary" onClick={onConfirm} disabled={true}>
								<ClearIcon color="disabled" />
							</IconButton>
						</span>
					</Tooltip>
				)}
			</StyledTableCell>
		</Fragment>
	);
};

const StyledDeleteComp = withStyles(styles, { withTheme: true })(DeleteComp);

const DeleteIcon = memo(
	({ item, status, type }) => (
		<StyledDeleteComp
			status={status}
			type={type}
			showDelete={item.showDelete}
			quantity={item.quantity}
			author={item.author}
			title={item.title}
			publisher={item.publisher}
			ean={item.ean}
			id={item.id}
		/>
	),
	(p, n) =>
		p.status === n.status &&
		p.type === n.type &&
		p.item.showDelete === n.item.showDelete &&
		p.item.quantity === n.item.quantity &&
		p.item.ean === n.item.ean &&
		p.item.author === n.item.author &&
		p.item.title === n.item.title &&
		p.item.publisher === n.item.publisher &&
		p.item.id === n.item.id
);

export default DeleteIcon;
