import React, { useState, Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Avatar, Chip, Box, IconButton, Popover } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CartInfo from '../CartInfo';
import utils from 'components/utils';

const ShippedOrdersInfoDetail = props => {
	const { order } = props;
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const mappedItems = order.orderItems.map(item => Boolean(item.promoCode));
	const hasPromo = mappedItems.some(item => item);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const chips = [
		{
			label: 'fcd',
			avatar: 'FDC',
			condiction: order.fcd,
			color: theme.palette.custom.black
		},
		{
			label: 'promo',
			avatar: 'PR',
			condiction: hasPromo,
			color: theme.palette.custom.green
		}
	];

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={0}
			sx={{ width: '100%', margin: '5px 0' }}
		>
			{iCan('OPERATE_PA_CARTS') && !order.isSAP && !order.isDefault && order.type !== 'BOOK2C' && (
				<Fragment>
					<IconButton sx={{ display: { xs: 'inherit', md: 'none', sm: 'inherit' } }} onClick={handleClick}>
						<AssignmentIcon sx={{ fontSize: '28px', color: theme.palette.custom.black }} />
					</IconButton>
					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'center',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'center',
							horizontal: 'left'
						}}
					>
						<CartInfo order={order} />
					</Popover>
				</Fragment>
			)}
			{chips.map(chip => (
				<Box key={chip.label} sx={{ display: chip.condiction ? 'inline-block' : 'none' }}>
					<Chip
						size="small"
						avatar={
							chip.avatar ? (
								<Avatar
									sx={{
										backgroundColor: '#fff !important',
										borderWidth: '0px',
										color: chip.color + ' !important'
									}}
								>
									{chip.avatar}
								</Avatar>
							) : null
						}
						icon={chip.icon ? chip.icon : null}
						label={chip.label}
						sx={{
							backgroundColor: chip.color,
							color: '#fff !important',
							margin: '2px',
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: chip.color,
							maxWidth: '150px'
						}}
					/>
				</Box>
			))}
		</Stack>
	);
};

export default ShippedOrdersInfoDetail;
