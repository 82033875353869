import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const RecipientComp = ({ recipient, recipientName }) => {
	let field = ``;
	if (recipient !== '' && recipientName !== '') field = `${recipient} - ${recipientName}`;
	return <StyledTableCell style={{ maxWidth: 250 }}>{field}</StyledTableCell>;
};

const Customer = memo(
	({ item }) => <RecipientComp recipient={item.recipient} recipientName={item.recipientName} />,
	(p, n) => p.item.recipient === n.item.recipient && p.item.recipientName === n.item.recipientName
);

export default Customer;
