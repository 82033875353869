export default theme => ({
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	advancedSearchButton: {
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(2, 0)
		}
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	elements: {
		padding: `${theme.spacing(2, 1, 2, 1)} !important`
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	}
});
