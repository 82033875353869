import React, { useState, Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { orange } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Card from 'components/Card';
import ResponsiveSideContent from 'components/ResponsiveSideContent';
import NumericStepper from 'components/NumericStepper';
import WarningIcon from '@mui/icons-material/Warning';
import utils from 'components/utils';
import styles from './styles';
import IconButton from '@mui/material/IconButton';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { InputLabel, OutlinedInput, SwipeableDrawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Stack, Box, TextField, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const NewRequest = ({ classes, showCloseIcon, handleCloseNewRequest }) => {
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const loading = useStoreState(state => state.returns.create.loading);
	const requestOpen = useStoreState(state => state.returns.requestOpen);
	const showInactive = useStoreState(state => state.returns.getCarts.showInactive);
	const referent = utils.useUserData('referent', { name: '' });
	const tabSelected = useStoreState(state => state.returns.tabSelected);
	const create = useStoreActions(dispatch => dispatch.returns.create.sendRequest);
	const setShowInactive = useStoreActions(dispatch => dispatch.returns.getCarts.setShowInactive);
	const selectPeriod = useStoreActions(dispatch => dispatch.returns.getCarts.selectPeriod);
	const selectCart = useStoreActions(dispatch => dispatch.returns.getCarts.selectCart);
	const loadCarts = useStoreActions(dispatch => dispatch.returns.getCarts.load);
	const setRequestOpen = useStoreActions(dispatch => dispatch.returns.setRequestOpen);
	const translate = utils.useI18N();
	const [numItems, setNumItems] = useState(0);
	const [numPackages, setNumPackages] = useState(0);
	const [weight, setWeight] = useState(0);
	const [sumCost, setSumCost] = useState(0);
	const [enableService, setEnableService] = useState(false);
	const [numberDocument, setNumberDocument] = useState('');
	const [dateDocument, setDateDocument] = useState(null);
	const carts = useStoreState(state => state.returns.getCarts.carts);
	const periods = useStoreState(state => state.returns.getCarts.periods);
	const selectedPeriod = useStoreState(state => state.returns.getCarts.selectedPeriod);
	const selectedCartValue = useStoreState(state => state.returns.getCarts.selectedCartValue);
	const loadingCarts = useStoreState(state => state.returns.getCarts.loading);
	const file =
		'https://www.fastbookspa.it/documents/547200/547255/Normativa_Colli.pdf/4731fb59-bb7c-4a3d-aa56-06b192f73037'; //Still to be checked
	const handleServiceChange = e => {
		setEnableService(e.target.checked);
	};
	const onSave = _ => {
		const convertedDateDocument = dateDocument ? moment(dateDocument).format('DD/MM/YYYY') : null;
		const convertedNumberDocument = numberDocument ? Number(numberDocument) : null;

		create({
			numItems,
			numPackages,
			weight,
			sumCost,
			enableService,
			numberDocument,
			dateDocument: convertedDateDocument
		});
	};
	useEffect(
		_ => {
			loadCarts();
		},
		[loadCarts]
	);

	const enabled = numItems !== 0 && numPackages !== 0 && sumCost !== 0;

	const enableSave = _ => {
		let ratio = sumCost / numItems;
		let correctRange = enabled ? true : false;
		if (numItems > 10 && enabled) {
			correctRange = ratio > 5 && ratio < 50 ? true : false;
		}
		return correctRange;
	};

	const handleNumDDT = value => {
		//Limita il numero di caratteri a 10 (la props maxLenght non funziona)
		if (value.length <= 10) setNumberDocument(value);
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{
				width: '100%',
				maxWidth: { xs: '340px', md: '540px', sm: '540px' },
				padding: '10px'
			}}
		>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '100%' }}>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: '90%' }}>
						<Typography variant="titleSection" sx={{ color: theme.palette.primary.main }}>
							{translate('returns.request')}
						</Typography>
					</Box>
					<Box sx={{ width: '10%' }}>
						<IconButton onClick={event => handleCloseNewRequest(event)}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Stack>
				<Box
					sx={{
						width: '100%',
						borderWidth: '1px 0 0 0',
						borderStyle: 'solid',
						borderColor: theme.palette.primary.main,
						paddingTop: '10px'
					}}
				>
					<Typography variant="text" sx={{ color: theme.palette.custom.orange, lineHeight: '1.1em' }}>
						{translate('returns.subTitle')}
					</Typography>
				</Box>
			</Stack>
			<Stack sx={{ padding: theme.spacing(2) }} spacing={3} direction={'column'}>
				<Typography variant="body1">
					<strong>{translate('returns.owner')}:</strong> {utils.capitalizeAll(referent.name)}
				</Typography>
				<Stack direction={'row'} justifyContent={'space-between'}>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="body1">{translate('returns.numItems')}</Typography>
						</Grid>
						<Grid item xs={4}>
							<NumericStepper
								removeDot
								precision={0}
								value={numItems}
								onChange={e => setNumItems(e)}
								strict
								min={0}
								step={1}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="body1">{translate('returns.numPackages')}</Typography>
						</Grid>
						<Grid item xs={4}>
							<NumericStepper
								removeDot
								value={numPackages}
								onChange={e => setNumPackages(e)}
								strict
								max={9999}
								min={0}
								step={1}
							/>
						</Grid>
					</Grid>
				</Stack>
				<Stack direction={'row'} justifyContent={'space-between'}>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="body1">{translate('returns.weight')}</Typography>
						</Grid>
						<Grid item xs={4}>
							<NumericStepper
								value={weight}
								onChange={e => setWeight(e)}
								precision={2}
								strict
								min={0}
								step={0.01}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="body1">{translate('returns.sumCost')}</Typography>
						</Grid>
						<Grid item xs={4}>
							<NumericStepper
								value={sumCost}
								onChange={e => setSumCost(e)}
								format={value => `${value}€`}
								precision={2}
								strict
								min={0}
								step={0.01}
							/>
						</Grid>
					</Grid>
				</Stack>
				<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
					<TextField
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={_ => setNumberDocument('')}>
										<CloseIcon fontSize="small" />
									</IconButton>
								</InputAdornment>
							)
						}}
						size="small"
						label={translate('returns.detail.ddt') + ' (max 10 crt)'}
						value={numberDocument}
						onChange={event => handleNumDDT(event.target.value)}
					/>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							slotProps={{ textField: { size: 'small' } }}
							style={{ marginBottom: '15px' }}
							autoOk
							invalidDateMessage={translate('common.dateInvalid')}
							value={dateDocument}
							onChange={date => setDateDocument(date)}
							variant="inline"
							format="DD/MM/YYYY"
							margin="normal"
							label={translate('returns.detail.dataDdt')}
						/>
					</LocalizationProvider>
				</Stack>
				{iCan('RETURNS_PA') && (
					<Fragment>
						<Stack direction={'row'}>
							{loadingCarts && (
								<Box sx={{ width: '40%' }}>
									<CircularProgress size={24} />
								</Box>
							)}
							{!loadingCarts && (
								<FormControl sx={{ width: '40%' }}>
									<InputLabel
										id="select-cart"
										sx={{
											fontFamily: ['"Poppins", sans-serif'].join(','),
											fontSize: '14px',
											marginTop: '-5px'
										}}
									>
										Seleziona carrello
									</InputLabel>
									<Select
										value={selectedCartValue}
										onChange={e => selectCart(e.target.value)}
										sx={{
											height: '40px',
											fontFamily: ['"Poppins", sans-serif'].join(','),
											fontSize: '14px',
											fontWeight: '400',
											backgroundColor: theme.palette.custom.white,
											borderWidth: '1px',
											borderStyle: 'solid',
											borderColor:
												selectedCartValue.length > 0 ? theme.palette.primary.main : '#cccdce'
										}}
										input={<OutlinedInput label="Seleziona carrello" />}
									>
										{carts.map(item => (
											<MenuItem key={`cart_${item.value}`} value={item.value}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													{item.fcd && <EyeIcon style={{ marginRight: 8 }} />}
													{item.name}
												</div>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
							<Box sx={{ width: '50%', marginLeft: '10%' }}>
								<FormGroup aria-label="inactive" row>
									<FormControlLabel
										checked={showInactive}
										onChange={e => setShowInactive(e.target.checked)}
										control={<Checkbox color="primary" />}
										label={translate('returns.cartNotActive')}
										labelPlacement="end"
									/>
								</FormGroup>
							</Box>
						</Stack>
						<FormControl style={{ width: '40%' }}>
							<Select
								disabled={!showInactive}
								value={selectedPeriod}
								onChange={e => selectPeriod(e.target.value)}
								sx={{
									height: '40px',
									fontFamily: ['"Poppins", sans-serif'].join(','),
									fontSize: '14px',
									fontWeight: '400',
									backgroundColor: theme.palette.custom.white,
									borderWidth: '1px',
									borderStyle: 'solid',
									borderColor: selectedPeriod.length > 0 ? theme.palette.primary.main : '#cccdce'
								}}
							>
								{periods.map(item => (
									<MenuItem key={`cart_${item.value}`} value={item.value}>
										{item.label + ' ' + translate('returns.weeks')}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Fragment>
				)}

				<FormGroup aria-label="position" row style={{ height: '100%', alignContent: 'flex-end' }}>
					<FormControlLabel
						checked={enableService}
						onChange={handleServiceChange}
						control={<Checkbox color="primary" />}
						label={translate('returns.enableService')}
						labelPlacement="end"
					/>
				</FormGroup>
				{enableService && (
					<Fragment>
						<Typography variant="caption">
							{translate('returns.serviceMessageEnabled.dearCustomer')}
							<br />
							{translate('returns.serviceMessageEnabled.withdrawalServiceSelected')}
							<br />
							{translate('returns.serviceMessageEnabled.costOfServiceFirst')}&nbsp;€ 9.50&nbsp;
							{translate('returns.serviceMessageEnabled.costOfServiceSecond')}
						</Typography>
					</Fragment>
				)}
				<Box>
					<Typography variant="caption">{translate('returns.norm')}&nbsp;</Typography>
					<Button href={file} color="primary" target="_blank" size="small">
						{translate('returns.here')}
					</Button>
				</Box>
				<Stack direction={'row'} justifyContent={'space-between'}>
					<Button variant="dark" onClick={onSave} disabled={!enableSave() || loading}>
						{translate('common.save')}
					</Button>
					<Button variant="light" onClick={_ => setRequestOpen(false)}>
						{translate('common.close')}
					</Button>
				</Stack>
				{!enableSave() && enabled && (
					<Box>
						<WarningIcon fontSize="small" style={{ color: orange[500], marginRight: 8 }} />
						<Typography variant="subtitle2" color="error">
							{translate('common.warningOutOfRange')}
						</Typography>
					</Box>
				)}
			</Stack>
		</Stack>

		// 	</Grid>
		// </Grid>
	);
};

export default withStyles(styles, { withTheme: true })(NewRequest);
