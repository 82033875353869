import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import utils from 'components/utils';

const BackToButton = ({ to, label, style }) => {
	const translate = utils.useI18N();
	return (
		<Button component={Link} to={to} style={style}>
			{translate('common.backTo') + translate(`common.${label}`)}
		</Button>
	);
};

export default BackToButton;
