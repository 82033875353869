import k from 'k';
import utils from 'services/utils';

export default ({ id, user }) => {
	const requestOptions = {
		method: 'DELETE',
		headers: utils.createHeaders()
	};
	return utils.fetch(`${k.endpoints.apis}/backoffice/user-groups/${id}/${user}`, requestOptions);
};
