import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const AmountComp = ({ amount, currency }) => {
	let field = ``;
	if (amount !== '' && currency !== '') field = `${amount} ${currency}`;
	return <StyledTableCell>{utils.formatPrice(field)}</StyledTableCell>;
};

const Amount = memo(
	({ item }) => <AmountComp amount={item.amount} currency={item.currency} />,
	(p, n) => p.item.amount === n.item.amount && p.item.currency === n.item.currency
);

export default Amount;
