import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SingleNotification from './SingleNotification';
import Pagination from './Pagination';
import styles from './styles';

const Notifications = ({ classes }) => {
	const items = useStoreState(state => state.notifications.list.items);
	const load = useStoreActions(dispatch => dispatch.notifications.list.load);
	// prettier-ignore
	useEffect(load, [load]);
	return (
		<Fragment>
			<Grid container spacing={1}>
				<Grid container item xs={12} className={classes.mobileMargin}>
					<Pagination />
				</Grid>
				<Grid container item xs={12}>
					{items.map(item => (
						<SingleNotification key={item.id} item={item} />
					))}
					{items.length === 0 && (
						<Grid container item xs={12} justify="center">
							<Typography variant="body1">Non ci sono nuove notifiche</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Notifications);
