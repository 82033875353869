import React from 'react';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';

const defaultStyles = {
	paddingLeft: 0,
	paddingRight: 0
};

const Empty = props => {
	const { additionalStyles } = props;
	const theme = useTheme();
	return (
		<TableCell
			align="left"
			style={{ ...defaultStyles, ...additionalStyles }}
			sx={{ background: theme.palette.custom.greytable }}
		>
			<span className="MuiButtonBase-root" aria-disabled="true">
				&nbsp;
			</span>
		</TableCell>
	);
};

export default Empty;
