import React, { memo } from 'react';
import PromoIcon from 'components/PromoIcon';
import Tooltip from '@mui/material/Tooltip';
import StyledTableCell from 'components/Table/StyledTableCell';
import IconButton from '@mui/material/IconButton';

const PromoComp = ({ promotionSapId, isPromoActive, promoSingleItem }) => (
	<StyledTableCell>
		{promotionSapId && isPromoActive && (
			<Tooltip title={promoSingleItem ? promoSingleItem : promotionSapId}>
				<IconButton disableRipple style={{ backgroundColor: 'transparent', cursor: 'default' }}>
					<PromoIcon />
				</IconButton>
			</Tooltip>
		)}
	</StyledTableCell>
);

const Promo = memo(
	({ item }) => (
		<PromoComp
			promotionSapId={item.promotionSapId}
			isPromoActive={item.isPromoActive}
			promoSingleItem={item.promoSingleItem}
		/>
	),
	(p, n) =>
		p.item.promotionSapId === n.item.promotionSapId &&
		p.item.isPromoActive === n.item.isPromoActive &&
		p.item.promoSingleItem === n.item.promoSingleItem
);

export default Promo;
