import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	modalOpen: false,
	setModalOpen: action((state, payload) => {
		state.modalOpen = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.conditionRequest
			.updateMultiple(payload)
			.then(data => {
				actions.setModalOpen(false);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('conditionRequest.respond');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
