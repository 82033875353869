import React, { Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TableHead from './Table/TableHead';
import TableBody from './Table/TableBody';
import Loader from 'components/Loader';
import Card from 'components/Card';
import utils from 'components/utils';
import { summaryInvoiceCols, ribaCols } from './Table/columns';
import DownloadOptions from './DownloadOptions';
import styles from './styles';

const SummaryInvoice = ({ classes }) => {
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.documents.details.loading);
	const results = useStoreState(state => state.documents.details.items);
	const modalOpen = useStoreState(state => state.documents.details.modalDetailsSummaryInvoice);
	const selectedType = useStoreState(state => state.documents.search.selectedType);
	const savedNumber = useStoreState(state => state.documents.search.savedNumber);
	const amountGross = useStoreState(state => state.documents.details.amountGross);
	const amountNet = useStoreState(state => state.documents.details.amountNet);
	const visibleDownloadOptions = useStoreState(state => state.documents.download.visible);
	const setVisibleDownloadOptions = useStoreActions(dispatch => dispatch.documents.download.setVisible);
	const setModalOpen = useStoreActions(dispatch => dispatch.documents.details.openModalSummaryInvoice);
	const visibleResults = results.filter(r => r.visible);
	const onDlOptionClick = _ => {
		setVisibleDownloadOptions(!visibleDownloadOptions);
	};
	return (
		<Modal className={classes.modalPosition} open={modalOpen} onClose={_ => setModalOpen(false)}>
			<div className="modal-content">
				<Fragment>
					<Card type="compact" title={translate(selectedType.i18nKey) + ' : ' + savedNumber}>
						<Grid container spacing={3}>
							<Grid container item xs={12} justify="flex-end" style={{ marginTop: -10, padding: 0 }}>
								<IconButton className={classes.noRipple} onClick={_ => setModalOpen(false)}>
									<CloseIcon />
								</IconButton>
							</Grid>
							{selectedType.id !== 'RIBA' && (
								<Grid item xs={6} style={{ paddingTop: 26 }}>
									<Typography className={classes.title}>
										{translate('documents.total')}:
										<strong> {translate('documents.amount')}: </strong>
										{utils.formatPrice(amountGross)}
										<strong> {translate('documents.net')}: </strong>
										{utils.formatPrice(amountNet)}
									</Typography>
								</Grid>
							)}
							{selectedType.id === 'RIBA' && (
								<Grid item xs={6} style={{ paddingTop: 26 }}>
									<Typography className={classes.title}>
										{translate('documents.total')}:
										<strong> {translate('documents.amount')}: </strong>
										{utils.formatPrice(amountGross)}
									</Typography>
								</Grid>
							)}
							{selectedType.id !== 'RIBA' && (
								<Grid container item xs={6} justify="flex-end">
									<Button variant="outlined" color="primary" onClick={onDlOptionClick}>
										{visibleDownloadOptions ? 'chiudi' : 'vedi'} opzioni di download
									</Button>
								</Grid>
							)}
						</Grid>
						{visibleDownloadOptions && selectedType.id !== 'RIBA' && (
							<Grid item container xs={12}>
								<DownloadOptions />
							</Grid>
						)}
						<Grid item container xs={12}>
							{loading && (
								<Grid
									item
									container
									xs={12}
									justify="center"
									spacing={3}
									style={{ padding: '2px, 1px, 2px, 1px' }}
								>
									<Loader />
								</Grid>
							)}
							{/* SEARCH RESULTS */}
							{!loading && results.length > 0 && (
								<Grid item container xs={12} style={{ marginTop: 16 }}>
									{results.length > 0 && (
										<TableContainer component={Paper} style={{ maxHeight: 400 }}>
											<Table size="small" stickyHeader aria-label="documents table">
												<TableHead
													visibleCols={
														selectedType.id !== 'RIBA' ? summaryInvoiceCols : ribaCols
													}
												/>
												<TableBody
													rows={visibleResults}
													visibleCols={
														selectedType.id !== 'RIBA' ? summaryInvoiceCols : ribaCols
													}
												/>
											</Table>
										</TableContainer>
									)}
								</Grid>
							)}
						</Grid>
					</Card>
				</Fragment>
			</div>
		</Modal>
	);
};

export default withStyles(styles, { withTheme: true })(SummaryInvoice);
