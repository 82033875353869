import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FileUploader from './FileUploader';
import utils from 'components/utils';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 1, 0, 0)
		}
	}
});

const ImportUserGroups = ({ classes, sapId }) => {
	const translate = utils.useI18N();
	const uploaderModalOpen = useStoreState(state => state.purchasings.importt.uploaderModalOpen);
	const setUploaderModalOpen = useStoreActions(dispatch => dispatch.purchasings.importt.setUploaderModalOpen);
	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={uploaderModalOpen}
				onClose={_ => {
					setUploaderModalOpen(false);
				}}
				closeAfterTransition
			>
				<div className="modal-content">
					<FileUploader sapId={sapId} />
				</div>
			</Modal>
			<Button
				className={classes.buttonsMargin}
				variant="outlined"
				onClick={_ => {
					setUploaderModalOpen(true);
				}}
			>
				{translate('purchasings.buttons.importUsers')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(ImportUserGroups);
