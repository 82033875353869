import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import parse from 'html-react-parser';
import { Typography, Link, Stack, Box, Switch } from '@mui/material';
import SelectDelivery from './SelectDelivery';
import utils from 'components/utils';

const SelectDepots = props => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const stateDepots = useStoreState(state => state.cart.evasion.depots);
	const setDepots = useStoreActions(dispatch => dispatch.cart.evasion.setDepots);
	const toggleSelectionDepot = useStoreActions(dispatch => dispatch.cart.evasion.toggleSelectionDepot);
	const totalEvadable = useStoreState(state => state.cart.evasion.totalEvadable);
	const paymentCond = useStoreState(state => state.cart.evasion.paymentCond);
	const paymentType = useStoreState(state => state.cart.evasion.paymentType);
	const paymentTypeId = utils.useUserData('paymentTypeId', [], false);
	const errorPymentType = paymentCond === '1' && paymentType === 'H';
	const [depots, setDepotsLocal] = useState(null);
	const [disabled, setDisabled] = useState(false);

	const selectDepot = (item, index) => {
		if (!errorPymentType) toggleSelectionDepot(index);
	};

	useEffect(_ => {
		setDepots();
	}, []);

	useEffect(
		_ => {
			if (!stateDepots) return;
			const ds = paymentTypeId === 1 ? stateDepots.filter(d => d.id !== 'FS01') : stateDepots;
			setDepotsLocal(ds);
			setDisabled(ds.every(d => d.deliveriesIsLoaded && d.deliveries.length === 0));
		},
		[stateDepots, paymentTypeId]
	);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			<Box>
				<Typography variant="titleTab">
					{translate('orders.evasion.totalEvadible') + ': ' + utils.formatPrice(totalEvadable)}
				</Typography>
			</Box>
			{errorPymentType && (
				<Box sx={{ width: '100%' }}>
					<Typography variant="titleTab" sx={{ color: theme.palette.custom.red }}>
						{translate('orders.evasion.errorPyamentType')}
					</Typography>
				</Box>
			)}
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{
					width: { xs: '100%', md: '100%', sm: '100%', lg: '100%' },
					padding: '5px !important',
					borderBottom: '1px solid #ccc'
				}}
			>
				<Box sx={{ width: '50%', textAlign: 'left' }}>
					<Typography variant="titleTab" sx={{ fontWeight: '600' }}>
						{translate('orders.filters.depot')}
					</Typography>
				</Box>
				<Box sx={{ width: '50%', textAlign: 'right' }}>
					<Typography variant="titleTab" sx={{ fontWeight: '600' }}>
						{translate('orders.evasion.deliveryMode')}
					</Typography>
				</Box>
			</Stack>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{
					width: { xs: '100%', md: '100%', sm: '100%', lg: '100%' },
					padding: '5px !important'
				}}
			>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={4}
					sx={{ width: '100%' }}
				>
					{depots &&
						depots.map((item, index) => (
							<Stack
								key={index}
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
								spacing={2}
								sx={{ width: '100%' }}
							>
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={0}
									sx={{
										width: '50%',
										paddingLeft: item.showSelectionCb ? '0' : '40px',
										paddingTop: '5px',
										paddingBottom: '5px',
										'&:hover': {
											backgroundColor: '#f5f5f5 !important'
										}
									}}
								>
									<Switch
										checked={item.showDeliveries}
										disabled={disabled || errorPymentType}
										onChange={_ => selectDepot(item, index)}
										inputProps={{ 'aria-labelledby': item.id }}
										sx={{ display: item.showSelectionCb ? 'inherit' : 'none' }}
									/>
									<Link
										onClick={_ => selectDepot(item, index)}
										sx={{
											textDecoration: 'none !important'
										}}
									>
										<Stack
											direction="column"
											justifyContent="flex-start"
											alignItems="flex-start"
											spacing={0}
										>
											<Typography
												variant="text"
												sx={{
													fontWeight: '600',
													textTransform: 'capitalize',
													textDecoration: 'none !important',
													color: errorPymentType
														? theme.palette.custom.disabled
														: item.showDeliveries
														? theme.palette.primary.main
														: '#000',
													cursor: 'pointer'
												}}
											>
												{item.name}
											</Typography>
											<Typography
												variant="text"
												sx={{
													textDecoration: 'none !important',
													color: errorPymentType ? theme.palette.custom.disabled : '#000',
													cursor: 'pointer'
												}}
											>
												{translate('orders.processable') + ': ' + utils.formatPrice(item.total)}
											</Typography>
										</Stack>
									</Link>
								</Stack>
								<Stack
									direction="row"
									justifyContent="flex-end"
									alignItems="flex-end"
									spacing={0}
									sx={{ width: '50%' }}
								>
									{item.showDeliveries && !errorPymentType && <SelectDelivery depot={item} />}
									{item.responseMessage && (
										<Typography
											variant="text"
											sx={{
												color: theme.palette.custom.orange,
												textAlign: 'right',
												'& ul': {
													listStyleType: 'none',
													margin: '5px 0 0 0 !important'
												}
											}}
										>
											{parse(item.responseMessage)}
										</Typography>
									)}
								</Stack>
							</Stack>
						))}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default SelectDepots;
