import React, { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import PromoIcon from 'components/PromoIcon';

const PromoComp = ({ isPromo, width, promoList }) => {
	const w = {
		width,
		maxWidth: width,
		minWidth: width
	};

	const promoName = promoList => {
		return promoList.map(promo => (
			<Grid key={promo.name} container>
				<Grid item xs={12}>
					{promo.name}
				</Grid>
			</Grid>
		));
	};

	return (
		<TableCell align="center" style={w}>
			{isPromo && (
				<Tooltip title={promoName(promoList)}>
					<IconButton style={{ paddingLeft: 2 }}>
						<PromoIcon color="error" style={{ fontSize: 20, margin: '0px 8px' }} />
					</IconButton>
				</Tooltip>
			)}
		</TableCell>
	);
};

const Promo = memo(
	({ item, width }) => <PromoComp isPromo={item.isPromo} width={width} promoList={item.promo} />,
	(p, n) => p.item.isPromo === n.item.isPromo
);

export default Promo;
