import k from 'k';
import utils from 'services/utils';

export default ({ month, year, available }) => {
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify({ month, year, available }),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/returns/month`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
