import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import NoResults from 'components/NoResults';
import Card from 'components/Card';
import TableHead from './TableHead';
import TableBody from './TableBody';
import utils from 'components/utils';
import TableToolbar from './TableToolbar';
import NewCard from 'components7/Card';

const ItemsTable = _ => {
	const params = useParams();
	const data = useStoreState(state => state.purchasings.detailsItems.data);
	const baseData = useStoreState(state => state.purchasings.details.data);
	const loading = useStoreState(state => state.purchasings.detailsItems.loading);
	const load = useStoreActions(dispatch => dispatch.purchasings.detailsItems.load);
	const order = useStoreState(state => state.purchasings.assignUsers.orderDirection);
	const orderBy = useStoreState(state => state.purchasings.assignUsers.orderBy);
	const translate = utils.useI18N();
	useEffect(
		_ => {
			load(params.id);
		},
		[load, params]
	);
	const orderUser = _ => {
		const val1 = order === 'asc' ? 1 : -1;
		return [...data].sort((a, b) => (a[orderBy] > b[orderBy] ? val1 : -val1));
	};
	let rows = orderUser(data);
	return (
		<Fragment>
			<NewCard
				backgroundColor="white"
				title={`${translate('purchasings.associatedUsers')}`}
				width={'100%'}
				margin={'20px 0 0 0'}
			>
				{loading && <CircularProgress size={40} />}
				{!loading && baseData.usersCentralPurchasing.length === 0 && (
					<NoResults text={translate('purchasings.noArticles')} />
				)}
				{!loading && baseData.usersCentralPurchasing.length > 0 && (
					<Fragment>
						<TableToolbar />
						{!loading && data.length === 0 ? (
							<NoResults text={translate('purchasings.userNotAssociated')} />
						) : (
							<TableContainer>
								<Table
									stickyHeader
									aria-labelledby="items"
									size="small"
									padding="none"
									aria-label="result table"
								>
									<TableHead />
									<TableBody rows={rows} />
								</Table>
							</TableContainer>
						)}
					</Fragment>
				)}
			</NewCard>
		</Fragment>
	);
};

export default ItemsTable;
