import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import HeadlessCard from 'components/Card/HeadlessCard';
import styles from './styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import Card from 'components/Card';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField/TextField';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from 'react-router';

const AddNewPurchasingCenter = ({ classes, close, showCloseIcon }) => {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [active, setActive] = useState(false);
	const addPurchasingCenter = useStoreActions(dispatch => dispatch.purchasings.add.sendRequest);
	const setAddOpen = useStoreActions(dispatch => dispatch.purchasings.add.setOpen);
	const referents = useStoreState(state => state.purchasings.referentList.referents);
	const loadingRef = useStoreState(state => state.purchasings.referentList.loading);
	const [selectedRef, selectRef] = useState('');
	const translate = utils.useI18N();
	const history = useHistory();

	const onClick = _ => {
		const data = {
			name: name,
			referentCode: selectedRef,
			referentDesc: description,
			enabled: active
		};

		addPurchasingCenter(data);
	};
	const onClose = _ => {
		setAddOpen(false);
		history.push(`/admin/purchasingcenters`);
	};

	const selectReferent = e => {
		selectRef(e.target.value);
	};

	return (
		<div
			className={clsx(classes.negateMargin, {
				[classes.margin]: showCloseIcon
			})}
		>
			<HeadlessCard>
				<Grid container spacing={2}>
					{showCloseIcon && (
						<Grid container item xs={12} justify="flex-end">
							<IconButton onClick={close} className={classes.closeIcon}>
								<CloseIcon />
							</IconButton>
						</Grid>
					)}
					<LocalizationProvider utils={MomentUtils}>
						<Box sx={{ padding: '16px' }}>
							<Grid container spacing={2}>
								<Grid container item xs={12}>
									<TextField
										value={name}
										onChange={e => setName(e.target.value)}
										fullWidth={true}
										label={translate('purchasings.tables.name') + '*'}
									/>
								</Grid>
								<Grid container item xs={12}>
									<TextField
										value={description}
										onChange={e => setDescription(e.target.value)}
										fullWidth={true}
										label={translate('purchasings.description')}
									/>
								</Grid>
								<Grid className={classes.baseGrid} item xs={12} sm={12} container justify="flex-start">
									<Typography align={'center'}>{translate('purchasings.tables.status')}</Typography>
									<Checkbox
										onChange={() => setActive(!active)}
										value={active}
										color={'primary'}
										style={{ padding: 0 }}
									/>
								</Grid>
								{!loadingRef && referents.length > 0 && (
									<Grid
										className={classes.baseGrid}
										item
										xs={12}
										sm={12}
										container
										justify="flex-start"
									>
										<FormControl className={classes.formControl} sx={{ width: '100%' }}>
											<InputLabel id="select-refernce-label">
												{translate('purchasings.selectReferent') + ' *'}
											</InputLabel>
											<Select
												labelId="select-refernce-label"
												value={selectedRef}
												onChange={e => selectReferent(e)}
												displayEmpty
												className={classes.selectEmpty}
											>
												{referents.map((item, index) => (
													<MenuItem
														key={`referent_purchasing_${item.userCode}`}
														value={item.userCode}
													>
														{item.userName}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								)}
								<Grid container item xs={6}>
									<Button onClick={onClose} variant="outlined" color="primary" type="submit">
										{translate('common.close')}
									</Button>
								</Grid>
								<Grid container item xs={6} justify="flex-end">
									<Button
										onClick={onClick}
										variant="contained"
										color="primary"
										type="submit"
										disabled={name === '' || selectedRef === ''}
									>
										{translate('common.save')}
									</Button>
								</Grid>
							</Grid>
						</Box>
					</LocalizationProvider>
				</Grid>
			</HeadlessCard>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(AddNewPurchasingCenter);
