import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, FormControl, FormControlLabel, RadioGroup, Radio, FormHelperText } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EuroIcon from '@mui/icons-material/Euro';
import utils from 'components/utils';

const SelectPaymentMethod = props => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const [iAmPersonifying] = utils.usePersonification();
	const depots = useStoreState(state => state.cart.evasion.depots);
	const paymentType = useStoreState(state => state.cart.evasion.paymentType);
	const paymentCond = useStoreState(state => state.cart.evasion.paymentCond);
	const paymentMethod = useStoreState(state => state.cart.evasion.paymentMethod);
	const setPaymentMethod = useStoreActions(dispatch => dispatch.cart.evasion.setPaymentMethod);
	const methodChoosen = paymentMethod;
	const depotsSelected = depots.filter(d => d.selected === true);
	const numDepotsSelected = depotsSelected.length;
	const shippingBanco = depotsSelected.filter(
		depot => depot.deliveries.filter(de => de.shippingType === 'Banco' && de.selected).length > 0
	);

	const methods = [
		{
			id: 0,
			label: 'orders.evasion.cashOnDelivery',
			value: 0,
			visible: paymentCond == '1' && paymentType == '8',
			enabled: true,
			icon: (
				<LocalShippingIcon
					sx={{ color: methodChoosen == 0 ? theme.palette.primary.main : theme.palette.custom.black }}
				/>
			)
		},
		{
			id: 1,
			label: 'orders.evasion.cash',
			value: 1,
			visible: paymentCond == '1' && paymentType == '1',
			enabled: true,
			icon: (
				<EuroIcon
					sx={{ color: methodChoosen == 1 ? theme.palette.primary.main : theme.palette.custom.black }}
				/>
			)
		},
		{
			id: 2,
			label: 'orders.evasion.online',
			value: 2,
			visible: paymentCond === '1' && (paymentType == '1' || paymentType == '8'),
			enabled:
				paymentCond === '1' &&
				(paymentType == '1' || paymentType == '8') &&
				numDepotsSelected === 1 &&
				shippingBanco.length === 0 &&
				!iAmPersonifying,
			icon: (
				<CreditCardIcon
					sx={{ color: methodChoosen == 2 ? theme.palette.primary.main : theme.palette.custom.black }}
				/>
			)
		}
	];

	const handleChangeMethod = event => {
		setPaymentMethod(event.target.value);
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			<FormControl>
				<RadioGroup
					aria-labelledby="evasion-paymentmethod-choose"
					name="paymentMethodChooseRadio"
					value={methodChoosen}
					onChange={handleChangeMethod}
				>
					{methods.map((method, index) => (
						<FormControlLabel
							key={method.id}
							value={method.value}
							disabled={!method.enabled}
							control={
								<Radio
									sx={{
										'& .MuiSvgIcon-root': {
											fontSize: 28
										}
									}}
								/>
							}
							label={
								<Stack
									direction={{ xs: 'column', md: 'row', sm: 'column', lg: 'row' }}
									justifyContent="flex-start"
									alignItems={{ xs: 'flex-start', md: 'center', sm: 'flex-start', lg: 'center' }}
									spacing={0.5}
								>
									<Stack
										direction="row"
										justifyContent="flex-start"
										alignItems="center"
										spacing={1}
										sx={{
											width: '280px',
											padding: '10px 20px',
											borderWidth: '1px',
											borderStyle: 'solid',
											borderColor:
												methodChoosen == method.value
													? theme.palette.primary.main
													: theme.palette.custom.greyborder,
											backgroundColor:
												methodChoosen == method.value ? theme.palette.custom.white : 'inherit'
										}}
									>
										{method.icon}
										<Typography
											variant="text"
											sx={{
												color:
													methodChoosen == method.value
														? theme.palette.primary.main
														: theme.palette.custom.black
											}}
										>
											{translate(method.label)}
										</Typography>
									</Stack>
								</Stack>
							}
							sx={{
								display: method.visible ? 'inherit' : 'none',
								margin: '10px 0 !important'
							}}
						/>
					))}
				</RadioGroup>
				{(paymentType == '1' || paymentType == '8') && numDepotsSelected > 1 && (
					<FormHelperText>
						<Typography
							variant="text"
							sx={{
								fontSize: { xs: '1.2em', md: '1.2em', sm: '1.2em', lg: '1.2em' },
								marginTop: '10px !important',
								color: theme.palette.custom.orange
							}}
						>
							{translate('orders.evasion.disabledMessage')}
						</Typography>
					</FormHelperText>
				)}
				{(paymentType == '1' || paymentType == '8') && shippingBanco.length > 0 && (
					<FormHelperText>
						<Typography
							variant="text"
							sx={{
								fontSize: { xs: '1.2em', md: '1.2em', sm: '1.2em', lg: '1.2em' },
								marginTop: '10px !important',
								color: theme.palette.custom.orange
							}}
						>
							{translate('orders.evasion.disabledMessageShippingType')}
						</Typography>
					</FormHelperText>
				)}
			</FormControl>
			{(paymentType == '1' || paymentType == '8') && iAmPersonifying && (
				<Typography
					variant="text"
					sx={{
						fontSize: { xs: '1.2em', md: '1.2em', sm: '1.2em', lg: '1.2em' },
						marginTop: '5px !important',
						marginBottom: '10px !important',
						color: theme.palette.custom.orange
					}}
				>
					{translate('orders.evasion.disabledMessagePersonify')}
				</Typography>
			)}
		</Stack>
	);
};

export default SelectPaymentMethod;
