import details from './details';
import list from './list';
import update from './update';
import conditionsOfSale from './conditions-of-sale';

export default {
	details,
	list,
	update,
	conditionsOfSale
};
