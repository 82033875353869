import React from 'react';
import k from 'k';

const withColumns = WrappedComponent => {
	const cols = [...k.wishlistCols];
	return props => {
		return <WrappedComponent {...props} visibleCols={cols} />;
	};
};

export default withColumns;
