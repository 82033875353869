import { action, thunk } from 'easy-peasy';
import pick from 'lodash/pick';
import clone from 'lodash/clone';
import utils from './utils';

export default {
	data: [],
	loadingPdf: false,
	loadingXls: false,
	setExportParams: action((state, payload) => {
		state.exportParams = clone(pick(payload, ['cartName', 'cartId']));
	}),
	setLoadingPdf: action((state, payload) => {
		state.loadingPdf = payload;
	}),
	setLoadingXls: action((state, payload) => {
		state.loadingXls = payload;
	}),
	openOrderPdf: thunk(utils.exportOpenPdf),
	openOrderXls: thunk(utils.exportOpenXls),
	processingOrderPdf: thunk(utils.exportProcessingPdf),
	processingOrderXls: thunk(utils.exportProcessingXls),
	shippedOrderPdf: thunk(utils.exportShippedPdf),
	shippedOrderXls: thunk(utils.exportShippedXls)
};
