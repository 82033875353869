import { action, thunkOn } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: {},
	save: action((state, payload) => {
		state.data = payload;
	}),
	retrieveOnDetailsOrImport: thunkOn(
		(actions, storeActions) => [
			storeActions.coupons.details.load.successType,
			storeActions.coupons.importt.sendRequestArticles.successType
		],
		async (actions, payload, { getStoreState, injections, getStoreActions }) => {
			const { services } = injections;
			actions.setLoading(true);
			const id = getStoreState().coupons.details.data.id;
			return services.coupons
				.getReport(id)
				.then(data => {
					actions.save(data);
				})
				.catch(e => {
					getStoreActions().error('purchasings.import');
				})
				.finally(_ => {
					actions.setLoading(false);
				});
		}
	)
};
