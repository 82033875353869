import { action, thunk, thunkOn, computed } from 'easy-peasy';
import moment from 'moment';
import utils from '../admin/coupons/utils';
import storeUtils from 'store/utils';

export default {
	items: [],
	loading: false,
	savedNumber: '',
	savedRefNum: '',
	realTotal: '',
	searching: false,
	goSearch: action((state, payload) => {
		state.searching = payload;
	}),
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	saveNumber: action((state, payload) => {
		state.savedNumber = payload;
	}),
	saveRealTotal: action((state, payload) => {
		state.realTotal = payload;
	}),
	saveRefNum: action((state, payload) => {
		state.savedRefNum = payload;
	}),
	resetItems: action((state, payload) => {
		state.items = [];
		state.types = state.types.map((t, i) => ({ ...t, selected: i === 0 }));
		state.periods = state.periods.map((p, i) => ({ ...p, selected: i === 0 }));
	}),
	resetOnPersonification: thunkOn(
		(actions, storeActions) => [storeActions.me.personify.sendRequest.successType],
		async (actions, target, helpers) => {
			actions.resetItems();
		}
	),
	save: action((state, payload) => {
		state.items = payload.items.map(item => ({
			...item,
			ordered: Number(item.docNum),
			isSelected: false
		}));
	}),
	resetSearch: action((state, payload) => {
		state.items = [];
		state.searching = false;
		state.types = state.types.map((t, i) => ({ ...t, selected: i === 0 }));
		state.periods = state.periods.map((p, i) => ({ ...p, selected: i === 0 }));
	}),
	load: thunk(utils.search),
	orderBy: 'date',
	orderDirection: 'desc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'desc';
		}
		state.orderBy = payload;
	}),
	toggleSelection: action((state, payload) => {
		const i = state.items.findIndex(itm => itm.docNum === payload);
		if (i === -1) return;
		const item = state.items[i];
		item.isSelected = !item.isSelected;
		state.items = state.items
			.slice(0, i)
			.concat(item)
			.concat(state.items.slice(i + 1));
	}),
	selectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: true
		}));
	}),
	deselectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: false
		}));
	}),
	startSearch: thunk((actions, payload, { getState, injections, getStoreActions }) => {
		const { services } = injections;
		const selectedType = getState().types.find(t => t.selected);
		const rows = getState().pagination.step;
		const page = getState().pagination.current - 1;
		const dateTo = moment().format('L');
		const selectedPeriod = getState().periods.find(p => p.selected);
		const dateFrom = moment()
			.subtract(selectedPeriod.id, 'months')
			.format('L');
		const params = { type: selectedType.id, rows, dateTo, dateFrom, page };
		actions.setLoading(true);
		return services.documents
			.search(params)
			.then(data => {
				actions.save(data);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('documents.errorSearch');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	...storeUtils.createPagination({ step: 25, paginationTrigger: 'save', loadFn: 'startSearch' }),
	types: [
		{ id: 'SELECT', i18nKey: 'documents.docDefault', selected: true },
		{ id: 'BILL', i18nKey: 'documents.BILL', selected: false }, // default
		{ id: 'BILL_DEPOT', i18nKey: 'documents.BILL_DEPOT', selected: false }, // depotStatement
		{ id: 'ACCOMPANYING_INVOICES', i18nKey: 'documents.ACCOMPANYING_INVOICES', selected: false }, // default
		{ id: 'STATEMENT_INVOICES', i18nKey: 'documents.STATEMENT_INVOICES', selected: false }, // depotStatement
		{ id: 'STATEMENT_INVOICES_TO_RECEIVE', i18nKey: 'documents.STATEMENT_INVOICES_TO_RECEIVE', selected: false }, // depotStatement
		{ id: 'SUMMARY_INVOICES', i18nKey: 'documents.SUMMARY_INVOICES', selected: false }, // detailsSummaryInvoice
		{ id: 'CREDIT_NOTE', i18nKey: 'documents.CREDIT_NOTE', selected: false }, // default
		{ id: 'RIBA', i18nKey: 'documents.RIBA', selected: false } // riba
	],
	selectType: action((state, payload) => {
		state.types = state.types.map(t => ({
			...t,
			selected: t.id === payload
		}));
	}),
	selectedType: computed(state => state.types.find(item => item.selected)),
	periods: [
		{ id: 3, i18nKey: 'documents.fromThree', selected: true },
		{ id: 6, i18nKey: 'documents.fromSix', selected: false },
		{ id: 9, i18nKey: 'documents.fromNine', selected: false },
		{ id: 12, i18nKey: 'documents.fromTwelve', selected: false },
		{ id: 24, i18nKey: 'documents.fromTwentyFour', selected: false }
	],
	selectPeriod: action((state, payload) => {
		state.periods = state.periods.map(p => ({
			...p,
			selected: p.id === payload
		}));
	}),
	selectedPeriod: computed(state => state.periods.find(item => item.selected))
};
