import list from './list';
import filters from './filters';
import create from './create';
import details from './details';
import detailItems from './detail-items';
import fileManager from './file-manager';
import detailUserGroup from './detail-user-group';
import detailItemsAssign from './detail-items-assign';

export default {
	list,
	filters,
	create,
	details,
	detailItems,
	fileManager,
	detailUserGroup,
	detailItemsAssign
};
