import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { history } = payload;
		const shippingDepots = getStoreState().users.details.items['SHIPPING'];
		const returnDepots = getStoreState().users.details.items['RETURN'];
		const userId = getStoreState().users.details.data.id;
		actions.setLoading(true);
		const promises = [
			services.users.updateById(payload),
			services.users.updatePermission({
				userCode: userId,
				permission: 'MANAGE_EPIPOLI',
				value: payload.user.epipoli || false
			}),
			services.users.updateDepots({ type: 'SHIPPING', userId, depots: shippingDepots }),
			services.users.updateDepots({ type: 'RETURN', userId, depots: returnDepots })
		];
		return Promise.all(promises)
			.then(data => {
				getStoreActions().success('user.updateById');
				//history.push('/admin/users');
				return Promise.resolve({});
			})
			.catch(e => {
				getStoreActions().error('user.updateById');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
