import React, { useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalPaper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: 400
	}
});

const FirstLogin = ({ classes }) => {
	const updateNewsletter = useStoreActions(dispatch => dispatch.me.updateNewsletter);
	const [mail, setMail] = useState('');
	const [show] = useState(true);
	const onButtonClick = value => {
		updateNewsletter({
			mailTo: mail,
			subscription: value,
			requested: true
		});
	};
	const re = /\S+@\S+\.\S+/;
	const canContinue = mail !== '' && re.test(mail);
	return (
		<Modal className={classes.modal} open={show} closeAfterTransition>
			<div className="modal-content">
				<Paper className={classes.modalPaper}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h6">Vuoi sottoscriverti alla Newsletter?</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={mail}
								onChange={e => {
									setMail(e.target.value);
								}}
								fullWidth={true}
								label="Indirizzo email"
							/>
						</Grid>
						<Grid item xs={6}>
							<Button
								disabled={!canContinue}
								onClick={_ => onButtonClick(true)}
								variant="contained"
								color="primary"
								type="submit"
							>
								SI
							</Button>
						</Grid>
						<Grid container item xs={6} justify="flex-end">
							<Button
								onClick={_ => onButtonClick(false)}
								variant="outlined"
								color="primary"
								type="submit"
							>
								NO
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</div>
		</Modal>
	);
};

export default withStyles(styles, { withTheme: true })(FirstLogin);
