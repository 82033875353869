import k from 'k';
import utils from 'services/utils';

export default ({ type, docNumber }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};

	const params = {
		type: type,
		reference: docNumber
	};
	const qParams = utils.createQueryParams(params);
	return utils
		.fetch(`${k.endpoints.apis}/document/detail?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
