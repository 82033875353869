import k from 'k';
import utils from 'services/utils';

export default ({ file, fileName }) => {
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify({
			file,
			fileName
		}),
		headers: utils.createHeaders()
	};
	return utils.fetch(`${k.endpoints.apis}/avatar/file`, requestOptions).then(response => response.text());
};
