import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation, useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { makeStyles, styled } from '@mui/styles';
import {
	Stack,
	Box,
	Typography,
	Stepper,
	Step,
	StepLabel,
	StepConnector,
	stepConnectorClasses,
	Button,
	CircularProgress,
	LinearProgress,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	DialogActions,
	IconButton
} from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SwipeRightAltIcon from '@mui/icons-material/SwipeRightAlt';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import pick from 'lodash/pick';
import utils from 'components/utils';
import clsx from 'clsx';
import SelectUsers from './SelectUsers';
import SelectCart from './SelectCart';
import SelectDepots from './SelectDepots';
import SelectPaymentMethod from './SelectPaymentMethod';
import Summary from './Summary';
import CheckoutStripe from './CheckoutStripe';
import SendFulfillment from './SendFulfillment';

const EvasionCart = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const history = useHistory();
	const caUsers = useStoreState(state => state.cart.evasion.caUsers);
	const items = useStoreState(state => state.cart.evasion.items);
	const listCA = useStoreState(state => state.ca.list.items);
	const depots = useStoreState(state => state.cart.evasion.depots);
	const step = useStoreState(state => state.cart.evasion.stepEvasion);
	const changeStep = useStoreActions(dispatch => dispatch.cart.evasion.setStepEvasion);
	const showEvasionButton = useStoreState(state => state.cart.evasion.showEvasionButton);
	const setShowEvasionButton = useStoreActions(dispatch => dispatch.cart.evasion.setShowEvasionButton);
	const loadingEvasion = useStoreState(state => state.cart.evasion.loadingEvasion);
	const checkEvasion = useStoreActions(dispatch => dispatch.cart.evasion.checkEvasion);
	const loadingOpen = useStoreState(state => state.cart.open.loading);
	const paymentMethod = useStoreState(state => state.cart.evasion.paymentMethod);
	const payloadFulfillment = useStoreState(state => state.cart.evasion.payloadFulfillment);
	const setPayloadFulfillment = useStoreActions(dispatch => dispatch.cart.evasion.setPayloadFulfillment);
	const deselectAll = useStoreActions(dispatch => dispatch.cart.evasion.deselectAll);
	const loadingStripe = useStoreState(state => state.stripe.loading);
	const collectPayment = useStoreActions(dispatch => dispatch.stripe.collectPayment);
	const firstStep = iCan('OPERATE_ON_MULTIPLE_CA') ? 0 : 1;
	const totalFulfillment = depots.filter(d => d.selected).reduce((t, d) => (t = t + d.total), 0);
	const search = useLocation().search;
	const params = new URLSearchParams(search);
	const [cancelOpen, setCancelOpen] = useState(false);
	//Recupero evenutali parametri del pagamento online
	const paymentOnlineEnabled = process.env.REACT_APP_PAYMENTONLINE_ENABLED;
	const payment_intent = params.get('payment_intent');
	const payment_intent_client_secret = params.get('payment_intent_client_secret');
	const redirect_status = params.get('redirect_status');
	const client_secret_fulfillment = sessionStorage.getItem('client_secret_fulfillment')
		? JSON.parse(sessionStorage.getItem('client_secret_fulfillment'))
		: null;

	const steps = [
		translate('orders.evasion.selectUsers'),
		translate('orders.evasion.selectCart'),
		translate('orders.evasion.selectDepot'),
		//paymentOnlineEnabled == 'true' ? translate('orders.evasion.paymentMethod'): translate('orders.evasion.checkFulfillment'),
		translate('orders.evasion.checkFulfillment'),
		translate('orders.evasion.recapFulfillment'),
		translate('orders.evasion.checkout'),
		translate('orders.evasion.resultFulfillment')
	];

	const stepDetail = [
		{
			id: 'user',
			title: translate('orders.evasion.selectUsers'),
			titleBox: translate('orders.evasion.selectUsers'),
			icon: <GroupIcon />,
			helper: translate('orders.evasion.selectUsers')
		},
		{
			id: 'cart',
			title: translate('orders.evasion.selectCart'),
			titleBox: translate('orders.evasion.selectCart'),
			icon: <ShoppingCartCheckoutIcon sx={{ fontSize: '30px', color: theme.palette.primary.main }} />,
			helper: translate('orders.evasion.selectCartHelperText')
		},
		{
			id: 'depot',
			title: translate('orders.evasion.selectDepot'),
			titleBox: translate('orders.evasion.selectDepot'),
			icon: <WarehouseIcon sx={{ fontSize: '30px', color: theme.palette.primary.main }} />,
			helper: translate('orders.evasion.selectDepotHelperText')
		},
		{
			id: 'payment',
			title: translate('orders.evasion.checkFulfillment'),
			titleBox: translate('orders.evasion.selectPaymentMethod'),
			icon: <CreditCardIcon sx={{ fontSize: '30px', color: theme.palette.primary.main }} />,
			helper: translate('orders.evasion.selectPaymentMethodHelperText')
		},
		{
			id: 'recap',
			title: translate('orders.evasion.recapFulfillment'),
			titleBox: translate('orders.evasion.recapFulfillment'),
			icon: <SummarizeIcon sx={{ fontSize: '30px', color: theme.palette.primary.main }} />,
			helper: translate('orders.evasion.recapFulfillmentHelperText')
		},
		{
			id: 'checkout',
			title: translate('orders.evasion.checkout'),
			titleBox: translate('orders.evasion.checkout'),
			icon: <SwipeRightAltIcon sx={{ fontSize: '30px', color: theme.palette.primary.main }} />,
			helper: translate('orders.evasion.checkoutHelperText')
		},
		{
			id: 'send',
			title: translate('orders.evasion.resultFulfillment'),
			titleBox: translate('orders.evasion.resultFulfillment'),
			icon: <TaskAltIcon sx={{ fontSize: '30px', color: theme.palette.primary.main }} />,
			helper: translate('orders.evasion.resultFulfillmentHelperText')
		}
	];

	const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 18
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundImage: 'linear-gradient(95deg, rgb(0 82 155) 0%, rgb(13 102 180) 50%, rgb(58 159 249) 100%)'
			}
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundImage: 'linear-gradient(95deg, rgb(0 82 155) 0%, rgb(13 102 180) 50%, rgb(5 58 105) 100%)'
			}
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			border: 0,
			backgroundColor: theme.palette.custom.greyborder,
			borderRadius: 1
		}
	}));

	const useColorlibStepIconStyles = makeStyles({
		root: {
			backgroundColor: '#ccc',
			zIndex: 1,
			color: '#fff',
			width: 28,
			height: 28,
			display: 'flex',
			borderRadius: '50%',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '18px'
		},
		active: {
			backgroundImage:
				'linear-gradient( 136deg, rgba(0,82,155,255) 0%, rgba(0,82,155,255) 50%, rgba(0,82,155,255) 100%)',
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
		},
		completed: {
			backgroundImage:
				'linear-gradient( 136deg, rgba(0,82,155,255) 0%, rgba(0,82,155,255) 50%, rgba(0,82,155,255) 100%)'
		}
	});

	const CustomStepIcon = props => {
		const classes = useColorlibStepIconStyles();
		const { active, completed } = props;

		const stepIcons = {
			0: <GroupIcon />,
			1: <ShoppingCartCheckoutIcon />,
			2: <WarehouseIcon />,
			//3: paymentOnlineEnabled == 'true' ? <CreditCardIcon /> : <FactCheckIcon />,
			3: <FactCheckIcon />,
			4: <SummarizeIcon />,
			5: <SwipeRightAltIcon />,
			6: <TaskAltIcon />
		};

		return (
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
					[classes.completed]: completed
				})}
			>
				{stepIcons[String(props.icon)]}
			</div>
		);
	};

	//Se carico la prima volta la pagina imposto il primo step
	useEffect(() => {
		//Se l'utente non puà vedere la pagina lo redirigo alla home
		if (!iCan('ORDERS')) window.location.href = '/';
		//Se carico la pagina la prima volta o dal ritorno dal pagamento online
		if (step === -1) {
			/* Rimano al primo step se:
				- non ci sono i dati del pagamento: è un primo caricamento della pagina
				- il client secret non coincide con quello in sessione: è stato fatto un refresh di una pagina di ritorno del pagamento
			*/
			if (
				(!payment_intent && !payment_intent_client_secret && !redirect_status) ||
				(payment_intent &&
					payment_intent_client_secret &&
					redirect_status &&
					payment_intent_client_secret != client_secret_fulfillment)
			) {
				//Pulisco la sessione per sicurezza
				sessionStorage.clear();
				//Setto il primo step
				changeStep(firstStep);
				//Ricarico la pagina
				history.push('fulfillment');
			}
			//Ritorno dal pagamento -> recupero il payload e invio allo step dell'evasione
			if (
				sessionStorage.getItem('payload_fulfillment') &&
				payment_intent &&
				payment_intent_client_secret &&
				redirect_status &&
				payment_intent_client_secret == client_secret_fulfillment &&
				redirect_status == 'succeeded'
			) {
				//Recupero il payload, aggiungo il paymentIntent
				const payload_fulfillment = getSessionPayload();
				payload_fulfillment.paymentIntent = payment_intent;
				setPayloadFulfillment(payload_fulfillment);
				//Invio allo step dell'evasione
				changeStep(6);
			}
		}
	}, []);

	const onBackClick = _ => {
		if (step > firstStep) {
			if (step == 4 && paymentMethod != 2) changeStep(step - 2);
			else changeStep(step - 1);
			setShowEvasionButton(true);
		}
	};

	const onCancelClick = _ => {
		setCancelOpen(true);
	};

	const handleCancel = event => {
		//Ripulisco la sessione
		sessionStorage.clear();
		//Deseleziono tutti i carrelli
		deselectAll();
		//Setto il primo step
		changeStep(firstStep);
		//Chiudo la dialogbox e riparto dal primo step
		setCancelOpen(false);
		//Ricarico la pagina
		history.push('fulfillment');
	};

	const getContinueDisabled = _ => {
		if (step === 0) return listCA.filter(i => i.selected).length === 0;
		if (step === 1) return items.filter(i => i.selected).length === 0;
		if (step === 2)
			return !showEvasionButton || depots.length === 0 || depots.filter(d => d.selected === true).length === 0;
		if (step == 3) return paymentMethod == -1;
		if (step > 4) return true;
		else return depots.filter(i => i.showDeliveries).length === 0;
	};

	const createPayload = _ => {
		const payload = {
			depots: depots
				.filter(d => d.showDeliveries)
				.map(d => ({
					depotCode: d.id,
					shippingType: d.deliveries.find(del => del.selected).shippingRule.code
				})),
			carts: items
				.filter(item => item.selected)
				.map(item => ({
					...pick(item, ['cig', 'chapter', 'cause', 'purchaseOrderDate', 'purchaseNumber']),
					orderType: item.fcd ? 'ZF07' : 'ZF01'
				}))
		};
		if (caUsers.length > 0) {
			payload.ownerUserCode = caUsers.find(i => i.selected).id;
		}

		payload.paymentType = paymentMethod;
		payload.totalFulfillment = totalFulfillment;

		return payload;
	};

	const getSessionPayload = _ => {
		if (!sessionStorage.getItem('payload_fulfillment')) return null;
		const session_storage_payload = JSON.parse(sessionStorage.getItem('payload_fulfillment'));
		const sessionPayload = {
			depots: JSON.parse(session_storage_payload.depots),
			carts: JSON.parse(session_storage_payload.carts),
			ownerUserCode: session_storage_payload.ownerUserCode
				? JSON.parse(session_storage_payload.ownerUserCode)
				: null,
			paymentType: JSON.parse(session_storage_payload.paymentType),
			totalFulfillment: JSON.parse(session_storage_payload.totalFulfillment)
		};

		return sessionPayload;
	};

	const onContinueClick = _ => {
		if (step < 2 || step == 3 || step == 5) changeStep(step + 1);
		//Caso di check o invio evasione
		if (step == 2) {
			const payload = createPayload();
			checkEvasion(payload);
		}
		if (step == 4) {
			if (paymentMethod == 2) changeStep(step + 1);
			else changeStep(step + 2);
		}
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			{/** Titolo pagina */}
			<Stack
				direction="column"
				justifyContent="flex-stat"
				alignItems="flex-start"
				spacing={0}
				variant="wrapperColor"
			>
				<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
					{step !== 5 && (
						<IconButton disabled={step === firstStep || step > 4} onClick={onBackClick}>
							<ArrowBackIcon />
						</IconButton>
					)}
					<Typography variant="titlePage">{translate('common.menu.fulfillment')}</Typography>
					{step !== -1 && (
						<Typography variant="titlePage" sx={{ textTransform: 'lowercase !important' }}>
							{'- ' + stepDetail[step].title}
						</Typography>
					)}
					{/*
					<Button variant="dark" onClick={_ => collectPayment()}>
						paga
					</Button>
					*/}
				</Stack>
			</Stack>

			{/** LinearProgress */}
			{(loadingOpen || loadingEvasion || loadingStripe) && (
				<Box sx={{ width: '100%', marginTop: '-5px !important' }}>
					<LinearProgress sx={{ color: theme.palette.primary.main }} />
				</Box>
			)}

			{/** Stepper */}
			<Box
				sx={{
					width: '100%',
					paddingTop: '20px !important',
					display: { xs: 'none', md: 'inherit', sm: 'inherit', lg: 'inherit' }
				}}
			>
				<Stepper
					activeStep={step - firstStep}
					alternativeLabel
					connector={<ColorlibConnector />}
					sx={{ width: '100%' }}
				>
					{steps
						.filter((s, index) => index >= firstStep)
						.map(label => (
							<Step
								key={label}
								sx={{
									padding: {
										xs: '0 4px !Important',
										md: '0 8px !Important',
										sm: '0 8px !important',
										lg: '0 8px !important'
									}
								}}
							>
								<StepLabel StepIconComponent={CustomStepIcon}>
									<Box sx={{ marginTop: '-10px !important' }}>
										<Typography
											variant="caption"
											sx={{
												fontSize: { xs: '0.85em', md: '1em', sm: '1em', lg: '1em' },
												lineHeight: '0.9em',
												fontWeight: '500'
											}}
										>
											{label}
										</Typography>
									</Box>
								</StepLabel>
							</Step>
						))}
				</Stepper>
			</Box>

			{/** Pulsanti */}
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					marginTop: '20px !important',
					paddingTop: '20px !important',
					borderTop: '1px solid #ECEEEF'
				}}
			>
				<Box sx={{ width: '50%', textAlign: 'left' }}>
					{step !== 5 && (
						<Button
							variant="light"
							startIcon={<KeyboardDoubleArrowLeftIcon />}
							disabled={step === firstStep || step > 4}
							onClick={onBackClick}
						>
							{translate('common.back')}
						</Button>
					)}
					{step == 5 && (
						<Button variant="light" startIcon={<KeyboardDoubleArrowLeftIcon />} onClick={onCancelClick}>
							{translate('common.cancel')}
						</Button>
					)}
				</Box>
				<Box sx={{ width: '50%', textAlign: 'right' }}>
					{loadingEvasion && <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />}
					{!loadingEvasion && step < 5 && (
						<Button
							variant="dark"
							endIcon={<KeyboardDoubleArrowRightIcon />}
							disabled={getContinueDisabled()}
							onClick={onContinueClick}
						>
							{translate('common.continue')}
						</Button>
					)}
				</Box>
			</Stack>

			{/** Componenti Step */}
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'column', lg: 'row' }}
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{
					width: '100%',
					marginTop: '15px !important',
					marginBottom: '10px !important',
					border: '5px solid #eceaea',
					padding: '10px',
					backgroundColor: '#fff'
				}}
			>
				<Box sx={{ width: { xs: '100%', md: '60%', sm: '100%' }, marginTop: '10px !important' }}>
					{loadingOpen && (
						<Typography variant="titleTab">{translate('orders.evasion.loadingCart')}</Typography>
					)}
					{step === 0 && <SelectUsers />}
					{step === 1 && <SelectCart />}
					{step === 2 && <SelectDepots />}
					{step === 3 && <SelectPaymentMethod />}
					{step === 4 && <Summary />}
					{step === 5 && <CheckoutStripe totalFulfillment={totalFulfillment} payload={createPayload()} />}
					{step === 6 && (
						<SendFulfillment
							payload={
								payloadFulfillment &&
								payment_intent &&
								payment_intent_client_secret &&
								redirect_status &&
								payment_intent_client_secret == client_secret_fulfillment &&
								redirect_status == 'succeeded'
									? payloadFulfillment
									: !sessionStorage.getItem('payload_fulfillment') &&
									  !payment_intent &&
									  totalFulfillment > 0
									? createPayload()
									: null
							}
						/>
					)}
				</Box>

				{/* Descrizione step */}
				{step !== -1 && (
					<Stack
						direction="column"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={0.5}
						sx={{
							width: { xs: '100%', md: '40%', sm: '100%' },
							border: '5px solid #d1e8fc',
							backgroundColor: '#f6fcff',
							padding: '20px',
							margin: { xs: '10px 0 0 0 !important', md: '30px !important', sm: '10px 0 0 0 !important' }
						}}
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="flex-start"
							spacing={0}
							sx={{ width: '100%' }}
						>
							<Typography variant="titlePage" sx={{ color: theme.palette.primary.main }}>
								{stepDetail[step].titleBox}
							</Typography>
							{stepDetail[step].icon}
						</Stack>
						<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
							{stepDetail[step].helper.split('<br>').map((line, index) => (
								<Typography
									key={index}
									variant="textTable"
									sx={{ fontWeight: '600 !important', lineHeight: '1.3em', color: '#000' }}
								>
									{line}
								</Typography>
							))}
						</Stack>
					</Stack>
				)}
			</Stack>

			{/* Dialog box per annullamento evasione */}
			<Dialog
				open={cancelOpen}
				onClose={() => {
					setCancelOpen(false);
				}}
				aria-labelledby="cancel-dialog-title"
			>
				<DialogTitle id="cancel-dialog-title">
					<Box
						sx={{
							borderWidth: '0 0 1px 0',
							borderStyle: 'solid',
							borderColor: theme.palette.primary.main,
							width: '100%'
						}}
					>
						<Typography
							variant="titleSextion"
							sx={{ fontWeight: '600', color: theme.palette.primary.main }}
						>
							{translate('orders.evasion.sending')}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Typography variant="titleSextion" sx={{ fontWeight: '600 !important' }}>
							{translate('orders.evasion.sendingCancel')}
						</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={0}
						sx={{
							width: '100%',
							borderWidth: '1px 0 0 0',
							borderStyle: 'solid',
							borderColor: theme.palette.primary.main,
							paddingTop: '10px !important'
						}}
					>
						<Button variant="light" autoFocus onClick={() => setCancelOpen(false)}>
							{translate('common.close')}
						</Button>
						<Button variant="dark" autoFocus onClick={handleCancel}>
							{translate('common.confirm')}
						</Button>
					</Stack>
				</DialogActions>
			</Dialog>
		</Stack>
	);
};

export default EvasionCart;
