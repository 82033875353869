import React from 'react';
import Sortable from './Sortable';
import NotSortable from './NotSortable';
import Empty from './Empty';

const allCols = [
	{
		id: 'id',
		comp: _ => <Sortable id="id" key="headCell_id" label="promo.tables.id" />
	},
	{
		id: 'sapId',
		comp: _ => <Sortable id="sapPromoId" key="headCell_sapId" label="promo.tables.sapId" />
	},
	{
		id: 'sapName',
		comp: _ => <Sortable id="nameOrder" key="headCell_sapName" label="promo.tables.sapName" />
	},
	{
		id: 'enabled',
		comp: _ => <NotSortable id="enabled" key="headCell_enabled" label="promo.tables.enabled" />
	},
	{
		id: 'enabledCatalog',
		comp: _ => <NotSortable id="enabledCatalog" key="headCell_enabledCatalog" label="promo.tables.status" />
	},
	{
		id: 'enabledWishlist',
		comp: _ => (
			<NotSortable id="enabledWishlist" key="headCell_enabledWishlist" label="promo.tables.wishlistStatus" />
		)
	},
	{
		id: 'approvalRequest',
		comp: _ => (
			<NotSortable id="approvalRequest" key="headCell_approvalRequest" label="promo.tables.approvalRequest" />
		)
	},
	{
		id: 'startDate',
		comp: _ => <Sortable id="startDate" key="headCell_startDate" label="promo.tables.startDate" />
	},
	{
		id: 'endDate',
		comp: _ => <Sortable id="endDate" key="headCell_endDate" label="promo.tables.endDate" />
	},
	{
		id: 'edit',
		comp: _ => <Empty id="endDate" key="headCell_edit" label="promo.tables.edit" />
	}
];

export default allCols;
