import k from 'k';
import utils from 'services/utils';

export default (type, word) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = word ? { type: type, word: word } : { type: type };
	const qParams = utils.createQueryParams(params);

	return utils
		.fetch(`${k.endpoints.apis}/thema/tree/?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
