import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, CircularProgress, Stepper, Step, StepLabel, IconButton } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AssistantIcon from '@mui/icons-material/Assistant';
import StreetviewIcon from '@mui/icons-material/Streetview';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';
import moment from 'moment';

const ShippingTracking = props => {
	const { deliveryID, onClose } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const items = useStoreState(state => state.order.shippingStepper.items).sort(
		(a, b) => a.generalStatus - b.generalStatus
	);
	const activeStep = useStoreState(state => state.order.shippingStepper.activeStep);
	const loading = useStoreState(state => state.order.shippingStepper.loading);
	const tracking = useStoreActions(dispatch => dispatch.order.shippingStepper.sendRequest);
	const [matches, setMatches] = useState(window.matchMedia('screen and (min-width:1024px)').matches);

	const steps = [
		{ id: 1, label: 'orders.stepper.processor', icon: <MoreHorizIcon /> },
		{ id: 2, label: 'orders.stepper.takenover', icon: <AssistantIcon /> },
		{ id: 3, label: 'orders.stepper.underprocessing', icon: <StreetviewIcon /> },
		{ id: 4, label: 'orders.stepper.docissuer', icon: <EventNoteIcon /> },
		{ id: 5, label: 'orders.stepper.takenbycourier', icon: <LocalShippingIcon /> },
		{ id: 6, label: 'orders.stepper.delivering', icon: <KeyboardTabIcon /> },
		{ id: 7, label: 'orders.stepper.delivered', icon: <WhereToVoteIcon /> }
	];

	const CustomConnector = styled(StepConnector)(({ theme }) => ({
		width: '50px',
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				borderColor: theme.palette.primary.main
			}
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				borderColor: theme.palette.primary.main
			}
		},
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#b1b1b1',
			borderTopWidth: 3,
			borderRadius: 1
		}
	}));

	const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 28,
		height: 28,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		...(ownerState.active && {
			backgroundImage:
				'linear-gradient( 136deg, rgba(0,82,155,255) 0%, rgba(0,82,155,255) 50%, rgba(0,82,155,255) 100%)',
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
		}),
		...(ownerState.completed && {
			backgroundImage:
				'linear-gradient( 136deg, rgba(0,82,155,255) 0%, rgba(0,82,155,255) 50%, rgba(0,82,155,255) 100%)'
		})
	}));

	const CustomStepIcon = props => {
		const { active, completed, className } = props;
		const stepIcons = {
			1: <MoreHorizIcon />,
			2: <AssistantIcon />,
			3: <StreetviewIcon />,
			4: <EventNoteIcon />,
			5: <LocalShippingIcon />,
			6: <KeyboardTabIcon />,
			7: <WhereToVoteIcon />
		};
		return (
			<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
				{stepIcons[String(props.icon)]}
			</ColorlibStepIconRoot>
		);
	};

	useEffect(
		_ => {
			tracking(deliveryID);
		},
		[deliveryID]
	);

	useEffect(_ => {
		window.matchMedia('screen and (min-width: 1024px)').addEventListener('change', e => setMatches(e.matches));
	}, []);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={{ xs: 1, md: 2, sm: 1 }}
			sx={{ border: '1px solid #333', padding: '10px 20px' }}
		>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={0}
				sx={{
					margin: '5px 0 !important',
					borderWidth: '0 0 1px 0',
					borderStyle: 'solid',
					borderColor: theme.palette.primary.main,
					width: '100%'
				}}
			>
				<Box sx={{ width: '70%' }}>
					<Typography variant="text" sx={{ fontSize: '18px !important', fontWeight: '600' }}>
						{translate('orders.tables.shippingTrackingTitle') + ': ' + deliveryID}
					</Typography>
				</Box>
				<Box sx={{ textAlign: 'right', width: '30%' }}>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</Stack>
			{loading && (
				<Box sx={{ width: '100%', textAlign: 'center', padding: '5px 0 !important' }}>
					<CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />
				</Box>
			)}
			{!loading && items.length === 0 && (
				<Typography
					variant="text"
					sx={{
						fontSize: { xs: '14px', md: '16px', sm: '15px' },
						fontWeight: '600',
						color: theme.palette.custom.red
					}}
				>
					{translate('orders.tables.noTracking')}
				</Typography>
			)}
			{!loading && items.length > 0 && (
				<Stepper
					activeStep={activeStep}
					orientation={matches ? 'horizontal' : 'vertical'}
					connector={<CustomConnector />}
				>
					{steps.map(step => (
						<Step key={step.id}>
							<StepLabel
								StepIconComponent={CustomStepIcon}
								optional={
									items.find(item => item.generalStatus === step.id) && (
										<Typography variant="text">
											{step.id !== 5 &&
												step.id !== 6 &&
												moment(
													items.find(item => item.generalStatus === step.id).statusDate
												).format('L [\n][ore] HH:mm')}
											{step.id === 5 &&
												moment(
													items.find(item => item.generalStatus === step.id).statusDate
												).format('L [\n][ore] HH:mm') +
													' (' +
													items.find(item => item.generalStatus === 5).courier +
													')'}
											{step.id === 6 && (
												<a
													href={items.find(item => item.generalStatus === 6).trackingLink}
													target="_blank"
													rel="noopener noreferrer"
												>
													{translate('totalPriceOrder.orders.stepper.')}
													<br />
													{items.find(item => item.generalStatus === 6).deliveryNumber}
												</a>
											)}
										</Typography>
									)
								}
							>
								<Typography variant="text">{translate(step.label)}</Typography>
							</StepLabel>
						</Step>
					))}
				</Stepper>
			)}
		</Stack>
	);
};

export default ShippingTracking;
