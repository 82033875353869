const getDepotProp = ({ bookDepots, id, prop }) => {
	const match = bookDepots.find(d => d.id === id);
	return match ? match[prop] : null;
};

const matchDepots = ({ bookDepots, userDepots }) => {
	return userDepots
		.map(depot => ({
			...depot,
			description: getDepotProp({ bookDepots, id: depot.id, prop: 'description' }),
			stock: getDepotProp({ bookDepots, id: depot.id, prop: 'stock' }),
			futureDisp: getDepotProp({ bookDepots, id: depot.id, prop: 'futureDisp' }),
			preload: getDepotProp({ bookDepots, id: depot.id, prop: 'preload' })
		}))
		.filter(depot => depot.stock !== null);
};

const getDepotWithHighestStock = depots => depots.sort((a, b) => b.stock - a.stock).slice(0, 1);
const getFirstDepot = depots => depots.find(depot => depot.id !== 'FS01');

const extractDepots = ({ mainDepot, depots, type, ordinable }) => {
	const noStock = depots.every(depot => depot.stock === 0);
	const isOrderable = ordinable === 2;
	if (type === 100) {
		const fastHub = depots.find(depot => depot.id === 'FS01');
		return [fastHub, null];
	} else {
		if (noStock) {
			// all depots has 0 as stock?
			if (isOrderable) {
				// ean is orderable?
				if (mainDepot.id === 'FS01') {
					// fast hub is the main depot?
					if (depots.findIndex(depot => depot.id === 'FS01') === -1) {
						// fast hub is not in selected depots?
						// console.log('Scenario AA');
						const secondDepot = getFirstDepot(depots);
						return [null, secondDepot];
					} else {
						// console.log('Scenario FF');
						const fastHub = depots.find(depot => depot.id === 'FS01');
						const secondDepot = getFirstDepot(depots);
						return [fastHub, secondDepot];
					}
				} else {
					if (depots.findIndex(depot => depot.id === 'FS01') === -1) {
						// fast hub is not in selected depots?
						if (depots.findIndex(depot => depot.id === mainDepot.id) === -1) {
							// main depot is not in selected depots?
							// console.log('Scenario BB');
							const secondDepot = getFirstDepot(depots);
							return [null, secondDepot];
						} else {
							// console.log('Scenario EE');
							const secondDepot = depots.find(depot => depot.id === mainDepot.id);
							return [null, secondDepot];
						}
					} else {
						if (depots.findIndex(depot => depot.id === mainDepot.id) === -1) {
							// main depot is not in selected depots?
							// console.log('Scenario DD');
							const fastHub = depots.find(depot => depot.id === 'FS01');
							const secondDepot = getFirstDepot(depots);
							return [fastHub, secondDepot];
						} else {
							// console.log('Scenario CC');
							const fastHub = depots.find(depot => depot.id === 'FS01');
							const secondDepot = depots.find(depot => depot.id === mainDepot.id);
							return [fastHub, secondDepot];
						}
					}
				}
			} else {
				// ean is not orderable?
				// console.log('Scenario G');
				return [null, null];
			}
		} else {
			if (mainDepot.id === 'FS01') {
				// fast hub is the main depot?
				if (depots.findIndex(depot => depot.id === 'FS01') === -1) {
					// fast hub is not in selected depots?
					// console.log('Scenario A');
					const [depotWithHighestStock] = getDepotWithHighestStock(depots);
					return [null, depotWithHighestStock];
				} else {
					// console.log('Scenario F');
					const fastHub = depots.find(depot => depot.id === 'FS01');
					const otherDepots = depots.filter(depot => depot.id !== 'FS01');
					const [depotWithHighestStock] = getDepotWithHighestStock(otherDepots);
					return [fastHub, depotWithHighestStock];
				}
			} else {
				if (depots.findIndex(depot => depot.id === 'FS01') === -1) {
					// fast hub is not in selected depots?
					if (depots.findIndex(depot => depot.id === mainDepot.id) === -1) {
						// main depot is not in selected depots?
						// console.log('Scenario B');
						const [depotWithHighestStock] = getDepotWithHighestStock(depots);
						return [null, depotWithHighestStock];
					} else {
						// console.log('Scenario E');
						const secondDepot = depots.find(depot => depot.id === mainDepot.id);
						return [null, secondDepot];
					}
				} else {
					if (depots.findIndex(depot => depot.id === mainDepot.id) === -1) {
						// main depot is not in selected depots?
						// console.log('Scenario D');
						const fastHub = depots.find(depot => depot.id === 'FS01');
						const otherDepots = depots.filter(depot => depot.id !== 'FS01');
						const [depotWithHighestStock] = getDepotWithHighestStock(otherDepots);
						return [fastHub, depotWithHighestStock];
					} else {
						// console.log('Scenario C');
						const fastHub = depots.find(depot => depot.id === 'FS01');
						const secondDepot = depots.find(depot => depot.id === mainDepot.id);
						return [fastHub, secondDepot];
					}
				}
			}
		}
	}
};

export default ({ mainDepot, bookDepots, userDepots, type, ordinable }) => {
	const depots = matchDepots({ bookDepots, userDepots });
	return extractDepots({ mainDepot, depots, type, ordinable });
};
