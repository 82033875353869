import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import Typography from '@mui/material/Typography';

const RankingComp = ({ position }) => {
	return (
		<StyledTableCell>
			<Typography component="p" style={{ fontSize: 14, paddingLeft: 4 }}>
				# {position}
			</Typography>
		</StyledTableCell>
	);
};

const Ranking = memo(
	({ item }) => <RankingComp position={item.position} />,
	(p, n) => p.item.position === n.item.position
);

export default Ranking;
