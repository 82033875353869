import { action, thunk, thunkOn } from 'easy-peasy';

export default {
	data: null,
	save: action((state, payload) => {
		state.data = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		services.specialDelivery
			.getCurrent()
			.then(data => {
				actions.save(data);
			})
			.catch(e => {
				console.log(e);
			});
	}),
	reloadVisibilityOnUpdate: thunkOn(
		(actions, storeActions) => [storeActions.BOspecialDelivery.update.sendRequest],
		async (actions, target, helpers) => {
			actions.load();
		}
	)
};
