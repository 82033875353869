import { action, thunk } from 'easy-peasy';

export default {
	data: null,
	save: action((state, payload) => {
		state.data = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		services.ca
			.getMine()
			.then(data => {
				actions.save(data.item);
			})
			.catch(e => {
				getStoreActions().error('ca.getMine');
			});
	})
};
