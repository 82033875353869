export default theme => ({
	root: {
		display: 'flex'
	},
	card: {
		maxWidth: 300,
		margin: 'auto',
		transition: '0.3s'
	},
	content: {
		textAlign: 'left',
		padding: theme.spacing(0),
		'&:last-child': {
			paddingBottom: 0
		}
	},
	addButton: {
		textTransform: 'none',
		justifyContent: 'left',
		padding: theme.spacing(3)
	},
	iconsSize: {
		fontSize: 18
	},
	dividerAria: {
		margin: theme.spacing(2, 0)
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
});
