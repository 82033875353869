import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import utils from 'components/utils';
import k from 'k';

const withColumns = WrappedComponent => {
	const breakpointColumnNumberMap = {
		xs: 12,
		md: 12,
		mdWithFilters: 4,
		lg: 13,
		lgWithFilters: 7
	};
	return props => {
		const theme = useTheme();
		const catalogColumns = utils.useUserData('catalogColumns', [], true);
		const availableDepots = utils.useFilteredDepots();
		const iCan = utils.usePermissions();
		const flagOneClick = utils.useUserData('flagOneClick', false);
		const filtersOpen = useStoreState(state => state.catalog.filters.open);
		const filters = useStoreState(state => state.catalog.filters.list);
		let colCount = breakpointColumnNumberMap['xs'];
		if (useMediaQuery(theme.breakpoints.up('md')) && !filtersOpen) colCount = breakpointColumnNumberMap['md'];
		if (useMediaQuery(theme.breakpoints.up('md')) && filtersOpen)
			colCount = breakpointColumnNumberMap['mdWithFilters'];
		if (useMediaQuery(theme.breakpoints.up('lg')) && !filtersOpen) colCount = breakpointColumnNumberMap['lg'];
		if (useMediaQuery(theme.breakpoints.up('lg')) && filtersOpen)
			colCount = breakpointColumnNumberMap['lgWithFilters'];
		const visibleCols =
			catalogColumns && catalogColumns.length > 0 ? catalogColumns.slice(0, colCount) : [...k.searchResultsCols];
		const titleIndex = visibleCols.indexOf('title');
		if (titleIndex !== -1) visibleCols.splice(titleIndex, 0, 'preload', 'availability');
		if (iCan('BUY') && availableDepots.length > 0) visibleCols.splice(0, 0, 'selection');
		if (
			(filters.selectedScope !== null && filters.selectedScope.id === 'rankings') ||
			(filters.badgeRanking !== null && filters.badgeRanking)
		)
			visibleCols.splice(0, 0, 'ranking');
		//	if (flagOneClick) visibleCols.push('oneClick'); -> OneClick rimosso per tutti
		return <WrappedComponent {...props} visibleCols={visibleCols} />;
	};
};

export default withColumns;
