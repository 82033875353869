import promotionSlide from './promotion-slide';
import banners from './banners';
import videos from './videos';
import promotions from './promotions';
import bestSellers from './best-sellers';

export default {
	promotionSlide,
	banners,
	videos,
	promotions,
	bestSellers
};
