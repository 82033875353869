import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import styles from './styles';
import utils from 'components/utils';
import clsx from 'clsx';

const ResponsiveSideContent = ({ children, classes, open, onClose, isSticky, style, idToCheck }) => {
	const menuOpen = useStoreState(state => state.menu.mainOpen);
	const [widthStyle, setWidth] = useState({});
	useEffect(
		_ => {
			const onResize = _ => {
				const elem = document.getElementById(idToCheck);
				if (!elem) return;
				const depWidth = elem.getBoundingClientRect().width;
				const stickyWidth = menuOpen ? window.innerWidth - 260 - depWidth : window.innerWidth - 116 - depWidth;
				setWidth({
					width: isSticky ? stickyWidth : 'inherit'
				});
			};
			window.addEventListener('resize', onResize);
			onResize();
			return _ => {
				window.removeEventListener('resize', onResize);
			};
		},
		[menuOpen, isSticky, idToCheck]
	);
	useEffect(
		_ => {
			const keyPress = e => {
				if (e.key === 'Escape' && open) {
					onClose();
				}
			};
			document.addEventListener('keydown', keyPress);
			return _ => {
				document.removeEventListener('keydown', keyPress);
			};
		},
		[open, onClose]
	);

	return (
		<Fragment>
			<Hidden smDown>
				<Grid
					item
					md={5}
					style={{ ...style, ...widthStyle }}
					className={clsx({ [classes.sticky]: isSticky, [classes.elements]: true })}
				>
					{children}
				</Grid>
			</Hidden>
			<Hidden mdUp>
				<Drawer anchor="bottom" open={open} onClose={onClose}>
					{children}
				</Drawer>
			</Hidden>
		</Fragment>
	);
};

const StickyRSC = utils.withSticky(ResponsiveSideContent, 90);

export default withStyles(styles, { withTheme: true })(StickyRSC);
