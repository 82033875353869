import React, { useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Avatar, Chip, Box, IconButton, Popover } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CartInfo from '../CartInfo';
import utils from 'components/utils';

const OpenOrdersInfoDetail = props => {
	const { order } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const fulfillmentFailed = useStoreState(state => state.cart.open.fulfillmentFailed);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const showFavourite =
		iCan('OPERATE_ON_CARTS') &&
		order.type === null &&
		!order.isDefault &&
		!order.isSAP &&
		(!order.fcd || order.referenceCartId);

	const hasFulfillmentFailed = () => {
		//Verifica se l'ordine ha evasioni fallite (l'invio a SAP è fallito e si è nella fase di gestione BOOMI)
		/*
		const checkFulfillmentProgress = order.name === '' ?
			fulfillmentFailed.filter(f => 
				f.orderNumber === '' && 
				(order.cig ? f.cig == order.cig : f.cig == '') && 
				(order.chapter ? f.chapter == order.chapter : f.chapter == '')) :
			fulfillmentFailed.filter(f => 
				f.orderNumber == order.purchaseNumber && 
				(order.cig ? f.cig == order.cig : f.cig == '') && 
				(order.chapter ? f.chapter == order.chapter : f.chapter == ''));		
		return checkFulfillmentProgress.length > 0;
		*/
		return (
			fulfillmentFailed.filter(f =>
				order.name === '' ? f.orderNumber === '' : f.orderNumber.split(' - ')[0] === order.id.toString()
			).length > 0
		);

		//return order.name === '' ? fulfillmentFailed.includes(0) : fulfillmentFailed.includes(order.id.toString());
	};

	const chips = [
		{
			/*La chip del carrello predefinito non viene più mostrata
			label: translate('orders.defaultCart'),
			avatar: 'DC',
			condiction: order.isDefault,
			color: theme.palette.secondary.main
		*/
		},
		{
			label: 'fcd',
			avatar: 'FCD',
			condiction: order.fcd,
			color: theme.palette.custom.black
		},
		{
			label: translate('catalog.book2c'),
			avatar: 'InL',
			condiction: order.type === 'BOOK2C',
			color: theme.palette.custom.book2c
		},
		{
			label: translate('orders.warningEvasionCartChip'),
			icon: <ProductionQuantityLimitsIcon sx={{ fontSize: '18px', color: '#fff !important' }} />,
			condiction: order.showWarning,
			color: theme.palette.custom.orange
		},
		{
			label: translate('orders.processableTitles'),
			avatar: 'CE',
			condiction: order.resumeOrder.sumOfProcessableCatalog,
			color: theme.palette.custom.green
		},
		{
			label: translate('orders.evasion.fulfillmentInProgess'),
			avatar: 'IE',
			condiction: hasFulfillmentFailed(),
			color: theme.palette.custom.disabled
		},
		{
			label: translate('orders.favouriteCart'),
			icon: <StarIcon sx={{ fontSize: '18px', color: '#fff !important' }} />,
			condiction: order.favourite && showFavourite,
			color: theme.palette.primary.main
		}
	];

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={0}
			sx={{ width: '100%', margin: '5px 0' }}
		>
			{iCan('OPERATE_PA_CARTS') && !order.isSAP && !order.isDefault && order.type !== 'BOOK2C' && (
				<Fragment>
					<IconButton sx={{ display: { xs: 'inherit', md: 'none', sm: 'inherit' } }} onClick={handleClick}>
						<AssignmentIcon sx={{ fontSize: '28px', color: theme.palette.custom.black }} />
					</IconButton>
					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'center',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'center',
							horizontal: 'left'
						}}
					>
						<CartInfo order={order} />
					</Popover>
				</Fragment>
			)}
			{chips.map((chip, index) => (
				<Box key={index} sx={{ display: chip.condiction ? 'inline-block' : 'none' }}>
					<Chip
						size="small"
						avatar={
							chip.avatar ? (
								<Avatar
									sx={{
										backgroundColor: '#fff !important',
										borderWidth: '0px',
										color: chip.color + ' !important'
									}}
								>
									{chip.avatar}
								</Avatar>
							) : null
						}
						icon={chip.icon ? chip.icon : null}
						label={chip.label}
						sx={{
							backgroundColor: chip.color,
							color: '#fff !important',
							margin: '2px',
							//padding:'0 !important',
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: chip.color,
							maxWidth: '150px'
						}}
					/>
				</Box>
			))}
		</Stack>
	);
};

export default OpenOrdersInfoDetail;
