import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import utils from 'components/utils';
import moment from 'moment';

const WishlistInfoComp = ({ id, isDefault, name, type, lastUpdate }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const titleStyle = type === 'BOOK2C' ? { color: 'rgba(57,172,233,255)' } : {};
	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
			<Typography
				variant="subtitle2"
				sx={{ color: type === 'BOOK2C' ? theme.palette.custom.book2c : theme.palette.primary.black }}
			>
				{!isDefault ? name : translate('orders.defaultCart')}
			</Typography>

			<Typography variant="body2">{moment(lastUpdate).format('L')}</Typography>
		</Stack>
	);
};

const WishlistInfo = memo(
	({ item }) => (
		<WishlistInfoComp
			id={item.id}
			isDefault={item.isDefault}
			name={item.name}
			type={item.type}
			lastUpdate={item.lastModifiedDate}
		/>
	),
	(p, n) =>
		p.item.id === n.item.id &&
		p.item.isDefault === n.item.isDefault &&
		p.item.name === n.item.name &&
		p.item.type === n.item.type &&
		p.item.lastModifiedDate === n.item.lastModifiedDate
);

export default WishlistInfo;
