import { alpha } from '@mui/material/styles';

export default theme => ({
	search: {
		display: 'flex',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.black, 0.25)
		},
		width: '100%'
	},
	searchIcon: {
		padding: theme.spacing(1, 0, 0, 1),
		height: '100%',
		pointerEvents: 'none'
	},
	autocompleteInput: {
		'&:first-child': {
			paddingTop: 4
		}
	}
});
