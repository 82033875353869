import React, { Fragment, useEffect, useState, forwardRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Button,
	Grid,
	Table,
	TableCell,
	TableContainer,
	TableBody,
	Paper,
	TableHead,
	TableRow,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	useMediaQuery,
	Select,
	MenuItem,
	TextField,
	Typography,
	FormControl,
	InputLabel,
	Tooltip,
	InputAdornment,
	CircularProgress
} from '@mui/material';
import utils from 'components/utils';
import Card from 'components/Card';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, KeyboardDatePicker, LocalizationProvider, MuiPickersUtilsProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Loader from 'components/Loader';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	topScrollPaper: {
		alignItems: 'flex-start'
	},
	topPaperScrollBody: {
		verticalAlign: 'top'
	}
});
const Health = props => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const classes = useStyles();

	const loadingAll = useStoreState(state => state.health.loadingAll);
	const allHealthInfo = useStoreState(state => state.health.allHealthInfo);
	const loadAllHealthInfo = useStoreActions(dispatch => dispatch.health.loadAllHealthInfo);
	const loadingCreate = useStoreState(state => state.health.loadingCreate);
	const createNewStatus = useStoreActions(dispatch => dispatch.health.createNewStatus);
	const loadingDelete = useStoreState(state => state.health.loadingDelete);
	const deleteStatus = useStoreActions(dispatch => dispatch.health.deleteStatus);

	const [open, setOpen] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);

	const [id, setId] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [scope, setScope] = useState('');
	const [type, setType] = useState('');
	const [datefrom, setDatefrom] = useState(null);
	const [dateto, setDateto] = useState(null);
	const [isDateExceeded, setIsDateExceeded] = useState(false);
	const newStatus = {
		id: id,
		title: title,
		description: description,
		scope: scope,
		type: type,
		datefrom: datefrom,
		dateto: dateto
	};

	const statusFontSize = 30;
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
	const textFieldWidth = '230px';
	const styleMenuItemType = {
		paddingTop: theme.spacing(0.5),
		paddingLeft: '5px'
	};

	const colorMap = {
		OK: '#006600',
		KO: '#ff0000',
		DOWN: '#D9B00D'
	};
	const statusMap = {
		OK: <CheckCircleIcon style={{ color: colorMap.OK, fontSize: statusFontSize }} />,
		KO: <ErrorIcon style={{ color: colorMap.KO, fontSize: statusFontSize }} />,
		DOWN: <WarningIcon style={{ color: colorMap.DOWN, fontSize: statusFontSize }} />
	};
	const typeMap = {
		error: translate('health.typeError'),
		active: translate('health.typeActive'),
		maintenance: translate('health.typeMaintenance')
	};
	const scopeMap = {
		application: translate('health.application'),
		logistics: translate('health.logistics'),
		transport: translate('health.transport'),
		other: translate('health.other')
	};

	const disabled = title === '' || scope === '' || type === '' || datefrom === '';
	const alertConfirm = !id && type === 'error';
	const isInvalidDateFrom = moment(datefrom).format('DD/MM/YYYY HH:mm').length === 1;
	const isInvalidDateTo = dateto && moment(dateto).format('DD/MM/YYYY HH:mm').length === 1;

	useEffect(_ => {
		loadAllHealthInfo();
	}, []);

	const handleOpen = health => {
		setId(health.id ? health.id : '');
		setTitle(health.title ? health.title : '');
		setDescription(health.description ? health.description : '');
		setScope(health.scope ? health.scope : '');
		setType(health.type !== 'click' ? health.type : '');
		setDatefrom(health.datefrom ? moment(health.datefrom) : moment());
		setDateto(health.dateto ? moment(health.dateto) : null);
		setOpen(true);
	};
	const handleClose = _ => {
		setOpen(false);
	};
	const handleOpenConfirm = _ => {
		setOpenConfirm(true);
	};
	const handleCloseConfirm = _ => {
		setOpenConfirm(false);
	};
	const handleSaveNotice = _ => {
		createNewStatus(newStatus);
		if (!id) handleClose();
		handleCloseConfirm();
	};
	const handleDeleteNotice = _ => {
		deleteStatus(id);
		handleClose();
		handleCloseConfirm();
	};

	const handleDatefrom = date => {
		setDatefrom(date);
		setIsDateExceeded(date && dateto && date.isAfter(dateto));
	};
	const handleDateto = date => {
		setDateto(date);
		setIsDateExceeded(datefrom && date && datefrom.isAfter(date));
	};

	return (
		<Fragment>
			{/** --------------- TABLE --------------- */}
			<Grid container>
				<Card type="primary" title={translate('common.menu.health')} style={{ marginTop: 0 }}>
					<Button
						onClick={handleOpen}
						variant="outlined"
						style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
					>
						{translate('health.createNew')}
					</Button>
					{loadingAll && <Loader />}
					{!loadingAll && (
						<TableContainer component={Paper}>
							<Table>
								<TableHead style={{ backgroundColor: 'rgb(248 248 248)' }}>
									<TableRow>
										<TableCell></TableCell>
										<TableCell>{translate('health.scope')}</TableCell>
										<TableCell>{translate('health.type')}</TableCell>
										<TableCell>{translate('health.title')}</TableCell>
										<TableCell>{translate('health.description')}</TableCell>
										<TableCell>{translate('health.datefrom')}</TableCell>
										<TableCell>{translate('health.dateto')}</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{allHealthInfo.map((health, index) => (
										<TableRow key={index}>
											<TableCell>{statusMap[health.status]}</TableCell>
											<TableCell>{scopeMap[health.scope]}</TableCell>
											<TableCell>{typeMap[health.type]}</TableCell>
											<TableCell style={{ color: colorMap[health.status] }}>
												{health.title}
											</TableCell>
											<TableCell
												style={{
													whiteSpace: 'normal',
													wordWrap: 'break-word'
												}}
											>
												{health.description}
											</TableCell>
											<TableCell>{moment(health.datefrom).format('DD/MM/YYYY HH:mm')}</TableCell>
											<TableCell>
												{health.dateto
													? moment(health.dateto).format('DD/MM/YYYY HH:mm')
													: translate('health.datemissing')}
											</TableCell>
											<TableCell>
												<Tooltip title={translate('health.edit')} placement="top">
													<IconButton onClick={_ => handleOpen(health)}>
														<EditIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Card>
			</Grid>

			{/** --------------- MODAL ADD/EDIT --------------- */}
			<Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
				<DialogTitle>
					<Typography variant="titleSection">
						{id ? translate('health.editStatus') : translate('health.createNewStatus')}
					</Typography>
					<IconButton
						style={{
							position: 'absolute',
							right: theme.spacing(1),
							top: theme.spacing(1),
							color: theme.palette.grey[500]
						}}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={3}>
						<Grid item sm={6} xs={12} container justifyContent="center">
							<TextField
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={_ => setTitle('')}>
												<CloseIcon fontSize="small" />
											</IconButton>
										</InputAdornment>
									)
								}}
								required
								label={translate('health.title')}
								style={{ width: textFieldWidth }}
								value={title}
								onChange={event => setTitle(event.target.value)}
							/>
						</Grid>
						<Grid item sm={6} xs={12} container justifyContent="center">
							<TextField
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={_ => setDescription('')}>
												<CloseIcon fontSize="small" />
											</IconButton>
										</InputAdornment>
									)
								}}
								label={translate('health.description')}
								multiline
								maxRows={4}
								style={{ width: textFieldWidth }}
								value={description}
								onChange={event => setDescription(event.target.value)}
							/>
						</Grid>
						<Grid item sm={6} xs={12} container justifyContent="center">
							<FormControl style={{ width: textFieldWidth }} required>
								<InputLabel id="scope-label">{translate('health.scope')}</InputLabel>
								<Select
									disabled={id !== ''}
									labelId="scope-label"
									value={scope}
									onChange={event => setScope(event.target.value)}
								>
									<MenuItem value={'application'}>{translate('health.application')}</MenuItem>
									<MenuItem value={'logistics'}>{translate('health.logistics')}</MenuItem>
									<MenuItem value={'transport'}>{translate('health.transport')}</MenuItem>
									<MenuItem value={'other'}>{translate('health.other')}</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item sm={6} xs={12} container justifyContent="center">
							<FormControl style={{ width: textFieldWidth }} required>
								<InputLabel id="type-label">{translate('health.type')}</InputLabel>
								<Select
									disabled={id !== ''}
									labelId="type-label"
									value={type}
									onChange={event => setType(event.target.value)}
								>
									<MenuItem value={'active'}>
										<Grid container alignItems="center">
											<Grid item xs={2} style={styleMenuItemType}>
												<CheckCircleIcon fontSize="inherit" style={{ color: colorMap.OK }} />
											</Grid>
											<Grid item xs={10}>
												<Typography style={{ color: colorMap.OK }}>
													{translate('health.typeActive')}
												</Typography>
											</Grid>
										</Grid>
									</MenuItem>
									<MenuItem value={'maintenance'}>
										<Grid container alignItems="center">
											<Grid item xs={2} style={styleMenuItemType}>
												<WarningIcon fontSize="inherit" style={{ color: colorMap.DOWN }} />
											</Grid>
											<Grid item xs={10}>
												<Typography style={{ color: colorMap.DOWN }}>
													{translate('health.typeMaintenance')}
												</Typography>
											</Grid>
										</Grid>
									</MenuItem>
									<MenuItem value={'error'}>
										<Grid container alignItems="center">
											<Grid item xs={2} style={styleMenuItemType}>
												<ErrorIcon fontSize="inherit" style={{ color: colorMap.KO }} />
											</Grid>
											<Grid item xs={10}>
												<Typography style={{ color: colorMap.KO }}>
													{translate('health.typeError')}
												</Typography>
											</Grid>
										</Grid>
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<Grid item sm={6} xs={12} container justifyContent="center">
								<DatePicker
									autoOk
									invalidDateMessage={translate('common.dateInvalid')}
									required
									style={{ width: textFieldWidth }}
									value={datefrom}
									onChange={handleDatefrom}
									variant="inline"
									format="DD/MM/YYYY HH:mm"
									margin="normal"
									label={translate('health.datefrom')}
								/>
							</Grid>
							<Grid item sm={6} xs={12} container justifyContent="center">
								<DatePicker
									autoOk
									disabled={type === 'active'}
									style={{ width: textFieldWidth }}
									value={dateto}
									onChange={handleDateto}
									disablePast
									error={isDateExceeded || isInvalidDateTo}
									helperText={
										isDateExceeded
											? translate('common.dateAfter')
											: isInvalidDateTo
											? translate('common.dateInvalid')
											: ''
									}
									variant="inline"
									format="DD/MM/YYYY HH:mm"
									margin="normal"
									label={translate('health.dateto')}
								/>
							</Grid>
						</LocalizationProvider>
					</Grid>
				</DialogContent>
				<DialogActions style={{ padding: theme.spacing(2) }}>
					<Button
						onClick={handleOpenConfirm}
						startIcon={<DeleteIcon />}
						style={{ position: 'absolute', left: theme.spacing(2), display: id ? 'inherit' : 'none' }}
						size="small"
						disabled={type === 'active'}
					>
						{translate('health.delete')}
					</Button>
					<Button onClick={handleClose} variant="contained" size="small">
						{translate('health.cancel')}
					</Button>
					<Button
						variant="contained"
						color="primary"
						size="small"
						onClick={alertConfirm ? handleOpenConfirm : handleSaveNotice}
						disabled={
							disabled || (loadingCreate && id) || isDateExceeded || isInvalidDateTo || isInvalidDateFrom
						}
					>
						{loadingCreate && id ? (
							<CircularProgress size={25} style={{ padding: '3px' }} />
						) : (
							translate('health.saveEdit')
						)}
					</Button>
				</DialogActions>
			</Dialog>

			{/** --------------- ALERT MODAL --------------- */}
			<Dialog
				classes={{
					scrollPaper: classes.topScrollPaper,
					paperScrollBody: classes.topPaperScrollBody
				}}
				open={openConfirm}
				onClose={handleCloseConfirm}
			>
				<DialogTitle>
					<Typography variant="titleSection" style={{ textTransform: 'uppercase' }}>
						{translate('health.attention')}
					</Typography>
					<IconButton
						style={{
							position: 'absolute',
							right: theme.spacing(1),
							top: theme.spacing(1),
							color: theme.palette.grey[500]
						}}
						onClick={handleCloseConfirm}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent style={{ fontSize: '18px', padding: theme.spacing(2) }}>
						{alertConfirm ? translate('health.confirmNoticeError') : translate('health.deleteNotice')}
					</DialogContent>
					<DialogActions style={{ padding: theme.spacing(2) }}>
						<Button
							onClick={handleCloseConfirm}
							style={{ position: 'absolute', left: theme.spacing(2) }}
							size="medium"
						>
							{translate('health.cancel')}
						</Button>
						<Button
							variant="contained"
							onClick={alertConfirm ? handleSaveNotice : handleDeleteNotice}
							style={{
								color: 'white',
								backgroundColor: theme.palette.error.main,
								position: 'absolute',
								right: theme.spacing(2)
							}}
							size="medium"
							disabled={loadingDelete || loadingCreate}
						>
							{loadingDelete || loadingCreate ? (
								<CircularProgress size={25} style={{ padding: ' 3px', color: 'white' }} />
							) : (
								translate('health.confirm')
							)}
						</Button>
					</DialogActions>
				</DialogTitle>
			</Dialog>
		</Fragment>
	);
};

export default Health;
