import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Stack, Paper, CircularProgress, Button, Typography, Box, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ColumnItem from './ColumnItem';
import utils from 'components/utils';
import k from 'k';
import styles from './styles';

const Settings = props => {
	const { classes, onClose } = props;
	const catalogColumns = utils.useUserData('catalogColumns', [], true);
	const loading = useStoreState(state => state.me.updateColumns.loading);
	const update = useStoreActions(dispatch => dispatch.me.updateColumns.performUpdate);
	const translate = utils.useI18N();
	const theme = useTheme();
	const alwayEnabled = ['promo', 'stock', 'title'];
	const cols = k.searchResultsCols.map(col => ({
		id: col,
		label: 'catalog.tableHeaders.' + col,
		enabled:
			alwayEnabled.includes(col) || (catalogColumns && catalogColumns.length > 0 && catalogColumns.includes(col)),
		editable: !alwayEnabled.includes(col)
	}));
	const [items, setItems] = useState(cols);
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};
	const onDragEnd = result => {
		if (!result.destination) return; // dropped outside the list
		const its = reorder(items, result.source.index, result.destination.index);
		setItems(its);
	};
	const onChange = item => {
		const i = items.findIndex(itm => itm.id === item.id);
		if (i === -1) return;
		const clicked = items[i];
		clicked.enabled = !clicked.enabled;
		const newItems = items
			.slice(0, i)
			.concat(clicked)
			.concat(items.slice(i + 1));
		setItems(newItems);
	};
	const onSave = _ => {
		update(items);
	};
	const direction = useMediaQuery(theme.breakpoints.up('md')) ? 'horizontal' : 'vertical';
	const titleKey = useMediaQuery(theme.breakpoints.up('md'))
		? 'catalog.settings.title'
		: 'catalog.settings.titleMobile';
	const listStyle = {
		display: 'flex',
		flexDirection: useMediaQuery(theme.breakpoints.up('md')) ? 'row' : 'column',
		padding: theme.spacing(),
		overflow: 'auto'
	};
	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{
				width: '100%',
				maxWidth: { xs: '90%', md: '75%', sm: '90%' },
				padding: '10px',
				backgroundColor: theme.palette.custom.white
			}}
		>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				sx={{
					width: '100%',
					borderWidth: '0 0 1px 0',
					borderStyle: 'solid',
					borderColor: theme.palette.primary.main
				}}
			>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: '90%' }}>
						<Typography variant="titleSection" sx={{ color: theme.palette.primary.main }}>
							{translate('catalog.settings.title')}
						</Typography>
					</Box>
					<Box sx={{ width: '10%' }}>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Stack>
			</Stack>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{
					width: '100%',
					padding: '5px'
				}}
			>
				<Typography variant="text" sx={{ fontWeight: '600' }}>
					{translate('catalog.settings.subtitle')}
				</Typography>
				<Typography variant="text" sx={{ display: { xs: 'none', md: 'inherit', sm: 'inherit' } }}>
					{translate('catalog.settings.description')}
				</Typography>
				<Typography variant="text" sx={{ display: { xs: 'none', md: 'inherit', sm: 'inherit' } }}>
					{translate('catalog.settings.fixed')}
				</Typography>
			</Stack>
			<Box sx={{ width: { xs: '100%', md: '97.5%', sm: '100%' } }}>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable" direction={direction}>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								style={{
									...listStyle,
									background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
									width: '100%'
								}}
								{...provided.droppableProps}
							>
								{items.map((item, index) => (
									<Draggable key={`draggable_${item.id}`} draggableId={item.id} index={index}>
										{(provided, snapshot) => (
											<ColumnItem
												item={item}
												provided={provided}
												snapshot={snapshot}
												onChange={onChange}
												isLast={index === items.length - 1}
											/>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</Box>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					marginTop: { xs: '10px !important', md: '30px !important', sm: '10px !important' }
				}}
			>
				<Button variant="light" endIcon={<CloseIcon />} onClick={onClose}>
					{translate('common.close')}
				</Button>
				<Button
					variant="dark"
					onClick={onSave}
					endIcon={
						loading ? (
							<CircularProgress size={24} sx={{ color: theme.palette.custom.white }} />
						) : (
							<SaveIcon sx={{ color: theme.palette.custom.white }} />
						)
					}
				>
					{translate('common.save')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(Settings);
