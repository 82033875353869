export default theme => ({
	elements: {
		padding: `${theme.spacing(2, 1, 2, 1)} !important`
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	}
});
