import { action, thunkOn } from 'easy-peasy';
import create from './create';
import accepted from './accepted';
import refused from './refused';
import pending from './pending';
import respond from './respond';
import calendar from './calendar';
import getCarts from './get-carts';

export default {
	requestOpen: false,
	setRequestOpen: action((state, payload) => {
		state.requestOpen = payload;
	}),
	detailOpen: false,
	setDetailOpen: action((state, payload) => {
		state.detailOpen = payload;
	}),
	currentDetail: {
		id: null,
		type: null
	},
	setCurrentDetail: action((state, payload) => {
		state.currentDetail = payload;
	}),
	setCurrentDetailDepot: action((state, payload) => {
		// only to trigger listeners
	}),
	setCurrentDetailNotes: action((state, payload) => {
		// only to trigger listeners
	}),
	closeRequestOnSuccess: thunkOn(
		(actions, storeActions) => [storeActions.returns.create.sendRequest.successType],
		(actions, target, helpers, getStoreActions) => {
			actions.setRequestOpen(false);
		}
	),
	closeDetailsOnRespond: thunkOn(
		(actions, storeActions) => [
			storeActions.returns.respond.accept.successType,
			storeActions.returns.respond.reject.successType
		],
		(actions, target, helpers) => {
			actions.setDetailOpen(false);
		}
	),
	tabSelected: 0,
	setTabSelected: action((state, payload) => {
		state.tabSelected = payload;
		state.detailOpen = false;
	}),
	create,
	accepted,
	refused,
	pending,
	respond,
	calendar,
	getCarts
};
