import React from 'react';
import Id from './Id';
import ExternalNotificationStatus from './ExternalNotificationStatus';
import InternalNotificationStatus from './InternalNotificationStatus';
import CreatedBy from './CreatedBy';
import Title from './Title';
import Body from './Body';
import Groups from './Groups';
import CreationDate from './CreationDate';

const allCells = [
	{
		id: 'id',
		comp: ({ item }) => <Id key={`bodyCell_id_${item.id}`} item={item} />
	},
	{
		id: 'externalNotificationStatus',
		comp: ({ item }) => (
			<ExternalNotificationStatus key={`bodyCell_externalNotificationStatus_${item.id}`} item={item} />
		)
	},
	{
		id: 'internalNotificationStatus',
		comp: ({ item }) => (
			<InternalNotificationStatus key={`bodyCell_internalNotificationStatus_${item.id}`} item={item} />
		)
	},
	{
		id: 'createdBy',
		comp: ({ item }) => <CreatedBy key={`bodyCell_createdBy_${item.id}`} item={item} />
	},
	{
		id: 'title',
		comp: ({ item }) => <Title key={`bodyCell_title_${item.id}`} item={item} />
	},
	{
		id: 'body',
		comp: ({ item }) => <Body key={`bodyCell_body_${item.id}`} item={item} />
	},
	{
		id: 'groups',
		comp: ({ item }) => <Groups key={`bodyCell_groups_${item.id}`} item={item} />
	},
	{
		id: 'creationDate',
		comp: ({ item }) => <CreationDate key={`bodyCell_creationDate_${item.id}`} item={item} />
	}
];

export default allCells;
