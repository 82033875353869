import k from 'k';
import utils from 'services/utils';

export default _ => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/promotions?enableCatalog=true`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
