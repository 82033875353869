const assignToCa = (items, updatingItems) => {
	const selected = items.filter(item => item.touched && item.isSelected);
	const unSelected = items.filter(item => item.touched && !item.isSelected);
	updatingItems.usersToInsert = [];
	updatingItems.usersToDelete = [];
	if (selected.length > 0) {
		selected.forEach(item => {
			if (!item.wasSelected) {
				item.wasSelected = true;
				updatingItems.usersToInsert.push(item.userCode);
			}
		});
	}
	if (unSelected.length > 0) {
		unSelected.forEach(item => {
			item.wasSelected = true;
			updatingItems.usersToDelete.push(item.userCode);
		});
	}
	updatingItems.usersToInsert = updatingItems.usersToInsert.join(',');
	updatingItems.usersToDelete = updatingItems.usersToDelete.join(',');
};

export default assignToCa;
