import k from 'k';
import utils from 'services/utils';

export default ({ id, notes, selectedPromoId }) => {
	const requestOptions = {
		method: 'PATCH',
		body: notes,
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/wishlist/conditions/${id}/promotion/${selectedPromoId}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
