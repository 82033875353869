import React from 'react';
import Container from '@mui/material/Container';
import Alert from '@mui/lab/Alert';

const UnauthorizedAlert = _ => (
	<Container>
		<Alert severity="error" variant="filled" style={{ marginTop: 16 }}>
			Utente non abilitato
		</Alert>
	</Container>
);

export default UnauthorizedAlert;
