import { action } from 'easy-peasy';
import open from './open';
import create from './create';
import processing from './processing';
import shipped from './shipped';
import list from './list';
import listLight from './list-light';
import remove from './remove';
import favourite from './favourite';
import search from './search';
import evasion from './evasion';

export default {
	openFilters: false,
	setOpenFilters: action((state, payload) => {
		state.openFilters = payload;
	}),
	caDetailsModalOpen: false,
	setCaDetailsModalOpen: action((state, payload) => {
		state.caDetailsModalOpen = payload;
	}),
	tabSelected: 0,
	setTabSelected: action((state, payload) => {
		state.tabSelected = payload;
	}),
	open,
	create,
	list,
	listLight,
	remove,
	favourite,
	processing,
	shipped,
	search,
	evasion
};
