import { action } from 'easy-peasy';
import filtersInitialState from './filters-initial-state';

export default {
	list: {
		...filtersInitialState
	},
	setToken: action((state, payload) => {
		state.list.token = payload;
	}),
	setStatus: action((state, payload) => {
		state.list.status = payload;
	}),
	resetToken: action((state, payload) => {
		state.list.token = '';
	}),
	resetStatus: action((state, payload) => {
		state.list.statusValue = false;
	}),
	setProfiles: action((state, payload) => {
		state.list.profiles = payload;
	}),
	openFilters: false,
	setOpenFilters: action((state, payload) => {
		state.openFilters = payload;
	})
};
