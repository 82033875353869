import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	openList: {},
	open: action((state, payload) => {
		state.openList[payload] = true;
	}),
	close: action((state, payload) => {
		state.openList[payload] = false;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);

		if (payload.amca) {
			const client = getStoreState()
				.cart.open.items.flatMap(i => i.orderItems)
				.filter(io => io.code === payload.orderCode)
				.reduce((a, c) => c).client;
			payload.ownerUserCode = client;
		}

		return services.order
			.update(payload)
			.then(data => {
				getStoreActions().success('order.update');
				actions.close(payload.orderCode);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('order.update');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
