import React from 'react';
import get from 'lodash/get';
import MUITextField from '@mui/material/TextField';
import utils from 'components/utils';

const TextField = props => {
	const translate = utils.useI18N();
	const fullWidth = get(props, 'fullWidth', true);
	const user = get(props, 'user', {});
	const onChange = get(props, 'onChange', _ => {});
	const disabled = get(props, 'disabled', false);
	const path = get(props, 'path');
	const label = translate(`user.${path}`);
	const value = get(user, path, '');
	const fixedValueNullError = value === null ? '' : value;
	return (
		<MUITextField
			value={fixedValueNullError}
			onChange={onChange}
			fullWidth={fullWidth}
			disabled={disabled}
			label={label}
			name={path}
			InputLabelProps={{
				shrink: true
			}}
			style={{ textTransform: 'capitalize' }}
		/>
	);
};

export default TextField;
