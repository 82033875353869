import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import k from 'k';

const withColumns = WrappedComponent => {
	const breakpointColumnNumberMap = {
		xs: 2,
		sm: 4,
		md: 6,
		lg: 6
	};
	const cols = [...k.pendingWishlistCols];
	return props => {
		const theme = useTheme();
		let colCount = breakpointColumnNumberMap['xs'];
		if (useMediaQuery(theme.breakpoints.up('sm'))) colCount = breakpointColumnNumberMap['sm'];
		if (useMediaQuery(theme.breakpoints.up('md'))) colCount = breakpointColumnNumberMap['md'];
		if (useMediaQuery(theme.breakpoints.up('lg'))) colCount = breakpointColumnNumberMap['lg'];
		const visibleCols = cols.slice(0, colCount);
		return <WrappedComponent {...props} visibleCols={visibleCols} />;
	};
};

export default withColumns;
