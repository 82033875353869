import { action, thunk, thunkOn } from 'easy-peasy';

export default {
	list: [],
	select: action((state, payload) => {
		state.list = state.list.map(s => ({
			...s,
			selected: s.sapPromoId === payload
		}));
	}),
	save: action((state, payload) => {
		state.list = payload.items.map(s => ({ ...s, selected: false }));
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	retrieve: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.catalog
			.getPromotionList()
			.then(data => {
				actions.save(data);
			})
			.catch(e => {
				getStoreActions().error('catalog.promotions');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	resetOnPersonification: thunkOn(
		(actions, storeActions) => [storeActions.me.personify.sendRequest.successType],
		async (actions, target, helpers) => {
			actions.retrieve();
		}
	)
};
