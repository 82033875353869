import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
	CardContent,
	CircularProgress,
	Stack,
	Box,
	Button,
	Checkbox,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from 'components7/Card';
import utils from 'components/utils';

const DetailItems = props => {
	const { proposal } = props;
	const theme = useTheme();
	const params = useParams();
	const translate = utils.useI18N();
	const user = useStoreState(state => state.me.data);
	const [iAmPersonifying, target] = utils.usePersonification();
	const userId = iAmPersonifying ? target.id : user.id;
	const data = useStoreState(state => state.proposals.detailItems.data);
	const loadingPositions = useStoreState(state => state.proposals.detailItems.loadingPositions);
	const loading = useStoreState(state => state.proposals.detailItems.loading);
	const load = useStoreActions(dispatch => dispatch.proposals.detailItems.load);
	const selectAll = useStoreActions(dispatch => dispatch.proposals.detailItems.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.proposals.detailItems.deselectAll);
	const toggleSelection = useStoreActions(dispatch => dispatch.proposals.detailItems.toggleSelection);
	const deleteItems = useStoreActions(dispatch => dispatch.proposals.detailItems.deleteItems);
	const updateItemsPosition = useStoreActions(dispatch => dispatch.proposals.detailItems.updateItemsPosition);
	const itemSelected = data.filter(i => i.isSelected);
	const proposalId = proposal.id;
	const canDelete = proposal.userCode === userId;

	const headCells = [
		{ id: 'pos', label: 'proposals.table.position', width: '40px' },
		{ id: 'ean', label: 'proposals.table.ean', width: '125px' },
		{ id: 'name', label: 'proposals.table.title', width: 'auto' }
	];

	useEffect(() => {
		if (proposalId && (!data || params.id !== proposalId)) load(params.id);
	}, [params.id]);

	const onSelectAll = event => {
		if (event.target.checked) selectAll();
		else deselectAll();
	};

	const deleteSelection = _ => {
		const itemsDel = itemSelected.map(i => i.ean);
		const obj = {
			eansToInsert: null,
			eansToDelete: itemsDel.join(',')
		};
		deleteItems({ items: obj, id: proposalId });
	};

	const handleDragDrop = result => {
		const { source, destination } = result;
		const newData = [...data];
		const [movedRow] = newData.splice(source.index, 1);
		newData.splice(destination.index, 0, movedRow);
		const positions = [];
		newData.map((book, index) => positions.push({ id: book.id, position: index }));
		updateItemsPosition({ proposalId, positions });
	};

	return (
		<Card backgroundColor="#fff" title={translate('proposals.items') + ' (' + data.length + ')'} width="100%">
			<CardContent sx={{ paddingBottom: '0' }}>
				{loading && (
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<CircularProgress size={32} sx={{ color: theme.palette.primary.main }} />
					</Box>
				)}
				{!loading && data.length == 0 && (
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<Typography variant="textTab" sx={{ fontWeight: '600' }}>
							{translate('proposals.noItems')}
						</Typography>
					</Box>
				)}
				{!loading && data.length > 0 && (
					<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
						<Box sx={{ width: '100%', textAlign: 'right', marginTop: '-15px !important' }}>
							<Button
								variant="dark"
								disabled={itemSelected.length === 0 || !canDelete}
								endIcon={<DeleteIcon sx={{ color: theme.palette.custom.white }} />}
								onClick={deleteSelection}
							>
								{translate('common.delete')}
							</Button>
						</Box>
						{loadingPositions && <CircularProgress size={32} sx={{ color: theme.palette.primary.main }} />}
						{!loadingPositions && (
							<TableContainer sx={{ overflowY: 'scroll', height: '438px', marginTop: '20px !important' }}>
								<Table aria-labelledby="items" aria-label="items table" padding="none">
									<TableHead>
										<TableRow>
											{canDelete && (
												<TableCell
													sx={{
														background: theme.palette.custom.greytable,
														fontWeight: '600',
														width: '54px'
													}}
												>
													<Checkbox
														indeterminate={
															itemSelected.length > 0 && itemSelected.length < data.length
														}
														checked={
															itemSelected.length > 0 &&
															data.length === itemSelected.length
														}
														onChange={onSelectAll}
														inputProps={{ 'aria-label': 'select all items' }}
													/>
												</TableCell>
											)}
											{headCells.map(headCell => (
												<TableCell
													key={headCell.id}
													align="left"
													sx={{
														background: theme.palette.custom.greytable,
														fontWeight: '600',
														width: headCell.width
													}}
												>
													{translate(headCell.label)}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<DragDropContext onDragEnd={handleDragDrop} disabled={!canDelete}>
										<Droppable
											droppableId="table-body"
											direction="vertical"
											isDropDisabled={!canDelete}
										>
											{provided => (
												<TableBody ref={provided.innerRef} {...provided.droppableProps}>
													{data.map((row, index) => (
														<Draggable
															key={row.ean}
															draggableId={row.ean}
															index={index}
															isDragDisabled={!canDelete}
														>
															{(provided, snapshot) => (
																<TableRow
																	key={`row_${index}`}
																	height="40px"
																	ref={provided.innerRef}
																	{...provided.dragHandleProps}
																	{...provided.draggableProps}
																	sx={{
																		backgroundColor: snapshot.isDragging
																			? theme.palette.custom.grey
																			: 'inherit'
																	}}
																>
																	{canDelete && (
																		<TableCell
																			align="left"
																			sx={{
																				paddingRight: snapshot.isDragging
																					? '10px'
																					: 'inherit'
																			}}
																		>
																			<Checkbox
																				checked={row.isSelected}
																				onChange={e => toggleSelection(row.id)}
																				inputProps={{
																					'aria-labelledby': row.id
																				}}
																			/>
																		</TableCell>
																	)}
																	<TableCell
																		sx={{
																			paddingRight: snapshot.isDragging
																				? '22px'
																				: 'inherit'
																		}}
																	>
																		<Typography variant="textTable">
																			{row.position + 1}
																		</Typography>
																	</TableCell>
																	<TableCell
																		sx={{
																			paddingRight: snapshot.isDragging
																				? '30px'
																				: 'inherit'
																		}}
																	>
																		<Typography variant="textTable">
																			{row.ean}
																		</Typography>
																	</TableCell>
																	<TableCell>
																		<Typography variant="textTable">
																			{row.title}
																		</Typography>
																	</TableCell>
																</TableRow>
															)}
														</Draggable>
													))}
												</TableBody>
											)}
										</Droppable>
									</DragDropContext>
								</Table>
							</TableContainer>
						)}
					</Stack>
				)}
			</CardContent>
		</Card>
	);
};

export default DetailItems;
