import React from 'react';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';
import ResetButton from './ResetButton';

const styles = theme => ({
	modalLabels: {
		marginBottom: theme.spacing()
	}
});

const InvalidFile = ({ classes }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="titleSection" sx={{ color: theme.palette.custom.red }}>
					{translate('import.invalidSubtitle')}
				</Typography>
			</Grid>
			<Grid item xs={12} container justify="center" style={{ marginTop: 16 }}>
				<Grid item xs={12}>
					<ResetButton />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(InvalidFile);
