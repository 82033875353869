import k from 'k';
import utils from 'services/utils';
import moment from 'moment';

export default _ => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = {
		rows: 1,
		dateTo: moment().format('L'),
		type: 'SUMMARY_INVOICES',
		dateFrom: moment('01011970', 'DDMMYYYY').format('L')
	};
	const qParams = utils.createQueryParams(params);
	return utils
		.fetch(`${k.endpoints.apis}/document?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
