import k from 'k';
import utils from 'services/utils';

export default ({ token, step, filters, order }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders(),
		responseType: 'blob'
	};
	const f = { ...filters };
	const scopeMap = {
		'coming-soon': 'comingsoon',
		coupons: 'coupon',
		'editorial-unlock': 'editorial',
		full: '',
		languages: 'language',
		'new-on-sale': 'newOnSale',
		rankings: 'ranking',
		typology: 'type'
	};
	if (f.selectedScope) {
		const s = scopeMap[f.selectedScope.id];
		if (s) f[s] = f.selectedSubScope ? f.selectedSubScope.id : true;
	}
	f.selectedScope = null;
	f.selectedSubScope = null;
	if (f.dewey && f.dewey.length > 0) f.dewey = f.dewey.map(d => d.id).join();
	const params = {
		keyword: token,
		rows: step,
		...f,
		sortby: order.by,
		sortdir: order.direction.toUpperCase()
	};
	const qParams = utils.createQueryParams(params);
	return utils
		.fetch(`${k.endpoints.apis}/catalog/find/export/xls?${qParams}`, requestOptions)
		.then(response => response.blob())
		.then(response => {
			const file = new Blob([response], { type: 'application/vnd.ms-excel' });
			var reader = new FileReader();
			reader.onload = e => {
				const link = document.createElement('a');
				link.href = e.target.result;
				const d = new Date();
				link.download =
					'ESTRAZIONE_CATALOGO_' +
					d.toLocaleDateString() +
					'_' +
					d.getHours() +
					'_' +
					d.getMinutes() +
					'_' +
					d.getSeconds() +
					'.xls';
				link.dispatchEvent(
					new MouseEvent('click', {
						bubbles: true,
						cancelable: true,
						view: window
					})
				);
				setTimeout(_ => {
					window.URL.revokeObjectURL(e.target.result);
					link.remove();
				}, 100);
			};
			reader.readAsDataURL(file);
		})
		.catch(error => {
			console.log(error);
		});
};
