import { action, thunk, actionOn, thunkOn, computed } from 'easy-peasy';
import merge from 'lodash/merge';
import initialState from './initial-state';
import channels from './channels';
import avatar from './avatar';
import token from './token';
import update from './update';
import updateColumns from './update-columns';
import personify from './personify';
import updateSapInfo from './update-sap-info';
import preview from './preview';
import getTermsConditions from './get-terms-conditions';
import updateSelectedBudget from './update-selected-budget';

export default {
	isFirstLoggin: true,
	setIsFirstLoggin: action((state, payload) => {
		state.isFirstLoggin = payload;
	}),

	iAmPersonifying: computed([(state, storeState) => storeState.me.personify.target.id], id => id !== ''),
	data: {
		...initialState
	},
	permissions: [],
	abilitations: null,
	saveAbilitations: action((state, payload) => {
		state.abilitations = payload;
	}),
	currentAbilitations: null,
	setCurrentAbilitations: action((state, payload) => {
		state.currentAbilitations = payload;
	}),
	save: action((state, payload) => {
		merge(state.data, payload);
	}),
	saveTokens: actionOn(
		(actions, storeActions) => [storeActions.me.token.request.successType],
		(state, target) => {
			merge(state.data, target.result);
		}
	),
	saveAlertAccepted: action((state, payload) => {
		state.data.alertAccepted = payload;
	}),
	onUpdateSapInfo: actionOn(
		(actions, storeActions) => [storeActions.me.updateSapInfo.sendRequest.successType],
		(state, target) => {
			if (!state.iAmPersonifying) state.data.showSapInfo = target.result;
		}
	),
	onUpdateSelectedBudget: actionOn(
		(actions, storeActions) => [storeActions.me.updateSelectedBudget.performUpdate.successType],
		(state, target) => {
			if (!state.iAmPersonifying) {
				state.data.selectedBudget = target.result;
			}
		}
	),
	onUpdateCatalgoColumns: actionOn(
		(actions, storeActions) => [storeActions.me.updateColumns.performUpdate.successType],
		(state, target) => {
			state.data.catalogColumns = target.result;
		}
	),
	onUpdateAvatar: actionOn(
		(actions, storeActions) => [
			storeActions.me.avatar.update.successType,
			storeActions.me.avatar.deletee.successType
		],
		(state, target) => {
			if (!state.iAmPersonifying) state.data.avatar = target.payload.fileName;
		}
	),
	onUpdateMe: actionOn(
		(actions, storeActions) => [storeActions.me.update.performUpdate.successType],
		(state, target) => {
			if (!state.iAmPersonifying) merge(state.data, target.result);
		}
	),
	onUpdateNewsletter: actionOn(
		(actions, storeActions) => [actions.updateNewsletter.successType],
		(state, target) => {
			if (!state.iAmPersonifying) {
				state.data.newsletter.mailTo = target.payload.mailTo;
				state.data.newsletter.subscription = target.payload.subscription;
			}
			state.data.newsletter.requested = true;
		}
	),
	onSubscribe: actionOn(
		(actions, storeActions) => [storeActions.me.channels.subscribe.successType],
		(state, target) => {
			state.data.alertAccepted.push({ ...target.payload });
		}
	),
	onUnsubscribe: actionOn(
		(actions, storeActions) => [storeActions.me.channels.unsubscribe.successType],
		(state, target) => {
			const { channelId, messageTypeId } = target.payload;
			const i = state.data.alertAccepted.findIndex(
				item => item.channelId === channelId && item.messageTypeId === messageTypeId
			);
			if (i === -1) return;
			state.data.alertAccepted = state.data.alertAccepted
				.slice(0, i)
				.concat(state.data.alertAccepted.slice(i + 1));
		}
	),
	savePermissions: action((state, payload) => {
		state.permissions = payload;
	}),
	replaceTokens: action((state, payload) => {
		state.data.bearer = payload.bearer;
		state.data.idToken = payload.idToken;
		state.data.refreshToken = payload.refreshToken;
	}),
	reset: action((state, payload) => {
		state.data = {
			...initialState
		};
		state.abilitations = null;
	}),
	loadPermissions: thunkOn(
		(actions, storeActions) => [storeActions.me.token.request.successType],
		async (actions, target, helpers) => {
			// prettier-ignore
			const { injections: { services }, getStoreActions } = helpers;
			services.me
				.loadPermissions()
				.then(data => {
					actions.savePermissions(data);
				})
				.catch(e => {
					getStoreActions().error('me.loadPermissions');
				});
		}
	),
	loadAbilitation: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		console.log('** RICHIAMO LOAD ***');
		return services.me.getAbilitation(payload).then(data => {
			console.log('** Salvo da load', data);
			actions.saveAbilitations(data);
		});
	}),
	getAbilitation: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		return services.me
			.getAbilitation(payload)
			.then(data => {
				actions.setCurrentAbilitations(data);
			})
			.catch(e => {
				console.log('Errore in caricamento abilitato');
			});
	}),
	updateNewsletter: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		return services.me
			.updateNewsletter(payload)
			.then(data => {
				getStoreActions().success('me.updateNewsletter');
			})
			.catch(e => {
				getStoreActions().error('me.updateNewsletter');
			});
	}),
	popperOpen: false,
	setPopperOpen: action((state, payload) => {
		state.popperOpen = payload;
	}),
	avatar,
	update,
	updateColumns,
	token,
	channels,
	personify,
	updateSapInfo,
	preview,
	getTermsConditions,
	updateSelectedBudget
};
