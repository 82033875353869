import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const VolumeComp = ({ volume }) => {
	return <StyledTableCell>{utils.capitalizeAll(volume)}</StyledTableCell>;
};

const Volume = memo(
	({ item }) => <VolumeComp volume={item.volume} />,
	(p, n) => p.item.volume === n.item.volume
);

export default Volume;
