import React from 'react';
import Id from './Id';
import Description from './Description';
import Name from './Name';
import Type from './Type';

const allCells = [
	{
		id: 'id',
		comp: ({ item }) => <Id key={`bodyCell_id_${item.id}`} item={item} />
	},
	{
		id: 'description',
		comp: ({ item }) => <Description key={`bodyCell_description_${item.id}`} item={item} />
	},
	{
		id: 'name',
		comp: ({ item }) => <Name key={`bodyCell_name_${item.id}`} item={item} />
	},
	{
		id: 'type',
		comp: ({ item }) => <Type key={`bodyCell_type_${item.id}`} item={item} />
	}
];

export default allCells;
