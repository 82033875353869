import returnAccepted from './actions/return-accepted';
import returnRefused from './actions/return-refused';
import returnRequest from './actions/return-request';
import extraDiscountRequest from './actions/extra-discount-request';
import extraDiscountRequestApproved from './actions/extra-discount-request-approved';
import extraDiscountRequestModified from './actions/extra-discount-request-modified';
import extraDiscountRequestRefused from './actions/extra-discount-request-refused';

const typeMap = {
	RETURN_ACCEPTED: returnAccepted,
	RETURN_REFUSED: returnRefused,
	RETURN_REQUEST: returnRequest,
	EXTRA_DISCOUNT_REQUEST: extraDiscountRequest,
	EXTRA_DISCOUNT_REQUEST_APPROVED: extraDiscountRequestApproved,
	EXTRA_DISCOUNT_REQUEST_MODIFIED: extraDiscountRequestModified,
	EXTRA_DISCOUNT_REQUEST_REFUSED: extraDiscountRequestRefused
};

const notificationAction = (notification, history) => {
	const { type, recipientUserRole } = notification;
	const fn = typeMap[type];
	if (!fn) console.warn('Invalid notification type: ' + type);
	fn && fn(history, recipientUserRole);
};

export default notificationAction;
