import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';
import ResetButton from './ResetButton';

const styles = theme => ({
	modalLabels: {
		marginBottom: theme.spacing()
	}
});

const NotFoundItems = ({ classes }) => {
	const items = useStoreState(state => state.importt.notFound);
	const notOrdinable = useStoreState(state => state.importt.notOrdinable);
	const setActiveStep = useStoreActions(dispatch => dispatch.importt.setActiveStep);
	const translate = utils.useI18N();
	const onContinueClick = _ => {
		if (notOrdinable.length === 0) setActiveStep(4);
		else setActiveStep(3);
	};
	return (
		<Grid container>
			<Grid item xs={12} sx={{ marginBottom: '20px !important' }}>
				<Typography variant="titleSection">{translate('import.notFoundTitle')}</Typography>
			</Grid>
			{items.map(item => (
				<Grid container item xs={12} sm={6} md={3} key={item} className={classes.modalLabels}>
					<Typography variant="text">{item}</Typography>
				</Grid>
			))}
			<Grid item xs={12} container justify="center" style={{ marginTop: 16 }}>
				<Grid item xs={6}>
					<Button disableElevation variant="light" onClick={onContinueClick}>
						{translate('common.continue')}
					</Button>
				</Grid>
				<Grid item container xs={6} justify="flex-end">
					<ResetButton />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(NotFoundItems);
