import React, { Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '@mui/material/Button';
import Modal, { modalClasses } from '@mui/material/Modal';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import Send from './Send';
import Drawer from '@mui/material/Drawer/Drawer';
import { Dialog, useMediaQuery, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	}
});

const SendButton = ({ classes }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const modalOpen = useStoreState(state => state.BOnotifications.send.open);
	const setModalOpen = useStoreActions(dispatch => dispatch.BOnotifications.send.setOpen);
	const handleClick = _ => {
		setModalOpen(!modalOpen);
	};
	return (
		<Fragment>
			<Modal
				className={classes.modal}
				open={modalOpen}
				onClose={handleClick}
				// PaperProps={{ sx: { width: '70%' } }}
			>
				<div className="modal-content">
					<Send />
				</div>
			</Modal>

			<Button className={classes.buttonsMargin} variant="outlined" onClick={handleClick}>
				{translate('sendNotification.send')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(SendButton);
