import React from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import Selection from './Selection';

const allCells = [
	{
		id: 'selection',
		comp: ({ item }) => <Selection key={`bodyCell_selection_${item.userCode}`} item={item} />
	},
	{
		id: 'cod',
		comp: ({ item }) => (
			<StyledTableCell key={`bodyCell_cod_${item.userCode}`} component="th" scope="row">
				{item.userCode}
			</StyledTableCell>
		)
	},
	{
		id: 'rag',
		comp: ({ item }) => (
			<StyledTableCell key={`bodyCell_rag_${item.userName}`} component="th" scope="row">
				{item.userName}
			</StyledTableCell>
		)
	},
	{
		id: 'city',
		comp: ({ item }) => (
			<StyledTableCell key={`bodyCell_city_${item.city}`} component="th" scope="row">
				{item.city}
			</StyledTableCell>
		)
	},
	{
		id: 'address',
		comp: ({ item }) => (
			<StyledTableCell key={`bodyCell_address_${item.address}`} component="th" scope="row">
				{item.address}
			</StyledTableCell>
		)
	},
	{
		id: 'province',
		comp: ({ item }) => (
			<StyledTableCell key={`bodyCell_province_${item.province}`} component="th" scope="row">
				{item.province}
			</StyledTableCell>
		)
	}
];

export default allCells;
