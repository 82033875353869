import me from './me';
import connect from './connect';
import alert from './alert';
import i18n from './i18n';
import catalog from './catalog';
import cart from './cart';
import wishlist from './wishlist';
import order from './order';
import cms from './cms';
import shipping from './shipping';
import depots from './admin/depots';
import userGroups from './admin/userGroups';
import returns from './admin/returns';
import BOpromotions from './admin/promotions';
import users from './admin/users';
import conditionRequest from './admin/conditionRequest';
import promotions from './promotions';
import notifications from './notifications';
import coupons from './admin/coupons';
import purchasings from './admin/purchasings';
import rankings from './admin/rankings';
import editorials from './admin/editorials';
import BOnotifications from './admin/notifications';
import documents from './documents';
import BOspecialDelivery from './admin/specialDelivery';
import specialDelivery from './specialDelivery';
import ca from './ca';
import budget from './budget';
import home from './home';
import wordpress from './wordpress';
import health from './health';
import stripe from './stripe';
import proposals from './proposals';
import contest from './contest';

export default {
	me,
	connect,
	alert,
	i18n,
	users,
	catalog,
	cart,
	order,
	shipping,
	depots,
	userGroups,
	returns,
	cms,
	BOpromotions,
	wishlist,
	conditionRequest,
	promotions,
	notifications,
	coupons,
	purchasings,
	rankings,
	editorials,
	BOnotifications,
	documents,
	BOspecialDelivery,
	specialDelivery,
	ca,
	budget,
	home,
	wordpress,
	health,
	stripe,
	proposals,
	contest
};
