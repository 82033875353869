import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ConditionRequest from './ConditionRequest';
import List from './List';
import utils from 'components/utils';

const Wishlist = props => {
	const iCan = utils.usePermissions();
	return (
		<Switch>
			<Route path="/wishlist/list">
				<List />
			</Route>
			{iCan('MANAGE_WISHLIST') && (
				<Route path="/wishlist/condition-request" exact={true}>
					<ConditionRequest />
				</Route>
			)}
		</Switch>
	);
};

export default Wishlist;
