import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const PriceComp = ({ fullPrice }) => {
	return <StyledTableCell>{utils.formatPrice(fullPrice)}</StyledTableCell>;
};

const Price = memo(
	({ item }) => <PriceComp fullPrice={item.fullPrice} />,
	(p, n) => p.item.fullPrice === n.item.fullPrice
);

export default Price;
