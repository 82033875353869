import k from 'k';
import utils from 'services/utils';

export default ({ sapId, data }) => {
	const requestOptions = {
		method: 'PATCH',
		headers: utils.createHeaders(),
		body: JSON.stringify(data)
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/catalog-groups/rankings/${sapId}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
