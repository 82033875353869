import pick from 'lodash/pick';

const download = (orderType, fileType) => {
	return (actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const date = getStoreState().cart.shipped.selectedDate;
		const formattedDate = date.format('yyyy-MM-DD');
		const data = pick(payload.item, ['cause', 'chapter', 'cig', 'purchaseNumber', 'purchaseOrderDate', 'fcd']);
		data.cartName = payload.item.name;
		data.cartId = payload.item.id;
		data.filterDate = formattedDate;
		const loadingActions = {
			PDF: actions.setLoadingPdf,
			XLS: actions.setLoadingXls
		};
		const svcs = {
			PDF: services.order.exportPdf,
			XLS: services.order.exportXls
		};
		loadingActions[fileType](true);
		svcs[fileType](data, orderType)
			.catch(e => {
				getStoreActions().error('order.export');
			})
			.finally(_ => loadingActions[fileType](false));
	};
};

export default {
	exportOpenPdf: download('OPEN', 'PDF'),
	exportOpenXls: download('OPEN', 'XLS'),
	exportProcessingPdf: download('PROCESSING', 'PDF'),
	exportProcessingXls: download('PROCESSING', 'XLS'),
	exportShippedPdf: download('CLOSED', 'PDF'),
	exportShippedXls: download('CLOSED', 'XLS')
};
