import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Tooltip, Box, Grid, IconButton } from '@mui/material';
import utils from 'components/utils';
import keys from 'lodash/keys';

const OpenOrderDepotsInfo = props => {
	const { order } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const resume = keys(order.resumeOrder.depotResumeOrder).map(k => order.resumeOrder.depotResumeOrder[k]);
	const arrProcessTitle = translate('orders.processableTitles').split(' ');
	const arrDepotsTile = translate('orders.depotsdetails').split(' ');

	return (
		<Stack
			direction="column"
			justifyContent={{ xs: 'center', md: 'flex-start', sm: 'flex-start' }}
			alignItems={{ xs: 'center', md: 'flex-start', sm: 'flex-start' }}
			spacing={0}
			sx={{ width: '100%', margin: '10px 0 !important', padding: '10px !important' }}
		>
			<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
				<Typography variant="text" sx={{ fontSize: '14px', fontWeight: '600' }}>
					{translate('orders.processableTitles')}
				</Typography>
				<Typography variant="text" sx={{ fontSize: '14px', fontWeight: '600', textTransform: 'lowercase' }}>
					({translate('orders.processable')} / {translate('common.total')})
				</Typography>
			</Stack>
			{/* Dettaglio depositi */}
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'row' }}
				justifyContent={{ xs: 'center', md: 'flex-start', sm: 'flex-start' }}
				alignItems={{ xs: 'center', md: 'center', sm: 'center' }}
				spacing={0}
				sx={{ width: '100%', padding: '5px 0 10px 0', borderTop: '1px solid #ccc', marginTop: '5px' }}
			>
				<Grid container>
					{resume.map((item, index) => (
						<Grid item xs={12} md={2} sm={4} key={index}>
							<Stack direction="column" justifyContent="center" alignItems="center" spacing={0}>
								<Typography variant="text" sx={{ fontSize: '13px' }}>
									{item.sumOfProcessableCatalog} / {item.sumOfCatalog}
								</Typography>
								<Typography variant="text" sx={{ fontSize: '13px' }}>
									{utils.formatPrice(item.sumCostProcessable)} / {utils.formatPrice(item.sumCost)}
								</Typography>
								<Typography
									variant="text"
									sx={{
										fontSize: '13px',
										textTransform: 'lowercase',
										fontWeight: '600',
										color:
											item.sumOfProcessableCatalog > 0
												? theme.palette.custom.green
												: theme.palette.custom.black
									}}
								>
									{item.depotDescription}
								</Typography>
							</Stack>
						</Grid>
					))}
				</Grid>
			</Stack>
		</Stack>
	);
};

export default OpenOrderDepotsInfo;
