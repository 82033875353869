import React, { useState, useEffect } from 'react';

const withSticky = (WrappedComponent, threshold) => {
	return props => {
		const [isSticky, setIsSticky] = useState(false);
		useEffect(_ => {
			window.addEventListener('scroll', setStickyBar);
			setStickyBar();
			return _ => {
				window.removeEventListener('scroll', setStickyBar);
			};
		}, []);

		const setStickyBar = _ => {
			window.scrollY > threshold ? setIsSticky(true) : setIsSticky(false);
		};

		return <WrappedComponent {...props} isSticky={isSticky} />;
	};
};

export default withSticky;
