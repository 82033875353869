const openOrderCols = [
	'selection',
	'deletee',
	'catalog',
	'title', // will always be with preload and availability
	'volume',
	'publisher',
	'price',
	'promo',
	'depot',
	'date',
	'requestQty',
	'evadibleQty',
	'newsItem',
	'slaDesc',
	'notes',
	'warning'
	//'edit'
];

export default openOrderCols;
