import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const renderDepot = d => (
	<Typography variant="body2">
		{utils.capitalizeAll(d.description)}: {d.stock ? d.stock.toString() : 0}
	</Typography>
);

const StockComp = ({ depots, type, ordinable }) => {
	const mainDepot = utils.useUserData('mainDepot', null);
	const availableDepots = utils.useUserData('availableDepots', []);
	const [firstDepot, secondDepot] = utils.extractDepots({
		mainDepot,
		bookDepots: depots,
		userDepots: availableDepots,
		type: type,
		ordinable: ordinable
	});
	return (
		<StyledTableCell>
			<Grid container style={{ flexDirection: 'column' }}>
				{firstDepot && renderDepot(firstDepot)}
				{secondDepot && renderDepot(secondDepot)}
			</Grid>
		</StyledTableCell>
	);
};

const Stock = memo(
	({ item }) => <StockComp depots={item.depots} ordinable={item.ordinable} stock={item.stock} type={item.type} />,
	(p, n) => p.item.depots === n.item.depots && p.item.stock === n.item.stock
);

export default Stock;
