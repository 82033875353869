import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import utils from 'components/utils';

const RejectButton = ({ item }) => {
	const loadingReject = useStoreState(state => state.returns.respond.loadingReject);
	const currentDetail = useStoreState(state => state.returns.currentDetail);
	const reject = useStoreActions(dispatch => dispatch.returns.respond.reject);
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const onReject = _ => {
		reject(currentDetail.id);
	};
	if (!iCan('MANAGE_RETURNS')) return '';
	return (
		currentDetail.type === 'pending' && (
			<Button variant="important" disabled={item.notes === '' || loadingReject} onClick={onReject}>
				{translate('returns.detail.reject')}
			</Button>
		)
	);
};

export default RejectButton;
