import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const SeriesComp = ({ serie }) => {
	return <StyledTableCell>{utils.capitalizeAll(serie)}</StyledTableCell>;
};

const Series = memo(
	({ item }) => <SeriesComp serie={item.serie} />,
	(p, n) => p.item.serie === n.item.serie
);
export default Series;
