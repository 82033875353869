import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { IconButton, Typography, Stack, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import utils from 'components/utils';

const WarningModal = props => {
	const { classes, onCloseSelecionModal } = props;
	const theme = useTheme();
	const setWarningPromo = useStoreActions(dispatch => dispatch.selection.setWarningPromo);
	const resetPromo = useStoreActions(dispatch => dispatch.selection.resetWarning);
	const titles = useStoreState(state => state.selection.titlesInPromo);
	const selection = useStoreState(state => state.selection.promoList);
	const translate = utils.useI18N();
	const resetModal = _ => {
		onCloseSelecionModal();
		resetPromo(false);
	};
	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={2}
			sx={{ width: '100%', padding: '10px 10px 20px 10px !important' }}
		>
			{/* Titolo e chiudi */}
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					borderWidth: '0 0 2px 0',
					borderStyle: 'solid',
					borderColor: theme.palette.primary.main
				}}
			>
				<Typography
					variant="titlePage"
					component="div"
					sx={{ width: '90%', color: theme.palette.primary.main }}
				>
					{''}
				</Typography>
				<IconButton
					onClick={_ => {
						resetModal();
					}}
					sx={{ color: theme.palette.primary.main }}
				>
					<CloseIcon />
				</IconButton>
			</Stack>

			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0.5}
				variant="wrapperColor"
				sx={{
					width: '100%',
					borderColor: theme.palette.secondary.main,
					borderWidth: '1px !important',
					borderStyle: 'solid',
					color: theme.palette.secondary.main
				}}
			>
				{translate('promotions.warningModal.warningTitle')}
			</Stack>

			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{ width: '100%', padding: { xs: '10px', md: '10px 20px', sm: '10px', lg: '10px 30px' } }}
			>
				{titles.map((item, key) => (
					<Stack key={key} direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
						<Typography variant="text" sx={{ fontWeight: '600' }}>
							{item.name}
						</Typography>
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={1}
							sx={{ marginBottom: '10px !important' }}
						>
							{selection.map((title, index) => (
								<Typography variant="text" key={index}>
									{title.promo.find(b => b.id === item.id) ? title.title + ' : ' + title.ean : ''}
								</Typography>
							))}
						</Stack>
						<Button
							variant="dark"
							component={Link}
							onClick={_ => resetModal()}
							disabled={item.isOnlyVisualization}
							to={`/catalog/search/by-promotion/${item.sapId}`}
							endIcon={<KeyboardDoubleArrowRightIcon />}
						>
							{!item.isOnlyVisualization
								? translate('catalog.details.promo.searchButton')
								: translate('catalog.details.promo.notAllowed') + ' ' + item.name}
						</Button>
					</Stack>
				))}
			</Stack>

			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					borderStyle: 'solid',
					borderWidth: '1px 0 0 0',
					borderColor: theme.palette.custom.greyborder,
					marginTop: '10px',
					padding: { xs: '10px 0 0 0', md: '10px 30px 0 30px', sm: '10px 10px 0 10px' },
					width: '100%',
					margin: { xs: '10px 0 0 0 !important', md: '10px 0 0 0 !important', sm: '10px 0 0 0 !important' }
				}}
			>
				<Button variant="dark" onClick={_ => setWarningPromo(false)}>
					{translate('promotions.continueWithout')}
				</Button>
				<Button variant="light" onClick={_ => resetModal()}>
					{translate('common.cancel')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default WarningModal;
