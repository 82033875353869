import k from 'k';
import utils from 'services/utils';

export default data => {
	const requestOptions = {
		method: 'POST',
		headers: utils.createHeaders(),
		body: JSON.stringify(data)
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/catalog-groups/rankings`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
