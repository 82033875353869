import k from 'k';
import utils from 'services/utils';

export default ({ code, codeVerifier }) => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/x-www-form-urlencoded');
	headers.append('Accept', 'application/json');
	const urlencoded = new URLSearchParams();
	urlencoded.append('client_id', 'fastbook');
	urlencoded.append('client_secret', k.clientSecret);
	urlencoded.append('code_verifier', codeVerifier);
	urlencoded.append('grant_type', 'authorization_code');
	urlencoded.append('code', code);
	urlencoded.append('redirect_uri', k.redirectURLS.login);
	const requestOptions = {
		method: 'POST',
		headers: headers,
		body: urlencoded,
		redirect: 'follow'
	};
	return fetch(k.endpoints.token, requestOptions).then(response => {
		if (response.status < 200 || response.status > 300) {
			response.text().then(txt => console.log(txt));
			return Promise.reject(utils.createError(response));
		}
		return response.json();
	});
};
