import React, { memo } from 'react';
import { useStoreActions } from 'easy-peasy';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import StyledTableCell from 'components/Table/StyledTableCell';

const NoteComp = ({ text, isAdded, catalog }) => {
	const removeItem = useStoreActions(dispatch => dispatch.documents.details.removeItem);
	if (isAdded)
		return (
			<StyledTableCell>
				<IconButton style={{ padding: 0 }} onClick={_ => removeItem(catalog)}>
					<DeleteIcon fontSize="small" />
				</IconButton>
			</StyledTableCell>
		);
	return <StyledTableCell>{text}</StyledTableCell>;
};

const Note = memo(
	({ item }) => <NoteComp text={item.note} isAdded={item.isAdded} catalog={item.catalog} />,
	(p, n) => p.item.note === n.item.note && p.item.isAdded === n.item.isAdded && p.item.catalog === n.item.catalog
);

export default Note;
