import k from 'k';
import utils from 'services/utils';

export default ({ type, refNum, format }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders(),
		responseType: 'blob'
	};

	const params = { type, reference: refNum };
	const qParams = utils.createQueryParams(params);
	return utils.fetch(`${k.endpoints.apis}/document/detail/export/${format}?${qParams}`, requestOptions);
};
