import { action, thunk } from 'easy-peasy';
import head from 'lodash.head';

const getItem = val => {
	if (!val.items || val.items.length === 0) return null;
	return head(val.items);
};

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: {
		lastCreditNote: null,
		lastBill: null,
		lastInvoice: null,
		lastAccompanyingInvoice: null
	},
	save: action((state, payload) => {
		state.data.lastCreditNote = getItem(payload[0]);
		state.data.lastBill = getItem(payload[1]);
		state.data.lastInvoice = getItem(payload[2]);
		state.data.lastAccompanyingInvoice = getItem(payload[3]);
	}),
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const list = [
			services.me.getLastCreditNote(),
			services.me.getLastRiba(),
			services.me.getLastBill(),
			services.me.getLastInvoice(),
			services.me.getLastAccompanyingInvoice()
		];
		return Promise.all(list)
			.then(values => {
				actions.save(values);
			})
			.catch(e => {
				getStoreActions().error('me.loadPreview');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
