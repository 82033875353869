import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, CircularProgress, LinearProgress, Box, Paper } from '@mui/material';
import utils from 'components/utils';
import HeaderPage from './HeaderPage';
import FilterBar from './FilterBar';
import TabBar from './TabBar';
import OpenOrders from './Open/OpenOrders';
import ProcessingOrders from './Processing/ProcessingOrders';
import ShippedOrders from './Shipped/ShippedOrders';

const Orders7 = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const loadOpen = useStoreActions(dispatch => dispatch.cart.open.load);
	const loadingOpen = useStoreState(state => state.cart.open.loading);
	const loadProcessing = useStoreActions(dispatch => dispatch.cart.processing.load);
	const loadingProcessing = useStoreState(state => state.cart.processing.loading);
	const loadShipped = useStoreActions(dispatch => dispatch.cart.shipped.load);
	const loadingShipped = useStoreState(state => state.cart.shipped.loading);
	const isSearching = useStoreState(state => state.cart.search.isSearching);
	const tabSelected = useStoreState(state => state.cart.tabSelected);
	const getFulfillmentFailed = useStoreActions(dispatch => dispatch.cart.open.getFulfillmentFailed);

	useEffect(_ => {
		if (!isSearching && !iCan('OPERATE_ON_MULTIPLE_CA')) {
			loadOpen();
			getFulfillmentFailed();
		}
		if (!isSearching && !iCan('VIEW_ONLY_OPEN_ORDERS')) {
			loadProcessing();
			loadShipped();
		}
	}, []);

	useEffect(() => {
		//Scroll top
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [tabSelected]);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				<HeaderPage />
				<FilterBar />
				<TabBar />

				{((tabSelected == 0 && loadingOpen) ||
					(tabSelected == 1 && loadingProcessing) ||
					(tabSelected == 2 && loadingShipped)) && (
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<LinearProgress sx={{ color: theme.palette.primary.main }} />
						{/*<CircularProgress
							sx={{
								color: theme.palette.primary.main,
								margin: { xs: '40px auto', md: '60px auto', sm: '40px auto' }
							}}
						/> */}
					</Box>
				)}
			</Paper>
			{tabSelected == 0 && !loadingOpen && <OpenOrders />}
			{tabSelected == 1 && !loadingProcessing && <ProcessingOrders />}
			{tabSelected == 2 && !loadingShipped && <ShippedOrders />}
		</Stack>
	);
};

export default Orders7;
