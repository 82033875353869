import { action, thunk, thunkOn, computed } from 'easy-peasy';
import storeUtils from 'store/utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	count: 0,
	setCount: action((state, payload) => {
		state.count = payload;
	}),
	expanded: -1,
	setExpanded: action((state, payload) => {
		state.expanded = payload;
	}),
	resetExpanded: action((state, payload) => {
		state.expanded = -1;
	}),
	notAllSelected: computed(state => state.items.some(item => !item.isSelected)),
	someSelected: computed(state => state.items.some(item => item.isSelected)),
	selectedItems: computed(state => state.items.filter(item => item.isSelected)),
	allSelectable: computed(state => state.items.every(item => item.responsible)),
	load: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		const reset = payload && payload.reset;
		const { services } = injections;
		actions.setLoading(true);
		const page = reset ? 0 : getState().pagination.current - 1;
		const step = getState().pagination.step;
		const dirtyFilters = getStoreState().conditionRequest.filters.list;
		const filters = dirtyFilters.promoId === '_empty_' ? { ...dirtyFilters, promoId: '' } : dirtyFilters;
		services.conditionRequest
			.getPendingList({ page, filters, step })
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('wishlist.retrieveConditionDetails');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	...storeUtils.createPagination({ step: 25, paginationTrigger: 'save', loadFn: 'load' }),
	getCount: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const dirtyFilters = getStoreState().conditionRequest.filters.list;
		const filters = dirtyFilters.promoId === '_empty_' ? { ...dirtyFilters, promoId: '' } : dirtyFilters;
		services.conditionRequest
			.getPendingCount({ filters })
			.then(data => actions.setCount(data))
			.catch(e => {
				getStoreActions().error('wishlist.retrieveConditionDetails');
			});
	}),
	search: thunkOn(
		(actions, storeActions) => [
			storeActions.conditionRequest.filters.performSearch,
			storeActions.conditionRequest.filters.reset
		],
		async (actions, target, helpers) => {
			actions.load();
			actions.getCount();
		}
	),
	selectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: true
		}));
	}),
	deselectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: false
		}));
	}),
	toggleSelection: action((state, payload) => {
		const i = state.items.findIndex(itm => itm.id === payload);
		if (i === -1) return;
		const item = state.items[i];
		item.isSelected = !item.isSelected;
		state.items = state.items
			.slice(0, i)
			.concat(item)
			.concat(state.items.slice(i + 1));
	}),
	save: action((state, payload) => {
		state.items = payload.items.map(item => ({
			...item,
			isSelected: false
		}));
	}),
	updateConditionChange: thunkOn(
		(actions, storeActions) => [
			storeActions.conditionRequest.approve.sendRequest.successType,
			storeActions.conditionRequest.reject.sendRequest.successType,
			storeActions.conditionRequest.update.sendRequest.successType,
			storeActions.conditionRequest.approveMultiple.sendRequest.successType,
			storeActions.conditionRequest.rejectMultiple.sendRequest.successType,
			storeActions.conditionRequest.updateMultiple.sendRequest.successType
		],
		async (actions, target, helpers) => {
			actions.load(actions, null, helpers);
			actions.resetExpanded();
			actions.getCount();
		}
	),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	})
};
