import React, { Fragment } from 'react';
import Hidden from '@mui/material/Hidden';
import Desktop from './Desktop';
import Mobile from './Mobile';

const ToolbarContent = props => {
	return (
		<Fragment>
			<Hidden smDown>
				<Desktop />
			</Hidden>
			<Hidden mdUp>
				<Mobile />
			</Hidden>
		</Fragment>
	);
};

export default ToolbarContent;
