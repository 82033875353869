import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	openCreate: false,
	setOpenCreate: action((state, payload) => {
		state.openCreate = payload;
	}),
	createProposal: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.proposals
			.createProposal(payload)
			.then(data => {
				getStoreActions().success('proposals.create');
				setTimeout(_ => {
					getStoreActions().proposals.list.load();
				}, 1000);
				actions.setOpenCreate(false);
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
