import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const IdComp = ({ id }) => {
	return <StyledTableCell>{id}</StyledTableCell>;
};

const Id = memo(
	({ item }) => <IdComp id={item.id} />,
	(p, n) => p.item.id === n.item.id
);

export default Id;
