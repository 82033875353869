import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { CardContent, Stack, Box, TextField, FormControlLabel, Switch, Typography, Button } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Card from 'components7/Card';
import utils from 'components/utils';

const DetailInfo = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const user = useStoreState(state => state.me.data);
	const [iAmPersonifying, target] = utils.usePersonification();
	const userId = iAmPersonifying ? target.id : user.id;
	const loading = useStoreState(state => state.proposals.details.loading);
	const proposal = useStoreState(state => state.proposals.details.data);
	const setName = useStoreActions(dispatch => dispatch.proposals.details.setName);
	const setDescription = useStoreActions(dispatch => dispatch.proposals.details.setDescription);
	const setEnabled = useStoreActions(dispatch => dispatch.proposals.details.setEnabled);
	const setStartDate = useStoreActions(dispatch => dispatch.proposals.details.setStartDate);
	const setEndDate = useStoreActions(dispatch => dispatch.proposals.details.setEndDate);
	const update = useStoreActions(dispatch => dispatch.proposals.details.update);
	const isError = !proposal.name || !proposal.name.trim();
	{
		/* || !proposal.startDate ||
		!moment(proposal.startDate).isValid() ||
		!proposal.endDate ||
		!moment(proposal.endDate).isValid() ||
		moment(proposal.startDate).isAfter(moment(proposal.endDate));
		*/
	}
	const canUpdate = proposal.userCode === userId;

	const handleSave = _ => {
		update({ proposalId: proposal.id, data: proposal });
	};

	return (
		<Card backgroundColor="#fff" title={translate('proposals.details')} width="100%">
			<CardContent sx={{ paddingBottom: '0' }}>
				<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
					<Stack
						direction={{ xs: 'column', md: 'row', sm: 'row' }}
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={2}
						sx={{ width: '100%' }}
					>
						<TextField
							value={proposal.name}
							label={translate('proposals.name')}
							fullWidth={true}
							InputLabelProps={{ shrink: true }}
							error={!proposal.name || !proposal.name.trim()}
							helperText={
								!proposal.name || !proposal.name.trim()
									? translate('common.formValidation.required')
									: ''
							}
							onChange={e => setName(e.target.value)}
							disabled={!canUpdate}
						/>
						{/*
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								label={translate('proposals.dateFrom')}
								value={proposal.startDate ? moment(proposal.startDate) : null}
								format="DD MMMM YYYY"
								onChange={setStartDate}
								disabled={!canUpdate}
								disablePast
								error={!proposal.startDate || !moment(proposal.startDate).isValid()}
								helperText={translate('common.dateInvalid')}
								sx={{ width: '100%', maxWidth: '200px' }}
							/>
							<DatePicker
								label={translate('proposals.dateTo')}
								value={proposal.endDate ? moment(proposal.endDate) : null}
								format="DD MMMM YYYY"
								onChange={setEndDate}
								disabled={!canUpdate}
								disablePast
								error={
									!proposal.endDate ||
									!moment(proposal.endDate).isValid() ||
									moment(proposal.startDate).isAfter(moment(proposal.endDate))
								}
								helperText={translate('common.dateInvalid')}
								sx={{ width: '100%', maxWidth: '200px' }}
							/>
						</LocalizationProvider>
						<FormControlLabel
							control={
								<Switch
									checked={proposal.enabled}
									disabled={!canUpdate}
									onChange={e => setEnabled(e.target.checked)}
								/>
							}
							label={<Typography variant="titleTab">{translate('proposals.enabled')}</Typography>}
						/>
						*/}
					</Stack>
					<TextField
						value={proposal.description}
						label={translate('proposals.description')}
						fullWidth={true}
						multiline
						rows={2}
						InputLabelProps={{ shrink: true }}
						onChange={e => setDescription(e.target.value)}
						disabled={!canUpdate}
					/>
					<Box sx={{ width: '100%', marginTop: '30px !important', textAlign: 'right' }}>
						<Button variant="dark" disabled={loading || isError || !canUpdate} onClick={handleSave}>
							{translate('common.save')}
						</Button>
					</Box>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default DetailInfo;
