import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { IconButton, Tooltip, Button } from '@mui/material';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';

const Reset = props => {
	const history = useHistory();
	const resetFilters = useStoreActions(dispatch => dispatch.catalog.filters.reset);
	const selectPromotion = useStoreActions(dispatch => dispatch.catalog.promotions.select);
	const resetTreeItemsSelected = useStoreActions(dispatch => dispatch.catalog.thema.resetItemsSelected);
	const setTreeToken = useStoreActions(dispatch => dispatch.catalog.themaAutocomplete.setToken);

	const onReset = () => {
		resetFilters();
		selectPromotion('');
		resetTreeItemsSelected();
		setTreeToken('');
		history.push('/catalog');
	};

	return (
		<Tooltip title="Reset">
			<Button
				variant="light"
				onClick={onReset}
				endIcon={<YoutubeSearchedForIcon sx={{ fontSize: '1.2em' }} />}
				sx={{ height: '55px', padding: '7px 16px !important' }}
			>
				Reset
			</Button>
		</Tooltip>
	);
};

export default Reset;
