import React, { Fragment, useEffect, useState, cloneElement } from 'react';
import moment from 'moment';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { red, green } from '@mui/material/colors';
import Popover from '@mui/material/Popover';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { alpha, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { StaticDatePicker, LocalizationProvider, DateCalendar, PickersDay } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import utils from 'components/utils';
import { useTheme } from '@mui/material/styles';

const styles = theme => ({
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		},
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1, 0, 0, 0),
			width: '100%'
		}
	},
	card: {
		transition: '0.3s'
	},
	red: {
		color: theme.palette.getContrastText(red[300]),
		backgroundColor: red[300]
	},
	green: {
		color: theme.palette.getContrastText(green[300]),
		backgroundColor: green[300]
	},
	white: {
		backgroundColor: 'white'
	}
});

const ShowCalendarButton = ({ classes }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const dayList = useStoreState(state => state.returns.calendar.items);
	const loading = useStoreState(state => state.returns.calendar.loading);
	const getDays = useStoreActions(dispatch => dispatch.returns.calendar.getDays);
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [currentDate, setCurrentDate] = useState(moment());
	const toggleOpen = _ => {
		setOpen(!open);
	};
	useEffect(
		_ => {
			const now = moment();
			getDays({ month: now.month() + 1, year: now.year() });
		},
		[getDays]
	);
	const onClick = event => {
		setAnchorEl(event.currentTarget);
		toggleOpen();
	};
	const handleClose = _ => setOpen(false);
	const renderDay = props => {
		const { day, selectedDay } = props;
		const getDayClass = day => {
			if (!day) return theme.palette.custom.white;
			const formattedDay = day.format('DD/MM/YYYY');
			const d = dayList.find(item => item.day === formattedDay);
			return d && d.available ? theme.palette.custom.green : theme.palette.custom.red;
		};
		return (
			<PickersDay
				onDaySelect={onDateChange}
				isFirstVisibleCell={false}
				isLastVisibleCell={false}
				outsideCurrentMonth={false}
				day={day}
				sx={{
					backgroundColor: alpha(getDayClass(day), 0.5),
					'&:disabled': { color: theme.palette.custom.black + ' !important' }
				}}
				disabled
			/>
		);
	};
	const onMonthChange = date => {
		getDays({ month: date.month() + 1, year: date.year() });
		setCurrentDate(date);
	};
	const onDateChange = date => {
		setCurrentDate(date);
	};
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DateCalendar
				onMonthChange={onMonthChange}
				value={currentDate}
				onChange={onDateChange}
				slots={{ day: renderDay }}
				slotProps={{
					actionBar: { actions: [] },
					day: {
						selectedDay: currentDate
					}
				}}
				sx={{ paddingBottom: theme.spacing(1) }}
				orientation={isMobile ? 'portrait' : 'landscape'}
				views={['day', 'month']}
				label=""
			/>
		</LocalizationProvider>
	);
};

export default withStyles(styles, { withTheme: true })(ShowCalendarButton);
