import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Stack, Grid, Typography, CircularProgress, Paper, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UnavailableCheckStep from './UnavailableCheckStep';
import CreateCartStep from './CreateCartStep';
import utils from 'components/utils';
import styles from '../styles';

const WlTransformUI = ({ classes, item }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const loadingUnavailableEans = useStoreState(state => state.wishlist.getDepotsStock.loading);
	const data = useStoreState(state => state.wishlist.details.data);
	const unavailableEans = useStoreState(state => state.wishlist.getDepotsStock.items);
	const setModalOpen = useStoreActions(dispatch => dispatch.wishlist.transform.setModalOpen);
	const loadUnavailableEans = useStoreActions(dispatch => dispatch.wishlist.getDepotsStock.load);
	const loadCartList = useStoreActions(dispatch => dispatch.cart.listLight.load);
	useEffect(_ => {
		loadCartList();
	}, []);
	useEffect(
		_ => {
			loadUnavailableEans(item.id);
		},
		[loadUnavailableEans, item.id]
	);

	const items = data[item.id]
		? data[item.id].map(d => ({
				promoDesc: d.promoDesc,
				id: d.id,
				quantity: d.quantity,
				depotCode: d.depotCode,
				...d.catalog
		  }))
		: [];
	const setCartCreationScope = useStoreActions(dispatch => dispatch.cart.open.setScope);
	// prettier-ignore
	useEffect(_ => { setCartCreationScope('wishlist.transform'); }, [setCartCreationScope]);

	return (
		<Paper>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{
					width: '100%',
					padding: '10px',
					maxWidth: { xs: '340px', md: '550px', sm: '550px' }
				}}
			>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: '90%' }}>
						<Typography
							variant="titleSection"
							sx={{ color: theme.palette.primary.main, textTransform: 'capitalize' }}
						>
							{translate('wishlist.transform')}
						</Typography>
					</Box>
					<Box sx={{ width: '10%' }}>
						<IconButton onClick={_ => setModalOpen(false)}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Stack>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					sx={{
						width: '100%',
						borderWidth: '1px 0 0 0',
						borderStyle: 'solid',
						borderColor: theme.palette.primary.main,
						paddingTop: '10px',
						marginBottom: '15px'
					}}
				>
					{loadingUnavailableEans && <CircularProgress size={35} />}
					{!loadingUnavailableEans && unavailableEans.length > 0 && (
						<UnavailableCheckStep
							allItems={items}
							items={unavailableEans}
							wishlistId={item.id}
							wishlistType={item.type}
						/>
					)}
					{!loadingUnavailableEans && unavailableEans.length === 0 && (
						<CreateCartStep items={items} item={item} />
					)}
				</Stack>
			</Stack>
		</Paper>
	);
};

export default withStyles(styles, { withTheme: true })(WlTransformUI);
