import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import utils from 'components/utils';

const Sortable = ({ id, label, additionalStyles }) => {
	//	const promoSearch = useStoreState(state => state.catalog.search.last.filters.selectedPromotion);
	const searchLast = useStoreState(state => state.catalog.search.last);
	const promoSearch = searchLast && searchLast.filters ? searchLast.filters.selectedPromotion : false;
	const order = useStoreState(state => state.catalog.order.direction);
	const orderBy = useStoreState(state => state.catalog.order.by);
	const setOrder = useStoreActions(dispatch => dispatch.catalog.order.setOrder);
	const translate = utils.useI18N();
	const defaultStyles = {
		paddingLeft: 4,
		paddingRight: 4,
		backgroundColor: promoSearch ? '#e67272' : '#fafafa'
	};
	return (
		<TableCell
			align="left"
			style={{ ...defaultStyles, ...additionalStyles }}
			sortDirection={orderBy === id ? order : false}
		>
			<TableSortLabel
				active={orderBy === id}
				direction={orderBy === id ? order : 'asc'}
				onClick={e => setOrder(id)}
			>
				{translate(label)}
			</TableSortLabel>
		</TableCell>
	);
};

export default Sortable;
