import { useEffect } from 'react';

const useEnterListener = fn => {
	useEffect(
		_ => {
			const onKeyDown = e => {
				if (e.key === 'Enter') fn();
			};
			document.addEventListener('keydown', onKeyDown);
			return _ => {
				document.removeEventListener('keydown', onKeyDown);
			};
		},
		[fn]
	);
};

export default useEnterListener;
