import { action, computed, thunk, thunkOn } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	carts: [],
	save: action((state, payload) => {
		state.carts = payload.map((p, index) => ({
			...p,
			selected: index === 0,
			value: index,
			name: p.name === '' || !p.name ? 'Carrello predefinito' : p.name
		}));
	}),
	selectedCartValue: computed(state => {
		const sel = state.carts.find(item => item.selected);
		return sel ? sel.value : '';
	}),
	selectedCart: computed(state => state.carts.find(item => item.selected)),
	selectCart: action((state, payload) => {
		const carts = state.carts.map(p => ({
			...p,
			selected: p.value === payload
		}));
		state.carts = carts;
		state.fillCart = carts.find(item => item.selected);
	}),
	showInactive: false,
	setShowInactive: action((state, payload) => {
		state.showInactive = payload;
	}),
	periods: new Array(52).fill(undefined).map((item, index) => ({
		value: index,
		label: index + 1,
		selected: index === 0
	})),
	selectPeriod: action((state, payload) => {
		state.periods = state.periods.map(p => ({
			...p,
			selected: p.value === payload
		}));
	}),
	selectedPeriod: computed(state => state.periods.find(item => item.selected).value),
	reloadOnPeriodOrInactiveChange: thunkOn(
		(actions, storeActions) => [actions.setShowInactive, actions.selectPeriod],
		async (actions, target, helpers) => {
			actions.load(actions, null, helpers);
		}
	),
	load: thunk((actions, payload, { getState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const period = getState().selectedPeriod;
		const showInactive = getState().showInactive;
		services.returns
			.getCarts(period, showInactive)
			.then(data => actions.save(data.items))
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
