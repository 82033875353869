import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	open: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.notifications
			.openTicket(payload)
			.then(data => {
				getStoreActions().success('notifications.sendContact');
			})
			.catch(e => {
				getStoreActions().error('notifications.sendContact');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
