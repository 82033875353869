import React from 'react';
import { Box, Grid } from '@mui/material';
import WPPosts from './posts/WPPosts';
import SliderComponent from './slider/SliderComponent';
import PromotionComponent from './promotions/PromotionComponent';
import HealthInfo from './health/HealtInfo';
import SearchComponent from './search/SearchComponent';
import utils from 'components/utils';
import LastTracking from './tracking/LastTracking';
import { useTheme } from '@mui/styles';
import Banners from './banners';

const HomeV2 = props => {
	const iCan = utils.usePermissions();
	const theme = useTheme();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={7} sm={12}>
				<SliderComponent />
			</Grid>
			<Grid
				item
				md={5}
				sm={12}
				sx={{ marginTop: { xs: '25px !important', md: '0 !important', sm: '25px !important' } }}
			>
				{iCan('CATALOGS') && <SearchComponent />}
				<HealthInfo />
			</Grid>
			<Grid item sm={12} sx={{ marginTop: { xs: '0 !important', md: '50px !important', sm: '0 !important' } }}>
				<Banners />
			</Grid>
			<Grid item xs={12}>
				<Box display={{ xs: 'none', lg: 'inherit' }}>
					<PromotionComponent />
				</Box>
				<Box>{iCan('ORDERS') && <LastTracking />}</Box>
				<Box display={{ xs: 'inherit', lg: 'none' }}>
					<PromotionComponent />
				</Box>
			</Grid>

			<Grid item xs={12}>
				<WPPosts />
			</Grid>
		</Grid>
	);
};

export default HomeV2;
