export default theme => ({
	notificationItem: {
		textAlign: 'left',
		marginBottom: theme.spacing()
	},
	mobileMargin: {
		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(2)
		}
	}
});
