import { action, thunk } from 'easy-peasy';

export default {
	items: [],
	save: action((state, payload) => {
		state.items = payload.map(item => ({
			...item,
			countUsers: item.utente
				? item.utente.includes('*')
					? ''
					: ' (' + (item.utente.split('.').length - 2).toString() + ')'
				: '',
			isSelected: false
		}));
	}),
	loadingList: false,
	setLoadingList: action((state, payload) => {
		state.loadingList = payload;
	}),
	modalOpen: false,
	setModalOpen: action((state, payload) => {
		state.modalOpen = payload;
	}),
	searchOpen: false,
	setSearchOpen: action((state, payload) => {
		state.searchOpen = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingList(true);
		services.userGroups
			.list()
			.then(data => actions.save(data.items))
			.catch(e => {
				getStoreActions().error('userGroups.errorList');
			})
			.finally(_ => {
				actions.setLoadingList(false);
			});
	}),
	orderBy: 'id',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),

	// Selezione multipla
	toggleSelection: action((state, payload) => {
		const i = state.items.findIndex(itm => itm.id === payload);
		if (i === -1) return;
		const item = state.items[i];
		item.isSelected = !item.isSelected;
		state.items = state.items
			.slice(0, i)
			.concat(item)
			.concat(state.items.slice(i + 1));
	}),
	selectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: true
		}));
	}),
	deselectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: false
		}));
	})
};
