import React, { useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import MUIAvatar from '@mui/material/Avatar';
import utils from 'components/utils';
import utilsServices from 'services/utils';
import k from 'k';

const Avatar = ({ size, forceLogged }) => {
	const [iAmPersonifying, target] = utils.usePersonification();
	const userId = useStoreState(state => state.me.data.id);
	const avatarId = useStoreState(state => state.me.data.avatar);
	const uId = iAmPersonifying && !forceLogged ? target.id : userId;

	const [img, setImg] = useState(null);
	useEffect(() => {
		async function getImage() {
			const headers = utilsServices.createHeaders();
			const requestOptions = {
				method: 'GET',
				headers: headers
			};
			await utilsServices
				.fetch(`${k.endpoints.apis}/avatar/img/${uId}`, requestOptions)
				.then(resp => resp.text())
				.then(data => {
					setImg(data);
				})
				.catch(error => {
					setImg('');
				});
		}
		getImage();
	}, [uId]);

	const style = {
		width: size,
		height: size
	};

	const src = avatarId ? `data:image/jpg;base64,${img}` : '';
	return <MUIAvatar src={src} style={style} />;
};

export default Avatar;
