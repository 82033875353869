import list from './list';
import listLight from './list-light';
import add from './add';
import remove from './remove';
import removeItem from './remove-item';
import create from './create';
import favourite from './favourite';
import details from './details';
import transform from './transform';
import requestExtraConditions from './request-extra-conditions';
import exportt from './export';
import update from './update';
import getDepotsStock from './get-depots-stock';
import removeUnavailableItems from './remove-unavailable-items';

export default {
	create,
	list,
	listLight,
	add,
	remove,
	removeItem,
	favourite,
	details,
	transform,
	requestExtraConditions,
	exportt,
	update,
	getDepotsStock,
	removeUnavailableItems
};
