import store from 'store';
import createError from './create-error';
import refreshToken from 'services/connect/refresh-token';
import k from 'k';

if (window.refreshingToken === undefined) {
	window.refreshingToken = false;
}

const handle401 = ({ response, isRetry, lastOperation, abortCtrl }) => {
	if (window.refreshingToken === true) return Promise.reject('Waiting for refresh');
	window.refreshingToken = true;
	const state = store.getState();
	const accessToken = state?.me?.data?.bearer;
	//if (isRetry || !accessToken) {
	if (!accessToken) {
		console.log('handle401 isRetry logout');
		window.location.href = '/logout';
	} else if (isRetry) {
		//Redirect al logout di Isabella
		setTimeout(_ => {
			window.localStorage.clear();
			window.location.href = k.endpoints.logoutProvider;
		}, 500);
	} else {
		return refreshToken()
			.then(async refreshTokenResp => {
				store.dispatch({
					type: '@action.me.replaceTokens',
					payload: {
						bearer: refreshTokenResp.access_token,
						idToken: refreshTokenResp.id_token,
						refreshToken: refreshTokenResp.refresh_token
					}
				});

				await store.persist.flush();

				window.location.reload();
			})
			.catch(e => {
				console.error('refreshToken catch logout', e);
				window.location.href = '/logout';
			});
	}
};

window.handle401 = handle401;
const customFetch = (url, options, isRetry = false) => {
	if (options === null) options = {};
	if (options.credentials === null) options.credentials = 'same-origin';
	const abortCtrl = options.abortCtrl ? options.abortCtrl : new AbortController();
	options.signal = abortCtrl.signal;

	return fetch(url, options).then(response => {
		if (response.status >= 200 && response.status < 300) {
			return Promise.resolve(response);
		} else if (response.status === 401) {
			const lastOperation = { url, options };
			return handle401({ response, isRetry, lastOperation, abortCtrl });
		} else {
			return Promise.reject(createError(response));
		}
	});
};

export default customFetch;
