import React from 'react';
import { useTheme } from '@mui/material/styles';
import TableHeadMUI from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import allCols from './cols';

const TableHead = ({ visibleCols }) => {
	const theme = useTheme();
	const cells = visibleCols.map(col => allCols.find(c => c.id === col));
	return (
		<TableHeadMUI>
			<TableRow style={{ height: theme.spacing(5) }}>{cells.map(cell => cell.comp())}</TableRow>
		</TableHeadMUI>
	);
};

export default TableHead;
