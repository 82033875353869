import { action, actionOn, thunk, thunkOn } from 'easy-peasy';

export default {
	items: [],
	select: action((state, payload) => {
		state.items = state.items.map(s => ({
			...s,
			selected: s.id === payload
		}));
	}),
	scope: '',
	setScope: action((state, payload) => {
		state.scope = payload;
	}),
	closeAccordionsListener: actionOn(
		(actions, storeActions) => [
			storeActions.wishlist.list.setScope,
			storeActions.wishlist.remove.sendDelete.successType,
			storeActions.wishlist.transform.sendRequest.successType
		],
		state => {
			state.expanded = -1;
		}
	),
	save: action((state, payload) => {
		state.items = [...payload.map((s, i) => ({ ...s, selected: i === 0 }))];
	}),
	expanded: -1,
	setExpanded: action((state, payload) => {
		state.expanded = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	loadedList: false,
	setLoadedList: action(state => {
		state.loadedList = true;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.wishlist
			.list()
			.then(data => {
				actions.setLoadedList();
				actions.save(data.items);
			})
			.catch(e => {
				if (e.name !== 'AbortError') getStoreActions().error('order.wishlist');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	reloadAfterUpdate: thunkOn(
		(actions, storeActions) => [storeActions.wishlist.update.updateItem.successType],
		async (actions, target, helpers) => {
			actions.load(actions, null, helpers);
		}
	),
	updateOnCreateDeleteRECTransform: thunkOn(
		(actions, storeActions) => [
			storeActions.wishlist.create.sendRequest.successType,
			storeActions.wishlist.remove.sendDelete.successType,
			storeActions.wishlist.requestExtraConditions.sendRequest.successType,
			storeActions.wishlist.transform.sendRequest.successType
		],
		async (actions, target, helpers) => {
			actions.load(actions, null, helpers);
		}
	),
	updateFavourite: actionOn(
		(actions, storeActions) => [storeActions.wishlist.favourite.sendRequest.successType],
		(state, target) => {
			const {
				result: {
					item: { id, favourite }
				}
			} = target;
			state.items = state.items.map(item => ({
				...item,
				favourite: item.id === id ? favourite : false
			}));
		}
	),
	updateOnRemoveItem: actionOn(
		(actions, storeActions) => [storeActions.wishlist.removeItem.sendDelete.successType],
		(state, target) => {
			const {
				result: { idWishlist, canRequestExtraConditions, totalItems, totalPrice }
			} = target;
			state.items = state.items.map(item => ({
				...item,
				totalItems: item.id === idWishlist ? totalItems : item.totalItems,
				totalPrice: item.id === idWishlist ? totalPrice : item.totalPrice,
				canRequestExtraConditions:
					item.id === idWishlist ? canRequestExtraConditions : item.canRequestExtraConditions
			}));
		}
	),
	updateOnRemoveItems: actionOn(
		(actions, storeActions) => [storeActions.wishlist.remove.deleteItems.successType],
		(state, target) => {
			const {
				result: { idWishlist, canRequestExtraConditions, totalItems, totalPrice }
			} = target;
			state.items = state.items.map(item => ({
				...item,
				totalItems: item.id === idWishlist ? totalItems : item.totalItems,
				totalPrice: item.id === idWishlist ? totalPrice : item.totalPrice,
				canRequestExtraConditions:
					item.id === idWishlist ? canRequestExtraConditions : item.canRequestExtraConditions
			}));
		}
	)
};
