import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';
import notificationAction from './notification-action';
import styles from './styles';
import moment from 'moment';

const withoutAction = ['GENERIC_COMMUNICATION', 'SERVICE_COMMUNICATION'];

const SingleNotification = ({ classes, item }) => {
	const history = useHistory();
	const hasAction = !withoutAction.includes(item.type);
	const markAsRead = useStoreActions(dispatch => dispatch.notifications.markAsRead.sendRequest);
	const formatDate = moment(item.creationDate).format('L');

	const ActionComp = _ => (
		<Button
			color="inherit"
			size="small"
			onClick={event => {
				event.stopPropagation();
				notificationAction(item, history);
				markAsRead(item.id);
			}}
		>
			VAI ALLA SEZIONE
		</Button>
	);
	return (
		<Grid item xs={12} className={classes.notificationItem}>
			<Alert
				style={{ cursor: 'pointer' }}
				onClick={event => {
					event.stopPropagation();
					notificationAction(history);
					markAsRead(item.id);
				}}
				variant="outlined"
				severity={item.isRead ? 'info' : 'warning'}
				action={hasAction ? <ActionComp /> : null}
			>
				<AlertTitle>{`${formatDate} - ${item.subject}`}</AlertTitle>
				<span dangerouslySetInnerHTML={{ __html: item.message }}></span>
			</Alert>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(SingleNotification);
