import React from 'react';
import { useStoreActions } from 'easy-peasy';
import Chip from '@mui/material/Chip';
import utils from 'components/utils';

const BadgeRanking = ({ filter }) => {
	const translate = utils.useI18N();
	const resetSingleFilter = useStoreActions(dispatch => dispatch.catalog.filters.resetSingle);
	const resetSingleNoTrigger = useStoreActions(dispatch => dispatch.catalog.filters.resetSingleNoTrigger);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const resetOrder = useStoreActions(dispatch => dispatch.catalog.order.reset);
	const searchIn = translate('catalog.searchIn');
	const label = translate(`catalog.filters.${filter.label}`);
	const resetBadgeRankingFilters = _ => {
		resetOrder();
		//resetSingleNoTrigger serve per evitare il richiamo al servizio search perchè resetSingleFilter lo richiama
		resetSingleNoTrigger('badgeRanking');
		resetSingleNoTrigger('ranking');
		//resetSingleFilter('badgeRankingName'); -> tolto per poter impostare il numero di righe a 25 con setStep
		resetSingleNoTrigger('badgeRankingName');
		setStep(25);
	};
	return <Chip size="small" label={`${searchIn}: ${label}`} onDelete={_ => resetBadgeRankingFilters()} />;
};

export default BadgeRanking;
