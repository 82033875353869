import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BookDetails from './BookDetails';

const Print = props => {
	return (
		<Switch>
			<Route path="/print/book/:ean" exact={true}>
				<BookDetails />
			</Route>
		</Switch>
	);
};

export default Print;
