import { action, thunk, thunkOn, computed, debug } from 'easy-peasy';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import utils from 'store/admin/utils';
import storeUtils from 'store/utils';

export default {
	detailsItems: computed([(state, storeState) => storeState.proposals.detailItems.data], s => s),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	openModalItemsAssign: false,
	setOpenModalItemsAssign: action((state, payload) => {
		state.openModalItemsAssign = payload;
	}),
	orderBy: 'publicationDate',
	orderDirection: 'desc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	results: [],
	showResults: false,
	setShowResults: action((state, payload) => {
		state.showResults = payload;
	}),
	showMessageResults: false,
	setShowMessageResults: action((state, payload) => {
		state.showMessageResults = payload;
	}),
	updatingItems: {
		eansToInsert: null,
		eansToDelete: null
	},
	toggleSelection: action((state, payload) => {
		const i = state.results.findIndex(itm => itm.ean === payload);
		if (i === -1) return;
		const item = state.results[i];
		item.isSelected = !item.isSelected;
		item.touched = item.isSelected !== item.wasSelected;
		state.results = state.results
			.slice(0, i)
			.concat(item)
			.concat(state.results.slice(i + 1));
	}),
	selectAll: action(state => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: true,
			touched: !item.wasSelected
		}));
	}),
	deselectAll: action(state => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: false,
			touched: item.wasSelected
		}));
	}),
	saveResults: action((state, payload) => {
		const detailsItems = debug(state).detailsItems;
		state.results = payload.items.map(item => ({
			...item,
			depots: item.depots.filter(r => r.enable),
			isSelected: detailsItems.findIndex(i => i.ean === item.ean) !== -1,
			wasSelected: detailsItems.findIndex(i => i.ean === item.ean) !== -1,
			touched: false
		}));
		if (state.results.length == 0) state.showMessageResults = true;
	}),
	...storeUtils.createPagination({ step: 25, paginationTrigger: 'saveResults', loadFn: 'performSearch' }),
	performSearchByOrder: thunkOn(
		(actions, storeActions) => [actions.setOrder],
		async (actions, target, helpers) => {
			actions.performSearch(actions, {}, helpers);
		}
	),
	performSearch: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		actions.setShowMessageResults(false);
		const token = getStoreState().catalog.search.token;
		const page = getState().pagination.current - 1;
		const step = getState().pagination.step;
		const by = getState().orderBy;
		const direction = getState().orderDirection;
		const filters = pickBy(getStoreState().catalog.filters.list, identity);
		//const filters = { allWords: true };
		const params = {
			token,
			page,
			filters,
			step,
			order: {
				by,
				direction
			}
		};
		return services.catalog
			.search(params)
			.then(data => {
				actions.saveResults(data);
				actions.setShowResults(true);
				getStoreActions().proposals.filters.setOpenFilters(false);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('catalog.search');
			})
			.finally(_ => {
				actions.setLoading(false);
				getStoreActions().catalog.autocomplete.close();
			});
	}),
	update: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		actions.setLoading(true);
		const {
			proposals: {
				detailItemsAssign: { results, updatingItems }
			}
		} = getStoreState();
		const proposalId = getStoreState().proposals.details.data.id;
		utils.assignToGroup(results, updatingItems);
		return services.proposals
			.updateItems(updatingItems, proposalId)
			.then(_ => {
				updatingItems.eansToDelete = [];
				updatingItems.eansToInsert = [];
				getStoreActions().success('proposals.associated');
				getStoreActions().proposals.detailItems.load(proposalId);
			})
			.catch(_ => {
				getStoreActions().error('proposals.errorAssiciation');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
