import React from 'react';
import Price from './Price';
import Code from './Code';
import Title from './Title';
import Serie from './Serie';
import Publisher from './Publisher';
import Quantity from './Quantity';

const allCells = [
	{
		id: 'ean',
		comp: ({ item }) => <Code key={`bodyCell_catalog_${item.ean}`} item={item} />
	},
	{
		id: 'title',
		comp: ({ item }) => <Title key={`bodyCell_title_${item.ean}`} item={item} />
	},
	{
		id: 'serie',
		comp: ({ item }) => <Serie key={`bodyCell_serie_${item.ean}`} item={item} />
	},
	{
		id: 'publisher',
		comp: ({ item }) => <Publisher key={`bodyCell_publisher_${item.ean}`} item={item} />
	},
	{
		id: 'fullPrice',
		comp: ({ item }) => <Price key={`bodyCell_price_${item.ean}`} item={item} />
	},
	{
		id: 'quantity',
		comp: ({ item }) => <Quantity key={`bodyCell_quantity_${item.ean}`} item={item} />
	}
];

export default allCells;
