import { action, thunk } from 'easy-peasy';

export default {
	loading: {},
	setLoading: action((state, payload) => {
		state.loading[payload] = true;
	}),
	unsetLoading: action((state, payload) => {
		state.loading[payload] = false;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(payload.id);
		services.budget
			.update(payload)
			.then(data => {
				getStoreActions().success('budget.update');
				getStoreActions().budget.list.load();
			})
			.catch(e => {
				getStoreActions().error('budget.update');
			})
			.finally(_ => {
				actions.unsetLoading(payload.id);
			});
	})
};
