import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';

const styles = theme => ({
	modalLabels: {
		marginBottom: theme.spacing()
	}
});

const InvalidFile = ({ classes }) => {
	const setActiveStep = useStoreActions(dispatch => dispatch.cart.importt.setActiveStep);
	const translate = utils.useI18N();
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography className={classes.modalLabels} variant="body1">
					<strong>{translate('import.invalidTitle')}</strong>
				</Typography>
				<Typography className={classes.modalLabels} variant="body1">
					{translate('import.invalidSubtitle')}
				</Typography>
			</Grid>
			<Grid item xs={12} container justify="center" style={{ marginTop: 16 }}>
				<Grid item xs={12}>
					<Button disableElevation color="primary" variant="contained" onClick={_ => setActiveStep(0)}>
						{translate('common.back')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(InvalidFile);
