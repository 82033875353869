import React from 'react';
import RichTextEditor from 'react-rte';

const TextEditor = ({ onChange, value }) => {
	const toolbarConfig = {
		display: [
			'INLINE_STYLE_BUTTONS',
			'BLOCK_ALIGNMENT_BUTTONS',
			'BLOCK_TYPE_BUTTONS',
			'LINK_BUTTONS',
			'BLOCK_TYPE_DROPDOWN'
		],
		INLINE_STYLE_BUTTONS: [
			{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
			{ label: 'Italic', style: 'ITALIC' },
			{ label: 'Strikethrough', style: 'STRIKETHROUGH' },
			{ label: 'Monospace', style: 'CODE' },
			{ label: 'Underline', style: 'UNDERLINE' }
		],
		BLOCK_ALIGNMENT_BUTTONS: [
			{ label: 'Align right', style: 'ALIGN_RIGHT' },
			{ label: 'Align center', style: 'ALIGN_LEFT' },
			{ label: 'Align left', style: 'ALIGN_CENTER' },
			{ label: 'Align Justify', style: 'ALIGN_JUSTIFY' }
		],
		BLOCK_TYPE_BUTTONS: [
			{ label: 'UL', style: 'unordered-list-item' },
			{ label: 'OL', style: 'ordered-list-item' },
			{ label: 'Blockquote', style: 'blockquote' }
		],
		BLOCK_TYPE_DROPDOWN: [
			{ label: 'Normal', style: 'unstyled' },
			{ label: 'Heading Large', style: 'header-one' },
			{ label: 'Heading Medium', style: 'header-two' },
			{ label: 'Heading Small', style: 'header-three' },
			{ label: 'Code Block', style: 'code-block' }
		]
	};

	return <RichTextEditor toolbarConfig={toolbarConfig} value={value} onChange={onChange} />;
};

export default TextEditor;
