import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import StyledTableCell from 'components/Table/StyledTableCell';

const IdComp = ({ id }) => {
	return (
		<StyledTableCell>
			<Typography variant="caption">{id}</Typography>
		</StyledTableCell>
	);
};

const Id = memo(
	({ item }) => <IdComp id={item.uuidRegistration} />,
	(p, n) => p.item.uuidRegistration === n.item.uuidRegistration
);

export default Id;
