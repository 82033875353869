import React from 'react';
import Author from './Author';
import Title from './Title';
import Discount from './Discount';
import Ean from './Ean';
import DocumentNumber from './DocumentNumber';
import Quantity from './Quantity';
import QuantityEvadible from './QuantityEvadible';
import QuantityRequest from './QuantityRequest';
import Amount from './Amount';
import Gross from './Gross';
import AmountSI from './AmountSI';
import Editor from './Editor';
import Net from './Net';
import Note from './Note';
import Numberr from './Number';
import Datee from './Date';
import DocDate from './DocumentDate';
import Type from './Type';
import AmountRiba from './AmountRiba';
import Customer from './Customer';
import SummaryInvoice from './SummaryInvoice';
import GiveOrTake from './GiveOrTake';

const allCells = [
	{
		id: 'catalog',
		comp: ({ item }) => <Ean key={`bodyCell_ean_documents_${item.id}`} item={item} />
	},
	{
		id: 'documentNumber',
		comp: ({ item }) => <DocumentNumber key={`bodyCell_number_docs_${item.id}`} item={item} />
	},
	{
		id: 'documentDate',
		comp: ({ item }) => <DocDate key={`bodyCell_date_docs_${item.id}`} item={item} />
	},
	{
		id: 'type',
		comp: ({ item }) => <Type key={`bodyCell_type_docs_${item.id}`} item={item} />
	},
	{
		id: 'amount',
		comp: ({ item }) => <AmountRiba key={`bodyCell_amount_docs_${item.id}`} item={item} />
	},
	{
		id: 'giveOrTake',
		comp: ({ item }) => <GiveOrTake key={`bodyCell_operation_docs_${item.id}`} item={item} />
	},
	{
		id: 'title',
		comp: ({ item }) => <Title key={`bodyCell_title_documents_${item.id}`} item={item} />
	},
	{
		id: 'author',
		comp: ({ item }) => <Author key={`bodyCell_author_documents_${item.id}`} item={item} />
	},
	{
		id: 'editorDesc',
		comp: ({ item }) => <Editor key={`bodyCell_editor_documents_${item.id}`} item={item} />
	},
	{
		id: 'quantity',
		comp: ({ item }) => <Quantity key={`bodyCell_quantity_documents_${item.id}`} item={item} />
	},
	{
		id: 'quantityEvadible',
		comp: ({ item }) => <QuantityEvadible key={`bodyCell_quantityEvadible_documents_${item.id}`} item={item} />
	},
	{
		id: 'quantityReceived',
		comp: ({ item }) => <QuantityRequest key={`bodyCell_quantityRequest_documents_${item.id}`} item={item} />
	},
	{
		id: 'quantityToKeep',
		comp: ({ item, isCheckout }) => (
			<QuantityEvadible
				key={`bodyCell_quantityEvadible_documents_${item.id}`}
				item={item}
				isCheckout={isCheckout}
			/>
		)
	},
	{
		id: 'quantityIndeliveryNote',
		comp: ({ item }) => (
			<QuantityRequest key={`bodyCell_quantityRequest_documents_${item.id}`} item={item.requestQt} />
		)
	},
	{
		id: 'amount',
		comp: ({ item }) => <Amount key={`bodyCell_amount_docs_${item.id}`} item={item} />
	},
	{
		id: 'grossprui',
		comp: ({ item }) => <Gross key={`bodyCell_amount_documents_${item.id}`} item={item} />
	},
	{
		id: 'discount1',
		comp: ({ item }) => <Discount key={`bodyCell_discount1_documents_${item.id}`} item={item.discount1} />
	},
	{
		id: 'discount2',
		comp: ({ item }) => <Discount key={`bodyCell_discount2_documents_${item.id}`} item={item.discount2} />
	},
	{
		id: 'netprui',
		comp: ({ item }) => <Net key={`bodyCell_net_documents_${item.id}`} item={item} />
	},
	{
		id: 'note',
		comp: ({ item }) => <Note key={`bodyCell_note_documents_${item.id}`} item={item} />
	},
	{
		id: 'number',
		comp: ({ item }) => <Numberr key={`bodyCell_number_documents_${item.id}`} item={item} />
	},
	{
		id: 'date',
		comp: ({ item }) => <Datee key={`bodyCell_date_documents_${item.id}`} item={item} />
	},
	{
		id: 'customer',
		comp: ({ item }) => <Customer key={`bodyCell_customer_documents_${item.id}`} item={item} />
	},
	{
		id: 'summaryInvoice',
		comp: ({ item }) => <SummaryInvoice key={`bodyCell_summaryInvoice_documents_${item.id}`} item={item} />
	},
	{
		id: 'amountSI',
		comp: ({ item }) => <AmountSI key={`bodyCell_amountSI_documents_${item.id}`} item={item} />
	}
];

export default allCells;
