import k from 'k';
import utils from 'services/utils';

export default data => {
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify(data),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/specialDelivery`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
