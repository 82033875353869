import { action, computed, actionOn, thunkOn } from 'easy-peasy';
import merge from 'lodash/merge';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import filtersInitialState from './filters-initial-state';

export default {
	list: {
		...filtersInitialState
	},
	open: false,
	setOpen: action((state, payload) => {
		state.open = payload;
	}),
	showFullScreen: true,
	setFullScreenOnSearchComplete: actionOn(
		(actions, storeActions) => [storeActions.catalog.search.performSearch.successType],
		(state, target) => {
			const {
				result: { items }
			} = target;
			state.showFullScreen = items.length === 0;
		}
	),
	removeFullScreenOnSearch: actionOn(
		(actions, storeActions) => [storeActions.catalog.search.performSearch.startType],
		(state, target) => {
			state.showFullScreen = false;
		}
	),
	arePristine: computed(state => isEqual(state.list, filtersInitialState)),
	promoSearch: computed(state => state.list.selectedPromotion !== ''),
	save: action((state, payload) => {
		if (payload.onlySpecial && state.list.onlySpecial !== payload.onlySpecial) payload.excludeOutOfList = false;
		if (payload.excludeOutOfList && state.list.excludeOutOfList !== payload.excludeOutOfList)
			payload.onlySpecial = false;
		merge(state.list, payload);
	}),
	clearFiltersOnChangePage: actionOn(
		(actions, storeActions) => [storeActions.catalog.search.setScope],
		(state, target) => {
			const { payload } = target;
			if (payload !== 'catalog') {
				state.list = {
					...filtersInitialState,
					allWords: true
				};
			}
		}
	),
	reset: action((state, payload) => {
		state.list = {
			...filtersInitialState,
			allWords: true
		};
		state.showFullScreen = true;
	}),
	reset_: action((state, payload) => {
		state.list = {
			...filtersInitialState,
			allWords: true
		};
		state.showFullScreen = true;
	}),
	onSelectPromotion: actionOn(
		(actions, storeActions) => [storeActions.catalog.promotions.select],
		(state, target) => {
			state.list.selectedPromotion = target.payload;
		}
	),
	resetSingle: action((state, payload) => {
		const initialValue = filtersInitialState[payload];
		state.list[payload] = initialValue;
		if (payload === 'selectedScope') state.list.selectedSubScope = null;
	}),
	resetSingleNoTrigger: action((state, payload) => {
		const initialValue = filtersInitialState[payload];
		state.list[payload] = initialValue;
		if (payload === 'selectedScope') state.list.selectedSubScope = null;
	}),
	resetSingleDewey: action((state, payload) => {
		state.list.dewey = state.list.dewey.filter(f => f.id !== payload);
	}),
	onSelectScope: actionOn(
		(actions, storeActions) => [storeActions.catalog.scopes.select, storeActions.catalog.scopes.selectSub],
		(state, target) => {
			const selectedScope = state.scopes.find(item => item.selected);
			if (!selectedScope) return;
			const selectedSubScope = selectedScope.children.find(item => item.selected);
			if (selectedSubScope && selectedSubScope.id === -1) {
				state.list.selectedScope = null;
				state.list.selectedSubScope = null;
			} else {
				state.list.selectedScope = selectedScope;
				state.list.selectedSubScope = selectedSubScope;
			}
		}
	),
	scopes: computed([(state, storeState) => storeState.catalog.scopes.list], s => s),
	applyDeweyFilters: action((state, payload) => {
		const selectedItems = [];
		const traverse = item => {
			if (item.selected) selectedItems.push(item);
			if (item.children && item.children.length > 0) {
				forEach(item.children, traverse);
			}
		};
		forEach(payload, traverse);
		state.list.dewey = selectedItems.filter(f => f.grandParent !== null);
	}),
	deweyCount: computed([state => state.list.dewey], l => l.length),
	resetOnPersonification: thunkOn(
		(actions, storeActions) => [storeActions.me.personify.sendRequest.successType],
		async (actions, target, helpers) => {
			actions.reset_();
		}
	)
};
