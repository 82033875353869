import React, { memo } from 'react';
import moment from 'moment';
import StyledTableCell from 'components/Table/StyledTableCell';

const PublicationDateComp = ({ publicationDate, width }) => {
	const w = {
		width,
		maxWidth: width,
		minWidth: width
	};
	return <StyledTableCell style={w}>{moment(publicationDate).format('L')}</StyledTableCell>;
};

const PublicationDate = memo(
	({ item, width }) => <PublicationDateComp publicationDate={item.publicationDate} width={width} />,
	(p, n) => p.item.publicationDate === n.item.publicationDate
);

export default PublicationDate;
