import React, { Fragment } from 'react';
import utils from 'components/utils';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import styles from './styles';

const AddButton = props => {
	const { classes, onClick } = props;
	const translate = utils.useI18N();
	return (
		<Fragment>
			<Button
				disableRipple
				className={classes.addButton}
				variant="text"
				fullWidth={true}
				color="primary"
				onClick={onClick}
				startIcon={<AddIcon />}
			>
				<Typography variant="body2">{translate('common.add')}</Typography>
			</Button>
		</Fragment>
		//TODO: apply label translations
	);
};

export default withStyles(styles, { withTheme: true })(AddButton);
