import { action, thunk } from 'easy-peasy';

export default {
	list: [],
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	skipNext: false,
	setSkipNext: action((state, payload) => {
		state.open = !payload;
		state.skipNext = payload;
	}),
	open: false,
	setOpen: action((state, payload) => {
		//if(!state.skipNext)
		state.open = payload;
		state.skipNext = !payload;
	}),
	token: '',
	setToken: action((state, payload) => {
		state.token = payload;
	}),
	lastToken: '', //Usato per evitare il doppio load
	setLastToken: action((state, payload) => {
		state.lastToken = payload;
	}),
	reset: action((state, payload) => {
		state.token = '';
		state.lastToken = '';
		state.open = false;
		state.skipNext = false;
		state.list = [];
	}),
	save: action((state, payload) => {
		state.list = payload;
	}),
	load: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const token = getState().token;
		const prevToken = getState().lastToken;
		actions.setLastToken(token);
		if (!token || token === '' || token === prevToken) return;
		actions.setLoading(true);
		services.catalog
			.themaAutocomplete(payload, token)
			.then(data => {
				actions.save(data);
				//	getStoreActions().catalog.thema.updateItemsSelected();
			})
			.catch(e => {
				getStoreActions().error('catalog.themaAutocomplete');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
