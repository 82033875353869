import { action, thunk } from 'easy-peasy';
import utils from 'store/utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	totalResults: 0,
	save: action((state, payload) => {
		state.items = payload.items;
		state.totalResults = payload.totalItems;
	}),
	filters: {
		token: ''
	},
	setToken: action((state, payload) => {
		state.filters.token = payload;
	}),
	load: thunk((actions, payload, { getState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const page = getState().pagination.current - 1;
		const step = getState().pagination.step;
		const token = getState().filters.token;
		const params = {
			token,
			page,
			step
		};
		return services.users
			.search(params)
			.then(data => {
				actions.save(data);
				return Promise.resolve(data.items);
			})
			.catch(e => {
				getStoreActions().error('users.search');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	...utils.createPagination({ step: 25, paginationTrigger: 'save', loadFn: 'load' })
};
