import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { Stack, Box, Typography, Paper, LinearProgress } from '@mui/material';
import HeaderPageDetail from './HeaderPageDetail';
import DetailInfo from './DetailInfo';
import DetailItems from './DetailItems';
import DetailsUserGroups from './DetailsUserGroups';
import utils from 'components/utils';

const Detail = props => {
	const theme = useTheme();
	const params = useParams();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const useType = utils.useType();
	const user = useStoreState(state => state.me.data);
	const [iAmPersonifying, target] = utils.usePersonification();
	const loading = useStoreState(state => state.proposals.details.loading);
	const data = useStoreState(state => state.proposals.details.data);
	const load = useStoreActions(dispatch => dispatch.proposals.details.load);

	useEffect(() => {
		if (!data || !data.id || data.id !== params.id) load(params.id);
	}, [params.id]);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				<HeaderPageDetail />
				{loading && (
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<LinearProgress sx={{ color: theme.palette.primary.main }} />
					</Box>
				)}
			</Paper>
			{!loading && (
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%', marginTop: '20px' }}
				>
					<DetailInfo />
					<Stack
						direction={{ xs: 'column', md: 'row', sm: 'column' }}
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={1}
						sx={{ width: '100%' }}
					>
						{false && useType !== 'CLIENT' && (
							<Stack
								direction="column"
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={1}
								sx={{ width: { xs: '100%', md: '50%', sm: '100%' } }}
							>
								<DetailsUserGroups />
							</Stack>
						)}
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={1}
							sx={{
								width: { xs: '100%', md: false && useType !== 'CLIENT' ? '50%' : '100%', sm: '100%' },
								marginTop: { xs: '20px !important', md: '0 !important', sm: '20px !important' }
							}}
						>
							<DetailItems proposal={data} />
						</Stack>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};

export default Detail;
