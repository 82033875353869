import { action, thunk } from 'easy-peasy';

export default {
	loading: null,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	openModal: false,
	setOpenModal: action((state, payload) => {
		state.openModal = payload;
	}),
	loadingFcd: null,
	setLoadingFcd: action((state, payload) => {
		state.loadingFcd = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { depot, successMessage, fcd, history, redirect, refresh = false } = payload;
		fcd ? actions.setLoadingFcd(depot) : actions.setLoading(depot);
		return services.order
			.create(payload)
			.then(data => {
				const { item } = data;
				if (item.budgetDateErrorMessage && item.budgetDateErrorMessage !== '') {
					getStoreActions().errorCustom(item.budgetDateErrorMessage);
				} else if (item.budgetResidualErrorMessage && item.budgetResidualErrorMessage !== '') {
					getStoreActions().errorCustom(item.budgetResidualErrorMessage);
				} else {
					getStoreActions().successCustom(successMessage);
					getStoreActions().catalog.search.incrementItemQuantity({
						ean: payload?.catalog,
						newQuantity: payload?.quantity
					});
					if (redirect && redirect !== '') {
						history.push(redirect);
						if (refresh) {
							history.go();
						}
					} else {
						history.goBack();
					}
				}
			})
			.catch(e => {
				getStoreActions().error('order.create');
			})
			.finally(_ => {
				actions.setLoading(null);
				actions.setLoadingFcd(null);
			});
	}),
	modalOpen: false,
	setModalOpen: action((state, payload) => {
		state.modalOpen = payload;
	})
};
