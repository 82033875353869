import oneClick from './one-click';
import getList from './get-list';
import exportPdf from './export-pdf';
import exportXls from './export-xls';
import remove from './remove';
import create from './create';
import createMultiple from './create-multiple';
import getStockForList from './get-stock-for-list';
import getSingleHistory from './single-history';
import update from './update';
import tracking from './tracking';
import removeItems from './remove-items';

export default {
	oneClick,
	getList,
	exportPdf,
	exportXls,
	remove,
	create,
	createMultiple,
	getStockForList,
	getSingleHistory,
	update,
	tracking,
	removeItems
};
