import React, { Fragment, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import utils from 'components/utils';
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Paper,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

const ConditionsData = _ => {
	const theme = useTheme();
	const data = useStoreState(state => state.users.conditionsOfSale.data);
	const [expanded, setExpanded] = useState(-1);
	const onAccordionChange = index => {
		if (expanded === index) setExpanded(-1);
		else setExpanded(index);
	};
	const formatPercentage = item => {
		return item.replace(/-/g, '');
	};
	return (
		<Stack direction={{ sm: 'row', xs: 'column' }} spacing={2} sx={{ width: '100%', marginTop: theme.spacing(2) }}>
			<Stack direction={'column'} spacing={2} width={{ sm: '50%', xs: '100%' }}>
				<Accordion
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 0}
					onChange={_ => onAccordionChange(0)}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="titleAccordion">Informazioni Generali</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TableContainer>
							<Table>
								<TableRow>
									<TableCell variant="head">Modalita pagamento</TableCell>
									<TableCell>{data.paymentModeDesc}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Tipo pagamento</TableCell>
									<TableCell>{data.paymentCodeDesc}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Tipo fido </TableCell>
									<TableCell>{data.creditType}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Valore fido</TableCell>
									<TableCell>{`${data.creditUsed}€/${data.creditValue}€`}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Cliente bloccato </TableCell>
									<TableCell>{data.isCustomerBlocked}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Valore minimo ordine </TableCell>
									<TableCell>{data.orderMinimumValue}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Accredito attivo</TableCell>
									<TableCell>{data.activeAccreditationDesc}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Vettore</TableCell>
									<TableCell>{data.courierDesc}</TableCell>
								</TableRow>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
				<Accordion
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 1}
					onChange={_ => onAccordionChange(1)}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="titleAccordion">Condizioni</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Tipo sconto</TableCell>
										<TableCell>Nome sconto</TableCell>
										<TableCell>Percentuale</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.conditionsList.map((item, index) => (
										<TableRow key={index}>
											<TableCell>{item.description}</TableCell>
											<TableCell>{item.materialsGroupDef}</TableCell>
											<TableCell>{`${formatPercentage(item.percCond)}${
												item.unitCond
											}`}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			</Stack>
			<Stack direction={'column'} spacing={2} width={{ sm: '50%', xs: '100%' }}>
				<Accordion
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 2}
					onChange={_ => onAccordionChange(2)}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="titleAccordion">Servizio</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{data.expensesList.length > 0 && (
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Nome sconto</TableCell>
											<TableCell>Unità</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.expensesList.map((item, index) => (
											<TableRow key={index}>
												<TableCell>{item.description}</TableCell>
												{item.unitCond === 'EUR' && (
													<TableCell>{utils.formatPrice(item.percCond)}</TableCell>
												)}
												{item.unitCond !== 'EUR' && (
													<TableCell>
														{item.percCond} {item.unitCond}
													</TableCell>
												)}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
						{data.expensesList.length === 0 && <Typography variant="text">Senza informazioni</Typography>}
					</AccordionDetails>
				</Accordion>
				<Accordion
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 3}
					onChange={_ => onAccordionChange(3)}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="titleAccordion">Condizioni editore</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Nome editore</TableCell>
										<TableCell>Tipo sconto</TableCell>
										<TableCell>Nome sconto</TableCell>
										<TableCell>Percentuale</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.conditionsPublisherList.map((item, index) => (
										<TableRow key={index}>
											<TableCell>{item.merchandisesGroupDef}</TableCell>
											<TableCell>{item.description}</TableCell>
											<TableCell>{item.materialsGroupDef}</TableCell>
											<TableCell>{`${formatPercentage(item.percCond)}${
												item.unitCond
											}`}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			</Stack>
		</Stack>
	);
};

export default ConditionsData;
