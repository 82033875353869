import React, { Fragment, forwardRef } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import utils from 'components/utils';

const ButtonContent = forwardRef((props, ref) => <RouterLink {...props} />);

const styles = theme => ({
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 1, 0, 0)
		}
	}
});

const ImportButton = ({ classes }) => {
	const translate = utils.useI18N();
	return (
		<Fragment>
			<Button className={classes.buttonsMargin} variant="outlined" component={ButtonContent} to="/import">
				{translate('wishlist.import')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(ImportButton);
