import k from 'k';
import utils from 'services/utils';

export default ({ token, page, step, filters, order }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const f = { ...filters };
	const scopeMap = {
		'coming-soon': 'comingsoon',
		coupons: 'coupon',
		'editorial-unlock': 'editorial',
		full: '',
		languages: 'language',
		'new-on-sale': 'newOnSale',
		rankings: 'ranking',
		typology: 'type'
	};
	if (f.selectedScope) {
		const s = scopeMap[f.selectedScope.id];
		if (s) f[s] = f.selectedSubScope ? f.selectedSubScope.id : true;
	}
	f.selectedScope = null;
	f.selectedSubScope = null;
	if (f.dewey && f.dewey.length > 0) f.dewey = f.dewey.map(d => d.id).join();
	const params = {
		keyword: token,
		rows: step,
		page: page,
		...f,
		sortby: order.by,
		sortdir: order.direction.toUpperCase()
	};
	const qParams = utils.createQueryParams(params);
	return utils
		.fetch(`${k.endpoints.apis}/catalog/find?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
