import React from 'react';
import Selection from './Selection';
import Empty from './Empty';
import Sortable from './Sortable';
import NotSortable from './NotSortable';

const allCols = [
	{
		id: 'selection',
		comp: _ => <Selection key="headCell_selection" />
	},
	{
		id: 'cover',
		comp: _ => <Empty key="headCell_cover" additionalStyles={{ width: 45 }} />
	},
	{
		id: 'ean',
		comp: _ => <Sortable id="ean" key="headCell_ean" label="catalog.tableHeaders.ean" />
	},
	{
		id: 'author',
		comp: _ => <Sortable id="author" key="headCell_author" label="catalog.tableHeaders.author" />
	},
	{
		id: 'preload',
		comp: _ => <Empty key="headCell_preload" additionalStyles={{ width: 50 }} />
	},
	{
		id: 'availability',
		comp: _ => <Empty key="headCell_availability" additionalStyles={{ width: 24 }} />
	},
	{
		id: 'title',
		comp: _ => (
			<Sortable
				id="title"
				key="headCell_title"
				label="catalog.tableHeaders.title"
				additionalStyles={{ maxWidth: 300 }}
			/>
		)
	},
	{
		id: 'volume',
		comp: _ => (
			<Sortable
				id="volume"
				key="headCell_volume"
				label="catalog.tableHeaders.volume"
				additionalStyles={{ maxWidth: 50 }}
			/>
		)
	},
	{
		id: 'serie',
		comp: _ => <Sortable id="serie" key="headCell_serie" label="catalog.tableHeaders.serie" />
	},
	{
		id: 'publisher',
		comp: _ => <Sortable id="publisher" key="headCell_publisher" label="catalog.tableHeaders.publisher" />
	},
	{
		id: 'publicationDate',
		comp: _ => (
			<Sortable
				id="publicationDate"
				key="headCell_publicationDate"
				label="catalog.tableHeaders.publicationDate"
				additionalStyles={{ width: 45 }}
			/>
		)
	},
	{
		id: 'price',
		comp: _ => (
			<Sortable
				id="price"
				key="headCell_price"
				label="catalog.tableHeaders.price"
				additionalStyles={{ width: 100, paddingLeft: 10 }}
			/>
		)
	},
	{
		id: 'promo',
		comp: _ => <Empty key="headCell_promo" additionalStyles={{ width: 30 }} />
	},
	{
		id: 'newsItem',
		comp: _ => <Sortable id="newsItem" key="headCell_newsItem" label="catalog.tableHeaders.newsItem" />
	},
	{
		id: 'cart',
		comp: _ => (
			<NotSortable key="headCell_cart" label="catalog.tableHeaders.cart" additionalStyles={{ width: 85 }} />
		)
	},
	{
		id: 'stock',
		comp: _ => <Sortable id="stock" key="headCell_stock" label="catalog.tableHeaders.stock" />
	},
	{
		id: 'oneClick',
		comp: _ => <Empty key="headCell_oneClick" />
	},
	{
		id: 'ranking',
		comp: _ => <Empty key="headCell_ranking" additionalStyles={{ width: 34 }} />
	}
];

export default allCols;
