import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Box, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EuroIcon from '@mui/icons-material/Euro';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import utils from 'components/utils';

const Summary = props => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const items = useStoreState(state => state.cart.evasion.items);
	const depots = useStoreState(state => state.cart.evasion.depots);
	const totalEvadable = useStoreState(state => state.cart.evasion.totalEvadable);
	const paymentType = useStoreState(state => state.cart.evasion.paymentType);
	const paymentCond = useStoreState(state => state.cart.evasion.paymentCond);
	const paymentMethod = useStoreState(state => state.cart.evasion.paymentMethod);
	const numCartsSelected = items.filter(item => item.selected).length;
	const numDepotsSelected = depots.filter(d => d.selected).length;
	const listDepotsSelected = depots
		.filter(d => d.selected)
		.map(d => d.name)
		.join(', ');
	const totalFulfillment = depots.filter(d => d.selected).reduce((t, d) => (t = t + d.total), 0);
	const paymentOnlineEnabled = process.env.REACT_APP_PAYMENTONLINE_ENABLED;

	const methods = [
		{
			id: 0,
			label: 'orders.evasion.cashOnDelivery',
			desc: '',
			value: 0,
			icon: <LocalShippingIcon sx={{ color: theme.palette.primary.main }} />
		},
		{
			id: 1,
			label: 'orders.evasion.cash',
			desc: '',
			value: 1,
			icon: <EuroIcon sx={{ color: theme.palette.primary.main }} />
		},
		{
			id: 2,
			label: 'orders.evasion.online',
			desc: 'orders.evasion.onlinedesc',
			value: 2,
			icon: <CreditCardIcon sx={{ color: theme.palette.primary.main }} />
		}
	];

	const summaryInfo = [
		{
			id: 0,
			label: 'orders.evasion.selectedCart',
			value: numCartsSelected,
			cond: true
		},
		{
			id: 1,
			label: 'orders.evasion.selectedDepot',
			value: numDepotsSelected + ' (' + listDepotsSelected + ')',
			cond: true
		},
		{
			id: 2,
			label: 'orders.evasion.totalEvadible',
			desc: paymentMethod != -1 ? translate(methods.filter(method => method.value == paymentMethod)[0].desc) : '',
			value: utils.formatPrice(totalFulfillment),
			cond: true
		},
		{
			id: 3,
			label: 'orders.evasion.paymentMethod',
			value:
				paymentMethod != -1 ? translate(methods.filter(method => method.value == paymentMethod)[0].label) : '',
			cond: paymentOnlineEnabled == 'true' && paymentCond === '1' && (paymentType === '1' || paymentType === '8')
		}
	];

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			{summaryInfo
				.filter(sum => sum.cond)
				.map((info, index) => (
					<Stack
						key={info.id}
						direction="column"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={0.5}
						sx={{ padding: '5px', width: '100%' }}
					>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={0.5}
							sx={{ width: '100%' }}
						>
							<CheckCircleIcon sx={{ fontSize: '30px', color: theme.palette.primary.main }} />
							<Typography variant="titleSection">
								{translate(info.label) + ': '}
								<span style={{ color: theme.palette.primary.main }}>{info.value}</span>
							</Typography>
						</Stack>
						<Typography variant="text" sx={{ color: theme.palette.custom.orange, paddingLeft: '33px' }}>
							{info.desc}
						</Typography>
					</Stack>
				))}
		</Stack>
	);
};

export default Summary;
