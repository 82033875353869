import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	openModalImport: false,
	setOpenModalImport: action((state, payload) => {
		state.openModalImport = payload;
	}),
	results: [],
	save: action((state, payload) => {
		state.results = payload.map(item => ({
			...item,
			isSelected: false
		}));
	}),
	importItmes: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.proposals
			.importItems(payload)
			.then(data => {
				actions.setOpenModalImport(false);
				if (data.items.length === 0) {
					getStoreActions().error('proposals.errorImportItems');
				} else {
					getStoreActions().success('proposals.importItems');
					actions.save(data.items);
					getStoreActions().proposals.detailItems.setData(data.items);
				}
				return Promise.resolve(data);
			})
			.catch(e => {
				console.log('errore', e);
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
