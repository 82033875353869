import React, { memo } from 'react';
import { useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

const SelectionComp = ({ id, active }) => {
	const toggleSelection = useStoreActions(dispatch => dispatch.purchasings.list.toggleSelection);

	return (
		<TableCell padding="checkbox">
			<Checkbox
				color="primary"
				onChange={_ => toggleSelection(id)}
				checked={active}
				inputProps={{ 'aria-labelledby': id }}
			/>
		</TableCell>
	);
};

const Selection = memo(
	({ item }) => <SelectionComp id={item.id} active={item.isSelected} />,
	(p, n) => p.item.id === n.item.id && p.item.isSelected === n.item.isSelected
);

export default Selection;
