import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const ItemNumberComp = ({ pieces }) => {
	return <StyledTableCell>{pieces}</StyledTableCell>;
};

const ItemNumber = memo(
	({ item }) => <ItemNumberComp pieces={item.pieces} />,
	(p, n) => p.item.pieces === n.item.pieces
);

export default ItemNumber;
