import healthInfo from './health-info';
import deleteStatus from './delete-status';
import createStatus from './create-status';
import allHealthInfo from './all-health-info';

export default {
	allHealthInfo,
	deleteStatus,
	createStatus,
	healthInfo
};
