import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	isWishlist: false,
	setIsWishlist: action((state, payload) => {
		state.loading = payload;
	}),
	scope: '',
	setScope: action((state, payload) => {
		state.scope = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { history, redirect, successMessage, goBack = true, refresh = false } = payload;
		actions.setLoading(true);
		return services.wishlist
			.addToWishlist(payload)
			.then(data => {
				getStoreActions().successCustom(successMessage);
				if (redirect && redirect !== '') {
					history.push(redirect);
					if (refresh) {
						history.go();
					}
				} else if (goBack) {
					history.goBack();
				}
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('order.create');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
