import { createStore, persist, action } from 'easy-peasy';
import services from 'services';
import appNotifications from './appNotifications';
import me from './me';
import messageType from './messageType';
import channels from './channels';
import i18n from './i18n';
import catalog from './catalog';
import cart from './cart';
import wishlist from './wishlist';
import order from './order';
import menu from './menu';
import shippingType from './shippingType';
import selection from './selection';
import cms from './cms';
import depots from './admin/depots';
import returns from './admin/returns';
import promotions from './admin/promotions';
import coupons from './admin/coupons';
import purchasings from './admin/purchasings';
import rankings from './admin/rankings';
import editorials from './admin/editorials';
import userGroups from './admin/userGroups';
import users from './admin/users';
import conditionRequest from './admin/conditionRequest';
import notifications from './notifications';
import BOnotifications from './admin/notifications';
import documents from './documents';
import BOspecialDelivery from './admin/specialDelivery';
import specialDelivery from './specialDelivery';
import ca from './ca';
import budget from './budget';
import importt from './import';
import wordpress from './wordpress';
import health from './health';
import lastShipping from './lastShipping';
import homePromotions from './homePromotions';
import stripe from './stripe';
import proposals from './proposals';
import contest from './contest';

const model = {
	appNotifications,
	BOnotifications,
	BOspecialDelivery,
	budget,
	ca,
	cart,
	catalog,
	channels,
	cms,
	conditionRequest,
	coupons,
	depots,
	documents,
	editorials,
	homePromotions,
	i18n,
	importt,
	lastShipping,
	me,
	menu,
	messageType,
	notifications,
	order,
	promotions,
	purchasings,
	rankings,
	returns,
	selection,
	shippingType,
	specialDelivery,
	userGroups,
	users,
	wishlist,
	wordpress,
	health,
	stripe,
	proposals,
	contest,
	error: action((state, payload) => {}),
	errorCustom: action((state, payload) => {}),
	success: action((state, payload) => {}),
	successCustom: action((state, payload) => {})
};

export default createStore(
	persist(model, {
		whitelist: ['me'],
		storage: 'localStorage'
	}),
	{
		injections: {
			services
		}
	}
);
