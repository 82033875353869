import React, { memo, useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';
import head from 'lodash.head';

const DepotComp = ({ ean, depot, type, depots, epipoli }) => {
	const availableDepots = utils.useFilteredDepots();
	const selectDepot = useStoreActions(dispatch => dispatch.selection.selectDepot);
	const enableDepots = depots.filter(d => (type === 200 ? d.id === 'FS01' : d.enable)).map(dep => dep.id);
	//Elimino i depositi non abilitati tra quelli disponibili per l'utente
	const depotsForSelect = availableDepots.filter(dep => enableDepots.includes(dep.id));
	//Imposto il valore da selezionare se contenuto tra i depositi validi
	const selvalDep = depotsForSelect.filter(dep => dep.id === depot.id).map(d => d.id);
	const selval = selvalDep.length > 0 ? selvalDep[0] : depotsForSelect.length > 0 ? head(depotsForSelect).id : '';

	return (
		<StyledTableCell style={{ minWidth: 100, maxWidth: 100, width: 100 }}>
			{type !== 100 && type !== 200 && (
				<FormControl variant="outlined" style={{ maxWidth: 100 }}>
					<Select
						value={selval}
						onChange={e => selectDepot({ ean, depot: e.target.value })}
						style={{ height: 35 }}
					>
						{depotsForSelect.map(ad => (
							<MenuItem key={ad.id} value={ad.id}>
								{ad.description}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			{(type === 100 || type == 200) && <Typography variant="body2">Fast Hub</Typography>}
		</StyledTableCell>
	);
};

const Depot = memo(
	({ item, epipoli }) => (
		<DepotComp
			ean={item.ean}
			depot={item.selection.depot}
			type={item.type}
			depots={item.depots}
			epipoli={epipoli}
		/>
	),
	(p, n) =>
		p.item.ean === n.item.ean &&
		p.item.selection.depot === n.item.selection.depot &&
		p.item.type === n.item.type &&
		p.item.dep === n.item.depots
);

export default Depot;
