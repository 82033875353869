import { action, thunk } from 'easy-peasy';

export default {
	loadingAccept: false,
	setLoadingAccept: action((state, payload) => {
		state.loadingAccept = payload;
	}),
	loadingReject: false,
	setLoadingReject: action((state, payload) => {
		state.loadingReject = payload;
	}),
	accept: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingAccept(true);
		const selectedId = getStoreState().returns.currentDetail.id;
		const selectedItem = getStoreState().returns.pending.list.find(item => item.number === selectedId);
		if (!selectedItem) return;
		const {
			depot: { depotCode }
		} = selectedItem;
		return services.returns
			.accept({ number: payload, returnedDepot: depotCode, notes: selectedItem.notes })
			.then(data => {
				getStoreActions().success('returnRequests.general');
				Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('returnRequests.general');
			})
			.finally(_ => {
				actions.setLoadingAccept(false);
			});
	}),
	reject: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingReject(true);
		const selectedId = getStoreState().returns.currentDetail.id;
		const selectedItem = getStoreState().returns.pending.list.find(item => item.number === selectedId);
		if (!selectedItem) return;
		const {
			depot: { depotCode }
		} = selectedItem;
		return services.returns
			.reject({ number: payload, returnedDepot: depotCode, notes: selectedItem.notes })
			.then(data => {
				getStoreActions().success('returnRequests.general');
				Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('returnRequests.general');
			})
			.finally(_ => {
				actions.setLoadingReject(false);
			});
	})
};
