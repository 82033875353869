import k from 'k';
import utils from 'services/utils';

export default ({ wishlistId, cartId, fcd, cart }) => {
	const requestOptions = {
		method: 'POST',
		headers: utils.createHeaders()
	};
	const params = {};
	if (cartId !== undefined && cartId !== -1) params.cartId = cartId;
	if (fcd) params.transferType = 'FCD';
	const qParams = utils.createQueryParams(params, false);
	const endpoint = `${k.endpoints.apis}/wishlist/${wishlistId}/cart?${qParams}`;

	return utils.fetch(endpoint, requestOptions).then(response => utils.handleResponse({ response }));
};
