import { action, thunk } from 'easy-peasy';
import { loadStripe } from '@stripe/stripe-js';
const stripeSecretKey = process.env.REACT_APP_STRIPE_KEY;

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	stripeError: false,
	setStripeError: action((state, payload) => {
		state.stripeError = payload;
	}),
	errorMessage: '',
	setErrorMessage: action((state, payload) => {
		state.errorMessage = payload;
		state.stripeError = true;
	}),
	title: '',
	setTitle: action((state, payload) => {
		state.title = payload;
	}),
	stripePromise: null,
	setStripePromise: action((state, payload) => {
		state.stripePromise = payload;
	}),
	loadStripePromise: thunk((actions, payload, { injections, getStoreActions, getState }) => {
		console.log('Richiamo loadStripe con key ', stripeSecretKey);
		const stripePromise = loadStripe(stripeSecretKey);
		console.log('recuperata promise', stripePromise);
		actions.setStripePromise(stripePromise);
	}),
	clientSecret: null,
	setClientSecret: action((state, payload) => {
		state.clientSecret = payload;
	}),
	paymentIntent: null,
	setPaymentIntent: action((state, payload) => {
		state.paymentIntent = payload;
	}),
	refunded: null,
	setRefunded: action((state, payload) => {
		state.refunded = payload;
	}),
	createPaymentIntent: thunk((actions, payload, { injections, getStoreActions, getState }) => {
		const { services } = injections;
		actions.setLoading(true);
		actions.setTitle('stripe.providerConnect');
		const amount = payload * 100; //Moltiplico perchè Stripe ragiona in centesimi
		services.stripe
			.createPaymentIntent(amount)
			.then(data => {
				console.log('data stripe', data);
				if (data.item && data.item.preAuthorizationCode)
					actions.setClientSecret(data.item.preAuthorizationCode);
			})
			.catch(e => {
				actions.setErrorMessage('stripe.errorCreatePaymentIntent');
				console.log('error stripe', e);
			})
			.finally(_ => {
				actions.setLoading(false);
				actions.setTitle('');
			});
	}),
	collectPayment: thunk((actions, payload, { injections, getStoreActions, getState }) => {
		const { services } = injections;
		services.stripe
			.collectPayment()
			.then(data => {
				console.log('pagamenti', data);
			})
			.catch(e => {
				console.log('errore pagamenti', e);
			});
	})
};
