import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import CartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popper from '@mui/material/Popper';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Avatar from 'components/Avatar';
import utils from 'components/utils';
import styles from './styles';

const MobileHeaderMenu = ({ classes }) => {
	const [iAmPersonifying, targetUser] = utils.usePersonification();
	const user = useStoreState(state => state.me.data);
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const history = useHistory();
	const [popperOpen, setPopperOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const togglePopper = _ => {
		setPopperOpen(!popperOpen);
	};
	const handleClickPopper = event => {
		setAnchorEl(event.currentTarget);
		togglePopper();
	};
	// prettier-ignore
	const onProfileClick = _ => { history.push('/me'); };
	// prettier-ignore
	const onNotificationsClick = _ => { history.push('/notifications'); };
	// prettier-ignore
	const onCartClick = _ => { history.push('/orders'); };
	const onWishlistClick = _ => {
		const dest = iCan('MANAGE_WISHLIST') ? '/wishlist/condition-request' : '/wishlist/list';
		history.push(dest);
	};
	useEffect(
		_ => {
			const onResize = _ => {
				setPopperOpen(false);
				setAnchorEl(null);
			};
			window.addEventListener('resize', onResize);
			return _ => {
				window.removeEventListener('resize', onResize);
			};
		},
		[setPopperOpen, setAnchorEl]
	);
	const backgroundColor = iAmPersonifying ? '#da5f60' : 'none';
	// const displayName = iAmPersonifying ? :
	return (
		<Fragment>
			<IconButton aria-haspopup="true" onClick={handleClickPopper} color="inherit" className={classes.expandIcon}>
				{popperOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</IconButton>
			<Popper open={popperOpen} anchorEl={anchorEl} placement="bottom-end" transition style={{ zIndex: 2 }}>
				<Card className={classes.card}>
					<CardContent className={classes.content}>
						<List dense disablePadding>
							<ListItem style={{ backgroundColor }}>
								<IconButton className={classes.iconButton} onClick={onProfileClick}>
									<Avatar size={24} forceLogged={true} />
								</IconButton>
								{iAmPersonifying && (
									<ListItemText
										onClick={onProfileClick}
										primary={utils.capitalizeAll(utils.useEllipsis(user.displayName, 15, '...'))}
										secondary={utils.capitalizeAll(
											utils.useEllipsis(targetUser.displayName, 15, '...')
										)}
									/>
								)}
								{!iAmPersonifying && (
									<ListItemText
										onClick={onProfileClick}
										primary={utils.capitalizeAll(utils.useEllipsis(user.displayName, 15, '...'))}
									/>
								)}
							</ListItem>
							<Divider />
							{iCan('ORDERS') && (
								<ListItem>
									<IconButton className={classes.iconButton} disabled={true} onClick={onCartClick}>
										<CartIcon color="disabled" />
									</IconButton>
									<ListItemText onClick={onCartClick} primary={translate('common.menu.cart')} />
								</ListItem>
							)}
							<Divider />
							{iCan('WISHLIST') && (
								<ListItem>
									<IconButton className={classes.iconButton} onClick={onWishlistClick}>
										<FavoriteIcon />
									</IconButton>
									<ListItemText
										onClick={onWishlistClick}
										primary={translate('common.menu.wishlist')}
									/>
								</ListItem>
							)}
							<Divider />
							<ListItem>
								<IconButton
									className={classes.iconButton}
									disabled={true}
									onClick={onNotificationsClick}
								>
									<NotificationsIcon color="disabled" />
								</IconButton>
								<ListItemText
									onClick={onNotificationsClick}
									primary={translate('common.menu.notifications')}
								/>
							</ListItem>
						</List>
					</CardContent>
				</Card>
			</Popper>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(MobileHeaderMenu);
