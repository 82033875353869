import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const DepotComp = ({ text }) => {
	return <StyledTableCell style={{ maxWidth: 90 }}>{text}</StyledTableCell>;
};

const Depot = memo(
	({ item }) => <DepotComp text={item.depotName} />,
	(p, n) => p.item.depotName === n.item.depotName
);

export default Depot;
