export default theme => ({
	formControl: {
		margin: theme.spacing(1, 0),
		minWidth: 120,
		width: '100%'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	baseGrid: {
		minHeight: 80,
		alignContent: 'baseline'
	}
});
