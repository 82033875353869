import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import UserGroupsSelector from 'components/UserGroupsSelector';
import utils from 'components/utils';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	}
});

const AssignUserGroup = ({ classes, currentEditorial }) => {
	const translate = utils.useI18N();
	const modalOpen = useStoreState(state => state.userGroups.list.modalOpen);
	const loadingListAbilitation = useStoreState(state => state.editorials.userGroupsAbilitation.loadingList);
	const setModalOpen = useStoreActions(dispatch => dispatch.userGroups.list.setModalOpen);
	const addAbilitation = useStoreActions(dispatch => dispatch.editorials.userGroupsAbilitation.add);
	const removeAbilitation = useStoreActions(dispatch => dispatch.editorials.userGroupsAbilitation.remove);
	const detailsAbilitation = useStoreState(state => state.editorials.detailsUserGroupsAbilitation.data);

	const toggleSelectionAbilitation = (id, checked) => {
		if (checked) addAbilitation({ id: currentEditorial.id, userGroupId: id });
		else removeAbilitation({ id: currentEditorial.id, userGroupId: id });
	};

	const getCbStatusAbilitation = id => {
		return detailsAbilitation.findIndex(item => item.id === id) !== -1;
	};
	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={modalOpen}
				onClose={_ => {
					setModalOpen(false);
				}}
				closeAfterTransition
			>
				<div className="modal-content">
					<UserGroupsSelector
						toggleSelectionAbilitation={toggleSelectionAbilitation}
						loadingListAbilitation={loadingListAbilitation}
						getCbStatusAbilitation={getCbStatusAbilitation}
						oneColumn={true}
					/>
				</div>
			</Modal>

			<Button
				className={classes.buttonsMargin}
				variant="outlined"
				onClick={_ => {
					setModalOpen(true);
				}}
			>
				{translate('editorials.buttons.openUserGroups')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(AssignUserGroup);
