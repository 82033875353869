import React from 'react';

const withColumns = WrappedComponent => {
	const visibleCols = ['selection', 'cod', 'rag', 'city', 'address', 'province'];
	return props => {
		return <WrappedComponent {...props} visibleCols={visibleCols} />;
	};
};

export default withColumns;
