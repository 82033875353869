export default (actions, payload, { getStoreState, injections, getStoreActions }) => {
	const { services } = injections;
	actions.setLoading(true);
	services.shipping
		.getItems(payload)
		.then(data => {
			actions.save(data.items);
		})
		.catch(e => {
			getStoreActions().error('shippingTypes.list');
		})
		.finally(_ => {
			actions.setLoading(false);
		});
};
