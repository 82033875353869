import React from 'react';
import { useStoreState } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import utils from 'components/utils';

const NotSortable = ({ label, additionalStyles }) => {
	const promoSearch = useStoreState(state => state.catalog.filters.promoSearch);
	const translate = utils.useI18N();
	const defaultStyles = {
		paddingLeft: 4,
		paddingRight: 4,
		backgroundColor: promoSearch ? '#e67272' : '#fafafa'
	};
	return (
		<TableCell align="left" style={{ ...defaultStyles, ...additionalStyles }}>
			<span className="MuiButtonBase-root" aria-disabled="true">
				{translate(label)}
			</span>
		</TableCell>
	);
};

export default NotSortable;
