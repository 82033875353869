import { action, thunk, thunkOn } from 'easy-peasy';
import moment from 'moment';

const load = (actions, payload, { getStoreState, injections, getStoreActions }) => {
	const { services } = injections;
	actions.setLoading(true);
	services.returns
		.list('PENDING')
		.then(data => actions.save(data.items))
		.catch(e => {
			getStoreActions().error('returns.pending');
		})
		.finally(_ => {
			actions.setLoading(false);
		});
};

const getDepotCode = item => {
	if (item.availableDepots.length === 0) return '';
	if (item.availableDepots.findIndex(d => d.depotCode === 'FS07') !== -1) return 'FS07';
	return item.availableDepots[0].depotCode;
};

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	list: [],
	save: action((state, payload) => {
		state.list = payload.map(item => ({
			...item,
			date: moment(item.updatedDate, 'YYYYMMDDHHmmss'),
			notes: '',
			depot: {
				depotCode: getDepotCode(item)
			}
		}));
	}),
	load: thunk(load),
	loadOnResponseOrCreation: thunkOn(
		(actions, storeActions) => [
			storeActions.returns.respond.accept.successType,
			storeActions.returns.respond.reject.successType,
			storeActions.returns.create.sendRequest.successType
		],
		async (actions, target, helpers) => {
			load(actions, null, helpers);
		}
	),
	setNotes_: action((state, payload) => {
		const { notes, id } = payload;
		const i = state.list.findIndex(item => item.number === id);
		if (i === -1) return;
		const newItem = {
			...state.list[i],
			notes
		};
		state.list = state.list
			.slice(0, i)
			.concat(newItem)
			.concat(state.list.slice(i + 1));
	}),
	setNotes: thunkOn(
		(actions, storeActions) => [storeActions.returns.setCurrentDetailNotes],
		(actions, target, helpers) => {
			const { getStoreState } = helpers;
			const { type, id } = getStoreState().returns.currentDetail;
			if (type !== 'pending') return;
			const notes = target.payload;
			const parsedNotes = _ => {
				return String(notes).replace(/[\u00A0-\u9999]/g, function(i) {
					return '&#' + i.charCodeAt(0) + ';';
				});
			};
			actions.setNotes_({ notes: parsedNotes(), id });
		}
	),
	setDepot: thunkOn(
		(actions, storeActions) => [storeActions.returns.setCurrentDetailDepot],
		(actions, target, helpers) => {
			const { getStoreState } = helpers;
			const { type, id } = getStoreState().returns.currentDetail;
			if (type !== 'pending') return;
			actions.setDepot_({ depot: { depotCode: target.payload }, id });
		}
	),
	setDepot_: action((state, payload) => {
		const { depot, id } = payload;
		const i = state.list.findIndex(item => item.number === id);
		if (i === -1) return;
		const newItem = {
			...state.list[i],
			depot
		};
		state.list = state.list
			.slice(0, i)
			.concat(newItem)
			.concat(state.list.slice(i + 1));
	}),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	})
};
