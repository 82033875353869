import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, alpha } from '@mui/material';
import { grey } from '@mui/material/colors';

/**
 * @param {*} title anything
 * @param {*} width anything
 * @param {*} height anything
 * @param {*} backgroundColor color of the box and the typography
 * @param {*} margin top right bottom left
 * @param {*} disabled change color of text
 * @returns Box with a Typography and a children
 */
const Card = ({ title, width, height, children, backgroundColor, margin, disabled }) => {
	const theme = useTheme();
	const style = {
		box: {
			backgroundColor: backgroundColor ? backgroundColor : theme.palette.custom.grey,
			border: `2px solid ${grey[300]}`,
			borderRadius: '5px',
			padding: '15px'
		},
		title: {
			backgroundColor: backgroundColor ? backgroundColor : theme.palette.custom.grey,
			padding: '10px',
			border: `2px solid ${grey[300]}`,
			position: 'relative',
			borderRadius: '5px',
			top: '-20px',
			left: '20px',
			color: disabled && alpha(theme.palette.custom.black, 0.5)
		}
	};
	return (
		<Box sx={{ width: width, height: height, ...style.box, margin: margin }}>
			<Typography variant="titleSection" sx={{ ...style.title }}>
				{title}
			</Typography>
			{children}
		</Box>
	);
};

export default Card;
