import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import get from 'lodash/get';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import CheckboxMUI from '@mui/material/Checkbox';
import utils from 'components/utils';

const Checkbox = props => {
	const translate = utils.useI18N();
	const path = get(props, 'path');
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const disabled = get(props, 'disabled', false);
	const label = translate(`catalog.filters.${path}`);
	const handleChange = event => {
		setFilters({
			[path]: !value
		});
	};
	const value = useStoreState(state => state.catalog.filters.list[path]);
	return (
		<FormGroup aria-label="position" row style={{ height: '100%', alignContent: 'flex-end' }}>
			<FormControlLabel
				checked={value}
				disabled={disabled}
				control={<CheckboxMUI color="primary" onChange={handleChange} />}
				label={label}
				labelPlacement="end"
			/>
		</FormGroup>
	);
};

export default Checkbox;
