export default theme => ({
	elements: {
		padding: `${theme.spacing(4, 2, 1, 2)} !important`
	},
	sticky: {
		zIndex: 1101,
		position: 'fixed',
		backgroundColor: 'transparent',
		top: 150,
		right: 24,
		[theme.breakpoints.down('sm')]: {
			top: 64,
			left: theme.spacing(1),
			borderBottom: 'none',
			padding: theme.spacing(1),
			width: `calc(100% - 16px)`,
			textAlign: 'center',
			justifyContent: 'center'
		}
	}
});
