import k from 'k';
import utils from 'services/utils';

export default ({ step, page }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = {
		rows: step,
		page
	};
	const qParams = utils.createQueryParams(params);
	return utils
		.fetch(`${k.endpoints.apis}/notification/all/?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
