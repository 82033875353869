import { action, thunk, thunkOn } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	expanded: -1,
	setExpanded: action((state, payload) => {
		state.expanded = payload;
	}),
	items: [],
	save: action((state, payload) => {
		state.items = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.BOspecialDelivery.getList()
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('specialDelivery.list');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	reloadOnUpdate: thunkOn(
		(actions, storeActions) => [storeActions.BOspecialDelivery.update.sendRequest],
		async (actions, target, helpers) => {
			actions.load();
		}
	)
};
