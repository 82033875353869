import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: [],
	modalOpen: false,
	setModalOpen: action((state, payload) => {
		state.modalOpen = payload;
	}),

	openFromURl: true,
	setOpenFromUrl: action((state, payload) => {
		state.openFromURl = payload;
	}),

	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.cms
			.getPromotions()
			.then(data => actions.save(data))
			.catch(e => {
				//getStoreActions().error('Errore nel caricamento delle promozioni');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	save: action((state, payload) => {
		state.data = payload.items;
	})
};
