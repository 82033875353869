import details from './details';
import list from './list';
import filters from './filters';
import search from './search';
import update from './update';
import referentList from './referent-list';
import add from './add';
import deletePurchasings from './delete-purchasing';
import assignUsers from './assign-users';
import importt from './import';
import detailsItems from './details-items';
import report from './report';

export default {
	details,
	list,
	filters,
	search,
	update,
	referentList,
	add,
	deletePurchasings,
	assignUsers,
	importt,
	detailsItems,
	report
};
