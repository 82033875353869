import React from 'react';
import Sortable from './Sortable';
import Empty from './Empty';
import Selection from './Selection';

const allCols = [
	{
		id: 'selection',
		comp: _ => <Selection key="headCell_selection_purchasing" />
	},
	{
		id: 'id',
		comp: _ => <Sortable id="id" key="headCell_id_purchasing" label="purchasings.tables.id" />
	},
	{
		id: 'name',
		comp: _ => <Sortable id="name" key="headCell_sapName_purchasing" label="purchasings.tables.name" />
	},
	{
		id: 'ref',
		comp: _ => <Sortable id="referentCode" key="headCell_status_ref" label="purchasings.tables.ref" />
	},
	{
		id: 'nameRef',
		comp: _ => <Sortable id="referentUserName" key="headCell_status_nameRef" label="purchasings.tables.nameRef" />
	},
	{
		id: 'status',
		comp: _ => <Sortable id="enabled" key="headCell_status_purchasing" label="purchasings.tables.status" />
	},
	{
		id: 'edit',
		comp: _ => <Empty id="edit" key="headCell_edit_purchasing" label="purchasings.tables.edit" />
	}
];

export default allCols;
