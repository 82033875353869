import React from 'react';
import { useTheme } from '@mui/material/styles';
import TableHeadMUI from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import allCols from './cols';
import withColumns from '../columns';

const TableHead = props => {
	const { visibleCols } = props;
	const theme = useTheme();
	const cells = visibleCols.map(col => allCols.find(c => c.id === col));
	return (
		<TableHeadMUI sx={{ background: theme.palette.custom.greytable, fontWeight: '600' }}>
			<TableRow>{cells.map(cell => cell.comp())}</TableRow>
		</TableHeadMUI>
	);
};

export default withColumns(TableHead);
