import { action, thunk } from 'easy-peasy';

export default {
	value: 0,
	save: action((state, payload) => {
		state.value = payload;
	}),
	load: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		services.catalog
			.count()
			.then(data => {
				actions.save(data);
			})
			.catch(e => console.log(e));
	})
};
