import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const NewsItemComp = ({ newsItem, type }) => {
	const txt = type === 100 ? 'introvabili' : newsItem;
	return <StyledTableCell>{txt}</StyledTableCell>;
};

const NewsItem = memo(
	({ item }) => <NewsItemComp newsItem={item.newsItem} type={item.type} />,
	(p, n) => p.item.newsItem === n.item.newsItem && p.item.type === n.item.type
);

export default NewsItem;
