import { action, thunk } from 'easy-peasy';

export default {
	loading: null,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	send: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(payload);
		return services.documents
			.sendEmail(payload)
			.then(data => {
				getStoreActions().success('documents.sendEmail');
			})
			.catch(e => {
				getStoreActions().error('documents.sendEmail');
			})
			.finally(_ => {
				actions.setLoading(null);
			});
	})
};
