import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import Grid from '@mui/material/Grid';

const PaymentComp = ({ paymentTypeDesc, paymentCondDesc }) => {
	return (
		<StyledTableCell style={{ maxWidth: 125 }}>
			<Grid container>
				<Grid item container xs={12}>
					{paymentTypeDesc}
				</Grid>
				<Grid item container xs={12}>
					{paymentCondDesc}
				</Grid>
			</Grid>
		</StyledTableCell>
	);
};

const Payment = memo(
	({ item }) => <PaymentComp paymentCondDesc={item.paymentCondDesc} paymentTypeDesc={item.paymentTypeDesc} />,
	(p, n) => p.item.paymentCondDesc === n.item.paymentCondDesc && p.item.paymentTypeDesc === n.item.paymentTypeDesc
);

export default Payment;
