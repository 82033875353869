import React, { Fragment, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Grid, Typography, Card, Button, Box, CircularProgress, LinearProgress } from '@mui/material';
import TrackingStepper from 'routes/Orders/components/Shipped/components/ShippingTracking/TrackingStepper';
import MoodBadTwoToneIcon from '@mui/icons-material/MoodBadTwoTone';
import utils from 'components/utils';
import '../styles.css';

const LastTracking = props => {
	const translate = utils.useI18N();
	const lastItem = useStoreState(state => state.lastShipping.lastItem);
	const getLastItem = useStoreActions(dispatch => dispatch.lastShipping.getLastItem);
	const personify = useStoreState(state => state.me.personify);
	const userData = useStoreState(state => state.me.data);
	const userId = personify.iAmPersonifying ? personify.target.id : userData.id;

	useEffect(
		_ => {
			getLastItem();
		},
		[userId]
	);

	/*
	const lastItem = {
		shippingId: '5002455240',
		shippingType: 'SPEDIZIONE',
		depot: 'FS01',
		depotDesc: 'fast hub',
		orderNumber: '4324',
		quantityRequired: 56,
		quantityShipped: 56,
		netPrice: 162
	};
	*/
	const loading = useStoreState(state => state.lastShipping.loading);
	const setStepSearch = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const circularProgressSize = 15;
	const circularProgressMarginLeft = '5px';
	const linearProgressMargin = '55px';
	const isObjEmpty = obj => {
		return Object.values(obj).length === 0 && obj.constructor === Object;
	};

	return (
		<Box sx={{ marginTop: '60px' }}>
			<Grid container alignItems="center" sx={{ marginBottom: '5px' }}>
				<Grid item xs={7} md={10}>
					<Typography className="sectionTitle">
						{translate('home.lastOrderHead')}
						<span className="secondary"> {translate('home.lastOrderFoot')}</span>
					</Typography>
				</Grid>
				<Grid item xs={5} md={2} sx={{ textAlign: 'right' }}>
					<Button className="sectionLink" component={Link} to={'/orders/list'}>
						{translate('home.gotoorders') + '>>'}
					</Button>
				</Grid>
			</Grid>
			{isObjEmpty(lastItem) && (
				<Card sx={{ padding: '15px' }}>
					<Grid container>
						<Grid item xs={12} sx={{ textAlign: 'center' }}>
							<MoodBadTwoToneIcon sx={{ fontSize: '40px' }} />
						</Grid>
						<Grid item xs={12} sx={{ textAlign: 'center' }}>
							<Typography sx={{ fontWeight: 400, fontSize: '25px' }}>
								{translate('home.lastItemNotPresent')}
							</Typography>
						</Grid>
						<Grid item xs={12} sx={{ textAlign: 'center' }}>
							<Button component={Link} to={'/catalog/search'} onClick={_ => setStepSearch(25)}>
								{translate('home.gotocatalog')}
							</Button>
						</Grid>
					</Grid>
				</Card>
			)}
			{!isObjEmpty(lastItem) && (
				<Fragment>
					<Grid container sx={{ columns: '3 auto' }} spacing={2}>
						<Grid item md={2} xs={4}>
							<Typography>
								<strong>{translate('orders.tables.deliveryNum') + ':'} </strong>
								{loading ? (
									<CircularProgress
										size={circularProgressSize}
										sx={{ marginLeft: circularProgressMarginLeft }}
									/>
								) : (
									lastItem.shippingId
								)}
							</Typography>
						</Grid>

						<Grid item md={2} xs={4}>
							<Typography>
								<strong>{translate('orders.tables.depotName') + ':'} </strong>
								{loading ? (
									<CircularProgress
										size={circularProgressSize}
										sx={{ marginLeft: circularProgressMarginLeft }}
									/>
								) : (
									lastItem.depotDesc
								)}
							</Typography>
						</Grid>

						<Grid item md={2} xs={4}>
							<Typography>
								<strong>{translate('orders.tables.withdrawal') + ':'} </strong>
								{loading ? (
									<CircularProgress
										size={circularProgressSize}
										sx={{ marginLeft: circularProgressMarginLeft }}
									/>
								) : (
									lastItem.shippingType
								)}
							</Typography>
						</Grid>

						<Grid item md={2} xs={4}>
							<Typography>
								<strong>{translate('orders.tables.shippedCopies') + ':'} </strong>
								{loading ? (
									<CircularProgress
										size={circularProgressSize}
										sx={{ marginLeft: circularProgressMarginLeft }}
									/>
								) : (
									lastItem.quantityShipped + ' / ' + lastItem.quantityRequired
								)}
							</Typography>
						</Grid>

						<Grid item md={2} xs={4}>
							<Typography>
								<strong>{translate('orders.tables.total') + ':'} </strong>
								{loading ? (
									<CircularProgress
										size={circularProgressSize}
										sx={{ marginLeft: circularProgressMarginLeft }}
									/>
								) : (
									utils.formatPrice(lastItem.netPrice)
								)}
							</Typography>
						</Grid>
					</Grid>
					<Card sx={{ marginTop: '20px' }}>
						{loading ? (
							<Box sx={{ margin: linearProgressMargin }}>
								<LinearProgress />
							</Box>
						) : (
							<TrackingStepper
								deliveryID={lastItem.shippingId}
								linearProgressMargin={linearProgressMargin}
							/>
						)}
					</Card>
				</Fragment>
			)}
		</Box>
	);
};
export default LastTracking;
