import { action, actionOn } from 'easy-peasy';
import { v4 as uuidv4 } from 'uuid';

const createMessage = message => ({
	message,
	dismissed: false,
	timestamp: Date.now(),
	id: uuidv4()
});

const createError = message => ({ ...createMessage(`errors.${message}`), type: 'error' });
const createSuccess = message => ({ ...createMessage(`success.${message}`), type: 'success' });
const createCustomError = message => ({ ...createMessage(message), type: 'error', skipI18N: true });
const createCustomSuccess = message => ({ ...createMessage(message), type: 'success', skipI18N: true });

export default {
	stack: [],
	reset: action((state, payload) => {
		state.stack = [];
	}),
	dismiss: action((state, payload) => {
		const i = state.stack.findIndex(e => e.id === payload);
		state.stack[i].dismissed = true;
	}),
	onSuccess: actionOn(
		(actions, storeActions) => [storeActions.successCustom, storeActions.success],
		(state, target) => {
			const { payload, resolvedTargets } = target;
			const [successCustom] = resolvedTargets;
			switch (target.type) {
				case successCustom:
					state.stack.push(createCustomSuccess(payload));
					break;
				default:
					state.stack.push(createSuccess(payload));
			}
		}
	),
	onError: actionOn(
		(actions, storeActions) => [storeActions.errorCustom, storeActions.error],
		(state, target) => {
			const { payload, resolvedTargets } = target;
			const [errorCustom] = resolvedTargets;
			switch (target.type) {
				case errorCustom:
					state.stack.push(createCustomError(payload));
					break;
				default:
					state.stack.push(createError(payload));
			}
		}
	)
};
