import { Button, Grid } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import SearchBar from 'routes/Catalog/Search/components/SearchBar';
import { Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import utils from 'components/utils';

const SearchComponent = _ => {
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const token = useStoreState(state => state.catalog.search.token);
	const inputRef = useRef();

	const translate = utils.useI18N();
	const isSearchFromHome = true;

	useEffect(() => {
		const onKeyDown = event => {
			if (event.key === 'Enter') {
				if (inputRef.current) inputRef.current.click(event);
			}
		};
		document.addEventListener('keydown', onKeyDown);
	}, [token]);

	return (
		<Grid
			container
			sx={{
				justifyContent: 'flex-end',
				marginTop: { xs: '20px !important', md: '0 !important', sm: '20px !important' }
			}}
		>
			<SearchBar isSearchFromHome={isSearchFromHome} />
			<Button
				ref={inputRef}
				component={Link}
				to={'/catalog/search'}
				onClick={_ => setStep(25)}
				sx={{ position: 'relative', top: '-45px', right: '15px' }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
			>
				{translate('common.search.search')}
			</Button>
		</Grid>
	);
};
export default SearchComponent;
