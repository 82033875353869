import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const EditorComp = ({ text }) => {
	return <StyledTableCell>{text}</StyledTableCell>;
};

const Editor = memo(
	({ item }) => <EditorComp text={item.editorDesc} />,
	(p, n) => p.item.editorDesc === n.item.editorDesc
);

export default Editor;
