import k from 'k';
import utils from 'services/utils';

export default ({ type, rows, dateTo, dateFrom, page }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	let params = {};
	let endpoint;
	if (type === 'RIBA') {
		params = {
			dateTo,
			type: type,
			dateFrom
		};
		const qParams = utils.createQueryParams(params);
		endpoint = `${k.endpoints.apis}/document/riba?${qParams}`;
	} else {
		params = {
			rows: rows,
			dateTo,
			type: type,
			dateFrom,
			page
		};
		const qParams = utils.createQueryParams(params);
		endpoint = `${k.endpoints.apis}/document/?${qParams}`;
	}

	return utils.fetch(endpoint, requestOptions).then(response => utils.handleResponse({ response }));
};
