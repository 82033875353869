import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import NoResults from 'components/NoResults';
import TableHead from './TableHead';
import TableBody from './TableBody';
import utils from 'components/utils';
import TableToolbar from './TableToolbar';

const ItemsTable = _ => {
	const params = useParams();
	const data = useStoreState(state => state.promotions.detailsItems.data);
	const loading = useStoreState(state => state.promotions.detailsItems.loading);
	const load = useStoreActions(dispatch => dispatch.promotions.detailsItems.load);
	const translate = utils.useI18N();
	useEffect(
		_ => {
			load(params.id);
		},
		[load, params]
	);
	return (
		<Fragment>
			{loading && <CircularProgress size={40} />}
			{!loading && data.length === 0 && <NoResults text={translate('coupons.noArticles')} />}
			{!loading && data.length > 0 && (
				<Fragment>
					<TableToolbar />
					<TableContainer>
						<Table
							stickyHeader
							aria-labelledby="items"
							size="small"
							padding="none"
							aria-label="result table"
						>
							<TableHead />
							<TableBody rows={data} />
						</Table>
					</TableContainer>
				</Fragment>
			)}
		</Fragment>
	);
};

export default ItemsTable;
