import React, { Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
// import promotions from './promotionsProva.json';
import { CardActionArea, CardMedia, Grid, Typography, Card, IconButton, Tooltip, Button } from '@mui/material';
import utils from 'components/utils';
import '../styles.css';
import GetAppIcon from '@mui/icons-material/GetApp';

const PromotionComponent = props => {
	const loading = useStoreState(state => state.cms.promotions.loading);
	const promotions = useStoreState(state => state.cms.promotions.data);
	const loadPromo = useStoreActions(dispatch => dispatch.cms.promotions.load);
	const targetUser = useStoreState(state => state.me.personify.target);
	const translate = utils.useI18N();

	useEffect(
		_ => {
			loadPromo();
		},
		[targetUser]
	);

	return (
		<Fragment>
			{!loading && promotions.length > 0 && (
				<Grid container sx={{ marginTop: '20px' }}>
					<Grid item xs={12}>
						<Typography className="sectionTitle" sx={{ marginBottom: '10px' }}>
							{translate('common.menu.promotions')}
						</Typography>
						<Grid container spacing={5} justifyContent={'center'}>
							{promotions.map((promo, index) => {
								const openGoogleDocOrder =
									promo.popupText != null
										? promo.popupText
												.split('href="')
												.pop()
												.split('" target=')[0]
										: '';
								const handleOpenGoogleDocOrder = _ => {
									window.open(openGoogleDocOrder, '_blank');
								};
								return (
									<Grid
										item
										xs={12}
										md={2}
										sm={6}
										key={index}
										className="imagePromoSize"
										sx={{
											marginBottom: '55px',
											padding: '0 10px'
										}}
									>
										<Card
											className="cardSize"
											sx={{ boxShadow: 'none !important', backgroundColor: '#f5f5f5 !important' }}
										>
											<CardActionArea
												onClick={openGoogleDocOrder ? handleOpenGoogleDocOrder : undefined}
												//disabled={!openGoogleDocOrder}
											>
												<CardMedia
													className="imagePromoSize"
													image={promo.image.url}
													title={promo.title}
													sx={{
														backgroundSize: 'contain',
														cursor: !openGoogleDocOrder ? 'default' : 'inherit'
													}}
												/>
											</CardActionArea>
										</Card>
										<Grid container alignItems="center">
											<Grid item xs={10}>
												<Typography
													sx={{ cursor: openGoogleDocOrder ? 'pointer' : 'default' }}
													className="promotionTitle"
													onClick={openGoogleDocOrder ? handleOpenGoogleDocOrder : undefined}
												>
													{promo.visibleTitle}
												</Typography>
											</Grid>
											<Grid item xs={2}>
												<Tooltip
													placement="bottom"
													title={
														promo.poster.url
															? translate('common.menu.downloadPoster')
															: translate('common.menu.downloadNotPresentPoster')
													}
												>
													<span>
														<IconButton
															onClick={_ => window.open(promo.poster.url)}
															disabled={!promo.poster.url}
														>
															<GetAppIcon sx={{ fontSize: '20px' }} />
														</IconButton>
													</span>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
};

export default PromotionComponent;
