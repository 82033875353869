import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import { green, red } from '@mui/material/colors';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const StatusComp = ({ enabled }) => {
	return (
		<StyledTableCell>
			{' '}
			{enabled ? (
				<CheckCircleOutlineRoundedIcon fontSize="small" style={{ color: green[300] }} />
			) : (
				<HighlightOffRoundedIcon fontSize="small" style={{ color: red[300] }} />
			)}
		</StyledTableCell>
	);
};
const Status = memo(
	({ item }) => <StatusComp enabled={item.enabled} />,
	(p, n) => p.item.enabled === n.item.enabled
);

export default Status;
