import React from 'react';
import Selection from './Selection';
import Sortable from './Sortable';

const allCols = [
	{
		id: 'selection',
		comp: _ => <Selection key="headCell_selection" />
	},
	{
		id: 'cod',
		comp: _ => <Sortable id="userCode" key="headCell_cod" label="purchasings.tables.cod" />
	},
	{
		id: 'rag',
		comp: _ => <Sortable id="userName" key="headCell_rag" label="purchasings.tables.rag" />
	},
	{
		id: 'city',
		comp: _ => <Sortable id="city" key="headCell_city" label="purchasings.tables.city" />
	},
	{
		id: 'address',
		comp: _ => <Sortable id="address" key="headCell_address" label="purchasings.tables.address" />
	},
	{
		id: 'province',
		comp: _ => <Sortable id="province" key="headCell_province" label="purchasings.tables.province" />
	}
];

export default allCols;
