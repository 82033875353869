import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { FormControl, ListItemText, Checkbox, InputLabel, OutlinedInput } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import utils from 'components/utils';

const SelectDepots = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const depots = useStoreState(state => state.cart.search.filters.depots);
	const toggleDepot = useStoreActions(dispatch => dispatch.cart.search.toggleDepot);
	const amcaUsersDepots = useStoreState(state => state.cart.open.uniqueCADepost);
	const selectDepotsValue = useStoreState(state => state.cart.search.selectDepotsValue);
	const setSelectDepotsValue = useStoreActions(dispatch => dispatch.cart.search.setSelectDepotsValue);
	const singleUserDepots = utils.useUserData('availableDepots', []);
	const availableDepots = iCan('OPERATE_ON_MULTIPLE_CA') ? amcaUsersDepots : singleUserDepots;

	const handleChange = event => {
		const targetValue = event.target.value;
		const value = targetValue.filter(val => typeof val !== 'string');
		if (selectDepotsValue.some(d => d == value[0].desc))
			setSelectDepotsValue(selectDepotsValue.filter(d => d !== value[0].desc));
		else setSelectDepotsValue([...selectDepotsValue, value[0].desc]);

		toggleDepot(value[0].id);
	};

	return (
		<FormControl sx={{ width: '250px' }}>
			<InputLabel
				id="filter-select-depots"
				sx={{
					fontFamily: ['"Poppins", sans-serif'].join(','),
					fontSize: '14px',
					marginTop: '-5px'
				}}
			>
				{translate('orders.filters.depot')}
			</InputLabel>
			<Select
				labelId="filter-select-depots"
				id="select-depots"
				multiple
				value={selectDepotsValue}
				onChange={handleChange}
				input={<OutlinedInput label={translate('orders.filters.depot')} />}
				renderValue={selected => selected.join(', ')}
				sx={{
					height: '40px',
					fontFamily: ['"Poppins", sans-serif'].join(','),
					fontSize: '14px',
					fontWeight: '400',
					backgroundColor: theme.palette.custom.white,
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: selectDepotsValue.length > 0 ? theme.palette.primary.main : '#cccdce'
				}}
			>
				{availableDepots.map(item => (
					<MenuItem key={item.id} value={{ id: item.id, desc: item.description }}>
						<Checkbox checked={depots.includes(item.id)} value={item.id} />
						<ListItemText
							primary={item.description}
							sx={{
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								fontWeight: '400'
							}}
						/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectDepots;
