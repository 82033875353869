import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	save: action((state, payload) => {
		state.items = payload.map(p => p.items).flat();
	}),
	reset: action(state => {
		state.items = [];
	}),
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		actions.reset();
		const { ean, depotCodes } = payload;
		const promises = depotCodes.map(depotCode => services.catalog.location({ ean, depotCode }));
		return Promise.all(promises)
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('Errore nel caricamento dei dati di ubicazione');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
