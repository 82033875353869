import React, { useEffect, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';
import take from 'lodash/take';
import keys from 'lodash/keys';
import head from 'lodash/head';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import utils from 'components/utils';

const DocumentHead = props => (
	<TableRow>
		<TableCell>Nome</TableCell>
		<TableCell>Data</TableCell>
	</TableRow>
);

const BudgetHead = props => (
	<TableRow>
		<TableCell>Nome</TableCell>
		<TableCell>CIG</TableCell>
	</TableRow>
);

const DocumentBody = ({ items }) => {
	const translate = utils.useI18N();
	return (
		<Fragment>
			{items.map((item, index) => (
				<TableRow key={`tr_${index}`}>
					<TableCell align="left">{item.isDefault ? translate('orders.defaultCart') : item.name}</TableCell>
					<TableCell align="left">{moment(item.lastUpdate).format('L')}</TableCell>
				</TableRow>
			))}
		</Fragment>
	);
};

const BudgetBody = ({ items }) => {
	return (
		<Fragment>
			{items.map((item, index) => (
				<TableRow key={`tr_${index}`}>
					<TableCell align="left">{item.name}</TableCell>
					<TableCell align="left">{item.cig}</TableCell>
				</TableRow>
			))}
		</Fragment>
	);
};

const OrdersResume = ({ loading, items, linkDestination, tabNum, head, body }) => {
	const setTabSelected = useStoreActions(dispatch => dispatch.cart.setTabSelected);
	const visibleItems = take(
		items.filter(item => item.visible),
		4
	);
	const onClick = _ => {
		setTabSelected(tabNum);
	};
	const BodyComp = body;
	return (
		<Grid container>
			{loading && (
				<Grid container item xs={12} style={{ marginTop: 8 }} justify="center">
					<CircularProgress size={24} />
				</Grid>
			)}
			{!loading && visibleItems.length === 0 && (
				<Typography variant="body1">al momento non ci sono elementi</Typography>
			)}
			{!loading && visibleItems.length > 0 && (
				<TableContainer component={Paper}>
					<Table size="small">
						<TableHead>{head}</TableHead>
						<TableBody>
							<BodyComp items={visibleItems} />
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<Grid container item xs={12} style={{ marginTop: 24 }} justify="center">
				<Button
					type="submit"
					onClick={onClick}
					variant="outlined"
					color="primary"
					component={Link}
					to={linkDestination}
				>
					vedi tutti
				</Button>
			</Grid>
		</Grid>
	);
};

export const OpenOrdersResume = props => {
	const loadingOpen = useStoreState(state => state.cart.open.loading);
	const itemsOpen = useStoreState(state => state.cart.open.items);
	const loadOpen = useStoreActions(dispatch => dispatch.cart.open.load);
	useEffect(
		_ => {
			loadOpen({});
		},
		[loadOpen]
	);
	return (
		<OrdersResume
			head={<DocumentHead />}
			body={DocumentBody}
			loading={loadingOpen}
			items={itemsOpen}
			tabNum={0}
			linkDestination="/orders/list"
		/>
	);
};

export const ShippedOrdersResume = props => {
	const loadingShipped = useStoreState(state => state.cart.shipped.loading);
	const itemsShipped = useStoreState(state => state.cart.shipped.items);
	const loadShipped = useStoreActions(dispatch => dispatch.cart.shipped.load);
	useEffect(
		_ => {
			loadShipped({});
		},
		[loadShipped]
	);
	return (
		<OrdersResume
			head={<DocumentHead />}
			body={DocumentBody}
			loading={loadingShipped}
			items={itemsShipped}
			tabNum={2}
			linkDestination="/orders/list"
		/>
	);
};

export const BudgetResume = props => {
	const loadingBudget = useStoreState(state => state.budget.list.loading);
	const itemsBudget = useStoreState(state => state.budget.list.items);
	const loadBudget = useStoreActions(dispatch => dispatch.budget.list.load);
	useEffect(
		_ => {
			loadBudget({});
		},
		[loadBudget]
	);
	const key = head(keys(itemsBudget));
	if (!itemsBudget[key]) return;
	const items = itemsBudget[key].userBudgets.map(i => ({
		...i,
		visible: true
	}));
	return (
		<OrdersResume
			head={<BudgetHead />}
			body={BudgetBody}
			loading={loadingBudget}
			items={items}
			linkDestination="/budget"
		/>
	);
};
