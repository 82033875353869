import { action, thunk } from 'easy-peasy';

export default {
	loadingUpdate: false,
	setLoadingUpdate: action((state, payload) => {
		state.loadingUpdate = payload;
	}),
	loadingDelete: false,
	setLoadingDelete: action((state, payload) => {
		state.loadingDelete = payload;
	}),
	update: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingUpdate(true);
		return services.me
			.updateAvatar(payload)
			.then(data => {
				getStoreActions().success('me.updateAvatar');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('me.updateAvatar');
			})
			.finally(_ => {
				actions.setLoadingUpdate(false);
			});
	}),
	deletee: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingDelete(true);
		return services.me
			.deleteAvatar(payload)
			.then(data => {
				getStoreActions().success('me.deleteAvatar');
				return Promise.resolve(null);
			})
			.catch(e => {
				getStoreActions().error('me.deleteAvatar');
			})
			.finally(_ => {
				actions.setLoadingDelete(false);
			});
	})
};
