const compareDepotsStock = (a, b) => {
	const aValue = max(a);
	const bValue = max(b);
	if (aValue > bValue) return 1;
	if (aValue < bValue) return -1;
	return 0;
};

const max = a => {
	let maxValue = 0;
	for (var propertyName in a) {
		if (a[propertyName].stock > maxValue) maxValue = a[propertyName].stock;
	}
	return maxValue;
};

const descendingComparator = (a, b, orderBy) => {
	//Caso quantità richiesta
	if (orderBy === 'requestQty') orderBy = 'quantityRequired';
	//Caso quantità evadibile
	if (orderBy === 'evadibleQty') orderBy = 'quantityEvadible';

	if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
		return '' + b[orderBy].localeCompare(a[orderBy]);
	}
	//case of depot.stock
	if (orderBy === 'depots') {
		return compareDepotsStock(a[orderBy], b[orderBy]);
	}
	if (b[orderBy] < a[orderBy]) return -1;
	if (b[orderBy] > a[orderBy]) return 1;
	return 0;
};

const getComparator = (order, orderBy) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export default (array, order, orderBy) => {
	const stabilized = array.map((el, index) => [el, index]);
	const comparatorFn = getComparator(order, orderBy);
	stabilized.sort((a, b) => {
		const order = comparatorFn(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilized.map(el => el[0]);
};
