import search from './search';
import details from './details';
import detailsRiba from './details-riba';
import download from './download';
import sendEmail from './send-email';
import getAvailableFormat from './get-available-format';
import checkin from './checkin';
import checkout from './checkout';
import getCheckIOStatus from './get-check-io-status';
import saveCheckIOStatus from './save-check-io-status';
import deleteCheckIOStatus from './delete-check-io-status';

export default {
	search,
	details,
	detailsRiba,
	download,
	sendEmail,
	getAvailableFormat,
	checkin,
	checkout,
	getCheckIOStatus,
	saveCheckIOStatus,
	deleteCheckIOStatus
};
