export default theme => ({
	root: {
		width: '40%'
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		fontSize: 14,
		minWidth: '80%',
		width: 'auto',
		padding: theme.spacing(1, 1)
	}
});
