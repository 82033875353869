import React, { Fragment, useEffect, useState, forwardRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory, useParams, matchPath } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Stack,
	Box,
	Card,
	Dialog,
	Slide
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WishlistResume from './components/WishlistResume';
import WishlistInfo from './components/WishlistInfo';
import WishlistSumCost from './components/WishlistSumCost';
import RequestExtraConditions from './components/RequestExtraConditions';
import DeleteButton from './components/DeleteButton';
import DeleteWishlistItems from './components/DeleteWishlistItems';
import Transform from './components/Transform';
import FavIcon from './components/FavIcon';
import ExportWishlist from './components/ExportOrder';
import Details from 'routes/Catalog/Details';
import serviceUtils from 'services/utils';
import utils from 'components/utils';
import Loader from 'components/Loader';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Wishlist = ({ classes }) => {
	const theme = useTheme();
	const loading = useStoreState(state => state.wishlist.list.loading);
	const data = useStoreState(state => state.wishlist.details.data);
	const items = useStoreState(state => state.wishlist.list.items);
	const expanded = useStoreState(state => state.wishlist.list.expanded);
	const setExpanded = useStoreActions(dispatch => dispatch.wishlist.list.setExpanded);
	const load = useStoreActions(dispatch => dispatch.wishlist.list.load);
	const setWishlistScope = useStoreActions(dispatch => dispatch.wishlist.list.setScope);
	const openModal = useStoreState(state => state.wishlist.details.openModal);
	const setOpenModal = useStoreActions(dispatch => dispatch.wishlist.details.setOpenModal);
	const setModalOpen = useStoreActions(dispatch => dispatch.wishlist.requestExtraConditions.setModalOpen);
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const [iAmPersonifying] = utils.usePersonification();
	const availableDepots = utils.useFilteredDepots();
	const { unsubscribe } = serviceUtils.abort;
	const history = useHistory();
	const params = useParams();
	const [selectedItem, setSelectedItem] = useState(null);
	const [item, setItem] = useState();
	const wishlistExpanded = useStoreState(state => state.wishlist.details.wishlistExpanded);
	const itemSelected = data[wishlistExpanded] ? data[wishlistExpanded].filter(i => i.isSelected) : null;

	useEffect(load, []);
	// prettier-ignore
	useEffect(_ => { setWishlistScope('wishlist'); }, []);

	const handleChange = index => (event, isExpanded) => {
		//Resetto tutte le selezioni degli item della wishlist
		items.forEach(value => {
			if (data[value.id])
				data[value.id].forEach(el => {
					el.isSelected = false;
				});
		});

		setExpanded(isExpanded ? index : -1);
	};
	const freezedWlStyle = status => {
		if (status === 'DRAFT_AGENT') {
			return {
				border: '1px solid #ce5656'
			};
		}
		if (status === 'APPROVED') {
			return {
				border: '1px solid #56ce97'
			};
		}
		if (status === 'REJECTED') {
			return {
				border: '1px solid #000000'
			};
		}
	};
	const showFavIcon = item => !item.isDefault && !item.isSAP;

	const expandItem = id => {
		setExpanded(-1);

		items.forEach((value, index) => {
			if (value.id.toString() === id.toString()) {
				setExpanded(index);
			}
		});
	};
	useEffect(
		_ => {
			return _ => {
				unsubscribe(['load-wishlist']);
			};
		},
		[unsubscribe]
	);

	useEffect(
		_ => {
			if (history.location.pathname.includes('/wishlist/list/extra-conditions')) {
				const match = matchPath(history.location.pathname, {
					path: '/wishlist/list/extra-conditions/:id',
					exact: true,
					strict: false
				});

				if (match.params.id && items) {
					setExpanded(-1);
					items.forEach((value, index) => {
						if (value.id.toString() === match.params.id.toString()) {
							setExpanded(index);
							setModalOpen(true);
						}
					});
				}
			}
		},
		[history, items, setModalOpen, setExpanded]
	);

	useEffect(() => {
		setOpenModal(false);
		if (items.length > 0 && history.location.pathname.includes('/wishlist/list/details')) {
			const match = matchPath(history.location.pathname, {
				path: '/wishlist/list/details/:id/:fatherId/:ean',
				exact: true,
				strict: false
			});

			if (match.params.id && match.params.fatherId && match.params.ean) {
				expandItem(match.params.fatherId);

				if (Object.keys(data).length > 0) {
					const itemsDataMapped = data[match.params.fatherId]
						? data[match.params.fatherId].map(d => ({
								fatherId: match.params.id,
								isPromoActive: d.isPromoActive,
								promotionSapId: d.promotionSapId,
								promoDesc: d.promoDesc,
								promoSingleItem: d.promotionDesc,
								id: d.id,
								quantity: d.quantity,
								depotCode: d.depotCode,
								notes: d.notes,
								selectedDepot: d.depotDescription,
								...d.catalog
						  }))
						: [];

					const itemData = itemsDataMapped.find(
						// eslint-disable-next-line
						value => value.id == match.params.id && value.ean === match.params.ean
					);

					setItem(itemData);
					setSelectedItem(match.params.ean);
					setOpenModal(true);
				}
			}
		}
		// eslint-disable-next-line
	}, [history, params, items, data]);
	useEffect(_ => {
		window.onpopstate = () => {
			setOpenModal(false);
			//history.goBack();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const style = {
		width: 'calc(100% - 57px)',
		height: 'calc(100% - 64px)',
		top: '64px !important',
		left: '57px !important',
		display: 'inline !important'
	};
	const onClose = () => {
		if (history.location.pathname.includes('/wishlist/list')) {
			setOpenModal(false);

			history.push('/wishlist/list');
		}
	};

	return (
		<Fragment>
			{!loading && (
				<Fragment>
					{items.length === 0 && (
						<Grid container item xs={12} justify="center">
							<Typography variant="body1"> {translate('wishlist.noResults')} </Typography>
						</Grid>
					)}
					{items.map((item, index) => (
						<Card
							key={`wishlist_${index}`}
							id={'simple-tabpanel-0'}
							style={{ marginBottom: 20, width: '100%' }}
						>
							<Accordion
								expanded={expanded === index}
								onChange={handleChange(index)}
								style={freezedWlStyle(item.status)}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									id="panel-header"
									sx={{ flexDirection: 'row-reverse' }}
								>
									<Stack
										direction={{ xs: 'row', nd: 'row', sm: 'row' }}
										justifyContent="flex-start"
										alignItems="center"
										spacing={1}
										sx={{ width: '100%' }}
									>
										<Box sx={{ width: '50%' }}>
											<WishlistInfo item={item} />
										</Box>
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={0}
											sx={{ width: '50%' }}
										>
											<Box sx={{ width: { xs: '100%', md: '80%', sm: '80%' } }}>
												<WishlistSumCost item={item} />
											</Box>
											<Box
												sx={{
													textAlign: 'center',
													display: {
														xs: 'none',
														md:
															showFavIcon(item) &&
															item.status === 'INITIAL' &&
															item.type === null
																? 'inherit'
																: 'none',
														sm:
															showFavIcon(item) &&
															item.status === 'INITIAL' &&
															item.type === null
																? 'inherit'
																: 'none'
													}
												}}
											>
												{showFavIcon(item) &&
													item.status === 'INITIAL' &&
													item.type === null && <FavIcon item={item} />}
											</Box>
										</Stack>
									</Stack>
								</AccordionSummary>
								<AccordionDetails>
									<Grid container spacing={0}>
										{expanded === index && (
											<Fragment>
												<Grid container>
													<Grid item container xs={11}>
														{item.totalItems > 0 && (
															<Typography
																variant="text"
																sx={{ color: theme.palette.custom.orange }}
															>
																{translate('wishlist.info1')}
															</Typography>
														)}
													</Grid>
													<Grid item container justify="flex-end" xs={1}>
														<ExportWishlist id={item.id} disabled={item.totalItems === 0} />
													</Grid>
												</Grid>
												{item.note && (
													<Grid item xs={12} style={{ marginBottom: 16 }}>
														<Typography
															variant="text"
															sx={{
																color: theme.palette.primary.main,
																marginBottom: '10px'
															}}
														>
															{item.note}
														</Typography>
													</Grid>
												)}
												<Grid item xs={12} style={{ marginBottom: 16 }}>
													<WishlistResume
														id={item.id}
														status={item.status}
														type={item.type}
													/>
												</Grid>
												<Grid container item xs={4} sm={4}>
													{item.canDelete &&
														item.status !== 'DRAFT_AGENT' &&
														itemSelected &&
														itemSelected.length == 0 && (
															<DeleteButton id={item.id} name={item.name} />
														)}
													{item.canDelete &&
														item.status !== 'DRAFT_AGENT' &&
														itemSelected &&
														itemSelected.length > 0 && (
															<DeleteWishlistItems
																id={item.id}
																name={item.name}
																itemSelected={itemSelected}
															/>
														)}
												</Grid>
												<Grid container justify="center" item xs={4} sm={4}>
													{item.canRequestExtraConditions &&
														item.status === 'INITIAL' &&
														iAmPersonifying &&
														iCan('REQUEST_EXTRA_CONDITIONS', true) && (
															<RequestExtraConditions item={item} index={index} />
														)}
												</Grid>
												<Grid container justify="flex-end" item xs={4} sm={4}>
													{item.canTransform &&
														item.status !== 'DRAFT_AGENT' &&
														availableDepots.length > 0 && <Transform item={item} />}
												</Grid>
											</Fragment>
										)}
									</Grid>
								</AccordionDetails>
							</Accordion>
						</Card>
					))}
				</Fragment>
			)}
			{/* Modale dettaglio */}
			<Dialog fullScreen open={openModal} onClose={onClose} TransitionComponent={Transition}>
				<Details itemWishlist={item} id_ean={selectedItem} showClose={true} urlComponet="/wishlist/list" />
			</Dialog>
		</Fragment>
	);
};

export default Wishlist;
