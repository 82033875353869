import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

const generateCodeVerifier = length => {
	let text = '';
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
	for (let i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
};
const base64URL = string =>
	string
		.toString(Base64)
		.replace(/=/g, '')
		.replace(/\+/g, '-')
		.replace(/\//g, '_');
const generateCodeChallenge = code_verifier => base64URL(sha256(code_verifier));

export default _ => {
	const codeVerifier = generateCodeVerifier(128);
	const codeChallenge = generateCodeChallenge(codeVerifier);
	return { codeVerifier, codeChallenge };
};
