import React, { Fragment, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import {
	Typography,
	Stack,
	Box,
	IconButton,
	Grid,
	TextField,
	Divider,
	InputAdornment,
	FormControlLabel,
	Switch,
	TextareaAutosize,
	Button,
	useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import utils from 'components/utils';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const NewBudget = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const { handleClose } = props;
	const [name, setName] = useState('');
	const [cig, setCig] = useState('');
	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState(null);
	const [amount, setAmount] = useState(0);
	const [check, setCheck] = useState(false);
	const [note, setNote] = useState('');
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	const createBudget = useStoreActions(dispatch => dispatch.budget.create.sendRequest);
	const newBudget = {
		name: name,
		cig: cig,
		validityDateFrom: dateFrom,
		validityDateTo: dateTo,
		amount: amount,
		check: check,
		note: note
	};
	const [textAreaCount, settextAreaCount] = React.useState(100);
	const handleCreate = _ => {
		//Trim dei campi
		const newBudgetTrim = {
			id: newBudget.id,
			name: newBudget.name.trim(),
			cig: newBudget.cig.trim(),
			validityDateFrom: newBudget.validityDateFrom,
			validityDateTo: newBudget.validityDateTo,
			checkImmediatelly: newBudget.checkImmediatelly,
			note: newBudget.note.trim(),
			amount: newBudget.amount
		};
		createBudget(newBudgetTrim);
		handleClose();
	};
	const recalculate = e => {
		const charCount = e.target.value.length;
		const charLeft = 100 - charCount;
		settextAreaCount(charLeft);
	};
	const calculateNoteLength = note => {
		const charCount = note.length;
		return 100 - charCount;
	};

	const EndAdornment = props => (
		<IconButton sx={{ padding: theme.spacing(0.5) }} onClick={_ => props.handleReset('')}>
			<Close fontSize="small" />
		</IconButton>
	);
	return (
		<Fragment>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				sx={{ margin: theme.spacing(2) }}
			>
				{/** ------ HEADER ------ */}
				<Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
					<Typography variant="titleSection" sx={{ color: theme.palette.primary.main }}>
						{translate('common.newBudget')}
					</Typography>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Stack>
				<Box
					sx={{
						borderWidth: '1px 0 0 0',
						borderStyle: 'solid',
						borderColor: theme.palette.primary.main,
						paddingTop: '10px'
					}}
				>
					<Typography variant="text" sx={{ color: theme.palette.custom.orange, lineHeight: '1.1em' }}>
						Attenzione, inserire un CIG valido e delle date in corso di validit&agrave;
					</Typography>
				</Box>

				{/** ------ DATA ------ */}
				<Grid container spacing={2} sx={{ marginTop: theme.spacing(1) }}>
					<Grid item xs={12}>
						<TextField
							id="name"
							value={name}
							onChange={event => setName(event.target.value)}
							required
							fullWidth
							size="small"
							label="Nome"
							InputProps={{
								endAdornment: <EndAdornment handleReset={setName} />
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={cig}
							onChange={event => setCig(event.target.value)}
							id="cig"
							required
							fullWidth
							size="small"
							label="Cig"
							InputProps={{
								endAdornment: <EndAdornment handleReset={setCig} />
							}}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={4}>
						<TextField size="small" label="Prenotazioni" disabled />
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField size="small" label="Ord. consegnati" disabled />
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField size="small" label="Ord. da consegnare" disabled />
					</Grid> */}
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<Grid item xs={12} sm={6}>
							<DatePicker
								value={dateFrom}
								onChange={date => setDateFrom(date)}
								id="dateFrom"
								label="Dalla data: "
								slotProps={{
									textField: {
										size: 'small',
										fullWidth: mobile,
										required: true // cannot be just "required"
									}
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<DatePicker
								value={dateTo}
								onChange={date => setDateTo(date)}
								id="dateTo"
								label="Alla data: "
								slotProps={{
									textField: {
										size: 'small',
										fullWidth: mobile,
										required: true // cannot be just "required"
									}
								}}
							/>
						</Grid>
					</LocalizationProvider>
					<Grid item xs={12} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={amount}
							onChange={event => {
								setAmount(event.target.value);
							}}
							id="amount"
							InputProps={{
								startAdornment: <InputAdornment position="start">€</InputAdornment>,
								endAdornment: mobile && <EndAdornment handleReset={setAmount} />,
								inputProps: { min: 0 }
							}}
							type="number"
							required
							fullWidth
							size="small"
							label="Budget iniziale"
						/>
					</Grid>

					{/* <Grid item xs={12}>
						<TextField
							InputProps={{
								startAdornment: <InputAdornment position="start">€</InputAdornment>
							}}
							type="number"
							fullWidth
							size="small"
							label="Budget residuo"
							disabled
						/>
					</Grid> */}
					<Grid item xs={12}>
						<FormControlLabel
							control={<Switch checked={check} onChange={event => setCheck(event.target.checked)} />}
							label="Controllo immediato sul budget"
							sx={{ paddingLeft: 2 }}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<TextareaAutosize
							value={note}
							onChange={event => {
								setNote(event.target.value);
								recalculate(event);
							}}
							maxLength={100}
							maxRows={5}
							minRows={5}
							style={{ width: '100%', resize: 'none' }}
							placeholder="Nota"
						/>
						<Typography variant="text" sx={{ marginTop: theme.spacing(1) }}>
							Inserire al massimo {note ? calculateNoteLength(note) : textAreaCount} caratteri
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
						<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
							<Button
								variant="dark"
								disabled={
									!name ||
									!cig ||
									!dateFrom ||
									!dateTo ||
									!moment(dateFrom).isValid() ||
									!moment(dateTo).isValid() ||
									amount === 0
								}
								onClick={handleCreate}
							>
								{translate('common.save')}
							</Button>
							<Button variant="light" onClick={handleClose}>
								{translate('common.cancel')}
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</Fragment>
	);
};

export default NewBudget;
