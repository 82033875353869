import k from 'k';
import utils from 'services/utils';

export default (data, id) => {
	if (data.usersToInsert === '') return;
	const requestOptions = {
		method: 'POST',
		headers: utils.createHeaders(),
		body: JSON.stringify(data)
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/user-groups/${id}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
