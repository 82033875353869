import React from 'react';
import Empty from './Empty';
import Sortable from './Sortable';

const allCols = [
	{
		id: 'cod',
		comp: _ => <Sortable id="cod" key="headCell_cod" label="purchasings.tables.cod" />
	},
	{
		id: 'rag',
		comp: _ => <Sortable id="rag" key="headCell_rag" label="purchasings.tables.rag" />
	},
	{
		id: 'delete',
		comp: _ => <Empty id="delete" key="headCell_delete" />
	}
];

export default allCols;
