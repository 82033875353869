const getProcessing = (actions, payload, { getStoreState, injections, getStoreActions }) => {
	const { services } = injections;
	actions.setLoading(true);
	services.cart
		.getProcessing()
		.then(data => actions.save(data))
		.catch(e => {
			if (e.name !== 'AbortError') getStoreActions().error('cart.retrieve');
		})
		.finally(_ => {
			actions.setLoading(false);
		});
};

export default getProcessing;
