import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import TableBodyMUI from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import allCols from './cols';
import withColumns from '../columns';
import { withStyles } from '@mui/styles';
import { useHistory, matchPath } from 'react-router';
import { alpha } from '@mui/material';

const styles = theme => ({
	tableRow: {
		'&$selected, &$selected:hover': {
			backgroundColor: alpha(theme.palette.primary.main, 0.3)
		},
		'&:hover': {
			backgroundColor: alpha(theme.palette.primary.main, 0.1),
			transition: '0.3s',
			cursor: 'pointer'
		},
		backgroundColor: 'white'
	},
	selected: {}
});

const CustomRow = props => {
	const { children, classes, onClick, selected, className } = props;

	return (
		<TableRow classes={classes} onClick={onClick} selected={selected} className={className}>
			{children}
		</TableRow>
	);
};

const TableBody = ({ rows, visibleCols, type, classes }) => {
	const [selectedId, setSelectedId] = useState(null);
	const setDetailOpen = useStoreActions(dispatch => dispatch.returns.setDetailOpen);
	const setRequestOpen = useStoreActions(dispatch => dispatch.returns.setRequestOpen);
	const setCurrentDetail = useStoreActions(dispatch => dispatch.returns.setCurrentDetail);
	const tabSelected = useStoreState(state => state.returns.tabSelected);
	const history = useHistory();

	useEffect(
		_ => {
			if (history.location.pathname.includes('/returns/refused')) {
				if (rows) {
					const match = matchPath(history.location.pathname, {
						path: '/returns/refused/:id',
						exact: true,
						strict: false
					});

					if (match.params.id) {
						const item = rows.find(value => value.number === match.params.id);
						if (item) {
							setCurrentDetail({ id: item.number, type: 'refused' });
							setDetailOpen(true);
						}
					}
				}
			} else if (history.location.pathname.includes('/returns/accepted')) {
				if (rows) {
					const match = matchPath(history.location.pathname, {
						path: '/returns/accepted/:id',
						exact: true,
						strict: false
					});

					if (match.params.id) {
						const item = rows.find(value => value.number === match.params.id);
						if (item) {
							setCurrentDetail({ id: item.number, type: 'accepted' });
							setDetailOpen(true);
						}
					}
				}
			} else if (history.location.pathname.includes('/returns/pending')) {
				if (rows) {
					const match = matchPath(history.location.pathname, {
						path: '/returns/pending/:id',
						exact: true,
						strict: false
					});

					if (match.params.id) {
						const item = rows.find(value => value.number === match.params.id);
						if (item) {
							setCurrentDetail({ id: item.number, type: 'pending' });
							setDetailOpen(true);
						}
					}
				}
			}
		},
		// eslint-disable-line react-hooks/exhaustive-deps
		[history, rows, setCurrentDetail, setDetailOpen]
	);
	return (
		<TableBodyMUI>
			{rows.map((item, index) => (
				<CustomRow
					item={item}
					onClick={() => {
						// if (!iCan('MANAGE_RETURNS') && type === 'pending') return;
						setSelectedId(index);
						setCurrentDetail({
							id: item.number,
							type: type
						});
						setDetailOpen(true);
						setRequestOpen(false);

						if (tabSelected === 1) {
							history.push('/returns/refused/' + item.number);
						} else if (tabSelected === 2) {
							history.push('/returns/accepted/' + item.number);
						} else if (tabSelected === 0) {
							history.push('/returns/pending/' + item.number);
						}
					}}
					selected={selectedId === index}
					classes={{ selected: classes.selected }}
					className={classes.tableRow}
					key={`row_${index}`}
				>
					{allCols.map(cell => {
						return cell.comp({ item, type });
					})}
				</CustomRow>
			))}
		</TableBodyMUI>
	);
};

export default withStyles(styles)(withColumns(TableBody));
