import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import keys from 'lodash/keys';
import head from 'lodash/head';
import ProgressBar from '@ramonak/react-progress-bar';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CircularProgress from '@mui/material/CircularProgress';
import utils from 'components/utils';
import styles from './styles';

const SingleBudget = ({ item, classes, onItemClick }) => {
	const effectivePerc = 100 - Math.ceil((100 * item.residual) / item.amount);
	const perc = Math.min(effectivePerc, 100);
	const progressBarColor = perc === 100 ? '#f44336' : '#00529b';
	const progressBarLabelAlignment = perc > 15 ? 'right' : 'outside';
	const progressBarLabelColor = perc > 15 ? '#fff' : '#00529b';
	return (
		<div key={item.id} className={classes.cnt} onClick={_ => onItemClick(item)}>
			<Grid container item xs={12} justify="center" style={{ marginTop: 8 }}>
				<Typography variant="caption" align="center">
					{item.cig} {utils.formatPrice(item.residual)}
					<br />
					Rimanenti
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<ProgressBar
					bgColor={progressBarColor}
					borderRadius="20px"
					height="15px"
					labelSize="12px"
					completed={perc}
					labelColor={progressBarLabelColor}
					labelAlignment={progressBarLabelAlignment}
					className={classes.pb}
				/>
			</Grid>
		</div>
	);
};

const BudgetPreview = ({ classes }) => {
	const selectedBudget = utils.useUserData('selectedBudget', null);
	const loading = useStoreState(state => state.budget.list.loading);
	const items = useStoreState(state => state.budget.list.items);
	const load = useStoreActions(dispatch => dispatch.budget.list.load);
	const updateSelectedBudget = useStoreActions(dispatch => dispatch.me.updateSelectedBudget.performUpdate);
	const [selected, setSelected] = useState(null);
	const [allItems, setAllItems] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperOpen, setPopperOpen] = useState(false);
	const [progressBarPerc, setProgressBarPerc] = useState(0);
	const [progressBarColor, setProgressBarColor] = useState('#ffffff');
	const [progressBarLabelAlignment, setProgressBarLabelAlignment] = useState('right');
	const [progressBarLabelColor, setProgressBarLabelColor] = useState('#fff');
	const togglePopper = _ => {
		setPopperOpen(!popperOpen);
	};
	const handleClickPopper = event => {
		setAnchorEl(event.currentTarget);
		togglePopper();
	};
	useEffect(
		_ => {
			const onResize = _ => {
				setPopperOpen(false);
				setAnchorEl(null);
			};
			window.addEventListener('resize', onResize);
			return _ => {
				window.removeEventListener('resize', onResize);
			};
		},
		[setPopperOpen, setAnchorEl]
	);
	// prettier-ignore
	useEffect(_ => { load({}); }, [load]);
	useEffect(
		_ => {
			const key = head(keys(items));
			if (!items[key]) return;
			const budgetList = items[key].userBudgets;
			const firstBudget = head(budgetList);
			const foundBudget = budgetList.find(b => b.id === parseInt(selectedBudget, 10));
			const b = foundBudget ? foundBudget : firstBudget;
			setSelected(b);
			setAllItems(budgetList);
			if (b) {
				const bPerc = Math.min(100 - Math.ceil((100 * b.residual) / b.amount), 100);
				setProgressBarPerc(bPerc);
				setProgressBarColor(bPerc === 100 ? '#f44336' : '#ffffff');
				setProgressBarLabelAlignment(bPerc > 10 ? 'right' : 'outside');
				setProgressBarLabelColor(bPerc > 10 ? '#00529b' : '#fff');
			}
		},
		[items, selectedBudget]
	);
	const onItemClick = item => {
		setSelected(item);
		updateSelectedBudget(item);
		togglePopper();
	};
	const handleClickAway = _ => setPopperOpen(false);
	return (
		<Grid container className={classes.text}>
			{loading && (
				<Grid container item xs={12} justify="center">
					<CircularProgress color="secondary" size={24} />
				</Grid>
			)}
			{!loading && !selected && (
				<Grid container item xs={12} justify="center">
					<Typography variant="caption" align="center" style={{ alignSelf: 'center' }}>
						Non ci sono budget
					</Typography>
				</Grid>
			)}
			{!loading && selected && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<div className={classes.cnt} onClick={handleClickPopper}>
						<Grid container item xs={12} justify="center">
							<Typography variant="caption" align="center">
								{selected.cig}
								<br /> {utils.formatPrice(selected.residual)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<ProgressBar
								bgColor={progressBarColor}
								borderRadius="20px"
								height="15px"
								labelSize="12px"
								completed={progressBarPerc}
								labelColor={progressBarLabelColor}
								labelAlignment={progressBarLabelAlignment}
							/>
						</Grid>
						<Popper
							style={{ zIndex: 1100 }}
							open={popperOpen}
							anchorEl={anchorEl}
							placement="bottom"
							transition
						>
							<Grid container className={classes.itemsContainer}>
								{allItems.map(item => (
									<SingleBudget
										key={`single_budget_${item.creationDate}`}
										item={item}
										classes={classes}
										onItemClick={onItemClick}
									/>
								))}
								<Grid container item xs={12} justify="center" style={{ marginTop: 26 }}>
									<Typography variant="caption" align="center">
										<Link to="/budget">Vai alla pagina dei budget</Link>
									</Typography>
								</Grid>
							</Grid>
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(BudgetPreview);
