import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Grid, CircularProgress, Button, Stack, TextField, Box, Typography, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';

const WishlistCreate = ({ onClose, onNoteChange, defaultNotesValue, defaultNameValue, onNameChange, resetOnSave }) => {
	const theme = useTheme();
	const create = useStoreActions(dispatch => dispatch.wishlist.create.sendRequest);
	const loading = useStoreState(state => state.wishlist.create.loading);
	const translate = utils.useI18N();
	const onClick = _ => {
		create({ name: defaultNameValue, note: defaultNotesValue });
		resetOnSave && resetOnSave();
	};
	const isDisable = defaultNameValue.trim() === '';
	const [textAreaCount, settextAreaCount] = React.useState(100);
	const recalculate = e => {
		const charCount = e.target.value.length;
		const charLeft = 100 - charCount;
		settextAreaCount(charLeft);
	};
	return (
		<Stack
			direction={'column'}
			spacing={2}
			sx={{
				width: '100%',
				maxWidth: { xs: '340px', md: '540px', sm: '540px' },
				minWidth: '340px',
				padding: '10px'
			}}
		>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={2}
				sx={{ width: '100%', borderBottom: `1px solid ${theme.palette.primary.main}` }}
			>
				<Box sx={{ width: '90%' }}>
					<Typography variant="titleSection" sx={{ color: theme.palette.primary.main }}>
						{translate('wishlist.createWl')}
					</Typography>
				</Box>
				<Box sx={{ width: '10%' }}>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</Stack>
			<Grid container item xs={12}>
				<TextField
					value={defaultNameValue}
					onChange={onNameChange}
					fullWidth={true}
					label={translate('wishlist.name')}
					size="small"
					inputProps={{ maxLength: 80 }}
				/>
			</Grid>
			<Grid container item xs={12}>
				<TextField
					rows={5}
					maxRows={5}
					multiline
					maxLength={100}
					aria-label="warning message"
					placeholder="note"
					defaultValue={defaultNotesValue}
					sx={{ width: '100%', resize: 'none' }}
					onChange={e => {
						onNoteChange(e);
						recalculate(e);
					}}
					inputProps={{ maxLength: 100 }}
				/>
				<p>Inserire al massimo {textAreaCount} caratteri</p>
			</Grid>
			<Grid container item xs={12}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%', marginTop: '10px !important' }}
				>
					<Button variant="dark" disabled={isDisable} onClick={onClick}>
						{loading ? (
							<CircularProgress size={24} sx={{ color: theme.palette.custom.white }} />
						) : (
							translate('common.save')
						)}
					</Button>
					<Button variant="light" onClick={onClose}>
						{translate('common.cancel')}
					</Button>
				</Stack>
			</Grid>
		</Stack>
	);
};

export default WishlistCreate;
