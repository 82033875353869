import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams, useHistory } from 'react-router-dom';
import clone from 'lodash/clone';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from 'components7/Card';
import utils from 'components/utils';
import * as UI from './components/ui';
import { OrderDepotExchange, ReturnDepotExchange } from './components/DepotExchange';
import styles from './styles';
import { Stack } from '@mui/material';

const UsersDetails = ({ classes }) => {
	const translate = utils.useI18N();
	const params = useParams();
	const history = useHistory();
	const load = useStoreActions(dispatch => dispatch.users.details.load);
	const update = useStoreActions(dispatch => dispatch.users.update.sendRequest);
	const loadDepots = useStoreActions(dispatch => dispatch.depots.load);
	const loading = useStoreState(state => state.users.details.loading);
	const loadingUpdate = useStoreState(state => state.users.update.loading);
	const loadingDepots = useStoreState(state => state.depots.loading);
	const permissions = useStoreState(state => state.users.details.permissions);
	const loadPermissions = useStoreActions(dispatch => dispatch.users.details.loadPermissions);
	const data = useStoreState(state => state.users.details.data);
	const [user, setUser] = useState(clone(data));
	// prettier-ignore
	useEffect(_ => { 
		load(params.id); 
		//Carico i permessi dell'utente
		loadPermissions(params.id);
	}, [params.id]);
	// prettier-ignore
	useEffect(_ => { setUser(clone(data)); }, [data]);
	// prettier-ignore
	useEffect(_ => loadDepots('ALL'), [loadDepots]);
	//Aggiorno il permesso Epipoli
	useEffect(() => {
		if (permissions && user) {
			setUser({ ...user, epipoli: permissions.MANAGE_EPIPOLI || false });
		}
	}, [permissions]);

	const onChange = event => {
		const { name, value } = event.target;
		setUser({
			...user,
			[name]: value
		});
	};
	const submit = _ => {
		update({ user, history });
	};

	return (
		<Fragment>
			<Grid container>
				{loading && <CircularProgress size={24} />}
				{!loading && data && (
					<Stack direction={'column'} spacing={4} width={'100%'}>
						<Card title="Informazioni di sistema">
							<Grid container spacing={2}>
								<Grid item xs={12} md={2}>
									<UI.EnableStatus user={user} disabled={true} />
								</Grid>
								<Grid item xs={12} md={3}>
									<UI.Id disabled={true} user={user} />
								</Grid>
								<Grid item xs={12} md={3}>
									<UI.Referral disabled={true} user={user} />
								</Grid>
								<Grid item xs={12} md={1}>
									<UI.UserType disabled={true} user={user} />
								</Grid>
								<Grid item xs={12} md={1}>
									<UI.Category disabled={true} user={user} />
								</Grid>
								<Grid item xs={12} md={2}>
									<UI.LastAccessDate disabled={true} user={user} />
								</Grid>
							</Grid>
						</Card>
						<Card title="Informazioni anagrafiche">
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<UI.CompanyName disabled={true} user={user} />
								</Grid>
								<Grid item xs={12} md={6}>
									<UI.VatID disabled={true} user={user} />
								</Grid>
								<Grid item xs={12} md={4}>
									<UI.InvoiceCode disabled={true} user={user} />
								</Grid>
								<Grid item xs={12} md={4}>
									<UI.BankCredit disabled={true} user={user} />
								</Grid>
								<Grid item xs={12} md={4}>
									<UI.TransferType disabled={true} user={user} />
								</Grid>
								<Grid item xs={6} lg={4}>
									<UI.Address disabled={true} user={user} />
								</Grid>
								<Grid item xs={6} lg={3}>
									<UI.City disabled={true} user={user} />
								</Grid>
								<Grid item xs={6} lg={3}>
									<UI.Country disabled={true} user={user} />
								</Grid>
								<Grid item xs={3} lg={1}>
									<UI.PostalCode disabled={true} user={user} />
								</Grid>
								<Grid item xs={3} lg={1}>
									<UI.County disabled={true} user={user} />
								</Grid>
							</Grid>
						</Card>
						<Card title="Informazioni di contatto">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<UI.CommercialEmail user={user} onChange={onChange} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<UI.AdministrativeEmail disabled={true} user={user} />
								</Grid>
								<Grid item xs={4} lg={4}>
									<UI.Phone user={user} onChange={onChange} />
								</Grid>
								<Grid item xs={4} lg={4}>
									<UI.Fax user={user} onChange={onChange} />
								</Grid>
								<Grid item xs={4} lg={4}>
									<UI.Mobile user={user} onChange={onChange} />
								</Grid>
							</Grid>
						</Card>
						<Card title="Preferenze">
							<Grid container spacing={2}>
								<Grid item xs={12} md={4}>
									<UI.ShowDiscount user={user} onChange={onChange} />
								</Grid>
								{/** Rimosso OneClick per tutti 
								<Grid item xs={12} md={4}>
									<UI.OneClick user={user} onChange={onChange} />
								</Grid>
								*/}
								<Grid item xs={12} md={4}>
									<UI.Epipoli user={user} onChange={onChange} />
								</Grid>
							</Grid>
						</Card>
						<Card title="Depositi">
							{loadingDepots && <CircularProgress size={16} />}
							{!loadingDepots && (
								<Grid container spacing={2}>
									<Grid item xs={12}>
										{data.mainDepot && (
											<Typography variant="body2">
												<strong>{translate('user.mainDepot.description')}: </strong>
												{`(${data.mainDepot.id}) - ${data.mainDepot.description}`}
											</Typography>
										)}
									</Grid>
									<Grid item xs={12} md={6}>
										<OrderDepotExchange />
									</Grid>
									<Grid item xs={12} md={6}>
										<ReturnDepotExchange />
									</Grid>
								</Grid>
							)}
						</Card>
						<Grid item xs={12} className={classes.marginTop16}>
							{loadingUpdate && <CircularProgress size={16} />}
							{!loadingUpdate && (
								<Button type="submit" onClick={submit} variant="contained" color="primary">
									{translate('common.save')}
								</Button>
							)}
						</Grid>
					</Stack>
				)}
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(UsersDetails);
