import React from 'react';
import NumInput from 'react-numeric-input';
import { withStyles } from '@mui/styles';
import styles from './styles';

const NumericStepper = ({
	value,
	min,
	max,
	step,
	onChange,
	required,
	strict,
	precision,
	format,
	size,
	disabled,
	removeDot
}) => {
	return (
		<NumInput
			size={size}
			format={removeDot ? num => num.replace('.', '') : format}
			precision={precision}
			onChange={onChange}
			value={value}
			required={required}
			min={min}
			max={max}
			step={step}
			strict={strict}
			disabled={disabled}
			mobile={false}
			style={{
				wrap: {
					background: '#E2E2E2',
					padding: '2px 2.26ex 2px 2px',
					borderRadius: '6px 3px 3px 6px',
					fontSize: 15,
					minWidth: '75px',
					width: '100%'
				},
				input: {
					borderRadius: '4px 2px 2px 4px',
					padding: '0.1ex 1ex',
					border: '1px solid #ccc',
					marginRight: 4,
					display: 'block',
					fontWeight: 100,
					width: '100%'
				},
				'input:focus': {
					outline: 'none'
				}
			}}
		/>
	);
};
export default withStyles(styles, { withTheme: true })(NumericStepper);
