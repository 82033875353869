import { action, thunk, thunkOn } from 'easy-peasy';
import utils from './utils';

export default {
	loading: false,
	results: [],
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	performSearchOnFiltersReset: thunkOn(
		(actions, storeActions) => [storeActions.promotions.filters.reset],
		async (actions, target, helpers) => {
			utils.search(actions, null, helpers);
		}
	),
	performSearch: thunk(utils.search)
};
