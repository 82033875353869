import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import utils from 'components/utils';

const Type = props => {
	const { details } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	let type = '';
	if (details && !isNaN(details.type)) {
		switch (details.type) {
			case 0:
				type = translate('catalog.details.typology.halfPrice');
				break;
			case 1:
				type = translate('catalog.details.typology.fullPrice');
				break;
			case 2:
				type = translate('catalog.details.typology.languageBooks');
				break;
			case 3:
			case 50:
				type = 'Print on demand';
				break;
			case 4:
				type = translate('catalog.details.typology.schoolBook');
				break;
			case 5:
				type = 'Dvd';
				break;
			case 6:
				type = 'Blu-Ray';
				break;
			case 7:
				type = 'Non book';
				break;
			case 8:
				type = translate('catalog.details.typology.academic');
				break;
			case 100:
				type = translate('catalog.details.typology.unavailable');
				break;
			case 200:
				type = translate('catalog.details.typology.epipoli');
				break;
			default:
				type = '';
		}
	}
	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
			<Typography variant="tetx" sx={{ fontWeight: '600' }}>
				{translate('catalog.details.type') + ':'}
			</Typography>
			{details.isPromo && (
				<Typography variant="text" sx={{ color: theme.palette.secondary.main }}>
					{translate('catalog.details.infoBox.promo')}
				</Typography>
			)}
			{!details.isPromo && <Typography variant="text">{utils.capitalize(type)}</Typography>}
		</Stack>
	);
};

export default Type;
