import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, CircularProgress, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import utils from 'components/utils';

const SelectDelivery = props => {
	const { depot } = props;
	const translate = utils.useI18N();
	const theme = useTheme();
	const getDeliveryList = useStoreActions(dispatch => dispatch.cart.evasion.getDelivery);
	const selectDelivery = useStoreActions(dispatch => dispatch.cart.evasion.selectDelivery);
	const selectedDelivery = depot.deliveries.length > 0 ? depot.deliveries.find(d => d.selected) : null;
	const selectedDeliveryId = selectedDelivery ? selectedDelivery.id : '';

	useEffect(
		_ => {
			if (getDeliveryList && depot.id) {
				getDeliveryList(depot.id);
			}
		},
		[depot.id]
	);

	return (
		<Stack direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={0} sx={{ width: '100%' }}>
			{depot.loadingDeliveries && <CircularProgress size={24} />}
			{!depot.loadingDeliveries && depot.deliveries.length === 0 && (
				<Typography variant="text">{translate('orders.evasion.warningMessage')}</Typography>
			)}
			{!depot.loadingDeliveries && depot.deliveries.length > 0 && (
				<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
					<FormControl variant="standard" sx={{ width: '145px' }}>
						<Select
							value={selectedDeliveryId}
							onChange={event => selectDelivery({ depotId: depot.id, deliveryId: event.target.value })}
						>
							{depot.deliveries.map((item, index) => (
								<MenuItem key={item.id} value={item.id}>
									{item.shippingRule.desc}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{selectedDelivery.warningEnabled && selectedDelivery.warning && (
						<Typography variant="text" sx={{ color: theme.palette.custom.orange }}>
							{selectedDelivery.warning}
						</Typography>
					)}
				</Stack>
			)}
		</Stack>
	);
};

export default SelectDelivery;
