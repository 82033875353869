import React, { useEffect, useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import keys from 'lodash/keys';
import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableSortLabel,
	Collapse,
	Typography,
	Stack,
	Paper,
	IconButton,
	Button,
	Tooltip,
	Popover
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ProcessingOrdersItemsDownload from './ProcessingOrdersItemsDownload';
import ShippingTracking from '../ShippingTracking';
import utils from 'components/utils';

const TableBodyList = props => {
	const { rows } = props;
	const theme = useTheme();
	const orderDirection = useStoreState(state => state.cart.processing.orderDirection);
	const orderBy = useStoreState(state => state.cart.processing.orderBy);
	const filteredRows = rows.filter(item => item.visible);
	const sortedRows = utils.stableSort(filteredRows, orderDirection, orderBy);

	const cells = [
		{ id: 'ean', field: 'catalog' },
		{ id: 'author', field: 'author' },
		{ id: 'epipoli', field: 'tyoe' },
		{ id: 'title', field: 'title' },
		{ id: 'publisher', field: 'publisher' },
		{ id: 'price', field: 'netPrice' },
		{ id: 'quantityRequired', field: 'quantityRequired' },
		{ id: 'note', field: 'note' }
	];

	return (
		<Fragment>
			{sortedRows.map((row, index) => (
				<TableRow key={index}>
					{cells.map((cell, idx) => (
						<TableCell sx={{ padding: '10px 5px' }} key={idx}>
							{cell.id == 'epipoli' && row[cell.field] === 200 ? (
								<CardGiftcardIcon sx={{ color: theme.palette.primary.main, fontSize: '22px' }} />
							) : cell.id == 'epipoli' && row[cell.field] !== 200 ? (
								''
							) : cell.id == 'price' ? (
								utils.formatPrice(parseFloat(row[cell.field]))
							) : (
								row[cell.field]
							)}
						</TableCell>
					))}
				</TableRow>
			))}
		</Fragment>
	);
};

const ProcessingOrderShippings = props => {
	const { order } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const orderDirection = useStoreState(state => state.cart.processing.orderDirection);
	const orderBy = useStoreState(state => state.cart.processing.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.cart.processing.setOrder);

	const {
		resumeOrder: { shippingResumeOrder }
	} = order;
	const ks = shippingResumeOrder ? keys(shippingResumeOrder) : [];

	const filterItems = (id, key) => {
		if (key === 'NO_SHIPPING_CODE') return id === null;
		else return id === key;
	};

	const composedShippingItems = ks.map(key => ({
		...shippingResumeOrder[key],
		orders: order.orderItems.filter(i => filterItems(i.id, key))
	}));

	const uniqueItems = order.orderItems.filter(
		(item, index, arr) => arr.findIndex(elm => elm.depotDesc === item.depotDesc) === index
	);
	const orderDepotsShown = uniqueItems.map(item => item.depotDesc).join(' - ');
	const resumeTypeDescShown = uniqueItems.map(item => item.typeDesc).join(' - ');
	const someUpdatingStatus = uniqueItems.some(item => item.status === '1');

	const [open, setOpen] = useState(-1);
	const [deliveryID, setDeliveryID] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const openTracking = Boolean(anchorEl);

	const headCells = [
		'orders.tables.deliveryNum',
		'orders.filters.status',
		'admin.withdrawTypes.depotName',
		'orders.tables.processingCopies',
		'orders.tables.withdrawTypes'
	];

	const headSortable = [
		{ id: 'author', label: 'catalog.tableHeaders.author' },
		{ id: 'epipoli', label: '' },
		{ id: 'title', label: 'catalog.tableHeaders.title' },
		{ id: 'publisher', label: 'catalog.tableHeaders.publisher' },
		{ id: 'price', label: 'catalog.tableHeaders.price' },
		{ id: 'quantityRequired', label: 'catalog.tableHeaders.qta' },
		{ id: 'note', label: 'catalog.tableHeaders.note' }
	];

	const handleOpen = index => {
		setOpen(index != open ? index : -1);
	};

	const handleOpenTracking = (event, shippingNumber) => {
		setDeliveryID(shippingNumber);
		setAnchorEl(event.currentTarget);
	};

	const handleCloseTracking = () => {
		setAnchorEl(null);
	};

	//console.clear();
	//console.group('ordine');
	//console.log('composedShippingItems', composedShippingItems);
	//console.log('uniqueItems', uniqueItems);
	//console.log('orderDepotsShown', orderDepotsShown);
	//console.log('resumeTypeDescShown', resumeTypeDescShown);
	//console.log('someUpdatingStatus', someUpdatingStatus);
	//console.group('ordine');

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
				<Typography variant="titleSection">{translate('orders.listShipping')}</Typography>
				<ProcessingOrdersItemsDownload item={order} />
			</Stack>
			<TableContainer sx={{ width: '100%', margin: '10px 0 !important' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{ padding: '10px 5px' }} />
							{headCells.map((cell, index) => (
								<TableCell sx={{ padding: '10px 5px' }} key={index}>
									{translate(cell)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{composedShippingItems.map((item, idx) => (
							<Fragment key={idx}>
								<TableRow key={idx}>
									<TableCell sx={{ padding: '10px 5px' }}>
										<IconButton size="small" onClick={() => handleOpen(idx)}>
											{open == idx ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									</TableCell>
									<TableCell sx={{ padding: '10px 5px' }}>
										{item.shippingNumber && (
											<Typography title={translate('orders.tables.shippingTracking')}>
												<Button
													variant="textAction"
													endIcon={<LocalShippingIcon />}
													onClick={event => handleOpenTracking(event, item.shippingNumber)}
													sx={{ color: theme.palette.primary.main, padding: '0 5px 0 0' }}
												>
													{'#' + item.shippingNumber}
												</Button>
											</Typography>
										)}
										{!item.shippingNumber && translate('orders.tables.deliveryNumUpdating')}
									</TableCell>
									<TableCell sx={{ padding: '10px 5px' }}>
										{item.shippingStatusDescription.toLowerCase()}
									</TableCell>
									<TableCell sx={{ padding: '10px 5px' }}>
										{item.shippingStatusCode === '1' && !someUpdatingStatus
											? orderDepotsShown
											: item.depotDescription}
									</TableCell>
									<TableCell sx={{ padding: '10px 5px' }}>
										{item.sumOfProcessableCatalog +
											' / ' +
											utils.formatPrice(item.sumCostProcessable)}
									</TableCell>
									<TableCell sx={{ padding: '10px 5px' }}>
										{item.shippingStatusCode === '1' && !someUpdatingStatus
											? resumeTypeDescShown
											: item.shippingTypeDescription}
									</TableCell>
								</TableRow>

								{/* Elenco ean */}
								<TableRow>
									<TableCell
										colSpan={6}
										sx={{ borderBottom: open == idx ? '1px solid #c7c7c7' : 'none' }}
									>
										<Collapse in={open == idx} timeout="auto" unmountOnExit>
											<Table size="small" aria-label="ean list">
												<TableHead>
													<TableRow>
														<TableCell
															sx={{
																padding: '10px 5px',
																backgroundColor: theme.palette.custom.greytable,
																fontWeight: '600'
															}}
														>
															{translate('catalog.tableHeaders.ean')}
														</TableCell>
														{headSortable.map((cell, index) => (
															<TableCell
																key={index}
																sx={{
																	padding: '10px 5px',
																	backgroundColor: theme.palette.custom.greytable,
																	fontWeight: '600'
																}}
															>
																<TableSortLabel
																	active={orderBy === cell.id}
																	direction={
																		orderBy === cell.id ? orderDirection : 'asc'
																	}
																	onClick={e => setOrder(cell.id)}
																	sx={{
																		backgroundColor: theme.palette.custom.greytable,
																		fontWeight: '600'
																	}}
																>
																	{translate(cell.label)}
																</TableSortLabel>
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													<TableBodyList rows={item.orders} />
												</TableBody>
											</Table>
										</Collapse>
									</TableCell>
								</TableRow>
							</Fragment>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Popover tracking */}
			<Popover
				open={openTracking}
				anchorEl={anchorEl}
				onClose={handleCloseTracking}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
			>
				<ShippingTracking deliveryID={deliveryID} onClose={handleCloseTracking} />
			</Popover>
		</Stack>
	);
};

export default ProcessingOrderShippings;
