import k from 'k';
import utils from 'services/utils';

export default date => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	requestOptions.abortCtrl = utils.abort.subscribe('load-shipped-carts');
	return utils
		.fetch(`${k.endpoints.apis}/cart/order/close?date=${date}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
