import { action, thunk } from 'easy-peasy';
import filtersInitialState from './filters-initial-state';

export default {
	list: {
		...filtersInitialState
	},
	open: false,
	setOpen: action((state, payload) => {
		state.open = payload;
	}),
	setUserId: action((state, payload) => {
		state.list.userId = payload;
	}),
	setIntoCart: action((state, payload) => {
		state.list.intoCart = payload;
	}),
	inCartChecked: true,
	setInCartChecked: action((state, payload) => {
		state.inCartChecked = payload;
	}),
	notInCartChecked: true,
	setNotInCartChecked: action((state, payload) => {
		state.notInCartChecked = payload;
	}),
	loadingPromo: false,
	setLoadingPromo: action((state, payload) => {
		state.loadingPromo = payload;
	}),
	promotions: [],
	setPromotions: action((state, payload) => {
		const selectNew = [{ sapPromoId: '_empty_', name: 'seleziona una promo', isSelected: false }];
		const decoratedPromo = [...selectNew, ...payload];
		state.promotions = decoratedPromo.map((promo, index) => ({
			...promo,
			isSelected: state.list.promoId !== '_empty_' ? promo.sapPromoId === state.list.promoId : index === 0
		}));
		state.list.promoId = state.promotions.find(item => item.isSelected).sapPromoId;
	}),
	loadPromotions: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingPromo(true);
		return services.promotions
			.getWishlist()
			.then(data => {
				actions.setPromotions(data);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('promotions.search');
			})
			.finally(_ => {
				actions.setLoadingPromo(false);
			});
	}),
	selectPromotion: action((state, payload) => {
		state.promotions = state.promotions.map(promo => ({
			...promo,
			isSelected: promo.sapPromoId === payload
		}));
		state.list.promoId = state.promotions.find(item => item.isSelected).sapPromoId;
	}),
	performSearch: action((state, payload) => {
		//
	}),
	reset: action((state, payload) => {
		state.list = {
			...filtersInitialState
		};
	})
};
