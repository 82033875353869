import React, { Fragment, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import NumericInput from 'react-numeric-input';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { green, blue } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/lab/Alert';
import Card from 'components/Card';
import Loader from 'components/Loader';
import utils from 'components/utils';
import { depotStatementCols, checkinCols, checkoutCols, ribaCols } from './Table/columns';
import TableHead from './Table/TableHead';
import TableBody from './Table/TableBody';
import DownloadOptions from './DownloadOptions';
import styles from './styles';

const DepotStatement = ({ classes }) => {
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.documents.details.loading);
	const results = useStoreState(state => state.documents.details.items);
	const savedNumber = useStoreState(state => state.documents.search.savedNumber);
	const amountGross = useStoreState(state => state.documents.details.amountGross);
	const amountNet = useStoreState(state => state.documents.details.amountNet);
	const status = useStoreState(state => state.documents.details.status);
	const urlFCD = useStoreState(state => state.documents.details.urlFCD);
	const untouched = useStoreState(state => state.documents.details.untouched);
	const selectedType = useStoreState(state => state.documents.search.selectedType);
	const modalOpen = useStoreState(state => state.documents.details.modalDetailsDepotStatement);
	const loadingCheck = useStoreState(state => state.documents.details.loadingCheck);
	const checkin = useStoreState(state => state.documents.details.checkin);
	const checkout = useStoreState(state => state.documents.details.checkout);
	const setModalOpen = useStoreActions(dispatch => dispatch.documents.details.openModalDepotStatement);
	const visibleDownloadOptions = useStoreState(state => state.documents.download.visible);
	const setVisibleDownloadOptions = useStoreActions(dispatch => dispatch.documents.download.setVisible);
	const search = useStoreActions(dispatch => dispatch.documents.details.search);
	const resetSearch = useStoreActions(dispatch => dispatch.documents.details.resetSearch);
	const resetAllRows = useStoreActions(dispatch => dispatch.documents.details.resetAllRows);
	const addNewItem = useStoreActions(dispatch => dispatch.documents.details.addNewItem);
	const doCheckin = useStoreActions(dispatch => dispatch.documents.details.doCheckin);
	const doCheckout = useStoreActions(dispatch => dispatch.documents.details.doCheckout);
	const saveCheckIOStatus = useStoreActions(dispatch => dispatch.documents.details.saveCheckIOStatus);
	const deleteCheckIOStatus = useStoreActions(dispatch => dispatch.documents.details.deleteCheckIOStatus);
	const setCheckin = useStoreActions(dispatch => dispatch.documents.details.setCheckin);
	const setCheckout = useStoreActions(dispatch => dispatch.documents.details.setCheckout);
	const [newEan, setNewEan] = useState('');
	const [newQuantity, setNewQuantity] = useState(1);
	const [token, setToken] = useState('');
	const greenTheme = createTheme({ palette: { primary: green } });
	const onDlOptionClick = _ => {
		setVisibleDownloadOptions(!visibleDownloadOptions);
	};
	const onAdd = _ => {
		addNewItem({
			evadibleQt: newQuantity,
			catalog: newEan
		});
		setNewEan('');
		setNewQuantity('1');
	};
	const onCheckClick = _ => {
		if (checkin) doCheckin();
		else if (checkout) doCheckout();
	};
	const onDocFCDDownload = _ => {
		window.open(urlFCD, '_blank');
	};
	const onModalClose = _ => {
		if (checkin) saveCheckIOStatus('checkin');
		else if (checkout) saveCheckIOStatus('checkout');
		setCheckin(false);
		setCheckout(false);
		setNewEan('');
		setNewQuantity(1);
		setToken('');
		setModalOpen(false);
	};
	const onExitClick = _ => {
		setCheckin(false);
		setCheckout(false);
		deleteCheckIOStatus();
		resetAllRows();
	};
	const showCheckUI = checkin || checkout;
	const visibleResults = results.filter(r => r.visible);
	const duplicateEAN = results.find(r => r.catalog === newEan) !== undefined;
	const newDisabled = newEan === '' || newQuantity === 0;
	const cols =
		selectedType.id === 'RIBA' ? ribaCols : checkin ? checkinCols : checkout ? checkoutCols : depotStatementCols;
	return (
		<Modal className={classes.modalPosition} open={modalOpen} onClose={onModalClose}>
			<div className="modal-content">
				<Fragment>
					<Card
						type="compact"
						title={translate(selectedType.i18nKey) + ' : ' + savedNumber}
						style={{ maxHeight: 700 }}
					>
						<Grid container spacing={2}>
							<Grid container item xs={12} justify="flex-end" style={{ marginTop: -10, padding: 0 }}>
								<IconButton className={classes.noRipple} onClick={onModalClose}>
									<CloseIcon />
								</IconButton>
							</Grid>
							{selectedType.id !== 'RIBA' && (
								<Grid item xs={6} style={{ paddingTop: 26 }}>
									<Typography className={classes.title}>
										{translate('documents.total')}:
										<strong> {translate('documents.amount')}: </strong>
										{utils.formatPrice(amountGross)}
										<strong> {translate('documents.net')}: </strong>
										{utils.formatPrice(amountNet)}
									</Typography>
								</Grid>
							)}
							{selectedType.id === 'RIBA' && (
								<Grid item xs={6} style={{ paddingTop: 26 }}>
									<Typography className={classes.title}>
										{translate('documents.total')}:
										<strong> {translate('documents.amount')}: </strong>
										{utils.formatPrice(amountGross)}
									</Typography>
								</Grid>
							)}
							{selectedType.id !== 'RIBA' && (
								<Grid container item xs={6} justify="flex-end">
									<Button variant="outlined" color="primary" onClick={onDlOptionClick}>
										{visibleDownloadOptions ? 'chiudi' : 'vedi'} opzioni di download
									</Button>
									{status === '00' && !showCheckUI && (
										<Button
											variant="contained"
											endIcon={<SubdirectoryArrowLeftIcon />}
											color="primary"
											onClick={_ => setCheckin(true)}
											style={{ marginLeft: 16 }}
										>
											checkin
										</Button>
									)}
									{status === '01' && !showCheckUI && (
										<Button
											variant="contained"
											endIcon={<SubdirectoryArrowRightIcon />}
											color="primary"
											onClick={_ => setCheckout(true)}
											style={{ marginLeft: 16 }}
										>
											checkout
										</Button>
									)}
									{status === '91' && (
										<Button
											variant="contained"
											endIcon={<GetAppOutlinedIcon />}
											color="primary"
											onClick={onDocFCDDownload}
											style={{ marginLeft: 16 }}
										>
											segnacollo
										</Button>
									)}
									{showCheckUI && !loadingCheck && (
										<ThemeProvider theme={greenTheme}>
											<Button
												variant="contained"
												endIcon={<CheckIcon />}
												color="primary"
												disabled={untouched}
												onClick={onCheckClick}
												style={{ marginLeft: 16 }}
											>
												completa
											</Button>
										</ThemeProvider>
									)}
									{showCheckUI && loadingCheck && <CircularProgress size={14} />}
								</Grid>
							)}
							{visibleDownloadOptions && selectedType.id !== 'RIBA' && (
								<Grid item container xs={12}>
									<DownloadOptions />
								</Grid>
							)}
							{showCheckUI && selectedType.id !== 'RIBA' && (
								<Fragment>
									<Grid item container xs={12}>
										<Alert
											style={{ width: '100%' }}
											variant="filled"
											severity="warning"
											action={
												<Button color="inherit" size="small" onClick={onExitClick}>
													Annulla
												</Button>
											}
										>
											{checkin && 'Checkin in corso'}
											{checkout && 'Checkout in corso'}
										</Alert>
									</Grid>
									<Grid item container xs={6}>
										<Grid item container xs>
											<div className={classes.search}>
												<div className={classes.searchIcon}>
													<SearchIcon />
												</div>
												<InputBase
													placeholder={translate('common.search.placeholder')}
													classes={{
														root: classes.inputRoot,
														input: classes.inputInput
													}}
													value={token}
													onChange={e => setToken(e.target.value)}
												/>
											</div>
										</Grid>
										<Grid item container style={{ width: 80 }} justify="flex-end">
											<Button
												color="primary"
												variant="contained"
												size="small"
												onClick={_ => search(token)}
											>
												Cerca
											</Button>
										</Grid>
										<Grid item container style={{ width: 80 }} justify="flex-end">
											<Button
												color="primary"
												variant="outlined"
												size="small"
												onClick={_ => resetSearch(token)}
											>
												Reset
											</Button>
										</Grid>
									</Grid>
									{checkin && (
										<Grid item container xs={6} justify="flex-end">
											<Grid item container xs justify="flex-end">
												<div className={classes.newEan}>
													<InputBase
														placeholder="EAN"
														classes={{
															root: classes.inputRoot,
															input: classes.inputInputNewEan
														}}
														value={newEan}
														onChange={e => setNewEan(e.target.value)}
													/>
												</div>
											</Grid>
											<Grid item container style={{ width: 70 }} justify="flex-end">
												<NumericInput
													style={{ input: { fontSize: 14, height: 35, width: 60 } }}
													step={1}
													min={0}
													value={newQuantity}
													onChange={v => setNewQuantity(v)}
												/>
											</Grid>
											<Grid
												item
												container
												style={{ width: duplicateEAN ? 30 : 123 }}
												justify="flex-end"
											>
												{duplicateEAN && (
													<Tooltip title="Titolo già presente in bolla, controlla l’elenco">
														<IconButton
															aria-label="warning 1000"
															style={{ padding: '6px 0 0 0' }}
														>
															<WarningIcon
																fontSize="small"
																style={{ color: blue[500] }}
															/>
														</IconButton>
													</Tooltip>
												)}
												{!duplicateEAN && (
													<Button
														color="primary"
														endIcon={<AddIcon />}
														variant="contained"
														size="small"
														disabled={newDisabled}
														onClick={onAdd}
													>
														aggiungi
													</Button>
												)}
											</Grid>
										</Grid>
									)}
								</Fragment>
							)}
							<Grid item container xs={12}>
								{loading && (
									<Grid
										item
										container
										xs={12}
										justify="center"
										spacing={3}
										style={{ padding: '2px, 1px, 2px, 1px' }}
									>
										<Loader />
									</Grid>
								)}
								{/* SEARCH RESULTS */}
								{!loading && visibleResults.length > 0 && (
									<Fragment>
										<Grid item container xs={12}>
											{visibleResults.length > 0 && (
												<TableContainer component={Paper} style={{ maxHeight: 400 }}>
													<Table size="small" stickyHeader aria-label="documents table">
														<TableHead visibleCols={cols} />
														<TableBody
															rows={visibleResults}
															visibleCols={cols}
															isCheckout={checkout}
														/>
													</Table>
												</TableContainer>
											)}
										</Grid>
									</Fragment>
								)}
							</Grid>
						</Grid>
					</Card>
				</Fragment>
			</div>
		</Modal>
	);
};

export default withStyles(styles, { withTheme: true })(DepotStatement);
