import endpoints from './endpoints';
import redirectURLS from './redirect-urls';
import searchResultsCols from './search-results-cols';
import openOrderCols from './open-order-cols';
import shippedOrderCols from './shipped-order-cols';
import processingOrderCols from './processing-order-cols';
import returnCols from './returns-cols';
import promotionDetailsCols from './promotion-details-cols';
import pendingWishlistCols from './pending-wishlist-cols';
import wishlistCols from './wishlist-cols';

export default {
	endpoints,
	redirectURLS,
	searchResultsCols,
	openOrderCols,
	shippedOrderCols,
	processingOrderCols,
	returnCols,
	promotionDetailsCols,
	pendingWishlistCols,
	wishlistCols,
	clientSecret: process.env.REACT_APP_CLIENT_SECRET,
	scholasticUrl: process.env.REACT_APP_SCHOLASTIC_URL
};
