import k from 'k';
import utils from 'services/utils';

export default ({ id, itemsId }) => {
	const requestOptions = {
		method: 'DELETE',
		headers: utils.createHeaders()
	};

	return utils
		.fetch(`${k.endpoints.apis}/wishlist/deleteitems/${id}?list=${itemsId}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
