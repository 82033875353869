import k from 'k';
import utils from 'services/utils';

export default ({ channelId, messageTypeId }) => {
	const requestOptions = {
		method: 'DELETE',
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/me/alertAccepted/channel/${channelId}/messageType/${messageTypeId}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
