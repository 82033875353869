import { action, thunk } from 'easy-peasy';

export default {
	owner: [
		{ profile: 'BO', label: 'sede' },
		{ profile: 'WAREHOUSE_MANAGER', label: 'sede' },
		{ profile: 'AGENT', label: 'agente' },
		{ profile: 'CLIENT', label: 'utente' }
	],
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	viewRows: [],
	setViewRows: action((state, payload) => {
		state.viewRows = payload;
	}),
	userId: null,
	setUserId: action((state, payload) => {
		state.userId = payload;
	}),
	orderBy: 'id',
	orderDirection: 'desc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	save: action((state, payload) => {
		const getOwnerLabel = userName => {
			const label = state.owner.filter(p => p.profile === userName);
			if (label.length > 0) return label[0].label;
			else return '';
		};
		state.items = payload.data.items.map(item => ({
			...item,
			isSelected: false,
			createdByLabel: item.userCode === state.userId ? 'personal' : getOwnerLabel(item.userName)
		}));
		state.viewRows = state.items;
	}),
	selectAll: action((state, payload) => {
		const { token, userId } = payload;
		state.items = state.items.map(item => ({
			...item,
			isSelected:
				!token.trim() && item.userCode == userId
					? true
					: state.viewRows.filter(i => i.id === item.id && item.userCode == userId).length > 0
					? true
					: item.isSelected
		}));
	}),
	deselectAll: action((state, payload) => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: !payload.trim()
				? false
				: state.viewRows.filter(i => i.id === item.id).length > 0
				? false
				: item.isSelected
		}));
	}),
	toggleSelection: action((state, payload) => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: item.id === payload ? !item.isSelected : item.isSelected
		}));
	}),
	load: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		const { services } = injections;
		const filters = getStoreState().proposals.filters.list;
		const params = { filters };
		actions.setLoading(true);
		actions.setUserId(payload);
		return services.proposals
			.search(params)
			.then(data => {
				actions.save({ data });
				//actions.setViewRows([]);
			})
			.catch(e => {
				getStoreActions().error('proposals.search');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	delete: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.proposals
			.deleteProposal(payload)
			.then(data => {
				actions.load();
				return Promise.resolve(data.items);
			})
			.catch(e => {
				getStoreActions().error('proposals.errorDeletItems');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
