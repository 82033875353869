export default ({ item, depot, depotForAll, fromAll }) => {
	if (item.type === 100) return item;
	const newDepot = item.depots.find(d => d.id === depot);
	const fesOnDepot = it => {
		const activeFESOnDepots = it.depots.find(d => d.fes);
		return activeFESOnDepots ? 1 : 0;
	};
	const getWarningFES = it => {
		const filteredFESDepots = fesOnDepot(it);
		return filteredFESDepots === 1 ? true : false;
	};
	const warnFES = getWarningFES(item);
	const qty = newDepot.stock === 0 ? 0 : 1;
	const qtyForAll = fromAll > newDepot.stock ? newDepot.stock : fromAll;

	if (warnFES && item.selection) {
		return {
			...item,
			selection: {
				...item.selection,
				depot: newDepot,
				warningExceeding: false,
				warningFES: warnFES,
				quantity: depotForAll ? qtyForAll : qty
			}
		};
	} else {
		return {
			...item,
			selection: {
				...item.selection,
				depot: newDepot,
				warningFES: warnFES,
				warningExceeding: newDepot.stock < item.selection.quantity,
				quantity: item.selection.quantity
			}
		};
	}
};
