import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

const Selection = _ => {
	const results = useStoreState(state => state.coupons.list.items);
	const selectAll = useStoreActions(dispatch => dispatch.coupons.list.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.coupons.list.deselectAll);
	const items = results.filter(r => r.isSelected);
	const onSelectAll = event => {
		event.target.checked ? selectAll() : deselectAll();
	};
	return (
		<TableCell padding="checkbox" style={{ backgroundColor: '#fafafa' }}>
			<Checkbox
				color="primary"
				checked={results.length === items.length}
				onChange={onSelectAll}
				inputProps={{ 'aria-label': 'select all rows' }}
			/>
		</TableCell>
	);
};

export default Selection;
