import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Chip from '@mui/material/Chip';
import utils from 'components/utils';

const ScopeChip = ({ filter }) => {
	const translate = utils.useI18N();
	const resetSingleFilter = useStoreActions(dispatch => dispatch.catalog.filters.resetSingle);
	const resetOrder = useStoreActions(dispatch => dispatch.catalog.order.reset);
	const searchIn = translate('catalog.searchIn');
	const label = translate(`catalog.scopes.${filter.value.label}`);
	const scopes = useStoreState(state => state.catalog.scopes.list);
	const selectScope = useStoreActions(dispatch => dispatch.catalog.scopes.select);
	const [scopeSelected, setScopeSelected] = useState('');
	const isVisibleFull = scopeSelected === 'full' ? false : true;

	useEffect(
		_ => {
			if (scopes.length === 0) return;
			const selected = scopes.find(s => s.selected);
			setScopeSelected(selected.id);
		},
		[scopes]
	);

	const resetBadgeRankingFilters = _ => {
		if (filter.value.id == 'rankings') resetOrder();
		resetSingleFilter('selectedScope');
		selectScope('full'); //Reset della select box dei filtri avanzati
	};

	return (
		isVisibleFull && (
			<Chip size="small" label={`${searchIn}: ${label}`} onDelete={_ => resetBadgeRankingFilters()} />
		)
	);
};

export default ScopeChip;
