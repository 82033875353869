import { action, thunk } from 'easy-peasy';

export default {
	items: [],
	setItems: action((state, payload) => {
		state.items = payload;
	}),
	updateSingleItem: action((state, payload) => {
		const { day, available } = payload;
		const i = state.items.findIndex(itm => itm.day === day);
		if (i === -1) {
			state.items = state.items.concat({ day, available });
		} else {
			state.items = state.items
				.slice(0, i)
				.concat({ day, available })
				.concat(state.items.slice(i + 1));
		}
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	getDays: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { month, year } = payload;
		actions.setLoading(true);
		services.returns
			.getDays({ month, year })
			.then(data => {
				actions.setItems(data.items);
			})
			.catch(e => {
				getStoreActions().error('returnRequests.general');
			})
			.finally(_ => actions.setLoading(false));
	}),
	setSingleDay: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { day, available } = payload;
		actions.setLoading(true);
		services.returns
			.setSingleDay({ startDate: day, endDate: day, available })
			.then(data => {
				actions.updateSingleItem(data.items[0]);
			})
			.catch(e => {
				getStoreActions().error('returnRequests.general');
			})
			.finally(_ => actions.setLoading(false));
	}),
	setAllMonth: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { month, year, available } = payload;
		actions.setLoading(true);
		services.returns
			.setAllMonth({ month, year, available })
			.then(data => {
				actions.setItems(data.items);
			})
			.catch(e => {
				getStoreActions().error('returnRequests.general');
			})
			.finally(_ => actions.setLoading(false));
	})
};
