import getPromotionSlide from './get-promotion-slide';
import getBanners from './get-banners';
import getVideos from './get-videos';
import getPromotions from './get-promotions';
import getBestSellers from './get-best-sellers';

export default {
	getPromotionSlide,
	getBanners,
	getVideos,
	getPromotions,
	getBestSellers
};
