import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import UpdateModal from '../../UpdateModal';
import { withStyles } from '@mui/styles';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		minWidth: 350
	},
	modalLabels: {
		marginBottom: theme.spacing()
	},
	buttonsMargin: {
		margin: `${theme.spacing(0, 1, 0, 1)}`,
		alignSelf: 'center',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1, 0)
		}
	}
});

const EditSelected = ({ classes }) => {
	const someSelected = useStoreState(state => state.conditionRequest.pendingList.someSelected);
	const selectedItems = useStoreState(state => state.conditionRequest.pendingList.selectedItems);
	const modalOpen = useStoreState(state => state.conditionRequest.updateMultiple.modalOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.conditionRequest.updateMultiple.setModalOpen);
	const update = useStoreActions(dispatch => dispatch.conditionRequest.updateMultiple.sendRequest);
	const translate = utils.useI18N();
	const onUpdate = (notes, selectedPromoId) => {
		update(
			selectedItems.map(item => ({
				id: item.id,
				sapPromoId: selectedPromoId,
				responsableNote: notes
			}))
		);
	};
	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={modalOpen}
				onClose={_ => {
					setModalOpen(false);
				}}
				closeAfterTransition
			>
				<UpdateModal items={selectedItems} onUpdate={onUpdate} onClose={_ => setModalOpen(false)} />
			</Modal>
			<Button disabled={!someSelected} variant="light" onClick={_ => setModalOpen(true)}>
				{translate('wishlist.editSelected')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(EditSelected);
