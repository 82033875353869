import { useStoreState } from 'easy-peasy';
import get from 'lodash/get';

const useUserData = (path, fallback, forceLogged) => {
	const personify = useStoreState(state => state.me.personify);
	const data = useStoreState(state => state.me.data);
	const source = forceLogged ? data : personify.target.id !== '' ? personify.target : data;
	if (path === '') return source;
	return get(source, path, fallback);
};

export default useUserData;
