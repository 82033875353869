import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { Stepper, StepLabel, Step, Grid, Typography, Stack, Paper } from '@mui/material';
import FileUploader from './components/FileUploader';
import InvalidFile from './components/InvalidFile';
import NotFoundItems from './components/NotFoundItems';
import NotOrdinableItems from './components/NotOrdinableItems';
import Selection from './components/Selection';
import utils from 'components/utils';

const styles = theme => ({});

const Import = ({ classes }) => {
	const translate = utils.useI18N();
	const activeStep = useStoreState(state => state.importt.activeStep);
	const reset = useStoreActions(dispatch => dispatch.importt.reset);
	const setCartCreationScope = useStoreActions(dispatch => dispatch.cart.open.setScope);
	const steps = [<FileUploader />, <InvalidFile />, <NotFoundItems />, <NotOrdinableItems />, <Selection />];
	const getActiveStepContent = _ => steps[activeStep];
	// prettier-ignore
	useEffect(_ => { reset() }, [reset]);
	// prettier-ignore
	useEffect(_ => { setCartCreationScope('import'); }, [setCartCreationScope]);
	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				<Stack
					direction={{ xs: 'row', md: 'row', sm: 'row' }}
					justifyContent="flex-stat"
					alignItems="flex-start"
					spacing={1}
					variant="wrapperColor"
				>
					<Typography variant="titlePage">{translate('common.import')}</Typography>
				</Stack>
			</Paper>
			<Stepper
				activeStep={activeStep}
				alternativeLabel
				sx={{ margin: '20px 0', width: { xs: '95%', md: '100%', sm: '100%' } }}
			>
				<Step>
					<StepLabel>{translate('import.stepper.import')}</StepLabel>
				</Step>
				<Step>
					<StepLabel>{translate('import.stepper.checkFile')}</StepLabel>
				</Step>
				<Step>
					<StepLabel>{translate('import.stepper.invalidEans')}</StepLabel>
				</Step>
				<Step>
					<StepLabel>{translate('import.stepper.notOrdinable')}</StepLabel>
				</Step>
				<Step>
					<StepLabel>Seleziona destinazione</StepLabel>
				</Step>
			</Stepper>
			<Grid container>
				<Grid item xs={12}>
					{getActiveStepContent()}
				</Grid>
			</Grid>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(Import);
