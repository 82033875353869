import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import styles from '../styles';
import TableCell from '@mui/material/TableCell/TableCell';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

const TabsVariant = ({
	loadingListAbilitation,
	getCbStatusAbilitation,
	toggleSelectionAbilitation,
	loadingListVisualization,
	getCbStatusVisualization,
	toggleSelectionVisualization,
	item
}) => {
	return (
		<Fragment>
			<TableCell align="left" style={{ width: 25 }}>
				{loadingListAbilitation.indexOf(item.id) === -1 && (
					<Checkbox
						color="primary"
						checked={getCbStatusAbilitation(item.id)}
						onChange={e => {
							toggleSelectionAbilitation(item.id, e.target.checked);
						}}
					/>
				)}
				{loadingListAbilitation.indexOf(item.id) !== -1 && <CircularProgress size={16} />}
			</TableCell>
			<TableCell align="left" style={{ width: 25 }}>
				{loadingListVisualization.indexOf(item.id) === -1 && (
					<Checkbox
						color="primary"
						checked={getCbStatusVisualization(item.id)}
						onChange={e => {
							toggleSelectionVisualization(item.id, e.target.checked);
						}}
					/>
				)}
				{loadingListVisualization.indexOf(item.id) !== -1 && <CircularProgress size={16} />}
			</TableCell>
		</Fragment>
	);
};
export default withStyles(styles, { withTheme: true })(TabsVariant);
