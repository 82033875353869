import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	loadingUpload: false,
	setLoadingUpload: action((state, payload) => {
		state.loadingUpload = payload;
	}),
	loadingCondictions: false,
	setLoadingCondictions: action((state, payload) => {
		state.loadingCondictions = payload;
	}),
	loadingAbilitations: false,
	setLoadingAbilitations: action((state, payload) => {
		state.loadingAbilitations = payload;
	}),
	successUpload: false,
	setSuccessUpload: action((state, payload) => {
		state.successUpload = payload;
	}),
	images: [],
	save: action((state, payload) => {
		state.images = payload;
	}),
	userInfo: null,
	saveUserInfo: action((state, payload) => {
		state.userInfo = payload;
	}),
	constestInfo: null,
	saveContestInfo: action((state, payload) => {
		state.constestInfo = payload;
	}),
	contestActive: null,
	abilitations: [],
	saveAbilitations: action((state, payload) => {
		if (payload.length > 0) {
			state.abilitations = payload[0];
			state.contestActive = payload[0].ctaEnabled;
		} else {
			state.abilitations = payload;
			state.contestActive = false;
		}
	}),
	getAbilitations: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingAbilitations(true);
		services.cms
			.getBanners()
			.then(data => {
				actions.saveAbilitations(data.items.filter(i => i.ctaTypeValue == payload));
			})
			.catch(e => {
				//getStoreActions().error('contest.errorLoadImages');
			})
			.finally(_ => {
				actions.setLoadingAbilitations(false);
			});
	}),
	loadUserInfo: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.contest
			.getUserInfo(payload)
			.then(data => {
				actions.saveUserInfo(data);
			})
			.catch(e => {
				//getStoreActions().error('contest.errorLoadImages');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	updateCondictions: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingCondictions(true);
		services.contest
			.updateCondictions(payload)
			.then(data => {
				actions.saveUserInfo(data);
			})
			.catch(e => {
				//getStoreActions().error('contest.errorLoadImages');
			})
			.finally(_ => {
				actions.setLoadingCondictions(false);
			});
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.contest
			.getImages(payload)
			.then(data => {
				actions.save(data);
			})
			.catch(e => {
				//getStoreActions().error('contest.errorLoadImages');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	uploadImage: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setSuccessUpload(false);
		actions.setLoadingUpload(true);
		const { data, path, pos } = payload;
		return services.contest
			.uploadImage(payload)
			.then(data => {
				//actions.load();
				actions.setSuccessUpload(true);
				actions.load(path);
			})
			.catch(e => {
				getStoreActions().error('contest.erroUploLoadImage');
				actions.setSuccessUpload(false);
			})
			.finally(_ => {
				actions.setLoadingUpload(false);
			});
	})
};
