import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import {
	Button,
	Grid,
	Typography,
	CircularProgress,
	TextField,
	Stack,
	Paper,
	FormControl,
	Select,
	MenuItem
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		width: 800
	},
	modalLabels: {
		marginBottom: theme.spacing()
	}
});

const UpdateModal = ({ classes, items, onClose, onUpdate }) => {
	const loadingUpdate = useStoreState(state => state.conditionRequest.update.loading);
	const loadingPromotions = useStoreState(state => state.conditionRequest.promotions.loading);
	const promotions = useStoreState(state => state.conditionRequest.promotions.list);
	const getPromos = useStoreActions(dispatch => dispatch.conditionRequest.promotions.load);
	const selectPromotion = useStoreActions(dispatch => dispatch.conditionRequest.promotions.select);
	const translate = utils.useI18N();
	const selectedPromo = promotions.find(p => p.isSelected);
	const selectedPromoValue = selectedPromo ? selectedPromo.id : '';
	const selectedPromoId = selectedPromo ? selectedPromo.sapPromoId : '';
	const [notes, setNotes] = useState('');
	const [textAreaCount, settextAreaCount] = React.useState(100);
	const recalculate = e => {
		const charCount = e.target.value.length;
		const charLeft = 100 - charCount;
		settextAreaCount(charLeft);
	};
	useEffect(getPromos, [getPromos]);
	return (
		<Paper className={classes.root}>
			<Grid container justify="space-between" style={{ marginTop: 16 }}>
				<Grid item xs={12}>
					<Typography variant="titleSection">{translate('wishlist.updateTitle')}</Typography>
				</Grid>
				{items.map(itm => (
					<Grid key={itm.id} item xs={12}>
						<Typography className={classes.modalLabels}>
							{itm.id} - {itm.wishlistName}: {itm.promotionName}
						</Typography>
					</Grid>
				))}
				{loadingPromotions && <CircularProgress size={16} />}
				{!loadingPromotions && (
					<Grid item xs={12}>
						<FormControl variant="outlined">
							<Select value={selectedPromoValue} onChange={e => selectPromotion(e.target.value)}>
								{promotions.map((item, index) => (
									<MenuItem key={item.id} value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				)}
				<Grid item xs={12} style={{ marginTop: 8 }}>
					<TextField
						rows={3}
						maxRows={5}
						multiline
						maxLength={100}
						style={{ width: '100%', resize: 'none' }}
						aria-label="warning message"
						defaultValue={notes}
						placeholder="note"
						onChange={e => {
							setNotes(e.target.value);
							recalculate(e);
						}}
					/>
					<p>Inserire al massimo {textAreaCount} caratteri</p>
				</Grid>
				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ width: '100%' }}>
					<Button variant="light" endIcon={<CloseIcon />} onClick={onClose}>
						{translate('common.close')}
					</Button>
					<Button
						variant="dark"
						onClick={_ => onUpdate(notes, selectedPromoId)}
						endIcon={loadingUpdate ? <CircularProgress size={16} /> : <SaveIcon />}
					>
						{translate('common.save')}
					</Button>
				</Stack>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles, { withTheme: true })(UpdateModal);
