import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableHead from './components/Table/TableHead';
import TableBody from './components/Table/TableBody';
import SendButton from './components/SendButton';
import Card from 'components/Card';
import Loader from 'components/Loader';
import CustomNoResult from './components/CustomNoResults';
import utils from 'components/utils';
import Pagination from './components/Pagination';
import styles from './styles';

const SendNotification = ({ classes }) => {
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.BOnotifications.list.loading);
	const results = useStoreState(state => state.BOnotifications.list.items);
	const load = useStoreActions(dispatch => dispatch.BOnotifications.list.load);
	useEffect(
		_ => {
			load();
		},
		[load]
	);
	return (
		<Card style={{ marginTop: 0 }} type="primary" title={translate('common.menu.sendNotification')}>
			<Grid container spacing={3}>
				<Grid item container xs={12}>
					<SendButton />
				</Grid>
				<Grid item container xs={12}>
					{loading && (
						<Grid
							item
							container
							xs={12}
							justify="center"
							spacing={3}
							style={{ padding: '2px, 1px, 2px, 1px' }}
						>
							<Loader />
						</Grid>
					)}
					{/* SEARCH RESULTS */}
					{!loading && results.length > 0 && (
						<Fragment>
							{/* PAGINATION */}
							<Grid item container xs={12} style={{ marginBottom: 10 }}>
								<Pagination />
							</Grid>
							<Grid item container xs={12}>
								{results.length > 0 && (
									<TableContainer component={Paper} style={{ maxHeight: 800 }}>
										<Table
											size="small"
											stickyHeader
											aria-label="promo table"
											sx={{ position: 'sticky' }}
										>
											<TableHead />
											<TableBody rows={results} />
										</Table>
									</TableContainer>
								)}
							</Grid>
						</Fragment>
					)}
					{/* NO RESULTS */}
					{!loading && results.length === 0 && (
						<Grid item container xs={12} justify="center">
							<CustomNoResult />
						</Grid>
					)}
				</Grid>
			</Grid>
		</Card>
	);
};

export default withStyles(styles, { withTheme: true })(SendNotification);
