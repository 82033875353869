import React, { forwardRef, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
const ButtonContent = forwardRef((props, ref) => <RouterLink {...props} />);

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 1, 0, 0)
		}
	}
});

const AssignUsers = ({ classes, currentCa }) => {
	const translate = utils.useI18N();
	const reset = useStoreActions(dispatch => dispatch.purchasings.assignUsers.resetSearch);

	return (
		<Fragment>
			<Button
				className={classes.buttonsMargin}
				variant="outlined"
				component={ButtonContent}
				to={`/admin/purchasingcenters/${currentCa}/assign`}
				onClick={() => reset()}
			>
				{translate('purchasings.buttons.assignUsers')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(AssignUsers);
