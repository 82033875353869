import React, { Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItem, Typography, Checkbox, ListItemText, Box, Stack } from '@mui/material';
import utils from 'components/utils';

const SelectCart = props => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const items = useStoreState(state => state.cart.evasion.items);
	const setItems = useStoreActions(dispatch => dispatch.cart.evasion.setItems);
	const toggleSelection = useStoreActions(dispatch => dispatch.cart.evasion.toggleSelection);
	const selectAll = useStoreActions(dispatch => dispatch.cart.evasion.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.cart.evasion.deselectAll);
	const allSelected = items.every(i => i.selected);
	const toggleAllSelection = _ => {
		if (allSelected) deselectAll();
		else selectAll();
	};

	useEffect(() => {
		setItems();
	}, []);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={0.5}
				sx={{ width: '100%', borderBottom: '1px solid #ccc', margin: '0 0 -10px 16px !important' }}
			>
				<Checkbox
					onChange={toggleAllSelection}
					checked={allSelected}
					indeterminate={items.filter(item => item.selected).length > 0}
					sx={{ color: theme.palette.primary.main }}
				/>
				<Typography variant="text" sx={{ fontWeight: '600' }}>
					{allSelected ? translate('common.deselectAll') : translate('common.selectAll')}
				</Typography>
			</Stack>
			<List sx={{ maxWidth: '100%' }}>
				{items.map((item, index) => (
					<ListItem key={index}>
						<ListItemButton
							onClick={e => toggleSelection(item.idForSelection)}
							sx={{ maxWidth: '45px', padding: '0' }}
						>
							<Checkbox checked={item.selected} inputProps={{ 'aria-labelledby': item.name }} />
						</ListItemButton>
						<ListItemText
							primary={
								<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
									{item.fcd && (
										<Typography variant="chip" sx={{ backgroundColor: theme.palette.custom.black }}>
											fcd
										</Typography>
									)}
									<Typography variant="text" sx={{ fontWeight: '600' }}>
										{!item.isDefault ? item.name : translate('orders.defaultCart')}
									</Typography>
								</Stack>
							}
							secondary={
								<Typography variant="text">
									{translate('orders.evasion.totalEvadible') +
										': ' +
										utils.formatPrice(item.resumeOrder.sumCostProcessable)}
								</Typography>
							}
						/>
					</ListItem>
				))}
			</List>
		</Stack>
	);
};

export default SelectCart;
