import React from 'react';
import { MenuItem, Stack, Typography, Divider, alpha } from '@mui/material';
import utils from 'components/utils';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const AdminList = props => {
	const { relativePath, allSubMenuStyles, setAnchorEl, secondaryStyle } = props;
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const translate = utils.useI18N();
	const mainPathFromRelativePath = relativePath
		.split('/')
		.splice(1, 2)
		.pop();
	const menuItems = [
		{
			id: ['send-notification'],
			label: 'notifications',
			to: '/admin/send-notification',
			iCan: iCan('MANAGE_NOTIFICATIONS')
		},
		{
			id: ['users'],
			label: 'anagraphic',
			to: '/admin/users',
			iCan: iCan('MANAGE_USERS')
		},
		{
			id: ['usergroups'],
			label: 'userGroups',
			to: '/admin/usergroups',
			iCan: iCan('MANAGE_GROUPS')
		},
		{
			id: ['purchasingcenters'],
			label: 'purchasingGroups',
			to: '/admin/purchasingcenters',
			iCan: iCan('MANAGE_CA')
		},
		{
			id: ['return-requests'],
			label: 'returns',
			to: '/admin/return-requests',
			iCan: iCan('MANAGE_CALENDAR_RETURNS')
		},
		{
			id: ['promotions'],
			label: 'promotions',
			to: '/admin/promotions',
			iCan: iCan('MANAGE_PROMOTIONS')
		},
		{
			id: ['coupons'],
			label: 'coupons',
			to: '/admin/coupons',
			iCan: iCan('MANAGE_COUPONS')
		},
		{
			id: ['rankings'],
			label: 'rankings',
			to: '/admin/rankings',
			iCan: iCan('MANAGE_RANKING')
		},
		{
			id: ['editorials'],
			label: 'editorials',
			to: '/admin/editorials',
			iCan: iCan('MANAGE_EDITORIAL_UNLOCK')
		},
		{
			id: ['withdraw-types'],
			label: 'evasions',
			to: '/admin/withdraw-types',
			iCan: iCan('MANAGE_SPECIAL_SHIPMENTS')
		},
		{
			id: ['special-delivery'],
			label: 'specialDelivery',
			to: '/admin/special-delivery',
			iCan: iCan('MANAGE_SPECIAL_DELIVERY')
		},
		{
			id: ['fastlink-health'],
			label: 'health',
			to: '/admin/fastlink-health',
			iCan: iCan('MANAGE_HEALTH')
		}
	];

	const handleClick = _ => {
		setAnchorEl(null);
	};

	return (
		<Stack
			sx={{ padding: `${secondaryStyle.margin}px` }}
			justifyContent="center"
			alignItems="center"
			direction="column"
		>
			<Typography variant="overline" sx={{ ...allSubMenuStyles.title }}>
				{translate('common.menu.admin')}
			</Typography>
			<Divider sx={{ ...allSubMenuStyles.divider }} />
			{menuItems.map((menuItem, index) => {
				const isSelectedPath =
					relativePath === menuItem.to ||
					mainPathFromRelativePath === menuItem.id.find(element => element === mainPathFromRelativePath);
				return (
					menuItem.iCan && (
						<MenuItem
							key={index}
							sx={{
								...allSubMenuStyles.singleItem,
								backgroundColor:
									isSelectedPath && alpha(theme.palette.primary.main, secondaryStyle.opacity),
								cursor: isSelectedPath && 'default' // cannot operate on disable prop to change color and background, so faking disable
							}}
							disableTouchRipple={isSelectedPath} // same as ↑
							onClick={handleClick}
							component={menuItem.to && Link}
							to={menuItem.to}
						>
							{translate(`common.menu.${menuItem.label}`)}
						</MenuItem>
					)
				);
			})}
		</Stack>
	);
};

export default AdminList;
