import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Loader from 'components/Loader';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import Nazionale from 'routes/Catalog/Details/components/images/nazionale.png';
import Regionale from 'routes/Catalog/Details/components/images/regionale.png';
import Provinciale from 'routes/Catalog/Details/components/images/provinciale.png';
import NazionalePro from 'routes/Catalog/Details/components/images/nazionalePro.png';
import RegionalePro from 'routes/Catalog/Details/components/images/regionalePro.png';
import ProvincialePro from 'routes/Catalog/Details/components/images/provincialePro.png';

const BadgeRankingCard = ({ setAnchorEl, size }) => {
	const search = useStoreActions(dispatch => dispatch.catalog.search.performSearch);
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const resetFilters = useStoreActions(dispatch => dispatch.catalog.filters.reset);
	const setOrder = useStoreActions(dispatch => dispatch.catalog.order.setOrderNoTrigger);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const loadBadges = useStoreActions(dispatch => dispatch.catalog.badges.loadBadges);
	const loading = useStoreState(state => state.catalog.badges.loading);
	const items = useStoreState(state => state.catalog.badges.items);
	const personify = useStoreState(state => state.me.personify);
	const userData = useStoreState(state => state.me.data);
	const translate = utils.useI18N();
	const [matches, setMatches] = useState(window.matchMedia('screen and (min-width:768px)').matches);
	const province = personify.target.id
		? personify.target.address.county
			? personify.target.address.county.toUpperCase()
			: 'XX'
		: userData.address.county
		? userData.address.county.toUpperCase()
		: 'XX';

	const components = {
		nazionale: Nazionale,
		regionale: Regionale,
		provinciale: Provinciale,
		nazionalePro: NazionalePro,
		regionalePro: RegionalePro,
		provincialePro: ProvincialePro
	};

	const icoStyle = {
		nazionale: '#91A410',
		regionale: '#46A6B6',
		provinciale: '#CA852A',
		nazionalePro: '#F34F32',
		regionalePro: '#D3C932',
		provincialePro: '#BF8FB3'
	};

	const textStyleRow = {
		fontWeight: '500',
		paddingTop: '24px'
	};

	const textStyleRows = {
		fontWeight: '500',
		paddingTop: '10px'
	};

	useEffect(
		_ => {
			loadBadges({ province });
		},
		[province]
	);
	const goToBadge = item => {
		resetFilters();
		setFilters({
			ranking: item.id,
			badgeRanking: true,
			badgeRankingName:
				item.ranking +
				(item.region ? ' (' + item.region + ')' : item.province ? ' (' + item.province + ')' : '')
		});
		setOrder('ranking');
		//		search(); -> tolto per evitare la doppia chiamata a search (setOrder chiama search)
		setStep(25); //Richiamo la ricerca perchè è stato inserito il setOrder che non richiama search
		setAnchorEl(null);
	};

	return (
		<Fragment>
			{loading && (
				<Grid item container xs={12} justifyContent="center" spacing={3}>
					<Loader />
				</Grid>
			)}
			{!loading && (
				<Fragment>
					<Typography
						variant="overline"
						color="primary"
						component="p"
						style={{ textAlign: 'center', fontSize: '15px' }}
					>
						{translate(`common.menu.badgeRankings`)}
					</Typography>
					<a
						href="https://www.ie-online.it/"
						target="_blank"
						rel="noopener noreferrer"
						style={{
							textAlign: 'center',
							fontSize: '14px',
							width: '100%',
							display: 'block',
							color: '#00529b'
						}}
					>
						{translate(`common.menu.badgeRankingsSubtitle`)}
					</a>
					<Divider style={{ margin: 8 }} light />
					<List sx={{ width: '100%' }}>
						{items.map(item => (
							<ListItem
								key={item.id}
								alignItems="flex-start"
								button
								component={Link}
								to={'/catalog/search'}
								onClick={_ => {
									goToBadge(item);
								}}
								style={{ padding: '0 5px 5px 15px' }}
							>
								<ListItemIcon>
									<img
										src={components[item.image]}
										width={size}
										style={{
											//backgroundColor: icoStyle[item.image],
											//borderRadius: '100%',
											//padding: '1px',
											marginRight: '10px'
										}}
									/>
								</ListItemIcon>
								<ListItemText
									primary={item.ranking}
									secondary={item.region ? item.region : item.province ? item.province : ''}
									primaryTypographyProps={{
										style:
											item.region || item.province || (!matches && item.ranking.length > 42)
												? textStyleRows
												: textStyleRow
									}}
								/>
							</ListItem>
						))}
					</List>
				</Fragment>
			)}
		</Fragment>
	);
};

export default withStyles({ withTheme: true })(BadgeRankingCard);
