import React, { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import MenuToolbar from './components/MenuToolbar';
import Home from './components/Home';
import Catalog from './components/Catalog';
import Profile from './components/Profile';
import Orders from './components/Orders';
import Returns from './components/Returns';
import Budget from './components/Budget';
import Documents from './components/Documents';
import Help from './components/Help';
import Admin from './components/Admin';
import Contact from './components/Contact';
import GetTV from './components/GetTV';
import Personify from './components/Personify';
import BadgeRanking from './components/BadgeRanking';
import Scholastic from './components/Scholastic';
import MobileLogout from './components/MobileLogout';
import FastSales from './components/FastSales';
import utils from 'components/utils';

const Menu = props => {
	const iCan = utils.usePermissions();
	const [iAmPersonifying] = utils.usePersonification();
	const fastsales = utils.useFastSales();

	return (
		<Fragment>
			<MenuToolbar />
			<Divider />
			<List>
				<Home />
				{iCan('CATALOGS') && <Catalog />}
				{iCan('CATALOGS') && <BadgeRanking />}
				{iCan('VIEW_SCHOLASTIC_MENU') && <Scholastic />}
				{iCan('ORDERS') && <Orders />}
				{iCan('RETURNS') && <Returns />}
				{iCan('BUDGET') && <Budget />}
				{iCan('DOCUMENTS') && <Documents />}
				{fastsales && <FastSales />}
				{iCan('HELP') && <Help />}
				{!iAmPersonifying && iCan('CONTACT_AGENT') && <Contact />}
				<Profile />
				<GetTV />
			</List>
			<Divider />
			{(iCan('PERSONIFY_USER') || iAmPersonifying) && <Personify />}
			{iCan('BACKOFFICE') && (
				<Fragment>
					<List>
						<Admin />
					</List>
					<Divider />
				</Fragment>
			)}
			<Hidden mdUp>
				<List>
					<MobileLogout />
				</List>
			</Hidden>
		</Fragment>
	);
};

export default Menu;
