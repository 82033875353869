import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
	Grid,
	Stack,
	IconButton,
	InputBase,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Button,
	Typography,
	Switch
} from '@mui/material';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import HeadlessCard from 'components/Card/HeadlessCard';
import utils from 'components/utils';
import moment from 'moment';
import styles from './styles';

const Filters = ({ classes, close, showCloseIcon, setAnchorEl }) => {
	const translate = utils.useI18N();
	const token = useStoreState(state => state.promotions.filters.list.token);
	const filtersArePristine = useStoreState(state => state.promotions.filters.arePristine);
	const status = useStoreState(state => state.promotions.filters.list.status);
	const statusValue = useStoreState(state => state.promotions.filters.list.statusValue);
	const statusWishlist = useStoreState(state => state.promotions.filters.list.statusWishlist);
	const statusWishlistValue = useStoreState(state => state.promotions.filters.list.statusWishlistValue);
	const setToken = useStoreActions(dispatch => dispatch.promotions.filters.setToken);
	const reset = useStoreActions(dispatch => dispatch.promotions.filters.reset);
	const setStatus = useStoreActions(dispatch => dispatch.promotions.filters.setStatus);
	const setStatusValue = useStoreActions(dispatch => dispatch.promotions.filters.setStatusValue);
	const setStatusWishlist = useStoreActions(dispatch => dispatch.promotions.filters.setStatusWishlist);
	const setStatusWishlistValue = useStoreActions(dispatch => dispatch.promotions.filters.setStatusWishlistValue);
	const dateFrom = useStoreState(state => state.promotions.filters.list.dateFrom);
	const setDateFrom = useStoreActions(dispatch => dispatch.promotions.filters.setDateFrom);
	const dateTo = useStoreState(state => state.promotions.filters.list.dateTo);
	const setDateTo = useStoreActions(dispatch => dispatch.promotions.filters.setDateTo);
	const search = useStoreActions(dispatch => dispatch.promotions.search.performSearch);

	return (
		<div
			className={clsx(classes.negateMargin, {
				[classes.margin]: showCloseIcon
			})}
		>
			<HeadlessCard>
				<Grid container spacing={2}>
					{showCloseIcon && (
						<Grid container item xs={12} justify="flex-end">
							<IconButton onClick={close} className={classes.closeIcon}>
								<CloseIcon />
							</IconButton>
						</Grid>
					)}
					<Grid container item xs={12} className={classes.elements}>
						<div className={classes.search}>
							<InputBase
								startAdornment={<SearchIcon />}
								placeholder={translate('promo.searchInPromo')}
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput
								}}
								value={token}
								onChange={e => setToken(e.target.value)}
								inputProps={{ 'aria-label': 'search' }}
							/>
						</div>
					</Grid>
					<Grid container item xs={6} className={classes.elements}>
						<FormGroup aria-label="position" row style={{ height: '100%', alignContent: 'flex-end' }}>
							<FormControlLabel
								checked={status}
								control={<Checkbox color="primary" onChange={e => setStatus(e.target.checked)} />}
								label={translate('promo.details.statusCatalogToggle')}
							/>
						</FormGroup>
					</Grid>
					<Grid container item xs={6} className={classes.elements} justifyContent="flex-end">
						<Typography component="div">
							<Grid component="label" container alignItems="center" spacing={1}>
								<Grid item>Off</Grid>
								<Grid item>
									<Switch
										color="primary"
										size="medium"
										checked={statusValue}
										onChange={e => setStatusValue(e.target.checked)}
									/>
								</Grid>
								<Grid item>On</Grid>
							</Grid>
						</Typography>
					</Grid>
					<Grid container item xs={6} className={classes.elements}>
						<FormGroup aria-label="position" row style={{ height: '100%', alignContent: 'flex-end' }}>
							<FormControlLabel
								checked={statusWishlist}
								control={
									<Checkbox color="primary" onChange={e => setStatusWishlist(e.target.checked)} />
								}
								label={translate('promo.details.statusWishlistToggle')}
							/>
						</FormGroup>
					</Grid>
					<Grid container item xs={6} className={classes.elements} justifyContent="flex-end">
						<Typography component="div">
							<Grid component="label" container alignItems="center" spacing={1}>
								<Grid item>Off</Grid>
								<Grid item>
									<Switch
										color="primary"
										size="medium"
										checked={statusWishlistValue}
										onChange={e => setStatusWishlistValue(e.target.checked)}
									/>
								</Grid>
								<Grid item>On</Grid>
							</Grid>
						</Typography>
					</Grid>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={2}
						sx={{ width: '100%' }}
					>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								value={dateFrom}
								format="DD/MM/YYYY"
								onChange={d => {
									setDateFrom(d);
								}}
								className="date-picker"
								label={translate('promo.dateFrom')}
								fullWidth
								error={!dateFrom || !moment(dateFrom).isValid()}
								helperText={translate('common.dateInvalid')}
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								value={dateTo}
								format="DD/MM/YYYY"
								onChange={d => {
									setDateTo(d);
								}}
								className="date-picker"
								label={translate('promo.dateTo')}
								fullWidth
								error={!dateTo || !moment(dateTo).isValid() || moment(dateFrom).isAfter(moment(dateTo))}
								helperText={translate('common.dateInvalid')}
							/>
						</LocalizationProvider>
					</Stack>
					<Grid container item xs={12} spacing={0} className={classes.elements} style={{ marginTop: 16 }}>
						<Grid container item xs={8}>
							<Button
								disableElevation
								variant="contained"
								disabled={
									filtersArePristine ||
									(dateFrom && !moment(dateFrom).isValid()) ||
									(dateTo && !moment(dateTo).isValid()) ||
									(dateFrom && dateTo && moment(dateFrom).isAfter(moment(dateTo)))
								}
								color="primary"
								onClick={search}
							>
								{translate('common.search.search')}
							</Button>
							<Button
								style={{ marginLeft: 8 }}
								disableElevation
								variant="outlined"
								color="primary"
								onClick={reset}
							>
								{translate('common.reset')}
							</Button>
						</Grid>
						<Grid container item xs={4} justifyContent="flex-end">
							<Button
								style={{ marginLeft: 8 }}
								disableElevation
								variant="outlined"
								color="primary"
								onClick={() => setAnchorEl(null)}
							>
								{translate('common.close')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</HeadlessCard>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(Filters);
