import { action, thunk, computed } from 'easy-peasy';
import utils from 'components/utils';

export default {
	items: [],
	setItems: action((state, payload) => {
		state.items = payload.items.map(item => ({ ...item, quantity: Math.max(1, item.quantity) }));
		state.notOrdinable = payload.notImportedItems;
		state.notFound = payload.notFoundItems;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	depot: '',
	setDepot: action((state, payload) => {
		state.depot = payload;
	}),
	notOrdinable: [],
	notFound: [],
	activeStep: 0,
	setActiveStep: action((state, payload) => {
		state.activeStep = payload;
	}),
	reset: action(state => {
		state.activeStep = 0;
		state.items = [];
		state.notOrdinable = [];
		state.notFound = [];
	}),
	remove: action((state, payload) => {
		state.items = state.items.filter(itm => itm.ean !== payload);
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.cart
			.importt(payload)
			.then(data => {
				/* Gestione eventuali item Epipoli */
				const iAmPersonifying = getStoreState().me.iAmPersonifying;
				const personify = getStoreState().me.personify;
				const permissions = getStoreState().me.permissions;
				const useEpipoli =
					(iAmPersonifying &&
						personify &&
						personify.target &&
						personify.target.userPermission &&
						personify.target.userPermission['MANAGE_EPIPOLI']) ||
					(!iAmPersonifying && permissions && permissions['MANAGE_EPIPOLI']);
				const itemsEpipoli = data.items && data.items.filter(i => i.type === 200);
				if (itemsEpipoli.length > 0) {
					//Se l'utente non è abilitato a Epipoli escludo gli item
					if (!useEpipoli) {
						data.notImportedItems = itemsEpipoli;
						data.items = data.items.filter(i => i.type !== 200);
					}
					//se l'utente è abilitato a Epipoli gestisco la quantità massima ordinabile e il deposito
					if (useEpipoli) {
						//Gli item su deposito diverso da Fast Hub li escludo
						data.notImportedItems = itemsEpipoli.filter(i => i.selectedDepotCode !== 'FS01');
						data.items = data.items
							.filter(i => i.type !== 200 || (i.type === 200 && i.selectedDepotCode === 'FS01'))
							.map(item => ({
								...item,
								quantity: item.type !== 200 ? item.quantity : Math.min(20, item.quantity)
							}));
					}
				}
				/* Fine gestione eventuali item Epipoli */

				actions.setItems(data);
				if (data.notFoundItems.length > 0) actions.setActiveStep(2);
				else if (data.notImportedItems.length > 0) actions.setActiveStep(3);
				else actions.setActiveStep(4);
				//getStoreActions().success('cart.import'); -> tolto per non mostrare il messaggio
				return Promise.resolve(data);
			})
			.catch(e => {
				console.log(e);
				actions.setActiveStep(1);
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	allFasthub: computed(state => state.items.every(item => item.selectedDepotCode === 'FS01')),
	noType100: computed(state => state.items.every(item => item.type !== 100)),
	noZ1: computed(state => state.items.every(item => item.noticeCode !== 'z1'))
};
