import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import ListItemLink from '../../../../components/ListItemLink';
import utils from 'components/utils';

const EditComp = ({ id }) => {
	const translate = utils.useI18N();
	return (
		<StyledTableCell>
			<ListItemLink
				data-tesid={`button.edit.${id}`}
				to={`/admin/editorials/${id}`}
				button
				style={{ background: 'none' }}
			>
				<Tooltip title={translate('common.edit')} placement="top">
					<IconButton size="small" disableRipple style={{ padding: 8, background: 'none' }}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
					</IconButton>
				</Tooltip>
			</ListItemLink>
		</StyledTableCell>
	);
};
const Edit = memo(
	({ item }) => <EditComp id={item.id} />,
	(p, n) => p.item.id === n.item.id
);

export default Edit;
