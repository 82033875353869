import { action, thunk } from 'easy-peasy';

export default {
	data: [],
	users: [],
	allUsers: [],
	setUsers: action((state, payload) => {
		state.users = payload;
	}),
	save: action((state, payload) => {
		let rows = payload.utenti;
		if (!payload.utenti || payload.utenti == null) {
			rows = payload.utente.includes('*') ? [] : payload.utente.split('.');
			if (rows.length > 0) {
				rows = rows[0] === '' ? rows.slice(1) : rows;
				rows = rows[rows.length - 1] === '' ? rows.slice(0, rows.length - 1) : rows;
			}
		}
		state.allUsers = rows;
		state.users = rows;
		state.data = payload;
	}),
	token: '',
	setToken: action((state, payload) => {
		state.token = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	setName: action((state, payload) => {
		state.data.name = payload;
	}),
	setDescription: action((state, payload) => {
		state.data.description = payload;
	}),
	setEnabled: action((state, payload) => {
		state.data.enabled = payload;
	}),
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.userGroups
			.getDetailsAssignment(payload)
			.then(data => {
				actions.save(data.item);
			})
			.catch(e => {
				getStoreActions().error('userGroups.errorGetDetailsUserGroup');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	remove: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { id, user } = payload;
		actions.setLoading(true);
		return services.userGroups
			.deleteUser(payload)
			.then(data => {
				getStoreActions().success('userGroups.userDelete');
				actions.load(id);
			})
			.catch(data => {
				getStoreActions().error('userGroups.errorUserDelete');
				actions.load(id);
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	orderBy: 'id',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	})
};
