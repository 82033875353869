import React, { useEffect, useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import InputBase from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import utils from 'components/utils';
import Sortable from './Sortable';
import styles from './styles';

const UserGroupsSelector = ({ classes, selected, toggleSelection }) => {
	const loading = useStoreState(state => state.userGroups.list.loadingList);
	const list = useStoreState(state => state.userGroups.list.items);
	const order = useStoreState(state => state.userGroups.list.orderDirection);
	const orderBy = useStoreState(state => state.userGroups.list.orderBy);
	const load = useStoreActions(dispatch => dispatch.userGroups.list.load);
	const [token, setToken] = useState('');
	let rows = utils.stableSort(list, order, orderBy).filter((row, index) => index !== 0);
	if (token !== '') {
		rows = rows.filter(
			item =>
				(item.name && item.name.toLowerCase().includes(token)) ||
				(item.description && item.description.toLowerCase().includes(token))
		);
	}
	useEffect(load, [load]);
	return (
		<Grid container spacing={1} justify="space-between">
			{loading && (
				<Grid container item xs={12}>
					<CircularProgress size={40} />
				</Grid>
			)}
			{!loading && (
				<Fragment>
					<Grid container item xs={12}>
						<div className={classes.search}>
							<InputBase
								startAdornment={<SearchIcon />}
								placeholder="Cerca"
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput
								}}
								value={token}
								onChange={e => setToken(e.target.value)}
								inputProps={{ 'aria-label': 'search', 'data-testid': 'promo.groups.search' }}
							/>
						</div>
					</Grid>
					<Grid container item xs={12}>
						<TableContainer component={Paper} style={{ maxHeight: 500 }}>
							<Table stickyHeader aria-label="manage evasion" size="small">
								<TableHead>
									<TableRow>
										<TableCell>&nbsp;</TableCell>
										<Sortable id="id" key="headCell_id" label="promo.tables.id" />
										<Sortable id="name" key="headCell_name" label="promo.tables.name" />
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map(item => (
										<TableRow key={`usergroup_cell_${item.id}`}>
											<TableCell align="left" style={{ width: 25 }}>
												<Checkbox
													color="primary"
													checked={selected.includes(item.id)}
													onChange={e => {
														toggleSelection(item.id, e.target.checked);
													}}
												/>
											</TableCell>
											<TableCell align="left">{item.id}</TableCell>
											<TableCell align="left">{item.name}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Fragment>
			)}
		</Grid>
	);
};
export default withStyles(styles, { withTheme: true })(UserGroupsSelector);
