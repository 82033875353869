import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';
import moment from 'moment';
import Icon from '@mui/material/Icon';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

const WishlistInfo = ({ item }) => {
	const translate = utils.useI18N();
	return (
		<Grid container wrap="nowrap" item xs={7} sm={10} md={10}>
			<Grid item xs={12} sm md={2}>
				<Typography variant="subtitle2">{utils.capitalize(item.userDisplayName)}</Typography>
				<Typography variant="caption">
					<b>Id:</b>&nbsp;
					{utils.capitalize(item.userCode)}
				</Typography>
			</Grid>
			<Grid item md={3} sx={{ alignSelf: 'flex-end', display: { xs: 'none', md: 'inherit', sm: 'none' } }}>
				<Typography variant="caption">
					<b>{translate('wishlist.summary.agent')}:</b>&nbsp;
					{utils.capitalize(item.creatorName)}
				</Typography>
			</Grid>
			<Grid item md={3} sx={{ alignSelf: 'flex-end', display: { xs: 'none', md: 'block', sm: 'none' } }}>
				<Grid item>
					<Typography variant="caption">
						<b>{translate('wishlist.summary.creationDate')}:</b>&nbsp;
						{moment(item.wishlistCreationDate).format('L')}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="caption">
						<b>{translate('wishlist.summary.lastModificationDate')}:</b>&nbsp;
						{moment(item.wishlistLastModificationDate).format('L')}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				item
				sm={8}
				md={7}
				justify="space-evenly"
				direction="column"
				sx={{ display: { xs: 'none', md: 'inherit', sm: 'inherit' } }}
			>
				<Grid item>
					{item.wishlistType === 'BOOK2C' && (
						<Typography variant="body2">
							<strong>{translate('wishlist.summary.name')}</strong>:&nbsp;
							{utils.capitalizeAll(item.wishlistName)}
						</Typography>
					)}
					{item.wishlistType === null && (
						<Typography variant="body2">
							<strong>{translate('wishlist.summary.name')}</strong>:&nbsp;
							{utils.capitalizeAll(item.wishlistName)}
						</Typography>
					)}
				</Grid>
				<Grid item>
					<Typography variant="body2">
						<strong>{translate('wishlist.summary.condition')}</strong>:&nbsp;
						{utils.capitalizeAll(item.promotionName)}
					</Typography>
				</Grid>
			</Grid>
			<Grid item md={1} sx={{ display: { xs: 'none', md: 'block', sm: 'none' } }}>
				{item.wishlistStatus === 'INTO_CART' && (
					<Icon component="div" fontSize="large">
						<ShoppingCartOutlinedIcon />
					</Icon>
				)}
			</Grid>
		</Grid>
	);
};

export default WishlistInfo;
