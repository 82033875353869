export default {
	id: '',
	name: '',
	lastname: '',
	email: '',
	company: '',
	address: {
		state: '',
		city: '',
		street: '',
		postalCode: '',
		country: ''
	},
	fiscalCode: '',
	showSapInfo: false,
	phone: '',
	mobile: '',
	catalogColumns: ['ean', 'author', 'title', 'promo', 'stock'],
	catalogRows: 25,
	promoRows: 25,
	fax: '',
	commercialEmail: '',
	vatID: '',
	invoiceCode: '',
	fastlinkProfile: '',
	category: '',
	administrativeEmail: '',
	bankCredit: '',
	showDiscountPercentage: false,
	documentSendEnabled: false,
	discountVisibility: false,
	isEnabled: false,
	referent: '',
	mainDepot: {
		id: '',
		name: '',
		type: ''
	},
	availableDepots: [],
	returnDepots: [],
	isLegalAccepted: false,
	newsletter: {
		mailTo: '',
		subscription: false,
		requested: true
	},
	alertAccepted: [],
	bearer: null,
	idToken: null,
	refreshToken: null,
	isLoggedIn: false,
	selectedBudget: null
};
