import React from 'react';
import { TableContainer, Table, Typography, Stack } from '@mui/material';
import utils from 'components/utils';
import OpenOrdersItemsTableHead from './OpenOrdersItemsTableHead';
import OpenOrdersItemsTableBody from './OpenOrdersItemsTableBody';
import OpenOrdersItemSelectedDelete from './OpenOrdersItemSelectedDelete';
import OpenOrdersItemsDownload from './OpenOrdersItemsDownload';

const OpenOrdersItemsTable = props => {
	const { order } = props;
	const translate = utils.useI18N();
	const itemSelected = order.orderItems.filter(i => i.isSelected);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			{/* Toolbar tabella */}
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{ width: '100%' }}
			>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={0}
					sx={{ width: '90%' }}
				>
					<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
						<Typography variant="titleSection">{translate('orders.listOfItems')}</Typography>
						{order.orderItems && order.orderItems.length > 0 && <OpenOrdersItemsDownload item={order} />}
					</Stack>
					{itemSelected.length > 0 && (
						<Typography variant="text">
							{translate('common.selected') + ': ' + itemSelected.length}
						</Typography>
					)}
				</Stack>
				<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
					{itemSelected.length > 0 && (
						<OpenOrdersItemSelectedDelete id={order.id} name={order.name} itemSelected={itemSelected} />
					)}
				</Stack>
			</Stack>
			{/* Elenco titoli */}
			<TableContainer sx={{ width: '100%', maxWidth: '1300px' }}>
				<Table aria-label="open order items table">
					<OpenOrdersItemsTableHead cart={order} />
					<OpenOrdersItemsTableBody cart={order} />
				</Table>
			</TableContainer>
		</Stack>
	);
};

export default OpenOrdersItemsTable;
