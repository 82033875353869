import k from 'k';
import utils from 'services/utils';

export default ca => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const idCA = ca ? `/?idCa=${ca}` : '';
	requestOptions.abortCtrl = utils.abort.subscribe('load-open-carts');
	return utils
		.fetch(`${k.endpoints.apis}/cart/order/open${idCA}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
