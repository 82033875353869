import importt from './import';
import getList from './list';
import getDetails from './get-details';
import search from './search';
import update from './update';
import addUserGroupAbilitation from './add-user-group-abilitation';
import removeUserGroupAbilitation from './remove-user-group-abilitation';
import getUserGroupsAbilitation from './get-user-groups-abilitation';
import getItems from './get-items';
import updateAssignment from './update-assignment';
import createCoupon from './create-coupon';
import deleteCoupons from './delete-coupons';
import download from './download';
import importArticles from './import-articles';
import getReport from './report';
import removePdf from './remove-pdf';

export default {
	importt,
	getList,
	getDetails,
	search,
	update,
	addUserGroupAbilitation,
	removeUserGroupAbilitation,
	getUserGroupsAbilitation,
	getItems,
	updateAssignment,
	createCoupon,
	deleteCoupons,
	download,
	importArticles,
	getReport,
	removePdf
};
