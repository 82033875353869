import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import keys from 'lodash/keys';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import styles from './styles';
import k from 'k';
import svcUtils from 'services/utils';

const Perms = ({ classes }) => {
	const stateUser = useStoreState(state => state.me.data);
	const permissions = useStoreState(state => state.me.permissions);
	const [perm, setPerm] = useState('');
	const [code, setCode] = useState(stateUser.id);
	const [grant, setGrant] = useState(false);
	const perms = keys(permissions);
	return (
		<Grid item container xs={12} justify="center" spacing={3} className={classes.elements}>
			<Grid item xs={2}>
				<FormControl variant="outlined">
					<Select value={perm} onChange={event => setPerm(event.target.value)}>
						{perms.map((item, index) => (
							<MenuItem key={item} value={item}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={2}>
				<TextField label="user" value={code} onChange={e => setCode(e.target.value)} />
			</Grid>
			<Grid item xs={1}>
				<FormControlLabel
					checked={grant}
					control={<Checkbox color="primary" onChange={e => setGrant(e.target.checked)} />}
					label="active"
				/>
			</Grid>
			<Grid item xs={2}>
				<Button
					variant="contained"
					color="primary"
					onClick={e => {
						fetch(`${k.endpoints.apis}/permission/users/${code}/${perm}`, {
							method: grant ? 'PATCH' : 'DELETE',
							headers: svcUtils.createHeaders()
						}).then(data => console.log('permission ok'));
					}}
				>
					SEND
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Link to="/"> - home</Link>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(Perms);
