import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import keys from 'lodash/keys';
import get from 'lodash/get';
import { Grid, Tooltip, Stack } from '@mui/material';
import styles from './styles';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import utils from 'components/utils';
import CASelector from 'components/CASelector';
import NormalChip from 'components/Filters/NormalChip';
import PubDateChip from './components/PubDateChip';
import ScopeChip from './components/ScopeChip';
import SubScopeChip from './components/SubScopeChip';
import PromotionChip from './components/PromotionChip';
import DeweyChips from './components/DeweyChips';
import BadgeRanking from './components/BadgeRanking';
import BadgeRankingName from './components/BadgeRankingName';
import TreePath from 'components/TreePath';

const AppliedFilters = ({ classes }) => {
	const iCan = utils.usePermissions();
	const selectPromotion = useStoreActions(dispatch => dispatch.catalog.promotions.select);
	const stateFilters = useStoreState(state => state.catalog.filters.list);
	const resetFilters = useStoreActions(dispatch => dispatch.catalog.filters.reset);
	const activeFilters = pickBy(stateFilters, identity);
	const history = useHistory();
	const filters = keys(activeFilters).map(key => ({ label: key, value: get(activeFilters, key) }));
	const resetSingleNoTrigger = useStoreActions(dispatch => dispatch.catalog.filters.resetSingleNoTrigger);
	const download = useStoreActions(dispatch => dispatch.coupons.importt.download);
	const hasCoupons = stateFilters.selectedScope ? stateFilters.selectedScope : [];
	const resetTreeItemsSelected = useStoreActions(dispatch => dispatch.catalog.thema.resetItemsSelected);
	const setTreeToken = useStoreActions(dispatch => dispatch.catalog.themaAutocomplete.setToken);
	const filterMap = {
		selectedScope: filter => <ScopeChip filter={filter} />,
		selectedSubScope: filter => <SubScopeChip filter={filter} />,
		selectedPromotion: filter => <PromotionChip filter={filter} />,
		pubDateFrom: filter => <PubDateChip filter={filter} />,
		pubDateTo: filter => <PubDateChip filter={filter} />,
		dewey: filter => <DeweyChips filter={filter} />,
		badgeRanking: filter => <BadgeRanking filter={filter} />,
		badgeRankingName: filter => <BadgeRankingName filter={filter} />,
		ranking: filter => null,
		themaType: filter => null,
		themaCodes: filter => null,
		themaRanking: filter => null
	};
	/*
	const onReset = () => {
		resetFilters();
		selectPromotion('');
		resetTreeItemsSelected();
		setTreeToken('');
		history.push('/catalog');
	};
	*/
	const viewChips =
		filters.filter(filt => filt.value !== null && filt.label !== 'themaCodes').length > 0 ? true : false;
	const viewTree = filters.filter(filt => filt.label === 'themaCodes').length > 0 ? true : false;

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="flex-start"
			spacing={0}
			sx={{ width: '100%' }}
		>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{ width: { xs: '90%', md: '90%', sm: '90%' } }}
			>
				{viewChips && (
					<div className={classes.root}>
						{filters.map(filter => (
							<Fragment key={`filter_${filter.label}`}>
								{filterMap[filter.label] && filterMap[filter.label](filter)}
								{!filterMap[filter.label] && (
									<NormalChip filter={filter} reset={resetSingleNoTrigger} />
								)}
							</Fragment>
						))}
						{hasCoupons.id === 'coupons' ? (
							<Tooltip title={'Scarica allegato PDF'}>
								<GetAppOutlinedIcon
									style={{ cursor: 'pointer' }}
									onClick={() => download(stateFilters.selectedSubScope.id)}
								/>
							</Tooltip>
						) : (
							''
						)}
					</div>
				)}
				{viewTree && (
					<div className={classes.themaRoot}>
						<TreePath />
					</div>
				)}
			</Stack>
			<Stack direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={1} sx={{ width: '10%' }}>
				{iCan('OPERATE_ON_MULTIPLE_CA') && (
					<Grid container item xs={6}>
						<CASelector />
					</Grid>
				)}
				{/* Spostato accanto al pulsante "Cerca"
				<Button variant="light" onClick={onReset}>
					RESET
				</Button>
				*/}
			</Stack>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(AppliedFilters);
