import utils from 'utils';

export default theme => ({
	elements: {
		padding: `${theme.spacing(2, 1, 2, 1)} !important`
	},
	buttonsMargin: {
		margin: `${theme.spacing(0, 1, 0, 1)}`,
		maxHeight: 36,
		alignSelf: 'center'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	borderedRow: {
		padding: theme.spacing(1, 2),
		borderRadius: theme.shape.borderRadius,
		border: `1px solid rgba(${utils.colors.hexToRgbString(theme.palette.primary.main)}, .20)`,
		marginTop: theme.spacing(2)
	}
});
