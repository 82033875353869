import React, { Fragment, useState, useEffect, useCallback, memo } from 'react';
import isObject from 'lodash/isObject';
import debounce from 'lodash/debounce';
import compact from 'lodash/compact';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { FixedSizeList } from 'react-window';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import serviceUtils from 'services/utils';
import styles from './styles';

const PersonifyItem = ({ data }) => {
	const token = useStoreState(state => state.me.personify.autocompleteToken);
	const iCan = utils.usePermissions();
	const getColor = id => ['yellow', 'green', 'red', 'gray'][id - 1];
	const getOptionLabel = o => {
		if (!isObject(o)) return o;
		const labels = iCan('OPERATE_ON_MULTIPLE_CA')
			? ['nameCA', 'usercode', 'username', 'city', 'province']
			: ['usercode', 'username', 'address', 'city', 'province'];
		const values = compact(labels.map(s => (o[s] ? o[s] : null)));
		return values.join(' - ');
	};
	const label = getOptionLabel(data);
	const matches = match(label, token);
	const parts = parse(label, matches);
	const abilitationCode = data.abilitationCode;
	return (
		<div
			style={{
				fontSize: 16,
				pointerEvents: abilitationCode === 3 ? 'none' : 'auto',
				cursor: abilitationCode === 3 ? 'not-allowed' : 'pointer'
			}}
		>
			<FiberManualRecordIcon style={{ color: getColor(abilitationCode), height: '0.6em' }} />
			{parts.map((part, index) => (
				<span
					key={index}
					style={{
						fontWeight: part.highlight ? 700 : 400,
						cursor: abilitationCode === 3 ? 'not-allowed' : 'pointer',
						opacity: abilitationCode === 3 ? 0.5 : 1
					}}
				>
					{part.text}
				</span>
			))}
		</div>
	);
};

const Results = memo(({ list, onClick }) => {
	const renderRow = ({ index, style }) => {
		const item = list[index];
		return (
			<ListItem key={item.usercode} style={style} button onClick={_ => onClick(item)}>
				<ListItemText primary={<PersonifyItem data={item} />} />
			</ListItem>
		);
	};
	const height = Math.min(200, list.length * 45);
	return (
		<FixedSizeList height={height} width={642} itemSize={50} itemCount={list.length}>
			{renderRow}
		</FixedSizeList>
	);
});

const SearchUser = ({ classes, onChange, setError, error }) => {
	const token = useStoreState(state => state.me.personify.autocompleteToken);
	const loading = useStoreState(state => state.me.personify.autocompleteLoading);
	const setToken = useStoreActions(dispatch => dispatch.me.personify.setAutocompleteToken);
	const load = useStoreActions(dispatch => dispatch.me.personify.loadUsers);
	const list = useStoreState(state => state.me.personify.autocompleteList);
	const { unsubscribe } = serviceUtils.abort;
	const [showResults, setShowResults] = useState(false);
	const debouncedLoad = useCallback(
		debounce(_ => {
			unsubscribe(['personify-search']);
			load();
		}, 1000),
		[]
	);
	const onItemSelect = value => {
		if (value.abilitationCode !== 3) {
			onChange(value);
			setShowResults(false);
		}
	};
	// prettier-ignore
	useEffect(_ => { if (token.length > 2) { debouncedLoad(); } }, [token, debouncedLoad]);
	// prettier-ignore
	useEffect(_ => { setShowResults(list.length > 0) }, [list]);
	return (
		<Fragment>
			<div className={classes.search}>
				<div className={classes.searchIcon}>
					<SearchIcon />
				</div>
				<InputBase
					placeholder="Cerca utenti"
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput
					}}
					endAdornment={
						<Fragment>
							{loading ? (
								<CircularProgress color="inherit" size={20} style={{ marginRight: 10 }} />
							) : null}
						</Fragment>
					}
					value={token}
					onChange={e => setToken(e.target.value)}
				/>
			</div>
			<div className={classes.results}>{showResults && <Results list={list} onClick={onItemSelect} />}</div>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(SearchUser);
