import React, { Fragment } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { useStoreState } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import Select from 'components/Select';
import Loader from 'components/Loader';
import head from 'lodash.head';
import utils from 'components/utils';

const DepotSelector = ({ onChange, selectedDepotId }) => {
	const loading = useStoreState(state => state.catalog.search.loading);
	const availableDepots = utils.useFilteredDepots();
	const competenceDepot = utils.useUserData('mainDepot', null);
	const mainDepotIndex = availableDepots.findIndex(d => d.description === competenceDepot.description);
	const mainDepot = availableDepots[mainDepotIndex];
	const orderedDepots = mainDepot
		? [mainDepot, ...availableDepots.slice(0, mainDepotIndex).concat(availableDepots.slice(mainDepotIndex + 1))]
		: availableDepots;
	if (!selectedDepotId) onChange({ target: { value: head(orderedDepots).id } }); // bad
	return (
		<Fragment>
			{loading && (
				<Grid item container xs={12} justify="center" spacing={3}>
					<Loader />
				</Grid>
			)}
			{!loading && (
				<FormControl variant="outlined">
					<Select
						value={selectedDepotId ? selectedDepotId : head(orderedDepots).id}
						onChange={onChange}
						path="depotForAll.select"
					>
						{orderedDepots.map((item, index) => (
							<MenuItem key={item.id} value={item.id}>
								{item.description}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</Fragment>
	);
};

export default DepotSelector;
