import React, { useState, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OpenOrdersResume, ShippedOrdersResume, BudgetResume } from './OrdersResume';
import DocumentsResume from './DocumentsResume';
import utils from 'components/utils';
import styles from './styles';

const accordionStyles = {
	width: 400,
	border: 'none',
	boxShadow: 'none'
};

const accordionDetailsStyles = {
	marginTop: 0
};

const Resumes = ({ classes }) => {
	const iCan = utils.usePermissions();
	const [expanded, setExpanded] = useState(-1);
	const onAccordionChange = index => {
		if (expanded === index) setExpanded(-1);
		else setExpanded(index);
	};
	return (
		<Fragment>
			<Grid item container xs={12}>
				<Accordion
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 0}
					onChange={_ => onAccordionChange(0)}
					style={accordionStyles}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="body1">Ordini inseriti</Typography>
					</AccordionSummary>
					<AccordionDetails style={accordionDetailsStyles}>
						{expanded === 0 && <OpenOrdersResume />}
					</AccordionDetails>
				</Accordion>
				<Accordion
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 1}
					onChange={_ => onAccordionChange(1)}
					style={accordionStyles}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="body1">Ultime consegne</Typography>
					</AccordionSummary>
					<AccordionDetails style={accordionDetailsStyles}>
						{expanded === 1 && <ShippedOrdersResume />}
					</AccordionDetails>
				</Accordion>
				<Accordion
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 2}
					onChange={_ => onAccordionChange(2)}
					style={accordionStyles}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="body1">Documenti</Typography>
					</AccordionSummary>
					<AccordionDetails style={accordionDetailsStyles}>
						{expanded === 2 && <DocumentsResume />}
					</AccordionDetails>
				</Accordion>
				{iCan('BUDGET') && (
					<Accordion
						TransitionProps={{ unmountOnExit: true }}
						expanded={expanded === 3}
						onChange={_ => onAccordionChange(3)}
						style={accordionStyles}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="body1">Budget</Typography>
						</AccordionSummary>
						<AccordionDetails style={accordionDetailsStyles}>
							{expanded === 3 && <BudgetResume />}
						</AccordionDetails>
					</Accordion>
				)}
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Resumes);
