import { action, thunk, thunkOn } from 'easy-peasy';

const load = (actions, payload, { injections, getState, getStoreActions }) => {
	const { services } = injections;
	if (payload) actions.setLastSapId(payload);
	actions.setLoading(true);
	services.BOpromotions.getUserGroupsVisualization(getState().lastSapId)
		.then(data => actions.setData(data.items))
		.catch(e => {
			getStoreActions().error('promotions.getUserGroups');
		})
		.finally(_ => {
			actions.setLoading(false);
		});
};

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: [],
	setData: action((state, payload) => {
		state.data = payload;
	}),
	lastSapId: false,
	setLastSapId: action((state, payload) => {
		state.lastSapId = payload;
	}),
	load: thunk(load),
	updateOnUserGroupChange: thunkOn(
		(actions, storeActions) => [
			storeActions.promotions.userGroupsVisualization.add.successType,
			storeActions.promotions.userGroupsVisualization.remove.successType
		],
		async (actions, target, helpers) => {
			load(actions, null, helpers);
		}
	)
};
