import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import utils from 'components/utils';
import k from 'k';

const withColumns = WrappedComponent => {
	const breakpointColumnNumberMap = {
		xs: 2,
		sm: 4,
		md: 6,
		mdWithFilters: 3,
		lg: 14,
		lgWithFilters: 4
	};
	let cols = [...k.returnCols];
	return props => {
		const theme = useTheme();
		const iCan = utils.usePermissions();
		if (!iCan('VIEW_ALL_RETURNS_COLS')) {
			cols = cols.filter(c => c !== 'client');
			cols = cols.filter(c => c !== 'companyName');
			cols = cols.filter(c => c !== 'city');
		}
		const requestOpen = useStoreState(state => state.returns.requestOpen);
		let colCount = breakpointColumnNumberMap['xs'];
		if (useMediaQuery(theme.breakpoints.up('sm'))) colCount = breakpointColumnNumberMap['sm'];
		if (useMediaQuery(theme.breakpoints.up('md')) && !requestOpen) colCount = breakpointColumnNumberMap['md'];
		if (useMediaQuery(theme.breakpoints.up('md')) && requestOpen)
			colCount = breakpointColumnNumberMap['mdWithFilters'];
		if (useMediaQuery(theme.breakpoints.up('lg')) && !requestOpen) colCount = breakpointColumnNumberMap['lg'];
		if (useMediaQuery(theme.breakpoints.up('lg')) && requestOpen)
			colCount = breakpointColumnNumberMap['lgWithFilters'];
		const visibleCols = cols.slice(0, colCount);
		return <WrappedComponent {...props} visibleCols={visibleCols} />;
	};
};

export default withColumns;
