import { action, thunk } from 'easy-peasy';

export default {
	referent: 0,
	saveReferent: action((state, payload) => {
		state.referent = payload;
	}),
	loadingReferent: false,
	setLoadingReferent: action((state, payload) => {
		state.loadingReferent = payload;
	}),
	getReferent: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingReferent(true);
		services.notifications
			.getReferent()
			.then(data => {
				actions.saveReferent(data.item);
			})
			.catch(e => {
				getStoreActions().error('notifications.getReferent');
			})
			.finally(_ => {
				actions.setLoadingReferent(false);
			});
	}),
	loadingSend: false,
	setLoadingSend: action((state, payload) => {
		state.loadingSend = payload;
	}),
	send: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingSend(true);
		services.notifications
			.sendContactRequest(payload)
			.then(data => {
				getStoreActions().success('notifications.sendMail');
			})
			.catch(e => {
				getStoreActions().error('notifications.sendMail');
			})
			.finally(_ => {
				actions.setLoadingSend(false);
			});
	})
};
