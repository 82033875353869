import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	openList: {},
	open: action((state, payload) => {
		state.openList[payload] = true;
	}),
	close: action((state, payload) => {
		state.openList[payload] = false;
	}),
	closeAll: action((state, payload) => {
		state.openList = {};
	}),
	updateItem: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.wishlist
			.updateItem(payload)
			.then(data => {
				getStoreActions().success('wishlist.updateItem');
				actions.closeAll();
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('wishlist.updateItem');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.wishlist
			.update(payload)
			.then(data => {
				getStoreActions().success('wishlist.update');
				actions.close(payload.wishlistId);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('wishlist.update');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
