import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DropzoneArea } from 'react-mui-dropzone';
import utils from 'components/utils';
import Alert from '@mui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles';

const FileUploader = ({ classes, id }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const loading = useStoreState(state => state.coupons.importt.loading);
	const setUploaderPdfModalOpen = useStoreActions(dispatch => dispatch.coupons.importt.setUploaderPdf);
	const setBase = useStoreActions(dispatch => dispatch.coupons.importt.setBase);
	const setFileName = useStoreActions(dispatch => dispatch.coupons.importt.setFileName);
	const upload = useStoreActions(dispatch => dispatch.coupons.importt.sendRequest);
	const fileName = useStoreState(state => state.coupons.importt.fileName);
	const [clicked, click] = useState(false);

	const onClose = _ => {
		setUploaderPdfModalOpen(false);
	};
	const onConfirm = _ => {
		upload(id);
	};
	const getFileLimitExceedMessage = filesLimit =>
		translate('upload.fileLimitExceedMessage').replace('_NUM_', filesLimit);
	const getFileAddedMessage = fileName => translate('upload.fileAddedMessage').replace('_NAME_', fileName);
	const getFileRemovedMessage = fileName => translate('upload.fileRemovedMessage').replace('_NAME_', fileName);
	const getDropRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) =>
		translate('upload.dropRejectMessage').replace('_NAME_', rejectedFile.name);
	return (
		<Paper className={classes.root}>
			<Grid container item>
				<Grid container item xs={12} justifyContent="flex-end" style={{ maxHeight: 24 }}>
					<IconButton
						size="small"
						style={{ marginTop: 5, right: theme.spacing(-2) }}
						className={classes.noRipple}
						onClick={_ => {
							setUploaderPdfModalOpen(false);
						}}
					>
						<CloseIcon />
					</IconButton>
				</Grid>
				<Grid item xs={12}>
					<Typography className={classes.modalLabels} variant="h4">
						{translate('upload.selectFile')}
					</Typography>
				</Grid>
			</Grid>
			{fileName !== '' && (
				<Typography className={classes.modalLabels} variant="caption">
					<strong>{translate('upload.fileAddedMessageSubtitle')}:</strong>
					<br /> {fileName}
				</Typography>
			)}
			<DropzoneArea
				acceptedFiles={['.pdf']}
				showAlerts={['error']}
				filesLimit={1}
				maxFileSize={3000000}
				dropzoneText=""
				getFileLimitExceedMessage={getFileLimitExceedMessage}
				getFileAddedMessage={getFileAddedMessage}
				getFileRemovedMessage={getFileRemovedMessage}
				getDropRejectMessage={getDropRejectMessage}
				alertSnackbarProps={{
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'top'
					},
					autoHideDuration: 2000
				}}
				previewGridProps={{
					container: { spacing: 0, justifyContent: 'center' },
					item: { xs: 4 }
				}}
				onDrop={async ([file]) => {
					var reader = new FileReader();
					reader.onload = function(e) {
						var contents = e.target.result;
						setFileName(file.name);
						setBase(contents);
					};
					reader.readAsDataURL(file);
				}}
			/>
			{clicked ? (
				<Grid style={{ marginTop: 16 }}>
					<Alert severity="error">
						<span>{translate('common.warningUpload')}</span>
					</Alert>
				</Grid>
			) : (
				''
			)}
			<Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
				<Grid item>
					<Button disableElevation variant="outlined" color="primary" onClick={onClose}>
						{translate('common.close')}
					</Button>
				</Grid>
				<Grid item>
					{loading && <CircularProgress size={24} />}
					{!loading && (
						<Button
							disabled={!fileName}
							disableElevation
							color="primary"
							variant="contained"
							onClick={() => (clicked ? onConfirm() : click(true))}
						>
							{clicked ? translate('common.ok') : translate('common.confirm')}
						</Button>
					)}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles, { withTheme: true })(FileUploader);
