import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const DocumentNumberComp = ({ text }) => {
	return <StyledTableCell>{text}</StyledTableCell>;
};

const DocNum = memo(
	({ item }) => <DocumentNumberComp text={item.documentNumber} />,
	(p, n) => p.item.documentNumber === n.item.documentNumber
);

export default DocNum;
