import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import utils from 'components/utils';
import styles from '../styles';
import CartSelector from 'components/CartSelector';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Popover from 'components/Popover';
import HeadlessCard from 'components/Card/HeadlessCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
//import CreateCart from 'components/CreateCart';
import CreateCart from 'routes/Orders7/CreateCart';

const UnavailableCheckStep = ({ classes, items, wishlistId, wishlistType, allItems }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const transform = useStoreActions(dispatch => dispatch.wishlist.transform.sendRequest);
	const cartList = useStoreState(state => state.cart.listLight.items);
	//const unavailableEans = useStoreState(state => state.wishlist.getDepotsStock.items);
	//const idList = unavailableEans.map(item => item.id);
	const transferType = utils.useUserData('transferType', null);
	const onlyFS01 = items.every(it => it.depotCode === 'FS01');
	const noZ1 = items.every(it => it.noticeCode !== 'z1');
	const noType100 = items.every(it => it.type !== 100);
	const loadingCart = useStoreState(state => state.wishlist.transform.loading);
	const showFcd = transferType === 'FCD' && !loadingCart && noZ1 && onlyFS01 && noType100;
	const setAnchorCartEl = useStoreActions(dispatch => dispatch.wishlist.transform.setAnchorCartEl);
	const anchorCartEl = useStoreState(state => state.wishlist.transform.anchorCartEl);
	const openCart = Boolean(anchorCartEl);

	const onSave = ({ fcd }) => {
		let cartId = null;
		const cart = cartList.find(it => it.selected);
		if (wishlistType === null) {
			cartId = cart.id;
			transform({ wishlistId: wishlistId, cartId: cartId, fcd });
		} else transform({ wishlistId: wishlistId, fcd });
	};
	const disable = allItems.filter(i => i.noticeCode).length === 1 && wishlistType === null;
	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				{wishlistType === null && (
					<Typography className={classes.modalLabels} variant="subtitle2">
						{translate('wishlist.invalidEans')}
						{disable && translate('wishlist.invalidEans2')}
					</Typography>
				)}
				{wishlistType === 'BOOK2C' && (
					<Typography className={classes.modalLabels} variant="subtitle2">
						{translate('wishlist.invalidEansFromExternalWL')}
					</Typography>
				)}
			</Grid>
			<Grid item xs={12} style={{ marginBottom: 8 }}>
				<TableContainer component={Paper} style={{ maxHeight: 500 }}>
					<Table stickyHeader size="small">
						<TableHead>
							<TableRow>
								<TableCell>EAN</TableCell>
								<TableCell>Titolo</TableCell>
								<TableCell>Autore</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map(item => (
								<TableRow key={`usergroup_cell_${item.id}`}>
									<TableCell align="left">{item.ean}</TableCell>
									<TableCell align="left">{item.title}</TableCell>
									<TableCell align="left">{item.author}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			{wishlistType === null && (
				<Grid item xs={12} style={{ marginBottom: 8 }}>
					<CartSelector disable={disable} />
					<Tooltip title={translate('orders.createCart')} placement="top">
						<IconButton
							className={classes.noRipple}
							style={{ paddingTop: 5 }}
							disableRipple
							onClick={e => {
								setAnchorCartEl(e.currentTarget);
							}}
							disabled={disable}
							sx={{ color: theme.palette.primary.main }}
						>
							<AddCircleIcon sx={{ fontSize: '1.2em' }} />
						</IconButton>
					</Tooltip>
					<Popover
						open={openCart}
						anchorEl={anchorCartEl}
						onClose={_ => {
							setAnchorCartEl(null);
						}}
					>
						<CreateCart
							showSave={true}
							showClose={true}
							onClose={_ => {
								setAnchorCartEl(null);
							}}
						/>
					</Popover>
				</Grid>
			)}
			{loadingCart && <CircularProgress size={16} />}
			{!loadingCart && (
				<Grid item xs={6}>
					<Button
						variant="contained"
						color="primary"
						onClick={_ => onSave({ fcd: false })}
						endIcon={<AddShoppingCartIcon />}
						disabled={disable}
					>
						carrello
					</Button>
				</Grid>
			)}
			{wishlistType === null && showFcd && !loadingCart && (
				<Grid container item xs={6} justify="flex-end">
					<Button
						variant="dark"
						onClick={_ => onSave({ fcd: true })}
						endIcon={<EyeIcon />}
						disabled={disable}
					>
						trasforma in visione
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(UnavailableCheckStep);
