import React, { Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import DownloadOptions from './DownloadOptions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from 'components/Card';
import Loader from 'components/Loader';
import utils from 'components/utils';
import { defaultCols, ribaCols } from './Table/columns';
import TableHead from './Table/TableHead';
import TableBody from './Table/TableBody';
import styles from './styles';

const Default = ({ classes }) => {
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.documents.details.loading);
	const results = useStoreState(state => state.documents.details.items);
	const modalOpen = useStoreState(state => state.documents.details.modalDetails);
	const setModalOpen = useStoreActions(dispatch => dispatch.documents.details.openModal);
	const selectedType = useStoreState(state => state.documents.search.selectedType);
	const savedNumber = useStoreState(state => state.documents.search.savedNumber);
	const documentNumber = useStoreState(state => state.documents.details.documentNumber);
	const docTypeDet = useStoreState(state => state.documents.details.docTypeDet);
	const realTotal = useStoreState(state => state.documents.search.realTotal);
	const amountGross = useStoreState(state => state.documents.details.amountGross);
	const visibleDownloadOptions = useStoreState(state => state.documents.download.visible);
	const setVisibleDownloadOptions = useStoreActions(dispatch => dispatch.documents.download.setVisible);
	const onDlOptionClick = _ => {
		setVisibleDownloadOptions(!visibleDownloadOptions);
	};
	const modalTitle = selectedType.id !== 'RIBA' ? savedNumber : documentNumber;
	const labelType =
		selectedType.id === 'SUMMARY_INVOICES' && docTypeDet === 'ZFCL'
			? translate('documents.SCOLASTIC_INVOICES')
			: translate(selectedType.i18nKey);

	return (
		<Modal className={classes.modalPosition} open={modalOpen} onClose={_ => setModalOpen(false)}>
			<div className="modal-content">
				<Fragment>
					<Card
						type="compact"
						title={
							<Fragment>
								{loading && <CircularProgress size="10px" />}
								{!loading && labelType + ': ' + modalTitle}
							</Fragment>
						}
					>
						<Grid container spacing={3}>
							<Grid
								container
								item
								xs={12}
								justifyContent="flex-end"
								style={{ marginTop: '20px', padding: 0 }}
							>
								<IconButton className={classes.noRipple} onClick={_ => setModalOpen(false)}>
									<CloseIcon />
								</IconButton>
							</Grid>
							{selectedType.id !== 'RIBA' && (
								<Grid item xs={6} style={{ paddingTop: 26 }}>
									<Typography className={classes.title}>
										{translate('documents.total')}:
										<strong> {translate('documents.amount')}: </strong>
										{utils.formatPrice(amountGross)}
										<strong> {translate('documents.net')}: </strong>
										{utils.formatPrice(realTotal)}
									</Typography>
								</Grid>
							)}
							{selectedType.id === 'RIBA' && (
								<Grid item xs={6} style={{ paddingTop: 26 }}>
									<Typography className={classes.title}>
										{translate('documents.total')}:<strong>{utils.formatPrice(amountGross)}</strong>
									</Typography>
								</Grid>
							)}
							{selectedType.id !== 'RIBA' && (
								<Grid container item xs={6} justifyContent="flex-end">
									<Button color="primary" onClick={onDlOptionClick}>
										{visibleDownloadOptions ? 'chiudi' : 'vedi'} opzioni di download
									</Button>
								</Grid>
							)}
							{visibleDownloadOptions && selectedType.id !== 'RIBA' && (
								<Grid item container xs={12}>
									<DownloadOptions />
								</Grid>
							)}
							<Grid item container xs={12}>
								{loading && (
									<Grid
										item
										container
										xs={12}
										justifyContent="center"
										spacing={3}
										style={{ padding: '2px, 1px, 2px, 1px' }}
									>
										<Loader />
									</Grid>
								)}
								{/* SEARCH RESULTS */}
								{!loading && results.length > 0 && (
									<Grid item container xs={12}>
										{results.length > 0 && (
											<TableContainer component={Paper} style={{ maxHeight: 400 }}>
												<Table size="small" stickyHeader aria-label="documents table">
													<TableHead
														visibleCols={
															selectedType.id !== 'RIBA' ? defaultCols : ribaCols
														}
													/>
													<TableBody
														visibleCols={
															selectedType.id !== 'RIBA' ? defaultCols : ribaCols
														}
														rows={results}
													/>
												</Table>
											</TableContainer>
										)}
									</Grid>
								)}
							</Grid>
						</Grid>
					</Card>
				</Fragment>
			</div>
		</Modal>
	);
};

export default withStyles(styles, { withTheme: true })(Default);
