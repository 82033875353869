import React, { useEffect, useState, Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import keys from 'lodash/keys';
import {
	Button,
	Accordion,
	Typography,
	Grid,
	AccordionSummary,
	AccordionDetails,
	Stack,
	Paper,
	LinearProgress,
	Popover,
	Skeleton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Add, ExpandMore } from '@mui/icons-material';
import NewBudget from './components/NewBudget';
import utils from 'components/utils';
import SingleBudget from './components/SingleBudget';

const Budget7 = _ => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const loading = useStoreState(state => state.budget.list.loading);
	const items = useStoreState(state => state.budget.list.items);
	const [anchorEl, setAnchorEl] = useState(null);
	const [expanded, setExpanded] = useState(-1);
	const totalResidual = useStoreState(state => state.budget.list.totals.residual);
	const totalAmount = useStoreState(state => state.budget.list.totals.amount);
	const load = useStoreActions(dispatch => dispatch.budget.list.load);

	const open = Boolean(anchorEl);
	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = _ => {
		setAnchorEl(null);
	};
	const onAccordionChange = index => {
		expanded === index ? setExpanded(-1) : setExpanded(index);
	};
	const props = {
		handleClose
	};

	useEffect(() => {
		//Scroll top
		if (expanded === -1) window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [items]);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh', width: '100%' }}
		>
			<Paper variant="stickyTop">
				<Stack
					direction={{ xs: 'row', md: 'row', sm: 'row' }}
					justifyContent="space-between"
					alignItems="center"
					spacing={1}
					variant="wrapperColor"
				>
					<Typography variant="titlePage">Budget</Typography>
					<Button variant="dark" endIcon={<Add />} onClick={handleOpen}>
						{translate('common.newBudget')}
					</Button>
				</Stack>
				{loading && <LinearProgress />}
			</Paper>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				PaperProps={{ sx: { width: { md: '30%', xs: '100%', sm: '70%' } } }}
			>
				<NewBudget {...props} />
			</Popover>

			<Grid container spacing={2}>
				{!iCan('OPERATE_ON_BUDGETS') && (
					<Grid container item xs={12} justifyContent="flex-end" style={{ marginTop: 16, marginBottom: 16 }}>
						<Typography variant="body1">
							Totale di tutti i budgets: <strong>{utils.formatPrice(totalAmount)}</strong>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Totale residuo:{' '}
							<strong>{utils.formatPrice(totalResidual)}</strong>
						</Typography>
					</Grid>
				)}

				{keys(items).map(index => (
					<Grid item xs={12} key={`budget_accordion_${index}`} sx={{ marginTop: theme.spacing(2) }}>
						<Accordion
							TransitionProps={{ unmountOnExit: true }}
							expanded={expanded === index}
							onChange={_ => onAccordionChange(index)}
							variant="listing"
							sx={{ borderColor: expanded == index ? theme.palette.custom.greyborder : 'none' }}
						>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography variant="titleAccordion">
									{index} - {items[index].username}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{expanded === index && (
									<Grid container spacing={2}>
										{items[index].userBudgets.length > 0 &&
											items[index].userBudgets.map((item, budgetIndex) => (
												<Grid item xs={12} md={6} lg={3} key={`budget_${budgetIndex}`}>
													<SingleBudget budget={item} />
												</Grid>
											))}
										{items[index].userBudgets.length === 0 && (
											<Grid item xs={12} md={6} lg={3}>
												<Typography variant="body1">Non ci sono budgets</Typography>
											</Grid>
										)}
									</Grid>
								)}
							</AccordionDetails>
						</Accordion>
					</Grid>
				))}
			</Grid>
		</Stack>
	);
};

export default Budget7;
