import React, { useEffect, Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { ToggleButtonGroup, ToggleButton, Stack, Box, Typography, LinearProgress } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import AdjustIcon from '@mui/icons-material/Adjust';
import utils from 'components/utils';
import styles from './styles';

const TabBar = ({ onChange, classes }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const menuOpen = useStoreState(state => state.menu.mainOpen);
	const tabSelected = useStoreState(state => state.conditionRequest.tabSelected);
	const setTabSelected = useStoreActions(dispatch => dispatch.conditionRequest.setTabSelected);
	const loadPendingCount = useStoreActions(dispatch => dispatch.conditionRequest.pendingList.getCount);
	const pendingCount = useStoreState(state => state.conditionRequest.pendingList.count);
	const acceptedCount = useStoreState(state => state.conditionRequest.acceptedList.count);
	const acceptedInCartCount = useStoreState(state => state.conditionRequest.acceptedList.inCartCount);
	const loadAcceptedCount = useStoreActions(dispatch => dispatch.conditionRequest.acceptedList.getCount);
	const loadAcceptedInCartCount = useStoreActions(dispatch => dispatch.conditionRequest.acceptedList.getInCartCount);
	const refusedCount = useStoreState(state => state.conditionRequest.refusedList.count);
	const refusedInCartCount = useStoreState(state => state.conditionRequest.refusedList.inCartCount);
	const loadRefusedCount = useStoreActions(dispatch => dispatch.conditionRequest.refusedList.getCount);
	const loadRefusedInCartCount = useStoreActions(dispatch => dispatch.conditionRequest.refusedList.getInCartCount);
	const colorSelected = theme.palette.primary.main;
	const colorNotSelected = theme.palette.custom.greyborder;

	const handleTab = (event, tab) => {
		setTabSelected(tab);
	};

	useEffect(_ => {
		loadPendingCount();
		loadAcceptedCount();
		loadAcceptedInCartCount();
		loadRefusedCount();
		loadRefusedInCartCount();
	}, []);

	const listTab = [
		{
			tab: 0,
			label: translate('wishlist.tabs.pending'),
			count: pendingCount,
			loading: false
		},
		{
			tab: 1,
			label: translate('wishlist.tabs.authorized') + ' / ' + translate('wishlist.tabs.ofWhichConverted'),
			count: `${acceptedCount} / ${acceptedInCartCount}`,
			loading: false
		},
		{
			tab: 2,
			label: translate('wishlist.tabs.refused') + ' / ' + translate('wishlist.tabs.ofWhichConverted'),
			count: `${refusedCount} / ${refusedInCartCount}`,
			loading: false
		}
	];

	return (
		<Stack
			direction={{ xs: 'column', md: 'row', sm: 'row' }}
			justifyContent="flex-stat"
			alignItems="center"
			spacing={1}
			variant="wrapperColor"
			sx={{ padding: { xs: '10px 0 !important', md: '10px 20px !important', sm: '10px !important' } }}
		>
			<Box sx={{ width: { xs: '100%', md: '80%', sm: '100%' } }}>
				<ToggleButtonGroup value={tabSelected} exclusive onChange={handleTab}>
					{listTab.map(item => (
						<ToggleButton
							key={item.tab}
							variant="tab"
							value={item.tab}
							disabled={item.loading || tabSelected == item.tab}
						>
							{tabSelected == item.tab && (
								<AdjustIcon
									sx={{
										color: !item.loading ? colorSelected : colorNotSelected,
										fontSize: { xs: '20px', md: '24px', sm: '24px' }
									}}
								/>
							)}
							{tabSelected != item.tab && (
								<Brightness1Icon
									sx={{ color: colorNotSelected, fontSize: { xs: '20px', md: '24px', sm: '24px' } }}
								/>
							)}
							<Stack
								direction={{ xs: 'column', md: 'row', sm: 'row' }}
								spacing={0}
								justifyContent="flex-start"
								alignItems="flex-start"
							>
								<Typography
									variant="titleTab"
									sx={{ color: tabSelected == item.tab ? colorSelected : theme.palette.custom.black }}
								>
									{item.label + ': '}
								</Typography>
								<Typography
									variant="titleTab"
									sx={{ color: tabSelected == item.tab ? colorSelected : theme.palette.custom.black }}
								>
									{!item.loading ? item.count : ''}
								</Typography>
							</Stack>
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Box>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(TabBar);
