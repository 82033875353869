import search from './search';
import createProposal from './create-proposal';
import getDetails from './get-details';
import update from './update';
import getItems from './get-items';
import importItems from './import-items';
import updateItems from './update-items';
import addUserGroupAbilitation from './add-user-group-abilitation';
import removeUserGroupAbilitation from './remove-user-group-abilitation';
import exportPdf from './export-pdf';
import deleteProposal from './delete-proposal';
import getUserBook2cInfo from './get-user-book2c-info';
import updateItemsPosition from './update-items-position';

export default {
	search,
	createProposal,
	getDetails,
	update,
	getItems,
	importItems,
	updateItems,
	addUserGroupAbilitation,
	removeUserGroupAbilitation,
	exportPdf,
	deleteProposal,
	getUserBook2cInfo,
	updateItemsPosition
};
