import React from 'react';
import { useStoreActions } from 'easy-peasy';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import utils from 'components/utils';

const Delete = ({ item }) => {
	const translate = utils.useI18N();
	const removeFromSelection = useStoreActions(dispatch => dispatch.importt.remove);
	return (
		<Tooltip title={translate('catalog.selection.delete')} placement="top">
			<IconButton color="secondary" onClick={e => removeFromSelection(item.ean)}>
				<ClearIcon color="error" />
			</IconButton>
		</Tooltip>
	);
};

export default Delete;
