import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import {
	Button,
	Grid,
	Typography,
	CircularProgress,
	FormControl,
	Box,
	Select,
	MenuItem,
	TextField,
	Paper,
	Divider,
	Stack,
	IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import utils from 'components/utils';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		width: 750
	},
	modalLabels: {
		marginBottom: theme.spacing()
	},
	dividerAria: {
		margin: theme.spacing(2, 0),
		backgroundColor: '#00529b'
	}
});

const RECModal = ({ classes, item, onClose }) => {
	const theme = useTheme();
	const loading = useStoreState(state => state.wishlist.requestExtraConditions.loadingPromotions);
	const loadingUser = useStoreState(state => state.wishlist.requestExtraConditions.loadingUser);
	const loadingREC = useStoreState(state => state.wishlist.requestExtraConditions.loadingREC);
	const promotions = useStoreState(state => state.wishlist.requestExtraConditions.promotions);
	const user = useStoreState(state => state.wishlist.requestExtraConditions.user);
	const getPromos = useStoreActions(dispatch => dispatch.wishlist.requestExtraConditions.getPromos);
	const getUser = useStoreActions(dispatch => dispatch.wishlist.requestExtraConditions.getUser);
	const selectPromotion = useStoreActions(dispatch => dispatch.wishlist.requestExtraConditions.selectPromotion);
	const requestExtraConditions = useStoreActions(dispatch => dispatch.wishlist.requestExtraConditions.sendRequest);
	const translate = utils.useI18N();
	useEffect(getPromos, [getPromos]);
	useEffect(
		_ => {
			getUser(item.createdBy);
		},
		[getUser, item.createdBy]
	);
	const selectedPromo = promotions.find(p => p.isSelected);
	const selectedPromoId = selectedPromo ? selectedPromo.sapPromoId : '';
	const [notes, setNotes] = useState(selectedPromo ? selectedPromo.notes : '');
	const [textAreaCount, settextAreaCount] = React.useState(100);
	const recalculate = e => {
		const charCount = e.target.value.length;
		const charLeft = 100 - charCount;
		settextAreaCount(charLeft);
	};
	const onSave = _ => {
		requestExtraConditions({
			idWishlist: item.id,
			sapPromoId: selectedPromoId,
			note: notes
		});
	};
	return (
		<Paper className={classes.root}>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{ width: '100%', padding: '10px' }}
			>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: '90%' }}>
						<Typography
							variant="titleSection"
							sx={{ color: theme.palette.primary.main, textTransform: 'capitalize' }}
						>
							{translate('wishlist.modalRequest.requestNewConditions')}
						</Typography>
					</Box>
					<Box sx={{ width: '10%' }}>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Stack>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					sx={{
						width: '100%',
						borderWidth: '1px 0 0 0',
						borderStyle: 'solid',
						borderColor: theme.palette.primary.main,
						paddingTop: '10px',
						marginBottom: '15px'
					}}
				>
					<Typography variant="text" sx={{ color: theme.palette.primary.main }}>
						{item.name}
					</Typography>
				</Stack>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					sx={{ width: '100%' }}
				>
					{loadingUser && <CircularProgress size={16} />}
					{!loadingUser && user && (
						<Stack
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={1}
							sx={{
								width: '100%',
								padding: '10px',
								borderStyle: 'solid',
								borderWidth: '1px',
								borderColor: theme.palette.custom.greyborder,
								backgroundColor: theme.palette.custom.greyback,
								borderRadius: '10px'
							}}
						>
							<Typography
								variant="text"
								sx={{ fontWeight: '600', textTransform: 'uppercase', fontSize: '14px' }}
							>
								{translate('wishlist.modalRequest.userData')}
							</Typography>
							<Stack
								direction="row"
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={1}
								sx={{ width: '100%', marginTop: '25px' }}
							>
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
									spacing={0}
									sx={{ width: '50%' }}
								>
									<Typography variant="text" sx={{ fontWeight: '600' }}>
										{user.displayName}
									</Typography>
									<Typography variant="text" sx={{ fontSize: '13px' }}>
										{translate('wishlist.modalRequest.userName')}
									</Typography>
								</Stack>
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
									spacing={0}
									sx={{ width: '50%' }}
								>
									<Typography variant="text" sx={{ fontWeight: '600' }}>
										{user.commercialEmail}
									</Typography>
									<Typography variant="text" sx={{ fontSize: '13px' }}>
										{translate('wishlist.modalRequest.userEmail')}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction="row"
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={1}
								sx={{ width: '100%', marginTop: '25px' }}
							>
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
									spacing={0}
									sx={{ width: '50%' }}
								>
									<Typography variant="text" sx={{ fontWeight: '600' }}>
										{user.mobile ? user.mobile : '-'}
									</Typography>
									<Typography variant="text" sx={{ fontSize: '13px' }}>
										{translate('wishlist.modalRequest.userMobile')}
									</Typography>
								</Stack>
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
									spacing={0}
									sx={{ width: '50%' }}
								>
									<Typography variant="text" sx={{ fontWeight: '600' }}>
										{user.phone ? user.phone : '-'}
									</Typography>
									<Typography variant="text" sx={{ fontSize: '13px' }}>
										{translate('wishlist.modalRequest.userPhone')}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					)}
				</Stack>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					sx={{ width: '100%', marginTop: '20px' }}
				>
					{loading && <CircularProgress size={16} />}
					{!loading && promotions.length === 0 && (
						<Typography variant="text" sx={{ color: theme.palette.custom.red }}>
							Non ci sono promozioni attive per le wishlist
						</Typography>
					)}
					{!loading && promotions.length > 0 && (
						<FormControl variant="outlined" size="small">
							<Select value={selectedPromoId} onChange={e => selectPromotion(e.target.value)}>
								{promotions.map((item, index) => (
									<MenuItem key={item.sapPromoId} value={item.sapPromoId}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<TextField
						rows={3}
						maxRows={5}
						multiline
						maxLength={100}
						sx={{ width: '100%', resize: 'none' }}
						aria-label="warning message"
						defaultValue={notes}
						onChange={e => {
							setNotes(e.target.value);
							recalculate(e);
						}}
					/>
					<p>Inserire al massimo {textAreaCount} caratteri</p>
				</Stack>
			</Stack>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				sx={{ width: '100%', marginTop: '10px !important' }}
			>
				<Button variant="light" endIcon={<CloseIcon />} onClick={onClose}>
					{translate('common.close')}
				</Button>
				<Button
					variant="dark"
					onClick={_ => onSave(true)}
					endIcon={
						loadingREC ? (
							<CircularProgress size={24} sx={{ color: theme.palette.custom.white }} />
						) : (
							<KeyboardDoubleArrowRightIcon sx={{ color: theme.palette.custom.white }} />
						)
					}
				>
					{translate('wishlist.modalRequest.send')}
				</Button>
			</Stack>
		</Paper>
	);
};

export default withStyles(styles, { withTheme: true })(RECModal);
