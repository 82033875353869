import { action, thunk, thunkOn } from 'easy-peasy';

export default {
	value: 0,
	save: action((state, payload) => {
		state.value = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.notifications
			.count()
			.then(data => {
				actions.save(data);
			})
			.catch(e => {
				console.log(e);
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	reloadOnMarkAsRead: thunkOn(
		(actions, storeActions) => [storeActions.notifications.markAsRead.sendRequest.successType],
		async (actions, target, helpers) => {
			actions.load(actions, null, helpers);
		}
	)
};
