import { useRef, useEffect } from 'react';

const usePrevious = value => {
	const ref = useRef();
	useEffect(_ => {
		ref.current = value;
	});
	return ref.current;
};

export default usePrevious;
