import k from 'k';
import utils from 'services/utils';

export default ({ data, ddt }) => {
	const requestOptions = {
		method: 'POST',
		headers: utils.createHeaders(),
		body: JSON.stringify(data)
	};
	return utils
		.fetch(`${k.endpoints.apis}/order/fcd/CHECK_IN?ddt=${ddt}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
