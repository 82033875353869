import React, { Fragment, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import { red } from '@mui/material/colors';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import utils from 'components/utils';
import styles from './styles';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import Personification from 'components/Personification';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';

const Personify = props => {
	const { classes } = props;
	const [target, setTarget] = useState({});
	const mainOpen = useStoreState(state => state.menu.mainOpen);
	const popperOpen = useStoreState(state => state.me.personify.open);
	const resetAutocomplete = useStoreActions(dispatch => dispatch.me.personify.resetAutocomplete);
	const setStrictSearch = useStoreActions(dispatch => dispatch.me.personify.setStrictSearch);
	const setPopperOpen = useStoreActions(dispatch => dispatch.me.personify.setOpen);
	const [anchorEl, setAnchorEl] = useState(null);
	const previousMainOpen = utils.usePrevious(mainOpen);
	const onPersonifyCloseClick = _ => {
		setPopperOpen(false);
		resetAutocomplete();
		setTarget({});
		setStrictSearch(false);
	};
	useEffect(
		_ => {
			if (setPopperOpen && previousMainOpen !== mainOpen) {
				setPopperOpen(false);
			}
		},
		[mainOpen, previousMainOpen, setPopperOpen]
	);
	const minOpenPadding = {
		paddingLeft: mainOpen ? 32 : 16
	};
	const togglePopperOpen = _ => {
		setPopperOpen(!popperOpen);
	};
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		togglePopperOpen();
		resetAutocomplete();
		setTarget({});
		setStrictSearch(false);
	};
	return (
		<Fragment>
			<ListItem className={classes.listItem} button onClick={handleClick}>
				{!mainOpen && (
					<Tooltip title="personifica" placement="right">
						<ListItemIcon className={classes.listIcon}>
							<SupervisorAccountIcon style={{ color: red[500] }} />
						</ListItemIcon>
					</Tooltip>
				)}
				{mainOpen && (
					<ListItemIcon className={classes.listIcon}>
						<SupervisorAccountIcon style={{ color: red[500] }} />
					</ListItemIcon>
				)}
				<ListItemText primary="Personifica" />
			</ListItem>
			<Hidden smDown>
				<Popper open={popperOpen} anchorEl={anchorEl} style={{ zIndex: 1400 }} placement="right-end" transition>
					<ClickAwayListener onClickAway={onPersonifyCloseClick}>
						<Card className={classes.card} elevation={4}>
							<CardContent className={classes.content} style={minOpenPadding}>
								<Personification
									target={target}
									setTarget={setTarget}
									onPersonifyCloseClick={onPersonifyCloseClick}
								/>
							</CardContent>
						</Card>
					</ClickAwayListener>
				</Popper>
			</Hidden>
			<Hidden mdUp>
				<Drawer
					anchor="bottom"
					onClose={_ => {
						setPopperOpen(false);
					}}
					open={popperOpen}
				>
					<Card className={classes.card} elevation={4}>
						<CardContent className={classes.content}>
							<Personification
								target={target}
								setTarget={setTarget}
								onPersonifyCloseClick={onPersonifyCloseClick}
							/>
						</CardContent>
					</Card>
				</Drawer>
			</Hidden>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Personify);
