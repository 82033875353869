import { action, thunk } from 'easy-peasy';

export default {
	items: [],
	save: action((state, payload) => {
		state.items = [...payload.map((s, i) => ({ ...s, selected: i === 0 }))];
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.notifications
			.listLight()
			.then(data => {
				actions.save(data.items);
			})
			.catch(e => {
				getStoreActions().error('notifications.load');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
