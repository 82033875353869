import k from 'k';
import utils from 'services/utils';

export default idList => {
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify(idList),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/wishlist/items`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
