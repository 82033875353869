import { useStoreState } from 'easy-peasy';
import jwt_decode from 'jwt-decode';

const useFastSales = _ => {
	const userData = useStoreState(state => state.me.data);
	const decoded = userData.bearer ? jwt_decode(userData.bearer) : null;
	const isFastSales =
		decoded &&
		decoded.feature &&
		Array.isArray(decoded.feature) &&
		decoded.feature.some(el => el.includes('FastSales'));
	/*
    if(decoded.feature && Array.isArray(decoded.feature)){
        return decoded.feature.some(el => el.includes("FastSales"));
    } 

    return false;
    */

	return isFastSales;
};

export default useFastSales;
