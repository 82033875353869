export default theme => ({
	root: {
		flexDirection: 'row'
	},
	title: {
		margin: theme.spacing(2, 0)
	},
	subtitle: {
		marginBottom: theme.spacing(1)
	},
	elements: {
		padding: `${theme.spacing(2, 1, 2, 1)} !important`,
		alignSelf: 'center'
	},
	dividerAria: {
		margin: theme.spacing(2, 0)
	},
	container: {
		overflowX: 'hidden'
	},
	noRipple: {
		paddingTop: 7,
		'&:hover': {
			background: 'none'
		}
	},
	alert: {
		marginTop: theme.spacing()
	},
	componentCentered: {
		alignSelf: 'center'
	}
});
