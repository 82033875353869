import k from 'k';
import utils from 'services/utils';

export default ({ number, returnedDepot, notes }) => {
	const requestOptions = {
		method: 'PATCH',
		headers: utils.createHeaders(),
		body: JSON.stringify({ returnedDepot, notes })
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/returns/${number}/REFUSED`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
