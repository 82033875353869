import { action, thunk, computed, thunkOn, actionOn } from 'easy-peasy';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import utils from './utils';
import storeUtils from 'store/utils';

export default {
	operateOnMultipleCA: computed([storeUtils.retrievePermission('OPERATE_ON_MULTIPLE_CA')], d => d),
	loadPage: false,
	setLoadPage: action((state, payload) => {
		state.loadPage = payload;
	}),

	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	resetItems: action((state, payload) => {
		state.items = [];
	}),
	userPaymentAssigned: {},
	uniqueCADepost: [],
	count: computed(state => state.items.reduce((acc, cur) => acc + utils.getTotalItemsInCart(cur), 0)),
	expanded: -1,
	setExpanded: action((state, payload) => {
		state.expanded = payload;
	}),
	scope: '',
	setScope: action((state, payload) => {
		state.scope = payload;
	}),
	resetExpanded: action((state, payload) => {
		state.expanded = -1;
	}),
	fulfillmentFailed: [],
	setFulfillmentFailed: action((state, payload) => {
		//state.fulfillmentFailed = payload.map(f => f.orderNumber === '' ? 0 : f.orderNumber.split(' - ')[0]);
		state.fulfillmentFailed = payload;
	}),
	total: computed(state =>
		state.items
			.map(i1 =>
				i1.orderItems
					.filter(i2 => i2.visible)
					.map(i3 => i3.price * i3.quantityRequired)
					.reduce((a, b) => a + b, 0)
			)
			.reduce((a, b) => a + b, 0)
	),
	load: thunk(utils.getOpen),
	reloadListener: thunkOn(
		(actions, storeActions) => [
			storeActions.cart.create.sendRequest.successType,
			storeActions.order.createMultiple.sendRequest.successType,
			storeActions.order.update.sendRequest.successType,
			storeActions.cart.remove.sendDelete.successType,
			storeActions.ca.list.select
		],
		async (actions, target, helpers) => {
			if (helpers.getState().scope === 'wishlist.transform') return;
			utils.getOpen(actions, {}, helpers);
			actions.resetExpanded();
		}
	),
	save: action((state, payload) => {
		const { fastlinkOrderShipping, items } = payload;
		state.userPaymentAssigned = fastlinkOrderShipping;
		state.items = items.map(item => ({
			...item,
			showWarning: utils.hasWarning(item),
			isDefault: item.id === null && item.purchaseNumber === '' && item.name === '',
			isSAP: item.name !== '' && item.id === null,
			visible: true,
			idForSelection: uuidv4(),
			orderItems: item.orderItems.map(orderItem => ({
				...orderItem,
				cartId: item.id,
				isAutorenew: orderItem.status === 1,
				showDelete: orderItem.status !== 1 && orderItem.deletable,
				rowColor: utils.getRowColor(orderItem),
				expirationDate: orderItem.expirationDate ? moment(orderItem.expirationDate, 'DD-MM-YYYY') : null,
				showWarning: orderItem.expirationDate !== null && orderItem.status !== 2,
				visible: true,
				date: moment(orderItem.date, 'DD-MM-YYYY'),
				isSelected: false
			}))
		}));
	}),
	saveUniqueCADepost: action((state, payload) => {
		const { items } = payload;
		const flatItems = items.flatMap(item => item.orderItems);
		const flatItemsAndDepots = flatItems.flatMap(item => [
			{
				id: item.depot,
				description: item.depotDesc
			}
		]);
		state.uniqueCADepost = flatItemsAndDepots.filter(
			(item, index, arr) => arr.findIndex(elm => elm.description === item.description) === index
		);
	}),
	removeOrderItemOnDelete: actionOn(
		(actions, storeActions) => [storeActions.order.remove.sendDelete.successType],
		(state, target) => {
			const {
				result: {
					item: { code }
				}
			} = target;
			let resetExpanded = false;
			state.items = state.items.map(item => {
				const isRemoved = item.orderItems.findIndex(oi => oi.code === code) !== -1;
				const orderItems = item.orderItems.filter(oi => oi.code !== code);
				const resumeOrder = isRemoved ? utils.calcResumeOrder(orderItems) : { ...item.resumeOrder };

				const hideEmptySAPCart = item?.isSAP && orderItems?.length <= 0;
				if (hideEmptySAPCart) {
					item.visible = false;
					resetExpanded = true;
				}

				return {
					...item,
					orderItems,
					resumeOrder
				};
			});
			if (resetExpanded) state.expanded = -1;
		}
	),
	removeOrderItemOnDeleteItems: actionOn(
		(actions, storeActions) => [storeActions.order.remove.deleteItems.successType],
		(state, target) => {
			const {
				result: {
					item: { code }
				}
			} = target;
			const setCodes = new Set(code.split(','));
			let resetExpanded = false;
			state.items = state.items.map(item => {
				const isRemoved = item.orderItems.findIndex(oi => setCodes.has(oi.code)) !== -1;
				const orderItems = item.orderItems.filter(oi => !setCodes.has(oi.code));
				const resumeOrder = isRemoved ? utils.calcResumeOrder(orderItems) : { ...item.resumeOrder };
				const hideEmptySAPCart = item?.isSAP && orderItems?.length <= 0;
				if (hideEmptySAPCart) {
					item.visible = false;
					resetExpanded = true;
				}

				return {
					...item,
					orderItems,
					resumeOrder
				};
			});
			if (resetExpanded) state.expanded = -1;
		}
	),
	updateFavourite: actionOn(
		(actions, storeActions) => [storeActions.cart.favourite.sendRequest.successType],
		(state, target) => {
			const {
				result: {
					item: { id, favourite, referenceCartId }
				}
			} = target;
			state.items = state.items.map(item => ({
				...item,
				favourite: item.id === id || item.id === referenceCartId ? favourite : false
			}));
		}
	),
	filters: computed([(state, storeState) => storeState.cart.search.filters], f => f),
	search: actionOn(
		(actions, storeActions) => [storeActions.cart.search.performSearch],
		(state, target) => {
			const originalItems = cloneDeep(state.items);
			state.items = utils.searchOpen(originalItems, state.filters);
		}
	),
	resetSearch: actionOn(
		(actions, storeActions) => [storeActions.cart.search.resetSearch],
		(state, target) => {
			state.items = utils.resetSearch(state.items);
		}
	),
	orderBy: 'date',
	orderDirection: 'desc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'desc';
		}
		state.orderBy = payload;
	}),
	resetOnPersonification: actionOn(
		(actions, storeActions) => [storeActions.me.personify.sendRequest.successType],
		(state, target) => {
			state.expanded = -1;
			state.loading = false;
			state.items = [];
			state.scope = '';
			state.orderBy = 'date';
			state.orderDirection = 'desc';
		}
	),
	selectAll: action((state, payload) => {
		state.items = state.items.map(item => ({
			...item,
			orderItems:
				item.id === payload
					? item.orderItems.map(orderItem => ({
							...orderItem,
							isSelected: orderItem.deletable
					  }))
					: item.orderItems
		}));
	}),
	deselectAll: action((state, payload) => {
		state.items = state.items.map(item => ({
			...item,
			orderItems:
				item.id === payload
					? item.orderItems.map(orderItem => ({
							...orderItem,
							isSelected: false
					  }))
					: item.orderItems
		}));
	}),
	toggleSelection: action((state, payload) => {
		const { orderid, code } = payload;
		state.items = state.items.map(item => ({
			...item,
			orderItems:
				item.id === orderid
					? item.orderItems.map(orderItem => ({
							...orderItem,
							isSelected: orderItem.code === code ? !orderItem.isSelected : orderItem.isSelected
					  }))
					: item.orderItems
		}));
	}),
	getFulfillmentFailed: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.cart
			.getFulfillmentFailed()
			.then(data => {
				actions.setFulfillmentFailed(data.items);
			})
			.catch(e => {
				getStoreActions().error('order.fulfillmentInProgess');
			})
			.finally(_ => {
				/*
					Non setto il loading, perchè se ci sono tanti ordini il load non è ancora terminato e 
					si crea un ritardo nella comparizione dell'elenco ordini
				*/
				//actions.setLoading(false);
			});
	})
};
