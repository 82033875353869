import React, { forwardRef } from 'react';
import { withStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end'
	},
	icon: {
		margin: theme.spacing(8),
		width: 24,
		height: 24
	}
});

const PromoIcon = forwardRef((props, ref) => {
	return (
		<SvgIcon
			color="error"
			style={{ fontSize: 20, verticalAlign: 'middle', margin: '0px 8px 0 18px' }}
			viewBox="0 0 16 12.375"
		>
			<path d="M6.624,12.27A3.5,3.5,0,0,1,3.975,8.7L1,8.121V8.5a.5.5,0,1,1-1,0v-5a.5.5,0,1,1,1,0v.352L15,1.13V.5a.5.5,0,0,1,1,0v11a.5.5,0,1,1-1,0v-.658l-4.223-.821A3.5,3.5,0,0,1,6.624,12.27Zm.242-.97A2.5,2.5,0,0,0,9.782,9.827L4.971,8.892A2.5,2.5,0,0,0,6.866,11.3ZM1,4.871V7.1L15,9.824V2.148Z" />
		</SvgIcon>
	);
});

export default withStyles(styles)(PromoIcon);
