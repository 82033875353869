export default theme => ({
	container: {
		maxWidth: 'calc(100vw - 110px)',
		margin: theme.spacing(2, 0),
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100vw'
		}
	},
	singlePromotion: {
		height: 330,
		width: 220,
		cursor: 'pointer'
	},
	promotionTitle: {
		marginBottom: theme.spacing(2),
		textAlign: 'center'
	}
});
