import React, { memo } from 'react';
import utils from 'components/utils';
import StyledTableCell from 'components/Table/StyledTableCell';
import Tooltip from '@mui/material/Tooltip';

const PositionComp = ({ position, index }) => {
	const translate = utils.useI18N();
	return (
		<Tooltip title={translate('rankings.tables.positionText')}>
			<StyledTableCell>{position}</StyledTableCell>
		</Tooltip>
	);
};

const Position = memo(
	({ item, index }) => <PositionComp position={item.position} index={index} />,
	(p, n) => p.item.position === n.item.position
);

export default Position;
