import { action, thunk, computed, thunkOn } from 'easy-peasy';
import merge from 'lodash/merge';

const search = (item, token) => {
	const fields = ['catalog', 'author', 'title', 'editorDesc', 'note'];
	return fields
		.map(f => item[f].toLowerCase())
		.map(f => f.includes(token))
		.some(f => Boolean(f));
};

export default {
	modalDetails: false,
	modalDetailsDepotStatement: false,
	modalDetailsSummaryInvoice: false,
	items: [],
	amountGross: 0,
	amountNet: 0,
	refNum: 0,
	documentNumber: 0,
	docTypeDet: '',
	urlFCD: '',
	setUrlFCD: action((state, payload) => {
		state.urlFCD = payload;
	}),
	status: null,
	setStatus: action((state, payload) => {
		state.status = payload;
	}),
	setEvadibleQt: action((state, payload) => {
		const { catalog, amount } = payload;
		state.items = state.items.map(item => ({
			...item,
			evadibleQt: item.catalog === catalog ? amount : item.evadibleQt,
			checked: item.catalog === catalog ? false : item.checked,
			color: item.catalog === catalog ? 'white' : item.color
		}));
	}),
	untouched: computed(state => state.items.some(item => !item.isAdded && !item.checked)),
	itemsForCheck: computed(state =>
		state.items.map((item, index) => ({
			ean: item.catalog,
			position: index,
			evadible: item.evadibleQt,
			request: item.requestQt
		}))
	),
	checkRow: action((state, payload) => {
		const getColor = item => {
			if (item.evadibleQt === item.requestQt) return '#dcffe7'; // green
			if (item.evadibleQt === 0) return '#ffdcdc'; // red
			if (item.evadibleQt !== item.requestQt) return '#fff9dc'; // yellow
		};
		state.items = state.items.map(item => ({
			...item,
			color: item.catalog === payload ? getColor(item) : item.color,
			checked: item.catalog === payload ? true : item.checked
		}));
	}),
	search: action((state, payload) => {
		state.items = state.items.map(item => ({
			...item,
			visible: search(item, payload)
		}));
	}),
	resetSearch: action((state, payload) => {
		state.items = state.items.map(item => ({
			...item,
			visible: true
		}));
	}),
	zeroRow: action((state, payload) => {
		state.items = state.items.map(item => ({
			...item,
			color: item.catalog === payload ? '#ffdcdc' : item.color,
			checked: item.catalog === payload ? true : item.checked,
			evadibleQt: item.catalog === payload ? 0 : item.evadibleQt
		}));
	}),
	addNewItem: action((state, payload) => {
		state.items = [
			...state.items,
			{
				author: '',
				catalog: payload.catalog,
				evadibleQt: payload.evadibleQt,
				requestQt: '',
				editorDesc: '',
				grosspr: '',
				grossprui: '',
				netprui: '',
				note: '',
				title: '',
				isAdded: true,
				visible: true
			}
		];
	}),
	removeItem: action((state, payload) => {
		state.items = state.items.filter(item => item.catalog !== payload);
	}),
	resetAllRows: action((state, payload) => {
		state.items = state.items
			.filter(item => !item.isAdded)
			.map(item => ({
				...item,
				evadibleQt: item.deliveredQuantity,
				requestQt: item.deliveredQuantity,
				color: 'white',
				checked: false,
				visible: true
			}));
	}),
	openModal: action((state, payload) => {
		state.modalDetails = payload;
	}),
	openModalDepotStatement: action((state, payload) => {
		state.modalDetailsDepotStatement = payload;
	}),
	openModalSummaryInvoice: action((state, payload) => {
		state.modalDetailsSummaryInvoice = payload;
	}),
	closeAllModals: action((state, payload) => {
		state.modalDetails = false;
		state.modalDetailsDepotStatement = false;
		state.modalDetailsSummaryInvoice = false;
	}),
	save: action((state, payload) => {
		const { data, refNum, documentNumber, docTypeDet } = payload;
		state.docTypeDet = docTypeDet;
		state.refNum = refNum;
		state.documentNumber = documentNumber;
		state.amountGross = data.totalGross;
		state.amountNet = data.totalNet;
		state.items = data.items.map(item => ({
			...item,
			isSelected: false,
			evadibleQt: item.deliveredQuantity,
			requestQt: item.deliveredQuantity,
			color: 'white',
			checked: false,
			visible: true
		}));
		state.totalResults = data.totalItems;
	}),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	getDetails: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.documents
			.details(payload)
			.then(data => {
				actions.save({ data, refNum: payload.refNum, docTypeDet: payload.docTypeDet });
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('documents.errorSearch');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	getDetailsRiba: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.documents
			.detailsRiba(payload)
			.then(data => {
				actions.save({ data, documentNumber: payload.documentNumber });
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('documents.errorSearch');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	loadingCheck: false,
	setLoadingCheck: action((state, payload) => {
		state.loadingCheck = payload;
	}),
	checkin: false,
	setCheckin: action((state, payload) => {
		state.checkin = payload;
	}),
	checkout: false,
	setCheckout: action((state, payload) => {
		state.checkout = payload;
	}),
	reconcileWithCache: action((state, payload) => {
		merge(state.items, payload);
	}),
	getCheckIOStatus: thunkOn(
		(actions, storeActions) => [actions.getDetails.successType],
		async (actions, target, helpers) => {
			const {
				injections: { services }
			} = helpers;
			return services.documents
				.getCheckIOStatus(target.payload.refNum)
				.then(data => {
					if (data.type === 'checkin') actions.setCheckin(true);
					else if (data.type === 'checkout') actions.setCheckout(true);
					actions.reconcileWithCache(data.items);
				})
				.catch(e => console.log(e));
		}
	),
	saveCheckIOStatus: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		const { services } = injections;
		return services.documents
			.saveCheckIOStatus({ type: payload, items: getState().items, ref: getState().refNum })
			.then(data => Promise.resolve(data))
			.catch(e => console.log(e));
	}),
	deleteCheckIOStatus: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		const { services } = injections;
		return services.documents
			.deleteCheckIOStatus(getState().refNum)
			.then(data => Promise.resolve(data))
			.catch(e => console.log(e));
	}),
	doCheckin: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingCheck(true);
		return services.documents
			.checkin({ data: getState().itemsForCheck, ddt: getStoreState().documents.search.savedNumber })
			.then(data => {
				actions.closeAllModals();
				getStoreActions().success('documents.checkin');
			})
			.catch(e => getStoreActions().error('documents.checkin'))
			.finally(_ => actions.setLoadingCheck(false));
	}),
	doCheckout: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingCheck(true);
		return services.documents
			.checkout({ data: getState().itemsForCheck, ddt: getStoreState().documents.search.savedNumber })
			.then(data => {
				actions.setUrlFCD('91');
				getStoreActions().success('documents.checkout');
			})
			.catch(e => getStoreActions().error('documents.checkout'))
			.finally(_ => actions.setLoadingCheck(false));
	})
};
