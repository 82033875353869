import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const styles = theme => ({
	footerText: {
		'& p': {
			margin: 0
		}
	},
	modalPaper: {
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '50%',
		height: 300,
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	}
});

const CtaModal = ({ classes, modalMessage, modalColor }) => {
	const theme = useTheme();
	const setModalOpen = useStoreActions(dispatch => dispatch.cms.promotionSlide.setModalOpen);
	return (
		<Paper className={classes.modalPaper} style={{ backgroundColor: modalColor }}>
			<Grid container item xs={12} justify="flex-end" style={{ maxHeight: 24 }}>
				<IconButton
					size="small"
					style={{ right: theme.spacing(-2) }}
					className={classes.noRipple}
					onClick={_ => {
						setModalOpen(false);
					}}
				>
					<CloseIcon />
				</IconButton>
			</Grid>
			<Grid item container xs={12} justify="center" style={{ height: '85%', alignContent: 'center' }}>
				<Typography variant="body1">
					<span className={classes.footerText} dangerouslySetInnerHTML={{ __html: modalMessage }}></span>
				</Typography>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles, { withTheme: true })(CtaModal);
