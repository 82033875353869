export default theme => ({
	card: {
		maxWidth: 300,
		margin: 'auto',
		transition: '0.3s'
	},
	content: {
		textAlign: 'left',
		padding: theme.spacing(1, 0, 0, 0),
		'&:last-child': {
			paddingBottom: 0
		},
		'& a': {
			paddingLeft: theme.spacing(1.5)
		}
	},
	iconButton: {
		paddingLeft: 0
	},
	expandIcon: {
		paddingRight: 0
	}
});
