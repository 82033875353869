import { action, thunk } from 'easy-peasy';

export default {
	loadingPdf: false,
	setLoadingPdf: action((state, payload) => {
		state.loadingPdf = payload;
	}),
	openPdf: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingPdf(true);
		services.me
			.getTermsConditions()
			.then(data => {})
			.catch(e => {
				getStoreActions().error('error');
			})
			.finally(_ => actions.setLoadingPdf(false));
	})
};
