import { action, thunk } from 'easy-peasy';

export default {
	open: false,
	setOpen: action((state, payload) => {
		state.open = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.BOnotifications.send(payload)
			.then(data => {
				if (data.errors) {
					getStoreActions().errorCustom(data.message);
				} else {
					actions.setOpen(false);
					getStoreActions().success('BOnotifications.send');
				}
			})
			.catch(e => {
				getStoreActions().error('BOnotifications.send');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
