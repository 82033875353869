import { useStoreState } from 'easy-peasy';

const usePermissions = _ => {
	const permissions = useStoreState(state => state.me.permissions);
	const personify = useStoreState(state => state.me.personify);
	const check = (grant, forceLogged) => {
		if (personify.target.id !== '' && !forceLogged) {
			return personify.target.userPermission[grant];
		} else {
			return permissions && permissions[grant];
		}
	};
	return check;
};

export default usePermissions;
