import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { Stack, Grid, FormControlLabel, Switch, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import utils from 'components/utils';
import { margin } from '@mui/system';

const styles = theme => ({
	container: {
		userSelect: 'none',
		padding: theme.spacing(1),
		margin: 0,
		[theme.breakpoints.down('sm')]: {
			padding: 0
		}
	}
});

const ColumnItem = props => {
	const { classes, item, provided, snapshot, onChange, isLast } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const getItemStyle = (isDragging, draggableStyle, item) => ({
		marginRight: isLast ? 0 : theme.spacing(),
		background: item.enabled ? '#A2FAA1' : '#FA5F42',
		...draggableStyle
	});
	return (
		<Stack
			direction={{ xs: 'column', md: 'row', sm: 'column' }}
			justifyContent="flex-start"
			alignItems="center"
			spacing={1}
			//className={classes.container}
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
			sx={{
				backgroundColor: item.enabled ? '#A2FAA1' : '#FA5F42',
				margin: '2px'
			}}
		>
			<Stack
				direction={{ xs: 'row', md: 'column', sm: 'row' }}
				justifyContent={{ xs: 'flex-start', md: 'center', sm: 'flex-start' }}
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					padding: { xs: '4px 0', md: '10px', sm: '10px 0' }
				}}
			>
				<Switch checked={item.enabled} disabled={!item.editable} onChange={_ => onChange(item)} />
				<Typography variant="text">{translate(item.label)}</Typography>
			</Stack>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(ColumnItem);
