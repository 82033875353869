import { action, thunk } from 'easy-peasy';

export default {
	loadingPdf: false,
	setLoadingPdf: action((state, payload) => {
		state.loadingPdf = payload;
	}),
	pdf: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const params = getStoreState().catalog.search.last;
		actions.setLoadingPdf(true);
		services.catalog
			.exportPdf(params)
			.catch(e => {
				getStoreActions().error('catalog.export');
			})
			.finally(_ => actions.setLoadingPdf(false));
	}),
	loadingXls: false,
	setLoadingXls: action((state, payload) => {
		state.loadingXls = payload;
	}),
	xls: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const params = getStoreState().catalog.search.last;
		actions.setLoadingXls(true);
		services.catalog
			.exportXls(params)
			.catch(e => {
				getStoreActions().error('catalog.export');
			})
			.finally(_ => actions.setLoadingXls(false));
	})
};
