import React from 'react';
import { useStoreActions } from 'easy-peasy';
import Chip from '@mui/material/Chip';
import utils from 'components/utils';

const NormalChip = ({ filter, reset }) => {
	const translate = utils.useI18N();
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const resetSingleFilter = reset;
	const getLabel = filter => {
		if (filter.value === true) return translate(`catalog.filters.${filter.label}`);
		else return `${translate(`catalog.filters.${filter.label}`)}: ${filter.value}`;
	};
	return (
		<Chip
			size="small"
			label={getLabel(filter)}
			onDelete={_ => {
				resetSingleFilter(filter.label); //-> dall'esterno è passato resetSingleFilterNoTrigger per impostare il numero di righe a 25 con setStep
				setStep(25);
			}}
		/>
	);
};

export default NormalChip;
