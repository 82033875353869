import { action } from 'easy-peasy';
import pendingList from './pending-list';
import acceptedList from './accepted-list';
import refusedList from './refused-list';
import details from './details';
import approve from './approve';
import approveMultiple from './approve-multiple';
import rejectMultiple from './reject-multiple';
import updateMultiple from './update-multiple';
import reject from './reject';
import update from './update';
import filters from './filters';
import promotions from './promotions';

export default {
	tabSelected: 0,
	setTabSelected: action((state, payload) => {
		state.tabSelected = payload;
	}),
	details,
	pendingList,
	acceptedList,
	refusedList,
	approve,
	reject,
	update,
	approveMultiple,
	rejectMultiple,
	updateMultiple,
	filters,
	promotions
};
