import k from 'k';
import utils from 'services/utils';

export default wishlistId => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders(),
		responseType: 'blob'
	};
	return utils
		.fetch(`${k.endpoints.apis}/wishlist/export/xls/${wishlistId}`, requestOptions)
		.then(response => response.blob())
		.then(response => {
			const file = new Blob([response], { type: 'application/vnd.ms-excel' });
			var reader = new FileReader();
			reader.onload = e => {
				const link = document.createElement('a');
				link.href = e.target.result;
				link.download = 'search-results.xls';
				link.dispatchEvent(
					new MouseEvent('click', {
						bubbles: true,
						cancelable: true,
						view: window
					})
				);
				setTimeout(_ => {
					window.URL.revokeObjectURL(e.target.result);
					link.remove();
				}, 100);
			};
			reader.readAsDataURL(file);
		})
		.catch(error => {
			console.log(error);
		});
};
