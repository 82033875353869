import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import TableBodyMUI from '@mui/material/TableBody';
import StyledTableRow from 'components/Table/StyledTableRow';
import StyledTableCell from 'components/Table/StyledTableCell';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import utils from 'components/utils';
import Delete from './table/Delete';
import NewsItem from './table/NewsItem';
import Depot from './table/Depot';
import Stock from './table/Stock';

const TableBody = props => {
	const theme = useTheme();
	const items = useStoreState(state => state.importt.items);
	const getStyle = type => {
		if (type === 100) {
			return {
				backgroundColor: '#D4AF37'
			};
		}
	};
	return (
		<TableBodyMUI>
			{items.map((item, index) => (
				<StyledTableRow key={`selectionmodal_row_${item.ean}`} style={getStyle(item.type)}>
					<StyledTableCell>
						<Delete item={item} />
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 140, maxWidth: 140, width: 140 }}>{item.ean}</StyledTableCell>
					<StyledTableCell style={{ minWidth: 140, maxWidth: 140, width: 140 }}>
						{utils.capitalizeAll(item.author)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 20, maxWidth: 20, width: 20 }}>
						{item.type === 200 && (
							<CardGiftcardIcon sx={{ color: theme.palette.primary.main, fontSize: '20px' }} />
						)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 240, maxWidth: 240, width: 240 }}>
						{utils.capitalize(item.title)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 100, maxWidth: 100, width: 100 }}>
						{utils.capitalize(item.serie)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 90, maxWidth: 90, width: 90 }}>
						{utils.capitalize(item.publisher)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 70, maxWidth: 70, width: 70 }}>
						{utils.formatPrice(item.fullPrice)}
					</StyledTableCell>
					<NewsItem item={item} />
					<Depot item={item} />
					<Stock item={item} />
				</StyledTableRow>
			))}
		</TableBodyMUI>
	);
};

export default TableBody;
