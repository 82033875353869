import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	results: [],
	uploaderModalOpen: false,
	setUploaderModalOpen: action((state, payload) => {
		state.uploaderModalOpen = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.editorials
			.importt(payload)
			.then(data => {
				actions.setUploaderModalOpen(false);
				if (data.items.length === 0) {
					getStoreActions().error('coupons.importError');
				} else {
					actions.save(data.items);
					getStoreActions().editorials.detailsItems.setData(data.items);
					getStoreActions().success('coupons.import');
					return Promise.resolve(data);
				}
			})
			.catch(e => {})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	save: action((state, payload) => {
		state.results = payload.map(item => ({
			...item,
			isSelected: false
		}));
	}),
	toggleSelection: action((state, payload) => {
		const i = state.results.findIndex(itm => itm.id === payload);
		if (i === -1) return;
		const item = state.results[i];
		item.isSelected = !item.isSelected;
		state.results = state.results
			.slice(0, i)
			.concat(item)
			.concat(state.results.slice(i + 1));
	}),
	selectAll: action((state, payload) => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: true
		}));
	}),
	deselectAll: action((state, payload) => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: false
		}));
	})
};
