import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import parse from 'html-react-parser';
import {
	Box,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
	Grid,
	CircularProgress,
	Button
} from '@mui/material';
import utils from 'components/utils';
import styles from 'routes/HomeV2/styles.css';

const WPPosts = props => {
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.wordpress.loading);
	const posts = useStoreState(state => state.wordpress.posts);
	const loadPosts = useStoreActions(dispatch => dispatch.wordpress.loadPosts);
	const noImg = 'https://www.fastbookspa.it/wp-content/themes/fastbook/assets/img/contents/blog/sfondo-testata.jpg';

	const formatPostDate = data => {
		if (!data) return data;
		const d = data.split('-');
		return d[2] + '/' + d[1] + '/' + d[0];
	};

	useEffect(() => {
		if (!posts || posts.length == 0) loadPosts(4);
	}, [posts]);

	return (
		<Box sx={{ marginTop: '20px' }}>
			<Grid container style={{ marginBottom: '10px' }}>
				<Grid item xs={8} md={10} sm={9}>
					<Typography className="sectionTitle">
						Fastbooker <span className="secondary">Blog</span>
					</Typography>
				</Grid>
				<Grid item xs={4} md={2} sm={3} style={{ textAlign: 'right' }}>
					<Button className="sectionLink" href="https://www.fastbookspa.it/index.php/blog/" target="_blank">
						{translate('home.gotoblog') + '>>'}
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ width: '100%' }}>
				{loading && (
					<Box style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
						<CircularProgress size={44} style={{ color: '#00539b' }} />
					</Box>
				)}
				{!loading &&
					posts.map((post, index) => (
						<Grid item xs={12} md={3} sm={6} key={index}>
							<Card>
								<CardActionArea href={post.link} target="_blank">
									<CardMedia
										className="mediaImg"
										image={post.mediaLink ? post.mediaLink : noImg}
										title={parse(post.title.rendered)}
									/>
								</CardActionArea>
								<CardContent style={{ padding: '0' }}>
									<Box className="mediaImgMeta">
										<Typography>{formatPostDate(post.date)}</Typography>
									</Box>
									<Box style={{ padding: '20px' }} className="postBox">
										<Typography className="postTitle">
											<a href={post.link} target="_blank">
												{parse(post.title.rendered)}
											</a>
										</Typography>
										{parse(
											post.excerpt.rendered.replace(
												'class="read-more"',
												'class="read-more" target="_blank"'
											)
										)}
									</Box>
								</CardContent>
							</Card>
						</Grid>
					))}
			</Grid>
		</Box>
	);
};

export default WPPosts;
