import React from 'react';
import Sortable from './Sortable';
import NotSortable from './NotSortable';

const allCols = [
	{
		id: 'catalog',
		comp: _ => <Sortable id="catalog" key="headCell_ean_documents" label="documents.tables.ean" />
	},
	{
		id: 'documentNumber',
		comp: _ => <NotSortable id="documentNumber" key="headCell_number_docs" label="documents.tables.docNumber" />
	},
	{
		id: 'documentDate',
		comp: _ => <NotSortable id="documentDate" key="headCell_date_docs" label="documents.tables.docDate" />
	},
	{
		id: 'type',
		comp: _ => <NotSortable id="type" key="headCell_type_docs" label="documents.tables.docType" />
	},
	{
		id: 'amount',
		comp: _ => <NotSortable id="amount" key="headCell_amount_docs" label="documents.tables.docAmount" />
	},
	{
		id: 'giveOrTake',
		comp: _ => <NotSortable id="giveOrTake" key="headCell_operation_docs" label="documents.tables.operation" />
	},
	{
		id: 'title',
		comp: _ => <Sortable id="title" key="headCell_title_documents" label="documents.tables.title" />
	},
	{
		id: 'author',
		comp: _ => <Sortable id="author" key="headCell_author_documents" label="documents.tables.author" />
	},
	{
		id: 'editorDesc',
		comp: _ => <Sortable id="editorDesc" key="headCell_editor_documents" label="documents.tables.editor" />
	},
	{
		id: 'quantity',
		comp: _ => (
			<Sortable id="deliveredQuantity" key="headCell_quantity_documents" label="documents.tables.quantity" />
		)
	},
	{
		id: 'quantityEvadible',
		comp: _ => (
			<NotSortable
				id="quantityEvadible"
				key="headCell_quantityEvadible_documents"
				label="documents.tables.quantityReceived"
			/>
		)
	},
	{
		id: 'quantityReceived',
		comp: _ => (
			<NotSortable
				id="quantityReceived"
				key="headCell_quantityReceived_documents"
				label="documents.tables.quantityReceived"
			/>
		)
	},
	{
		id: 'quantityIndeliveryNote',
		comp: _ => (
			<NotSortable
				id="quantityIndeliveryNote"
				key="headCell_quantityIndeliveryNote_documents"
				label="documents.tables.quantityIndeliveryNote"
			/>
		)
	},
	{
		id: 'quantityToKeep',
		comp: _ => (
			<NotSortable
				id="quantityToKeep"
				key="headCell_quantityToKeep_documents"
				label="documents.tables.quantityToKeep"
			/>
		)
	},
	{
		id: 'amount',
		comp: _ => <Sortable id="amount" key="headCell_amount_documents" label="documents.tables.amount" />
	},
	{
		id: 'grossprui',
		comp: _ => <Sortable id="grossprui" key="headCell_gross_documents" label="documents.tables.gross" />
	},
	{
		id: 'discount1',
		comp: _ => <Sortable id="discount1" key="headCell_discount1_documents" label="documents.tables.discount1" />
	},
	{
		id: 'discount2',
		comp: _ => <Sortable id="discount2" key="headCell_discount2_documents" label="documents.tables.discount2" />
	},
	{
		id: 'netprui',
		comp: _ => <Sortable id="netprui" key="headCell_net_documents" label="documents.tables.net" />
	},
	{
		id: 'note',
		comp: _ => <Sortable id="note" key="headCell_note_documents" label="documents.tables.note" />
	},
	{
		id: 'number',
		comp: _ => <Sortable id="docNumRif" key="headCell_docNumRif_documents" label="documents.tables.docNumRif" />
	},
	{
		id: 'date',
		comp: _ => <Sortable id="docDateRif" key="headCell_docDateRif_documents" label="documents.tables.docDateRif" />
	},
	{
		id: 'customer',
		comp: _ => (
			<Sortable
				id="recipientName"
				key="headCell_recipientName_documents"
				label="documents.tables.recipientName"
			/>
		)
	},
	{
		id: 'summaryInvoice',
		comp: _ => (
			<Sortable
				id="summaryInvoice"
				key="headCell_summaryInvoice_documents"
				label="documents.tables.summaryInvoice"
			/>
		)
	},
	{
		id: 'amountSI',
		comp: _ => <Sortable id="grossAmount" key="headCell_amountSI_documents" label="documents.tables.amount" />
	}
];

export default allCols;
