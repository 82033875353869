import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import utils from 'components/utils';

const TextArea = ({ notes }) => {
	const value = useStoreState(state => state.returns.tabSelected);
	const currentDetail = useStoreState(state => state.returns.currentDetail);
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const setCurrentDetailNotes = useStoreActions(dispatch => dispatch.returns.setCurrentDetailNotes);
	const showTextArea = iCan('MANAGE_RETURNS') && currentDetail.type === 'pending';
	return (
		<Fragment>
			{showTextArea && (
				<TextareaAutosize
					maxRows={5}
					minRows={5}
					style={{ width: '100%', resize: 'none', height: '100%' }}
					aria-label="warning message"
					placeholder={translate('returns.detail.notes')}
					defaultValue={notes}
					onChange={e => {
						setCurrentDetailNotes(e.target.value);
					}}
					disabled={value !== 0}
				/>
			)}
			{!showTextArea && (
				<span
					dangerouslySetInnerHTML={{ __html: notes ? `${translate('returns.detail.notes')}: ${notes}` : '' }}
				></span>
			)}
		</Fragment>
	);
};

export default TextArea;
