import { action, thunk, computed, actionOn } from 'easy-peasy';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import utils from './utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	trackingModalOpen: false,
	setTrackingModalOpen: action((state, payload) => {
		state.trackingModalOpen = payload;
	}),
	trackingShippingNumber: 0,
	setTrackingShippingNumber: action((state, payload) => {
		state.trackingShippingNumber = payload;
	}),
	openFromURl: true,
	setOpenFromUrl: action((state, payload) => {
		state.openFromURl = payload;
	}),
	items: [],
	count: computed(state => state.items.reduce((acc, cur) => acc + utils.getTotalItemsInCart(cur), 0)),
	tooltip: false,
	openTooltip: action((state, payload) => {
		state.tooltip = payload;
	}),
	expanded: -1,
	setExpanded: action((state, payload) => {
		state.expanded = payload;
	}),
	total: computed(state =>
		state.items
			.map(i1 =>
				i1.orderItems
					.filter(i2 => i2.visible)
					.map(i3 => i3.netPrice * i3.quantityRequired)
					.reduce((a, b) => a + b, 0)
			)
			.reduce((a, b) => a + b, 0)
	),
	load: thunk(utils.getProcessing),
	save: action((state, payload) => {
		state.items = payload.items.map(item => ({
			...item,
			visible: true,
			isDefault: item.id === null && item.purchaseNumber === '',
			isSAP: item.name !== '' && item.id === null,
			orderItems: item.orderItems.map(orderItem => ({
				...orderItem,
				visible: true,
				date: moment(orderItem.date, 'DD-MM-YYYY')
			}))
		}));
	}),
	filters: computed([(state, storeState) => storeState.cart.search.filters], f => f),
	search: actionOn(
		(actions, storeActions) => [storeActions.cart.search.performSearch],
		(state, target) => {
			const originalItems = cloneDeep(state.items);
			state.items = utils.searchProcessing(originalItems, state.filters);
		}
	),
	resetSearch: actionOn(
		(actions, storeActions) => [storeActions.cart.search.resetSearch],
		(state, target) => {
			state.items = utils.resetSearch(state.items);
		}
	),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	resetOnPersonification: actionOn(
		(actions, storeActions) => [storeActions.me.personify.sendRequest.successType],
		(state, target) => {
			state.expanded = -1;
			state.loading = false;
			state.items = [];
			state.orderBy = 'title';
			state.orderDirection = 'desc';
		}
	)
};
