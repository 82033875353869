import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Chip from '@mui/material/Chip';
import utils from 'components/utils';

const PromotionChip = ({ filter }) => {
	const translate = utils.useI18N();
	const promotions = useStoreState(state => state.catalog.promotions.list);
	//const resetSingleFilter = useStoreActions(dispatch => dispatch.catalog.filters.resetSingle);
	const resetSingleNoTrigger = useStoreActions(dispatch => dispatch.catalog.filters.resetSingleNoTrigger);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const selectPromotion = useStoreActions(dispatch => dispatch.catalog.promotions.select);
	const promo = promotions.find(s => s.sapPromoId === filter.value);

	return (
		<Fragment>
			{promo && (
				<Chip
					size="small"
					label={`${translate(`catalog.promotions`)}: ${promo.name}`}
					onDelete={_ => {
						//resetSingleFilter(filter.label); -> tolto per impostare il numero di righe a 25 con setStep
						resetSingleNoTrigger(filter.label);
						selectPromotion('');
						setStep(25);
					}}
				/>
			)}
		</Fragment>
	);
};

export default PromotionChip;
