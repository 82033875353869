import React from 'react';
import Id from './Id';
import SapId from './SapId';
import SapName from './SapName';
import Enabled from './Enabled';
import EnabledCatalog from './EnabledCatalog';
import EnabledWishlist from './EnabledWishlist';
import ApprovalRequest from './ApprovalRequest';
import StartDate from './StartDate';
import EndDate from './EndDate';
import Edit from './Edit';

const allCells = [
	{
		id: 'id',
		comp: ({ item }) => <Id key={`bodyCell_id_${item.id}`} item={item} />
	},
	{
		id: 'sapId',
		comp: ({ item }) => <SapId key={`bodyCell_sapId_${item.id}`} item={item} />
	},
	{
		id: 'sapName',
		comp: ({ item }) => <SapName key={`bodyCell_sapName_${item.id}`} item={item} />
	},
	{
		id: 'enabled',
		comp: ({ item }) => <Enabled key={`bodyCell_enabled_${item.id}`} item={item} />
	},
	{
		id: 'enabledCatalog',
		comp: ({ item }) => <EnabledCatalog key={`bodyCell_enabledCatalog_${item.id}`} item={item} />
	},
	{
		id: 'enabledWishlist',
		comp: ({ item }) => <EnabledWishlist key={`bodyCell_enabledWishlist_${item.id}`} item={item} />
	},
	{
		id: 'approvalRequest',
		comp: ({ item }) => <ApprovalRequest key={`bodyCell_approvalRequest_${item.id}`} item={item} />
	},
	{
		id: 'startDate',
		comp: ({ item }) => <StartDate key={`bodyCell_startDate_${item.id}`} item={item} />
	},
	{
		id: 'endDate',
		comp: ({ item }) => <EndDate key={`bodyCell_endDate_${item.id}`} item={item} />
	},
	{
		id: 'edit',
		comp: ({ item }) => <Edit key={`bodyCell_edit_${item.id}`} item={item} />
	}
];

export default allCells;
