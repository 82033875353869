import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import moment from 'moment';

const DateComp = ({ text }) => {
	return <StyledTableCell>{moment(text).format('L')}</StyledTableCell>;
};

const Datee = memo(
	({ item }) => <DateComp text={item.date} />,
	(p, n) => p.item.date === n.item.date
);

export default Datee;
