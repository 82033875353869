import getItems from './get-items';
import getAllItems from './get-all-items';
import update from './update';
import deletee from './delete';
import getLastItem from './get-last-item';

export default {
	getItems,
	getAllItems,
	getLastItem,
	update,
	deletee
};
