import k from 'k';
import utils from 'services/utils';

export default (base, id, fileName) => {
	const file = {
		file: base,
		fileName: fileName
	};

	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify(file),
		headers: utils.createHeaders()
	};

	return utils
		.fetch(`${k.endpoints.apis}/backoffice/catalog-groups/coupons/` + id + '/file', requestOptions)
		.then(response => response);
};
