export default theme => ({
	container: {
		maxWidth: 'calc(100vw - 110px)',
		textAlign: 'center',
		marginBottom: theme.spacing(4),
		margin: theme.spacing(4, 0),
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100vw'
		}
	},
	footerText: {
		'& p': {
			margin: 0
		}
	},
	cta: {
		maxHeight: 40,
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.6rem'
		}
	}
});
