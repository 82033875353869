const drawerWidth = 200;

export default theme => ({
	root: {
		display: 'flex'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		maxHeight: 64,
		minHeight: 64,
		height: 64
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap'
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1
	},
	drawerCloseHidden: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: 0,
		border: 0
	},
	toolbar: {
		padding: theme.spacing(0, 2),
		...theme.mixins.toolbar,
		[theme.breakpoints.down('sm')]: {
			minHeight: 64
		}
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			padding: 0
		}
	},
	pageTitle: {
		flexGrow: 1
	}
});
