import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import utils from 'components/utils';

const AcceptButton = ({ item }) => {
	const loadingAccept = useStoreState(state => state.returns.respond.loadingAccept);
	const currentDetail = useStoreState(state => state.returns.currentDetail);
	const accept = useStoreActions(dispatch => dispatch.returns.respond.accept);
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const onAccept = _ => {
		accept(currentDetail.id);
	};
	if (!iCan('MANAGE_RETURNS')) return '';
	return (
		currentDetail.type === 'pending' && (
			<Button
				style={{ marginRight: 8 }}
				disabled={item.depot.depotCode === '-1' || loadingAccept}
				variant="dark"
				onClick={onAccept}
			>
				{translate('returns.detail.accept')}
			</Button>
		)
	);
};

export default AcceptButton;
