import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const BodyComp = ({ body }) => {
	return <StyledTableCell>{body}</StyledTableCell>;
};

const Body = memo(
	({ item }) => <BodyComp body={item.body} />,
	(p, n) => p.item.body === n.item.body
);

export default Body;
