const getOpen = (actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
	const { services } = injections;
	let ca = null;

	if (getState().operateOnMultipleCA) {
		const listCA = getStoreState().ca.list.items;
		const selected = listCA.find(i => i.selected);
		if (!selected) return Promise.resolve();
		ca = selected.id;
	}

	actions.setLoading(true);
	actions.resetItems();
	services.cart
		.getOpen(ca)
		.then(data => {
			actions.save(data);
			actions.saveUniqueCADepost(data);
		})
		.catch(e => {
			if (e.name !== 'AbortError') getStoreActions().error('cart.retrieve');
		})
		.finally(_ => {
			actions.setLoading(false);
		});
};

export default getOpen;
