import { useEffect } from 'react';
import utils from 'utils';

const Logout = props => {
	useEffect(_ => {
		window.localStorage.clear();
		const pkce = utils.pkce();
		window.localStorage.setItem('codeVerifier', pkce.codeVerifier);
		window.localStorage.setItem('codeChallenge', pkce.codeChallenge);
		utils.goToLogin();
	}, []);
	return '';
};

export default Logout;
