import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useState } from 'react';
import { useTheme } from '@mui/styles';
import parse from 'html-react-parser';
import {
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemSecondaryAction,
	Grid,
	Typography,
	Box,
	CircularProgress,
	ListItemText,
	IconButton,
	Popover,
	Button,
	Tooltip
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import utils from 'components/utils';
import moment from 'moment';
import { Close } from '@mui/icons-material';

const HealthInfo = props => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const loading = useStoreState(state => state.health.loading);
	const healthInfo = useStoreState(state => state.health.healthInfo);
	const loadHealtInfo = useStoreActions(dispatch => dispatch.health.loadHealtInfo);
	const [listInfo, setListInfo] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [infoDett, setInfoDett] = useState(null);
	const open = Boolean(anchorEl);

	const statusMap = {
		OK: <CheckCircleIcon style={{ color: '#006600', fontSize: '40' }} />,
		KO: <ErrorIcon style={{ color: '#ff0000', fontSize: '40' }} />,
		DOWN: <WarningIcon style={{ color: '#D9B00D', fontSize: '40' }} />
	};

	const colorMap = {
		OK: '#006600',
		KO: '#ff0000',
		DOWN: '#D9B00D'
	};

	useEffect(() => {
		loadHealtInfo();
	}, []);

	useEffect(() => {
		let newList = [];
		healthInfo.map((health, index) => {
			const items = newList.filter(item => item.scope == health.scope);
			if (items.length == 0) newList = newList.concat(health);
			else {
				if (items[0].status == 'OK' && health.status != 'OK') {
					newList = newList.filter(h => h.id != items[0].id).concat(health);
				}
			}
		});
		setListInfo(newList);
	}, [healthInfo]);

	const handleClick = (event, info) => {
		setInfoDett(info);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Grid
			container
			sx={{
				marginBottom: '10px',
				marginTop: { xs: '0', md: '20px', sm: '0' },
				padding: '15px'
			}}
		>
			<Grid item xs={12} md={12} sm={12}>
				<Typography className="sectionTitle">
					FastLink <span className="secondary">Health</span>
				</Typography>
			</Grid>
			<Grid item xs={12} md={12} sm={12}>
				{loading && (
					<Box style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
						<CircularProgress size={44} style={{ color: '#00539b' }} />
					</Box>
				)}
				{!loading && (
					<Box style={{ width: '100%' }}>
						<List>
							{listInfo.map((info, index) => (
								<ListItem
									key={index}
									style={{
										marginTop: '10px',
										borderBottom: index !== listInfo.length - 1 && '1px solid #ccc',
										marginBottom: '-10px'
									}}
								>
									<ListItemAvatar>{statusMap[info.status]}</ListItemAvatar>
									<ListItemText
										primary={
											<Typography
												style={{
													fontWeight: 500,
													fontSize: '15px',
													marginBottom: '-5px',
													marginTop: '-10px'
												}}
											>
												{translate('health.' + info.label)}
											</Typography>
										}
										secondary={
											<Typography
												style={{
													color: colorMap[info.status],
													fontWeight: '500',
													marginBottom: '-10px'
												}}
											>
												{info.title}
											</Typography>
										}
									/>
									<ListItemSecondaryAction style={{ width: '36px' }}>
										{info.status != 'OK' && (
											<Tooltip title={translate('health.viewdetail')}>
												<IconButton
													onClick={event => {
														handleClick(event, info);
													}}
												>
													<InfoIcon
														style={{ color: theme.palette.primary.main, fontSize: '36px' }}
													/>
												</IconButton>
											</Tooltip>
										)}
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'center',
								horizontal: 'left'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							<Grid container style={{ padding: '15px', maxWidth: '350px' }}>
								<Grid item xs={2}>
									{infoDett ? statusMap[infoDett.status] : null}
								</Grid>
								<Grid item xs={8}>
									<Typography style={{ fontSize: '18px', fontWeight: '500', lineHeight: '1.2em' }}>
										{infoDett ? translate('health.' + infoDett.label) : ''}
									</Typography>
									<Typography
										style={{
											fontSize: '17px',
											fontWeight: '500',
											lineHeight: '1.4em',
											color: infoDett ? colorMap[infoDett.status] : '#000'
										}}
									>
										{infoDett ? infoDett.title : ''}
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<IconButton onClick={handleClose}>
										<Close />
									</IconButton>
								</Grid>
								<Grid item xs={12} style={{ marginTop: '10px' }}>
									<Typography style={{ fontSize: '16px', lineHeight: '1.2em', fontWeight: '400' }}>
										{infoDett ? infoDett.description : ''}
									</Typography>
								</Grid>
								<Grid item xs={12} style={{ marginTop: '10px' }}>
									<Typography
										style={{
											fontSize: '16px',
											lineHeight: '1.2em',
											fontWeight: '400'
										}}
									>
										<span style={{ fontWeight: '500', paddingRight: '5px' }}>
											{translate('health.datefrom')}:
										</span>
										{infoDett ? moment(infoDett.datefrom).format('L HH:mm') : ''}
									</Typography>
								</Grid>
								<Grid item xs={12} style={{ marginTop: '5px' }}>
									<Typography
										style={{
											fontSize: '16px',
											lineHeight: '1.2em',
											fontWeight: '400'
										}}
									>
										<span style={{ fontWeight: '500', paddingRight: '5px' }}>
											{translate('health.dateto')}:
										</span>
										{infoDett && infoDett.dateto
											? moment(infoDett.dateto).format('L HH:mm')
											: translate('health.datemissing')}
									</Typography>
								</Grid>
								{/* <Grid item xs={12} style={{ marginTop: '20px', textAlign: 'right' }}>
									<Button
										style={{ border: '1px solid #00539b', background: '#fff', color: '#00539b' }}
										onClick={handleClose}
									>
										{translate('common.close')}
									</Button>
								</Grid> */}
							</Grid>
						</Popover>
					</Box>
				)}
			</Grid>
		</Grid>
	);
};

export default HealthInfo;
