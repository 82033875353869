import k from 'k';
import utils from 'services/utils';

export default (idCa, { page, step, keyword }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = {
		page,
		rows: step,
		keyword
	};
	if (keyword) params.keyword = keyword;
	const qParams = utils.createQueryParams(params, false);
	return utils
		.fetch(`${k.endpoints.apis}/centralPurchasing/${idCa}/users/available?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
