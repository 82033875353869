import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import styles from './styles';
import clsx from 'clsx';
import utils from 'components/utils';
import Delete from './Delete';

const DeleteRankings = ({ classes, close, showCloseIcon }) => {
	const deleteRanking = useStoreActions(dispatch => dispatch.rankings.deleteRankings.deleteRanking);
	const modalOpen = useStoreState(state => state.rankings.deleteRankings.modalDeleteOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.rankings.deleteRankings.openModalDelete);
	const translate = utils.useI18N();

	return (
		<div
			className={clsx(classes.negateMargin, {
				[classes.margin]: showCloseIcon
			})}
		>
			<Modal
				className={classes.modalPosition}
				open={modalOpen}
				onClose={_ => setModalOpen(false)}
				aria-labelledby="shipping-types-modal"
				aria-describedby="shipping-types-modal"
			>
				<div className="modal-content">{modalOpen && <Delete />}</div>
			</Modal>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(DeleteRankings);
