export default theme => ({
	root: {
		paddingTop: theme.spacing(3),
		border: '1px solid black'
	},
	button: {
		margin: theme.spacing()
	},
	title: {
		borderTop: '1px solid black',
		borderBottom: '1px solid black',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		paddingTop: theme.spacing(),
		paddingBottom: theme.spacing()
	},
	coverImg: {
		height: '100%',
		width: '100%',
		objectFit: 'contain',
		objectPosition: 'top'
	},
	infoContainer: {
		padding: theme.spacing(0, 2)
	},
	noPrint: {
		'@media print': {
			display: 'none !important'
		}
	},
	fontButtons: {
		'& svg': {
			fontSize: 32
		}
	}
});
