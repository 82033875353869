import React, { useEffect, useState, useCallback } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import utils from 'components/utils';

const FilterBarSapInfo = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const show = utils.useUserData('showSapInfo', false);
	const update = useStoreActions(dispatch => dispatch.me.updateSapInfo.sendRequest);

	return (
		<FormGroup>
			<FormControlLabel
				label={translate('orders.viewOrdersRow')}
				control={
					<Switch
						checked={show}
						onChange={e => {
							update({ target: e.target.checked, successMessage: '' });
						}}
						sx={{ color: theme.palette.primary.main }}
					/>
				}
			/>
		</FormGroup>
	);
};

export default FilterBarSapInfo;
