import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import styles from '../styles';
import TableCell from '@mui/material/TableCell/TableCell';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

const OneColumnTab = ({ loadingList, getCbStatus, toggleSelection, item }) => {
	return (
		<Fragment>
			<TableCell align="left" style={{ width: 25 }}>
				{loadingList.indexOf(item.id) === -1 && (
					<Checkbox
						color="primary"
						checked={getCbStatus(item.id)}
						onChange={e => {
							toggleSelection(item.id, e.target.checked);
						}}
					/>
				)}
				{loadingList.indexOf(item.id) !== -1 && <CircularProgress size={16} />}
			</TableCell>
		</Fragment>
	);
};
export default withStyles(styles, { withTheme: true })(OneColumnTab);
