import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import utils from 'components/utils';

const SelectDate = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const currentDate = useStoreState(state => state.cart.shipped.selectedDate);
	const setCurrentDate = useStoreActions(dispatch => dispatch.cart.shipped.setSelectedDate);
	const loadShippedCarts = useStoreActions(dispatch => dispatch.cart.shipped.load);
	const minDate = moment().subtract(1, 'years');

	const setShippedDate = date => {
		//Setto la data inserita dall'utente
		setCurrentDate(date);
		//Se la data è valida ricarico gli ordini
		if (moment(date).isSameOrAfter(minDate, 'day')) {
			loadShippedCarts();
		}
	};

	useEffect(() => {
		//Scroll top
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [currentDate]);

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DatePicker
				disableFuture
				label={translate('orders.shippedOrderFrom')}
				//views={['day']}
				value={currentDate}
				minDate={minDate}
				className="date-picker"
				onChange={date => setShippedDate(date)}
			/>
		</LocalizationProvider>
	);
};

export default SelectDate;
