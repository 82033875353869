import React from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const Stock = ({ item }) => {
	const depot = item.depots.find(d => d.id === item.selection.depot.id);
	const stock = depot ? depot.stock : 0;
	return (
		<StyledTableCell style={{ textAlign: 'center', minWidth: 50, maxWidth: 50, width: 50 }}>
			{stock}
		</StyledTableCell>
	);
};

export default Stock;
