import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const AmountComp = ({ amount }) => {
	return <StyledTableCell>{utils.formatPrice(amount)}</StyledTableCell>;
};

const Amount = memo(
	({ item }) => <AmountComp amount={item.amount} />,
	(p, n) => p.item.amount === n.item.amount
);

export default Amount;
