import React, { useEffect, useState, forwardRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { useHistory, useParams, matchPath } from 'react-router-dom';
import { Stack, Typography, Box, Accordion, AccordionDetails, AccordionSummary, Dialog, Slide } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenOrdersAccordionSummary from './OpenOrdersAccordionSummary';
import OpenOrdersAccordionDetails from './OpenOrdersAccordionDetails';
import Details from 'routes/Catalog/Details';
import utils from 'components/utils';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const OpenOrders = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const history = useHistory();
	const params = useParams();
	const items = useStoreState(state => state.cart.open.items);
	const openCount = useStoreState(state => state.cart.open.count);
	const openTotal = useStoreState(state => state.cart.open.total);
	const listCA = useStoreState(state => state.ca.list.items);
	const expanded = useStoreState(state => state.cart.open.expanded);
	const setExpanded = useStoreActions(dispatch => dispatch.cart.open.setExpanded);
	const openModal = useStoreState(state => state.order.create.openModal);
	const setOpenModal = useStoreActions(dispatch => dispatch.order.create.setOpenModal);
	const [currentCart, setCurrentCart] = useState();
	const [selectedItem, setSelectedItem] = useState(null);
	const [item, setItem] = useState();
	const visibleItems = items.filter(item => item.visible);

	const openLabel =
		iCan('OPERATE_ON_MULTIPLE_CA') && listCA.length > 0
			? translate('orders.orderOpen') - listCA.find(i => i.selected).name
			: translate('orders.orderOpen');
	const title =
		translate('orders.orders') +
		' ' +
		openLabel +
		': ' +
		openCount +
		' ' +
		translate('orders.orders') +
		' - ' +
		utils.formatPrice(openTotal);

	const handleChange = index => (event, isExpanded) => {
		setExpanded(isExpanded ? index : -1);
	};

	const expandItem = id => {
		setExpanded(-1);
		items.forEach((value, index) => {
			if (value.idForSelection == id) {
				setExpanded(index);
			}
		});
	};

	useEffect(() => {
		if (items.length > 0 && history.location.pathname.includes('/orders/list/details')) {
			const match = matchPath(history.location.pathname, {
				path: '/orders/list/details/:orderCode/:idForSelection/:catalog',
				exact: true,
				strict: false
			});

			if (match.params.idForSelection && match.params.catalog) {
				expandItem(match.params.idForSelection);
				const itemData = items.find(value => value.idForSelection == match.params.idForSelection);
				setCurrentCart(itemData);
				if (itemData) {
					const ord = itemData.orderItems.filter(
						val => val.catalog === match.params.catalog && val.code.slice(0, 10) === match.params.orderCode
					);
					setItem(ord[0]);
				}
				setSelectedItem(match.params.catalog);
				setOpenModal(true);
			}
		}
		// eslint-disable-next-line
	}, [history, params, items, setOpenModal]);

	const onClose = () => {
		if (history.location.pathname.includes('/orders/list')) {
			setOpenModal(false);
			history.push('/orders/list');
		}
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0.5}
			variant="wrapperSection"
			sx={{ padding: { xs: '20px 5px', md: '20px', sm: '20px 10px' } }}
		>
			<Typography variant="titleSection" sx={{ marginLeft: '20px' }}>
				{title}
			</Typography>
			<Box sx={{ width: '100%' }}>
				{visibleItems.map((item, index) => (
					<Accordion
						key={index}
						variant="listing"
						expanded={expanded == index}
						onChange={handleChange(index)}
						sx={{ borderColor: expanded == index ? theme.palette.custom.greyborder : 'none' }}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							id="panel-header"
							sx={{ flexDirection: 'row-reverse' }}
						>
							<OpenOrdersAccordionSummary order={item} />
						</AccordionSummary>
						<AccordionDetails>
							{expanded === index && <OpenOrdersAccordionDetails order={item} />}
						</AccordionDetails>
					</Accordion>
				))}
			</Box>

			{/* Modale dettaglio */}
			<Dialog fullScreen open={openModal} onClose={onClose} TransitionComponent={Transition}>
				<Details
					itemOrder={item}
					id_ean={selectedItem}
					currentCart={currentCart}
					showClose={true}
					urlComponet="/orders/list"
				/>
			</Dialog>
		</Stack>
	);
};

export default OpenOrders;
