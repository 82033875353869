import React from 'react';
import get from 'lodash/get';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import CheckboxMUI from '@mui/material/Checkbox';
import utils from 'components/utils';

const Checkbox = props => {
	const translate = utils.useI18N();
	const path = get(props, 'path');
	const user = get(props, 'user', {});
	const disabled = get(props, 'disabled', false);
	const label = translate(`user.${path}`);
	const onChange = get(props, 'onChange', _ => {});
	const value = get(user, path, false);
	const handleChange = event => {
		onChange({ target: { name: path, value: !value } });
	};
	return (
		<FormGroup aria-label="position" row>
			<FormControlLabel
				checked={value}
				disabled={disabled}
				control={<CheckboxMUI color="primary" onChange={handleChange} />}
				label={label}
				labelPlacement="end"
			/>
		</FormGroup>
	);
};

export default Checkbox;
