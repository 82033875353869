export default theme => ({
	titleContainer: {
		marginLeft: 16
	},
	withPaddingTop8: {
		paddingTop: theme.spacing()
	},
	withPaddingTop20: {
		paddingTop: 20
	},
	menuOpen: {
		display: 'none'
	}
});
