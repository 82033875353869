import k from 'k';
import utils from 'services/utils';

export default ({ codes, ca }) => {
	const requestOptions = {
		method: 'POST',
		headers: utils.createHeaders(),
		body: JSON.stringify(codes)
	};
	const idCA = ca ? `/?idCA=${ca}` : '';
	return utils
		.fetch(`${k.endpoints.apis}/order/stock${idCA}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
