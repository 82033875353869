import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Card from 'components/Card';
import Loader from 'components/Loader';
import utils from 'components/utils';
import TableHead from './components/Table/TableHead';
import TableBody from './components/Table/TableBody';
import SearchButton from './components/SearchButton';
import AddPurchasingCenter from './components/AddPurchasingCenter';
import DeletePurchasingCentersButton from './components/DeletePurchasingCentersButton';
import Filters from './components/Filters';
import styles from './styles';
import CustomNoResult from './components/CustomNoResults';
import AddNewPurchasingCenter from './components/AddNewPurchasingCenter';
import DeletePurchasingCenters from './components/DeletePurchasingCenters';
import Chip from '@mui/material/Chip';
import { useHistory } from 'react-router-dom';

const PurchasingCenters = ({ classes }) => {
	const translate = utils.useI18N();
	const searchOpen = useStoreState(state => state.purchasings.filters.searchOpen);
	const addNewOpen = useStoreState(state => state.purchasings.add.open);
	const setSearchOpen = useStoreActions(dispatch => dispatch.purchasings.filters.setSearchOpen);
	const setAddOpen = useStoreActions(dispatch => dispatch.purchasings.add.setOpen);
	const loading = useStoreState(state => state.purchasings.list.loading);
	const results = useStoreState(state => state.purchasings.list.items);
	const filters = useStoreState(state => state.purchasings.filters.list);
	const resetToken = useStoreActions(dispatch => dispatch.purchasings.filters.resetToken);
	const resetStatus = useStoreActions(dispatch => dispatch.purchasings.filters.resetStatus);
	const load = useStoreActions(dispatch => dispatch.purchasings.list.load);
	const resetDetails = useStoreActions(dispatch => dispatch.purchasings.details.resetDetails);
	const resetDetailsItems = useStoreActions(dispatch => dispatch.purchasings.detailsItems.resetDetailsItems);
	const resetSearch = useStoreActions(dispatch => dispatch.purchasings.assignUsers.resetSearch);
	const leftGridMdWidth = searchOpen || addNewOpen ? 7 : 12;
	const items = results.filter(r => r.isSelected);
	const history = useHistory();
	// prettier-ignore
	useEffect(_ => { load() }, [load]);
	// prettier-ignore
	useEffect(_ => { resetDetails() }, [resetDetails]);
	// prettier-ignore
	useEffect(_ => { resetDetailsItems() }, [resetDetailsItems]);
	// prettier-ignore
	useEffect(_ => { resetSearch() }, [resetSearch]);

	useEffect(() => {
		if (history.location.pathname.includes('/admin/purchasingcenters/search')) setSearchOpen(true);
		if (history.location.pathname.includes('/admin/purchasingcenters/add')) setAddOpen(true);
	}, [history, setSearchOpen, setAddOpen]);

	return (
		<Fragment>
			<Grid item xs={12}>
				<Card type="primary" title={translate('common.menu.purchasingGroups')}>
					<Grid container spacing={3}>
						<Grid item container xs={12}>
							<SearchButton />
							<AddPurchasingCenter />
							<DeletePurchasingCentersButton active={items.length === 0} />
							{filters.token !== '' && (
								<Chip
									style={{ marginRight: 8, marginTop: 6 }}
									size="small"
									label={filters.token}
									onDelete={resetToken}
								/>
							)}
							{filters.statusValue && (
								<Chip
									style={{ marginTop: 6 }}
									size="small"
									label={filters.statusValue ? translate('purchasing.tables.status') + ' attivo' : ''}
									onDelete={resetStatus}
								/>
							)}
						</Grid>
						<Grid item container xs={12}>
							{loading && (
								<Grid
									item
									container
									xs={12}
									justify="center"
									spacing={3}
									style={{ padding: '2px, 1px, 2px, 1px' }}
								>
									<Loader />
								</Grid>
							)}
							{/* SEARCH RESULTS */}
							<Grid item container xs={12} md={leftGridMdWidth}>
								{results.length > 0 && (
									<TableContainer component={Paper} style={{ maxHeight: 800 }}>
										<Table size="small" stickyHeader aria-label="purchasing table">
											<TableHead />
											<TableBody rows={results} />
										</Table>
									</TableContainer>
								)}
							</Grid>
							{/* NO RESULTS */}
							{!loading && results.length === 0 && (
								<Grid item container xs={12} md={leftGridMdWidth} justify="center">
									<CustomNoResult />
								</Grid>
							)}
							{/* DESKTOP FILTERS */}
							{searchOpen && (
								<Grid item md={5} className={classes.elements}>
									<Filters />
								</Grid>
							)}

							{/* DESKTOP FILTERS */}
							{addNewOpen && (
								<Grid item md={5} className={classes.elements}>
									<AddNewPurchasingCenter />
								</Grid>
							)}
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<DeletePurchasingCenters />
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(PurchasingCenters);
