import { action, thunk } from 'easy-peasy';

export default {
	addOpen: false,
	setAddOpen: action((state, payload) => {
		state.addOpen = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.userGroups
			.createUserGroup(payload)
			.then(data => {
				getStoreActions().success('userGroups.add');
				getStoreActions().userGroups.list.load();
				getStoreActions().userGroups.addUserGroup.setAddOpen(false);
				return Promise.resolve(data.items);
			})
			.catch(e => {
				getStoreActions().error('userGroups.errorAdd');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
