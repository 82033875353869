import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemLink from '../components/ListItemLink';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import Loader from 'components/Loader';
import Card from 'components/Card';
import utils from 'components/utils';
import styles from './styles';

const WithdrawTypes = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.depots.loading);
	const depots = useStoreState(state => state.depots.data);
	const load = useStoreActions(dispatch => dispatch.depots.load);
	// prettier-ignore
	useEffect(_ => load('SHIPPING'), [load]);
	const translateTypes = types => {
		if (types.length === 0) return '-';
		const translated = types.map(type => type.typeDesc);
		return translated.join(' ,');
	};
	return (
		<Fragment>
			{loading && (
				<Grid item container xs={12} justifyContent="center" spacing={3} className={classes.elements}>
					<Loader />
				</Grid>
			)}
			{!loading && (
				<Grid item xs={12}>
					<Card type="primary" title={translate('admin.withdrawTypes.title')} style={{ marginTop: 0 }}>
						<Grid item container xs={12}>
							<TableContainer component={Paper}>
								<Table aria-label="manage evasion" size="small">
									<TableHead>
										<TableRow>
											<TableCell>{translate('admin.withdrawTypes.depotCode')}</TableCell>
											<TableCell>{translate('admin.withdrawTypes.depotName')}</TableCell>
											<TableCell>{translate('admin.withdrawTypes.types')}</TableCell>
											<TableCell>&nbsp;</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{depots.map((depot, index) => (
											<TableRow key={`depot_cell_${depot.code}`}>
												<TableCell align="left">{depot.code}</TableCell>
												<TableCell align="left">{utils.capitalizeAll(depot.name)}</TableCell>
												<TableCell align="left">{translateTypes(depot.typologies)}</TableCell>
												<TableCell align="left" style={{ width: 25 }}>
													<ListItemLink
														to={`/admin/withdraw-types/${depot.code}`}
														button
														className={classes.noRipple}
													>
														<Tooltip
															title={translate('admin.withdrawTypes.setWithdrawalType')}
															placement="top"
														>
															<ListItemIcon>
																<EditIcon />
															</ListItemIcon>
														</Tooltip>
													</ListItemLink>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Card>
				</Grid>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(WithdrawTypes);
