import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import { Stack, Typography } from '@mui/material';
import utils from 'components/utils';

const WishlistSumCostComp = ({ totalItems, totalPrice }) => {
	const translate = utils.useI18N();
	return (
		<Stack>
			<Stack direction="column" justifyContent="flex-end" alignItems="center" spacing={0} sx={{ width: '100%' }}>
				<Typography variant="text" sx={{ fontWeight: '600' }}>
					{totalItems + ' / ' + utils.formatPrice(totalPrice)}
				</Typography>
				<Typography variant="text" sx={{ fontSize: '13px', textTransform: 'lowercase' }}>
					{translate('wishlist.summary.quantity') + ' / ' + translate('common.total')}
				</Typography>
			</Stack>
		</Stack>
	);
};

const WishlistSumCost = memo(
	({ item }) => <WishlistSumCostComp totalItems={item.totalItems} totalPrice={item.totalPrice} />,
	(p, n) => p.item.totalPrice === n.item.totalPrice && p.item.totalItems === n.item.totalItems
);

export default WishlistSumCost;
