import { action, actionOn, thunk, thunkOn } from 'easy-peasy';
import utils from './utils';

export default {
	list: [],
	select: action((state, payload) => {
		const newScopes = state.list.map(s => ({
			...s,
			selected: s.id === payload
		}));
		const newlySelectedIndex = newScopes.findIndex(item => item.selected);
		const newlySelected = newScopes.find(item => item.selected);
		if (newlySelected.children.length > 0) {
			newlySelected.children = newlySelected.children.map((item, index) => ({
				...item,
				selected: index === 0
			}));
		}
		state.list = newScopes
			.slice(0, newlySelectedIndex)
			.concat(newlySelected)
			.concat(state.list.slice(newlySelectedIndex + 1));
	}),
	selectSub: action((state, payload) => {
		const selectedScopeIndex = state.list.findIndex(s => s.selected);
		const selectedScope = state.list.find(s => s.selected);
		if (!selectedScope) return;
		selectedScope.children = selectedScope.children.map(s => ({
			...s,
			selected: s.id === payload
		}));
		state.list = state.list
			.slice(0, selectedScopeIndex)
			.concat(selectedScope)
			.concat(state.list.slice(selectedScopeIndex + 1));
	}),
	save: action((state, payload) => {
		state.list = utils.transformScopes(payload.item);
	}),
	loading: false,
	setLoading: actionOn(
		actions => [actions.retrieve.startType],
		state => {
			state.loading = true;
		}
	),
	unsetLoading: actionOn(
		actions => [actions.retrieve.type],
		state => {
			state.loading = false;
		}
	),
	retrieve: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		services.catalog
			.getScopeList()
			.then(data => {
				actions.save(data);
			})
			.catch(e => {
				getStoreActions().error('catalog.scopes');
			});
	}),
	resetScope: thunkOn(
		(actions, storeActions) => [
			storeActions.catalog.filters.reset,
			storeActions.me.personify.sendRequest.successType
		],
		async (actions, target, helpers) => {
			actions.retrieve(actions, null, helpers);
		}
	)
};
