import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Tooltip, Typography, MenuItem, FormControl } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Select from 'components/Select';
import Loader from 'components/Loader';
import head from 'lodash.head';
import utils from 'components/utils';

const DepotSelector = _ => {
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.catalog.search.loading);
	const availableDepots = utils.useFilteredDepots();
	const selectDepotForAll = useStoreActions(dispatch => dispatch.selection.selectDepotForAll);
	const competenceDepot = utils.useUserData('mainDepot', null);
	const depotForAll = useStoreState(state => state.selection.depotForAll);
	const mainDepotIndex = availableDepots.findIndex(d => d.description === competenceDepot.description);
	const mainDepot = availableDepots[mainDepotIndex];
	const orderedDepots = mainDepot
		? [mainDepot, ...availableDepots.slice(0, mainDepotIndex).concat(availableDepots.slice(mainDepotIndex + 1))]
		: availableDepots;
	//Recupero tutti i depositi di tutti gli item selezionati per filtrare i depositi comuni abilitati
	const items = useStoreState(state => state.selection.items);
	//Verifico se tra gli elementi selezionati c'è almeno una card epipoli per gestire deposito e quantità per tutti
	const thereIsEpipoli = items.filter(item => item.type === 200).length > 0;
	//Verifico se ci sono elementi non procurabili per l'avviso sulla selezione del deposito per tutti
	const thereIsNotProcurable = items.filter(item => item.type === 100).length > 0;
	//Elenco dei depositi abilitati
	const enableDepotsList = items.map(item =>
		item.depots.filter(d => (thereIsEpipoli ? d.id === 'FS01' : d.enable)).map(dep => dep.id)
	);
	//Elenco degli id dei depositi abilitati
	const enableDepots = [];
	enableDepotsList.map(item =>
		item.map(el => {
			let insertEl = true;
			enableDepotsList.map(subitem => {
				if (!subitem.includes(el)) insertEl = false;
			});
			if (insertEl && !enableDepots.includes(el)) enableDepots.push(el);
		})
	);

	//Elenco dei depositi da visualizzare nella select box: solo quelli abilitati
	const depotsForSelect = orderedDepots.filter(dep => enableDepots.includes(dep.id));
	//Imposto il valore da selezionare se contenuto tra i depositi validi:
	//valDep è l'ipotetico valore da selezionare
	const valDep = depotForAll ? depotForAll : depotsForSelect.length > 0 ? head(depotsForSelect) : '';
	//Se valDep è incluso tra i depositi da visualizzare lo seleziono altrimenti seleziono il primo abilitato
	const selvalDep = depotsForSelect.length > 0 ? depotsForSelect.filter(dep => dep.id === valDep).map(d => d.id) : [];
	const selval = selvalDep.length > 0 ? selvalDep[0] : depotsForSelect.length > 0 ? head(depotsForSelect).id : '';

	return (
		<Fragment>
			{loading && (
				<Grid item container xs={12} justify="center" spacing={3}>
					<Loader />
				</Grid>
			)}
			{!loading && (
				<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
					<FormControl variant="outlined">
						<Select
							value={selval} //{depotForAll ? depotForAll : head(orderedDepots).id}
							onChange={e => selectDepotForAll(e.target.value)}
							path="depotForAll.select"
						>
							{depotsForSelect.map((item, index) => (
								<MenuItem key={item.id} value={item.id}>
									{item.description}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{(thereIsEpipoli || thereIsNotProcurable) && (
						<Tooltip title={translate('catalog.selection.depotForEveryoneAlert')}>
							<InfoIcon sx={{ color: theme.palette.custom.orange, fontSize: '22px' }} />
						</Tooltip>
					)}
				</Stack>
			)}
		</Fragment>
	);
};

export default DepotSelector;
