import React, { Fragment, useState, useEffect } from 'react';
import utils from 'components/utils';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory, Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import {
	Tooltip,
	IconButton,
	Popper,
	Card,
	CardContent,
	Grid,
	Typography,
	Divider,
	Button,
	Box,
	Stack,
	Popover,
	Slide,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	CircularProgress
} from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import MUIavatar from '@mui/material/Avatar';
import globalUtils from 'utils';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from 'components/Avatar';
import { grey } from '@mui/material/colors';
import take from 'lodash.take';
import Loader from 'components/Loader';

const Profile = props => {
	const { style } = props;
	const translate = utils.useI18N();
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const history = useHistory();
	const loadingOpen = useStoreState(state => state.cart.open.loading);
	const itemsOpen = useStoreState(state => state.cart.open.items);
	const loadOpen = useStoreActions(dispatch => dispatch.cart.open.load);
	const loadingShipped = useStoreState(state => state.cart.shipped.loading);
	const itemsShipped = useStoreState(state => state.cart.shipped.items);
	const loadShipped = useStoreActions(dispatch => dispatch.cart.shipped.load);
	const loadingBudget = useStoreState(state => state.budget.list.loading);
	const itemsBudget = useStoreState(state => state.budget.list.items);
	const loadBudget = useStoreActions(dispatch => dispatch.budget.list.load);
	const loadingDocument = useStoreState(state => state.me.preview.loading);
	const itemsDocument = useStoreState(state => state.me.preview.data);
	const loadDocument = useStoreActions(dispatch => dispatch.me.preview.load);
	const user = useStoreState(state => state.me.data);
	const personifiedName = utils.useUserData('displayName', null);
	const personifiedId = utils.useUserData('id', null);
	const [iAmPersonifying] = utils.usePersonification();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [expanded, setExpanded] = useState(false);
	const accordionStyle = {
		// ':hover': {
		// 	backgroundColor: grey[100],
		// 	transition: 'ease-in-out 0.3s'
		// }
	};
	// const formattedDate = moment.unix(user.lastAccessDate).format('L');
	// const formattedHour = moment.unix(user.lastAccessDate).format('HH:mm');
	const onProfileClick = _ => {
		history.push('/me');
	};
	const onExitClick = _ => {
		setAnchorEl(null);
		globalUtils.logout();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleChangeAccordion = panel => (event, expanded) => {
		setExpanded(expanded ? panel : false);
	};

	useEffect(() => {
		switch (expanded) {
			case 'insertOrderedPanel': {
				loadOpen();
				break;
			}
			case 'lastShippedPanel': {
				loadShipped();
				break;
			}
			case 'documentPanel': {
				loadDocument();
				break;
			}
			case 'budgetPanel': {
				loadBudget();
				break;
			}
			default:
				return;
		}
	}, []);

	return (
		<Box>
			<IconButton onClick={event => setAnchorEl(event.currentTarget)}>
				<Avatar size={30} forceLogged={true} />
			</IconButton>
			<Popover
				TransitionComponent={Slide}
				// sx={{ marginTop: style.distanceFromHeader }}
				disableScrollLock
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				elevation={style.elevation}
				anchorOrigin={{
					vertical: style.anchorOrigin.vertical,
					horizontal: style.anchorOrigin.horizontal
				}}
				transformOrigin={{
					vertical: style.transformOrigin.vertical,
					horizontal: style.transformOrigin.horizontal
				}}
			>
				<Card
					sx={{
						width: '100%',
						height: '100%',
						borderRadius: '8px'
					}}
				>
					<CardContent>
						<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
							<Stack
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
								spacing={1}
								width="100%"
							>
								<Avatar size={70} forceLogged={true} />
								<Stack
									direction="column"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={0.5}
								>
									<Typography variant="titlePage">{`${utils.capitalizeAll(user.displayName)} (${
										user.id
									})`}</Typography>
									<Typography variant="text">
										{utils.capitalizeAll(user.profileDescription)}
									</Typography>
								</Stack>
							</Stack>
							{iCan('PREVIEW_DATA') && !iAmPersonifying && (
								<Stack id="AccordionStack" direction="column" sx={{ width: '100%' }}>
									<Accordion
										TransitionProps={{ unmountOnExit: true }}
										expanded={expanded === 'insertOrderedPanel'}
										onChange={handleChangeAccordion('insertOrderedPanel')}
										sx={accordionStyle}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="titleAccordion">
												{translate('common.insertOrders')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Resume
												resume={{
													id: 'open-ordered',
													items: itemsOpen,
													loading: loadingOpen,
													to: '/orders/list',
													tableHead: { cell1: 'Nome', cell2: 'Data' },
													tableBody: { cell1: 'name', cell2: 'date' }
												}}
											/>
										</AccordionDetails>
									</Accordion>
									<Accordion
										TransitionProps={{ unmountOnExit: true }}
										expanded={expanded === 'lastShippedPanel'}
										onChange={handleChangeAccordion('lastShippedPanel')}
										sx={accordionStyle}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="titleAccordion">
												{translate('common.lastOrders')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Resume
												resume={{
													id: 'last-shipped',
													items: itemsShipped,
													loading: loadingShipped,
													to: '/orders/list',
													tableHead: { cell1: 'Nome', cell2: 'Data' },
													tableBody: { cell1: 'name', cell2: 'date' }
												}}
											/>
										</AccordionDetails>
									</Accordion>
									<Accordion
										TransitionProps={{ unmountOnExit: true }}
										expanded={expanded === 'documentPanel'}
										onChange={handleChangeAccordion('documentPanel')}
										sx={accordionStyle}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="titleAccordion">
												{translate('common.menu.documents')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Fragment>
												<Resume
													resume={{
														id: 'documents',
														items: itemsDocument,
														loading: loadingDocument,
														to: '/documents',
														tableHead: { cell1: 'Nome', cell2: 'Data' },
														tableBody: { cell1: 'name', cell2: 'date' }
													}}
												/>
											</Fragment>
										</AccordionDetails>
									</Accordion>
									{iCan('BUDGET') && (
										<Accordion
											TransitionProps={{ unmountOnExit: true }}
											expanded={expanded === 'budgetPanel'}
											onChange={handleChangeAccordion('budgetPanel')}
											sx={accordionStyle}
										>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography variant="titleAccordion">
													{translate('common.menu.budget')}
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Resume
													resume={{
														id: 'budget',
														items: itemsBudget,
														loading: loadingBudget,
														to: '/budget',
														tableHead: { cell1: 'Nome', cell2: 'Cig' },
														tableBody: { cell1: 'name', cell2: 'cig' }
													}}
												/>
											</AccordionDetails>
										</Accordion>
									)}
								</Stack>
							)}
							{iAmPersonifying && (
								<Box
									sx={{
										width: '100%',
										border: `2px solid ${theme.palette.secondary.main}`,
										borderRadius: '8px',
										padding: '10px 0',
										marginLeft: '20px'
									}}
								>
									<Stack direction="column" justifyContent="center" alignItems="center">
										<Avatar size={40} />
										<Typography variant="titleSection">{`${personifiedName} (${personifiedId})`}</Typography>
									</Stack>
								</Box>
							)}
							<Divider variant="middle" sx={{ color: 'black', width: '100%' }} />
							<Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
								<Button onClick={onProfileClick} variant="light">
									{translate('common.menu.profile')}
								</Button>
								<Button onClick={onExitClick} endIcon={<ExitToAppIcon />} variant="important">
									{translate('common.menu.logout')}
								</Button>
							</Stack>
						</Stack>
					</CardContent>
				</Card>
			</Popover>
		</Box>
	);
};

export default Profile;

const Resume = props => {
	const { resume } = props;
	const translate = utils.useI18N();
	let visibleItems = [];
	if (resume.id !== 'documents') {
		visibleItems = take(
			resume.items.filter(item => item.visible),
			4
		);
	}
	const tableCell1 = item => {
		const tableCellData = '';
		switch (resume.tableBody.cell1) {
			case 'nameCart': {
				tableCellData = item.isDefault ? translate('orders.defaultCart') : item.name;
				break;
			}
		}
		return tableCellData;
	};
	const tableCell2 = item => {
		const tableCellData = '';
		switch (resume.tableBody.cell2) {
			case 'date': {
				tableCellData = item.date;
				break;
			}
			case 'cig': {
				tableCellData = item.cig;
				break;
			}
		}
		return tableCellData;
	};
	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
			{resume.loading && <CircularProgress size={24} />}
			{!resume.loading && visibleItems.length === 0 && resume.id !== 'documents' && (
				<Typography variant="text">Al momento non ci sono elementi</Typography>
			)}
			{!resume.loading && visibleItems.length > 0 && resume.id !== 'documents' && (
				<TableContainer>
					<Table>
						<TableHead>
							<TableCell>{resume.tableHead.cell1}</TableCell>
							<TableCell>{resume.tableHead.cell2}</TableCell>
						</TableHead>
						<TableBody>
							{visibleItems.map((item, index) => (
								<TableRow key={index}>
									<TableCell>
										{item.isDefault ? translate('orders.defaultCart') : item.name}
									</TableCell>
									<TableCell>{tableCell2(item)}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{!resume.loading && resume.id === 'documents' && (
				<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
					<Typography variant="text">
						{resume.items.lastBill ? (
							<strong>Ultima bolla: {resume.items.lastBill.date}</strong>
						) : (
							'Al momento non ci sono bolle'
						)}
					</Typography>
					<Typography variant="text">
						{resume.items.lastInvoice ? (
							<strong>Ultima fattura: {resume.items.lastInvoice.date}</strong>
						) : (
							'Al momento non ci sono fatture'
						)}
					</Typography>
					<Typography variant="text">
						{resume.items.lastCreditNote ? (
							<strong>Ultima nota di credito: {resume.items.lastCreditNote.date}</strong>
						) : (
							'Al momento non ci sono note di credito'
						)}
					</Typography>
					<Typography variant="text">
						{resume.items.lastAccompanyingInvoice ? (
							<strong>Ultima fattura accompagnativa: {resume.items.lastAccompanyingInvoice.date}</strong>
						) : (
							'Al momento non ci sono fatture accompagnative'
						)}
					</Typography>
				</Stack>
			)}
			<Button variant="light" component={Link} to={resume.to}>
				Vedi tutti
			</Button>
		</Stack>
	);
};
