import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const DateeComp = ({ date }) => {
	return <StyledTableCell>{date.format('LLL')}</StyledTableCell>;
};
const Datee = memo(
	({ item }) => <DateeComp date={item.date} />,
	(p, n) => p.item.date === n.item.date
);

export default Datee;
