import k from 'k';
import utils from 'services/utils';

export default ({ id, codeSelected, amca, ownerUserCode }) => {
	const requestOptions = {
		method: 'DELETE',
		headers: utils.createHeaders()
	};

	const codes = codeSelected.map(item => item).join();

	const params = { codes: codes };
	if (amca) params.ownerUserCode = ownerUserCode;
	if (id) params.id = id;
	const qParams = utils.createQueryParams(params, false);

	return utils
		.fetch(`${k.endpoints.apis}/order/deleteItems/?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
