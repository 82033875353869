import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CartSelector from 'components/CartSelector';
import CreateCart from 'routes/Orders7/CreateCart';
import Popover from 'components/Popover';
import utils from 'components/utils';
import styles from '../styles';

const CreateCartStep = ({ classes, items, item }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.wishlist.transform.loading);
	const cartList = useStoreState(state => state.cart.listLight.items);
	const transferType = utils.useUserData('transferType', null);
	const anchorCartEl = useStoreState(state => state.wishlist.transform.anchorCartEl);
	const transform = useStoreActions(dispatch => dispatch.wishlist.transform.sendRequest);
	const setAnchorCartEl = useStoreActions(dispatch => dispatch.wishlist.transform.setAnchorCartEl);
	const openCart = Boolean(anchorCartEl);
	const setCartCreationScope = useStoreActions(dispatch => dispatch.cart.open.setScope);
	const onlyFS01 = items.every(it => it.depotCode === 'FS01');
	const noZ1 = items.every(it => it.noticeCode !== 'z1');
	const noType100 = items.every(it => it.type !== 100);
	// prettier-ignore
	useEffect(_ => { setCartCreationScope('wishlist.transform'); }, [setCartCreationScope]);
	const onSave = ({ fcd }) => {
		let cartId = null;
		const cart = cartList.find(it => it.selected);
		if (item.type === null) {
			cartId = cart.id;
			transform({ wishlistId: item.id, cartId: cartId, fcd });
		} else transform({ wishlistId: item.id, fcd });
	};
	const showFcd = transferType === 'FCD' && !loading && noZ1 && onlyFS01 && noType100;
	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				{item.type === null && (
					<Typography className={classes.modalLabels} variant="subtitle2">
						{translate('wishlist.createNewCart')}
					</Typography>
				)}
				{item.type === 'BOOK2C' && (
					<Typography className={classes.modalLabels} variant="subtitle2">
						{translate('wishlist.createNewCartFromExternalWL')} {item.name}
					</Typography>
				)}
			</Grid>
			{item.type === null && (
				<Grid item xs={12} style={{ marginBottom: 8 }}>
					<CartSelector />
					<Tooltip title={translate('orders.createCart')} placement="top">
						<IconButton
							className={classes.noRipple}
							style={{ paddingTop: 5 }}
							disableRipple
							onClick={e => {
								setAnchorCartEl(e.currentTarget);
							}}
							sx={{ color: theme.palette.primary.main }}
						>
							<AddCircleIcon sx={{ fontSize: '1.2em' }} />
						</IconButton>
					</Tooltip>
					<Popover
						open={openCart}
						anchorEl={anchorCartEl}
						onClose={_ => {
							setAnchorCartEl(null);
						}}
					>
						<CreateCart
							showSave={true}
							showClose={true}
							onClose={_ => {
								setAnchorCartEl(null);
							}}
						/>
					</Popover>
				</Grid>
			)}
			{loading && <CircularProgress size={16} />}
			{!loading && (
				<Grid item xs={6}>
					<Button variant="dark" onClick={_ => onSave({ fcd: false })} endIcon={<AddShoppingCartIcon />}>
						carrello
					</Button>
				</Grid>
			)}
			{item.type !== 'BOOK2C' && showFcd && loading && <CircularProgress size={16} />}
			{item.type !== 'BOOK2C' && showFcd && !loading && (
				<Grid container item xs={6} justify="flex-end">
					<Button variant="light" onClick={_ => onSave({ fcd: true })} endIcon={<EyeIcon />}>
						trasforma in visione
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(CreateCartStep);
