import { action, actionOn, thunk, thunkOn } from 'easy-peasy';

export default {
	posts: [],
	save: action((state, payload) => {
		state.posts = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	loadPosts: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.wordpress
			.postList(payload)
			.then(data => {
				actions.save(data.posts);
			})
			.catch(e => {
				//getStoreActions().error('wordpress.posts');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
