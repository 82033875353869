import React, { memo } from 'react';
import { useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

const SelectionComp = ({ ean, active }) => {
	const toggleSelection = useStoreActions(dispatch => dispatch.rankings.assign.toggleSelection);
	return (
		<TableCell padding="checkbox">
			<Checkbox
				color="primary"
				onChange={_ => toggleSelection(ean)}
				checked={active}
				inputProps={{ 'aria-labelledby': ean }}
			/>
		</TableCell>
	);
};

const Selection = memo(
	({ item }) => <SelectionComp ean={item.ean} active={item.isSelected} />,
	(p, n) => p.item.ean === n.item.ean && p.item.isSelected === n.item.isSelected
);

export default Selection;
