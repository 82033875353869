import { action, thunk } from 'easy-peasy';

export default {
	open: false,
	setOpen: action((state, payload) => {
		state.open = payload;
	}),
	openFromUrl: true,
	setOpenFromUrl: action((state, payload) => {
		state.openFromUrl = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.wishlist
			.create(payload)
			.then(data => {
				actions.setOpen(false);
				getStoreActions().success('wishlist.create');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('wishlist.create');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
