import React, { useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Stack,
	Backdrop,
	Typography,
	Box,
	Fab,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Button,
	Popover,
	Paper,
	Modal,
	IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadIcon from '@mui/icons-material/Upload';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImportItems from './ImportItems';
import UserGroups from './UserGroups';
import DetailItemsAssign from './DetailItemsAssign';
import ExportPdf from './ExportPdf';
import utils from 'components/utils';

const HeaderPageDetail = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const useType = utils.useType();
	const user = useStoreState(state => state.me.data);
	const [iAmPersonifying, target] = utils.usePersonification();
	const userId = iAmPersonifying ? target.id : user.id;
	const proposal = useStoreState(state => state.proposals.details.data);
	const dataAssigned = useStoreState(state => state.proposals.detailItems.data);
	const openModalImport = useStoreState(state => state.proposals.fileManager.openModalImport);
	const setOpenModalImport = useStoreActions(dispatch => dispatch.proposals.fileManager.setOpenModalImport);
	const openModalUserGroups = useStoreState(state => state.proposals.detailUserGroup.openModalUserGroups);
	const setOpenModalUserGroups = useStoreActions(
		dispatch => dispatch.proposals.detailUserGroup.setOpenModalUserGroups
	);
	const openModalItemsAssign = useStoreState(state => state.proposals.detailItemsAssign.openModalItemsAssign);
	const setOpenModalItemsAssign = useStoreActions(
		dispatch => dispatch.proposals.detailItemsAssign.setOpenModalItemsAssign
	);
	const openModalPdf = useStoreState(state => state.proposals.details.openModalPdf);
	const setOpenModalPdf = useStoreActions(dispatch => dispatch.proposals.details.setOpenModalPdf);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClickImport = event => {
		setAnchorEl(null);
		setOpenModalImport(true);
	};

	const handleCloseImport = () => {
		setOpenModalImport(false);
	};

	const handleClickUserGroups = () => {
		setAnchorEl(null);
		setOpenModalUserGroups(true);
	};

	const handlecloseUserGroups = () => {
		setOpenModalUserGroups(false);
	};

	const handleClickItemAssign = () => {
		setAnchorEl(null);
		setOpenModalItemsAssign(true);
	};

	const handlecloseItemsAssign = () => {
		setOpenModalItemsAssign(false);
	};

	const handkeClickPdf = () => {
		setAnchorEl(null);
		setOpenModalPdf(true);
	};

	const handleClosePdf = () => {
		setOpenModalPdf(false);
	};

	const handleModalClose = () => {
		if (openModalImport) handleCloseImport();
		if (openModalUserGroups) handlecloseUserGroups();
		if (openModalItemsAssign) handlecloseItemsAssign();
		if (openModalPdf) handleClosePdf();
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const buttonList = [
		{
			label: translate('proposals.assignUserGroup'),
			icon: <GroupAddIcon />,
			condiction: false, //|| iCan('PROPOSALS') && useType !== 'CLIENT' && proposal.userCode === userId,
			disabled: false,
			href: null,
			onclick: handleClickUserGroups,
			type: 'light'
		},
		/*
		{
			label: translate('proposals.assignCustomers'),
			icon: <RecentActorsIcon />,
			condiction: iCan('PROPOSALS') && useType !== 'CLIENT',
			href: null,
			onclick: null,
			type: 'light'
		},
        */
		{
			label: translate('proposals.assignItems'),
			icon: <MenuBookIcon />,
			condiction: iCan('PROPOSALS') && proposal.userCode === userId,
			disabled: false,
			href: null,
			onclick: handleClickItemAssign,
			type: 'light'
		},
		{
			label: translate('proposals.import'),
			icon: <UploadIcon />,
			condiction: iCan('PROPOSALS') && proposal.userCode === userId,
			disabled: false,
			href: null,
			onclick: handleClickImport,
			type: 'light'
		},
		{
			label: translate('proposals.exportPdf'),
			icon: <PictureAsPdfIcon />,
			condiction: iCan('PROPOSALS'),
			disabled: dataAssigned.length == 0,
			href: null,
			onclick: handkeClickPdf,
			type: 'light'
		}
	];

	return (
		<Fragment>
			<Stack direction="row" justifyContent="flex-stat" alignItems="center" spacing={1} variant="wrapperColor">
				<IconButton href="/proposals">
					<ArrowBackIcon />
				</IconButton>
				<Typography variant="titlePage" sx={{ width: { xs: '100%', md: '40%', sm: '100&' } }}>
					{proposal.name}
				</Typography>
				{/* Pulsanti su Desktop */}
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					spacing={1}
					sx={{ display: { xs: 'none', md: 'inherit', sm: 'none' }, width: '60%' }}
				>
					{buttonList.map(btn => (
						<Button
							key={btn.label}
							variant={btn.type}
							href={btn.href}
							disabled={btn.disabled}
							onClick={btn.onclick}
							endIcon={btn.icon}
							sx={{ display: btn.condiction ? 'inherit' : 'none' }}
						>
							{btn.label}
						</Button>
					))}
				</Stack>
				{/* Pulsanti su Mobile */}
				<Box
					sx={{
						display: { xs: 'inherit', md: 'none', sm: 'inherit' },
						position: 'absolute',
						top: '10px',
						right: '10px'
					}}
				>
					<Backdrop open={open} />
					<Fab
						aria-label="Mobile function"
						onClick={handleClick}
						sx={{
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.custom.white,
							'&:hover': {
								backgroundColor: theme.palette.custom.hover
							}
						}}
						size="small"
					>
						<AddIcon />
					</Fab>
					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<Paper elevation={24}>
							<List sx={{ padding: '0' }}>
								{buttonList.map(btn => (
									<ListItemButton
										key={btn.label}
										variant="mobileList"
										href={btn.href}
										disabled={btn.disabled}
										onClick={btn.onclick}
										sx={{
											display: btn.condiction ? 'flex' : 'none',
											backgroundColor:
												btn.type === 'dark'
													? theme.palette.primary.main
													: theme.palette.custom.white,
											'&:hover': {
												backgroundColor:
													btn.type === 'dark'
														? theme.palette.primary.main
														: theme.palette.custom.white
											}
										}}
									>
										<ListItemIcon>{btn.icon}</ListItemIcon>
										<ListItemText
											primary={btn.label}
											sx={{ color: btn.type == 'dark' ? theme.palette.custom.white : 'inherit' }}
										/>
									</ListItemButton>
								))}
							</List>
						</Paper>
					</Popover>
				</Box>
			</Stack>

			{/* Modale importazione, User Groups e PDF */}
			<Modal
				open={openModalImport || openModalUserGroups || openModalItemsAssign || openModalPdf}
				onClose={handleModalClose}
				aria-labelledby="modal-import-groups"
				closeAfterTransition
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
					height: '100%'
				}}
			>
				<Fragment>
					{openModalImport && (
						<Box
							sx={{
								width: '100%',
								maxWidth: '470px',
								backgroundColor: theme.palette.custom.white,
								padding: '10px'
							}}
						>
							<ImportItems id={proposal.id} onClose={handleCloseImport} />
						</Box>
					)}
					{openModalUserGroups && <UserGroups proposalId={proposal.id} onClose={handlecloseUserGroups} />}
					{openModalItemsAssign && (
						<Box
							sx={{
								width: '100%',
								height: '100%',
								backgroundColor: theme.palette.custom.white,
								padding: '10px',
								overflowY: 'scroll'
							}}
						>
							<DetailItemsAssign proposalId={proposal.id} onClose={handlecloseItemsAssign} />
						</Box>
					)}
					{openModalPdf && (
						<Box
							sx={{
								width: '400px',
								height: 'auto',
								backgroundColor: theme.palette.custom.white,
								padding: '10px'
							}}
						>
							<ExportPdf proposalId={proposal.id} proposalName={proposal.name} onClose={handleClosePdf} />
						</Box>
					)}
				</Fragment>
			</Modal>
		</Fragment>
	);
};

export default HeaderPageDetail;
