import { action, thunk } from 'easy-peasy';
import clone from 'lodash/clone';

export default {
	list: [],
	realm: '',
	skipNext: false,
	setRealm: action((state, payload) => {
		state.realm = payload;
		state.list = [];
	}),
	open: null,
	setOpen: action((state, payload) => {
		if (!state.skipNext) state.open = payload;
		state.skipNext = false;
	}),
	close: action((state, payload) => {
		state.open = null;
		state.list = [];
		state.skipNext = true; //  this is to prevent autocomplete opening immediatly after search (FAS-474)
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	save: action((state, payload) => {
		state.list = payload;
	}),
	load: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const r = getState().realm;
		const token =
			r === 'token'
				? clone(getStoreState().catalog.search.token)
				: clone(getStoreState().catalog.filters.list[r]);
		if (!token || token === '') return;
		actions.setLoading(true);
		services.catalog
			.autocomplete({ token, realm: r })
			.then(data => {
				actions.save(data.items);
			})
			.catch(e => {
				getStoreActions().error('catalog.autocomplete');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
