import k from 'k';
import utils from 'services/utils';

export default ({ proposalId, positions }) => {
	const requestOptions = {
		method: 'POST',
		headers: utils.createHeaders(),
		body: JSON.stringify({ positions: positions })
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/catalog-groups/proposals/` + proposalId + '/positions', requestOptions)
		.then(response => utils.handleResponse({ response }));
};
