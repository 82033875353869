import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { Link as MaterialLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Search from '@mui/icons-material/Search';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HomeLink from './components/HomeLink';
import Carousel from './components/cms/Carousel';
import Banners from './components/cms/Banners';
import Videos from './components/cms/Videos';
import Promotions from './components/cms/Promotions';
import BestSellers from './components/cms/BestSellers';
import utils from 'components/utils';
import styles from './styles';
import { useStoreState } from 'easy-peasy';

const Home = ({ classes }) => {
	const iCan = utils.usePermissions();
	const loadPromoSlide = useStoreActions(dispatch => dispatch.cms.promotionSlide.load);
	const loadBanner = useStoreActions(dispatch => dispatch.cms.banners.load);
	const loadPromo = useStoreActions(dispatch => dispatch.cms.promotions.load);
	const loadVideos = useStoreActions(dispatch => dispatch.cms.videos.load);
	const loadBestSellers = useStoreActions(dispatch => dispatch.cms.bestSellers.load);
	const idPersonify = useStoreState(state => state.me.personify.target.id);
	const refParameter = window.pageYOffset;

	useEffect(() => {
		loadPromoSlide();
		loadBanner();
		loadPromo();
		loadVideos();
		loadBestSellers();
	}, [loadBanner, loadBestSellers, loadPromo, loadPromoSlide, loadVideos, idPersonify]);

	return (
		<Grid container spacing={3}>
			<Videos />
			<Grid container item xs={12}>
				<Carousel autoplay dots arrows={false} />
			</Grid>
			<Grid container item xs={12}>
				<Banners />
			</Grid>
			<Grid container item xs={12}>
				<Promotions />
			</Grid>
			{iCan('CATALOGS') && (
				<Grid container item xs={12}>
					<BestSellers refParameter={refParameter} />
				</Grid>
			)}
			<Grid container item xs={12} sm={3}>
				<Card className={classes.heightBox}>
					<HomeLink
						text="Ricerca e ordina dal nostro catalogo"
						to="/catalog"
						linkStyle={classes.linkStyle}
						boxStyle={classes.centerBox}
						iconStyle={classes.fontSize}
						icon={<Search className={classes.fontSize} />}
						isEnabled={iCan('CATALOGS')}
					/>
				</Card>
			</Grid>
			<Grid container item xs={12} sm={3}>
				<Card className={classes.heightBox}>
					<HomeLink
						text="Consulta il tuo portafoglio ordini"
						to="/orders/list"
						linkStyle={classes.linkStyle}
						boxStyle={classes.centerBox}
						iconStyle={classes.fontSize}
						icon={<ShoppingCart className={classes.fontSize} />}
						isEnabled={iCan('ORDERS')}
					/>
				</Card>
			</Grid>
			<Grid container item xs={12} sm={3}>
				<Card className={classes.heightBox}>
					<HomeLink
						text="Visualizza e scarica i tuoi documenti contabili"
						to="/documents"
						linkStyle={classes.linkStyle}
						boxStyle={classes.centerBox}
						iconStyle={classes.fontSize}
						icon={<ListAltIcon className={classes.fontSize} />}
						isEnabled={iCan('DOCUMENTS')}
					/>
				</Card>
			</Grid>
			<Grid container item xs={12} sm={3}>
				<Card className={classes.heightBox}>
					<CardContent className={classes.centerBox}>
						<Box>
							<MaterialLink href="https://www.facebook.com/fastbookspa.it" target="_blank">
								<FacebookIcon className={classes.fontSize} />
							</MaterialLink>
							<MaterialLink href="https://www.instagram.com/fastbook_italia/" target="_blank">
								<InstagramIcon className={classes.fontSize} />
							</MaterialLink>
							<MaterialLink href="mailto:assistenza.clienti@fastbookspa.it" target="_blank">
								<MailOutlineIcon className={classes.fontSize} />
							</MaterialLink>
						</Box>
						<Typography variant="h6">Vicini alla tua attività</Typography>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(Home);
