import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import utils from 'components/utils';
import { DateTimePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';

const makeDatePickerStyles = makeStyles({
	root: {
		width: 180
	}
});

const DatePickerFrom = props => {
	const translate = utils.useI18N();
	const saveDateFrom = useStoreActions(dispatch => dispatch.editorials.add.saveDateFrom);
	const pubDateFrom = useStoreState(state => state.editorials.add.dateFrom);
	const datePickerStyles = makeDatePickerStyles();

	return (
		<DateTimePicker
			className={datePickerStyles.root}
			autoOk
			value={pubDateFrom}
			onChange={saveDateFrom}
			format="L HH:mm"
			disableToolbar
			label={translate('catalog.filters.pubDateFrom') + ':'}
			ampm={false}
			disablePast
			slotProps={{
				textField: {
					sx: {
						width: '240px !important'
					}
				}
			}}
		/>
	);
};

export { DatePickerFrom };
