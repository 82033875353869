import list from './list';
import listLight from './list-light';
import count from './count';
import markAsRead from './mark-as-read';
import getReferent from './get-referent';
import sendContactRequest from './send-contact-request';
import openTicket from './open-ticket';

export default {
	list,
	listLight,
	count,
	markAsRead,
	getReferent,
	sendContactRequest,
	openTicket
};
