import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import { Stack, Typography, Box, IconButton, CircularProgress, Tooltip, Badge } from '@mui/material';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloseIcon from '@mui/icons-material/Close';
import PromoList from './PromoList';
import PromoInfo from './PromoInfo';
import Card from 'components7/Card';
import PublisherSheet from './components/PublisherSheet';
import Synopsis from './components/Synopsis';
import InfoThema from './components/InfoThema';
import Type from './components/Type';
import Price from './components/Price';
import Discount from './components/Discount';
import Validity from './components/Validity';
import Availability from './components/Availability';
import BadgeList from './components/BadgeList';
import utils from 'components/utils';
import CardRow from './components/CardRow';
import Order from './components/Order';
import EditOrders from './components/EditOrders';
import EditWishlist from './components/EditWishlist';
import OrderHistory from './components/OrderHistory';
import OpacCheck from './components/OpacCheck';
import Location from './components/Location';
import moment from 'moment';
import InCart from './components/InCart';

const Details = props => {
	const { classes, id_ean, itemOrder, currentCart, itemWishlist, urlComponet, scrollPosition, showClose } = props;
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const translate = utils.useI18N();
	const useEpipoli = iCan('MANAGE_EPIPOLI'); //utils.useEpipoli();
	const params = useParams();
	const history = useHistory();
	const loading = useStoreState(state => state.catalog.details.loading);
	const load = useStoreActions(dispatch => dispatch.catalog.details.load);
	const details = useStoreState(state => state.catalog.details.data);
	const promoSearch = useStoreState(state => state.catalog.filters.promoSearch);
	const setBestSellersModalOpen = useStoreActions(dispatch => dispatch.cms.bestSellers.setModalOpen);
	const setCatalogOneModalOpen = useStoreActions(dispatch => dispatch.catalog.details.setModalOpen);
	const setWishlistModalOpen = useStoreActions(dispatch => dispatch.wishlist.details.setOpenModal);
	const setOrdersModalOpen = useStoreActions(dispatch => dispatch.order.create.setOpenModal);
	const showFullScreenFilters = useStoreState(state => state.catalog.filters.showFullScreen);
	const checkEpipoli = details && (details.type !== 200 || (details.type === 200 && useEpipoli));
	const stock = useStoreState(state => state.catalog.search.stock);
	const getStock = useStoreActions(dispatch => dispatch.catalog.search.getStock);
	const resetListCartInDetail = useStoreActions(dispatch => dispatch.cart.listLight.resetList);
	const resetListWLInDetail = useStoreActions(dispatch => dispatch.wishlist.listLight.resetList);
	const { search } = useLocation();
	const inCart = search.slice(4); // slicing to the value of query params
	const bookInfo = details
		? [
				{
					id: 'Author',
					type: 'card',
					component: <CardRow label={translate('catalog.details.infoBox.author')} value={details.author} />
				},
				{
					id: 'Illustratore',
					type: 'card',
					component: (
						<CardRow
							label={translate('catalog.details.infoBox.illustrator')}
							value={details.illustratore}
						/>
					)
				},
				{
					id: 'Serie',
					type: 'card',
					component: <CardRow label={translate('catalog.details.infoBox.serie')} value={details.serie} />
				},
				{
					id: 'Publisher',
					type: 'card',
					component: (
						<CardRow label={translate('catalog.details.infoBox.publisher')} value={details.publisher} />
					)
				},
				{
					id: 'PubDate',
					type: 'card',
					component: (
						<CardRow
							label={translate('catalog.details.infoBox.publicationDate')}
							value={moment(details.publicationDate).format('L')}
						/>
					)
				},
				{ id: 'PublisherSheet', type: 'card', component: <PublisherSheet details={details} /> },
				{ id: 'Synopsis', type: 'card', component: <Synopsis details={details} /> },
				{ id: 'InfoThema', type: 'card', component: <InfoThema ean={id_ean} /> },
				{
					id: 'Ean',
					type: 'info',
					component: <CardRow label={translate('catalog.details.infoBox.ean')} value={details.ean} />
				},
				{
					id: 'NewsItem',
					type: 'info',
					component: (
						<CardRow label={translate('catalog.details.infoBox.newsItem')} value={details.newsItem} />
					)
				},
				{ id: 'Type', type: 'info', component: <Type details={details} /> },
				{ id: 'Validity', type: 'info', component: <Validity details={details} /> },
				{
					id: 'Language',
					type: 'info',
					component: (
						<CardRow label={translate('catalog.details.infoBox.language')} value={details.language} />
					)
				},
				{ id: 'Price', type: 'info', component: <Price details={details} /> },
				{ id: 'Discount', type: 'info', component: <Discount details={details} /> },
				{ id: 'Availability', type: 'info', component: <Availability details={details} /> },
				{ id: 'InCart', type: 'info', component: <InCart inCart={inCart === '' ? stock : inCart} /> }
		  ]
		: null;

	useEffect(
		_ => {
			if (id_ean) {
				load(id_ean);
				resetListCartInDetail(); //Resetto la lista dei carrelli da mostrare nel dettaglio
				resetListWLInDetail(); //Resetto la lista delle WL da mostrare nel dettaglio
			}
			if (inCart === '') getStock(id_ean);
		},
		[load, id_ean]
	);

	const onClose = () => {
		if (history.location.pathname.includes('/catalog/search/details') && params.ean) {
			setCatalogOneModalOpen(false);
			if (showFullScreenFilters) history.push(`/catalog/`);
			else history.push(`/catalog/search/`);
		} else if (history.location.pathname.includes('/wishlist/list')) {
			setWishlistModalOpen(false);
			history.push('/wishlist/list');
		} else if (history.location.pathname.includes('/orders/list')) {
			setOrdersModalOpen(false);
			history.push('/orders/list');
		} else if (history.location.pathname.includes('/home/bestsellers')) {
			setBestSellersModalOpen(false);
			if (history.action === 'PUSH') {
				window.history.replaceState(null, '', '/');
				window.scrollTo(0, scrollPosition);
			} else history.push('/');
		} else {
			history.push(`/catalog/search/`);
		}
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%', padding: '10px 10px 20px 10px !important' }}
		>
			{loading && (
				<Box sx={{ width: '100%' }}>
					<CircularProgress sx={{ fontSize: '1.2em' }} />
				</Box>
			)}
			{!loading && details && (
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					sx={{ width: '100%' }}
				>
					{/* Titolo + stampa e chiudi */}
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={0}
						sx={{
							width: '100%',
							borderWidth: '0 0 2px 0',
							borderStyle: 'solid',
							borderColor: theme.palette.primary.main
						}}
					>
						<Typography
							variant="titlePage"
							component="div"
							sx={{ width: '90%', color: theme.palette.primary.main }}
						>
							{details.title}
						</Typography>
						<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
							<Tooltip title={translate('catalog.details.fastlinkSheet')}>
								<IconButton component={Link} to={'/print/book/' + details.ean} target="_blank">
									<LocalPrintshopIcon sx={{ color: theme.palette.primary.main }} />
								</IconButton>
							</Tooltip>
							{showClose && (
								<Tooltip title={translate('common.close')}>
									<IconButton onClick={onClose}>
										<CloseIcon sx={{ color: theme.palette.primary.main }} />
									</IconButton>
								</Tooltip>
							)}
						</Stack>
					</Stack>

					{/* Promozione */}
					{details.isPromo && details.promo.length > 0 && !promoSearch && <PromoList />}
					{details.isPromo && promoSearch && <PromoInfo />}

					{/* Copertina + Informazioni libro + sezione ordini */}
					<Stack
						direction={{ xs: 'column-reverse', md: 'column', sm: 'column-reverse' }}
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={0}
						sx={{ width: '100%' }}
					>
						{/* Copertina, Informazioni libro e Badge */}
						<Stack
							direction={{ xs: 'column', md: 'row', sm: 'column' }}
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={{ xs: 1, md: 0, sm: 1 }}
							sx={{ width: '100%' }}
						>
							{/* Copertina */}
							<Box
								sx={{
									width: '20%',
									maxWidth: '200px',
									display: { xs: 'none', md: 'inherit', sm: 'none' }
								}}
							>
								<Badge
									badgeContent={details.volume}
									color="error"
									invisible={details.volume === null ? true : false}
									max={99999}
									sx={{ maxWidth: '100%', top: '10px' }}
								>
									<Box
										component="img"
										src={details.coverImageURL}
										alt={details.title}
										sx={{ width: '100%', maxWidth: '200px', margin: '-10px 0 10px 0 !important' }}
									/>
								</Badge>
							</Box>
							{/* Scheda libro + Informazioni */}
							<Stack
								direction={{ xs: 'column', md: 'row', sm: 'row' }}
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={0}
								sx={{ width: '100%' }}
							>
								{/* Scheda libro */}
								<Card
									title={translate('catalog.details.bookCard')}
									backgroundColor={theme.palette.custom.white}
									margin={{
										xs: '15px 0 0 0 !important',
										md: '15px 0 0 20px !important',
										sm: '15px 5px 0 0 !important'
									}}
									width={{ xs: '100%', md: '50%', sm: '50%' }}
								>
									{bookInfo
										.filter(b => b.type === 'card')
										.map(item => (
											<Fragment key={item.id}>{item.component}</Fragment>
										))}
								</Card>
								{/* Informazioni */}
								<Card
									title={translate('catalog.details.bookInfo')}
									backgroundColor={theme.palette.custom.white}
									margin={{
										xs: '15px 0 0 0 !important',
										md: '15px 0 0 20px !important',
										sm: '15px 0 0 5px !important'
									}}
									width={{ xs: '100%', md: '50%', sm: '50%' }}
								>
									{bookInfo
										.filter(b => b.type === 'info')
										.map(item => (
											<Fragment key={item.id}>{item.component}</Fragment>
										))}
								</Card>
							</Stack>
							{/* Badge classifiche sellout */}
							<BadgeList id_ean={id_ean} size="72px" />
						</Stack>

						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={1}
							sx={{ width: '100%' }}
						>
							{/* Card Epipoli non gestibile dall'utente */}
							{!checkEpipoli && (
								<Card
									title={translate('catalog.details.orderInfo')}
									backgroundColor={theme.palette.custom.white}
									margin={'20px 0 0 0 !important'}
									width={'100%'}
								>
									<Box sx={{ width: '100%', margin: '10px auto !important', textAlign: 'center' }}>
										<Typography variant="titleSection" sx={{ color: theme.palette.secondary.main }}>
											{translate('catalog.details.epipolidisabled')}
										</Typography>
									</Box>
								</Card>
							)}

							{/* Odini */}
							{iCan('BUY') && details.ordinable > 0 && checkEpipoli && (
								<Order
									itemOrder={itemOrder}
									itemWishlist={itemWishlist}
									promoSearch={promoSearch}
									urlComponet={urlComponet}
								/>
							)}

							{/* Modifica ordine (se la scheda dettaglio è aperta dalla pagina ordini) */}
							{itemOrder && checkEpipoli && (
								<Card
									title={translate('common.edit')}
									backgroundColor={theme.palette.custom.white}
									margin={'20px 0 0 0 !important'}
									width={'100%'}
								>
									<EditOrders itemOrder={itemOrder} currentCart={currentCart} type={details.type} />
								</Card>
							)}

							{/* Modifica WL (se la scheda dettaglio è aperta dalla pagina WL) */}
							{itemWishlist && checkEpipoli && (
								<Card
									title={translate('common.edit')}
									backgroundColor={theme.palette.custom.white}
									margin={'20px 0 0 0 !important'}
									width={'100%'}
								>
									<EditWishlist itemWishlist={itemWishlist} type={details.type} />
								</Card>
							)}

							<OrderHistory />
							{iCan('SHOW_OPAC_CHECK_IN_DETAILS') && <OpacCheck ean={details.ean} />}
							{iCan('SHOW_LOCATION_ITEM_IN_DETAILS', true) && (
								<Location ean={details.ean} depots={details.depots} />
							)}
						</Stack>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};

export default Details;
