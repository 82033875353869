import oneClick from './one-click';
import exportt from './export';
import remove from './remove';
import create from './create';
import createMultiple from './create-multiple';
import singleHistory from './single-history';
import shippingStepper from './shipping-stepper';
import update from './update';

export default {
	oneClick,
	exportt,
	remove,
	create,
	createMultiple,
	singleHistory,
	shippingStepper,
	update
};
