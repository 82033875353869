import useUserData from './use-user-data';

const useFilteredDepots = _ => {
	const availableDepots = useUserData('availableDepots', []);
	const paymentTypeId = useUserData('paymentTypeId', -1);
	if (paymentTypeId === 1) {
		return availableDepots.filter(d => d.id !== 'FS01');
	}
	return availableDepots;
};

export default useFilteredDepots;
