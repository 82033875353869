import { red, green } from '@mui/material/colors';

export default theme => ({
	red: {
		color: theme.palette.getContrastText(red[300]),
		backgroundColor: red[300]
	},
	green: {
		color: theme.palette.getContrastText(green[300]),
		backgroundColor: green[300]
	},
	white: {
		backgroundColor: 'white'
	}
});
