import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { useStoreActions } from 'easy-peasy';

const AttachmentComp = ({ hasAttach, id }) => {
	const download = useStoreActions(dispatch => dispatch.coupons.importt.download);
	return (
		<StyledTableCell>
			{hasAttach ? (
				<IconButton size="small" disableRipple style={{ padding: 8, background: 'none' }}>
					<ListItemIcon>
						<AttachFileIcon onClick={() => download(id)} />
					</ListItemIcon>
				</IconButton>
			) : (
				<Typography>{'Nessun allegato presente'}</Typography>
			)}
		</StyledTableCell>
	);
};
const Attachment = memo(
	({ item }) => <AttachmentComp hasAttach={item.attachFileId} id={item.id} />,
	(p, n) => p.item.id === n.item.id
);

export default Attachment;
