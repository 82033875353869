import k from 'k';
import utils from 'services/utils';

export default ({ token, page, step }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	return utils
		.fetch(
			`${k.endpoints.apis}/backoffice/search/users?keyword=${token}&pageSize=${step}&pageNumber=${page}`,
			requestOptions
		)
		.then(response => utils.handleResponse({ response }));
};
