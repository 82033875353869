import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const GiveOrTakeComp = ({ giveOrTake }) => {
	return <StyledTableCell>{giveOrTake === true ? 'Dare' : 'Avere'}</StyledTableCell>;
};

const GiveOrTake = memo(
	({ item }) => <GiveOrTakeComp giveOrTake={item.giveOrTake} />,
	(p, n) => p.item.giveOrTake === n.item.giveOrTake
);

export default GiveOrTake;
