import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Stack, Box } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import utils from 'components/utils';
import styles from './styles';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import NewCard from 'components7/Card';

const Attributes = ({ details }) => {
	const translate = utils.useI18N();
	const loadDetails = useStoreActions(dispatch => dispatch.purchasings.details.load);
	const loadingUpdate = useStoreState(state => state.purchasings.update.loading);
	const update = useStoreActions(dispatch => dispatch.purchasings.update.sendRequest);
	const referents = useStoreState(state => state.purchasings.referentList.referents);
	const params = useParams();
	const setName = useStoreActions(dispatch => dispatch.purchasings.details.setName);
	const setEnabled = useStoreActions(dispatch => dispatch.purchasings.details.setEnabled);
	const setReferent = useStoreActions(dispatch => dispatch.purchasings.details.setReferent);
	const [referentList, setReferentList] = useState([]);
	useEffect(
		_ => {
			loadDetails(params.id);
		},
		[loadDetails, params]
	);
	useEffect(
		_ => {
			if (referents) {
				const newRef = referents.map(r => ({
					...r,
					isSelected: details.referentCode === r.userCode
				}));
				setReferentList(newRef);
			}
		},
		[setReferentList, referents, details.referentCode]
	);

	const selectRef = userCode => {
		setReferentList(
			referentList.map((d, i) => ({
				...d,
				isSelected: d.userCode === userCode
			}))
		);
		setReferent(userCode);
	};

	const selectedRef = referentList ? referentList.find(d => d.isSelected) : null;
	const selectedRefCode = selectedRef ? selectedRef.userCode : '';

	const onSaveClick = _ => {
		const user = {
			name: details.name,
			referentCode: details.referentCode,
			referentDesc: details.referentDesc,
			enabled: details.enabled
		};

		update({
			id: params.id,
			user
		});
	};
	const uniformReferentName = string => {
		const stringInLowerCase = string.toLowerCase();
		return utils.capitalizeAll(stringInLowerCase);
	};
	return (
		<NewCard
			backgroundColor="white"
			title={`${translate('purchasings.attributes')}`}
			width={'100%'}
			margin={'20px 0 0 0'}
		>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={{ xs: 1, md: 0, sm: 1 }}
				sx={{ width: '100%' }}
			>
				<Stack
					direction={{ xs: 'column', md: 'row', sm: 'column' }}
					justifyContent="flex-start"
					alignItems={{ xs: 'flex-start', md: 'center', sm: 'flex-start' }}
					spacing={2}
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: { xs: '100%', md: '25%', sm: '100%' } }}>
						{details ? (
							details.referentDesc ? (
								<Typography
									align={'left'}
									variant={'overline'}
									style={{ marginBottom: '2%', fontWeight: 'bold' }}
								>
									{translate('purchasings.description') + ': ' + details.referentDesc}
								</Typography>
							) : (
								''
							)
						) : (
							''
						)}
					</Box>
					<Box sx={{ width: { xs: '100%', md: '25%', sm: '100%' } }}>
						<TextField
							value={(details && details.name) || ''}
							onChange={e => setName(e.target.value)}
							fullWidth={false}
							label={translate('purchasings.name')}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Box>
					<Box sx={{ width: { xs: '100%', md: '25%', sm: '100%' } }}>
						<FormControl sx={{ width: '100%' }}>
							<InputLabel>{translate('purchasings.selectReferent') + ' *'}</InputLabel>
							<Select
								value={selectedRefCode}
								onChange={event => selectRef(event.target.value)}
								displayEmpty
							>
								{referents.map((item, index) => (
									<MenuItem key={`referent_purchasing_${item.userCode}`} value={item.userCode}>
										{`${uniformReferentName(item.userName)} (${item.userCode})`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Grid container item md={4} xs={12} justify="center" sx={{ alignContent: 'flex-end' }}>
						<FormControlLabel
							value="start"
							control={
								<Checkbox
									style={{ paddingBottom: 0 }}
									color="primary"
									size="medium"
									checked={(details && details.enabled) || false}
									onChange={e => setEnabled(e.target.checked)}
								/>
							}
							label={translate('purchasings.tables.status')}
							labelPlacement="start"
							style={{ alignItems: 'flex-end' }}
						/>
					</Grid>
				</Stack>
				<Grid container item xs={12} justify="flex-end" style={{ marginTop: 24 }}>
					{loadingUpdate && <CircularProgress size={16} />}
					{!loadingUpdate && (
						<Button variant="contained" color="primary" onClick={onSaveClick}>
							{translate('common.save')}
						</Button>
					)}
				</Grid>
			</Stack>
		</NewCard>
	);
};

export default withStyles(styles, { withTheme: true })(Attributes);
