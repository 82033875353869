import React, { useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Stack, TextField, Typography, Input, Box, Button, CircularProgress, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';
import moment from 'moment';

const CreateCart = props => {
	const { onClose } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const loading = useStoreState(state => state.cart.create.loading);
	const name = useStoreState(state => state.cart.create.name);
	const setName = useStoreActions(dispatch => dispatch.cart.create.setName);
	const create = useStoreActions(dispatch => dispatch.cart.create.sendRequest);
	const [chapter, setChapter] = useState('');
	const [cig, setCig] = useState('');
	const [cause, setCause] = useState('');
	const [purchaseNumber, setPurchaseNumber] = useState('');
	const [purchaseOrderDate, setPurchaseOrderDate] = useState(moment());
	const pa = iCan('OPERATE_PA_CARTS');
	const isDisable = pa ? name.trim() === '' || cig.trim() === '' || chapter.trim() === '' : name.trim() === '';

	const handleClose = event => {
		onClose(event);
	};

	const onClick = event => {
		create({
			name: name.trim(),
			cig: cig.trim(),
			chapter: chapter.trim(),
			cause: cause.trim(),
			purchaseOrderDate,
			purchaseNumber: purchaseNumber.trim()
		});
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{
				width: '100%',
				maxWidth: { xs: '340px', md: '540px', sm: '540px' },
				minWidth: '340px',
				padding: '10px'
			}}
		>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '100%' }}>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: '90%' }}>
						<Typography variant="titleSection" sx={{ color: theme.palette.primary.main }}>
							{translate('orders.createCart')}
						</Typography>
					</Box>
					<Box sx={{ width: '10%' }}>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Stack>
				{pa && (
					<Box
						sx={{
							width: '100%',
							borderWidth: '1px 0 0 0',
							borderStyle: 'solid',
							borderColor: theme.palette.primary.main,
							paddingTop: '10px'
						}}
					>
						<Typography variant="text" sx={{ color: theme.palette.custom.orange, lineHeight: '1.1em' }}>
							{translate('orders.creationAlert')}
						</Typography>
					</Box>
				)}
			</Stack>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={2}
				sx={{
					width: '100%',
					padding: '20px 5px 5px 5px'
				}}
			>
				<Stack
					direction={{ xs: 'column', md: 'row', sm: 'row' }}
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={{ xs: 2, md: 1, sm: 1 }}
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: { xs: '100%', md: pa ? '50%' : '100%', sm: pa ? '50%' : '100%' } }}>
						<TextField
							required
							value={name}
							error={!name || name.trim() === ''}
							onChange={e => {
								setName(e.target.value);
							}}
							label={translate('orders.name')}
							fullWidth
							size="small"
						/>
					</Box>
					{pa && (
						<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									value={purchaseOrderDate}
									onChange={d => {
										setPurchaseOrderDate(d);
									}}
									//views={['day']}
									className="date-picker"
									label={translate('orders.purchaseOrderDate')}
									fullWidth
								/>
							</LocalizationProvider>
						</Box>
					)}
				</Stack>
				{pa && (
					<Fragment>
						<Stack
							direction={{ xs: 'column', md: 'row', sm: 'row' }}
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={{ xs: 2, md: 1, sm: 1 }}
							sx={{ width: '100%', marginTop: '5px' }}
						>
							<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
								<TextField
									required
									value={chapter}
									error={!chapter || chapter.trim() === ''}
									onChange={e => {
										setChapter(e.target.value);
									}}
									label={translate('orders.chapter')}
									size="small"
									fullWidth
								/>
							</Box>
							<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
								<TextField
									required
									value={cig}
									error={!cig || cig.trim() === ''}
									onChange={e => {
										setCig(e.target.value);
									}}
									label={translate('orders.cig')}
									size="small"
									fullWidth
								/>
							</Box>
						</Stack>
						<Stack
							direction={{ xs: 'column', md: 'row', sm: 'row' }}
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={{ xs: 2, md: 1, sm: 1 }}
							sx={{ width: '100%' }}
						>
							<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
								<TextField
									value={cause}
									onChange={e => {
										setCause(e.target.value);
									}}
									label={translate('orders.cause')}
									helperText={translate('orders.cuseHelperText')}
									size="small"
									inputProps={{ maxLength: 20 }}
									fullWidth
								/>
							</Box>
							<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
								<TextField
									value={purchaseNumber}
									onChange={e => {
										setPurchaseNumber(e.target.value);
									}}
									label={translate('orders.purchaseNumber')}
									helperText={translate('orders.purchaseNumberHelperText')}
									inputProps={{ maxLength: 20 }}
									size="small"
									fullWidth
								/>
							</Box>
						</Stack>
					</Fragment>
				)}
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%', marginTop: '30px !important' }}
				>
					<Button variant="dark" disabled={isDisable} onClick={onClick}>
						{loading ? (
							<CircularProgress size={24} sx={{ color: theme.palette.custom.white }} />
						) : (
							translate('common.save')
						)}
					</Button>
					<Button variant="light" onClick={handleClose}>
						{translate('common.cancel')}
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default CreateCart;
