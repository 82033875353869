import React, { Fragment } from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import styles from './styles';

const TableToolbar = props => {
	const { classes } = props;
	const results = useStoreState(state => state.purchasings.assignUsers.items);
	const items = results.filter(r => r.isSelected);
	return (
		<Fragment>
			{items.length > 0 && (
				<Toolbar>
					<Typography className={classes.title} color="inherit" variant="subtitle2" component="div">
						{items.length} selected
					</Typography>
				</Toolbar>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(TableToolbar);
