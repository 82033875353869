import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import moment from 'moment';

const StartDateComp = ({ startDate }) => {
	return <StyledTableCell>{moment(startDate).format('L HH:mm')}</StyledTableCell>;
};

const StartDate = memo(
	({ item }) => <StartDateComp startDate={item.startDate} />,
	(p, n) => p.item.startDate === n.item.startDate
);

export default StartDate;
