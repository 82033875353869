import React, { Fragment, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import utils from 'components/utils';
import styles from './styles';
import { withStyles } from '@mui/styles';
import { Grid, Chip, Modal, IconButton, Tooltip, Paper, Link } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Thema from 'routes/Catalog/Thema';

const ThemaChips = ({ classes, showAll, numChips, type }) => {
	const itemsSelected = useStoreState(state => state.catalog.thema.itemsSelected);
	const itemSelectedInfo = useStoreState(state => state.catalog.thema.itemSelectedInfo);
	const deleteItemSelected = useStoreActions(dispatch => dispatch.catalog.thema.deleteItemSelected);
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const resetFilters = useStoreActions(dispatch => dispatch.catalog.filters.reset);
	const search = useStoreActions(dispatch => dispatch.catalog.search.performSearch);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const itemSelectedView = showAll ? itemSelectedInfo : itemSelectedInfo.filter((item, index) => index < numChips);

	const deleteChips = code => {
		deleteItemSelected(code);
		//Recupero il nuovo elenco di nodi selezionati
		const newItemsSelected = itemsSelected.filter(i => i != code);

		resetFilters();
		//Se ci sono ancora chips aggiorno i filtri
		if (newItemsSelected.length > 0) {
			setFilters({
				themaType: type,
				themaCodes: newItemsSelected.toString(),
				themaRanking: true
			});
		}
		//search(); -> usato setStep per richiamare la ricerca per impostare il numero di righe a 25
		setStep(25);
	};

	return (
		<div className={classes.wrapperChips}>
			{itemSelectedView.map((item, index) => (
				<Fragment key={`filter_${item.code}`}>
					<Chip size="small" label={item.label} onDelete={_ => deleteChips(item.code)} />
				</Fragment>
			))}
		</div>
	);
};

const TreePath = ({ classes, codes }) => {
	const translate = utils.useI18N();
	const itemSelectedInfo = useStoreState(state => state.catalog.thema.itemSelectedInfo);
	const themaType = useStoreState(state => state.catalog.thema.themaType);
	const openModal = useStoreState(state => state.catalog.thema.openModal);
	const setOpenModal = useStoreActions(dispatch => dispatch.catalog.thema.setOpenModal);
	const numChips = 10;
	const showFilterLink = itemSelectedInfo.length > numChips;
	const [showFullChips, setShowFullChips] = useState(!showFilterLink);
	const filterLinkText = showFullChips
		? translate('catalog.themaFilterShowMinus')
		: translate('catalog.themaFilterShowMore');

	const handleChips = event => {
		setShowFullChips(!showFullChips);
	};
	const tooltipText = themaType == 'C' ? 'catalog.themasearch' : 'catalog.themasearchScope';

	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={openModal}
				onClose={_ => {
					setOpenModal(false);
				}}
				closeAfterTransition
			>
				<Paper sx={{ padding: '10px', maxHeight: '700px', overflowY: 'scroll' }}>
					<Thema type={themaType} />
				</Paper>
			</Modal>
			<Grid container item xs={12}>
				<Grid item xs={6} style={{ justifyContent: 'flex-start' }}>
					<Tooltip title={translate(tooltipText)}>
						<IconButton
							className={classes.themaIcon}
							onClick={e => {
								setOpenModal(true);
							}}
						>
							<AddCircleIcon />
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item xs={6} style={{ justifyContent: 'flex-end' }}>
					{showFilterLink && (
						<Link href="#" className={classes.filterLink} onClick={handleChips}>
							{filterLinkText}
						</Link>
					)}
				</Grid>
			</Grid>
			<Grid container item xs={12} alignItems="stretch">
				<ThemaChips classes={classes} showAll={showFullChips} numChips={numChips} type={themaType} />
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(TreePath);
