import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Typography from '@mui/material/Typography';
import { Link as MaterialLink } from '@mui/material';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import styles from '../styles';

const Promotions = ({ classes }) => {
	const promoList = useStoreState(state => state.homePromotions.items);
	const load = useStoreActions(dispatch => dispatch.homePromotions.load);
	// prettier-ignore
	useEffect(_ => { load(); }, [load]);
	return (
		<Fragment>
			<Grid container spacing={1} item xs={12} justify="center">
				<Grid item xs={12} className={classes.promotionTitle} style={{ marginBottom: 16 }}>
					<Typography style={{ fontSize: 20 }} variant="button" color="error">
						Promozioni
					</Typography>
				</Grid>
				<Grid container spacing={1} className={classes.promotionsContainer}>
					{promoList &&
						promoList.map((item, index) => (
							<Grid item xs={12} sm={4} md={3} key={index} container justify="center">
								<MaterialLink href={item.url} target="_blank">
									<Paper elevation={3} className={classes.singlePromotion}>
										<CardMedia style={{ height: '100%' }} image={item.image} title={item.title} />
									</Paper>
								</MaterialLink>
							</Grid>
						))}
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Promotions);
