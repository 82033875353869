import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

const Selection = ({ wltype, status }) => {
	const theme = useTheme();
	const data = useStoreState(state => state.wishlist.details.data);
	const wishlistExpanded = useStoreState(state => state.wishlist.details.wishlistExpanded);
	const itemSelected = data[wishlistExpanded].filter(i => i.isSelected);
	const selectAll = useStoreActions(dispatch => dispatch.wishlist.details.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.wishlist.details.deselectAll);
	const isBook2C = wltype === 'BOOK2C';
	const isDeleted = status === 'INITIAL';
	const onSelectAll = event => {
		if (event.target.checked) selectAll();
		else deselectAll();
	};

	return (
		<TableCell padding="checkbox" sx={{ background: theme.palette.custom.greytable }}>
			{!isBook2C && isDeleted && (
				<Checkbox
					color="primary"
					indeterminate={itemSelected.length > 0 && itemSelected.length < data[wishlistExpanded].length}
					checked={itemSelected.length > 0 && data[wishlistExpanded].length === itemSelected.length}
					onChange={onSelectAll}
					inputProps={{ 'aria-label': 'select all rows' }}
				/>
			)}
		</TableCell>
	);
};

export default Selection;
