export default ({ item, quantity }) => {
	const selectedDepot = item.depots.find(d => d.id === item.selection.depot.id) || item.depots[0];
	const warning1000 = (item.type === 200 && quantity > 20) || quantity > 999; //Inserita gestione Epipoli (max 20)

	const fesOnDepot = depot => {
		const activeFESOnDepots = depot.find(d => d.fes);
		return activeFESOnDepots ? 1 : 0;
	};
	const filteredFESDepots = fesOnDepot(item.depots);
	const getMaxValue = _ => {
		if (filteredFESDepots === 1 && quantity >= selectedDepot.stock) {
			if (selectedDepot.stock > 999) return 999;
			return selectedDepot.stock;
		}

		//Epipoli max 20
		if (item.type === 200) return 20;

		return 999;
	};
	const realQt = Math.min(quantity, getMaxValue());

	const getWarningFES = _ => {
		return filteredFESDepots === 1 ? true : false;
	};
	const warnFES = getWarningFES();

	if (selectedDepot.fes) {
		return {
			...item,
			selection: {
				...item.selection,
				depot: selectedDepot,
				warningExceeding: false,
				warningFES: warnFES,
				warning1000: selectedDepot.stock === 999 && realQt > selectedDepot.stock,
				quantity: Math.min(selectedDepot.stock, realQt)
			}
		};
	} else {
		return {
			...item,
			selection: {
				...item.selection,
				depot: selectedDepot,
				warningExceeding: realQt > selectedDepot.stock,
				warningFES: warnFES,
				warning1000,
				quantity: realQt
			}
		};
	}
};
