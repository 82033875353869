import store from 'store';
import k from 'k';

export default _ => {
	/* ==> tolto perchè eseguendo l'azione store.me.reset si aggiorna lo stato e viene ridisegnata la pagina
	prima di andare sul logout e se c'è un errore in quelche servizio scatta la gestione del 401 che fa il refresh
	del token e ricarica la pagina e quindi non viene fatto il logout. 
	Il caso capita, ad esempio, se si fa logout da home page e va in errore il servizio last-shipping.
	In ogni caso andando direttamente sul logout di Isabella l'utente deve rilloggarsi e tutto lo store è ricreato.
	store.dispatch({
		type: '@action.me.reset'
	});*/
	setTimeout(_ => {
		window.localStorage.clear();
		//	window.location.href = k.endpoints.logout; -> utilizzo url di logout di Isabella
		window.location.href = k.endpoints.logoutProvider;
	}, 500);
};
