import list from './list';
import listLight from './list-light';
import count from './count';
import markAsRead from './mark-as-read';
import contact from './contact';
import ticket from './ticket';

export default {
	list,
	listLight,
	count,
	markAsRead,
	contact,
	ticket
};
