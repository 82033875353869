import React from 'react';
import NotSortable from './NotSortable';
import Empty from './Empty';
const allCols = [
	{
		id: 'ean',
		comp: _ => <NotSortable id="ean" key="headCell_ean" label="promo.tables.ean" />
	},
	{
		id: 'title',
		comp: _ => <NotSortable id="title" key="headCell_title" label="promo.tables.title" />
	},
	{
		id: 'delete',
		comp: _ => <Empty id="author" key="headCell_delete" />
	}
];

export default allCols;
