import React from 'react';

const withColumns = WrappedComponent => {
	return props => {
		const visibleCols = ['ean', 'title', 'delete'];
		return <WrappedComponent {...props} visibleCols={visibleCols} />;
	};
};

export default withColumns;
