import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import moment from 'moment';
import 'moment/locale/it';

const Bootstrap = () => {
	const isLoggedIn = useStoreState(state => state.me.data.isLoggedIn);
	const isFirstLoggin = useStoreState(state => state.me.isFirstLoggin);
	const setIsFirstLoggin = useStoreActions(dispatch => dispatch.me.setIsFirstLoggin);
	const currentLocale = useStoreState(state => state.i18n.current);
	const loadLabels = useStoreActions(dispatch => dispatch.i18n.load);
	const loadCA = useStoreActions(dispatch => dispatch.ca.list.load);
	const [redirect, setRedirect] = useState(false);
	useEffect(loadLabels, [loadLabels]);
	useEffect(
		_ => {
			moment.locale(currentLocale);
			moment.updateLocale(moment.locale(), { invalidDate: ' ' });
		},
		[currentLocale]
	);
	useEffect(
		_ => {
			if (isLoggedIn && isFirstLoggin) {
				setIsFirstLoggin(false);
				loadCA();
				setRedirect('/');
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isLoggedIn, loadCA]
	);
	return redirect ? <Redirect to={redirect} /> : '';
};

export default Bootstrap;
