import React from 'react';
import Sortable from './Sortable';

const allCols = [
	{
		id: 'codeNumber',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="number"
				key="headCell_codeNumber"
				label="returns.tables.codeNumber"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	},
	{
		id: 'withdrawalService',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="flagAdhesionServiceReturn"
				key="headCell_withdrawalService"
				label="returns.tables.withdrawalService"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	},
	{
		id: 'bundleNumber',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="packages"
				key="headCell_bundleNumber"
				label="returns.tables.bundleNumber"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	},
	{
		id: 'itemNumber',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="pieces"
				key="headCell_itemNumber"
				label="returns.tables.itemNumber"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	},
	{
		id: 'weight',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="weight"
				key="headCell_weight"
				label="returns.tables.weight"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	},
	{
		id: 'amount',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="amount"
				key="headCell_amount"
				label="returns.tables.amount"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	},
	{
		id: 'date',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="date"
				key="headCell_date"
				label="returns.tables.date"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	},
	{
		id: 'client',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="sendUserCode"
				key="headCell_client"
				label="returns.tables.client"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	},
	{
		id: 'companyName',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="sendUserBusinessName"
				key="headCell_companyName"
				label="returns.tables.companyName"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	},
	{
		id: 'city',
		comp: ({ order, orderBy, setOrder }) => (
			<Sortable
				id="city"
				key="headCell_city"
				label="returns.tables.city"
				order={order}
				orderBy={orderBy}
				setOrder={setOrder}
			/>
		)
	}
];

export default allCols;
