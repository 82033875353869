import k from 'k';
import utils from 'services/utils';

export default (data, sapId) => {
	if (data.eansToInsert === '') data.eansToInsert = null;
	if (data.eansToDelete === '') data.eansToDelete = null;
	const requestOptions = {
		method: 'PATCH',
		headers: utils.createHeaders(),
		body: JSON.stringify(data)
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/catalog-groups/promotions/` + sapId + '/items', requestOptions)
		.then(response => utils.handleResponse({ response }));
};
