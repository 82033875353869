import React, { useState, Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Stack,
	Box,
	Typography,
	IconButton,
	CircularProgress,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	InputAdornment,
	FormControlLabel,
	Switch
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkIcon from '@mui/icons-material/Link';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import utils from 'components/utils';

const ExportPdf = props => {
	const { proposalId, proposalName, onClose } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const useType = utils.useType();
	const user = useStoreState(state => state.me.data);
	const [iAmPersonifying, target] = utils.usePersonification();
	const userId = iAmPersonifying ? target.id : user.id;
	const loadingPdf = useStoreState(state => state.proposals.details.loadingPdf);
	const createPdf = useStoreActions(dispatch => dispatch.proposals.details.createPdf);
	const loadingBook2c = useStoreState(state => state.proposals.details.loadingBook2c);
	const book2cInfo = useStoreState(state => state.proposals.details.book2cInfo);
	const getBook2cInfo = useStoreActions(dispatch => dispatch.proposals.details.getBook2cInfo);
	const domain = process.env.REACT_APP_DOMAIN;
	const isClient = useType == 'CLIENT';
	const [baseLink, setBaseLink] = useState(isClient ? 'INLIB' : 'FL');
	const [objectLink, setObjectLink] = useState('');
	const [errorObjectLink, setErrorObjectLink] = useState('');
	const [book2cUrl, setBook2cUrl] = useState('');
	const [checkePosition, setCheckePosition] = useState(true);

	const linkType = [
		{
			id: 'INLIB',
			value: 'INLIB',
			label: translate('proposals.exportPdfInLib'),
			textFieldLabel: translate('proposals.exportPdfInLibText'),
			icon: <LinkIcon />,
			addorment: 'https://'
		},
		{
			id: 'EMAIL',
			value: 'EMAIL',
			label: translate('proposals.exportPdfEmail'),
			textFieldLabel: translate('proposals.exportPdfEmailText'),
			icon: <MailOutlineIcon />,
			addorment: ''
		},
		{
			id: 'WHATSAPP',
			value: 'WHATSAPP',
			label: translate('proposals.exportPdfWhatsapp'),
			textFieldLabel: translate('proposals.exportPdfWhatsappText'),
			icon: <WhatsAppIcon />,
			addorment: '+39'
		}
		/*
		{
			id: 'FL',
			value: 'FL',
			label: translate('proposals.exportPdfFL'),
			textFieldLabel: translate('proposals.exportPdfFLWarning'),
			icon: <MenuBookIcon />,
			addorment: ''
		}
		*/
	];

	const getLinkTypeText = id => {
		const ret = linkType.filter(t => t.id == id)[0].textFieldLabel;
		return ret;
	};

	const getLinkTypeIcon = id => {
		return linkType.filter(t => t.id == id)[0].icon;
	};

	const getLinkTypeAddorment = id => {
		return linkType.filter(t => t.id == id)[0].addorment;
	};

	const createBook2cUrl = _ => {
		if (book2cInfo && book2cInfo.dominioAbilitato && book2cInfo.dominio)
			return 'https://' + book2cInfo.dominio + '.inlibreria.net';
		else return '';
	};

	const handleChange = event => {
		setBaseLink(event.target.value);
		setObjectLink(event.target.value == 'INLIB' ? book2cInfo.dominio : '');
		setErrorObjectLink('');
	};

	const isValidEmail = email => {
		return /\S+@\S+\.\S+/.test(email);
	};

	const isValidPhone = phone => {
		return phone.match('[0-9]{10}');
	};

	const isValidURL = url => {
		const res = url.match(
			/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
		);
		return res !== null;
	};

	const handleChangeObjectLink = event => {
		setErrorObjectLink('');
		let text = event.target.value;
		/*
		if (baseLink === 'INLIB') {
			if (text.length >= 8 && text.substring(0, 8) == 'https://') text = text.substring(8);
			if (text.length >= 7 && text.substring(0, 7) == 'http://') text = text.substring(7);
		} else 
		*/
		if (baseLink == 'WHATSAPP') {
			if (text.length >= 3 && text.substring(0, 3) == '+39') text = text.substring(3);
			if (isNaN(text.substring(0)) || text.substring(0, 1) == '+')
				setErrorObjectLink(translate('proposals.exportPdfWhatsappNumberError'));
			if (!isNaN(text.substring(0)) && text.substring(0, 1) != '+') setErrorObjectLink('');
		}

		setObjectLink(text);
	};

	const handleChangePosition = event => {
		setCheckePosition(event.target.checked);
	};

	const handleCreatePdf = () => {
		if (isClient) {
			/*
			if (baseLink === 'INLIB') {
				const dominio = 'https://' + book2cInfo.dominio + '.Inlibreria.net';
				if (!isValidURL(dominio)) {
					setErrorObjectLink(translate('proposals.exportPdfInLibUrlError'));
					return;
				}
			}
			*/
			if (baseLink === 'EMAIL' && !isValidEmail(objectLink)) {
				setErrorObjectLink(translate('proposals.exportPdfEmailError'));
				return;
			}
			if (baseLink === 'WHATSAPP' && !isValidPhone(objectLink)) {
				setErrorObjectLink(translate('proposals.exportPdfWhatsappNumberError'));
				return;
			}
		}
		const pdfOption = {
			drawSquare: baseLink == 'FL',
			baseLink: isClient ? baseLink : 'FL',
			objectLink: isClient && objectLink ? objectLink : domain,
			compression: 0.35,
			name: proposalName.trim(),
			showPosition: checkePosition
		};

		createPdf({ proposalId, pdfOption });
	};

	const onCloseModal = _ => {
		onClose();
	};

	useEffect(() => {
		//In caso di cliente e se non ho già i dati, carico le info sul dominio
		if (isClient && (!book2cInfo || book2cInfo.codice !== userId)) getBook2cInfo(userId);
	}, [userId]);

	useEffect(() => {
		if (isClient && book2cInfo && book2cInfo.dominioAbilitato && book2cInfo.dominio)
			setBook2cUrl(createBook2cUrl());
		if (isClient && (!book2cInfo || !book2cInfo.dominioAbilitato || !book2cInfo.dominio))
			setBook2cUrl(translate('proposals.exportPdfInLibNoDomain'));
		//Se INLIB è selezionato setto l'objectLink altrimenti non abilita il pulsante per creare il pdf
		if (baseLink === 'INLIB') setObjectLink(book2cInfo ? book2cInfo.dominio : '');
	}, [book2cInfo]);

	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					borderWidth: '0 0 1px 0',
					borderStyle: 'solid',
					borderColor: theme.palette.custom.black
				}}
			>
				<Typography variant="titleTab">{translate('proposals.exportPdf')}</Typography>
				<IconButton onClick={onCloseModal} disabled={loadingPdf}>
					<CloseIcon />
				</IconButton>
			</Stack>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={2}
				sx={{ width: '100%', paddingTop: '10px' }}
			>
				{(loadingPdf || loadingBook2c) && (
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={1}
						sx={{
							width: '100%',
							paddingTop: '10px',
							paddingBottom: '10px'
						}}
					>
						<CircularProgress size={46} sx={{ color: theme.palette.primary.main }} />
						{loadingPdf && (
							<Typography variant="titleSection" sx={{ fontWeight: '600' }}>
								{translate('proposals.exportPdfMessage')}
							</Typography>
						)}
					</Stack>
				)}
				{!loadingPdf && !loadingBook2c && (
					<Stack
						direction="column"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={2}
						sx={{ width: '100%' }}
					>
						<Typography variant="textTable" sx={{ fontWeight: '600' }}>
							{isClient
								? translate('proposals.exportPdfSelectLink')
								: translate('proposals.exportPdfFLdesc')}
						</Typography>
						{isClient && (
							<Fragment>
								<FormControl fullWidth>
									<InputLabel id="createPdf">{translate('proposals.exportPdfType')}</InputLabel>
									<Select
										labelId="createPdf"
										id="createPdfSelect"
										label={translate('proposals.exportPdfType')}
										value={baseLink}
										onChange={handleChange}
									>
										{linkType.map((tipo, index) => (
											<MenuItem key={tipo.id} value={tipo.value}>
												<Typography variant="textTable">{tipo.label}</Typography>
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<Stack
									direction="column"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={baseLink !== 'INLIB' ? 4 : 1}
									sx={{ width: '100%' }}
								>
									<Typography variant="textTable" sx={{ color: theme.palette.primary.main }}>
										{getLinkTypeText(baseLink)}
									</Typography>
									{baseLink === 'INLIB' && (
										<Stack
											direction="column"
											justifyContent="flex-start"
											alignItems="flex-start"
											spacing={0}
										>
											{book2cUrl.split('<br>').map((line, index) => (
												<Typography
													key={index}
													variant="text"
													sx={{ color: theme.palette.custom.black, fontWeight: '600' }}
												>
													{line}
												</Typography>
											))}
										</Stack>
									)}
									{baseLink !== 'FL' && baseLink !== 'INLIB' && (
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignItems="baseline"
											spacing={0}
											sx={{ width: '100%', marginTop: '8px !important' }}
										>
											<TextField
												variant="standard"
												required
												value={objectLink}
												error={errorObjectLink !== ''}
												helperText={errorObjectLink}
												onChange={handleChangeObjectLink}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															{getLinkTypeAddorment(baseLink)}
														</InputAdornment>
													)
												}}
												sx={{ width: '100%' }}
											/>
										</Stack>
									)}
								</Stack>
							</Fragment>
						)}
						<Box
							sx={{
								width: '100%',
								marginTop: '16px !important'
							}}
						>
							<FormControlLabel
								control={<Switch checked={checkePosition} onChange={handleChangePosition} />}
								label={
									<Typography variant="text">
										{translate('proposals.exportPdfLabelPosition')}
									</Typography>
								}
							/>
						</Box>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={0}
							sx={{ width: '100%' }}
						>
							<Button variant="light" onClick={onCloseModal} disabled={loadingPdf}>
								{translate('common.close')}
							</Button>
							<Button
								variant="dark"
								onClick={handleCreatePdf}
								disabled={
									loadingPdf ||
									loadingBook2c ||
									(isClient &&
										baseLink !== 'FL' &&
										(objectLink === '' ||
											errorObjectLink != '' ||
											(baseLink === 'INLIB' && !book2cUrl.trim())))
								}
							>
								{translate('proposals.exportPdf')}
							</Button>
						</Stack>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default ExportPdf;
