import search from './search';
import getScopeList from './get-scope-list';
import getPromotionList from './get-promotion-list';
import autocomplete from './autocomplete';
import getDetails from './get-details';
import getDiscount from './discount';
import exportPdf from './export-pdf';
import exportXls from './export-xls';
import dewey from './dewey';
import opacCheck from './opac-check';
import count from './count';
import location from './location';
import getBadges from './get-badges';
import getAllBadges from './get-allbadges';
import thema from './thema';
import themaCode from './thema-code';
import themaAutocomplete from './thema-autocomplete';
import themaEan from './thema-ean';

export default {
	search,
	getScopeList,
	getPromotionList,
	autocomplete,
	getDetails,
	getDiscount,
	exportPdf,
	exportXls,
	dewey,
	opacCheck,
	count,
	location,
	getBadges,
	getAllBadges,
	thema,
	themaCode,
	themaAutocomplete,
	themaEan
};
