import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Popover, Paper, Box, IconButton, Stack, Typography, Button, Tooltip, CircularProgress } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import utils from 'components/utils';

const OpenOrdersItemDelete = props => {
	const { row, cartId } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.order.remove.loading);
	const remove = useStoreActions(dispatch => dispatch.order.remove.sendDelete);
	const openModalDelete = useStoreState(state => state.order.remove.openModalDelete);
	const setOpenModalDelete = useStoreActions(dispatch => dispatch.order.remove.setOpenModalDelete);
	const iCan = utils.usePermissions();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		if (!row.showDelete || row.from === 'book2c') return;
		setAnchorEl(event.currentTarget);
		setOpenModalDelete(row.code);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpenModalDelete(null);
	};

	const onConfirm = _ => {
		//console.log('cancello', row.code, cartId, iCan('OPERATE_ON_MULTIPLE_CA'));
		remove({ code: row.code, id: cartId, amca: iCan('OPERATE_ON_MULTIPLE_CA') });
	};

	const rowInfo = [
		{ label: translate('catalog.tableHeaders.author'), info: row.author },
		{ label: translate('catalog.tableHeaders.title'), info: row.title },
		{ label: translate('catalog.tableHeaders.ean'), info: row.catalog },
		{ label: translate('catalog.tableHeaders.requestQty'), info: row.quantityRequired },
		{ label: translate('catalog.tableHeaders.publisher'), info: row.publisher }
	];

	return (
		<Box>
			{row.isAutorenew && (
				<Tooltip
					variant="light"
					arrow
					followCursor
					placement="top"
					title={<Typography variant="tooltip">{translate('orders.autorenew')}</Typography>}
				>
					<AutorenewIcon sx={{ color: theme.palette.custom.greyborder }} />
				</Tooltip>
			)}
			{!row.isAutorenew && (
				<Box>
					<Tooltip
						variant="light"
						arrow
						followCursor
						placement="top"
						title={
							row.showDelete ? translate('catalog.selection.remove') : translate('orders.deleteForbidden')
						}
					>
						<IconButton onClick={handleClick}>
							<ClearIcon
								sx={{
									color:
										!row.showDelete || row.from === 'book2c'
											? theme.palette.custom.greyborder
											: theme.palette.custom.red
								}}
							/>
						</IconButton>
					</Tooltip>
					<Popover
						open={open && openModalDelete === row.code}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'center',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'center',
							horizontal: 'left'
						}}
					>
						<Paper elevation={24}>
							<Stack
								direction="column"
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={2}
								variant="wrapperColor"
								sx={{ minWidth: { xs: '340px', md: '450px', sm: '400px' } }}
							>
								<Typography
									variant="titleSextion"
									sx={{
										fontWeight: '600',
										color: theme.palette.primary.main,
										borderWidth: '0 0 1px 0',
										borderStyle: 'solid',
										borderColor: theme.palette.primary.main,
										width: '100%'
									}}
								>
									{translate('orders.removeNum')}
								</Typography>
								{rowInfo.map(r => (
									<Typography variant="text" sx={{ textTransform: 'capitalize' }} key={r.label}>
										{<strong>{r.label}: </strong>}
										{r.info}
									</Typography>
								))}
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={4}
									sx={{
										width: '100%',
										marginTop: '10px',
										paddingTop: '10px',
										borderWidth: '1px 0 0 0',
										borderStyle: 'solid',
										borderColor: theme.palette.primary.main
									}}
								>
									<Box sx={{ width: '50%' }}>
										<Button variant="light" onClick={handleClose}>
											{translate('common.cancel')}
										</Button>
									</Box>
									<Box sx={{ width: '50%', textAlign: 'right' }}>
										{loading && (
											<CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />
										)}
										{!loading && (
											<Button variant="dark" onClick={onConfirm}>
												{translate('common.confirm')}
											</Button>
										)}
									</Box>
								</Stack>
							</Stack>
						</Paper>
					</Popover>
				</Box>
			)}
		</Box>
	);
};

export default OpenOrdersItemDelete;
