import React from 'react';
import { useStoreState } from 'easy-peasy';
import TableBodyMUI from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import utils from 'components/utils';
import allCols from './cols';
import withColumns from '../columns';

const TableBody = ({ rows, visibleCols }) => {
	const cells = visibleCols.map(col => allCols.find(c => c.id === col));
	const order = useStoreState(state => state.documents.search.orderDirection);
	const orderBy = useStoreState(state => state.documents.search.orderBy);
	const sortedRows = utils.stableSort(rows, order, orderBy);
	return (
		<TableBodyMUI>
			{sortedRows.map((item, index) => (
				<TableRow
					item={item}
					key={
						item.documentNumber !== null
							? `row_${item.documentNumber}_${index}`
							: `row_${item.docNumber}_${index}`
					}
				>
					{cells.map(cell => {
						return cell.comp({ item });
					})}
				</TableRow>
			))}
		</TableBodyMUI>
	);
};

export default withColumns(TableBody);
