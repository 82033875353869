import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const TypeComp = ({ text }) => {
	return <StyledTableCell>{text}</StyledTableCell>;
};

const Type = memo(
	({ item }) => <TypeComp text={item.typeDescription} />,
	(p, n) => p.item.typeDescription === n.item.typeDescription
);

export default Type;
