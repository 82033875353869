import React from 'react';
import Number from './Number';
import DocumentNumber from './DocumentNumber';
import Customer from './Customer';
import Depot from './Depot';
import Type from './Type';
import Invoice from './Invoice';
import Payment from './Payment';
import Amount from './Amount';
import Amount2 from './Amount2';
import Download from './Download';
import Date from './Date';
import ExpirationDate from './ExpirationDate';
import IssuingDate from './IssuingDate';
import MatchNumber from './MatchNumber';
import Selection from './Selection';

const allCells = [
	{
		id: 'type',
		comp: ({ item }) => <Type key={`bodyCell_type_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'number',
		comp: ({ item }) => <Number key={`bodyCell_number_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'documentNumber',
		comp: ({ item }) => <DocumentNumber key={`bodyCell_number_documents2_${item.docNum}`} item={item} />
	},
	{
		id: 'date',
		comp: ({ item }) => <Date key={`bodyCell_date_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'expirationDate',
		comp: ({ item }) => <ExpirationDate key={`bodyCell_expiration_date_${item.docNum}`} item={item} />
	},
	{
		id: 'issuingDate',
		comp: ({ item }) => <IssuingDate key={`bodyCell_issuing_date_${item.docNum}`} item={item} />
	},
	{
		id: 'matchNumber',
		comp: ({ item }) => <MatchNumber key={`bodyCell_match_number_${item.docNum}`} item={item} />
	},
	{
		id: 'customer',
		comp: ({ item }) => <Customer key={`bodyCell_customer_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'depot',
		comp: ({ item }) => <Depot key={`bodyCell_depot_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'amount',
		comp: ({ item }) => <Amount key={`bodyCell_amount_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'amount2',
		comp: ({ item }) => <Amount2 key={`bodyCell_amount2_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'invoice',
		comp: ({ item }) => <Invoice key={`bodyCell_invoice_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'payment',
		comp: ({ item }) => <Payment key={`bodyCell_payment_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'selection',
		comp: ({ item }) => <Selection key={`bodyCell_selection_documents_${item.docNum}`} item={item} />
	},
	{
		id: 'download',
		comp: ({ item }) => <Download key={`bodyCell_download_documents_${item.docNum}`} item={item} />
	}
];

export default allCells;
