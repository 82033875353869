import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const QuantityComp = ({ quantity }) => {
	return <StyledTableCell>{quantity}</StyledTableCell>;
};

const Quantity = memo(
	({ item }) => <QuantityComp quantity={item.quantity} />,
	(p, n) => p.item.quantity === n.item.quantity
);

export default Quantity;
