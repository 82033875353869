import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import NoResults from 'components/NoResults';
import TableHead from './TableHead';
import TableBody from './TableBody';
import utils from 'components/utils';

const UserGroupsTable = ({ data, loading, load }) => {
	const translate = utils.useI18N();
	const params = useParams();
	useEffect(
		_ => {
			load(params.id);
		},
		[load, params]
	);
	return (
		<Fragment>
			{loading && <CircularProgress size={40} />}
			{!loading && data.length === 0 && <NoResults text={translate('coupons.noUserGroups')} />}
			{!loading && data.length > 0 && (
				<TableContainer>
					<Table
						stickyHeader
						aria-labelledby="userGroups"
						size="small"
						padding="none"
						aria-label="result table"
					>
						<TableHead />
						<TableBody rows={data} />
					</Table>
				</TableContainer>
			)}
		</Fragment>
	);
};

export const UserGroupsTableAbilitation = _ => {
	const data = useStoreState(state => state.rankings.detailsUserGroupsAbilitation.data);
	const loading = useStoreState(state => state.rankings.detailsUserGroupsAbilitation.loading);
	const load = useStoreActions(dispatch => dispatch.rankings.detailsUserGroupsAbilitation.load);
	return <UserGroupsTable data={data} loading={loading} load={load} />;
};
