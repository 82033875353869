import React from 'react';
import TableBodyMUI from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import allCols from './cols';
import withColumns from '../columns';

const TableBody = ({ rows, visibleCols }) => {
	const cells = visibleCols.map(col => allCols.find(c => c.id === col));
	return (
		<TableBodyMUI>
			{rows.map((item, index) => (
				<TableRow style={{ height: 35 }} key={`row_${index}`}>
					{cells.map(cell => cell.comp({ item, index }))}
				</TableRow>
			))}
		</TableBodyMUI>
	);
};

export default withColumns(TableBody);
