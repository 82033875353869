import React from 'react';
import { Divider, MenuItem, Stack, Typography, alpha } from '@mui/material';
import { Link } from 'react-router-dom';
import utils from 'components/utils';
import { useTheme } from '@mui/material/styles';

const SupportList = props => {
	const { relativePath, allSubMenuStyles, setAnchorEl, secondaryStyle } = props;
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const translate = utils.useI18N();
	const [iAmPersonifying] = utils.usePersonification();
	const teamViewerUrl = 'https://www.teamviewer.com/it/download-automatico-teamviewer/';

	const handleClick = menuItem => {
		menuItem.openWindow && window.open(menuItem.openWindow, '_blank');
		setAnchorEl(null);
	};
	const menuItems = [
		{
			id: 'assistance',
			label: 'assistance',
			to: '/support',
			iCan: iCan('HELP')
		},
		{
			id: 'contact',
			label: 'contact',
			to: '/contact',
			iCan: !iAmPersonifying && iCan('CONTACT_AGENT')
		},
		{
			id: 'teamViewer',
			label: 'support',
			openWindow: teamViewerUrl,
			iCan: true
		}
	];
	return (
		<Stack
			sx={{ padding: `${secondaryStyle.margin}px` }}
			justifyContent="center"
			alignItems="center"
			direction="column"
		>
			<Typography variant="overline" sx={{ ...allSubMenuStyles.title }}>
				{translate('common.menu.help')}
			</Typography>
			<Divider sx={{ ...allSubMenuStyles.divider }} />
			{menuItems.map((menuItem, index) => {
				const isSelectedPath = relativePath === menuItem.to;
				return (
					menuItem.iCan && (
						<MenuItem
							key={index}
							sx={{
								...allSubMenuStyles.singleItem,
								backgroundColor:
									isSelectedPath && alpha(theme.palette.primary.main, secondaryStyle.opacity),
								cursor: isSelectedPath && 'default' // cannot operate on disable prop to change color and background, so faking disable
							}}
							disableTouchRipple={isSelectedPath} // same as ↑
							onClick={_ => handleClick(menuItem)}
							component={menuItem.to && Link}
							to={menuItem.to}
						>
							{translate(`common.menu.${menuItem.label}`)}
						</MenuItem>
					)
				);
			})}
		</Stack>
	);
};

export default SupportList;
