import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import HeadlessCard from 'components/Card/HeadlessCard';
import styles from './styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch/Switch';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';

const Filters = ({ classes, close, showCloseIcon }) => {
	const translate = utils.useI18N();
	const token = useStoreState(state => state.coupons.filters.list.token);
	const filtersArePristine = useStoreState(state => state.coupons.filters.arePristine);
	const statusValue = useStoreState(state => state.coupons.filters.list.statusValue);
	const setStatusValue = useStoreActions(dispatch => dispatch.coupons.filters.setStatusValue);
	const status = useStoreState(state => state.coupons.filters.list.status);
	const setSearchOpen = useStoreActions(dispatch => dispatch.coupons.filters.setSearchOpen);
	const setToken = useStoreActions(dispatch => dispatch.coupons.filters.setToken);
	const reset = useStoreActions(dispatch => dispatch.coupons.filters.reset);
	const setStatus = useStoreActions(dispatch => dispatch.coupons.filters.setStatus);
	const search = useStoreActions(dispatch => dispatch.coupons.search.performSearch);
	const restore = _ => {
		reset();
		search();
	};
	return (
		<div
			className={clsx(classes.negateMargin, {
				[classes.margin]: showCloseIcon
			})}
		>
			<LocalizationProvider utils={MomentUtils}>
				<HeadlessCard>
					<Grid container spacing={2}>
						{showCloseIcon && (
							<Grid container item xs={12} justify="flex-end">
								<IconButton onClick={close} className={classes.closeIcon}>
									<CloseIcon />
								</IconButton>
							</Grid>
						)}
						<Grid container item xs={12} className={classes.elements}>
							<div className={classes.search}>
								<InputBase
									startAdornment={<SearchIcon />}
									placeholder={translate('coupons.searchInCoupons')}
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput
									}}
									value={token}
									onChange={e => setToken(e.target.value)}
									inputProps={{ 'aria-label': 'search' }}
								/>
							</div>
						</Grid>
						<Grid container item xs={6} className={classes.elements}>
							<FormGroup aria-label="position" row style={{ height: '100%', alignContent: 'flex-end' }}>
								<FormControlLabel
									checked={status}
									control={<Checkbox color="primary" onChange={e => setStatus(e.target.checked)} />}
									label={translate('coupons.statusFilterToggle')}
								/>
							</FormGroup>
						</Grid>
						<Grid container item xs={6} className={classes.elements} justify="flex-end">
							<Typography component="div">
								<Grid component="label" container alignItems="center" spacing={1}>
									<Grid item>Off</Grid>
									<Grid item>
										<Switch
											color="primary"
											size="medium"
											checked={statusValue}
											onChange={e => setStatusValue(e.target.checked)}
										/>
									</Grid>
									<Grid item>On</Grid>
								</Grid>
							</Typography>
						</Grid>
						<Grid container item xs={12} spacing={0} className={classes.elements} style={{ marginTop: 16 }}>
							<Grid container item xs={8}>
								<Button
									disableElevation
									variant="contained"
									color="primary"
									disabled={filtersArePristine}
									onClick={search}
								>
									{translate('common.search.search')}
								</Button>
								<Button
									style={{ marginLeft: 8 }}
									disableElevation
									variant="outlined"
									color="primary"
									onClick={restore}
								>
									{translate('common.reset')}
								</Button>
							</Grid>
							<Grid container item xs={4} justify="flex-end">
								<Button
									style={{ marginLeft: 8 }}
									disableElevation
									variant="outlined"
									color="primary"
									onClick={_ => setSearchOpen(false)}
								>
									{translate('common.close')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</HeadlessCard>
			</LocalizationProvider>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(Filters);
