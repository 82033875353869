import React, { Fragment } from 'react';
import { useStoreActions } from 'easy-peasy';
import Button from '@mui/material/Button';
import utils from 'components/utils';

const ResetButton = ({ stepBack }) => {
	const reset = useStoreActions(dispatch => dispatch.importt.reset);
	const translate = utils.useI18N();
	return (
		<Fragment>
			<Button variant="light" onClick={_ => reset()}>
				{translate('wishlist.stepBack')}
			</Button>
		</Fragment>
	);
};

export default ResetButton;
