import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { Grid, Typography, Checkbox, FormControlLabel, Box } from '@mui/material';
import utils from 'components/utils';
import moment from 'moment';

const WishlistInfo = ({ item }) => {
	const translate = utils.useI18N();
	const toggleSelection = useStoreActions(dispatch => dispatch.conditionRequest.pendingList.toggleSelection);

	return (
		<Grid container wrap="nowrap" item xs={7} sm={10} md={10}>
			<Grid item xs={12} sm md={3}>
				<FormControlLabel
					aria-label="Acknowledge"
					onClick={event => event.stopPropagation()}
					control={
						<Checkbox
							checked={item.isSelected}
							color="primary"
							onChange={e => {
								toggleSelection(item.id);
							}}
							disabled={!item.responsible}
							style={!item.responsible ? { display: 'none' } : { display: 'block' }}
						/>
					}
					label={
						<div>
							<Typography variant="subtitle2" style={{ color: 'black' }}>
								{utils.capitalize(item.userDisplayName)}
							</Typography>
							<Typography variant="caption" style={{ color: 'black' }}>
								<b>Id:</b>&nbsp;{utils.capitalize(item.userCode)}
							</Typography>
						</div>
					}
				/>
			</Grid>
			<Grid item md={3} sx={{ alignSelf: 'flex-end', display: { xs: 'none', md: 'inherit', sm: 'none' } }}>
				<Typography variant="caption">
					<b>{translate('wishlist.summary.agent')}:</b>&nbsp;
					{utils.capitalize(item.creatorName)}
				</Typography>
			</Grid>
			<Grid item md={3} sx={{ alignSelf: 'flex-end', display: { xs: 'none', md: 'block', sm: 'none' } }}>
				<Grid item>
					<Typography variant="caption">
						<b>{translate('wishlist.summary.creationDate')}:</b>&nbsp;
						{moment(item.wishlistCreationDate).format('L')}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="caption">
						<b>{translate('wishlist.summary.lastModificationDate')}:</b>&nbsp;
						{moment(item.wishlistLastModificationDate).format('L')}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				item
				sm={7}
				md={6}
				justify="space-evenly"
				direction="column"
				sx={{ display: { xs: 'none', md: 'inherit', sm: 'inherit' } }}
			>
				<Grid item>
					{item.wishlistType === 'BOOK2C' && (
						<Typography variant="body2">
							<strong>{translate('wishlist.summary.name')}</strong>:&nbsp;
							{item.wishlistName}
						</Typography>
					)}
					{item.wishlistType === null && (
						<Typography variant="body2">
							<strong>{translate('wishlist.summary.name')}</strong>:&nbsp;
							{item.wishlistName}
						</Typography>
					)}
				</Grid>
				<Grid item>
					<Typography variant="body2">
						<strong>{translate('wishlist.summary.condition')}</strong>:&nbsp;
						{utils.capitalizeAll(item.promotionName)}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default WishlistInfo;
