import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const TitleComp = ({ ean, title }) => {
	return <StyledTableCell>{utils.capitalize(title)}</StyledTableCell>;
};

const Title = memo(
	({ item }) => <TitleComp ean={item.ean} title={item.title} />,
	(p, n) => p.item.title === n.item.title && p.item.ean === n.item.ean
);

export default Title;
