import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import get from 'lodash/get';
import TextFieldMUI from '@mui/material/TextField';
import utils from 'components/utils';

const TextField = props => {
	const disabled = get(props, 'disabled', false);
	const translate = utils.useI18N();
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const path = get(props, 'path');
	const label = translate(`catalog.${path}`);
	const testId = `${path}.field`;
	const value = useStoreState(state => state.catalog.filters.list[path]);
	return (
		<TextFieldMUI
			disabled={disabled}
			fullWidth={true}
			label={label === 'volume' ? 'Volume' : label}
			name={path}
			value={value}
			onChange={event => {
				const { name, value } = event.target;
				setFilters({
					[name]: value
				});
			}}
			inputProps={{
				'data-testid': testId
			}}
		/>
	);
};

export default TextField;
