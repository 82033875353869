import { action, thunk, actionOn } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	loadedList: false,
	setLoadedList: action(state => {
		state.loadedList = true;
	}),
	resetList: action(state => {
		state.loadedList = false;
		state.items = [];
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.wishlist
			.list()
			.then(data => {
				actions.setLoadedList();
				actions.save(data.items);
			})
			.catch(e => {
				getStoreActions().error('order.wishlist');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	loadTitle: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.wishlist
			.listTitle()
			.then(data => {
				actions.setLoadedList();
				actions.save(data.items);
			})
			.catch(e => {
				getStoreActions().error('order.wishlist');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	items: [],
	select: action((state, payload) => {
		state.items = state.items.map(s => ({
			...s,
			selected: s.id === payload
		}));
	}),
	save: action((state, payload) => {
		const available = payload.filter(item => item.status === 'INITIAL' && item.type !== 'BOOK2C');
		state.items = available.map((item, index) => ({
			...item,
			selected: item.favourite ? true : index === 0
		}));
	}),
	updateOnCreate: actionOn(
		(actions, storeActions) => [storeActions.wishlist.create.sendRequest.successType],
		(state, target) => {
			const {
				result: { item }
			} = target;
			item.selected = true;
			state.items = state.items
				.map(itm => ({
					...itm,
					selected: false
				}))
				.concat(item);
		}
	)
};
