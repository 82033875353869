import { action, thunk } from 'easy-peasy';

export default {
	open: false,
	setOpen: action((state, payload) => {
		state.open = payload;
	}),
	dateFrom: null,
	dateTo: null,
	loading: false,
	saveDateFrom: action((state, payload) => {
		state.dateFrom = payload;
	}),
	saveDateTo: action((state, payload) => {
		state.dateTo = payload;
	}),
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	resetAdd: action((state, payload) => {
		state.dateFrom = null;
		state.dateTo = null;
		state.open = false;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.coupons
			.createCoupon(payload)
			.then(data => {
				getStoreActions().success('coupons.add');
				if (getStoreState().coupons.importt.fileName)
					getStoreActions().coupons.importt.sendRequest(data.item.id);
				setTimeout(_ => {
					getStoreActions().coupons.list.load();
				}, 1000);
				getStoreActions().coupons.add.resetAdd();
				return Promise.resolve(data.items);
			})
			.catch(e => {
				//getStoreActions().error('coupons.errorAdd');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
