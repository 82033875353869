import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { TableBody, TableRow, TableCell, Checkbox, Stack, Typography, Tooltip } from '@mui/material';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import SellIcon from '@mui/icons-material/Sell';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import utils from 'components/utils';
import OpenOrdersItemDelete from './OpenOrdersItemDelete';

const OpenOrdersItemsTableBody = props => {
	const { cart } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const user = utils.useUserData('', null);
	const personify = useStoreState(state => state.me.personify);
	const order = useStoreState(state => state.cart.open.orderDirection);
	const orderBy = useStoreState(state => state.cart.open.orderBy);
	const toggleSelection = useStoreActions(dispatch => dispatch.cart.open.toggleSelection);
	const fulfillmentFailed = useStoreState(state => state.cart.open.fulfillmentFailed);
	const filteredRows = cart.orderItems.filter(row => row.visible);
	const sortedRows = utils.stableSort(filteredRows, order, orderBy);
	const showSapInfo = utils.useUserData('showSapInfo', false);
	const isBook2C = cart.ordertype === 'BOOK2C';
	const checkboxEnabled =
		(!isBook2C && personify.target.id !== '') || (personify.target.id === '' && user.userType !== '02');
	const [depotsInProgress, setDepotsInProgress] = useState([]);

	const colorMap = {
		white: theme.palette.table.white,
		yellow: theme.palette.table.yellow,
		green: theme.palette.table.green,
		red: theme.palette.table.red,
		grey: theme.palette.custom.grey
	};

	useEffect(() => {
		//Recupero i depositi sui quali cìè un'evantuale evasioni in corso (gestita da BOOMI)
		const checkFulfillmentProgress = fulfillmentFailed.filter(f =>
			cart.name === '' ? f.orderNumber === '' : f.orderNumber.split(' - ')[0] === cart.id.toString()
		);
		if (checkFulfillmentProgress.length > 0) {
			const listDepost = [];
			checkFulfillmentProgress.map(f => {
				if (!listDepost.includes(f.depotCode)) listDepost.push(f.depotCode);
			});
			setDepotsInProgress(listDepost);
		}
	}, [cart]);

	return (
		<TableBody>
			{sortedRows.map((row, index) => (
				<TableRow
					key={`row_${index}`}
					sx={{
						backgroundColor: depotsInProgress.includes(row.depot)
							? colorMap['grey']
							: colorMap[row.rowColor]
					}}
				>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						{checkboxEnabled && !isBook2C && row.deletable && (
							<Checkbox
								checked={row.isSelected}
								onChange={e => toggleSelection({ orderid: cart.id, code: row.code })}
								inputProps={{ 'aria-labelledby': row.code }}
							/>
						)}
						{(!checkboxEnabled || isBook2C || !row.deletable) && (
							<Typography variant="textTable">&nbsp;</Typography>
						)}
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						<OpenOrdersItemDelete row={row} cartId={cart.id} />
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						<Typography variant="textTable" sx={{ textTransform: 'capitalize' }}>
							{row.author}
						</Typography>
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						{row.type === 200 && (
							<CardGiftcardIcon sx={{ color: theme.palette.primary.main, fontSize: '22px' }} />
						)}
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
							<Link
								to={`/orders/list/details/${row.code.slice(0, 10)}/${cart.idForSelection}/${
									row.catalog
								}`}
								style={{ textDecoration: 'none' }}
							>
								<Typography variant="textTable" sx={{ color: theme.palette.primary.main }}>
									{row.title}
								</Typography>
							</Link>
							<Typography variant="textTable">{row.catalog}</Typography>
						</Stack>
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						<Typography variant="textTable" sx={{ textTransform: 'capitalize' }}>
							{row.publisher}
						</Typography>
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						{row.promoCode && row.isPromoActive && (
							<Tooltip
								variant="light"
								arrow
								followCursor
								placement="top"
								title={row.promoDesc ? row.promoDesc : row.promoCode ? row.promoCode : row.promoFromWl}
							>
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={1}
									sx={{ color: theme.palette.custom.green }}
								>
									<Typography variant="textTable">{utils.formatPrice(row.price)}</Typography>
									<SellIcon sx={{ color: theme.palette.custom.green }} />
								</Stack>
							</Tooltip>
						)}
						{(!row.promoCode || !row.isPromoActive) && (
							<Typography variant="textTable">{utils.formatPrice(row.price)}</Typography>
						)}
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						{!depotsInProgress.includes(row.depot) && (
							<Typography variant="textTable">{row.depotDesc}</Typography>
						)}
						{depotsInProgress.includes(row.depot) && (
							<Tooltip
								variant="light"
								arrow
								followCursor
								placement="top"
								title={translate('orders.evasion.fulfillmentInProgess')}
							>
								<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
									<Typography variant="textTable" sx={{ color: theme.palette.primary.main }}>
										{row.depotDesc}
									</Typography>
									<RotateRightIcon sx={{ color: theme.palette.primary.main }} />
								</Stack>
							</Tooltip>
						)}
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
							<Typography variant="textTable">{row.date.format('L')}</Typography>
							{showSapInfo && (
								<Stack
									direction="column"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={0}
								>
									<Typography variant="textTable" sx={{ color: theme.palette.custom.red }}>
										{row.code}
									</Typography>
									<Typography variatn="textTable" sx={{ color: theme.palette.custom.red }}>
										User: {row.realUserCode ? row.realUserCode : 'batch'}
									</Typography>
								</Stack>
							)}
						</Stack>
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px', textAlign: 'center' }}>
						<Typography variant="textTable" sx={{ textAlign: 'center' }}>
							{row.quantityRequired}
						</Typography>
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px', textAlign: 'center' }}>
						<Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5}>
							<Typography
								variant="textTable"
								sx={{ color: row.showWarning ? theme.palette.custom.orange : 'inherit' }}
							>
								{row.quantityEvadible}
							</Typography>
							{row.showWarning && (
								<Tooltip
									variant="light"
									arrow
									followCursor
									placement="top"
									title={translate('orders.warningEvasion') + ' 2gg'}
								>
									<ProductionQuantityLimitsIcon sx={{ color: theme.palette.custom.orange }} />
								</Tooltip>
							)}
						</Stack>
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						<Typography variant="textTable">{row.notice}</Typography>
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						<Typography variant="textTable">{row.slaDesc}</Typography>
					</TableCell>
					<TableCell sx={{ padding: '4px 0 4px 4px' }}>
						<Typography variant="textTable">{row.notes}</Typography>
					</TableCell>
				</TableRow>
			))}
		</TableBody>
	);
};

export default OpenOrdersItemsTableBody;
