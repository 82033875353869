import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const NumberComp = ({ text }) => {
	return <StyledTableCell>{text}</StyledTableCell>;
};

const Numberr = memo(
	({ item }) => <NumberComp text={item.docNum} />,
	(p, n) => p.item.docNum === n.item.docNum
);

export default Numberr;
