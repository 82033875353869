import { action, actionOn, thunkOn } from 'easy-peasy';

const createPagination = opts => ({
	pagination: {
		current: 1,
		pageCount: 0,
		totalItems: 0,
		step: opts.step
	},
	restorePagination: action((state, payload) => {
		state.pagination.step = opts.step;
		state.pagination.current = 1;
		state.pagination.pageCount = 0;
	}),
	setPaginationCurrent: action((state, payload) => {
		state.pagination.current = payload;
	}),
	setPaginationStep: action((state, payload) => {
		state.pagination.step = payload;
	}),
	setPaginationPageCount: action((state, payload) => {
		state.pagination.pageCount = payload;
	}),
	resetCurrentPageOnStepChange: actionOn(
		(actions, storeActions) => [actions.setPaginationStep],
		(state, target) => {
			state.pagination.current = 1;
		}
	),
	updatePaginationOnSave: actionOn(
		(actions, storeActions) => [actions[opts.paginationTrigger]],
		(state, target) => {
			const { page, totalItems, rows } = target.payload;
			state.pagination.current = page + 1;
			state.pagination.step = rows;
			state.pagination.pageCount = Math.ceil(totalItems / rows);
			state.pagination.totalItems = totalItems;
		}
	),
	loadOnPaginationChange: thunkOn(
		(actions, storeActions) => [actions.setPaginationCurrent, actions.setPaginationStep],
		async (actions, target, helpers) => {
			if (opts.loadFn !== '') actions[opts.loadFn](actions, null, helpers);
		}
	)
});

export default createPagination;
