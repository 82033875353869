import search from './search';
import filters from './filters';
import scopes from './scopes';
import promotions from './promotions';
import autocomplete from './autocomplete';
import details from './details';
import discount from './discount';
import order from './order';
import exportt from './export';
import dewey from './dewey';
import opacCheck from './opac-check';
import location from './location';
import count from './count';
import badges from './badges';
import thema from './thema';
import themaAutocomplete from './thema-autocomplete';

export default {
	search,
	filters,
	scopes,
	promotions,
	autocomplete,
	details,
	discount,
	order,
	exportt,
	dewey,
	opacCheck,
	location,
	count,
	badges,
	thema,
	themaAutocomplete
};
