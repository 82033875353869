import React from 'react';
import Selection from './Selection';
import Sortable from './Sortable';

const allCols = [
	{
		id: 'selection',
		comp: _ => <Selection key="headCell_selection" />
	},
	{
		id: 'ean',
		comp: _ => <Sortable id="ean" key="headCell_ean" label="catalog.tableHeaders.ean" />
	},
	{
		id: 'author',
		comp: _ => <Sortable id="author" key="headCell_author" label="catalog.tableHeaders.author" />
	},
	{
		id: 'title',
		comp: _ => (
			<Sortable
				id="title"
				key="headCell_title"
				label="catalog.tableHeaders.title"
				additionalStyles={{ maxWidth: 300 }}
			/>
		)
	},
	{
		id: 'serie',
		comp: _ => <Sortable id="serie" key="headCell_serie" label="catalog.tableHeaders.serie" />
	},
	{
		id: 'publisher',
		comp: _ => <Sortable id="publisher" key="headCell_publisher" label="catalog.tableHeaders.publisher" />
	},
	{
		id: 'publicationDate',
		comp: _ => (
			<Sortable
				id="publicationDate"
				key="headCell_publicationDate"
				label="catalog.tableHeaders.publicationDate"
				additionalStyles={{ width: 45 }}
			/>
		)
	}
];

export default allCols;
