import { action, thunk, thunkOn } from 'easy-peasy';
import storeUtils from 'store/utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	count: 0,
	setCount: action((state, payload) => {
		state.count = payload;
	}),
	expanded: -1,
	setExpanded: action((state, payload) => {
		state.expanded = payload;
	}),
	inCartCount: null,
	setInCartCount: action((state, payload) => {
		state.inCartCount = payload;
	}),
	load: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		const reset = payload && payload.reset;
		const { services } = injections;
		actions.setLoading(true);
		const page = reset ? 0 : getState().pagination.current - 1;
		const step = getState().pagination.step;
		const dirtyFilters = getStoreState().conditionRequest.filters.list;
		const filters = dirtyFilters.promoId === '_empty_' ? { ...dirtyFilters, promoId: '' } : dirtyFilters;
		services.conditionRequest
			.getRefusedList({ page, filters, step })
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('wishlist.retrieveConditionDetails');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	...storeUtils.createPagination({ step: 25, paginationTrigger: 'save', loadFn: 'load' }),
	getInCartCount: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const status = 'REJECTED';
		const { services } = injections;
		const dirtyFilters = getStoreState().conditionRequest.filters.list;
		const filters = dirtyFilters.promoId === '_empty_' ? { ...dirtyFilters, promoId: '' } : dirtyFilters;
		services.conditionRequest
			.getInCartCount({ filters, status })
			.then(data => {
				const filteredData = filters.intoCart || filters.intoCart === '' ? data : 0;
				actions.setInCartCount(filteredData);
			})
			.catch(e => {
				getStoreActions().error('wishlist.retrieveConditionDetails');
			});
	}),
	getCount: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const dirtyFilters = getStoreState().conditionRequest.filters.list;
		const filters = dirtyFilters.promoId === '_empty_' ? { ...dirtyFilters, promoId: '' } : dirtyFilters;
		services.conditionRequest
			.getRefusedCount({ filters })
			.then(data => actions.setCount(data))
			.catch(e => {
				getStoreActions().error('wishlist.retrieveConditionDetails');
			});
	}),
	search: thunkOn(
		(actions, storeActions) => [
			storeActions.conditionRequest.filters.performSearch,
			storeActions.conditionRequest.filters.reset
		],
		async (actions, target, helpers) => {
			actions.load();
			actions.getCount();
			actions.getInCartCount();
		}
	),
	save: action((state, payload) => {
		state.items = payload.items.map(item => ({
			...item
		}));
	}),
	updateConditionChange: thunkOn(
		(actions, storeActions) => [
			storeActions.conditionRequest.approve.sendRequest.successType,
			storeActions.conditionRequest.reject.sendRequest.successType,
			storeActions.conditionRequest.update.sendRequest.successType,
			storeActions.conditionRequest.approveMultiple.sendRequest.successType,
			storeActions.conditionRequest.rejectMultiple.sendRequest.successType,
			storeActions.conditionRequest.updateMultiple.sendRequest.successType
		],
		async (actions, target, helpers) => {
			actions.load(actions, null, helpers);
			actions.getCount();
		}
	),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	})
};
