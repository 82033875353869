import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import moment from 'moment';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { red, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Grid from '@mui/material/Grid';
import { DateCalendar, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Card from 'components/Card';
import utils from 'components/utils';
import styles from './styles';
import { alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const redTheme = createTheme({ palette: { primary: red } });
const greenTheme = createTheme({ palette: { primary: green } });

const ReturnRequests = props => {
	const { classes } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const dayList = useStoreState(state => state.returns.calendar.items);
	const loading = useStoreState(state => state.returns.calendar.loading);
	const getDays = useStoreActions(dispatch => dispatch.returns.calendar.getDays);
	const setSingleDay = useStoreActions(dispatch => dispatch.returns.calendar.setSingleDay);
	const setAllMonth = useStoreActions(dispatch => dispatch.returns.calendar.setAllMonth);
	const [currentDate, setCurrentDate] = useState(moment());
	const today = new Date();
	const viewSelectDeselectAll =
		currentDate >= today || (currentDate.year() == today.getFullYear() && currentDate.month() == today.getMonth());
	const minDate = moment('01/01/2022');
	const maxDate = moment().add(1, 'y');
	useEffect(
		_ => {
			const now = moment();
			getDays({ month: now.month() + 1, year: now.year() });
		},
		[getDays]
	);
	const onDateChange = date => {
		const formattedDay = date.format('DD/MM/YYYY');
		const d = dayList.find(item => item.day === formattedDay);
		const isAvailable = d && d.available;
		setSingleDay({ day: formattedDay, available: !isAvailable });
		setCurrentDate(date);
	};
	const onMonthChange = date => {
		getDays({ month: date.month() + 1, year: date.year() });
		setCurrentDate(date);
	};
	const onYearChange = date => {
		getDays({ month: date.month() + 1, year: date.year() });
		setCurrentDate(date);
	};
	const toggle = available => {
		setAllMonth({ month: currentDate.month() + 1, year: currentDate.year(), available });
	};
	const disabledDate = date => {
		return date < today;
	};
	const renderDay = props => {
		const { day, selectedDay } = props;
		const getDayClass = day => {
			if (!day) return theme.palette.custom.white;
			const formattedDay = day.format('DD/MM/YYYY');
			const d = dayList.find(item => item.day === formattedDay);
			return d && d.available ? theme.palette.custom.green : theme.palette.custom.red;
		};
		return (
			<PickersDay
				isFirstVisibleCell={false}
				isLastVisibleCell={false}
				outsideCurrentMonth={false}
				onDaySelect={onDateChange}
				day={day}
				sx={{
					backgroundColor: alpha(getDayClass(day), 0.5),
					'&:hover': {
						backgroundColor: alpha(getDayClass(day), 0.3)
					},
					transition: '0.2s'
				}}
				disableRipple
				disabled={moment(day).isBefore(moment(), 'day')}
			/>
		);
	};
	return (
		<Card type="primary" title={translate('admin.returnRequests.title')} style={{ marginTop: 0 }}>
			<Grid container spacing={1}>
				<Grid item style={{ width: 465 }}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						{/* <DatePicker
							autoOk
							value={currentDate}
							onChange={onDateChange}
							format="D MMMM yyyy"
							variant="static"
							orientation="landscape"
							//disablePast -> abilitati anche i mesi precedenti
							maxDate={maxDate}
							minDate="20220101"
							onMonthChange={onMonthChange}
							onYearChange={onYearChange}
							renderDay={renderDay}
							disabled={loading}
							shouldDisableDate={disabledDate}
							label=""
						/> */}
						<DateCalendar
							minDate={minDate}
							maxDate={maxDate}
							slots={{ day: renderDay }}
							slotProps={{
								day: {
									selectedDay: currentDate
								}
							}}
							value={currentDate}
							onChange={onDateChange}
							onMonthChange={onMonthChange}
							onYearChange={onYearChange}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs style={{ marginLeft: 16 }}>
					<Box>
						<Typography variant="h4" component="p">
							{currentDate.format('MMMM YYYY')}
						</Typography>
					</Box>
					<Box>
						<ThemeProvider theme={greenTheme}>
							<IconButton
								color="primary"
								onClick={_ => {
									toggle(true);
								}}
								disabled={!viewSelectDeselectAll}
							>
								<AddCircleIcon />
							</IconButton>
						</ThemeProvider>
						<Typography variant="body1" component="span">
							{translate('common.selectAll')}
						</Typography>
					</Box>
					<Box>
						<ThemeProvider theme={redTheme}>
							<IconButton
								color="primary"
								onClick={_ => {
									toggle(false);
								}}
								disabled={!viewSelectDeselectAll}
							>
								<RemoveCircleIcon />
							</IconButton>
						</ThemeProvider>
						<Typography variant="body1" component="span">
							{translate('common.deselectAll')}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Card>
	);
};

export default withStyles(styles, { withTheme: true })(ReturnRequests);
