import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import utils from 'components/utils';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const DatePickerFrom = props => {
	const translate = utils.useI18N();
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const pubDateFrom = useStoreState(state => state.catalog.filters.list.pubDateFrom);

	const onDateFromChange = d => {
		if (d) return setFilters({ pubDateFrom: d.toISOString() });
		if (!d) return setFilters({ pubDateFrom: null });
	};

	return (
		<DatePicker
			autoOk
			value={pubDateFrom ? moment(pubDateFrom) : null}
			onChange={onDateFromChange}
			variant="dialog"
			disableToolbar
			clearable
			label={translate('catalog.filters.pubDateFrom') + ':'}
			sx={{ width: '100%' }}
		/>
	);
};

const DatePickerTo = props => {
	const translate = utils.useI18N();
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const pubDateTo = useStoreState(state => state.catalog.filters.list.pubDateTo);

	const onDateToChange = d => {
		if (d) return setFilters({ pubDateTo: d.toISOString() });
		if (!d) return setFilters({ pubDateTo: null });
	};

	return (
		<DatePicker
			autoOk
			value={pubDateTo ? moment(pubDateTo) : null}
			onChange={onDateToChange}
			fullWidth={true}
			variant="dialog"
			disableToolbar
			label={translate('catalog.filters.pubDateTo') + ':'}
			sx={{ width: '100%' }}
		/>
	);
};

export { DatePickerFrom, DatePickerTo };
