import React, { useMemo, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Stack, Button, Typography, Box, TableContainer, Table } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody';
import utils from 'components/utils';

const SearchTable = props => {
	const { rows } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const availableDepots = utils.useUserData('availableDepots', []);
	const results = useStoreState(state => state.catalog.search.results);
	const items = results.filter(r => r.isSelected);
	const selection = useStoreState(state => state.selection.items);
	const setSelectionModalOpen = useStoreActions(dispatch => dispatch.selection.setModalOpen);
	const setWarningPromo = useStoreActions(dispatch => dispatch.selection.setWarningPromo);
	const isDisabled = availableDepots.length === 0;

	const idSelections = useMemo(() => {
		let id = '';

		items.forEach((value, index) => {
			if (index === 0) id = '?';
			id = id + 'ean=' + value.ean;
			if (index < items.length - 1) id = id + '&';
		});
		return id;
	}, [items]);

	const next = _ => {
		const hasPromo = selection.some(item => item.isPromo);
		setSelectionModalOpen(true);
		if (hasPromo) setWarningPromo(true);
	};

	const ToolbarTable = _ => {
		return (
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={1}
				sx={{ width: '100%', marginTop: '20px' }}
			>
				<Box sx={{ width: '90%' }}>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						{items.length > 0 ? items.length + ' ' + translate('common.selected') : ''}
					</Typography>
				</Box>
				{items.length > 0 && (
					<Button
						variant="light"
						component={Link}
						onClick={_ => next()}
						to={`/catalog/search/${idSelections}`}
						disabled={isDisabled}
						endIcon={<KeyboardDoubleArrowRightIcon />}
					>
						{translate('common.continue')}
					</Button>
				)}
			</Stack>
		);
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			{/* Toolbar tabella */}
			<ToolbarTable />
			{/* Elenco Titoli */}
			<TableContainer sx={{ maxHeight: { xs: '400px', md: '800px', sm: '600px' } }}>
				<Table stickyHeader aria-labelledby="searchResults" aria-label="result table">
					<TableHead />
					<TableBody rows={rows} />
				</Table>
			</TableContainer>
			{/* Toolbar tabella */}
			<ToolbarTable />
		</Stack>
	);
};

export default SearchTable;
