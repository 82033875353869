import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Popover, Paper, Box, IconButton, Stack, Typography, Tooltip, CircularProgress, Button } from '@mui/material';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import utils from 'components/utils';

const OpenOrdersItemSelectedDelete = props => {
	const { id, name, itemSelected } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const loading = useStoreState(state => state.order.remove.loading);
	const deleteItems = useStoreActions(dispatch => dispatch.order.remove.deleteItems);
	const codeSelected = itemSelected.map(item => item.code);
	const deleteDisabled = itemSelected.length > 150;
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onConfirm = _ => {
		deleteItems({ id, codeSelected, amca: iCan('OPERATE_ON_MULTIPLE_CA') });
	};

	return (
		<Box>
			<Tooltip variant="light" arrow followCursor placement="top" title={translate('orders.deleteItems')}>
				<IconButton sx={{ color: theme.palette.secondary.main }} onClick={handleClick}>
					<DeleteSweepIcon sx={{ fontSize: '1.2em' }} />
				</IconButton>
			</Tooltip>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
			>
				<Paper elevation={24}>
					<Stack
						direction="column"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={1}
						variant="wrapperColor"
						sx={{ minWidth: { xs: '340px', md: '450px', sm: '400px' } }}
					>
						<Typography
							variant="titleSextion"
							sx={{
								fontWeight: '600',
								color: theme.palette.primary.main,
								borderWidth: '0 0 1px 0',
								borderStyle: 'solid',
								borderColor: theme.palette.primary.main,
								width: '100%'
							}}
						>
							{translate('orders.deleteItems')}
						</Typography>
						<Typography
							variant="titleSection"
							sx={{ color: deleteDisabled ? theme.palette.custom.red : 'inherit' }}
						>
							{deleteDisabled
								? translate('orders.tooManyTitleSelected')
								: translate('orders.confirmOrderItemsRemove')}
						</Typography>
						{deleteDisabled && (
							<Typography variant="titleSection" sx={{ margin: '0px 0 10px 0 !important' }}>
								{translate('orders.titleSelectedMax') + ': 150'}
							</Typography>
						)}
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={0}
							sx={{ marginTop: '10px' }}
						>
							<Typography variant="text" sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
								{translate('orders.order') + ': '}
							</Typography>
							<Typography variant="text">{name}</Typography>
						</Stack>
						<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
							<Typography variant="text" sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
								{translate('orders.titles') + ': '}
							</Typography>
							<Typography variant="text">{itemSelected.length}</Typography>
						</Stack>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={4}
							sx={{
								width: '100%',
								marginTop: '15px !important',
								paddingTop: '10px',
								borderWidth: '1px 0 0 0',
								borderStyle: 'solid',
								borderColor: theme.palette.primary.main
							}}
						>
							<Box sx={{ width: '50%' }}>
								<Button variant="light" onClick={handleClose}>
									{translate('common.cancel')}
								</Button>
							</Box>
							<Box sx={{ width: '50%', textAlign: 'right' }}>
								{loading && <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />}
								{!loading && (
									<Button
										variant={deleteDisabled ? 'light' : 'dark'}
										onClick={onConfirm}
										disabled={deleteDisabled}
									>
										{translate('common.confirm')}
									</Button>
								)}
							</Box>
						</Stack>
					</Stack>
				</Paper>
			</Popover>
		</Box>
	);
};

export default OpenOrdersItemSelectedDelete;
