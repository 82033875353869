import { action, thunk } from 'easy-peasy';

export default {
	modalDeleteOpen: false,
	openModalDelete: action((state, payload) => {
		state.modalDeleteOpen = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	deleteRanking: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.rankings
			.deleteRankings(payload)
			.then(data => {
				getStoreActions().success('rankings.delete');
				getStoreActions().rankings.list.load();
				return Promise.resolve(data.items);
			})
			.catch(e => {
				getStoreActions().error('rankings.errorDelete');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
