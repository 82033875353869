import { action, thunk, thunkOn } from 'easy-peasy';
import utils from './utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	totalResults: 0,
	save: action((state, payload) => {
		state.items = payload.items.map(item => ({
			...item,
			isSelected: false
		}));
		state.totalResults = payload.totalItems;
	}),
	load: thunk(utils.search),
	reloadOnTokenReset: thunkOn(
		(actions, storeActions) => [storeActions.purchasings.filters.resetToken],
		async (actions, target, helpers) => {
			actions.load();
		}
	),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	toggleSelection: action((state, payload) => {
		const i = state.items.findIndex(itm => itm.id === payload);
		if (i === -1) return;
		const item = state.items[i];
		item.isSelected = !item.isSelected;
		state.items = state.items
			.slice(0, i)
			.concat(item)
			.concat(state.items.slice(i + 1));
	}),
	selectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: true
		}));
	}),
	deselectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: false
		}));
	})
};
