import k from 'k';
import utils from 'services/utils';

export default ({ data, path, pos }) => {
	const requestOptions = {
		method: 'POST',
		body: data,
		headers: utils.createHeadersNoContent()
	};

	return utils
		.fetch(`${k.endpoints.apis}/contest/upload/${path}/${pos}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
