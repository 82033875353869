import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	list: [],
	save: action((state, payload) => {
		state.list = payload.map((p, i) => ({
			...p,
			isSelected: i === 0
		}));
	}),
	select: action((state, payload) => {
		state.list = state.list.map(promo => ({
			...promo,
			isSelected: promo.id === payload
		}));
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.conditionRequest
			.getPromos()
			.then(data => {
				actions.save(data.items);
			})
			.catch(e => {
				getStoreActions().error('conditionRequest.getPromos');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
