import { useReducer, useEffect } from 'react';
import get from 'lodash/get';
import keys from 'lodash/keys';
import { useStoreState } from 'easy-peasy';

const useI18N = _ => {
	const labels = useStoreState(state => state.i18n.labels);
	const current = useStoreState(state => state.i18n.current);
	const [, forceUpdate] = useReducer(x => x + 1, 0);
	const translate = key => {
		if (keys(labels).length === 0) return key;
		const source = labels[current];
		const result = get(source, key, key);
		if (result === key) {
			console.warn(`I18N Error: missing key for ${key}`);
			if (process.env.NODE_ENV) return result.split('.').reverse()[0];
		}
		return result;
	};
	useEffect(
		_ => {
			forceUpdate();
		},
		[current, forceUpdate]
	);
	return translate;
};

export default useI18N;
