import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { green, red } from '@mui/material/colors';
import {
	Button,
	Checkbox,
	Chip,
	Grid,
	InputBase,
	InputLabel,
	MenuItem,
	Modal,
	FormControl,
	FormControlLabel,
	FormLabel,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Typography
} from '@mui/material';
import { CheckCircleOutlineRounded, HighlightOffRounded, Search } from '@mui/icons-material';
import Card from 'components/Card';
import AddUserGroupButton from './AddUserGroupButton';
import DeleteUserGroups from './DeleteUserGroupsButton';
import Edit from './Edit';
import SearchButton from './SearchButton';
import HeadlessCard from 'components/Card/HeadlessCard';
import Loader from 'components/Loader';
import clsx from 'clsx';
import styles from './styles';
import utils from 'components/utils';

const UserGroups = ({ classes, showCloseIcon }) => {
	const translate = utils.useI18N();
	const items = useStoreState(state => state.userGroups.list.items);
	const searchOpen = useStoreState(state => state.userGroups.list.searchOpen);
	const setSearchOpen = useStoreActions(dispatch => dispatch.userGroups.list.setSearchOpen);
	const addOpen = useStoreState(state => state.userGroups.addUserGroup.addOpen);
	const setAddOpen = useStoreActions(dispatch => dispatch.userGroups.addUserGroup.setAddOpen);
	const addUserGroup = useStoreActions(dispatch => dispatch.userGroups.addUserGroup.sendRequest);
	const deleteOpen = useStoreState(state => state.userGroups.deleteUserGroups.modalDeleteOpen);
	const setDeleteOpen = useStoreActions(dispatch => dispatch.userGroups.deleteUserGroups.openModalDelete);
	const deleteUserGroups = useStoreActions(dispatch => dispatch.userGroups.deleteUserGroups.sendRequest);
	const userCode = useStoreState(state => state.me.data.id);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [enabled, setEnabled] = useState(false);
	const load = useStoreActions(dispatch => dispatch.userGroups.list.load);
	const loading = useStoreState(state => state.userGroups.list.loadingList);
	const leftGridMdWidth = searchOpen || addOpen ? 7 : 12;
	const order = useStoreState(state => state.userGroups.list.orderDirection);
	const orderBy = useStoreState(state => state.userGroups.list.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.userGroups.list.setOrder);
	const selectedItems = items.filter(item => item.isSelected);
	const toggleSelection = useStoreActions(dispatch => dispatch.userGroups.list.toggleSelection);
	const selectAll = useStoreActions(dispatch => dispatch.userGroups.list.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.userGroups.list.deselectAll);
	const onSelectAll = event => {
		event.target.checked ? selectAll() : deselectAll();
	};
	const theme = useTheme();
	let rows = utils.stableSort(items, order, orderBy);
	useEffect(load, [load]);

	const [token, setToken] = useState('');
	if (token !== '') {
		rows = rows.filter(
			item =>
				item.name.toLowerCase().includes(token) ||
				(item.description ? item.description.toLowerCase().includes(token) : '') ||
				(item.utente ? item.utente.toLowerCase().includes('.' + token) : '')
		);
	}
	const [value, setValue] = useState('everything');
	rows = rows.filter(item => {
		if (value == 'enabled') return item.enabled;
		else if (value == 'disabled') return !item.enabled;
		else return item;
	});
	const onClick = _ => {
		const data = {
			userCode: userCode,
			name: name,
			description: description,
			enabled: enabled,
			type: 'ASSIGNMENT'
		};

		addUserGroup(data);
	};
	const onClose = _ => {
		setAddOpen(false);
	};

	const onClickDelete = _ => {
		selectedItems.forEach(item => {
			deleteUserGroups(item.id);
			setDeleteOpen(false);
		});
	};

	const SelectionAll = _ => {
		return (
			<TableCell padding="checkbox" style={{ backgroundColor: '#fafafa' }}>
				<Checkbox
					color="primary"
					indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
					checked={items.length === selectedItems.length}
					onChange={onSelectAll}
					inputProps={{ 'aria-label': 'select all rows' }}
				/>
			</TableCell>
		);
	};
	const SelectionComp = ({ id, isSelected }) => {
		return (
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					onChange={_ => toggleSelection(id)}
					inputProps={{ 'aria-labelledby': id }}
					checked={isSelected}
				/>
			</TableCell>
		);
	};

	const Sortable = ({ id, label, width }) => {
		return (
			<TableCell align="left" sortDirection={orderBy === id ? order : false} width={width}>
				<TableSortLabel
					active={orderBy === id}
					direction={orderBy === id ? order : 'asc'}
					onClick={e => setOrder(id)}
				>
					{translate(label)}
				</TableSortLabel>
			</TableCell>
		);
	};
	return (
		<Fragment>
			<Card style={{ marginTop: 0 }} type="primary" title={translate('common.menu.userGroups')}>
				<Grid container spacing={3}>
					{/** BUTTONS & CHIPS */}
					<Grid item container xs={12}>
						<SearchButton />
						<AddUserGroupButton />
						<DeleteUserGroups active={selectedItems.length === 0} />
						{token !== '' && (
							<Chip
								style={{ marginRight: '8px', marginTop: '6px' }}
								size="small"
								label={token}
								onDelete={_ => {
									setToken('');
									load();
								}}
							/>
						)}
						{value !== 'everything' && (
							<Chip
								style={{ marginRight: '8px', marginTop: '6px' }}
								size="small"
								label={translate(`common.${value == 'enabled' ? 'enabled' : 'disabled'}`)}
								onDelete={_ => {
									setValue('everything');
									load();
								}}
							/>
						)}
					</Grid>

					<Grid item container xs={12}>
						{loading && (
							<Grid
								item
								container
								xs={12}
								justifyContent="center"
								spacing={3}
								style={{ padding: '2px, 1px, 2px, 1px' }}
							>
								<Loader />
							</Grid>
						)}
						{/** TABLE */}
						{!loading && (
							<Fragment>
								<Grid item container xs={12} md={leftGridMdWidth}>
									<TableContainer component={Paper} style={{ maxHeight: 500 }}>
										<Table stickyHeader aria-label="manage evasion" size="small">
											<TableHead>
												<TableRow style={{ height: theme.spacing(5) }}>
													<SelectionAll />
													<Sortable id="id" key="headCell_id" label="Id" />
													<Sortable
														id="name"
														key="headCell_name"
														label={
															translate('userGroups.common.name') +
															translate('userGroups.common.countAssignedUsers')
														}
														width={'20%'}
													/>
													<Sortable
														id="description"
														key="headCell_description"
														label={translate('userGroups.common.description')}
														width={'20%'}
													/>
													<Sortable
														id="type"
														key="headCell_type"
														label={translate('userGroups.common.type')}
														width={'10%'}
													/>
													<Sortable
														id="enabled"
														key="headCell_enabled"
														label={translate('common.enabled')}
													/>
													<TableCell align="left">
														<span className="MuiButtonBase-root" aria-disabled="true">
															&nbsp;
														</span>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{rows.map(item => (
													<TableRow
														key={`usergroup_cell_${item.id}`}
														style={{ height: theme.spacing(8) }}
													>
														<SelectionComp id={item.id} isSelected={item.isSelected} />
														<TableCell align="left">{item.id}</TableCell>
														<TableCell align="left" width={'20%'}>
															{item.name + item.countUsers}
														</TableCell>
														<TableCell align="left" width={'20%'}>
															{item.description}
														</TableCell>
														<TableCell align="left" width={'10%'}>
															{item.type}
														</TableCell>
														<TableCell align="left">
															{item.enabled && (
																<CheckCircleOutlineRounded
																	fontSize="small"
																	style={{ color: green[300] }}
																/>
															)}
															{!item.enabled && (
																<HighlightOffRounded
																	fontSize="small"
																	style={{ color: red[300] }}
																/>
															)}
														</TableCell>
														<Edit className="root" item={item} />
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Fragment>
						)}
						{/** SEARCH */}
						{searchOpen && !loading && (
							<Grid item md={5} className={classes.elements}>
								<div className={clsx(classes.negateMargin)}>
									<HeadlessCard>
										<Grid container spacing={2}>
											<Grid container item xs={12} className={classes.elements}>
												<div className={classes.search}>
													<InputBase
														startAdornment={<Search />}
														placeholder={'Cerca'}
														classes={{
															root: classes.inputRoot,
															input: classes.inputInput
														}}
														value={token}
														onChange={e => setToken(e.target.value)}
														inputProps={{ 'aria-label': 'search' }}
													/>
												</div>
											</Grid>
											<Grid container item xs={12} justifyContent="flex-start">
												<FormControl>
													<FormLabel>{translate('userGroups.common.status')}</FormLabel>
													<RadioGroup
														name="controlled-radio-buttons-group"
														value={value}
														onChange={e => setValue(e.target.value)}
													>
														<FormControlLabel
															value="everything"
															control={<Radio color="primary" />}
															label={translate('userGroups.button.everything')}
														/>
														<FormControlLabel
															value="enabled"
															control={<Radio color="primary" />}
															label={translate('common.enabled')}
														/>
														<FormControlLabel
															value="disabled"
															control={<Radio color="primary" />}
															label={translate('common.disabled')}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
											<Grid container item xs={12} justifyContent="flex-end">
												<Button
													style={{ marginLeft: 8 }}
													disableElevation
													variant="outlined"
													color="primary"
													onClick={_ => {
														setSearchOpen(false);
													}}
												>
													{translate('common.close')}
												</Button>
											</Grid>
										</Grid>
									</HeadlessCard>
								</div>
							</Grid>
						)}
						{/** ADD */}
						{addOpen && !loading && (
							<Grid item md={5} className={classes.elements}>
								<div className={clsx(classes.negateMargin)}>
									<HeadlessCard>
										<Grid container spacing={2}>
											<Grid container item xs={12}>
												<TextField
													value={name}
													onChange={e => setName(e.target.value)}
													fullWidth={true}
													label={translate('userGroups.common.name') + ' *'}
												/>
											</Grid>
											<Grid container item xs={12}>
												<TextField
													value={description}
													onChange={e => setDescription(e.target.value)}
													fullWidth={true}
													label={translate('userGroups.common.description')}
												/>
											</Grid>
											<Grid container item xs={12}>
												<FormControl required style={{ minWidth: 120 }}>
													<InputLabel>{translate('userGroups.common.type')}</InputLabel>
													<Select
														defaultValue="ASSIGNMENT"
														value={'ASSIGNMENT'}
														className={classes.selectEmpty}
													>
														<MenuItem value={'ASSIGNMENT'}>ASSIGNMENT</MenuItem>
														<MenuItem value={'QUERY'} disabled>
															QUERY
														</MenuItem>
													</Select>
												</FormControl>
											</Grid>
											<Grid
												className={classes.baseGrid}
												item
												xs={12}
												sm={12}
												container
												justifyContent="flex-start"
												style={{ paddingTop: 20, paddingBottom: 20 }}
											>
												<Typography align={'center'} style={{ paddingRight: '5px' }}>
													{translate('common.enabled')}
												</Typography>
												<Checkbox
													onChange={() => setEnabled(!enabled)}
													value={enabled}
													color={'primary'}
													style={{ padding: 0 }}
												/>
											</Grid>

											<Grid container item xs={6}>
												<Button
													onClick={onClick}
													variant="contained"
													color="primary"
													type="submit"
													disabled={name === ''}
												>
													{translate('userGroups.button.createUserGroup')}
												</Button>
											</Grid>
											<Grid container item xs={6} justifyContent="flex-end">
												<Button
													onClick={onClose}
													variant="outlined"
													color="primary"
													type="submit"
												>
													{translate('common.close')}
												</Button>
											</Grid>
										</Grid>
									</HeadlessCard>
								</div>
							</Grid>
						)}
						{/** DELETE */}
						<div className={clsx(classes.negateMargin, { [classes.margin]: showCloseIcon })}>
							<Fragment>
								<Modal
									className={classes.modalPosition}
									open={deleteOpen}
									onClose={_ => setDeleteOpen(false)}
									aria-labelledby="shipping-types-modal"
									aria-describedby="shipping-types-modal"
								>
									<div className="modal-content">
										<Fragment>
											{deleteOpen && (
												<Paper
													style={{
														maxWidth: '350px',
														padding: theme.spacing(1),
														borderRadius: '6px'
													}}
												>
													<div
														style={{
															padding: '3%'
														}}
													>
														<Typography align={'center'} style={{ marginBottom: '10px' }}>
															{translate('userGroups.button.deleteConfirmation')}
														</Typography>
														<Button align={'center'} onClick={() => onClickDelete()}>
															{translate('common.yes')}
														</Button>
														<Button
															style={{ float: 'right' }}
															onClick={() => setDeleteOpen(false)}
														>
															{translate('userGroups.button.return')}
														</Button>
													</div>
												</Paper>
											)}
										</Fragment>
									</div>
								</Modal>
							</Fragment>
						</div>
					</Grid>
				</Grid>
			</Card>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(UserGroups);
