import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Loader from 'components/Loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import Pagination from './Pagination';
import styles from './styles';
import { useMediaQuery } from '@mui/material';

const Users = ({ classes }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const setCurrentPage = useStoreActions(dispatch => dispatch.users.list.setPaginationCurrent);
	const loadingSearch = useStoreState(state => state.users.list.loading);
	const token = useStoreState(state => state.users.list.filters.token);
	const results = useStoreState(state => state.users.list.items);
	const setToken = useStoreActions(dispatch => dispatch.users.list.setToken);
	const load = useStoreActions(dispatch => dispatch.users.list.load);
	const [searchPerformed, setSearchPerformed] = useState(false);
	const onSearch = _ => {
		setCurrentPage(1);
		load();
		setSearchPerformed(true);
	};
	utils.useEnterListener(onSearch);
	const ListItemLink = props => <ListItem button component={Link} {...props} />;
	const getColor = id => ['yellow', 'green', 'red', 'gray'][id - 1];
	const mobile = useMediaQuery(theme.breakpoints.down('xs'));
	const desktop = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={12} sm={6} md={4}>
							<InputBase
								startAdornment={<SearchIcon />}
								placeholder={translate('common.search.placeholder')}
								className={classes.search}
								value={token}
								onChange={e => setToken(e.target.value)}
							/>
						</Grid>
						{desktop && (
							<Grid item sm={3}>
								<Box ml={1}>
									<Button
										variant="contained"
										color="primary"
										disabled={token.length < 3}
										onClick={_ => {
											onSearch();
										}}
									>
										{translate('common.search.search')}
									</Button>
								</Box>
							</Grid>
						)}
					</Grid>
				</Grid>
				{!loadingSearch && results.length > 0 && (
					<Grid item container xs={12}>
						<Pagination />
					</Grid>
				)}
				<Grid item xs={12} container justify="center">
					{loadingSearch && (
						<Grid item container xs={12} justify="center" style={{ marginTop: 16 }}>
							<Loader />
						</Grid>
					)}
					{!loadingSearch && results.length === 0 && searchPerformed && (
						<Card className={classes.noResults} variant="outlined">
							<CardContent className={classes.cardMessageContent}>
								<Icon component="div" fontSize="large" color="error">
									<ReportProblemIcon fontSize="large" />
								</Icon>
								{translate('common.search.noResults')}
							</CardContent>
						</Card>
					)}
					{!loadingSearch && results.length > 0 && (
						<TableContainer component={Paper} style={{ maxHeight: 800 }}>
							<Table aria-label="user search results table" size="small" stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell className={classes.mobileCellsPadding}>&nbsp;</TableCell>
										<TableCell className={classes.mobileCellsPadding}>
											{translate('admin.users.clientId')}
										</TableCell>
										<TableCell className={classes.mobileCellsPadding}>
											{translate('admin.users.fullName')}
										</TableCell>
										{desktop && (
											<Fragment>
												<TableCell>{translate('admin.users.city')}</TableCell>
												<TableCell>{translate('admin.users.address')}</TableCell>
												<TableCell>{translate('admin.users.province')}</TableCell>
											</Fragment>
										)}
										<TableCell className={classes.mobileCellsPadding} align="right">
											&nbsp;
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{results.map((item, index) => (
										<TableRow key={`row_${index}`}>
											<TableCell className={classes.mobileCellsPadding}>
												<FiberManualRecordIcon
													style={{ color: getColor(item.abilitationCode) }}
												/>
											</TableCell>
											<TableCell className={classes.mobileCellsPadding}>
												{item.clientId}
											</TableCell>
											{mobile && (
												<TableCell className={classes.mobileCellsPadding}>
													{utils.useEllipsis(item.name, 15, '...')}
												</TableCell>
											)}
											{desktop && (
												<Fragment>
													<TableCell>{item.name}</TableCell>
													<TableCell>{item.city}</TableCell>
													<TableCell>{item.address}</TableCell>
													<TableCell>{item.province}</TableCell>
												</Fragment>
											)}
											{item.abilitationCode === 4 && (
												<TableCell
													className={classes.mobileCellsPadding}
													style={{ height: 69 }}
												>
													&nbsp;
												</TableCell>
											)}
											{item.abilitationCode !== 4 && (
												<TableCell className={classes.mobileCellsPadding} align="right">
													<ListItemLink
														to={`/admin/users/${item.clientId}`}
														button
														style={{
															background: 'none',
															justifyContent: 'flex-end',
															padding: 0
														}}
													>
														<Tooltip title={translate('common.edit')} placement="top">
															<IconButton
																size="small"
																disableRipple
																style={{ padding: 8, background: 'none' }}
															>
																<ListItemIcon>
																	<EditIcon />
																</ListItemIcon>
															</IconButton>
														</Tooltip>
													</ListItemLink>
												</TableCell>
											)}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Users);
