import React from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import utils from 'components/utils';
import styles from './styles';
import ListItemLink from './ListItemLink';
import Tooltip from '@mui/material/Tooltip';

const Contact = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const open = useStoreState(state => state.menu.mainOpen);
	return (
		<ListItemLink to="/contact" className={classes.listItem} button>
			{!open && (
				<Tooltip title={translate('common.menu.contact')} placement="right">
					<ListItemIcon className={classes.listIcon}>
						<ContactSupportIcon />
					</ListItemIcon>
				</Tooltip>
			)}
			{open && (
				<ListItemIcon className={classes.listIcon}>
					<ContactSupportIcon />
				</ListItemIcon>
			)}
			<ListItemText primary={translate('common.menu.contact')} />
		</ListItemLink>
	);
};

export default withStyles(styles, { withTheme: true })(Contact);
