import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { green, red } from '@mui/material/colors';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Loader from 'components/Loader';
import utils from 'components/utils';
import DayDetail from './components/DayDetail';
import styles from './styles';
import moment from 'moment';

const SpecialDelivery = ({ classes }) => {
	const translate = utils.useI18N();
	const items = useStoreState(state => state.BOspecialDelivery.list.items);
	const loading = useStoreState(state => state.BOspecialDelivery.list.loading);
	const load = useStoreActions(dispatch => dispatch.BOspecialDelivery.list.load);
	const expanded = useStoreState(state => state.BOspecialDelivery.list.expanded);
	const setExpanded = useStoreActions(dispatch => dispatch.BOspecialDelivery.list.setExpanded);
	const handleChange = index => (event, isExpanded) => {
		setExpanded(isExpanded ? index : -1);
	};
	const now = moment().isoWeekday();
	// prettier-ignore
	useEffect(_ => { load() }, [load]);
	return (
		<Grid item xs={12}>
			<Grid container spacing={3}>
				<Grid item container xs={12}>
					{loading && (
						<Grid
							item
							container
							xs={12}
							justifyContent="center"
							spacing={3}
							style={{ padding: '2px, 1px, 2px, 1px' }}
						>
							<Loader />
						</Grid>
					)}
					{items.map((item, index) => (
						<Grid item container xs={12} key={`open-cart_${index}`}>
							<Card style={{ marginBottom: 20, width: 1000 }}>
								<Accordion expanded={expanded === index} onChange={handleChange(index)}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel-header">
										<Grid container>
											<Grid item style={{ width: 30 }}>
												{item.enabled && (
													<CheckCircleOutlineRoundedIcon
														fontSize="small"
														style={{ color: green[300] }}
													/>
												)}
												{!item.enabled && (
													<HighlightOffRoundedIcon
														fontSize="small"
														style={{ color: red[300] }}
													/>
												)}
											</Grid>
											<Grid item style={{ width: 150 }}>
												<Typography variant="subtitle2">
													<b>{translate(`specialDelivery.days.${item.day.toLowerCase()}`)}</b>
												</Typography>
											</Grid>
											<Grid item xs>
												<Typography variant="subtitle2">
													Ora inizio:{' '}
													<b>{moment(item.startTime, 'HH:mm:ss').format('HH:mm')}</b>
													&nbsp;&nbsp;&nbsp;Ora fine:{' '}
													<b>{moment(item.endTime, 'HH:mm:ss').format('HH:mm')}</b>
												</Typography>
											</Grid>
											{index + 1 === now && (
												<Grid container item xs={12}>
													<Typography
														variant="caption"
														color="error"
														style={{ alignSelf: 'center' }}
													>
														<b>{translate('common.alert')}</b>:&nbsp;
													</Typography>
													<Typography variant="caption" style={{ alignSelf: 'center' }}>
														<b>{translate('specialDelivery.alertMessage')}</b>
													</Typography>
												</Grid>
											)}
										</Grid>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={0}>
											<DayDetail item={item} index={index} />
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Card>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(SpecialDelivery);
