import search from './search';
import details from './details';
import download from './download';
import sendEmail from './send-email';

export default {
	search,
	details,
	download,
	sendEmail
};
