import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const QuantityComp = ({ amount }) => {
	return <StyledTableCell>{amount}</StyledTableCell>;
};

const Quantity = memo(
	({ item }) => <QuantityComp amount={item.deliveredQuantity} />,
	(p, n) => p.item.deliveredQuantity === n.item.deliveredQuantity
);

export default Quantity;
