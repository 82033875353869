export default theme => ({
	negateMargin: {
		marginTop: -41
	},
	noRipple: {
		padding: 0,
		'&:hover': {
			background: 'none'
		}
	}
});
