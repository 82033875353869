import { action, thunk, thunkOn } from 'easy-peasy';
import storeUtils from 'store/utils';

export default {
	items: [],
	save: action((state, payload) => {
		state.items = payload.items.map((s, i) => ({ ...s, selected: i === 0 }));
	}),
	updateReadStatus: action((state, payload) => {
		state.items = state.items.map(item => ({ ...item, isRead: item.id === payload ? true : item.isRead }));
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	load: thunk((actions, payload, { getState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const page = getState().pagination.current - 1;
		const step = getState().pagination.step;
		const params = { page, step };
		return services.notifications
			.list(params)
			.then(data => {
				actions.save(data);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('notifications.load');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	...storeUtils.createPagination({ step: 25, paginationTrigger: 'save', loadFn: 'load' }),
	markAsReadOnSuccess: thunkOn(
		(actions, storeActions) => [storeActions.notifications.markAsRead.sendRequest.successType],
		(actions, target, helpers) => {
			const { payload } = target;
			actions.updateReadStatus(payload);
		}
	)
};
