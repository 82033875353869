import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import PaginationComp from 'components/Pagination';

const Pagination = _ => {
	const currentPage = useStoreState(state => state.rankings.assign.pagination.current);
	const step = useStoreState(state => state.rankings.assign.pagination.step);
	const pageCount = useStoreState(state => state.rankings.assign.pagination.pageCount);
	const totalItems = useStoreState(state => state.rankings.assign.pagination.totalItems);
	const setCurrentPage = useStoreActions(dispatch => dispatch.rankings.assign.setPaginationCurrent);
	const setStep = useStoreActions(dispatch => dispatch.rankings.assign.setPaginationStep);
	return (
		<PaginationComp
			currentPage={currentPage}
			step={step}
			pageCount={pageCount}
			setCurrentPage={setCurrentPage}
			setStep={setStep}
			totalItems={totalItems}
		/>
	);
};

export default Pagination;
