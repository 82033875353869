import React from 'react';
import { withStyles } from '@mui/styles';
import { cardBodyStyles } from './style.js';

const CardBody = props => {
	const { classes, children, bodyStyle } = props;
	return (
		<div className={classes.cardBody} style={bodyStyle}>
			{children}
		</div>
	);
};

export default withStyles(cardBodyStyles, { withTheme: true })(CardBody);
