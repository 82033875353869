import k from 'k';
import utils from 'services/utils';

export default docUrl => {
	const requestOptions = {
		method: 'POST',
		headers: utils.createHeaders(),
		body: JSON.stringify({ docUrl })
	};
	return utils
		.fetch(`${k.endpoints.apis}/document/send`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
