import React, { memo } from 'react';
import utils from 'components/utils';
import WarningIcon from '@mui/icons-material/Warning';
import { orange } from '@mui/material/colors';
import { Badge, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';

const QuantityComp = ({ quantity, depots, depotCode, ean, list }) => {
	const translate = utils.useI18N();
	const selectedDepot = depots.find(d => {
		return d.id === depotCode;
	});
	const listFilter = list.filter(
		item => item.ean === ean && item.quantityTot > item.stock && depotCode === item.depot
	);
	const iconVisualize = listFilter.length;

	return (
		<Grid container spacing={0} style={{ marginTop: '15px' }}>
			<Grid item xs={4}>
				{quantity}
			</Grid>
			{iconVisualize > 0 && (
				<Grid item xs={8}>
					<Tooltip
						title={
							translate('wishlist.warningQuantityFES').replace('tot', listFilter[0].quantityTot) +
							` (${selectedDepot.stock})`
						}
					>
						<Badge badgeContent={selectedDepot.stock} color="error" max={9999}>
							<WarningIcon style={{ color: orange[500] }} />
						</Badge>
					</Tooltip>
				</Grid>
			)}
		</Grid>
	);
};
const Quantity = memo(
	({ item, list }) => (
		<QuantityComp
			quantity={item.quantity}
			ean={item.ean}
			depots={item.depots}
			depotCode={item.depotCode}
			list={list}
		/>
	),
	(p, n) =>
		p.item.quantity === n.item.quantity &&
		p.item.depotCode === n.item.depotCode &&
		p.item.ean === n.item.ean &&
		p.list === n.list &&
		p.item.depots === n.item.depots
);

export default Quantity;
