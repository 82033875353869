const formatPrice = price => {
	if (typeof price === 'string') {
		price = parseFloat(price);
	}
	if (!price) return '0,00 €';
	const fixedPrice = price.toFixed(2);
	const formatted = new Intl.NumberFormat('it-IT', {
		style: 'currency',
		currency: 'EUR'
	}).format(fixedPrice);
	return formatted;
};

export default formatPrice;
