import { action, thunk } from 'easy-peasy';

export default {
	items: [],
	setItems: action((state, payload) => {
		state.items = payload.map(item => ({ ...item, quantity: Math.max(1, item.quantity) }));
		state.notFound = payload.notFoundItems;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	notFound: [],
	activeStep: 0,
	setActiveStep: action((state, payload) => {
		state.activeStep = payload;
	}),
	getActiveStep: action(state => console.log(state.activeStep)),
	courier: '',
	setCourier: action((state, payload) => {
		state.courier = payload;
	}),
	trackingLink: '',
	seTrackingLink: action((state, payload) => {
		state.trackingLink = payload;
	}),
	date: '',
	setDate: action((state, payload) => {
		state.date = payload;
	}),
	deliveryNumber: 0,
	setDeliveryNumber: action((state, payload) => {
		state.deliveryNumber = payload;
	}),
	reset: action(state => {
		state.activeStep = 0;
		state.items = [];
		state.notFound = [];
		state.trackingLink = '';
		state.courier = '';
		state.date = '';
		state.deliveryNumber = '';
	}),
	remove: action((state, payload) => {
		state.items = state.items.filter(itm => itm.ean !== payload);
	}),

	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.order
			.tracking(payload)
			.then(data => {
				actions.setItems(data);

				const step = Math.max.apply(
					Math,
					data.map(function(o) {
						return o.generalStatus;
					})
				);
				if (step === 99) actions.setActiveStep(3);
				else actions.setActiveStep(step - 1);

				return Promise.resolve(data);
			})
			.catch(e => {
				console.log(e);
				actions.reset();
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
