import React from 'react';
import { useStoreState } from 'easy-peasy';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import DocumentTitle from 'react-document-title';
//import theme from 'theme';
import theme from './theme7';
import Bootstrap from './components/Bootstrap';
import Notifications from './components/Notifications';
import ErrorBoundary from './components/ErrorBoundary';
import FirstLogin from './FirstLogin';
import UnauthorizedAlert from './UnauthorizedAlert';
import NoMatch from './routes/NoMatch';
import Layout7 from 'layouts/Layuot7';
import HomeV2 from 'routes/HomeV2';
import getRoutes from './routes';
import utils from 'components/utils';

import './lastCommitHash';

const App = _ => {
	const permissions = useStoreState(state => state.me.permissions);
	const [iAmPersonifying, target] = utils.usePersonification();
	const requested = utils.useUserData('newsletter.requested', false);
	const subscription = utils.useUserData('newsletter.subscription', false);
	const abilitationCode = utils.useUserData('abilitationCode', 'unset', true);
	const showFirstLogin = !requested && !subscription;

	let routes = {};
	if (iAmPersonifying) {
		routes = getRoutes(target.userPermission);
	} else {
		routes = getRoutes(permissions);
	}
	const showApp = abilitationCode === 1 || abilitationCode === 2 || abilitationCode === 'unset';

	return (
		<Router>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Notifications />
				<Bootstrap />
				{showFirstLogin && !iAmPersonifying && showApp && <FirstLogin />}
				{!showApp && <UnauthorizedAlert />}
				{showApp && (
					<Switch>
						{routes.map((route, i) => (
							<Route
								key={i}
								path={route.path}
								render={props => {
									const Layout = route.layout;
									const Comp = route.component;
									return (
										<DocumentTitle title={'Fastlink - ' + route.title}>
											<Layout>
												<ErrorBoundary>
													<Comp />
												</ErrorBoundary>
											</Layout>
										</DocumentTitle>
									);
								}}
								exact={route.exact}
							/>
						))}
						{/*<Route component={NoMatch} />*/}
						<Route
							render={props => {
								return (
									<DocumentTitle title={''}>
										<Layout7>
											<ErrorBoundary>
												<HomeV2 />
											</ErrorBoundary>
										</Layout7>
									</DocumentTitle>
								);
							}}
						/>
					</Switch>
				)}
			</ThemeProvider>
		</Router>
	);
};

export default App;
