import React, { useState, Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import clone from 'lodash/clone';
import NumericStepper from 'components/NumericStepper';
import { orange } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import WarningIcon from '@mui/icons-material/Warning';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from 'components/Select';
import Typography from '@mui/material/Typography';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const styles = theme => ({
	custom: {
		'&:hover': {
			background: 'none'
		}
	},
	card: {
		maxWidth: 450,
		margin: 'auto',
		transition: '0.3s'
	},
	content: {
		textAlign: 'left',
		padding: theme.spacing(0),
		'&:last-child': {
			paddingBottom: 0
		},
		'& a': {
			paddingLeft: theme.spacing(1.5)
		}
	},
	modalPosition: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
});

/*
IMPORTANT!
To have an idea of what's going on there look at this document
https://docs.google.com/spreadsheets/d/11a1fTSEdOw_lgReRRSLkBJpUyaT_97XbiaRb8sHHOoM
*/
const getScenario = (item, paymentTypeId, availableDepots) => {
	const depotIsFasthub = item.depotCode === 'FS01' ? 1 : 0;
	const money = paymentTypeId === 1 ? 2 : 0;
	const onlyFastHub = availableDepots.length === 1 && availableDepots[0].id === 'FS01' ? 4 : 0;
	const itemHasDepot = item.depots.find(d => d.id === item.depotCode) ? 8 : 0;
	return depotIsFasthub + money + onlyFastHub + itemHasDepot;
};

const Edit = ({ item, classes, status }) => {
	const translate = utils.useI18N();
	const paymentTypeId = utils.useUserData('paymentTypeId', [], false);
	const availableDepots = utils.useUserData('availableDepots', []);
	const loading = useStoreState(state => state.wishlist.update.loading);
	const save = useStoreActions(dispatch => dispatch.wishlist.update.updateItem);
	const close = useStoreActions(dispatch => dispatch.wishlist.update.close);
	const [localItem, setLocalItem] = useState(clone(item));
	const openList = useStoreState(state => state.wishlist.update.openList[localItem.id]);
	const selectedDepot = localItem.depots.find(d => d.id === localItem.depotCode);
	const [warning1000, setWarning1000] = useState(false);

	const onStepperChange = valueAsNumber => {
		setLocalItem({
			...localItem,
			quantity: valueAsNumber
		});
		if (!selectedDepot.fes) {
			setWarning1000(valueAsNumber === 999);
		}
	};
	const getMaxValue = _ => {
		if (!selectedDepot || !selectedDepot.fes) return 999;
		return selectedDepot.stock;
	};
	const onNoteChange = event => {
		setLocalItem({
			...localItem,
			notes: event.target.value
		});
	};
	const onDepotSelect = event => {
		const depotCode = event.target.value;
		const selected = depotList.find(d => d.id === depotCode);
		if (!selected) return;
		setLocalItem({
			...localItem,
			depotCode,
			depotDescription: selected.description
		});
	};
	const onSave = event => {
		save({
			wishlistItemId: localItem.id,
			quantity: localItem.quantity,
			depotCode: localItem.depotCode,
			depotDescription: localItem.depotDescription,
			notes: localItem.notes
		});
	};
	const isOpen = Boolean(openList);
	const fes = selectedDepot ? selectedDepot.fes : false;
	const scenario = getScenario(item, paymentTypeId, availableDepots);
	if ([6, 7, 14, 15].includes(scenario) || (fes === null && item.stock === null)) {
		return (
			<StyledTableCell>
				<Tooltip title="Non puoi modificare le quantità di questo prodotto">
					<IconButton className={classes.custom} aria-label="warning wishlist" style={{ padding: 0 }}>
						<EditIcon fontSize="small" disabled={true} />
					</IconButton>
				</Tooltip>
			</StyledTableCell>
		);
	}
	let depotList = localItem.depots;
	if ([10, 3, 11, 1, 5].includes(scenario)) depotList = depotList.filter(d => d.id !== 'FS01');
	if ([0, 4].includes(scenario)) depotList = depotList.filter(d => d.id !== item.depotCode);
	if (scenario === 2) depotList = depotList.filter(d => d.id !== item.depotCode && d.id !== 'FS01');
	const depotMenuValue = depotList.find(d => d.id === localItem.depotCode)
		? localItem.depotCode
		: depotList.length > 0
		? depotList[0].id
		: '';
	return (
		<Fragment>
			<Modal className={classes.modalPosition} open={isOpen} onClose={_ => close(localItem.id)}>
				<div className="modal-content">
					<Card className={classes.card} spacing={2} style={{ padding: 16 }}>
						<CardContent className={classes.content}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="h6">Modifica deposito e quantità</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2">
										{item.ean} - {item.title}
									</Typography>
								</Grid>
								<Grid container item xs={3} style={{ marginTop: 13 }}>
									<Typography variant="body2">Quantità</Typography>
								</Grid>
								<Grid container item xs={7} style={{ marginTop: 8 }}>
									<Grid item xs={10}>
										<NumericStepper
											removeDot
											precision={0}
											min={1}
											strict
											max={getMaxValue}
											step={1}
											value={localItem.quantity}
											onChange={onStepperChange}
										/>
									</Grid>
								</Grid>
								{selectedDepot && selectedDepot.fes && (
									<Grid item container xs={12} justify="center" style={{ marginTop: 8 }}>
										<WarningIcon fontSize="small" style={{ color: orange[500] }} />
										<Typography variant="subtitle2" color="error">
											{translate('catalog.selection.warningFES')}
										</Typography>
									</Grid>
								)}
								{warning1000 && (
									<Grid item container xs={12} justify="center" style={{ marginTop: 8 }}>
										<WarningIcon fontSize="small" style={{ color: orange[500] }} />
										<Typography variant="subtitle2" color="error">
											Non è possibile ordinare più di 999 copie con una sola richiesta
										</Typography>
									</Grid>
								)}
								<Grid container item xs={3} style={{ marginTop: 13 }}>
									<Typography variant="body2">Deposito</Typography>
								</Grid>
								{item.type === 100 && (
									<Grid container item xs={9} style={{ marginTop: 13 }}>
										<Typography variant="body2">Fast Hub</Typography>
									</Grid>
								)}
								{item.type !== 100 && (
									<Fragment>
										<Grid container item xs={9} style={{ marginTop: 8 }}>
											<FormControl variant="outlined">
												<Select value={depotMenuValue} onChange={onDepotSelect}>
													{depotList.map((item, index) => (
														<MenuItem key={item.id} value={item.id}>
															{item.description}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									</Fragment>
								)}
								<Grid item xs={12} style={{ marginTop: 8 }}>
									<TextareaAutosize
										rowsMax={5}
										rowsMin={5}
										style={{ width: '100%', resize: 'none' }}
										aria-label="warning message"
										placeholder="note"
										value={localItem.notes}
										onChange={onNoteChange}
									/>
								</Grid>
								<Grid container item xs={6} style={{ marginTop: 8 }}>
									<Button
										onClick={_ => close(localItem.id)}
										variant="outlined"
										color="primary"
										type="submit"
									>
										{translate('common.close')}
									</Button>
								</Grid>
								<Grid container item xs={6} justify="flex-end" style={{ marginTop: 8 }}>
									{loading && <CircularProgress size={14} />}
									{!loading && (
										<Button onClick={onSave} variant="contained" color="primary" type="submit">
											salva
										</Button>
									)}
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</div>
			</Modal>
			{/* <StyledTableCell>
				<Tooltip title={title}>
					<span>
						<IconButton
							disabled={disabled}
							className={classes.custom}
							aria-label="warning evasion"
							style={{ padding: 0 }}
							onClick={_ => open(localItem.id)}
						>
							<EditIcon fontSize="small" />
						</IconButton>
					</span>
				</Tooltip>
			</StyledTableCell> */}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Edit);
