import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const style = {
	color: '#00529b',
	maxHeight: 20
};

export const SliderArrowBack = ({ onClick, className }) => {
	return (
		<div onClick={onClick} style={{ display: 'block' }}>
			<ArrowBackIosIcon style={style} className={className} />
		</div>
	);
};
export const SliderArrowForward = ({ onClick, className }) => {
	return (
		<div onClick={onClick} style={{ display: 'block' }}>
			<ArrowForwardIosIcon style={style} className={className} />
		</div>
	);
};
