import React, { Fragment, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import styles from './styles';
import { Hidden } from '@mui/material';

const getDate = end => {
	const now = moment();
	const diff = moment(end.diff(now));
	return [end.diff(now, 'hours'), diff.minutes(), diff.seconds()];
};

const CountdownComp = ({ classes, end, onFinish }) => {
	const [h, setH] = useState(null);
	const [m, setM] = useState(null);
	const [s, setS] = useState(null);
	const format = d => ('0' + d).slice(-2);
	useEffect(
		_ => {
			const intervalFn = _ => {
				const [hrs, min, sec] = getDate(end);
				setH(format(hrs));
				setM(format(min));
				setS(format(sec));
			};
			const interval = setInterval(intervalFn, 1000);
			intervalFn();
			return _ => clearInterval(interval);
		},
		[end]
	);
	if (h === '00' && m === '00' && s === '00') onFinish();
	if (!h || !m || !s) return '';
	return (
		<Fragment>
			<Hidden smDown>
				<Tooltip title="Tempo residuo entro il quale effettuare la richiesta di evasione per la spedizione in giornata">
					<Typography className={classes.text}>
						{h}:{m}:{s}
					</Typography>
				</Tooltip>
			</Hidden>
			<Hidden mdUp>cdown mobile</Hidden>
		</Fragment>
	);
};

const Countdown = ({ classes }) => {
	const isLoggedIn = useStoreState(state => state.me.data.isLoggedIn);
	const data = useStoreState(state => state.specialDelivery.current.data);
	const loadSpecialDelivery = useStoreActions(dispatch => dispatch.specialDelivery.current.load);
	const [visible, setVisible] = useState(true);
	useEffect(
		_ => {
			if (isLoggedIn) loadSpecialDelivery();
		},
		[isLoggedIn, loadSpecialDelivery]
	);
	if (!data || !data.enabled) return '';
	const now = moment();
	const start = moment(data.startTime, 'HH:mm');
	const end = moment(data.endTime, 'HH:mm');
	const onFinish = _ => {
		setVisible(false);
	};
	if (now < start || now > end || !visible) return '';
	return <CountdownComp classes={classes} end={end} onFinish={onFinish} />;
};

export default withStyles(styles, { withTheme: true })(Countdown);
