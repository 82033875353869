import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const RefNameComp = ({ name }) => {
	return <StyledTableCell>{name}</StyledTableCell>;
};

const RefName = memo(
	({ item }) => <RefNameComp name={item.referentUserName} />,
	(p, n) => p.item.referentUserName === n.item.referentUserName
);

export default RefName;
