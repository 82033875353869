import utils from 'utils';

export const cardStyles = theme => ({
	card: {
		border: '0',
		margin: theme.spacing(5, 0, 0, 0),
		borderRadius: theme.spacing(1),
		color: `rgba(${utils.colors.hexToRgbString(theme.palette.common.black)}, .87)`,
		background: theme.palette.common.white,
		width: '100%',
		boxShadow: `0 1px 4px 0 rgba(${utils.colors.hexToRgbString(theme.palette.common.black)}, .14)`,
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		wordWrap: 'break-word',
		fontSize: '.875rem',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(2, 0)
		}
	}
});

export const cardHeaderStyles = theme => ({
	cardHeader: {
		padding: theme.spacing(2, 3),
		marginBottom: 0,
		borderBottom: 'none',
		background: 'transparent',
		borderRadius: theme.spacing(0.5),
		margin: theme.spacing(0, 2),
		marginTop: -theme.spacing(3),
		position: 'relative',
		color: theme.palette.common.white,
		[theme.breakpoints.down('xs')]: {
			margin: 0,
			padding: theme.spacing(2),
			borderRadius: 0
		}
	},
	cardHeaderTitle: {
		marginTop: 0,
		minHeight: 'auto',
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		fontWeight: 300,
		fontSize: '1.3rem',
		marginBottom: theme.spacing(0.5),
		textDecoration: 'none'
	},
	cardHeaderSubtitle: {
		color: `rgba(${utils.colors.hexToRgbString(theme.palette.common.white)}, .62)`,
		marginTop: '0px',
		minHeight: 'auto',
		fontWeight: '300',
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: theme.spacing(0.5),
		textDecoration: 'none'
	},
	primaryCardHeader: {
		color: theme.palette.common.white,
		background: `linear-gradient(60deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
		boxShadow: `${theme.spacing(0, 0.5, 3, 0)} rgba(${utils.colors.hexToRgbString(
			theme.palette.common.black
		)}, .14),
			${theme.spacing(0, 1, 1, -0.5)} rgba(${utils.colors.hexToRgbString(theme.palette.primary.main)}, .4)`
	},
	secondaryCardHeader: {
		color: theme.palette.common.white,
		background: `linear-gradient(60deg, ${theme.palette.secondary.main}, ${theme.palette.primary.dark})`,
		boxShadow: `${theme.spacing(0, 0.5, 3, 0)} rgba(${utils.colors.hexToRgbString(
			theme.palette.common.black
		)}, .14),
			${theme.spacing(0, 1, 1, -0.5)} rgba(${utils.colors.hexToRgbString(theme.palette.secondary.main)}, .4)`
	},
	compactCardHeader: {
		color: `rgba(${utils.colors.hexToRgbString(theme.palette.primary.main)}, .62)`,
		background: theme.palette.common.white,
		boxShadow: `0 1px 4px 0 rgba(${utils.colors.hexToRgbString(theme.palette.common.black)}, .14)`,
		padding: theme.spacing(1),
		position: 'absolute',
		[theme.breakpoints.down('xs')]: {
			position: 'relative',
			boxShadow: 'none'
		}
	},
	compactCardHeaderTitle: {
		fontSize: '1rem',
		[theme.breakpoints.down('xs')]: {
			paddingBottom: theme.spacing(1),
			borderBottom: `1px solid rgba(${utils.colors.hexToRgbString(theme.palette.primary.main)}, .20)`
		}
	}
});

export const cardBodyStyles = theme => ({
	cardBody: {
		padding: theme.spacing(3),
		flex: '1 1 auto',
		WebkitBoxFlex: '1',
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}
	},
	cardBodyProfile: {
		marginTop: theme.spacing(2)
	}
});
