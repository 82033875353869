import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemLink from './ListItemLink';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
//import AddchartIcon from '@mui/icons-material/Addchart';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import utils from 'components/utils';
import styles from './styles';

const FastSales = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const open = useStoreState(state => state.menu.mainOpen);

	const onClick = _ => {
		window.open(
			'https://bi.messaggerie.it/t/Fastlink/views/DashboardLibrerie/Cockpit?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
			'_blank'
		);
	};
	return (
		<ListItemLink to="" onClick={onClick} className={classes.listItem} button>
			{!open && (
				<Tooltip title={translate('common.menu.fastsales')} placement="right">
					<ListItemIcon className={classes.listIcon}>
						<PostAddIcon />
					</ListItemIcon>
				</Tooltip>
			)}
			{open && (
				<ListItemIcon className={classes.listIcon}>
					<PostAddIcon />
				</ListItemIcon>
			)}
			<ListItemText primary={translate('common.menu.fastsales')} />
		</ListItemLink>
	);
};

export default withStyles(styles, { withTheme: true })(FastSales);
