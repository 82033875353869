import list from './list';
import addToWishlist from './add-to-wishlist';
import remove from './remove';
import removeItem from './remove-item';
import removeItems from './remove-items';
import create from './create';
import getDetail from './get-detail';
import updateFavourite from './update-favourite';
import requestExtraConditions from './request-extra-conditions';
import transform from './transform';
import exportPdf from './export-pdf';
import exportXls from './export-xls';
import update from './update';
import updateItem from './update-item';
import getDepotsStock from './get-depots-stock';
import removeUnavailableItems from './remove-unavailable-items';
import listTitle from './list-title';

export default {
	list,
	addToWishlist,
	remove,
	removeItem,
	removeItems,
	create,
	getDetail,
	updateFavourite,
	requestExtraConditions,
	transform,
	exportPdf,
	exportXls,
	update,
	updateItem,
	getDepotsStock,
	removeUnavailableItems,
	listTitle
};
