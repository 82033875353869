import { action, thunk } from 'easy-peasy';

export default {
	items: {},
	save: action((state, payload) => {
		state.items[payload.id] = payload.data;
	}),
	loading: {},
	setLoading: action((state, payload) => {
		state.loading[payload] = true;
	}),
	unsetLoading: action((state, payload) => {
		state.loading[payload] = false;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(payload);
		services.budget
			.getDetails(payload)
			.then(data => {
				actions.save({ id: payload, data });
			})
			.catch(e => {
				getStoreActions().error('budget.details');
			})
			.finally(_ => {
				actions.unsetLoading(payload);
			});
	})
};
