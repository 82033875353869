import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const TitleComp = ({ title }) => {
	return <StyledTableCell>{title}</StyledTableCell>;
};

const Title = memo(
	({ item }) => <TitleComp title={item.title} />,
	(p, n) => p.item.title === n.item.title
);

export default Title;
