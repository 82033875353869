import React from 'react';
import LogoPNG from './Logo.png';

const Logo = ({ classes, children }) => {
	return (
		<div>
			<img src={LogoPNG} alt="Fastlink-logo" style={{ verticalAlign: 'middle', maxHeight: 64 }} />
		</div>
	);
};

export default Logo;
