import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const styles = theme => ({});

const OrderTakenOver = props => {
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="caption"></Typography>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(OrderTakenOver);
