import React, { Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styles from './styles';

const CAInfo = ({ classes }) => {
	const data = useStoreState(state => state.ca.mine.data);
	const load = useStoreActions(dispatch => dispatch.ca.mine.load);
	useEffect(load, [load]);
	return (
		<Fragment>
			{data && (
				<Grid item xs={12}>
					<Typography variant="caption">
						<strong>Centrale Acquisto: </strong>
						{data.nameCA} [{data.numberOfUsersOfCA}]
					</Typography>
				</Grid>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(CAInfo);
