import React from 'react';
import { withStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import utils from 'components/utils';
import styles from './styles';

const CustomNoResult = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	return (
		<Card className={classes.noResults} variant="outlined">
			<CardContent className={classes.cardMessageContent}>
				<Icon component="div" fontSize="large" color="error">
					<ReportProblemIcon fontSize="large" />
				</Icon>
				{translate('purchasings.noResults')}
			</CardContent>
		</Card>
	);
};

export default withStyles(styles, { withTheme: true })(CustomNoResult);
