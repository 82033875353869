import React from 'react';
import { useStoreState } from 'easy-peasy';

const withColumns = WrappedComponent => {
	return props => {
		const selectedType = useStoreState(state => state.documents.search.selectedType);
		let cols;
		if (selectedType.id === 'RIBA') {
			cols = ['type', 'documentNumber', 'amount2', 'expirationDate', 'matchNumber', 'issuingDate', 'download'];
		} else {
			cols = [
				'selection',
				'type',
				'number',
				'date',
				'customer',
				'depot',
				'amount',
				'invoice',
				'payment',
				'download'
			];
		}
		const n = selectedType.id === 'bill' ? 8 : 7;
		const c = [...cols.slice(0, n), ...cols.slice(n + 1)];
		return <WrappedComponent {...props} visibleCols={c} />;
	};
};

export default withColumns;
