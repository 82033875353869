import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, IconButton, Popover, Typography, Box, Button, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterBarSearch from './FilterBarSearch';
import FilterProfile from './FilterProfile';
import utils from 'components/utils';

const FilterBar = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const items = useStoreState(state => state.proposals.list.viewRows);
	const deleteProposals = useStoreActions(dispatch => dispatch.proposals.list.delete);
	const itemSelected = items.filter(i => i.isSelected);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDelete = event => {
		itemSelected.forEach(function(item) {
			deleteProposals(item.id);
			setAnchorEl(null);
		});
	};

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} variant="wrapperColor">
			<Box sx={{ width: { xs: 'auto', md: '85%', sm: '80%' } }}>
				<FilterBarSearch />
			</Box>
			<Box sx={{ width: '50px' }}>
				<FilterProfile />
			</Box>
			<Box sx={{ width: '50px' }}>
				{itemSelected.length > 0 && (
					<Box>
						<Tooltip title={translate('proposals.delete')}>
							<IconButton
								onClick={handleClick}
								sx={{
									backgroundColor: theme.palette.custom.red,
									'&:hover': {
										backgroundColor: theme.palette.custom.red
									}
								}}
							>
								<DeleteIcon sx={{ color: theme.palette.custom.white, fontSize: '26px' }} />
							</IconButton>
						</Tooltip>
						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'center',
								horizontal: 'center'
							}}
							transformOrigin={{
								vertical: 'center',
								horizontal: 'right'
							}}
						>
							<Stack
								direction="column"
								justifyContent="center"
								alignItems="center"
								spacing={4}
								sx={{ width: '100%', padding: '20px !important' }}
							>
								<Typography variant="titleTab">{translate('proposals.deleteConfirm')}</Typography>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={0}
									sx={{ width: '100%' }}
								>
									<Button variant="light" onClick={handleClose}>
										{translate('common.close')}
									</Button>
									<Button variant="dark" onClick={handleDelete}>
										{translate('common.confirm')}
									</Button>
								</Stack>
							</Stack>
						</Popover>
					</Box>
				)}
			</Box>
		</Stack>
	);
};

export default FilterBar;
