import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Modal from '@mui/material/Modal';
import RejectModal from '../../RejectModal';
import { withStyles } from '@mui/styles';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		minWidth: 350
	},
	modalLabels: {
		marginBottom: theme.spacing()
	},
	buttonsMargin: {
		margin: `${theme.spacing(0, 1, 0, 1)}`,
		alignSelf: 'center',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1, 0)
		}
	}
});

const RejectSelected = ({ classes }) => {
	const someSelected = useStoreState(state => state.conditionRequest.pendingList.someSelected);
	const selectedItems = useStoreState(state => state.conditionRequest.pendingList.selectedItems);
	const modalOpen = useStoreState(state => state.conditionRequest.rejectMultiple.modalOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.conditionRequest.rejectMultiple.setModalOpen);
	const reject = useStoreActions(dispatch => dispatch.conditionRequest.rejectMultiple.sendRequest);
	const translate = utils.useI18N();
	const onReject = notes => {
		reject(
			selectedItems.map(item => ({
				id: item.id,
				wishlistConditionRequestStatus: 'REJECTED',
				responsableNote: notes
			}))
		);
	};

	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={modalOpen}
				onClose={_ => {
					setModalOpen(false);
				}}
				closeAfterTransition
			>
				<RejectModal items={selectedItems} onReject={onReject} onClose={_ => setModalOpen(false)} />
			</Modal>
			<Button disabled={!someSelected} variant="light" onClick={_ => setModalOpen(true)}>
				{translate('wishlist.refuseSelected')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(RejectSelected);
