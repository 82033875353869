import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	uploaderModalOpen: false,
	setUploaderModalOpen: action((state, payload) => {
		state.uploaderModalOpen = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.userGroups
			.importUsers(payload)
			.then(data => {
				actions.setUploaderModalOpen(false);
				getStoreActions().userGroups.detailsUserGroup.load(data.item.id);
				getStoreActions().success('userGroups.import');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('userGroups.errorImport');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
