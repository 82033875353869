import React, { Children, Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Stack,
	Button,
	Box,
	Tooltip,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel,
	Checkbox,
	Typography,
	IconButton,
	CircularProgress,
	Modal,
	useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CampaignIcon from '@mui/icons-material/Campaign';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PrintIcon from '@mui/icons-material/Print';
import TuneIcon from '@mui/icons-material/Tune';
import SearchBar from 'routes/Catalog/Search/components/SearchBar';
import AppliedFilters from 'routes/Catalog/Search/components/AppliedFilters';
import DetailItemsAssignPagination from './DetailItemsAssignPagination';
import DetailItemsAssignFilter from './DetailItemsAssignFilter';
import moment from 'moment';
import utils from 'components/utils';

const TabelCellList = ({ children }) => {
	return <TableCell sx={{ padding: '0 5px 0 0' }}>{children}</TableCell>;
};

const DetailItemsAssign = props => {
	const { proposalId, onClose } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const loadingSearch = useStoreState(state => state.proposals.detailItemsAssign.loading);
	const showResults = useStoreState(state => state.proposals.detailItemsAssign.showResults);
	const setShowResults = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.setShowResults);
	const showMessageResults = useStoreState(state => state.proposals.detailItemsAssign.showMessageResults);
	const setShowMessageResults = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.setShowResults);
	const results = useStoreState(state => state.proposals.detailItemsAssign.results);
	const orderBy = useStoreState(state => state.proposals.detailItemsAssign.orderBy);
	const orderDirection = useStoreState(state => state.proposals.detailItemsAssign.orderDirection);
	const setOrder = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.setOrder);
	const search = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.performSearch);
	const toggleSelection = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.toggleSelection);
	const selectAll = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.deselectAll);
	const update = useStoreActions(dispatch => dispatch.proposals.detailItemsAssign.update);
	const openFilters = useStoreState(state => state.proposals.filters.openFilters);
	const setOpenFilters = useStoreActions(dispatch => dispatch.proposals.filters.setOpenFilters);
	const itemSelected = results.filter(i => i.isSelected);
	const sortedRows = iCan('SHOW_SPECIAL_FILTER') ? results : results.filter(item => item.type !== 100);
	const showResultsList = !loadingSearch && showResults && sortedRows.length > 0;
	const mainDepot = utils.useUserData('mainDepot', null);
	const availableDepots = utils.useUserData('availableDepots', []);
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const headCells = [
		{ id: 'ean', label: 'catalog.tableHeaders.ean', width: '118px' },
		{ id: 'author', label: 'catalog.tableHeaders.author', width: '160px' },
		{ id: 'availability', label: '', width: '40px' },
		{ id: 'title', label: 'catalog.tableHeaders.title', width: 'auto' },
		{ id: 'serie', label: 'catalog.tableHeaders.serie', width: '150px' },
		{ id: 'pubblisher', label: 'catalog.tableHeaders.publisher', width: '110px' },
		{ id: 'publicationDate', label: 'catalog.tableHeaders.publicationDate', width: '90px' },
		{ id: 'price', label: 'catalog.tableHeaders.price', width: '80px' },
		{ id: 'promo', label: '', width: '40px' },
		{ id: 'newsItem', label: 'catalog.tableHeaders.newsItem', width: '120px' },
		{ id: 'stock', label: 'catalog.tableHeaders.stock', width: '120px' }
	];

	//In caso di Agente non c'è il deposito principale e quindi non bisogma visualizzare la colonnza giacenza
	const headCellsView = mainDepot ? headCells : headCells.filter(h => h.id !== 'stock');

	const availabilityMap = {
		POOR: theme.palette.custom.red,
		FAIR: theme.palette.custom.yellow,
		GOOD: theme.palette.custom.greenlight,
		EXCELLENT: theme.palette.custom.green
	};

	const getPromoList = promo => {
		return (
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
				{promo.map((p, index) => (
					<Typography key={index} variant="textTab">
						{p.name}
					</Typography>
				))}
			</Stack>
		);
	};

	const getStockInfo = item => {
		if (!mainDepot) return;

		const [firstDepot, secondDepot] = utils.extractDepots({
			mainDepot,
			bookDepots: item.depots,
			userDepots: availableDepots,
			type: item.type,
			ordinable: item.ordinable
		});

		return (
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{ width: '100%' }}
			>
				{firstDepot && (
					<Typography variant="textTable" sx={{ textTransform: 'capitalize' }}>
						{firstDepot.description}: {firstDepot.stock ? firstDepot.stock.toString() : 0}
					</Typography>
				)}
				{secondDepot && (
					<Typography variant="textTable" sx={{ textTransform: 'capitalize' }}>
						{secondDepot.description}: {secondDepot.stock ? secondDepot.stock.toString() : 0}
					</Typography>
				)}
			</Stack>
		);
	};

	const onCloseModal = _ => {
		onClose();
	};

	const onSelectAll = event => {
		if (event.target.checked) selectAll();
		else deselectAll();
	};

	useEffect(() => {
		setShowResults(false);
		setShowMessageResults(false);
		const onKeyDown = e => {
			if (e.key === 'Enter') {
				search();
			}
		};
		document.addEventListener('keydown', onKeyDown);
	}, []);

	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					borderWidth: '0 0 1px 0',
					borderStyle: 'solid',
					borderColor: theme.palette.custom.black
				}}
			>
				<Typography variant="titleTab">{translate('proposals.assignItems')}</Typography>
				<IconButton onClick={onCloseModal}>
					<CloseIcon />
				</IconButton>
			</Stack>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={2}
				sx={{ width: '100%' }}
			>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: '50%' }}>
						<SearchBar path="search" />
					</Box>
					<Button
						variant="dark"
						disabled={loadingSearch}
						onClick={_ => {
							search();
						}}
						endIcon={<SearchIcon />}
						sx={{
							height: '55px',
							padding: '7px 16px !important',
							backgroundColor: loadingSearch ? theme.palette.custom.disabled : theme.palette.primary.main
						}}
					>
						{matches ? '' : translate('common.search.search')}
					</Button>
					<Button
						variant="light"
						disabled={loadingSearch || results.length === 0 || !showResults}
						onClick={_ => {
							update();
						}}
						endIcon={<PlaylistAddCheckIcon />}
						sx={{
							height: '55px',
							padding: '7px 16px !important'
						}}
					>
						{matches ? '' : translate('proposals.assignItemsUdpade')}
					</Button>
					<Button
						variant="light"
						disabled={loadingSearch}
						onClick={_ => setOpenFilters(true)}
						endIcon={<TuneIcon />}
						sx={{
							height: '55px',
							padding: '7px 16px !important'
						}}
					>
						{matches ? '' : translate('common.filter')}
					</Button>
				</Stack>
				{showMessageResults && (
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<Typography variant="textTab" sx={{ fontWeight: '600' }}>
							{translate('common.search.noResults')}
						</Typography>
					</Box>
				)}
				{loadingSearch && (
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<CircularProgress size={90} />
					</Box>
				)}
				{showResultsList && <AppliedFilters />}
				{showResultsList && <DetailItemsAssignPagination />}
				{showResultsList && (
					<TableContainer
						sx={{
							width: '100%',
							overflowY: 'scroll',
							height: { xs: '400px', md: '450px', sm: '450px' }
						}}
					>
						<Table aria-label="proposals items table" padding="none">
							<TableHead>
								<TableRow>
									<TableCell
										sx={{
											background: theme.palette.custom.greytable,
											fontWeight: '600',
											width: '45px'
										}}
									>
										<Checkbox
											indeterminate={
												itemSelected.length > 0 && itemSelected.length < results.results
											}
											checked={itemSelected.length > 0 && results.length === itemSelected.length}
											onChange={onSelectAll}
											inputProps={{ 'aria-label': 'select all items' }}
										/>
									</TableCell>
									{headCellsView.map(headCell => (
										<TableCell
											key={headCell.id}
											align={headCell.id === 'price' ? 'center' : 'left'}
											sortDirection={orderBy === headCell.id ? orderDirection : false}
											sx={{
												background: theme.palette.custom.greytable,
												fontWeight: '600',
												width: headCell.width
											}}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? orderDirection : 'asc'}
												onClick={e => setOrder(headCell.id)}
												sx={{ lineHeight: '18px' }}
											>
												{headCell.label && translate(headCell.label)}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{sortedRows.map((row, index) => (
									<TableRow key={`row_${index}`}>
										<TableCell align="left">
											<Checkbox
												checked={row.isSelected}
												onChange={e => toggleSelection(row.ean)}
												inputProps={{ 'aria-labelledby': row.ean }}
											/>
										</TableCell>
										<TabelCellList>
											<Typography variant="textTable">{row.ean}</Typography>
										</TabelCellList>
										<TabelCellList>
											<Typography variant="textTable">{row.author}</Typography>
										</TabelCellList>
										<TableCell align="right" sx={{ paddingRight: '3px', paddingTop: '5px' }}>
											{row.availability && row.availability !== 'PRINT_ON_DEMAND' && (
												<Tooltip
													title={
														translate(
															`catalog.availability.${row.availability.toLowerCase()}`
														) +
														'.' +
														translate(
															`catalog.availabilityDesc.${row.availability.toLowerCase()}`
														)
													}
												>
													<CheckCircleRoundedIcon
														sx={{
															color: availabilityMap[row.availability],
															fontSize: '18px'
														}}
													/>
												</Tooltip>
											)}
											{row.availability && row.availability === 'PRINT_ON_DEMAND' && (
												<Tooltip
													title={
														translate(
															`catalog.availability.${row.availability.toLowerCase()}`
														) +
														'.' +
														translate(
															`catalog.availabilityDesc.${row.availability.toLowerCase()}`
														)
													}
												>
													<PrintIcon sx={{ fontSize: '18px' }} />
												</Tooltip>
											)}
										</TableCell>
										<TabelCellList>
											<Typography variant="textTable">{row.title}</Typography>
										</TabelCellList>
										<TabelCellList>
											<Typography variant="textTable">{row.serie}</Typography>
										</TabelCellList>
										<TabelCellList>
											<Typography variant="textTable">{row.publisher}</Typography>
										</TabelCellList>
										<TabelCellList>
											<Typography variant="textTable">
												{moment(row.publicationDate).format('L')}
											</Typography>
										</TabelCellList>
										<TableCell align="right" sx={{ paddingRight: '3px' }}>
											<Typography variant="textTable">
												{utils.formatPrice(row.fullPrice)}
											</Typography>
										</TableCell>
										<TabelCellList>
											{row.isPromo && (
												<Tooltip title={getPromoList(row.promo)}>
													<CampaignIcon sx={{ fontSize: '24px' }} />
												</Tooltip>
											)}
										</TabelCellList>
										<TabelCellList>
											<Typography variant="textTable">
												{row.type === 100
													? translate('catalog.details.typology.unavailable')
													: row.newsItem}
											</Typography>
										</TabelCellList>
										{mainDepot && <TabelCellList>{getStockInfo(row)}</TabelCellList>}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
				{showResultsList && <DetailItemsAssignPagination />}
			</Stack>

			{/* Modale Filtri Avanzati */}
			<Modal
				open={openFilters}
				onClose={_ => setOpenFilters(false)}
				aria-labelledby="modal-filters"
				closeAfterTransition
			>
				<Fragment>
					<DetailItemsAssignFilter />
				</Fragment>
			</Modal>
		</Stack>
	);
};

export default DetailItemsAssign;
