export default theme => ({
	elements: {
		padding: `${theme.spacing(2, 1, 2, 1)} !important`
	},
	titleSeparator: {
		textAlign: 'center',
		margin: theme.spacing(2, 0)
	},
	buttonsMargin: {
		margin: `${theme.spacing(0, 1, 0, 1)}`,
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1, 0, 0, 0),
			width: '90%'
		}
	}
});
