import React from 'react';
import { useTheme } from '@mui/material/styles';
import TableHeadMUI from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import allCols from './cols';
import withColumns from '../columns';

const TableHead = props => {
	const theme = useTheme();
	return (
		<TableHeadMUI>
			<TableRow style={{ height: theme.spacing(5) }}>{allCols.map(cell => cell.comp())}</TableRow>
		</TableHeadMUI>
	);
};

export default withColumns(TableHead);
