import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	loadingPositions: false,
	setLoadingPositions: action((state, payload) => {
		state.loading = payload;
	}),
	data: [],
	setData: action((state, payload) => {
		state.data = payload.map(item => ({
			...item,
			isSelected: false
		}));
	}),
	selectAll: action((state, payload) => {
		state.data = state.data.map(item => ({
			...item,
			isSelected: true
		}));
	}),
	deselectAll: action((state, payload) => {
		state.data = state.data.map(item => ({
			...item,
			isSelected: false
		}));
	}),
	toggleSelection: action((state, payload) => {
		state.data = state.data.map(item => ({
			...item,
			isSelected: item.id === payload ? !item.isSelected : item.isSelected
		}));
	}),
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.proposals
			.getItems(payload)
			.then(data => actions.setData(data.items))
			.catch(e => {
				getStoreActions().error('proposals.getItems');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	deleteItems: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		const { items, id } = payload;
		actions.setLoading(true);
		return services.proposals
			.updateItems(items, id)
			.then(_ => {
				actions.load(id);
				getStoreActions().success('proposals.articlesDeleted');
			})
			.catch(_ => {
				getStoreActions().error('proposals.errorDeletItems');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	updateItemsPosition: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		actions.setLoadingPositions(true);
		return services.proposals
			.updateItemsPosition(payload)
			.then(data => actions.setData(data.items))
			.finally(_ => {
				actions.setLoadingPositions(false);
			});
	})
};
