import React, { useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Stack, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchBar from './Search/components/SearchBar';
import Filters from './Search/components/Filters';
import utils from 'components/utils';

const SeacrhCatalog = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const inputRef = useRef();
	const token = useStoreState(state => state.catalog.search.token);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);

	useEffect(() => {
		//Scroll top
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => {
		const onKeyDown = event => {
			if (event.key === 'Enter') {
				if (inputRef.current) inputRef.current.click(event);
			}
		};
		document.addEventListener('keydown', onKeyDown);
	}, [token]);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={4}
			sx={{ width: '100%' }}
		>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{ marginTop: '10px', width: { xs: '100%', md: '50%', sm: '70%' } }}
			>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={0.5}
					sx={{ width: '100%' }}
				>
					<SearchBar path="search" />
					<Button
						ref={inputRef}
						variant="dark"
						component={Link}
						to={'/catalog/search'}
						onClick={_ => {
							setStep(25);
						}}
						endIcon={<SearchIcon />}
						sx={{ height: '55px' }}
					>
						{translate('common.search.search')}
					</Button>
				</Stack>
			</Stack>
			<Filters variant="fullScreen" />
		</Stack>
	);
};

export default SeacrhCatalog;
