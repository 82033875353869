import React, { useEffect, useState, useCallback } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import parse from 'html-react-parser';
import {
	Stack,
	Typography,
	Divider,
	Tooltip,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	LinearProgress,
	Fab,
	CircularProgress,
	Paper,
	Box,
	Checkbox,
	Backdrop,
	Button
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckIcon from '@mui/icons-material/Check';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SouthIcon from '@mui/icons-material/South';
import utils from 'components/utils';

const Contest = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const user = useStoreState(state => state.me.data);
	const [iAmPersonifying, target] = utils.usePersonification();
	const userId = iAmPersonifying ? target.id : user.id;
	const loadingAbilitations = useStoreState(state => state.contest.loadingAbilitations);
	const contestActive = useStoreState(state => state.contest.contestActive);
	const abilitations = useStoreState(state => state.contest.abilitations);
	const getAbilitations = useStoreActions(dispatch => dispatch.contest.getAbilitations);
	const userInfo = useStoreState(state => state.contest.userInfo);
	const loadUserInfo = useStoreActions(dispatch => dispatch.contest.loadUserInfo);
	const loadingCondictions = useStoreState(state => state.contest.loadingCondictions);
	const updateCondictions = useStoreActions(dispatch => dispatch.contest.updateCondictions);
	const loading = useStoreState(state => state.contest.loading);
	const images = useStoreState(state => state.contest.images);
	const load = useStoreActions(dispatch => dispatch.contest.load);
	const loadingUpload = useStoreState(state => state.contest.loadingUpload);
	const uploadImage = useStoreActions(dispatch => dispatch.contest.uploadImage);
	const successUpload = useStoreState(state => state.contest.successUpload);
	const setSuccessUpload = useStoreActions(dispatch => dispatch.contest.setSuccessUpload);
	const numImages = !images ? 0 : images.filter(img => img.fileUrl != null).length;
	const disabledCondictions = !userInfo || numImages > 0 || loading || loadingUpload || loadingCondictions;
	const disableUpload = iAmPersonifying || !userInfo || !userInfo.condictions || numImages > 2;
	const homePage = process.env.REACT_APP_DOMAIN;
	const logo = process.env.PUBLIC_URL + '/earthbook/earthbook.png';
	const premio = process.env.PUBLIC_URL + '/earthbook/earthbook_premio.png';
	const noImg = process.env.PUBLIC_URL + '/noImg.jpg';
	const contestPath = window.location.pathname.substring(1);
	const condictionsUrl = parse(translate('contest.condition'));
	const validType = ['image/jpeg', 'image/png'];
	const uploadMessage = iAmPersonifying
		? 'uploadSelectFilePersonify'
		: !userInfo || !userInfo.condictions
		? 'uploadSelectFileAccet'
		: numImages >= 3
		? 'uploadSelectFileCompleted'
		: 'uploadSelectFile';
	const [file, setFile] = useState(null);
	const [fileError, setFileError] = useState(false);
	const [previewUrl, setPreviewUrl] = useState(noImg);
	const [contestName, setContestName] = useState('');

	const handleCheckCondictions = event => {
		if (userInfo && userInfo.condictions && numImages > 0) return;
		updateCondictions({ condictions: event.target.checked, path: contestPath });
	};

	const handleUpload = _ => {
		//Evito il doppio caricamento della stessa foto
		if (successUpload) return;
		const path = contestName;
		const pos = numImages + 1;
		const data = new FormData();
		data.append('file', file);
		uploadImage({ data, path, pos });
	};

	const onDrop = useCallback(acceptedFiles => {
		setFile(null);
		setSuccessUpload(false);
		setPreviewUrl(noImg);
		setFileError(false);
		if (acceptedFiles.length > 0) {
			//Verifico il tipo
			if (!validType.includes(acceptedFiles[0].type)) {
				setFileError(true);
				return;
			}
			setFileError(false);
			setFile(acceptedFiles[0]);
			setPreviewUrl(URL.createObjectURL(acceptedFiles[0]));
		} else {
			setFileError(true);
			setFile(null);
			setPreviewUrl(noImg);
		}
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		accept: ['image/jpeg', 'image/png'],
		onDrop,
		maxSize: 30000000,
		disabled: disableUpload
	});

	useEffect(() => {
		//Carico le abilitazioni
		getAbilitations(contestPath);
	}, []);

	useEffect(() => {
		/*
			Verifico se l'utente è abilitato:
			- se non ci sono banners esco => l'utente non è abilitato
			- se ci sono banners cerco quello con url = pathname
		*/
		if (contestActive == false) {
			window.location.href = homePage;
		}
		//Registro il nome del contest
		const contestUrl = contestPath.split('/');
		setContestName(contestUrl[contestUrl.length - 1]);
		//Carico i dati utente
		if (contestActive == true) loadUserInfo(contestPath);
	}, [abilitations]);

	useEffect(() => {
		//Se non ho caricato i dati utente non carico le immagini
		if (!userInfo) return;
		//Inizializzo e carico le immagini
		setFileError(false);
		setFile(null);
		setSuccessUpload(false);
		setPreviewUrl(noImg);
		load(contestName);
	}, [userId, userInfo]);

	return (
		<>
			{(loadingAbilitations || !contestActive) && (
				<Backdrop open={loadingAbilitations} sx={{ zIndex: 2 }}>
					<CircularProgress sx={{ color: theme.palette.primary.main }} size={60} />
				</Backdrop>
			)}
			{!loadingAbilitations && contestActive && (
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					sx={{ width: '100%' }}
				>
					{/* Logo */}
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={1}
						sx={{ width: '100%' }}
					>
						<Box sx={{ maxWidth: { xs: '100%', md: '600px', sm: '100%' } }}>
							<img src={logo} style={{ width: '100%' }} />
						</Box>
					</Stack>

					{/* Immagine, titolo e descrizione */}
					<Stack
						direction={{ xs: 'column', md: 'row', sm: 'row' }}
						justifyContent={{ xs: 'center', md: 'space-between', sm: 'space-between' }}
						alignItems="center"
						spacing={2}
						sx={{ width: '100%' }}
					>
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={0}
							sx={{
								width: { xs: '100%', md: '55%', sm: '50%' },
								padding: {
									xs: '0 10px !important',
									md: '0 30px 0 10px !important',
									sm: '0 10px !important'
								}
							}}
						>
							{/* Titolo e descrizione */}
							{/* 
							<Typography
								variant="titleStyled"
								sx={{
									fontSize: { xs: '30px', md: '31px', sm: '30px' },
									color: theme.palette.custom.blacklight
								}}
							>
								{parse(translate('contest.titlePrize'))}
							</Typography>
							*/}
							<Box>
								<Typography variant="textStyled" sx={{ color: theme.palette.custom.blacklight }}>
									{parse(translate('contest.description'))}
								</Typography>
							</Box>
							<Button
								variant="dark"
								href="#go"
								endIcon={<ArrowDownwardIcon />}
								sx={{ fontSize: '16px', marginTop: '10px !important' }}
							>
								{translate('contest.descriptionAction')}
							</Button>
						</Stack>
						<Box sx={{ width: { xs: '100%', md: '45%', sm: '50%' } }}>
							<img src={premio} style={{ maxWidth: '100%' }} />
						</Box>
					</Stack>

					{/* Numero foto caricate + termini e condizioni */}
					<Stack
						direction="column"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={2}
						sx={{ width: '100%', marginTop: '20px !important' }}
					>
						<a name="go" />
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={1}
							sx={{ width: '100%', padding: '0 10px !important' }}
						>
							<Typography variant="titlePage">{translate('contest.loadedImages') + ': '}</Typography>
							{loading && <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />}
							{!loading && <Typography variant="titlePage">{numImages}</Typography>}
							<Typography variant="titlePage">{translate('contest.uploadMaxFile')}</Typography>
						</Stack>

						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={1}
							sx={{ width: '100%', height: '30px' }}
						>
							{loadingCondictions && (
								<CircularProgress
									size={24}
									sx={{ color: theme.palette.primary.main, margin: '0 4px 0 14px !important' }}
								/>
							)}
							{!loadingCondictions && (
								<Checkbox
									icon={<RadioButtonUncheckedIcon />}
									checkedIcon={<TaskAltIcon sx={{ color: theme.palette.custom.green }} />}
									checked={userInfo && userInfo.condictions ? true : false}
									disabled={iAmPersonifying || disabledCondictions}
									onChange={handleCheckCondictions}
								/>
							)}
							<Typography variant="titleTab">{condictionsUrl}</Typography>
						</Stack>

						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={1}
							sx={{ width: '100%', padding: '0 10px !important', margin: '0px 0 0 36px !Important' }}
						>
							<Typography variant="titleTab">{parse(translate('contest.uploadRules'))}</Typography>
						</Stack>
					</Stack>

					{/* Caricamento foto */}
					{numImages < 4 && (
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={2}
							sx={{ padding: '10px', width: '100%' }}
						>
							<Stack
								direction={{ xs: 'column', md: 'row', sm: 'row' }}
								justifyContent={{ xs: 'center', md: 'flex-start', sm: 'flex-start' }}
								alignItems={{ xs: 'center', md: 'flex-start', sm: 'flex-start' }}
								spacing={{ xs: 3, md: 2, sm: 2 }}
								sx={{ width: '100%' }}
							>
								{/* Dropzone + Progress */}
								<Stack
									direction="column"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={1}
									sx={{ width: '100%', maxWidth: '400px' }}
								>
									{/* Dropzone */}
									<Paper
										{...getRootProps()}
										variant="outlined"
										sx={{
											width: '100%',
											height: '180px',
											backgroundColor: disableUpload
												? theme.palette.custom.greytable
												: theme.palette.custom.white,
											borderStyle: 'dashed',
											borderWidth: '1px',
											borderColor: theme.palette.custom.blacklight,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											cursor: disableUpload ? 'auto' : 'pointer'
										}}
									>
										<Stack
											direction="column"
											justifyContent="center"
											alignItems="center"
											spacing={0}
										>
											<input {...getInputProps()} />
											<Typography
												variant="textTable"
												sx={{
													fontWeight: '600',
													color: theme.palette.custom.blacklight
												}}
											>
												{translate('contest.' + uploadMessage)}
											</Typography>
											<CloudUploadIcon
												sx={{ fontSize: '36px', color: theme.palette.custom.blacklight }}
											/>
										</Stack>
									</Paper>
									{/* file settings o Error */}
									{fileError && (
										<Typography
											variant="textTable"
											sx={{ color: theme.palette.secondary.main, fontWeight: '500' }}
										>
											{parse(translate('contest.fileError'))}
										</Typography>
									)}
									{!fileError && (
										<Typography
											variant="textTable"
											sx={{ color: theme.palette.custom.black, fontWeight: '500' }}
										>
											{parse(translate('contest.filesetting'))}
										</Typography>
									)}
								</Stack>

								{/* Preview */}
								{file && (
									<ImageList
										sx={{
											width: { xs: '100%', md: '578px', sm: '578px' },
											border: '1px solid black',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										<ImageListItem>
											<img
												src={previewUrl}
												//onLoad={() => URL.revokeObjectURL(previewUrl)}
											/>
											<ImageListItemBar
												title={
													<Typography
														variant="chip"
														sx={{ color: theme.palette.custom.white }}
													>
														{file ? file.name : translate('contest.uploadNoFile')}
													</Typography>
												}
												subtitle={
													<Typography
														variant="chip"
														sx={{ color: theme.palette.custom.white }}
													>
														{file ? Math.round(file.size / 1024) : '0'}Mb
													</Typography>
												}
											/>
										</ImageListItem>
									</ImageList>
								)}
								{file && (
									<Stack
										direction="column"
										justifyContent="flex-start"
										alignItems="flec-start"
										spacing={2}
										sx={{ width: '100%' }}
									>
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={0}
											sx={{ width: '100%' }}
										>
											<SouthIcon
												sx={{
													fontSize: '46px',
													color: successUpload
														? theme.palette.custom.green
														: theme.palette.primary.main
												}}
											/>
											<Typography
												variant="titleAccordion"
												sx={{
													color: successUpload
														? theme.palette.custom.green
														: theme.palette.custom.blacklight
												}}
											>
												{successUpload
													? translate('contest.uploadSuccess')
													: translate('contest.uploadDesc')}
											</Typography>
										</Stack>
										<Box
											sx={{
												width: '100%',
												borderWidth: '1px',
												borderColor: theme.palette.custom.blacklight,
												backgroundColor: theme.palette.custom.white,
												borderStyle: 'dashed',
												borderRadius: '5px',
												padding: '10px'
											}}
										>
											<Stack
												direction="row"
												justifyContent="flex-start"
												alignItems="center"
												spacing={0.5}
												sx={{
													width: '100%',
													position: 'relative'
												}}
											>
												<Tooltip
													title={
														successUpload
															? translate('contest.uploadSuccess')
															: translate('contest.upload')
													}
												>
													{!successUpload && (
														<Button
															variant="dark"
															onClick={handleUpload}
															disabled={successUpload}
															endIcon={
																loadingUpload ? (
																	<CircularProgress
																		size={24}
																		sx={{ color: theme.palette.custom.white }}
																	/>
																) : successUpload ? (
																	<CheckIcon />
																) : (
																	<CloudUploadIcon />
																)
															}
															sx={{
																backgroundColor: successUpload
																	? theme.palette.custom.green
																	: 'auto'
															}}
														>
															{translate('contest.send')}
														</Button>
													)}
													{successUpload && (
														<TaskAltIcon
															sx={{ color: theme.palette.custom.green, fontSize: '50px' }}
														/>
													)}
													{/* 
													<Fab
														aria-label="upload"
														onClick={handleUpload}
														sx={{
															width: { xs: '74px', md: '74px', sm: '78px' },
															height: { xs: '60px', md: '60px', sm: '62px' },
															color: theme.palette.custom.white,
															backgroundColor: successUpload
																? theme.palette.custom.green
																: theme.palette.primary.main,
															'&:hover': {
																color: theme.palette.custom.white,
																backgroundColor: successUpload
																	? theme.palette.custom.green
																	: theme.palette.custom.blacklight
															}
														}}
													>
														{successUpload ? (
															<CheckIcon />
														) : (
															<CloudUploadIcon
																sx={{
																	'&:hover': { color: theme.palette.custom.white }
																}}
															/>
														)}
													</Fab>
													*/}
												</Tooltip>
												{/* loadingUpload && (
													<CircularProgress
														size={68}
														sx={{
															color: theme.palette.primary.main,
															position: 'absolute',
															top: { xs: -5, md: -5, sm: -5 },
															left: { xs: -10, md: -6, sm: -10 },
															zIndex: 1
														}}
													/>
													)*/}
												<Stack
													direction="column"
													justifyContent="flex-start"
													alignItems="center"
													spacing={0}
													sx={{ width: '100%' }}
												>
													<Typography variant="textTable" sx={{ fontWeight: '600' }}>
														Upload
													</Typography>
													<Box
														sx={{
															width: '100%',
															color: loadingUpload
																? theme.palette.primary.main
																: successUpload
																? theme.palette.custom.green
																: theme.palette.custom.greyborder
														}}
													>
														<LinearProgress
															variant={loadingUpload ? 'indeterminate' : 'determinate'}
															value={successUpload ? 100 : 0}
															color="inherit"
														/>
													</Box>
													<Typography variant="textTable" sx={{ fontWeight: '600' }}>
														{loadingUpload
															? translate('contest.uploading')
															: successUpload
															? '100%'
															: '0%'}
													</Typography>
												</Stack>
											</Stack>
										</Box>
									</Stack>
								)}
							</Stack>
						</Stack>
					)}
				</Stack>
			)}
		</>
	);
};

export default Contest;
