import k from 'k';
import utils from 'services/utils';

export default userCode => {
	const requestOptions = {
		method: 'POST',
		headers: utils.createHeaders()
	};
	delete requestOptions.headers.PersonifiedUserCode;
	return utils
		.fetch(`${k.endpoints.apis}/me/personify/${userCode}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
