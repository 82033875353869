export default theme => ({
	root: {
		display: 'flex'
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	elements: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	dividerAria: {
		margin: theme.spacing(2, 2)
	},
	labelPlacementStart: {
		marginLeft: 0
	},
	approvalLabel: {
		marginLeft: 0,
		alignItems: 'flex-end',
		[theme.breakpoints.down('md')]: {
			width: 140
		}
	},
	justifyAttributes: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			marginBottom: theme.spacing(3)
		}
	},
	datePickerMargin: {
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(3)
		}
	},
	pickerContainer: {
		justifyContent: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
			justifyContent: 'center'
		}
	}
});
