import React from 'react';
import { useStoreState } from 'easy-peasy';
import Alert from '@mui/lab/Alert';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';

const TransformAlert = ({ id, promoDesc }) => {
	const translate = utils.useI18N();
	const data = useStoreState(state => state.wishlist.details.data);
	if (!data[id] || data[id].length === 0 || !promoDesc) return '';
	return (
		<Alert elevation={3} severity="error" variant="standard" style={{ marginBottom: 8 }}>
			<Typography variant="caption">{translate('wishlist.info2')}</Typography>
			<Typography variant="caption">
				<strong>{` ${promoDesc}. `}</strong>
			</Typography>
			<Typography variant="caption">{translate('wishlist.info3')}</Typography>
		</Alert>
	);
};

export default TransformAlert;
