import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import { useStoreState } from 'easy-peasy';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import utils from 'components/utils';
import styles from '../styles';

const DownloadPdf = ({ classes, item }) => {
	const value = useStoreState(state => state.returns.tabSelected);
	const translate = utils.useI18N();
	return (
		<Fragment>
			{value === 2 && !item.flagAdhesionServiceReturn && (
				<Tooltip title={translate('catalog.downloadResults')} placement="top">
					<IconButton className={classes.noRipple} target="_blank" href={item.pdf}>
						<GetAppOutlinedIcon />
					</IconButton>
				</Tooltip>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(DownloadPdf);
