import React, { Fragment, memo } from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const styles = theme => ({
	custom: {
		paddingLeft: '4px !important',
		'&:hover': {
			background: 'none'
		}
	}
});

const getPriceStyle = type => {
	if (type === 100) {
		return {
			textAlign: 'center',
			textDecoration: 'line-through'
		};
	} else {
		return {
			textAlign: 'center'
		};
	}
};

const PriceNormalUI = ({ classes, fullPrice, ean, type }) => {
	return (
		<Grid container spacing={0} justify="space-between">
			<Grid container item xs justify="center" alignItems="center">
				<Typography variant="body2" style={getPriceStyle(type)}>
					{utils.formatPrice(fullPrice)}
				</Typography>
			</Grid>
		</Grid>
	);
};

const PriceNormal = withStyles(styles, { withTheme: true })(PriceNormalUI);

const PriceOnlySpecialNormalUI = ({ classes, ean, originalPrice, fullPrice }) => {
	return (
		<Fragment>
			{originalPrice && (
				<Fragment>
					<Grid container spacing={0} justify="space-between">
						<Grid container item xs justify="flex-end" alignItems="center">
							<Typography variant="body2" style={{ textDecoration: 'line-through', textAlign: 'right' }}>
								{utils.formatPrice(originalPrice)}
							</Typography>
							<Typography variant="body2" style={{ textAlign: 'right' }}>
								{utils.formatPrice(fullPrice)}
							</Typography>
						</Grid>
					</Grid>
				</Fragment>
			)}
			{!originalPrice && (
				<Typography variant="body2" style={{ textAlign: 'right' }}>
					{utils.formatPrice(fullPrice)}
				</Typography>
			)}
		</Fragment>
	);
};
const PriceOnlySpecialNormal = withStyles(styles, { withTheme: true })(PriceOnlySpecialNormalUI);

const PriceComp = ({ ean, fullPrice, type, originalPrice }) => {
	const onlySpecial = useStoreState(state => state.catalog.filters.list.onlySpecial);
	const onlySpecialFromSelect = useStoreState(state => state.catalog.filters.list.selectedSubScope);
	const onlySpecialScopeFilter = onlySpecialFromSelect && onlySpecialFromSelect.id === '100';
	const isOnlySpecial = onlySpecial || type === 100 || onlySpecialScopeFilter;

	return (
		<StyledTableCell style={{ width: 100, minWidth: 100, paddingRight: 10 }}>
			<Fragment>
				{!isOnlySpecial && <PriceNormal ean={ean} fullPrice={fullPrice} type={type} />}
				{isOnlySpecial && (
					<PriceOnlySpecialNormal ean={ean} fullPrice={fullPrice} originalPrice={originalPrice} />
				)}
			</Fragment>
		</StyledTableCell>
	);
};

const Price = memo(
	({ item }) => (
		<PriceComp ean={item.ean} fullPrice={item.fullPrice} type={item.type} originalPrice={item.originalPrice} />
	),
	(p, n) =>
		p.item.ean === n.item.ean &&
		p.item.fullPrice === n.item.fullPrice &&
		p.item.originalPrice === n.item.originalPrice &&
		p.item.type === n.item.type
);

export default Price;
