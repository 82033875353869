import React from 'react';
import { Stack, Typography } from '@mui/material';
import utils from 'components/utils';

const Price = props => {
	const { classes, details } = props;
	const translate = utils.useI18N();
	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0.5}
			sx={{ margin: '5px 0' }}
		>
			<Typography variant="text" sx={{ fontWeight: '600' }}>
				{translate('catalog.details.infoBox.fullPrice') + ':'}
			</Typography>
			{details.originalPrice && (
				<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
					<Typography variant="text" style={{ textDecoration: 'line-through' }}>
						{utils.formatPrice(details.originalPrice)}
					</Typography>
					<Typography variant="text">- {utils.formatPrice(details.fullPrice)}</Typography>
				</Stack>
			)}
			{!details.originalPrice && (
				<Typography variant="text">
					{utils.formatPrice(details.fullPrice)} {details.iva !== '0' ? '(+ iva ' + details.iva + '%)' : ''}
				</Typography>
			)}
		</Stack>
	);
};

export default Price;
