import React from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import Delete from './Delete';

const allCells = [
	{
		id: 'cod',
		comp: ({ item }) => (
			<StyledTableCell id="cod" key={`bodyCell_ean_${item.clientCode}`} component="th" scope="row">
				{item.clientCode}
			</StyledTableCell>
		)
	},
	{
		id: 'rag',
		comp: ({ item }) => (
			<StyledTableCell id="rag" key={`bodyCell_ean_${item.clientUserName}`} component="th" scope="row">
				{item.clientUserName}
			</StyledTableCell>
		)
	},
	{
		id: 'delete',
		comp: ({ item }) => <Delete id="delete" key={`bodyCell_delete_${item.id}`} item={item} />
	}
];

export default allCells;
