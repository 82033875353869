import React, { Fragment, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { Stack, Grid, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import utils from 'components/utils';
import styles from './styles';

const ScopeSelector = props => {
	const { classes } = props;
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const translate = utils.useI18N();
	const stateFilters = useStoreState(state => state.catalog.filters.list);
	const scopes = useStoreState(state => state.catalog.scopes.list);
	const loadingGetScopes = useStoreState(state => state.catalog.scopes.loading);
	const getScopes = useStoreActions(dispatch => dispatch.catalog.scopes.retrieve);
	const selectScope = useStoreActions(dispatch => dispatch.catalog.scopes.select);
	const selectSubScope = useStoreActions(dispatch => dispatch.catalog.scopes.selectSub);
	const [scopeSelected, setScopeSelected] = useState('');
	const [subScopeSelected, setSubScopeSelected] = useState('');
	const [currentSubScopeList, setCurrentSubScopeList] = useState([]);
	useEffect(
		_ => {
			if (scopes.length === 0) getScopes();
		},
		[scopes, getScopes]
	);
	useEffect(
		_ => {
			if (scopes.length === 0) return;
			const selected = scopes.find(s => s.selected);
			setScopeSelected(selected.id);
			setCurrentSubScopeList(selected.children === null ? [] : selected.children);
		},
		[scopes]
	);
	useEffect(
		_ => {
			if (currentSubScopeList.length === 0) return;
			const selected = currentSubScopeList.find(s => s.selected);
			setSubScopeSelected(selected.id);
		},
		[currentSubScopeList]
	);
	const filterOnlySpecial = currentSubScopeList.filter(item => item.id !== '100');
	const filteredScope = iCan('SHOW_SPECIAL_FILTER') ? currentSubScopeList : filterOnlySpecial;

	//Nel caso di ricerca su badge ranking o thema elimino alcuni elementi
	const newScopes =
		props.cbSize === 6 && (stateFilters.badgeRanking || stateFilters.themaRanking)
			? scopes.filter(item => item.id !== 'rankings' && item.id !== 'editorial-unlock' && item.it !== 'coupons')
			: scopes;

	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ width: '100%' }}>
			{!loadingGetScopes && scopes.length > 0 && (
				<FormControl sx={{ width: { xs: '50%', md: '25%', sm: '50%' } }}>
					<Select
						labelId="filter-select-scope"
						id="select-scope"
						value={scopeSelected}
						onChange={event => selectScope(event.target.value)}
						displayEmpty
						sx={{
							fontFamily: ['"Poppins", sans-serif'].join(','),
							fontSize: '14px',
							fontWeight: '400',
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: scopeSelected !== 'full' ? theme.palette.primary.main : '#cccdce'
						}}
					>
						{newScopes.map((item, index) => (
							<MenuItem key={`filter_scope_${item.id}`} value={item.id}>
								{translate(`catalog.scopes.${item.label}`)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}

			{filteredScope.length > 0 && (
				<FormControl sx={{ width: { xs: '50%', md: '350px', sm: '50%' } }}>
					<Select
						labelId="filter-select-filteredscope"
						id="select-filteredscope"
						value={subScopeSelected}
						onChange={event => selectSubScope(event.target.value)}
						displayEmpty
						sx={{
							fontFamily: ['"Poppins", sans-serif'].join(','),
							fontSize: '14px',
							fontWeight: '400',
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor:
								subScopeSelected !== -1 ? theme.palette.primary.main : theme.palette.secondary.main
						}}
					>
						{filteredScope.map((item, index) => (
							<MenuItem key={`filter_subscope_${item.id}`} value={item.id}>
								{item.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(ScopeSelector);
