import k from 'k';
import utils from 'services/utils';

export default ({ startDate, endDate, available }) => {
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify({ startDate, endDate, available }),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/returns/calendar`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
