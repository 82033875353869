import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

export default (actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
	const { services } = injections;
	actions.setLoading(true);
	const step = payload && payload.forceStep ? payload.forceStep : getState().SEARCH_paginationStep;
	const page = getState().pagination.current - 1;
	const token = getState().token;
	const last = getState().last;
	const filters = pickBy(getStoreState().catalog.filters.list, identity);
	const { by, direction } = getStoreState().catalog.order;
	const params = {
		token,
		page,
		filters,
		order: {
			by,
			direction
		},
		step
	};
	// if somehting changes we reset the page number
	if (last) {
		const picked = pick(params, ['token', 'filters', 'step', 'order']);
		if (!isEqual(picked, last)) params.page = 0;
	}
	return services.catalog
		.search(params)
		.then(data => {
			getStoreActions().selection.resetPromoList();
			actions.saveResults(data);
			actions.setPerformed(true);
			actions.setLast(params);
			return Promise.resolve(data);
		})
		.catch(e => {
			getStoreActions().error('catalog.search');
		})
		.finally(_ => {
			actions.setLoading(false);
			getStoreActions().catalog.autocomplete.close();
		});
};
