import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import {
	Stack,
	Grid,
	Typography,
	TextField,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Paper,
	IconButton,
	Tooltip,
	CircularProgress
} from '@mui/material';
import CartSelector from 'components/CartSelector';
import WishlistSelector from 'components/WishlistSelector';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WishlistCreate from 'components/WishlistCreate';
import CreateCart from 'routes/Orders7/CreateCart';
import Popover from 'components/Popover';
import utils from 'components/utils';
import NumericStepper from 'components/NumericStepper';
import compUtils from 'components/utils';
import Card from 'components7/Card';
import Restocking from './Restocking';
import CartIcon from './CartIcon';
import WishlistIcon from './WishlistIcon';
import DepotWarnings from './DepotWarnings';
import styles from './styles';

const getShippingTypeInfo = depot => {
	const def = depot.shippingTypes.find(d => d.defaultValue);
	return def ? def.sla : 'NO_INFO';
};
const fesOnDepot = depot => {
	const activeFESOnDepots = depot.find(d => d.fes);
	return activeFESOnDepots ? 1 : 0;
};

const getSource = ulrSearch => {
	//verifico se c'è una querystring
	const qsArray = ulrSearch.split('?');
	//Se non c'è una querystring non c'è il source
	if (qsArray.length < 2) return '';
	//Se c'è una querystring cerco il parametro source
	let param = '';
	let source = '';
	const paramsList = qsArray[1].split('&');
	paramsList.forEach(qs => {
		param = qs.split('=');
		//Se c'è il parametro source registro il valore
		if (param.length == 2 && param[0] === 'source') source = param[1];
	});
	return source;
};

const Order = ({ itemOrder, itemWishlist, classes, promoSearch, urlComponet }) => {
	const theme = useTheme();
	const history = useHistory();
	const availableDepots = utils.useUserData('availableDepots', []);
	const paymentTypeId = utils.useUserData('paymentTypeId', [], false);
	const transferType = utils.useUserData('transferType', null);
	const anchorWlEl = useStoreState(state => state.selection.anchorWlEl);
	const anchorCartEl = useStoreState(state => state.selection.anchorCartEl);
	const loadingWlLight = useStoreState(state => state.wishlist.listLight.loading);
	const loadCartList = useStoreActions(dispatch => dispatch.cart.listLight.load);
	const loadWlList = useStoreActions(dispatch => dispatch.wishlist.listLight.load);
	const loadingCartLight = useStoreState(state => state.cart.listLight.loading);
	const details = useStoreState(state => state.catalog.details.data);
	const cartList = useStoreState(state => state.cart.listLight.items);
	const wList = useStoreState(state => state.wishlist.listLight.items);
	const createOrder = useStoreActions(dispatch => dispatch.order.create.sendRequest);
	const addToWishlist = useStoreActions(dispatch => dispatch.wishlist.add.sendRequest);
	const selectQuantity = useStoreActions(dispatch => dispatch.catalog.details.selectQuantity);
	const setAnchorWlEl = useStoreActions(dispatch => dispatch.selection.setAnchorWlEl);
	const setAnchorCartEl = useStoreActions(dispatch => dispatch.selection.setAnchorCartEl);
	const setModalOpen = useStoreActions(dispatch => dispatch.catalog.details.setModalOpen);
	const setOpenWishlistModal = useStoreActions(dispatch => dispatch.wishlist.details.setOpenModal);
	const setOpenOrdersModal = useStoreActions(dispatch => dispatch.order.create.setOpenModal);
	const realUser = useStoreState(state => state.me.data);
	const translate = compUtils.useI18N();
	const [note, setNote] = useState('');
	const [wlName, setWlName] = useState('');
	const [wlNotes, setWlNotes] = useState('');
	let pathIds = '';
	let orderFromNL = '';

	if (history.location.search) {
		pathIds = history.location.search;
		pathIds = pathIds.replace('?', '');
		pathIds = pathIds.split('=');
		//Il source non deve considerare il parametro "ic" aggiunto per visualizzare "Carrello" nella scheda dettaglio
		//orderFromNL = pathIds[0] !== 'ic' ? pathIds[1] : ''; -> creata funzione per recuperare il parametro source
		orderFromNL = getSource(history.location.search);
	}

	//useEffect(loadWlList, [loadWlList]); //-> eliminato perchè crea una doppia chiamata a wishlist e non serve
	//useEffect(loadCartList, [loadCartList]); -> eliminato perchè crea una doppia chiamata a cart e non serve
	const openCart = Boolean(anchorCartEl);
	const openWl = Boolean(anchorWlEl);
	const mQ = useMediaQuery(theme.breakpoints.down('xs'));
	const responsiveTitle = (title, length) => {
		if (mQ) return utils.useEllipsis(title, length, '.');
		if (!mQ) return title;
	};
	const addToCart = (depotId, depotDescription, fcd) => {
		const cart = cartList.find(item => item.selected);
		const cartId = cart ? cart.id : null;
		const redirect = urlComponet;
		const refresh = urlComponet.includes('/orders/list') ? true : false;
		const selectedDepot = details.depots.find(itm => itm.id === depotId);
		const totalPrice = details.fullPrice * selectedDepot.quantity;
		const source = orderFromNL;
		if (!selectedDepot) return;
		const successMessage =
			translate('catalog.selection.addToSelectedCart') +
			' ' +
			cart.name +
			', ' +
			translate('catalog.selection.withDepot') +
			' ' +
			utils.capitalizeAll(depotDescription);

		const order = {
			redirect,
			refresh,
			history,
			catalog: details.ean,
			source: source,
			depot: depotId,
			cartId,
			quantity: selectedDepot.quantity,
			note,
			successMessage,
			promoCode: promoSearch && details.isPromo ? details.promo[0].sapId : null,
			realUserCode: realUser.id,
			totalPrice: totalPrice,
			fcd,
			sla: null,
			shippingType: null,
			supplier: null
		};

		if (selectedDepot && selectedDepot.shippingTypes && selectedDepot.shippingTypes.length) {
			const sla = selectedDepot.shippingTypes[0]?.sla;
			const shippingType = selectedDepot.shippingTypes[0]?.code;
			const supplier = selectedDepot.shippingTypes[0]?.supplier;

			order.sla = sla;
			order.shippingType = shippingType;
			order.supplier = supplier;
		}
		createOrder(order, history, redirect, refresh);
		setOpenWishlistModal(false);
		setOpenOrdersModal(false);
		setModalOpen(false);
		history.push(`/catalog/search/`); //Aggiunto per gestire il caso di apertura scheda dettaglio su altro tab
	};
	const addToWl = (depotId, depotDescription) => {
		const wishlistItem = wList.find(item => item.selected);
		const wlistId = wishlistItem ? wishlistItem.id : null;
		const selectedDepot = details.depots.find(itm => itm.id === depotId);
		if (!selectedDepot) return;
		const orders = [
			{
				ean: details.ean,
				depotCode: depotId,
				depotDescription,
				quantity: selectedDepot.quantity,
				promotionSapId: promoSearch && details.isPromo ? details.promo[0].sapId : null,
				notes: note,
				shippingType: selectedDepot.shippingTypes[0]?.code
			}
		];
		const wishlist = wlistId;
		const successMessage = wlistId
			? translate('catalog.selection.addToSelectedWishlist') +
			  ' ' +
			  wishlistItem.name +
			  ', ' +
			  translate('catalog.selection.withDepot') +
			  ' ' +
			  utils.capitalizeAll(depotDescription)
			: null;
		const redirect = urlComponet;
		const refresh = urlComponet.includes('/wishlist/list') ? true : false;
		addToWishlist({ orders, wishlist, successMessage, history, redirect, refresh });
		setOpenOrdersModal(false);
		setOpenWishlistModal(false);
		setModalOpen(false);
		history.push(`/catalog/search/`); //Aggiunto per gestire il caso di apertura scheda dettaglio su altro tab
	};
	const isOrderable = details.ordinable === 2;
	const resetOnWlClose = _ => {
		setWlNotes('');
		setWlName('');
	};
	const padding = {
		padding: 8
	};
	const maxValue = depot => {
		if (depot.fes) return depot.stock;
		if (depot.warning1000) return depot.quantity;
		if (details.type === 200) return 20;
		return 9999;
	};
	//Se di tratta di Card Epipoli abilito solo fust hub altrimenti eseguo l'abilitazione normale dei depositi
	const filteredDetailsDepots =
		details.type === 200
			? details.depots.filter(d => d.id === 'FS01')
			: paymentTypeId !== 1
			? details.depots
			: details.depots.filter(d => d.id !== 'FS01');
	const filteredFESDepots = fesOnDepot(details.depots);
	const filteredAvailableDepots =
		paymentTypeId !== 1 ? availableDepots : availableDepots.filter(d => d.id !== 'FS01');

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ width: '100%' }}
		>
			{details && isOrderable && filteredAvailableDepots.length > 0 && (
				<Card
					title={translate('catalog.details.orderInfo')}
					backgroundColor={theme.palette.custom.white}
					margin={'20px 0 0 0 !important'}
					width={'100%'}
				>
					{details.newsItem === 'non accettab.in resa' && (
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={0.5}
							sx={{ marginBottom: '20px' }}
						>
							<Typography variant="text" sx={{ fontWeight: '600' }}>
								{translate('catalog.details.infoAlert')}&nbsp;
							</Typography>
							<Typography variant="text" sx={{ color: theme.palette.secondary.main }}>
								{translate('catalog.details.infoAlertMessage')}
							</Typography>
						</Stack>
					)}
					<Stack
						direction={{ xs: 'column', md: 'row', sm: 'column' }}
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={{ xs: 1, md: 2, sm: 1 }}
						sx={{ width: '100%' }}
					>
						<TableContainer component={Paper}>
							<Table aria-label="search results table" size="small">
								<TableHead>
									<TableRow>
										<TableCell sx={{ width: '110px' }}>
											{translate('catalog.details.depot')}
										</TableCell>
										<TableCell sx={{ maxWidth: '80px' }}>
											{responsiveTitle(translate('common.quantity'), 6)}
										</TableCell>
										<TableCell sx={{ maxWidth: '80px' }}>
											{responsiveTitle(translate('catalog.details.stockInfo'), 5)}
										</TableCell>
										<TableCell sx={{ maxWidth: '90px' }}>
											{translate('catalog.details.supplying')}
										</TableCell>
										<TableCell>{translate('catalog.details.info')}</TableCell>
										<TableCell sx={{ maxWidth: '80px' }}>
											{responsiveTitle(translate('catalog.details.order'), 4)}
										</TableCell>
										<TableCell sx={{ maxWidth: '80px' }}>
											{responsiveTitle(translate('common.menu.wishlist'), 10)}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(filteredFESDepots === 0 &&
										filteredDetailsDepots.map((depot, index) => (
											<TableRow key={`book_detaild_depot_${depot.id}`} style={{ height: 46 }}>
												<TableCell>{depot.description}</TableCell>
												<TableCell>
													{depot.enable && (
														<Grid container>
															<Grid
																item
																container
																xs={12}
																md={6}
																sx={{ maxWidth: '80px !important' }}
															>
																<NumericStepper
																	onChange={valueAsNumber => {
																		selectQuantity({
																			quantity: valueAsNumber,
																			id: depot.id
																		});
																	}}
																	value={depot.quantity}
																	min={1}
																	max={maxValue(depot)}
																	strict
																	removeDot
																	precision={0}
																/>
															</Grid>
															<Grid item container xs={12}>
																<DepotWarnings item={depot} />
															</Grid>
														</Grid>
													)}
													{!depot.enable && <span>{depot.quantity}</span>}
												</TableCell>
												<TableCell>{depot.stock}</TableCell>
												<Restocking depot={depot} />
												<TableCell align="left">
													{translate(
														'catalog.shippingTypes.' +
															getShippingTypeInfo(depot).toLowerCase()
													)}
												</TableCell>
												<TableCell>
													<CartIcon
														enabled={depot.enable}
														depotId={depot.id}
														onClick={_ => {
															addToCart(depot.id, depot.description, false);
														}}
													/>
												</TableCell>
												<TableCell>
													<WishlistIcon
														enabled={depot.enable}
														depotId={depot.id}
														onClick={_ => {
															addToWl(depot.id, depot.description);
														}}
													/>
												</TableCell>
											</TableRow>
										))) ||
										(filteredFESDepots === 1 &&
											filteredDetailsDepots
												.filter(depot => depot.stock > 0)
												.map((depot, index) => (
													<TableRow
														key={`book_detaild_depot_${depot.id}`}
														style={{ height: 46 }}
													>
														<TableCell>{depot.description}</TableCell>
														<TableCell>
															{depot.enable && (
																<Grid container>
																	<Grid item container xs={12} md={6}>
																		<NumericStepper
																			onChange={valueAsNumber => {
																				selectQuantity({
																					quantity: valueAsNumber,
																					id: depot.id
																				});
																			}}
																			value={depot.quantity}
																			min={1}
																			max={maxValue(depot)}
																			strict
																			removeDot
																			precision={0}
																		/>
																	</Grid>
																	<Grid item container xs={12}>
																		<DepotWarnings item={depot} />
																	</Grid>
																</Grid>
															)}
															{!depot.enable && <span>{depot.quantity}</span>}
														</TableCell>
														<TableCell>{depot.stock}</TableCell>
														<Restocking depot={depot} />
														<TableCell align="left">
															{translate(
																'catalog.shippingTypes.' +
																	getShippingTypeInfo(depot).toLowerCase()
															)}
														</TableCell>
														<TableCell>
															<CartIcon
																enabled={depot.enable}
																depotId={depot.id}
																onClick={_ => {
																	addToCart(depot.id, depot.description, false);
																}}
															/>
														</TableCell>
														<TableCell>
															<WishlistIcon
																enabled={depot.enable}
																depotId={depot.id}
																onClick={_ => {
																	addToWl(depot.id, depot.description);
																}}
															/>
														</TableCell>
													</TableRow>
												)))}
								</TableBody>
							</Table>
						</TableContainer>
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={0}
							sx={{
								width: { xs: '100%', md: '50%', sm: '100%' },
								marginTop: { xs: '15px !important', md: '0 !important', sm: '15px !important' }
							}}
						>
							{!itemWishlist && !itemOrder && (
								<TextField
									value={note}
									onChange={e => setNote(e.target.value)}
									label={translate('catalog.details.insertNote')}
									//label={translate('catalog.details.note')}
									sx={{ width: '100%', marginTop: '0 !important' }}
								/>
							)}
							<Stack
								direction="column"
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={1}
								sx={{
									width: '100%',
									borderWidth: '1px',
									borderStyle: 'solid',
									borderColor: theme.palette.custom.greyborder,
									borderRadius: '5px',
									marginTop: '15px !important',
									padding: '10px 4px 10px 8px !important'
								}}
							>
								{/* CREATE CART COMPONENT */}
								{loadingCartLight && <CircularProgress style={{ alignSelf: 'center' }} size={16} />}
								{!loadingCartLight && (
									<Stack
										direction="column"
										justifyContent="flex-start"
										alignItems="flex-start"
										spacing={0.5}
										sx={{ width: '100%' }}
									>
										<Typography variant="text">
											{translate('catalog.details.selectedCart')}
										</Typography>
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={0}
											sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}
										>
											<CartSelector />
											<Tooltip title={translate('orders.createCart')} placement="top">
												<IconButton
													disableRipple
													onClick={e => {
														setAnchorCartEl(e.currentTarget);
													}}
													sx={{ color: theme.palette.primary.main }}
												>
													<AddCircleIcon sx={{ fontSize: '1.2em' }} />
												</IconButton>
											</Tooltip>
										</Stack>
										<Popover
											open={openCart}
											anchorEl={anchorCartEl}
											onClose={_ => {
												setAnchorCartEl(null);
											}}
										>
											<CreateCart
												showSave={true}
												showClose={true}
												onClose={_ => {
													setAnchorCartEl(null);
												}}
											/>
										</Popover>
									</Stack>
								)}
								{/* CREATE WL COMPONENT */}
								{loadingWlLight && <CircularProgress size={16} style={{ alignSelf: 'center' }} />}
								{!loadingWlLight && (
									<Stack
										direction="column"
										justifyContent="flex-start"
										alignItems="flex-start"
										spacing={0.5}
										sx={{ width: '100%', marginTop: '20px !important' }}
									>
										<Typography variant="body2">
											{translate('catalog.details.selectedWishlist')}
										</Typography>
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={0}
											sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}
										>
											<WishlistSelector />
											<Tooltip
												title={translate('catalog.details.createWishlist')}
												placement="top"
											>
												<IconButton
													disableRipple
													onClick={e => {
														setAnchorWlEl(e.currentTarget);
													}}
													sx={{ color: theme.palette.primary.main }}
												>
													<AddCircleIcon sx={{ fontSize: '1.2em' }} />
												</IconButton>
											</Tooltip>
										</Stack>
										<Popover
											open={openWl}
											anchorEl={anchorWlEl}
											onClose={_ => {
												setAnchorWlEl(null);
												resetOnWlClose();
											}}
										>
											<WishlistCreate
												resetOnSave={resetOnWlClose}
												onClose={_ => {
													setAnchorWlEl(null);
													resetOnWlClose();
												}}
												onNoteChange={e => {
													setWlNotes(e.target.value);
												}}
												defaultNotesValue={wlNotes}
												defaultNameValue={wlName}
												onNameChange={e => {
													setWlName(e.target.value);
												}}
											/>
										</Popover>
									</Stack>
								)}
							</Stack>
						</Stack>
					</Stack>
				</Card>
			)}
			{details && isOrderable && filteredAvailableDepots.length === 0 && (
				<Grid container item xs={12} justify="center">
					<Typography variant="titleSection">{translate('catalog.details.noDepotsMessage')}</Typography>
				</Grid>
			)}
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(Order);
