import k from 'k';
import utils from 'services/utils';

export default wishlistItemId => {
	const requestOptions = {
		method: 'DELETE',
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/wishlist/items/${wishlistItemId}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
