import React from 'react';
import { useTheme } from '@mui/material/styles';
import TableHeadMUI from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import allCols from './cols';
import withColumns from '../columns';

const TableHead = ({ visibleCols, order, orderBy, setOrder }) => {
	const theme = useTheme();
	return (
		<TableHeadMUI sx={{ background: theme.palette.custom.greytable, fontWeight: '600' }}>
			<TableRow>{allCols.map(cell => cell.comp({ order, orderBy, setOrder }))}</TableRow>
		</TableHeadMUI>
	);
};

export default withColumns(TableHead);
