import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { DropzoneArea } from 'react-mui-dropzone';
import { Stack, Typography, Button, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';

const ImportItems = props => {
	const { id, onClose } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.proposals.fileManager.loading);
	const importItmes = useStoreActions(dispatch => dispatch.proposals.fileManager.importItmes);
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [clicked, click] = useState(false);

	const getFileLimitExceedMessage = filesLimit =>
		translate('upload.fileLimitExceedMessage').replace('_NUM_', filesLimit);
	const getFileAddedMessage = fileName => translate('upload.fileAddedMessage').replace('_NAME_', fileName);
	const getFileRemovedMessage = fileName => translate('upload.fileRemovedMessage').replace('_NAME_', fileName);
	const getDropRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) =>
		translate('upload.dropRejectMessage').replace('_NAME_', rejectedFile.name);

	const onConfirm = _ => {
		importItmes({ file, id });
	};

	const onCloseModal = _ => {
		onClose();
	};

	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					borderWidth: '0 0 1px 0',
					borderStyle: 'solid',
					borderColor: theme.palette.custom.black
				}}
			>
				<Typography variant="titleTab">{translate('upload.selectFile')}</Typography>
				<IconButton onClick={onCloseModal}>
					<CloseIcon />
				</IconButton>
			</Stack>
			{fileName !== '' && (
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={0.5}
					sx={{ width: '100%' }}
				>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						{translate('proposals.importAddedFile') + fileName}
					</Typography>
					<Typography variant="text" sx={{ color: theme.palette.custom.orange }}>
						{translate('common.warningUpload')}
					</Typography>
					<Typography variant="text" sx={{ color: theme.palette.custom.orange }}>
						{translate('proposals.importMaxItems')}
					</Typography>
				</Stack>
			)}
			<DropzoneArea
				acceptedFiles={['.csv']}
				showAlerts={['error']}
				filesLimit={1}
				maxFileSize={3000000}
				dropzoneText=""
				getFileLimitExceedMessage={getFileLimitExceedMessage}
				getFileAddedMessage={getFileAddedMessage}
				getFileRemovedMessage={getFileRemovedMessage}
				getDropRejectMessage={getDropRejectMessage}
				alertSnackbarProps={{
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'top'
					},
					autoHideDuration: 2000
				}}
				previewGridProps={{
					container: { spacing: 0, justifyContent: 'center' },
					item: { xs: 4 }
				}}
				onDrop={async ([file]) => {
					var reader = new FileReader();
					reader.onload = function(e) {
						var contents = e.target.result;
						setFile(contents);
						setFileName(file.name);
					};
					reader.readAsText(file);
				}}
			/>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{ width: '100%' }}
			>
				<Button variant="light" onClick={onCloseModal}>
					{translate('common.close')}
				</Button>
				{loading && <CircularProgress size={24} />}
				{!loading && (
					<Button variant="dark" disabled={!file} onClick={onConfirm}>
						{translate('common.confirm')}
					</Button>
				)}
			</Stack>
		</Stack>
	);
};

export default ImportItems;
