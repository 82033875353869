export default theme => ({
	text: {
		padding: '2px 9px'
	},
	itemsContainer: {
		backgroundColor: '#fff',
		width: 240,
		padding: 16,
		border: '1px solid #00529b',
		borderRadius: '0 0 8px 8px'
	},
	cnt: {
		width: '100%',
		cursor: 'pointer'
	},
	pb: {
		textShadow: '#000 1px 1px 1px'
	}
});
