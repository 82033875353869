import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: [],
	setData: action((state, payload) => {
		state.data = payload;
	}),
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.editorials
			.getItems(payload)
			.then(data => actions.setData(data.items))
			.catch(e => {
				getStoreActions().error('editorials.getItems');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
