import React from 'react';
import TableCell from '@mui/material/TableCell';
import utils from 'components/utils';

const NotSortable = ({ label, additionalStyles }) => {
	const translate = utils.useI18N();
	const defaultStyles = {
		paddingLeft: 4,
		paddingRight: 4,
		backgroundColor: '#fafafa'
	};
	return (
		<TableCell align="left" style={{ ...defaultStyles, ...additionalStyles }}>
			<span className="MuiButtonBase-root" aria-disabled="true">
				{translate(label)}
			</span>
		</TableCell>
	);
};

export default NotSortable;
