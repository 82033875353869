import React, { useState, useEffect } from 'react';
import compact from 'lodash/compact';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? 'lightgrey' : '#F5F5F5',
	padding: 8,
	width: 150,
	alignContent: 'flex-start',
	marginBottom: 16
});
const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	padding: 8,
	margin: `0 0 8px 0`,
	background: isDragging ? 'grey' : 'white',
	...draggableStyle
});
const subtitleStyle = {
	marginBottom: 8
};
const getDepot = (source, code) => source.find(d => d.code === code);

const DepotExchange = ({ classes, type, availableDepots, currentDepots, items, updateItems }) => {
	const translate = utils.useI18N();
	const source = useStoreState(state => state.depots.data);
	const purgedAvailable = availableDepots.filter(ad => currentDepots.indexOf(ad) === -1);
	const move = (source, destination, droppableSource, droppableDestination) => {
		const sourceClone = Array.from(source);
		const destClone = Array.from(destination);
		const [removed] = sourceClone.splice(droppableSource.index, 1);
		destClone.splice(droppableDestination.index, 0, removed);
		const result = {};
		result[droppableSource.droppableId] = sourceClone;
		result[droppableDestination.droppableId] = destClone;
		return result;
	};
	const getList = id => (id === 'droppableLeft' ? itemsLeft : itemsRight);
	const onDragEnd = result => {
		const { source, destination } = result;
		if (!destination) return; // dropped outside the list
		if (source.droppableId === destination.droppableId) return; // dropped in the same list
		const { droppableLeft, droppableRight } = move(
			getList(source.droppableId),
			getList(destination.droppableId),
			source,
			destination
		);
		setItemsLeft(droppableLeft);
		updateItems(droppableLeft);
		setItemsRight(droppableRight);
	};
	const [itemsLeft, setItemsLeft] = useState(compact(currentDepots.map(d => getDepot(source, d))));
	const [itemsRight, setItemsRight] = useState(compact(purgedAvailable.map(d => getDepot(source, d))));
	useEffect(
		_ => {
			updateItems(currentDepots.map(d => getDepot(source, d)));
		},
		[currentDepots, updateItems, source]
	);
	return (
		<Grid container>
			<Grid container item xs={12}>
				<Typography>{translate('users.depotsTitle' + type)}</Typography>
			</Grid>
			<Grid container item xs={6} style={subtitleStyle}>
				<Typography variant="body2">{translate('users.depotsSelected')}</Typography>
			</Grid>
			<Grid container item xs={6} style={subtitleStyle}>
				<Typography variant="body2">{translate('users.depotsAvailable')}</Typography>
			</Grid>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppableLeft">
					{(provided, snapshot) => (
						<Grid
							container
							item
							xs={6}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
							{...provided.droppableProps}
						>
							{itemsLeft.map((item, index) => (
								<Draggable key={`draggable_${item.code}`} draggableId={item.code} index={index}>
									{(provided, snapshot) => (
										<Grid
											item
											xs={12}
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
										>
											{`(${item.code}) ${item.name}`}
										</Grid>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Grid>
					)}
				</Droppable>
				<Droppable droppableId="droppableRight">
					{(provided, snapshot) => (
						<Grid
							container
							item
							xs={6}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
							{...provided.droppableProps}
						>
							{itemsRight.map((item, index) => (
								<Draggable key={`draggable_${item.code}`} draggableId={item.code} index={index}>
									{(provided, snapshot) => (
										<Grid
											item
											xs={12}
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
										>
											{`(${item.code}) ${item.name}`}
										</Grid>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Grid>
					)}
				</Droppable>
			</DragDropContext>
		</Grid>
	);
};

export const OrderDepotExchange = props => {
	const availableDepots = useStoreState(state => state.depots.shippingDepots);
	const currentDepots = useStoreState(state => state.users.details.data.availableDepots);
	const updateItems = useStoreActions(dispatch => dispatch.users.details.setShippingItems);
	return (
		<DepotExchange
			availableDepots={availableDepots.map(d => d.code)}
			currentDepots={currentDepots.map(d => d.code)}
			type="SHIPPING"
			updateItems={updateItems}
		/>
	);
};

export const ReturnDepotExchange = props => {
	const availableDepots = useStoreState(state => state.depots.returnDepots);
	const returnDepots = useStoreState(state => state.users.details.data.returnDepots);
	const updateItems = useStoreActions(dispatch => dispatch.users.details.setReturnItems);
	return (
		<DepotExchange
			availableDepots={availableDepots.map(d => d.code)}
			currentDepots={returnDepots.map(d => d.code)}
			type="RETURN"
			updateItems={updateItems}
		/>
	);
};
