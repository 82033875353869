import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import UserGroupsSelector from 'components/UserGroupsSelector';
import utils from 'components/utils';
import Drawer from '@mui/material/Drawer/Drawer';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	}
});

const AssignUserGroup = ({ classes, currentPromo }) => {
	const translate = utils.useI18N();
	const modalOpen = useStoreState(state => state.userGroups.list.modalOpen);
	const loadingListVisualization = useStoreState(state => state.promotions.userGroupsVisualization.loadingList);
	const loadingListAbilitation = useStoreState(state => state.promotions.userGroupsAbilitation.loadingList);
	const detailsVisualization = useStoreState(state => state.promotions.detailsUserGroupsVisualization.data);
	const detailsAbilitation = useStoreState(state => state.promotions.detailsUserGroupsAbilitation.data);
	const setModalOpen = useStoreActions(dispatch => dispatch.userGroups.list.setModalOpen);
	const addVisualization = useStoreActions(dispatch => dispatch.promotions.userGroupsVisualization.add);
	const addAbilitation = useStoreActions(dispatch => dispatch.promotions.userGroupsAbilitation.add);
	const removeVisualization = useStoreActions(dispatch => dispatch.promotions.userGroupsVisualization.remove);
	const removeAbilitation = useStoreActions(dispatch => dispatch.promotions.userGroupsAbilitation.remove);
	const toggleSelectionVisualization = (id, checked) => {
		if (checked) addVisualization({ sapId: currentPromo.sapPromoId, userGroupId: id });
		else removeVisualization({ sapId: currentPromo.sapPromoId, userGroupId: id });
	};
	const toggleSelectionAbilitation = (id, checked) => {
		if (checked) addAbilitation({ sapId: currentPromo.sapPromoId, userGroupId: id });
		else removeAbilitation({ sapId: currentPromo.sapPromoId, userGroupId: id });
	};
	const getCbStatusVisualization = id => {
		return detailsVisualization.findIndex(item => item.id === id) !== -1;
	};
	const getCbStatusAbilitation = id => {
		return detailsAbilitation.findIndex(item => item.id === id) !== -1;
	};
	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={modalOpen}
				onClose={_ => {
					setModalOpen(false);
				}}
				closeAfterTransition
			>
				<div className="modal-content">
					<UserGroupsSelector
						toggleSelectionVisualization={toggleSelectionVisualization}
						loadingListVisualization={loadingListVisualization}
						getCbStatusVisualization={getCbStatusVisualization}
						toggleSelectionAbilitation={toggleSelectionAbilitation}
						loadingListAbilitation={loadingListAbilitation}
						getCbStatusAbilitation={getCbStatusAbilitation}
						oneColumn={false}
					/>
				</div>
			</Modal>
			<Button
				className={classes.buttonsMargin}
				variant="outlined"
				onClick={_ => {
					setModalOpen(true);
				}}
			>
				{translate('promo.openUserGroups')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(AssignUserGroup);
