import React from 'react';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import SelectMUI from '@mui/material/Select';

const Select = props => {
	const { value, onChange, children } = props;
	const theme = useTheme();

	return (
		<FormControl variant="outlined">
			<SelectMUI
				value={value}
				onChange={onChange}
				sx={{
					height: '40px',
					fontFamily: ['"Poppins", sans-serif'].join(','),
					fontSize: '14px',
					fontWeight: '400',
					backgroundColor: theme.palette.custom.white,
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: theme.palette.custom.greyborder
				}}
			>
				{children}
			</SelectMUI>
		</FormControl>
	);
};

export default Select;
