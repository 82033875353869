import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableHeadMUI from '@mui/material/TableHead';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';
import { withStyles } from '@mui/styles';

const styles = theme => ({
	head: {
		backgroundColor: '#e67272'
	}
});

const TableHead = props => {
	const translate = utils.useI18N();
	return (
		<TableHeadMUI>
			<TableRow>
				<StyledTableCell style={{ minWidth: 50, maxWidth: 50, width: 50 }}>&nbsp;</StyledTableCell>
				<StyledTableCell style={{ minWidth: 140, maxWidth: 140, width: 140 }}>
					{translate('catalog.tableHeaders.ean')}
				</StyledTableCell>
				<StyledTableCell style={{ minWidth: 140, maxWidth: 140, width: 140 }}>
					{translate('catalog.tableHeaders.author')}
				</StyledTableCell>
				<StyledTableCell style={{ minWidth: 20, maxWidth: 20, width: 20 }} />
				<StyledTableCell style={{ minWidth: 240, maxWidth: 240, width: 240 }}>
					{translate('catalog.tableHeaders.title')}
				</StyledTableCell>
				<StyledTableCell style={{ minWidth: 100, maxWidth: 100, width: 100 }}>
					{translate('catalog.tableHeaders.serie')}
				</StyledTableCell>
				<StyledTableCell style={{ minWidth: 90, maxWidth: 90, width: 90 }}>
					{translate('catalog.tableHeaders.publisher')}
				</StyledTableCell>
				<StyledTableCell style={{ minWidth: 70, maxWidth: 70, width: 70 }}>
					{translate('catalog.tableHeaders.price')}
				</StyledTableCell>
				<StyledTableCell style={{ minWidth: 100, maxWidth: 100, width: 100 }}>
					{translate('catalog.tableHeaders.newsItem')}
				</StyledTableCell>
				<StyledTableCell style={{ minWidth: 100, maxWidth: 100, width: 100 }}>
					{translate('catalog.depot')}
				</StyledTableCell>
				<StyledTableCell style={{ minWidth: 130, maxWidth: 130, width: 130 }}>
					{translate('common.quantity')}
				</StyledTableCell>
			</TableRow>
		</TableHeadMUI>
	);
};
export default withStyles(styles, { withTheme: true })(TableHead);
