import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableMUI from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import TableBody from './TableBody';
import TableHead from './TableHead';
import utils from 'components/utils';
import { useTheme } from '@mui/material/styles';

const Table = ({ items, loading, type, order, orderBy, setOrder }) => {
	const theme = useTheme();
	return (
		<Grid item container xs={12}>
			{items.length > 0 && (
				<TableContainer style={{ marginTop: theme.spacing(2), maxHeight: 800 }}>
					<TableMUI aria-label="sticky table">
						<TableHead order={order} orderBy={orderBy} setOrder={setOrder} />
						<TableBody rows={items} type={type} />
					</TableMUI>
				</TableContainer>
			)}
			{items.length === 0 && (
				<Grid item container xs={12} justify="center">
					<Typography variant="body2">Nessuna richiesta disponibile</Typography>
				</Grid>
			)}
		</Grid>
	);
};

export const PendingTable = _ => {
	const items = useStoreState(state => state.returns.pending.list);
	const loading = useStoreState(state => state.returns.pending.loading);
	const order = useStoreState(state => state.returns.pending.orderDirection);
	const orderBy = useStoreState(state => state.returns.pending.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.returns.pending.setOrder);
	const sortedRows = utils.stableSort(items, order, orderBy);
	return (
		<Table
			items={sortedRows}
			loading={loading}
			type="pending"
			order={order}
			orderBy={orderBy}
			setOrder={setOrder}
		/>
	);
};

export const RefusedTable = _ => {
	const items = useStoreState(state => state.returns.refused.list);
	const loading = useStoreState(state => state.returns.refused.loading);
	const order = useStoreState(state => state.returns.refused.orderDirection);
	const orderBy = useStoreState(state => state.returns.refused.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.returns.refused.setOrder);
	const sortedRows = utils.stableSort(items, order, orderBy);
	return (
		<Table
			items={sortedRows}
			loading={loading}
			type="refused"
			order={order}
			orderBy={orderBy}
			setOrder={setOrder}
		/>
	);
};

export const AcceptedTable = _ => {
	const items = useStoreState(state => state.returns.accepted.list);
	const loading = useStoreState(state => state.returns.accepted.loading);
	const order = useStoreState(state => state.returns.accepted.orderDirection);
	const orderBy = useStoreState(state => state.returns.accepted.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.returns.accepted.setOrder);
	const sortedRows = utils.stableSort(items, order, orderBy);
	return (
		<Table
			items={sortedRows}
			loading={loading}
			type="accepted"
			order={order}
			orderBy={orderBy}
			setOrder={setOrder}
		/>
	);
};
