import { action } from 'easy-peasy';

export default {
	list: {},
	save: action((state, payload) => {
		state.list = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	reset: action((state, payload) => {
		state.list = {};
	})
};
