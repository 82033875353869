import React from 'react';
import { useStoreState } from 'easy-peasy';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from './components/Menu';
import HamburgerMenuButton from './components/HamburgerMenuButton';
import ToolbarContent from './components/ToolbarContent';
import styles from './styles';

const Default = ({ classes, children }) => {
	const open = useStoreState(state => state.menu.mainOpen);
	return (
		<div className={classes.root}>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open
				})}
			>
				<Toolbar className={classes.toolbar}>
					<HamburgerMenuButton />
					<ToolbarContent />
				</Toolbar>
			</AppBar>
			<Hidden xsDown>
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open
						})
					}}
				>
					<Menu />
				</Drawer>
			</Hidden>
			<Hidden smUp>
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerCloseHidden]: !open
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: open,
							[classes.drawerCloseHidden]: !open
						})
					}}
				>
					<Menu />
				</Drawer>
			</Hidden>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				{children}
			</main>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(Default);
