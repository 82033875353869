import k from 'k';
import utils from 'services/utils';

export default ({ token, realm }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	if (realm === 'token') realm = 'keyword';
	return utils
		.fetch(`${k.endpoints.apis}/catalog/autocomplete?keyword=${token}&field=${realm}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
