import React, { memo } from 'react';
import moment from 'moment';
import StyledTableCell from 'components/Table/StyledTableCell';

const DateComp = ({ date }) => {
	return <StyledTableCell>{moment(date).format('L')}</StyledTableCell>;
};

const Date = memo(
	({ item }) => <DateComp date={item.date} />,
	(p, n) => p.item.date === n.item.date
);

export default Date;
