import React from 'react';
import utils from 'components/utils';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import styles from './styles';

const Preload = ({ classes, value }) => {
	const translate = utils.useI18N();
	let text = translate('catalog.preload.preloadingHigh');
	if (value < 1000) text = translate('catalog.preload.preloading1000');
	if (value < 500) text = translate('catalog.preload.preloading500');
	if (value < 100) text = translate('catalog.preload.preloading100');
	if (value < 10) text = translate('catalog.preload.preloading10');
	if (!value || value === 0) return '';
	return (
		<Tooltip title={text}>
			<IconButton className={classes.custom} aria-label={text}>
				<SvgIcon viewBox="0 0 800 800">
					<path d="m89 482h-7v-452c0-16.569-13.431-30-30-30s-30 13.431-30 30v452h-7c-8.284 0-15 6.716-15 15s6.716 15 15 15h74c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
					<path d="m327 188.653c22.563-7.385 45.513-13.51 68.655-15.593 2.976-.268 5.922-.457 8.845-.584v-69.904c-25.408 1.287-51.179 8.684-77.5 17.712z" />
					<path d="m219.5 323.965c26.259-4.896 52.191-13.931 77.5-23.024v-70c-25.309 9.093-51.241 18.129-77.5 23.024z" />
					<path d="m112 141.583v65.494c23.063 14.892 46.276 20.554 69.779 20.556 2.57 0 5.144-.075 7.721-.205v-69.899c-2.626.114-5.234.176-7.818.176-24.963-.002-48.019-5.334-69.682-16.122z" />
					<path d="m219.5 223.382c23.719-4.965 47.808-13.635 72.419-22.495 1.69-.608 3.385-1.218 5.081-1.827v-68.119c-25.309 9.093-51.241 18.129-77.5 23.024z" />
					<path d="m327 290.284c26.321-9.028 52.092-16.425 77.5-17.712v-70c-25.408 1.287-51.179 8.684-77.5 17.712z" />
					<path d="m434.5 104.191v69.585c25.552 3.471 49.102 13.093 71.265 29.05l6.235 4.489v-63.033l-23.765-17.111c-17.81-12.821-35.698-19.928-53.735-22.98z" />
					<path d="m112 241.583v70c21.663 10.789 44.719 16.121 69.682 16.121 2.585 0 5.192-.062 7.818-.176v-70c-2.626.114-5.234.176-7.818.176-24.963-.001-48.019-5.333-69.682-16.121z" />
					<path d="m112 37.076v70c23.063 14.892 46.276 20.554 69.779 20.556 2.57 0 5.144-.075 7.721-.205v-70c-26.149 1.322-51.914-3.829-77.5-20.351z" />
					<path d="m434.5 204.191v70c18.037 3.051 35.925 10.158 53.735 22.982 4.567 3.29 10.594 3.745 15.604 1.178 5.01-2.566 8.161-7.722 8.161-13.351v-40.716l-23.765-17.111c-17.81-12.824-35.698-19.931-53.735-22.982z" />
					<path d="m327 18.653v70c22.563-7.385 45.513-13.51 68.655-15.593 2.976-.268 5.922-.457 8.845-.584v-70c-2.923.127-5.869.316-8.845.584-23.142 2.083-46.092 8.208-68.655 15.593z" />
					<path d="m219.5 53.379v70.003c23.719-4.965 47.808-13.635 72.419-22.495 1.69-.608 3.385-1.218 5.081-1.827v-70c-1.696.609-3.391 1.219-5.081 1.827-24.609 8.859-48.7 17.528-72.419 22.492z" />
					<path d="m505.765 32.827c-22.163-15.957-45.713-25.579-71.265-29.05v70.001c25.552 3.471 49.102 13.092 71.265 29.049l6.235 4.489v-62.316c0-4.824-2.32-9.354-6.235-12.173z" />
				</SvgIcon>
			</IconButton>
		</Tooltip>
	);
};

export default withStyles(styles, { withTheme: true })(Preload);
