import React, { Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import styles from './styles';
import { Grid, Paper, Typography, FormControlLabel, Checkbox, Stack, Box } from '@mui/material';
import AcceptSelected from './components/AcceptSelected';
import EditSelected from './components/EditSelected';
import RejectSelected from './components/RejectSelected';
import utils from 'components/utils';

const MultiWishlistHandler = ({ classes }) => {
	const translate = utils.useI18N();
	const selectAll = useStoreActions(dispatch => dispatch.conditionRequest.pendingList.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.conditionRequest.pendingList.deselectAll);
	const someSelected = useStoreState(state => state.conditionRequest.pendingList.someSelected);
	const notAllSelected = useStoreState(state => state.conditionRequest.pendingList.notAllSelected);
	const allSelectable = useStoreState(state => state.conditionRequest.pendingList.allSelectable);
	const onSelectAll = event => {
		event.target.checked ? selectAll() : deselectAll();
	};
	return (
		<Stack
			direction={{ xs: 'column-reverse', md: 'row', sm: 'column-reverse' }}
			justifyContent="flex-start"
			alignItems="center"
			spacing={2}
			sx={{ width: '100%', paddingLeft: { xs: '0', md: '20px', sm: '0' } }}
		>
			{allSelectable && (
				<FormControlLabel
					aria-label="Acknowledge"
					control={
						<Checkbox
							color="primary"
							onChange={onSelectAll}
							checked={someSelected}
							indeterminate={notAllSelected && someSelected}
						/>
					}
					label={
						<Typography variant="text" sx={{ fontWeight: '600', fontSize: '14px !important' }}>
							{translate('wishlist.selectAll')}
						</Typography>
					}
				/>
			)}
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'row' }}
				justifyContent="flex-end"
				alignItems="center"
				spacing={2}
				sx={{
					width: { xs: '100%', md: allSelectable ? '80%' : '100%', sm: '100%' },
					padding: { xs: '0', md: '0 20px', sm: '0' }
				}}
			>
				<AcceptSelected />
				<EditSelected />
				<RejectSelected />
			</Stack>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(MultiWishlistHandler);
