import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import utils from 'components/utils';
import { DateTimePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const makeDatePickerStyles = makeStyles({
	root: {
		width: 180
	}
});

const DatePickerFrom = props => {
	const translate = utils.useI18N();
	const saveDateFrom = useStoreActions(dispatch => dispatch.coupons.add.saveDateFrom);
	const pubDateFrom = useStoreState(state => state.coupons.add.dateFrom);
	const datePickerStyles = makeDatePickerStyles();
	return (
		<DateTimePicker
			autoOk
			value={pubDateFrom}
			onChange={saveDateFrom}
			format="L HH:mm"
			disableToolbar
			label={translate('catalog.filters.pubDateFrom') + ':'}
			ampm={false}
			disablePast
		/>
	);
};

const DatePickerTo = props => {
	const translate = utils.useI18N();
	const saveDateTo = useStoreActions(dispatch => dispatch.coupons.add.saveDateTo);
	const pubDateTo = useStoreState(state => state.coupons.add.dateTo);
	const pubDateFrom = useStoreState(state => state.coupons.add.dateFrom);
	const datePickerStyles = makeDatePickerStyles();
	const minDate = pubDateFrom ? pubDateFrom : '';

	return (
		<DateTimePicker
			sx={{ width: '240px' }}
			autoOk
			value={pubDateTo}
			onChange={saveDateTo}
			format="L HH:mm"
			disableToolbar
			label={translate('catalog.filters.pubDateTo') + ':'}
			ampm={false}
			disablePast
			minDate={minDate}
		/>
	);
};

export { DatePickerFrom, DatePickerTo };
