import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Stack, SwipeableDrawer, Box, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Sidebar from './Sidebar';
import Header from './Header';
import { grey } from '@mui/material/colors';
import Footer from 'components/Footer';
import utils from 'components/utils';
import globalUtils from 'utils';

const Layout7 = ({ children }) => {
	const theme = useTheme();
	const history = useHistory();
	const [iAmPersonifying] = utils.usePersonification();
	const abilitations = useStoreState(state => state.me.abilitations);
	const loadAbilitation = useStoreActions(dispatch => dispatch.me.loadAbilitation);
	const currentAbilitations = useStoreState(state => state.me.currentAbilitations);
	const getAbilitation = useStoreActions(dispatch => dispatch.me.getAbilitation);
	const user = useStoreState(state => state.me.data);
	const domain = process.env.REACT_APP_DOMAIN;
	const betaDomain = process.env.REACT_APP_BETA_DOMAIN;
	const logoutPath = '/logout';
	const distanceLeftSideToChildren = 80;
	const sideBarWidth = 73;
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const [openNavMenu, setOpenNavMenu] = useState(false);

	const handleOpenNavMenu = event => {
		setOpenNavMenu(true);
	};

	const handleCloseNavMenu = () => {
		setOpenNavMenu(false);
	};

	useEffect(() => {
		if (!abilitations) {
			loadAbilitation();
		}
		//		else getAbilitation();
	}, []);

	useEffect(() => {
		//Nel caso in cui sono state caricate le abilitazioni ma non ci sono le abilitazioni attuali le recupero
		if (abilitations && !currentAbilitations) {
			getAbilitation();
		}
		//Nel caso in cui ci sono sia le abilitazioni in cache sia quelle correnti faccio le verifiche
		if (abilitations && currentAbilitations && user.id === currentAbilitations.userCode) {
			const currentHost = window.location.protocol + '//' + window.location.hostname;
			//Verifica dominio beta (per tutti gli utenti)
			if (currentAbilitations.beta && currentHost !== betaDomain) {
				window.localStorage.clear();
				window.location.href = betaDomain + logoutPath;
				//window.location.href = betaDomain + history.location.pathname;
			}
			//Verifica dominio non beta (per tutti gli utenti)
			else if (!currentAbilitations.beta && currentHost === betaDomain) {
				window.localStorage.clear();
				window.location.href = domain + logoutPath;
				//window.location.href = domain + history.location.pathname;
			}
			//Verifica versione (per tutti gli utenti) e flag abilitato (solo se non si sta impersonificando)
			else if (
				(currentAbilitations.beta && currentAbilitations.betaVersion !== abilitations.betaVersion) ||
				(!currentAbilitations.beta && currentAbilitations.version !== abilitations.version) ||
				(!iAmPersonifying && currentAbilitations.abilitato !== abilitations.abilitato)
			)
				globalUtils.logout();
		}
	}, [currentAbilitations, abilitations]);

	return (
		/**  ------------- TOP LEVEL LAYOUT ------------- */

		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '100%' }}>
			{/** ------------- HEADER LAYOUT ------------- */}
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={1}
				sx={{
					padding: `5px ${isMobile ? '20px' : '40px'}`,
					width: '100%',
					backgroundColor: theme.palette.custom.white,
					position: 'sticky',
					top: '0',
					zIndex: '1'
					/*boxShadow: '1px 5px 7px #505050'*/
				}}
			>
				{/** ------------- SIDEBAR MOBILE LAYOUT ------------- */}
				{isMobile && (
					<IconButton
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleOpenNavMenu}
						sx={{
							color: theme.palette.primary.main
						}}
					>
						<MenuIcon />
					</IconButton>
				)}
				<Header />
				{isMobile && (
					<SwipeableDrawer
						id="mobile-menu-appbar"
						anchor="left"
						variant="temporary"
						open={openNavMenu}
						onClose={handleCloseNavMenu}
						onOpen={handleOpenNavMenu}
					>
						<Box
							sx={{
								width: '150px',
								// backgroundColor: theme.palette.custom.menu,
								height: '100vh'
							}}
						>
							<Sidebar />
						</Box>
					</SwipeableDrawer>
				)}
			</Stack>

			{/** ------------- SIDEBAR DESKTOP LAYOUT ------------- */}
			<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '100%' }}>
				<Box
					sx={{
						width: `${sideBarWidth}px`,
						backgroundColor: theme.palette.custom.white,
						display: { xs: 'none', md: 'inline-block', sm: 'none' },
						/*height: '100vh',
						minHeight: '100%',*/
						position: 'fixed',
						top: '1',
						left: '0'
					}}
				>
					<Sidebar sideBarWidth={sideBarWidth} distanceLeftSideToChildren={distanceLeftSideToChildren} />
				</Box>
				<Stack
					direction={'column'}
					justifyContent="space-between"
					sx={{
						width: '100%',
						marginLeft: { md: `${distanceLeftSideToChildren}px` },
						minHeight: '100vh',
						backgroundColor: grey[100],
						borderRadius: { xs: '0', md: '15px', sm: '15px' },
						padding: { xs: '24px 4px', md: theme.spacing(3), sm: theme.spacing(3) }
					}}
				>
					{children}
					<Footer />
				</Stack>
			</Stack>
		</Stack>
	);
};

export default Layout7;
