import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Box, Paper, LinearProgress, Typography } from '@mui/material';
import HeaderPage from './HeaderPage';
import FilterBar from './FilterBar';
import List from './List';
import utils from 'components/utils';

const Home = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const useType = utils.useType();
	const user = useStoreState(state => state.me.data);
	const [iAmPersonifying, target] = utils.usePersonification();
	const userId = iAmPersonifying ? target.id : user.id;
	const token = useStoreState(state => state.proposals.filters.list.token);
	const setToken = useStoreActions(dispatch => dispatch.proposals.filters.setToken);
	const profiles = useStoreState(state => state.proposals.filters.list.profiles);
	const loading = useStoreState(state => state.proposals.list.loading);
	const load = useStoreActions(dispatch => dispatch.proposals.list.load);
	const items = useStoreState(state => state.proposals.list.items);
	const viewRows = useStoreState(state => state.proposals.list.viewRows);
	const setViewRows = useStoreActions(dispatch => dispatch.proposals.list.setViewRows);
	const filteredRows = items.filter(
		item =>
			item.userCode == userId ||
			(useType === 'AGENT'
				? item.userName === 'WAREHOUSE_MANAGER'
				: useType === 'WAREHOUSE_MANAGER' || useType === 'BO'
				? item.userName !== 'CLIENT'
				: //useType !== 'CLIENT' ? item.userName !== 'CLIENT'
				  item.userGroups.filter(i => i.id === 1 || (i.utente && i.utente.includes('.' + userId + '.')))
						.length > 0)
	);

	const search = () => {
		const searchList = token
			.trim()
			.toLowerCase()
			.split(' ');
		return filteredRows.filter(item => {
			let result = false;
			for (let word of searchList) {
				if (
					item.id.toString().includes(word) ||
					item.name.toLowerCase().includes(word) ||
					item.description.toLowerCase().includes(word)
				) {
					result = true;
				}
			}
			return result;
		});
	};

	const findProfile = item => {
		return;
		(profiles.includes('PERSONAL') && item.userCode == userId) ||
			(profiles.includes('SEDE') && (item.userName == 'WAREHOUSE_MANAGER' || item.userName == 'BO')) ||
			profiles.includes('AGENT' && item.userName == 'AGENT');
	};

	const filterProfile = rows => {
		return rows.filter(
			item =>
				(profiles.includes('PERSONAL') && item.userCode == userId) ||
				(profiles.includes('SEDE') &&
					((!profiles.includes('PERSONAL') &&
						item.userCode != userId &&
						(item.userName == 'WAREHOUSE_MANAGER' || item.userName == 'BO')) ||
						(profiles.includes('PERSONAL') &&
							(item.userName == 'WAREHOUSE_MANAGER' || item.userName == 'BO')))) ||
				(profiles.includes('AGENT') &&
					((!profiles.includes('PERSONAL') && item.userCode != userId && item.userName == 'AGENT') ||
						(profiles.includes('PERSONAL') && item.userName == 'AGENT')))
		);
	};

	const handleRows = () => {
		if (filteredRows.length == 0) return;
		if (!token.trim()) setViewRows(filterProfile(filteredRows));
		else setViewRows(filterProfile(search()));
	};

	useEffect(() => {
		setToken('');
		load(userId);
	}, []);

	/*
	useEffect(() => {
		if(items.length > 0 && viewRows.length == 0){
console.log("non ha caricato i viewRows");
			setViewRows(items);
		}
	},[items]);
	*/

	useEffect(() => {
		handleRows();
	}, [token, items, profiles]);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				<HeaderPage />
				<FilterBar />
				{loading && (
					<Box sx={{ width: '100%' }}>
						<LinearProgress sx={{ color: theme.palette.primary.main }} />
					</Box>
				)}
			</Paper>
			{!loading && filteredRows.length == 0 && (
				<Box sx={{ width: '100%', textAlign: 'center', marginTop: '20px !important' }}>
					<Typography variant="titleSection">{translate('proposals.noProposals')}</Typography>
				</Box>
			)}
			{!loading && filteredRows.length > 0 && <List userId={userId} />}
		</Stack>
	);
};

export default Home;
