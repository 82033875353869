import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Stack, Box, Pagination as PaginationMUI } from '@mui/material';
import utils from 'components/utils';
import StepSelector from './components/StepSelector';

const Pagination = ({ currentPage, step, pageCount, setCurrentPage, setStep, totalItems, listStep }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const firstElementIndex = (currentPage - 1) * step + 1;
	let lastElementIndex = firstElementIndex + step - 1;
	if (totalItems < lastElementIndex) {
		lastElementIndex = totalItems;
	}
	const summary = translate('common.pagination')
		.replace('$start', firstElementIndex)
		.replace('$end', lastElementIndex)
		.replace('$total', totalItems);
	const helperTextJustify = useMediaQuery(theme.breakpoints.up('md')) ? 'flex-end' : 'center';
	const onPaginationChange = (event, value) => {
		setCurrentPage(value);
	};
	const showPageSelector = pageCount > 1;
	return (
		<Stack
			direction={{ xs: 'column', md: 'row', sm: 'column' }}
			justifyContent={{ xs: 'center', md: 'space-between', sm: 'center' }}
			alignItems="center"
			spacing={1}
			sx={{ width: '100%' }}
		>
			<StepSelector currentStep={step} setStep={setStep} listStep={listStep} />
			<Box sx={{ display: 'flex', justifyContent: 'center', width: { xs: '100%', md: '60%', sm: '100%' } }}>
				{showPageSelector && (
					<PaginationMUI
						count={pageCount}
						boundaryCount={1}
						page={currentPage}
						onChange={onPaginationChange}
					/>
				)}
			</Box>
			<Box sx={{ textAlign: { xs: 'center', md: 'right', sm: 'center' } }}>
				<Typography variant="text" sx={{ fontSize: '13px !important' }}>
					{summary}
				</Typography>
			</Box>
		</Stack>
	);
};

export default Pagination;
