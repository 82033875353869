import React, { useEffect, Fragment, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from 'components/Select';

const CartSelector = ({ className, disable }) => {
	const loading = useStoreState(state => state.cart.listLight.loading);
	const list = useStoreState(state => state.cart.listLight.items);
	const listIsLoaded = useStoreState(state => state.cart.listLight.loadedList);
	const load = useStoreActions(dispatch => dispatch.cart.listLight.load);
	const select = useStoreActions(dispatch => dispatch.cart.listLight.select);
	const personify = useStoreState(state => state.me.personify);
	const userData = useStoreState(state => state.me.data);
	const userCode = personify.iAmPersonifying ? personify.target.id : userData.id;
	const [selectedCart, setSelectedCart] = useState('');
	/*
	useEffect(
		_ => {
			if (
				(list.length === 0 && !listIsLoaded) //||
				//(list && list.filter(e => e.userCode === userCode).length === 0)
			) {
				load();
			}
		},
		[listIsLoaded]
	);
	*/

	/** Modificato per evitare loop di chiamate del caricamento carrelli
	 * e ricaricare correttamente i carrelli quando si cambia utente */
	useEffect(_ => {
		if (!listIsLoaded) {
			load();
		}
	}, []);

	useEffect(
		_ => {
			if (list.length > 0) {
				setSelectedCart(list.find(carts => carts.selected).id);
			}
		},
		[list]
	);
	return (
		<Fragment>
			{!loading && list.length > 0 && (
				<FormControl variant="outlined" className={className} sx={{ width: '80%' }}>
					<Select value={selectedCart} onChange={event => select(event.target.value)}>
						{list.map((item, index) => (
							<MenuItem key={item.id} value={item.id} disabled={disable}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			{!loading && list.length === 0 && <p>no carts</p>}
		</Fragment>
	);
};

export default CartSelector;
