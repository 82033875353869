import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Tooltip } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import utils from 'components/utils';

const Availability = props => {
	const { classes } = props;
	const theme = useTheme();
	const details = useStoreState(state => state.catalog.details.data);
	const translate = utils.useI18N();
	const availabilityMap = {
		POOR: theme.palette.secondary.main,
		FAIR: theme.palette.custom.yellow,
		GOOD: theme.palette.custom.greenlight,
		EXCELLENT: theme.palette.custom.green
	};
	const text =
		details && details.type !== 200 && details.availability
			? translate(`catalog.availability.${details.availability.toLowerCase()}`)
			: '';
	const tooltipText =
		details && details.type !== 200 && details.availability
			? translate(`catalog.availabilityDesc.${details.availability.toLowerCase()}`)
			: '';
	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0.5}
			sx={{ margin: '5px 0' }}
		>
			<Typography variant="text" sx={{ fontWeight: '600' }}>
				{translate('catalog.details.infoBox.availability') + ':'}
			</Typography>
			<Typography variant="text">{text}</Typography>
			{details.type !== 200 && (
				<Tooltip title={tooltipText}>
					<CheckCircleRoundedIcon
						fontSize="small"
						sx={{
							color: Object.keys(availabilityMap).includes(details.availability)
								? availabilityMap[details.availability]
								: 'transparent'
						}}
					/>
				</Tooltip>
			)}
		</Stack>
	);
};

export default Availability;
