import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	referents: [],
	setData: action((state, payload) => {
		state.referents = payload;
	}),
	getReferents: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.purchasings
			.getReferent(payload)
			.then(data => actions.setData(data.items))
			.catch(e => {
				getStoreActions().error('purchasings.getItems');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
