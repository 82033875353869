import { action, thunk } from 'easy-peasy';

export default {
	loading: {},
	setLoading: action((state, payload) => {
		state.loading[payload] = true;
	}),
	unsetLoading: action((state, payload) => {
		state.loading[payload] = false;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(payload);
		return services.budget
			.deletee(payload)
			.then(data => {
				getStoreActions().success('budget.delete');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('budget.delete');
			})
			.finally(_ => {
				actions.unsetLoading(payload);
			});
	})
};
