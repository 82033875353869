import k from 'k';
import utils from 'services/utils';

export default _ => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders(),
		responseType: 'blob'
	};
	return utils
		.fetch(`${k.endpoints.apis}/terms-conditions`, requestOptions)
		.then(response => response.blob())
		.then(response => {
			const file = new Blob([response], { type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		});
};
