import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const CodeNumberComp = ({ number, type }) => {
	return <StyledTableCell>{number}</StyledTableCell>;
};

const CodeNumber = memo(
	({ item, type }) => <CodeNumberComp number={item.number} type={type} />,
	(p, n) => p.item.number === n.item.number && p.type === n.type
);

export default CodeNumber;
