import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Stack,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel,
	Checkbox,
	Typography,
	Link
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import moment from 'moment';
import utils from 'components/utils';

const List = props => {
	const { userId } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const orderBy = useStoreState(state => state.proposals.list.orderBy);
	const orderDirection = useStoreState(state => state.proposals.list.orderDirection);
	const setOrder = useStoreActions(dispatch => dispatch.proposals.list.setOrder);
	const selectAll = useStoreActions(dispatch => dispatch.proposals.list.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.proposals.list.deselectAll);
	const toggleSelection = useStoreActions(dispatch => dispatch.proposals.list.toggleSelection);
	const token = useStoreState(state => state.proposals.filters.list.token);
	const items = useStoreState(state => state.proposals.list.viewRows);
	const itemSelected = items.filter(i => i.isSelected);
	const sortedRows = utils
		.stableSort(items, orderDirection, orderBy)
		.filter(item => (item.userCode !== userId && item.enabled) || item.userCode === userId);

	const headCells = [
		{ id: 'id', label: 'proposals.id', width: '80px', minWidth: 'auto' },
		{ id: 'createdByLabel', label: 'proposals.createdBy', width: '130px', minWidth: 'auto' },
		{ id: 'name', label: 'proposals.name', width: 'auto', minWidth: '150px' },
		{ id: 'description', label: 'proposals.description', width: 'auto', minWidth: '150px' }
		/*
		{ id: 'dateFrom', label: 'proposals.dateFrom' },
		{ id: 'dateTo', label: 'proposals.dateTo' },
		{ id: 'enabled', label: 'proposals.enabled' }
		*/
	];

	const onSelectAll = event => {
		if (event.target.checked) selectAll({ token, userId });
		else deselectAll({ token, userId });
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0.5}
			variant="wrapperSection"
			sx={{ padding: '20px 0' }}
		>
			<TableContainer sx={{ width: '100%' }}>
				<Table aria-label="proposals items table">
					<TableHead>
						<TableRow>
							<TableCell
								sx={{ background: theme.palette.custom.greytable, fontWeight: '600', width: '40px' }}
							>
								<Checkbox
									indeterminate={itemSelected.length > 0 && itemSelected.length < items.length}
									checked={itemSelected.length > 0 && items.length === itemSelected.length}
									onChange={onSelectAll}
									inputProps={{ 'aria-label': 'select all items' }}
								/>
							</TableCell>

							{headCells.map(headCell => (
								<TableCell
									key={headCell.id}
									align="left"
									sortDirection={orderBy === headCell.id ? orderDirection : false}
									style={headCell.sx}
									sx={{
										background: theme.palette.custom.greytable,
										fontWeight: '600',
										width: headCell.width,
										minWidth: headCell.minWidth
									}}
								>
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id ? orderDirection : 'asc'}
										//direction={orderDirection}
										onClick={e => setOrder(headCell.id)}
										sx={{ lineHeight: '18px' }}
									>
										{headCell.label && translate(headCell.label)}
									</TableSortLabel>
								</TableCell>
							))}
							<TableCell sx={{ background: theme.palette.custom.greytable, padding: '0 0 0 4px' }}>
								&nbsp;
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedRows.map((row, index) => (
							<TableRow key={`row_${index}`}>
								<TableCell align="left">
									{row.userCode === userId && (
										<Checkbox
											checked={row.isSelected}
											onChange={e => toggleSelection(row.id)}
											inputProps={{ 'aria-labelledby': row.id }}
										/>
									)}
								</TableCell>

								<TableCell>
									<Typography variant="textTable">{row.id}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="textTable" sx={{ textTransform: 'uppercase' }}>
										{/*row.userCode === userId
											? translate('proposals.personal')
											: translate('proposals.' + row.createdByLabel)
										*/}
										{row.userName === 'AGENT' && row.userCode !== userId
											? translate('proposals.' + row.createdByLabel) + ' (' + row.userCode + ')'
											: translate('proposals.' + row.createdByLabel)}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="textTable">{row.name}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="textTable">{row.description}</Typography>
								</TableCell>
								{/* 
								<TableCell>
									<Typography variant="textTable">{moment(row.startDate).format('L')}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="textTable">{moment(row.endDate).format('L')}</Typography>
								</TableCell>
								<TableCell>
									{row.enabled ? (
										<CheckCircleOutlineIcon sx={{ color: theme.palette.custom.green }} />
									) : (
										<HighlightOffIcon sx={{ color: theme.palette.custom.red }} />
									)}
								</TableCell>
								*/}
								<TableCell>
									<Link href={`/proposals/detail/${row.id}`}>
										<ArrowCircleRightIcon sx={{ fontSize: '30px' }} />
									</Link>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Stack>
	);
};

export default List;
