import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	loadingPdf: false,
	setLoadingPdf: action((state, payload) => {
		state.loadingPdf = payload;
	}),
	loadingBook2c: false,
	setLoadingBook2c: action((state, payload) => {
		state.loadingBook2c = payload;
	}),
	openModalPdf: false,
	setOpenModalPdf: action((state, payload) => {
		state.openModalPdf = payload;
	}),
	data: {},
	save: action((state, payload) => {
		state.data = payload;
	}),
	setName: action((state, payload) => {
		state.data.name = payload;
	}),
	setDescription: action((state, payload) => {
		state.data.description = payload;
	}),
	setEnabled: action((state, payload) => {
		state.data.enabled = payload;
	}),
	setStartDate: action((state, payload) => {
		state.data.startDate = payload;
	}),
	setEndDate: action((state, payload) => {
		state.data.endDate = payload;
	}),
	book2cInfo: null,
	setBook2cInfo: action((state, payload) => {
		state.book2cInfo = payload;
	}),
	setBook2cEmpty: action((state, payload) => {
		state.book2cInfo = { codice: payload, dominio: '', dominioAbilitato: false };
	}),
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.proposals
			.getDetails(payload)
			.then(data => {
				actions.save(data.item);
				getStoreActions().proposals.detailUserGroup.setData(data.item.userGroups);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('proposals.errorGetDetails');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	update: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.proposals
			.update(payload)
			.then(data => {
				getStoreActions().success('proposals.update');
			})
			.catch(e => {
				getStoreActions().error('proposals.errorUpdate');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	createPdf: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingPdf(true);
		services.proposals
			.exportPdf(payload)
			.then(data => {
				actions.setOpenModalPdf(false);
			})
			.catch(e => {
				getStoreActions().error('proposals.errorExportPdf');
			})
			.finally(_ => actions.setLoadingPdf(false));
	}),
	getBook2cInfo: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingBook2c(true);
		return services.proposals
			.getUserBook2cInfo(payload)
			.then(data => {
				actions.setBook2cInfo(data);
			})
			.catch(e => {
				actions.setBook2cEmpty(payload);
				//getStoreActions().error('proposals.errroGetBook2cInfo');
			})
			.finally(_ => actions.setLoadingBook2c(false));
	})
};
