import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Card } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		}
	}
}))(TableRow);

const Location = ({ ean, depots }) => {
	const loading = useStoreState(state => state.catalog.location.loading);
	const items = useStoreState(state => state.catalog.location.items);
	const load = useStoreActions(dispatch => dispatch.catalog.location.load);
	const reset = useStoreActions(dispatch => dispatch.catalog.location.reset);
	const [expanded, setExpanded] = useState(false);
	const depotCodes = depots && depots.map(item => item.id);
	const onAccordionChange = _ => {
		if (expanded) reset();
		else {
			load({ ean, depotCodes });
		}
		setExpanded(!expanded);
	};
	return (
		<Card sx={{ marginTop: '16px', width: '100%' }}>
			<Accordion
				TransitionProps={{ unmountOnExit: true }}
				expanded={expanded}
				onChange={_ => onAccordionChange()}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						Ubicazione
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container item xs={12} justify="center">
						{expanded && loading && <CircularProgress size={24} />}
						{expanded && !loading && items.length === 0 && (
							<Typography variant="text">Informazione non disponibile</Typography>
						)}
						{expanded && !loading && items.length > 0 && (
							<TableContainer component={Paper} style={{ maxWidth: 800 }}>
								<Table aria-label="location" size="small">
									<TableHead>
										<TableRow>
											<TableCell>Posizione</TableCell>
											<TableCell>Giacenza</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{items.map((item, index) => (
											<StyledTableRow key={index}>
												<TableCell>{item.location}</TableCell>
												<TableCell>{item.quantity}</TableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</Card>
	);
};

export default Location;
