import { action, computed, thunk } from 'easy-peasy';
import utils from 'store/admin/utils';
import storeUtils from 'store/utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	setPerformed: action((state, payload) => {
		state.performed = payload;
	}),
	resetSearch: action((state, payload) => {
		state.items = [];
		state.token = '';
		state.performed = false;
	}),
	items: [],
	token: '',
	performed: false,
	modalOpen: false,
	updatingItems: {
		usersToInsert: null,
		usersToDelete: null
	},
	load: thunk((actions, payload, { injections, getStoreActions, getStoreState, getState }) => {
		const { services } = injections;
		const page = getState().pagination.current - 1;
		const step = getState().pagination.step;
		const id = getStoreState().purchasings.details.data.id;
		const keyword = getState().token;
		const params = {
			page,
			step,
			keyword
		};
		actions.setLoading(true);
		services.purchasings
			.getUsers(id, params)
			.then(data => {
				actions.save(data);
				actions.presetItems();
				actions.setPerformed(true);
			})
			.catch(e => {
				getStoreActions().error('purchasings.errorGetUsers');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	...storeUtils.createPagination({ step: 25, paginationTrigger: 'save', loadFn: 'load' }),
	search: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		const id = getStoreState().purchasings.details.data.id;
		const keyword = getStoreState().purchasings.assignUsers.token;
		const params = {
			keyword
		};
		actions.setLoading(true);
		services.purchasings
			.getCaUsers(id, params)
			.then(data => {
				getStoreActions().purchasings.detailsItems.setData(data.items);
				actions.save(data.items);
			})
			.catch(e => {
				getStoreActions().error('purchasings.errorGetUsers');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	reset: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		const id = getStoreState().purchasings.details.data.id;
		const params = {
			keyword: ''
		};
		actions.setLoading(true);
		services.purchasings
			.getCaUsers(id, params)
			.then(data => {
				getStoreActions().purchasings.detailsItems.setData(data.items);
				actions.save(data.items);
			})
			.catch(e => {
				getStoreActions().error('purchasings.errorGetUsers');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	presetItems: action((state, payload) => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: state.detailsItems.usersCentralPurchasing.findIndex(i => i.clientCode === item.userCode) !== -1,
			wasSelected:
				state.detailsItems.usersCentralPurchasing.findIndex(i => i.clientCode === item.userCode) !== -1,
			touched: false
		}));
	}),
	save: action((state, payload) => {
		state.items = payload.items;
	}),
	setToken: action((state, payload) => {
		state.token = payload;
	}),
	setModalOpen: action((state, payload) => {
		state.modalOpen = payload;
	}),
	orderBy: 'cod',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	toggleSelection: action((state, payload) => {
		const i = state.items.findIndex(itm => itm.userCode === payload);
		if (i === -1) return;
		const item = state.items[i];
		item.isSelected = !item.isSelected;
		item.touched = item.isSelected !== item.wasSelected;
		state.items = state.items
			.slice(0, i)
			.concat(item)
			.concat(state.items.slice(i + 1));
	}),
	selectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: true,
			touched: !item.wasSelected
		}));
	}),
	deselectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			isSelected: false,
			touched: item.wasSelected
		}));
	}),
	update: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		actions.setLoading(true);
		const {
			purchasings: {
				assignUsers: { items, updatingItems }
			}
		} = getStoreState();
		utils.assignToCa(items, updatingItems);
		return services.purchasings
			.updateAssignment(updatingItems, getStoreState().purchasings.details.data.id)
			.then(data => {
				getStoreActions().success('purchasings.associated');
				return Promise.resolve(data);
			})
			.catch(_ => {
				getStoreActions().error('purchasings.errorUpdate');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	remove: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		actions.setLoading(true);
		const obj = {
			usersToInsert: '',
			usersToDelete: payload
		};
		return services.purchasings
			.updateAssignment(obj, getStoreState().purchasings.details.data.id)
			.then(data => {
				getStoreActions().purchasings.detailsItems.load(getStoreState().purchasings.details.data.id);
				getStoreActions().purchasings.detailsItems.setData(data.items);
				getStoreActions().success('purchasings.associated');
			})
			.catch(_ => {
				getStoreActions().error('purchasings.errorUpdate');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	clearAll: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.purchasings
			.updateAssignment(payload, getStoreState().purchasings.details.data.id)
			.then(_ => {
				getStoreActions().purchasings.detailsItems.setData([]);
				getStoreActions().success('purchasings.articlesDeleted');
			})
			.catch(_ => {
				getStoreActions().error('purchasings.errorUpdate');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	detailsItems: computed([(state, storeState) => storeState.purchasings.details.data], s => s)
};
