import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const AmountComp = ({ text, isAdded }) => {
	return <StyledTableCell>{isAdded ? '' : utils.formatPrice(text)}</StyledTableCell>;
};

const Amount = memo(
	({ item }) => <AmountComp text={item.grossprui} isAdded={item.isAdded} />,
	(p, n) => p.item.grossprui === n.item.grossprui && p.item.isAdded === n.item.isAdded
);

export default Amount;
