import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Box } from '@mui/material';
import utils from 'components/utils';
import ShippedOrdersInfoDetail from './ShippedOrdersInfoDetail';
import ShippedOrderShippings from './ShippedOrderShippings';

const ShippedOrdersAccordionDetails = props => {
	const { order } = props;

	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
			<Box sx={{ display: { xs: 'inherit', md: 'none', sm: 'inherit' } }}>
				<ShippedOrdersInfoDetail order={order} />
			</Box>
			<ShippedOrderShippings order={order} />
		</Stack>
	);
};

export default ShippedOrdersAccordionDetails;
