const assignToGroup = (results, updatingItems) => {
	const selected = results.filter(item => item.touched && item.isSelected);
	const unSelected = results.filter(item => item.touched && !item.isSelected);
	updatingItems.eansToInsert = [];
	updatingItems.eansToDelete = [];
	if (selected.length > 0) {
		selected.forEach(item => {
			if (!item.wasSelected) {
				item.wasSelected = true;
				updatingItems.eansToInsert.push(item.ean);
			}
		});
	}
	if (unSelected.length > 0) {
		unSelected.forEach(item => {
			item.wasSelected = true;
			updatingItems.eansToDelete.push(item.ean);
		});
	}
	updatingItems.eansToInsert = updatingItems.eansToInsert.join(',');
	updatingItems.eansToDelete = updatingItems.eansToDelete.join(',');
};

export default assignToGroup;
