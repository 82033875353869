import React, { Fragment, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import utils from 'components/utils';
import Link from '@mui/material/Link';

const InfoThemaLink = ({ themaLink, type, sep }) => {
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const resetFilters = useStoreActions(dispatch => dispatch.catalog.filters.reset);
	const search = useStoreActions(dispatch => dispatch.catalog.search.performSearch);
	const setThemaType = useStoreActions(dispatch => dispatch.catalog.thema.setThemaType);
	const resetItemsSelected = useStoreActions(dispatch => dispatch.catalog.thema.resetItemsSelected);
	const addItemSelectedFromLink = useStoreActions(dispatch => dispatch.catalog.thema.addItemSelectedFromLink);
	const updateItemsSelected = useStoreActions(dispatch => dispatch.catalog.thema.updateItemsSelected);
	const setModalOpen = useStoreActions(dispatch => dispatch.catalog.details.setModalOpen);
	const history = useHistory();

	const goTosearch = (code, label) => {
		resetFilters();
		setThemaType(type);
		resetItemsSelected();
		addItemSelectedFromLink(code);
		updateItemsSelected();
		setFilters({
			themaType: type,
			themaCodes: [code],
			themaRanking: true
		});
		search();
		setModalOpen(false);
		history.push(`/catalog/search/`);
	};

	const textLink = sep ? '\u00A0|\u00A0' + themaLink['label'] : themaLink['label'];
	return (
		<Link
			href="#"
			onClick={_ => {
				goTosearch(themaLink['code'], themaLink['label']);
			}}
		>
			{textLink}
		</Link>
	);
};

const InfoThema = ({ classes, ean }) => {
	const translate = utils.useI18N();
	const themaEan = useStoreState(state => state.catalog.thema.themaEan);
	const loadEan = useStoreActions(dispatch => dispatch.catalog.thema.loadEan);
	const [showFullClass, setShowFullClass] = useState(false);
	const [showFullQual, setShowFullQual] = useState(false);
	const showHideClassText = showFullClass
		? translate('catalog.details.themaHideClassQual')
		: translate('catalog.details.themaShowClassQual');
	const showHideQualText = showFullQual
		? translate('catalog.details.themaHideClassQual')
		: translate('catalog.details.themaShowClassQual');
	useEffect(
		_ => {
			//Richiamo il servizio di recupero dati thema se l'ean corrente cambia
			if (ean && ean !== themaEan.ean) {
				loadEan(ean);
			}
		},
		[ean]
	);

	const toggleClass = event => {
		event.preventDefault();
		setShowFullClass(!showFullClass);
	};

	const toggleQual = event => {
		event.preventDefault();
		setShowFullQual(!showFullQual);
	};

	const showClass1 = themaEan && themaEan.class1;
	const showClass2 = themaEan && themaEan.class2 && showFullClass;
	const showClass3 = themaEan && themaEan.class3 && showFullClass;
	const showClass4 = themaEan && themaEan.class4 && showFullClass;
	const showQual1 = themaEan && themaEan.qual1;
	const showQual2 = themaEan && themaEan.qual2 && showFullQual;
	const showQual3 = themaEan && themaEan.qual3 && showFullQual;
	const showQual4 = themaEan && themaEan.qual4 && showFullQual;
	const showLinkClass = themaEan && (themaEan.class2 || themaEan.class3 || themaEan.class4);
	const showLinkQUal = themaEan && (themaEan.qual2 || themaEan.qual3 || themaEan.qual4);

	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
			<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
				<Typography variant="text" sx={{ fontWeight: '600' }}>
					{translate('catalog.details.themaClass') + ':'}
				</Typography>
				<Typography variant="text">
					{showClass1 && <InfoThemaLink classes={classes} themaLink={themaEan.class1} type="C" />}
					{showClass2 && <InfoThemaLink classes={classes} themaLink={themaEan.class2} type="C" sep={true} />}
					{showClass3 && <InfoThemaLink classes={classes} themaLink={themaEan.class3} type="C" sep={true} />}
					{showClass4 && <InfoThemaLink classes={classes} themaLink={themaEan.class4} type="C" sep={true} />}
					{showLinkClass && (
						<Link href="#" onClick={toggleClass}>
							{' (' + showHideClassText + ')'}
						</Link>
					)}
				</Typography>
			</Stack>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0.5}
				sx={{ margin: '5px 0' }}
			>
				<Typography variant="text" sx={{ fontWeight: '600' }}>
					{translate('catalog.details.themaQual') + ':'}
				</Typography>
				<Typography variant="text">
					{showQual1 && <InfoThemaLink classes={classes} themaLink={themaEan.qual1} type="Q" />}
					{showQual2 && <InfoThemaLink classes={classes} themaLink={themaEan.qual2} type="Q" sep={true} />}
					{showQual3 && <InfoThemaLink classes={classes} themaLink={themaEan.qual3} type="Q" sep={true} />}
					{showQual4 && <InfoThemaLink classes={classes} themaLink={themaEan.qual4} type="Q" sep={true} />}
					{showLinkQUal && (
						<Link href="#" onClick={toggleQual}>
							{' (' + showHideQualText + ')'}
						</Link>
					)}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default InfoThema;
