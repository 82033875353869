import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Tooltip, Box, CircularProgress, IconButton } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StarIcon from '@mui/icons-material/Star';
//import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import utils from 'components/utils';
import moment from 'moment';
import CartInfo from '../CartInfo';
import OpenOrdersInfoDetail from './OpenOrdersInfoDetail';

const OpenOrdersAccordionSummary = props => {
	const { order } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const loadingFavourite = useStoreState(state => state.cart.favourite.loading);
	const setFavourite = useStoreActions(dispatch => dispatch.cart.favourite.sendRequest);
	const loadingRemove = useStoreState(state => state.cart.remove.loading);
	const remove = useStoreActions(dispatch => dispatch.cart.remove.sendDelete);

	const showFavourite =
		iCan('OPERATE_ON_CARTS') &&
		order.type === null &&
		!order.isDefault &&
		!order.isSAP &&
		(!order.fcd || order.referenceCartId);

	const onClick = e => {
		e.stopPropagation();
		setFavourite(order.id);
	};

	const onDelete = e => {
		e.stopPropagation();
		remove(order.id);
	};

	return (
		<Stack
			direction={{ xs: 'row', nd: 'row', sm: 'row' }}
			justifyContent="flex-start"
			alignItems="center"
			spacing={1}
			sx={{ width: '100%' }}
		>
			{/* Nome e data aggiornamento */}
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{ width: { xs: '50%', md: '30%', sm: '70%' } }}
			>
				<Typography
					variant="titleAccordion"
					sx={{
						color:
							order.type === 'BOOK2C'
								? theme.palette.custom.book2c
								: order.isDefault
								? theme.palette.secondary.main
								: theme.palette.primary.black
					}}
				>
					{order.isDefault ? translate('orders.defaultCart') : order.name}
				</Typography>
				<Typography variant="text" sx={{ fontSize: '13px' }}>
					{moment(order.lastUpdate).format('L')}
				</Typography>
			</Stack>

			{/* Icone info */}
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={0}
				sx={{
					display: { xs: 'none', md: 'inherit', sm: 'none' },
					width: { xs: '0%', md: '30%', sm: '0%' }
				}}
			>
				{iCan('OPERATE_PA_CARTS') && !order.isSAP && !order.isDefault && order.type !== 'BOOK2C' && (
					<Tooltip variant="light" arrow followCursor placement="top" title={<CartInfo order={order} />}>
						<AssignmentIcon sx={{ fontSize: '28px' }} />
					</Tooltip>
				)}
				<Box>
					<OpenOrdersInfoDetail order={order} />
				</Box>
				{/* -> Inserite chips per esteso al posto delle icone
				{order.isDefault && (
					<Tooltip
						variant="light"
						arrow
						followCursor
						placement="top"
						title={<Typography variant="tooltip">{translate('orders.defaultCart')}</Typography>}
					>
						<Typography variant="chip" sx={{ backgroundColor: theme.palette.secondary.main }}>
							CP
						</Typography>
					</Tooltip>
				)}
				{order.fcd && (
					<Tooltip
						variant="light"
						arrow
						followCursor
						placement="top"
						title={<Typography variant="tooltip">fcd</Typography>}
					>
						<Typography variant="chip" sx={{ backgroundColor: theme.palette.custom.black }}>
							FCD
						</Typography>
					</Tooltip>
				)}
				{order.type === 'BOOK2C' && (
					<Tooltip
						variant="light"
						arrow
						followCursor
						placement="top"
						title={<Typography variant="tooltip">{translate('catalog.book2c')}</Typography>}
					>
						<Typography variant="chip" sx={{ backgroundColor: theme.palette.custom.book2c }}>
							InL
						</Typography>
					</Tooltip>
				)}
				{order.showWarning && (
					<Tooltip
						variant="light"
						arrow
						followCursor
						placement="top"
						title={<Typography variant="tooltip">{translate('orders.warningEvasionCart')}</Typography>}
					>
						<ProductionQuantityLimitsIcon sx={{ fontSize: '28px', color: theme.palette.custom.orange }} />
					</Tooltip>
				)}
				{order.resumeOrder.sumOfProcessableCatalog > 0 && (
					<Tooltip
						variant="light"
						arrow
						followCursor
						placement="top"
						title={
							<Typography variant="tooltip">
								{translate('orders.processableTitles') +
									': ' +
									order.resumeOrder.sumOfProcessableCatalog}
							</Typography>
						}
					>
						<Typography variant="chip" sx={{ backgroundColor: theme.palette.custom.green }}>
							CE
						</Typography>
					</Tooltip>
				)}
				 */}
			</Stack>

			{/* Totale / Evadibile */}
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={0}
				sx={{ width: { xs: '50%', md: '30%', sm: '30%' } }}
			>
				<Typography
					variant="text"
					sx={{
						color:
							order.resumeOrder.sumOfProcessableCatalog > 0
								? theme.palette.custom.green
								: theme.palette.custom.black
					}}
				>
					{order.resumeOrder.sumOfCatalog} / {order.resumeOrder.sumOfProcessableCatalog}
				</Typography>
				<Typography
					variant="text"
					sx={{
						color:
							order.resumeOrder.sumOfProcessableCatalog > 0
								? theme.palette.custom.green
								: theme.palette.custom.black
					}}
				>
					{utils.formatPrice(order.resumeOrder.sumCost)} /{' '}
					{utils.formatPrice(order.resumeOrder.sumCostProcessable)}
				</Typography>
				<Typography variant="text" sx={{ fontSize: '13px', textTransform: 'lowercase' }}>
					{translate('common.total')} / {translate('orders.processable')}
				</Typography>
			</Stack>
			{/* Icone funzionalità */}
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={0}
				sx={{ display: { xs: 'none', md: 'inherit', sm: 'inherit' } }}
			>
				<Box>
					{loadingFavourite === order.id && <CircularProgress size={16} sx={{ marginLeft: '5px' }} />}
					{loadingFavourite !== order.id && showFavourite && (
						<Tooltip
							variant="light"
							arrow
							followCursor
							placement="top"
							title={
								<Typography variant="tooltip">
									{order.favourite
										? translate('orders.favouriteUnset')
										: translate('orders.favouriteSet')}
								</Typography>
							}
						>
							<IconButton
								onClick={onClick}
								sx={{
									color: order.favourite ? theme.palette.primary.main : theme.palette.custom.disabled,
									padding: '0'
								}}
							>
								<StarIcon sx={{ fontSize: '28px' }} />
							</IconButton>
						</Tooltip>
					)}
					{!showFavourite && <StarIcon sx={{ fontSize: '28px', color: theme.palette.custom.white }} />}
				</Box>
				{order.orderItems.length === 0 && !order.isDefault && (
					<Box>
						{loadingRemove === order.id && (
							<CircularProgress size={16} sx={{ marginLeft: '10px', color: theme.palette.custom.red }} />
						)}
						{loadingRemove !== order.id && (
							<Tooltip
								variant="light"
								arrow
								followCursor
								placement="top"
								title={<Typography variant="tooltip">{translate('common.deleteCart')}</Typography>}
							>
								<IconButton
									onClick={onDelete}
									sx={{ color: theme.palette.custom.red, padding: '0 0 0 10px' }}
								>
									<DeleteForeverIcon sx={{ fontSize: '26px' }} />
								</IconButton>
							</Tooltip>
						)}
					</Box>
				)}
				{order.orderItems.length === 0 && order.type === 'BOOK2C' && (
					<DeleteForeverIcon sx={{ fontSize: '26px', color: theme.palette.custom.white }} />
				)}
			</Stack>
		</Stack>
	);
};

export default OpenOrdersAccordionSummary;
