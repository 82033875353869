import create from './create';
import list from './list';
import accept from './accept';
import reject from './reject';
import getDays from './get-days';
import getCarts from './get-carts';
import setSingleDay from './set-single-day';
import setAllMonth from './set-all-month';

export default {
	create,
	list,
	accept,
	reject,
	getDays,
	getCarts,
	setSingleDay,
	setAllMonth
};
