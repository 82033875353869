export default theme => ({
	negateMargin: {
		marginTop: -41
	},
	noPadding: {
		padding: 0
	},
	linkButton: {
		minWidth: 'fit-content',
		height: theme.spacing(2),
		padding: theme.spacing(0),
		borderRadius: 0,
		'&:hover': {
			cursor: 'pointer',
			background: 'none',
			borderBottom: '1px solid #00529b'
		}
	}
});
