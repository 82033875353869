import React from 'react';
import Id from './Id';
import SapName from './SapName';
import Status from './Status';
import Edit from './Edit';
import Selection from './Selection';
import StartDate from './StartDate';

const allCells = [
	{
		id: 'selection',
		comp: ({ item }) => <Selection key={`bodyCell_selection_editorials_${item.ean}`} item={item} />
	},
	{
		id: 'id',
		comp: ({ item }) => <Id key={`bodyCell_id_editorials_${item.id}`} item={item} />
	},
	{
		id: 'sapName',
		comp: ({ item }) => <SapName key={`bodyCell_sapName_editorials_${item.id}`} item={item} />
	},
	{
		id: 'status',
		comp: ({ item }) => <Status key={`bodyCell_status_editorials_${item.id}`} item={item} />
	},
	{
		id: 'startDate',
		comp: ({ item }) => <StartDate key={`bodyCell_startDate_editorials_${item.id}`} item={item} />
	},
	{
		id: 'edit',
		comp: ({ item }) => <Edit key={`bodyCell_edit_editorials_${item.id}`} item={item} />
	}
];

export default allCells;
