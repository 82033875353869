import { thunk, action } from 'easy-peasy';

export default {
	lastItem: {},
	save: action((state, payload) => {
		state.lastItem = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	getLastItem: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		actions.setLoading(true);
		const { services } = injections;
		services.shipping
			.getLastItem()
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('shipping.last');
			})
			.finally(_ => actions.setLoading(false));
	})
};
