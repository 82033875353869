import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const NumberComp = ({ docNum }) => {
	return <StyledTableCell>{docNum}</StyledTableCell>;
};

const Num = memo(
	({ item }) => <NumberComp docNum={item.docNum} />,
	(p, n) => p.item.docNum === n.item.docNum
);

export default Num;
