export default theme => ({
	filtersRoot: {
		[theme.breakpoints.down('sm')]: {
			maxHeight: 600
		}
	},
	negateMargin: {
		marginTop: -40
	},
	closeIcon: {
		padding: 0
	}
});
