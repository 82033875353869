import React from 'react';
import { useHistory } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import utils from 'components/utils';

const WishlistButton = ({ classes }) => {
	const history = useHistory();
	const iCan = utils.usePermissions();
	const onWishlistClick = _ => {
		const dest = iCan('MANAGE_WISHLIST') ? '/wishlist/condition-request' : '/wishlist/list';
		history.push(dest);
	};
	return (
		<IconButton onClick={onWishlistClick}>
			<FavoriteIcon />
		</IconButton>
	);
};

export default WishlistButton;
