import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import styles from './styles';

const PublisherSheet = ({ details }) => {
	const translate = utils.useI18N();
	return (
		<Fragment>
			{details && details.pdfBookProfileURL && (
				<Grid container item xs={12}>
					<Button
						href={details.pdfBookProfileURL}
						color="primary"
						style={{ textDecoration: 'underline', backgroundColor: 'transparent', padding: 0 }}
						disableRipple
						target="_blank"
						size="small"
					>
						{translate('catalog.details.publisherSheet')}
					</Button>
				</Grid>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(PublisherSheet);
