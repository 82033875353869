import { action, thunk } from 'easy-peasy';

export default {
	loads: {},
	setLoading: action((state, payload) => {
		state.loads[payload] = true;
	}),
	unsetLoading: action((state, payload) => {
		state.loads[payload] = false;
	}),
	reset: action((state, payload) => {
		state.loads = {};
	}),
	adding: null,
	setAdding: action((state, payload) => {
		state.adding = payload;
	}),
	add: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(payload);
		actions.setAdding(payload.ean);
		services.order
			.oneClick(payload.ean)
			.then(data => {
				const { item } = data;
				if (item.budgetDateErrorMessage && item.budgetDateErrorMessage !== '') {
					getStoreActions().errorCustom(item.budgetDateErrorMessage);
				} else if (item.budgetResidualErrorMessage && item.budgetResidualErrorMessage !== '') {
					getStoreActions().errorCustom(item.budgetResidualErrorMessage);
				} else {
					getStoreActions().successCustom(payload.successMessage);
					getStoreActions().catalog.search.incrementItemQuantity({ ean: payload.ean });
				}
			})
			.catch(e => {
				getStoreActions().error('order.oneClick');
			})
			.finally(_ => {
				actions.unsetLoading(payload);
				actions.setAdding(null);
			});
	})
};
