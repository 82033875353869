import React from 'react';
import Sortable from './Sortable';
const allCols = [
	{
		id: 'ean',
		comp: _ => <Sortable id="ean" key="headCell_ean" label="wishlist.tables.ean" />
	},
	{
		id: 'title',
		comp: _ => <Sortable id="title" key="headCell_title" label="wishlist.tables.title" />
	},
	{
		id: 'serie',
		comp: _ => <Sortable id="serie" key="headCell_serie" label="wishlist.tables.serie" />
	},
	{
		id: 'publisher',
		comp: _ => <Sortable id="publisher" key="headCell_publisher" label="wishlist.tables.publisher" />
	},
	{
		id: 'fullPrice',
		comp: _ => <Sortable id="fullPrice" key="headCell_price" label="wishlist.tables.price" />
	},
	{
		id: 'quantity',
		comp: _ => <Sortable id="quantity" key="headCell_quantity" label="wishlist.tables.quantity" />
	}
];

export default allCols;
