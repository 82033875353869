import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import utils from 'components/utils';
import moment from 'moment';

const CartInfo = props => {
	const { order } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();

	const element = [
		{ label: 'chapter', value: order.chapter },
		{ label: 'cig', value: order.cig },
		{ label: 'cause', value: order.cause },
		{ label: 'purchaseNumber', value: order.purchaseNumber },
		{ label: 'purchaseOrderDate', value: moment(order.purchaseOrderDate).format('L') }
	];

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0.5}
			sx={{ width: '100%', padding: '5px' }}
		>
			{element.map((el, index) => (
				<Typography variant="tooltip" key={index}>
					<strong>{translate('orders.' + el.label)}</strong>
					{': ' + el.value}
				</Typography>
			))}
		</Stack>
	);
};

export default CartInfo;
