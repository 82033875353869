import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import StopIcon from '@mui/icons-material/Stop';
import { red } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import styles from './styles';
import utils from 'components/utils';

const PersonificationResume = ({ classes }) => {
	const [, target] = utils.usePersonification();
	const history = useHistory();
	const stop = useStoreActions(dispatch => dispatch.me.personify.stop);
	const resetWishlist = useStoreActions(dispatch => dispatch.wishlist.listLight.resetList);
	const resetCart = useStoreActions(dispatch => dispatch.cart.listLight.resetList);
	const redTheme = createTheme({ palette: { primary: red } });
	const onStopClick = _ => {
		stop();
		resetWishlist();
		resetCart();
		history.push('/');
	};
	return (
		<Grid container spacing={1}>
			<Grid item container xs>
				<Typography className={classes.heading} variant={'body1'}>
					{target.id} ({target.displayName})
				</Typography>
			</Grid>
			<Grid item container justify="flex-end" className={classes.stop}>
				<ThemeProvider theme={redTheme}>
					<Button
						size="large"
						style={{ marginTop: 12 }}
						variant="contained"
						color="primary"
						onClick={onStopClick}
						endIcon={<StopIcon />}
					>
						stop
					</Button>
				</ThemeProvider>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(PersonificationResume);
