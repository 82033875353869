import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import debounce from 'lodash/debounce';
import { TextField, CircularProgress, Box } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import styles from './styles';
import { Grid } from '@mui/material';

const SearchBar = ({ classes, type }) => {
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.catalog.themaAutocomplete.loading);
	const load = useStoreActions(dispatch => dispatch.catalog.themaAutocomplete.load);
	const token = useStoreState(state => state.catalog.themaAutocomplete.token);
	const setToken = useStoreActions(dispatch => dispatch.catalog.themaAutocomplete.setToken);
	const open = useStoreState(state => state.catalog.themaAutocomplete.open);
	const setOpen = useStoreActions(dispatch => dispatch.catalog.themaAutocomplete.setOpen);
	const skipNext = useStoreState(state => state.catalog.themaAutocomplete.skipNext);
	const setSkipNext = useStoreActions(dispatch => dispatch.catalog.themaAutocomplete.setSkipNext);
	const list = useStoreState(state => state.catalog.themaAutocomplete.list);
	const loadTree = useStoreActions(dispatch => dispatch.catalog.thema.loadTree);
	const initTree = useStoreActions(dispatch => dispatch.catalog.thema.load);
	const reset = useStoreActions(dispatch => dispatch.catalog.themaAutocomplete.reset);
	const [selectedValue, SetSelectedValue] = useState('');
	const placeHolder = type == 'C' ? 'catalog.themasearch' : 'catalog.themasearchScope';

	const debouncedLoad = useCallback(
		debounce(_ => {
			load(type);
		}, 400),
		[]
	);

	const onChange = (event, value) => {
		//setSkipNext(true);
		if (value.length > 3) {
			setSkipNext(true);
			loadTree({ type, value });
		}
	};

	const onClose = event => {
		setOpen(false);
	};

	const onInputChange = (event, value) => {
		setSkipNext(false);
		SetSelectedValue(value);
		//Se cancello il testo ricarico l'alberatura
		if (!value) {
			const word = null;
			reset();
			initTree({ type, word });
		}
		if (value.length > 3) {
			setToken(value);
		}
	};

	useEffect(
		_ => {
			if (token.length > 3) {
				SetSelectedValue(token);
				debouncedLoad();
			}
		},
		[token, debouncedLoad]
	);

	return (
		<Autocomplete
			id="catalog-thema-autocomplete"
			sx={{ width: '100%' }}
			freeSolo
			disableClearable
			open={open}
			options={list}
			loading={loading}
			onInputChange={onInputChange}
			onChange={onChange}
			onClose={onClose}
			getOptionLabel={option => option}
			inputValue={selectedValue}
			renderInput={params => (
				<TextField
					{...params}
					label=""
					placeholder={translate(placeHolder)}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<Fragment>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</Fragment>
						)
					}}
				></TextField>
			)}
			renderOption={(props, option) => (
				<Box component="li" {...props}>
					{option}
				</Box>
			)}
		/>
	);
};

export default withStyles(styles, { withTheme: true })(SearchBar);
