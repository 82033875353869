import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';
import { useStoreState } from 'easy-peasy';

const TypeComp = ({ docTypeDet, status }) => {
	const statusList = {
		'00': 'Disponibile Checkin',
		'01': 'Disponibile Checkout',
		'90': 'In attesa di Checkin',
		'91': 'In attesa di Checkout'
	};
	const selectedType = useStoreState(state => state.documents.search.selectedType);
	const translate = utils.useI18N();
	const currentStatus = statusList[status];
	const labelType =
		selectedType.id === 'SUMMARY_INVOICES' && docTypeDet === 'ZFCL'
			? translate('documents.SCOLASTIC_INVOICES')
			: translate(selectedType.i18nKey);
	return (
		<StyledTableCell>
			<Typography variant="body2">{labelType}</Typography>
			{currentStatus && selectedType.id !== 'RIBA' && (
				<Typography variant="caption">({currentStatus})</Typography>
			)}
		</StyledTableCell>
	);
};

const Type = memo(
	({ item }) => <TypeComp docTypeDet={item.docTypeDet} status={item.statusfcd} />,
	(p, n) => p.item.docTypeDet === n.item.docTypeDet && p.item.statusfcd === n.item.statusfcd
);

export default Type;
