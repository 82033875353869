import { actionOn, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: actionOn(
		actions => actions.performUpdate.startType,
		state => {
			state.loading = true;
		}
	),
	unsetLoading: actionOn(
		actions => actions.performUpdate.type,
		state => {
			state.loading = false;
		}
	),
	performUpdate: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		const cols = payload.filter(item => item.enabled).map(item => item.id);
		return services.me
			.updateColumns(cols)
			.then(data => {
				getStoreActions().success('me.updateColumns');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('me.updateColumns');
			});
	})
};
