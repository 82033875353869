import React, { useEffect } from 'react';
import keys from 'lodash/keys';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableHead from './Table/TableHead';
import TableBody from './Table/TableBody';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import utils from 'components/utils';

const WishlistResume = ({ id }) => {
	const translate = utils.useI18N();
	const load = useStoreActions(dispatch => dispatch.conditionRequest.details.load);
	const data = useStoreState(state => state.conditionRequest.details.data);
	const loading = useStoreState(state => state.conditionRequest.details.loading);
	useEffect(() => {
		if (keys(data).indexOf(id.toString()) === -1) {
			load(id);
		}
	}, [load, data, id]);
	const items = data[id] ? data[id].wishlistItem.map(d => ({ id: d.id, quantity: d.quantity, ...d.catalog })) : [];
	const isDeleted = data[id] && data[id].deleted;
	return (
		<TableContainer component={Paper} sx={{ position: 'relative', zIndex: 0 }}>
			{loading && (
				<Grid item container xs={12} justify="center" style={{ marginBottom: 24 }}>
					<CircularProgress size={40} />
				</Grid>
			)}
			{isDeleted && (
				<Grid container item xs={12}>
					<Typography color="error" variant="overline">
						<b>{translate('common.info')}:</b>&nbsp;
					</Typography>
					<Typography variant="caption" style={{ alignSelf: 'center' }}>
						{translate('wishlist.isDeleted')}
					</Typography>
				</Grid>
			)}
			{!loading && items.length > 0 && (
				<Table size="small" stickyHeader aria-label="sticky table">
					<TableHead />
					<TableBody rows={items} />
				</Table>
			)}
			{!loading && items.length === 0 && (
				<Grid container item justify="center" sx={{ margin: '10px 0' }}>
					<Typography variant="text" sx={{ width: '100%', textAlign: 'center' }}>
						{translate('common.search.noResults')}
					</Typography>
				</Grid>
			)}
		</TableContainer>
	);
};

export default WishlistResume;
