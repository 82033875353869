import getDetails from './get-details';
import getPendingList from './get-pending-list';
import getAcceptedList from './get-accepted-list';
import getRefusedList from './get-refused-list';
import getPendingCount from './get-pending-count';
import getAcceptedCount from './get-accepted-count';
import getInCartCount from './get-in-cart-count';
import getRefusedCount from './get-refused-count';
import approve from './approve';
import reject from './reject';
import update from './update';
import getPromos from './get-promos';
import approveMultiple from './approve-multiple';
import rejectMultiple from './reject-multiple';
import updateMultiple from './update-multiple';

export default {
	getDetails,
	getPendingList,
	getAcceptedList,
	getRefusedList,
	getPendingCount,
	getAcceptedCount,
	getInCartCount,
	getRefusedCount,
	approve,
	reject,
	update,
	getPromos,
	approveMultiple,
	rejectMultiple,
	updateMultiple
};
