import k from 'k';
import utils from 'services/utils';

export default ({ ean, ca }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const idCA = ca ? `/?idCA=${ca}` : '';
	return utils
		.fetch(`${k.endpoints.apis}/order/history/${ean}${idCA}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
