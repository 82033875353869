import React from 'react';
import Publisher from './Publisher';
import Title from './Title';
import Code from './Code';
import Depot from './Depot';
import Promo from './Promo';
import Price from './Price';
import DeleteIcon from './Delete';
import EvadibleQuantity from './EvadibleQuantity';
import Quantity from './Quantity';
import NewsItem from './NewsItem';
import Author from './Author';
import Series from './Series';
import Edit from './Edit';
import Selection from './Selection';
import Volume from './Volume';
import Epipoli from './Epipoli';

const allCells = [
	{
		id: 'selection',
		comp: ({ item, type, status }) => (
			<Selection key={`bodyCell_selection_${item.id}`} item={item} wltype={type} status={status} />
		)
	},
	{
		id: 'deletee',
		comp: ({ item, status, type }) => (
			<DeleteIcon
				key={`bodyCell_delete_${item.catalog}`}
				item={item}
				status={status}
				type={type}
				style={{ width: 50 }}
			/>
		)
	},
	{
		id: 'catalog',
		comp: ({ item }) => <Code key={`bodyCell_catalog_${item.id}`} item={item} />
	},
	{
		id: 'author',
		comp: ({ item }) => <Author key={`bodyCell_author_${item.id}`} item={item} />
	},
	{
		id: 'epipoli',
		comp: ({ item }) => <Epipoli key={`bodyCell_epipoli_${item.id}`} item={item} />
	},
	{
		id: 'title',
		comp: ({ item }) => <Title key={`bodyCell_title_${item.id}`} item={item} />
	},
	{
		id: 'volume',
		comp: ({ item }) => <Volume key={`bodyCell_volume_${item.id}`} item={item} />
	},
	{
		id: 'series',
		comp: ({ item }) => <Series key={`bodyCell_series_${item.id}`} item={item} />
	},
	{
		id: 'publisher',
		comp: ({ item }) => <Publisher key={`bodyCell_publisher_${item.id}`} item={item} />
	},
	{
		id: 'price',
		comp: ({ item }) => <Price key={`bodyCell_price_${item.id}`} item={item} />
	},
	{
		id: 'promo',
		comp: ({ item }) => <Promo key={`bodyCell_promo_${item.id}`} item={item} />
	},
	{
		id: 'news',
		comp: ({ item }) => <NewsItem key={`bodyCell_notice_${item.id}`} item={item} />
	},
	{
		id: 'depot',
		comp: ({ item }) => <Depot key={`bodyCell_depot_${item.id}`} item={item} />
	},
	{
		id: 'evadibleQty',
		comp: ({ item }) => <EvadibleQuantity key={`bodyCell_evadibleQty_${item.id}`} item={item} />
	},
	{
		id: 'quantity',
		comp: ({ item, list }) => <Quantity key={`bodyCell_quantity_${item.id}`} item={item} list={list} />
	},
	{
		id: 'edit',
		comp: ({ item, status }) => (
			<Edit key={`bodyCell_edit_${item.id}`} status={status} item={item} style={{ width: 50 }} />
		)
	}
];

export default allCells;
