import { alpha } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export default theme => ({
	filtersRoot: {
		[theme.breakpoints.down('sm')]: {
			maxHeight: 600
		}
	},
	negateMargin: {
		marginTop: -41
	},
	margin: {
		marginTop: 0
	},
	closeIcon: {
		padding: 0
	},
	elements: {
		padding: `${theme.spacing(1, 1, 3, 1)} !important`
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.black, 0.25)
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(0),
			width: '100%'
		}
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch'
		}
	},
	baseGrid: {
		minHeight: 80,
		alignContent: 'baseline'
	},
	finalRow: {
		background: grey[300]
	},
	noBorder: {
		borderBottom: 'none'
	},
	custom: {
		'&:hover': {
			background: 'none'
		}
	},
	titleSeparator: {
		textAlign: 'center',
		margin: theme.spacing(2, 0)
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	modalPosition: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		flexDirection: 'row'
	},
	modalPaper: {
		justifyContent: 'center',
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '80%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	title: {
		margin: theme.spacing(2, 0)
	},
	subtitle: {
		marginBottom: theme.spacing(1)
	},
	dividerAria: {
		margin: theme.spacing(2, 0)
	},
	container: {
		maxHeight: 300,
		overflowX: 'hidden'
	}
});
