import importt from './import';
import getList from './list';
import getDetails from './get-details';
import search from './search';
import update from './update';
import addUserGroupVisualization from './add-user-group-visualization';
import addUserGroupAbilitation from './add-user-group-abilitation';
import removeUserGroupVisualization from './remove-user-group-visualization';
import removeUserGroupAbilitation from './remove-user-group-abilitation';
import getUserGroupsVisualization from './get-user-groups-visualization';
import getUserGroupsAbilitation from './get-user-groups-abilitation';
import getItems from './get-items';
import updateAssignment from './update-assignment';
import getReport from './report';

export default {
	importt,
	getList,
	getDetails,
	search,
	update,
	addUserGroupVisualization,
	addUserGroupAbilitation,
	removeUserGroupVisualization,
	removeUserGroupAbilitation,
	getUserGroupsVisualization,
	getUserGroupsAbilitation,
	getItems,
	updateAssignment,
	getReport
};
