import React, { useEffect, Fragment, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from 'components/Select';

const WishlistSelector = ({ className }) => {
	const loading = useStoreState(state => state.wishlist.listLight.loading);
	const list = useStoreState(state => state.wishlist.listLight.items);
	const listIsLoaded = useStoreState(state => state.wishlist.listLight.loadedList);
	const load = useStoreActions(dispatch => dispatch.wishlist.listLight.loadTitle);
	const select = useStoreActions(dispatch => dispatch.wishlist.listLight.select);
	const personify = useStoreState(state => state.me.personify);
	const userData = useStoreState(state => state.me.data);
	const userCode = personify.iAmPersonifying ? personify.target.id : userData.id;
	const [selectedWishlist, setSelectedWishlist] = useState('');
	/*
	useEffect(
		_ => {
			if (list.length === 0 && !listIsLoaded) {
				load();
			}
		},
		[list, load, listIsLoaded]
	);
	*/

	useEffect(_ => {
		if (!listIsLoaded) load();
	}, []);

	useEffect(
		_ => {
			if (list.length > 0) {
				setSelectedWishlist(list.find(wList => wList.selected).id);
			}
		},
		[list]
	);

	return (
		<Fragment>
			{!loading && list.length > 0 && (
				<FormControl variant="outlined" className={className} sx={{ width: '80%' }}>
					<Select value={selectedWishlist} onChange={event => select(event.target.value)}>
						{list.map((item, index) => (
							<MenuItem key={index} value={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			{!loading && list.length === 0 && <b style={{ alignSelf: 'center' }}>Nessuna wishlist selezionabile</b>}
		</Fragment>
	);
};

export default WishlistSelector;
