import { withStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';

const StyledTableCell = withStyles(theme => ({
	root: {
		padding: 4
	}
}))(TableCell);

export default StyledTableCell;
