import { grey } from '@mui/material/colors';

export default theme => ({
	elements: {
		padding: `${theme.spacing(2, 1, 2, 1)} !important`
	},
	finalRow: {
		background: grey[300]
	},
	noBorder: {
		borderBottom: 'none'
	},
	custom: {
		'&:hover': {
			background: 'none'
		}
	},
	titleSeparator: {
		textAlign: 'center',
		margin: theme.spacing(2, 0)
	}
});
