import importt from './import';
import details from './details';
import list from './list';
import filters from './filters';
import search from './search';
import update from './update';
import userGroupsVisualization from './user-groups-visualization';
import userGroupsAbilitation from './user-groups-abilitation';
import assign from './assign';
import detailsItems from './details-items';
import detailsUserGroupsVisualization from './details-user-groups-visualization';
import detailsUserGroupsAbilitation from './details-user-groups-abilitation';
import report from './report';

export default {
	importt,
	details,
	list,
	filters,
	search,
	update,
	userGroupsVisualization,
	userGroupsAbilitation,
	assign,
	detailsItems,
	detailsUserGroupsVisualization,
	detailsUserGroupsAbilitation,
	report
};
