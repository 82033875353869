export default (actions, payload, { getStoreState, injections, getStoreActions }) => {
	const { services } = injections;
	actions.setLoading(true);
	const filters = getStoreState().purchasings.filters.list;
	const params = { filters };
	return services.purchasings
		.search(params)
		.then(data => {
			getStoreActions().purchasings.list.save(data);
			getStoreActions().purchasings.referentList.getReferents();
			return Promise.resolve(data.items);
		})
		.catch(e => {
			getStoreActions().error('purchasings.search');
		})
		.finally(_ => {
			actions.setLoading(false);
		});
};
