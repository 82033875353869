import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const NameComp = ({ name }) => {
	return <StyledTableCell>{name}</StyledTableCell>;
};

const Name = memo(
	({ item }) => <NameComp name={item.name} />,
	(p, n) => p.item.name === n.item.name
);

export default Name;
