import { action, thunk } from 'easy-peasy';
import { act } from 'react-dom/test-utils';

export default {
	openModalUserGroups: false,
	setOpenModalUserGroups: action((state, payload) => {
		state.openModalUserGroups = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: [],
	setData: action((state, payload) => {
		state.data = payload;
	}),
	loadingList: [],
	addToLoadingList: action((state, payload) => {
		state.loadingList.push(payload);
	}),
	removeFromLoadingList: action((state, payload) => {
		const i = state.loadingList.indexOf(payload);
		if (i === -1) return;
		state.loadingList = state.loadingList.slice(0, i).concat(state.loadingList.slice(i + 1));
		state.loadingList.push(payload);
	}),
	add: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.addToLoadingList(payload);
		const { id, userGroupId } = payload;
		return services.proposals
			.addUserGroupAbilitation({ id, userGroupId })
			.then(data => {
				getStoreActions().success('proposals.addUserGroup');
				actions.reload(id); //Ricarico la proposta per visualizzare l'elenco gruppi aggiornato
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('proposals.errorAddUserGroup');
			})
			.finally(_ => {
				actions.removeFromLoadingList(payload);
			});
	}),
	remove: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.addToLoadingList(payload);
		const { id, userGroupId } = payload;
		return services.proposals
			.removeUserGroupAbilitation({ id, userGroupId })
			.then(data => {
				getStoreActions().success('proposals.removeUserGroup');
				actions.reload(id); //Ricarico la proposta per visualizzare l'elenco gruppi aggiornato
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('proposals.errorRemoveUserGroup');
			})
			.finally(_ => {
				actions.removeFromLoadingList(payload);
			});
	}),
	reload: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.proposals
			.getDetails(payload)
			.then(data => {
				actions.setData(data.item.userGroups);
			})
			.catch(e => {
				getStoreActions().error('proposals.errorGetDetails');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
