import React from 'react';
import { withStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import utils from 'components/utils';
import globalUtils from 'utils';
import styles from './styles';

const Logout = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const onClick = _ => {
		globalUtils.logout();
	};
	return (
		<ListItem button onClick={onClick}>
			<ListItemIcon className={classes.listIcon}>
				<ExitToAppIcon />
			</ListItemIcon>
			<ListItemText primary={translate('common.menu.logout')} />
		</ListItem>
	);
};

export default withStyles(styles, { withTheme: true })(Logout);
