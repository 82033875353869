import k from 'k';
import utils from 'services/utils';

export default (token, strictSearch) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	delete requestOptions.headers.PersonifiedUserCode;
	requestOptions.abortCtrl = utils.abort.subscribe('personify-search');
	return utils
		.fetch(`${k.endpoints.apis}/me/personify/autocomplete?keyword=${token}&strict=${strictSearch}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
