import { action, thunk, computed } from 'easy-peasy';
import tail from 'lodash/tail';

export default {
	visible: false,
	setVisible: action((state, payload) => {
		state.visible = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	fileLoadingPdf: false,
	setFileLoadingPdf: action((state, payload) => {
		state.fileLoadingPdf = payload;
	}),
	fileLoadingXls: false,
	setFileLoadingXls: action((state, payload) => {
		state.fileLoadingXls = payload;
	}),
	fileLoadingCsv: false,
	setFileLoadingCsv: action((state, payload) => {
		state.fileLoadingCsv = payload;
	}),
	formats: {
		pdf: false,
		xls: false,
		csv: false,
		marc: [],
		dublin: [],
		mods: []
	},
	hasMarc: computed(state => state.formats.marc.length + state.formats.dublin.length + state.formats.mods.length > 0),
	setFormats: action((state, payload) => {
		state.formats.xls = payload.xls;
		state.formats.pdf = payload.pdf;
		state.formats.csv = payload.csv;
		state.formats.marc = payload.format.marc;
		state.formats.dublin = payload.format.dublin;
		state.formats.mods = payload.format.mods;
	}),
	getAvailableFormat: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setVisible(true);
		actions.setLoading(true);
		const type = getStoreState().documents.search.selectedType.id;
		const reference = getStoreState().documents.search.savedRefNum;
		return services.documents
			.getAvailableFormat({ type, reference })
			.then(data => {
				actions.setFormats(data);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('documents.getAvailableFormat');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	getFile: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const type = getStoreState().documents.search.selectedType.id;
		const reference = getStoreState().documents.search.savedNumber;
		const refNum = getStoreState().documents.search.savedRefNum;
		let fileName = '';
		if (payload === 'pdf') {
			actions.setFileLoadingPdf(true);
		} else if (payload === 'xls') {
			actions.setFileLoadingXls(true);
		} else {
			actions.setFileLoadingCsv(true);
		}
		return services.documents
			.download({ type, reference, refNum, format: payload })
			.then(response => {
				const h = response.headers;
				const cd = h.get('content-disposition');
				fileName = tail(cd.split('filename='));
				return response;
			})
			.then(response => response.blob())
			.then(response => {
				const typeMap = {
					pdf: 'application/pdf',
					xls: 'application/vnd.ms-excel'
				};
				const file = new Blob([response], { type: typeMap[payload] });
				const fileURL = URL.createObjectURL(file);
				var a = document.createElement('a');
				a.download = fileName;
				a.href = fileURL;
				document.body.appendChild(a);
				a.click();
				a.remove();
			})
			.finally(_ => {
				if (payload === 'pdf') {
					actions.setFileLoadingPdf(false);
				} else if (payload === 'xls') {
					actions.setFileLoadingXls(false);
				} else {
					actions.setFileLoadingCsv(false);
				}
			});
	})
};
