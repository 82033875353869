import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const CodeComp = ({ ean }) => {
	return <StyledTableCell>{ean}</StyledTableCell>;
};

const Code = memo(
	({ item }) => <CodeComp ean={item.ean} />,
	(p, n) => p.item.ean === n.item.ean
);

export default Code;
