import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
	Box,
	Paper,
	Stack,
	Typography,
	Button,
	InputBase,
	InputLabel,
	FormControlLabel,
	Checkbox,
	Alert,
	List,
	ListItem,
	Popover,
	Dialog,
	DialogContent,
	IconButton,
	useMediaQuery,
	ListItemButton,
	ListItemText,
	Backdrop,
	Fab,
	Slide,
	Divider
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useHistory } from 'react-router-dom';
import clone from 'lodash/clone';
import utils from 'components/utils';
import Card from 'components7/Card';
import Picture from './components/Picture';
import { grey } from '@mui/material/colors';
import NotificationManager from './components/NotificationManager';
import { Close, Add } from '@mui/icons-material';

const Me = props => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const history = useHistory();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const [iAmPersonifying] = utils.usePersonification();
	const stateUser = utils.useUserData('', null);
	const loadingUpdateMe = useStoreState(state => state.me.update.loading);
	const updateMe = useStoreActions(dispatch => dispatch.me.update.performUpdate);
	const setWishlistScope = useStoreActions(dispatch => dispatch.wishlist.list.setScope);
	const openPdf = useStoreActions(dispatch => dispatch.me.getTermsConditions.openPdf);
	const [checked, ischecked] = useState(true);
	const [user, setUser] = useState(clone(stateUser));
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const openButtonList = Boolean(anchorEl);
	const re = /[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
	const newsletterValid =
		!user.newsletter.subscription || (user.newsletter.mailTo !== '' && re.test(user.newsletter.mailTo));
	const disabled = !checked || !newsletterValid || loadingUpdateMe;
	useEffect(
		_ => {
			setWishlistScope('profile');
		},
		[setWishlistScope]
	);
	const handleSave = _ => {
		updateMe({ user, history });
	};
	const handleClickPrivacy = event => {
		ischecked(event.target.checked);
	};
	const handleOpenNotification = _ => {
		setOpen(true);
	};
	const handleCloseNotification = _ => {
		setOpen(false);
	};
	const handleOpenButtonList = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseButtonlist = _ => {
		setAnchorEl(null);
	};
	const onChange = event => {
		setUser({
			...user,
			newsletter: {
				...user.newsletter,
				mailTo: event.target.value
			}
		});
	};
	const toggleNewsletterSubscription = _ => {
		setUser({
			...user,
			newsletter: {
				...user.newsletter,
				subscription: !user.newsletter.subscription
			}
		});
	};
	const MuiTextField = ({ label, value, width }) => (
		<Stack direction={'column'} width={width}>
			<InputLabel variant="standard">{label}</InputLabel>
			<InputBase id={label} variant="textBox" value={value ? value : ' '} disabled multiline />
		</Stack>
	);

	const buttonList = [
		{
			label: 'Canali di comunicazione',
			condition: true,
			href: null,
			onclick: handleOpenNotification,
			type: 'light',
			disabled: false
		},
		{
			label: 'Condizioni di vendita',
			condition: iAmPersonifying && iCan('VIEW_SELLING_CONDITIONS', true),
			href: '/conditions',
			onclick: null,
			type: 'light',
			disabled: false
		},
		{
			label: translate('common.save'),
			condition: true,
			href: null,
			onclick: handleSave,
			type: 'dark',
			disabled: disabled
		}
	];

	return (
		<Fragment>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{ minHeight: '100vh' }}
			>
				<Paper variant="stickyTop">
					<Stack
						direction={{ xs: 'row', md: 'row', sm: 'row' }}
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
						variant="wrapperColor"
					>
						<Typography variant="titlePage">Profilo</Typography>
						{!isMobile && (
							<Stack direction="row" spacing={1}>
								{buttonList.map(
									(button, index) =>
										button.condition && (
											<Button
												key={index}
												component={button.href && Link}
												variant={button.type}
												to={button.href}
												onClick={button.onclick}
												disabled={button.disabled}
											>
												{button.label}
											</Button>
										)
								)}
							</Stack>
						)}
						{isMobile && (
							<Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
								<Backdrop open={openButtonList} />
								<Fab
									aria-label="Mobile function"
									onClick={handleOpenButtonList}
									sx={{
										backgroundColor: theme.palette.primary.main,
										color: theme.palette.custom.white,
										'&:hover': {
											backgroundColor: theme.palette.custom.hover
										}
									}}
									size="small"
								>
									<Add />
								</Fab>
								<Popover
									open={openButtonList}
									anchorEl={anchorEl}
									onClose={handleCloseButtonlist}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center'
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right'
									}}
								>
									<Paper elevation={24}>
										<List sx={{ padding: '0' }}>
											{buttonList.map(
												btn =>
													btn.condition && (
														<ListItemButton
															key={btn.label}
															variant="mobileList"
															to={btn.href}
															component={btn.href && Link}
															onClick={btn.onclick}
															sx={{
																width: '100%',
																backgroundColor:
																	btn.type === 'dark'
																		? theme.palette.primary.main
																		: theme.palette.custom.white
															}}
														>
															<ListItemText
																primary={btn.label}
																sx={{
																	color:
																		btn.type == 'dark'
																			? theme.palette.custom.white
																			: 'inherit'
																}}
															/>
														</ListItemButton>
													)
											)}
										</List>
									</Paper>
								</Popover>
							</Box>
						)}
					</Stack>
				</Paper>

				<Stack id="mainStack" direction={'column'} width={'100%'}>
					<Stack
						direction={{ sm: 'row', xs: 'column' }}
						spacing={2}
						sx={{ marginTop: '30px', width: '100%' }}
					>
						<Card title={'Immagine profilo'} width={{ sm: '40%', xs: '100%' }}>
							<Picture />
						</Card>
						<Card title={'Anagrafica'} width={{ sm: '60%', xs: '100%' }}>
							<Stack
								direction={'column'}
								spacing={4}
								sx={{ width: '100%' }}
								divider={isMobile && <Divider sx={{ color: 'black' }} />}
							>
								<Stack
									direction={{ sm: 'row', xs: 'column' }}
									spacing={2}
									justifyContent={'space-between'}
								>
									<MuiTextField label={translate('user.id')} value={user.id} />
									<MuiTextField label={translate('user.displayName')} value={user.displayName} />
									<MuiTextField label={translate('user.vatCode')} value={user.vatCode} />
									<MuiTextField label={translate('user.invoiceCode')} value={user.invoiceCode} />
								</Stack>
								<Stack
									direction={{ sm: 'row', xs: 'column' }}
									spacing={2}
									justifyContent={'space-between'}
								>
									<MuiTextField
										label={translate('user.address.street')}
										value={user.address.street}
									/>
									<MuiTextField label={translate('user.address.city')} value={user.address.city} />
									<MuiTextField
										label={translate('user.address.county')}
										value={user.address.county}
									/>
									<MuiTextField
										label={translate('user.address.postalCode')}
										value={user.address.postalCode}
									/>
									<MuiTextField
										label={translate('user.address.country')}
										value={user.address.country}
									/>
								</Stack>
								<Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
									<MuiTextField
										label={translate('user.administrativeEmail')}
										value={user.administrativeEmail}
										width={{ sm: '33%', xs: '100%' }}
									/>
									<MuiTextField
										label={translate('user.email')}
										value={user.email}
										width={{ sm: '33%', xs: '100%' }}
									/>
									<MuiTextField
										label={translate('user.referent.name')}
										value={user.referent.name}
										width={{ sm: '33%', xs: '100%' }}
									/>
									{iCan('SHOW_BANK_CREDIT') && (
										<MuiTextField label={translate('user.bankCredit')} value={user.bankCredit} />
									)}
								</Stack>
								<Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
									<MuiTextField
										label={translate('user.phone')}
										value={user.phone}
										width={{ sm: '33%', xs: '100%' }}
									/>
									<MuiTextField
										label={translate('user.mobile')}
										value={user.mobile}
										width={{ sm: '33%', xs: '100%' }}
									/>
									<MuiTextField
										label={translate('user.fax')}
										value={user.fax}
										width={{ sm: '33%', xs: '100%' }}
									/>
								</Stack>
							</Stack>
						</Card>
					</Stack>
					<Stack direction={{ sm: 'row', xs: 'column' }} spacing={2} sx={{ marginTop: theme.spacing(3) }}>
						<Stack direction={'column'} width={{ sm: '40%', xs: '100%' }} spacing={3}>
							{iCan('SHOW_DISCOUNT_ONECLICK_STATUS', true) && iAmPersonifying && (
								<Card title={'Preferenze'}>
									<Stack direction={'row'} spacing={2}>
										<Stack direction={'column'} sx={{ width: '50%' }} spacing={2}>
											<FormControlLabel
												disabled
												variant="checkbox"
												control={
													<Checkbox disableFocusRipple disableTouchRipple disableRipple />
												}
												label={translate('user.discountVisibility')}
												checked={user.discountVisibility}
											/>
											<MuiTextField
												label={translate('user.abilitationDesc')}
												value={user.abilitationDesc}
											/>
										</Stack>
										<Stack direction={'column'} sx={{ width: '50%' }} spacing={2}>
											<FormControlLabel
												disabled
												variant="checkbox"
												control={
													<Checkbox disableFocusRipple disableTouchRipple disableRipple />
												}
												label={translate('user.flagOneClick')}
												checked={user.flagOneClick}
											/>
											<MuiTextField
												label={translate('user.bankCredit')}
												value={user.bankCredit}
											/>
										</Stack>
									</Stack>
								</Card>
							)}
							<Card title="Newsletter">
								<Stack
									direction={'row'}
									spacing={2}
									justifyContent={'space-between'}
									alignItems={'flex-end'}
								>
									<Stack direction={'column'} sx={{ width: '50%' }}>
										<InputLabel variant="standard">Email</InputLabel>
										<InputBase
											id="inputNewsletter"
											variant="textBox"
											value={user.newsletter.mailTo ? user.newsletter.mailTo : ''}
											onChange={onChange}
											placeholder="Inserisci email per la newsletter..."
										/>
									</Stack>
									<FormControlLabel
										sx={{ height: '80%', width: '50%' }}
										variant="checkbox"
										control={<Checkbox disableFocusRipple disableTouchRipple disableRipple />}
										label="Adesione alla newsletter"
										checked={user.newsletter.subscription}
										onChange={toggleNewsletterSubscription}
									/>
								</Stack>
							</Card>
							<Card title="Privacy">
								<Stack direction={'column'} spacing={1} justifyContent={'center'}>
									<Typography typography={'text'}>
										Informativa ai sensi dell’art. 13 del Regolamento Europeo 2016/679
									</Typography>
									<Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
										<Button onClick={_ => openPdf()} sx={{ width: '50%' }}>
											Leggi testo sulla normativa
										</Button>
										<FormControlLabel
											sx={{ width: '50%' }}
											variant="checkbox"
											required
											control={<Checkbox disableFocusRipple disableTouchRipple disableRipple />}
											label={translate('user.privacyPolicy.read')}
											checked={checked}
											onChange={handleClickPrivacy}
										/>
									</Stack>
									{!checked && (
										<Alert severity="error">
											<Typography>Questo è un campo obbligatorio.</Typography>
										</Alert>
									)}
								</Stack>
							</Card>
						</Stack>
						<Card title={'Depositi'} width={{ sm: '60%', xs: '100%' }}>
							<Stack direction={'column'} sx={{ padding: '10px' }} alignItems={'center'} spacing={1}>
								<Typography variant="titleTab">{translate('user.mainDepot.description')}: </Typography>
								<Typography variant="text">
									{user.mainDepot ? user.mainDepot.description : ''}
								</Typography>
							</Stack>
							<Stack direction={'row'} spacing={2} sx={{ marginTop: theme.spacing(2) }}>
								<Box sx={{ width: '50%' }}>
									<Stack
										direction={'column'}
										alignItems={'center'}
										spacing={1}
										sx={{ padding: '10px' }}
									>
										<Typography variant="titleTab">
											{translate('user.availableDepots')}:{' '}
										</Typography>
										<List>
											{user.depots.map((depot, index) => (
												<Fragment key={index}>
													{depot && depot.type !== 'RESA' && (
														<ListItem>
															<Typography variant="text">
																<strong>{depot.id}: </strong>
																{depot.description}
															</Typography>
														</ListItem>
													)}
												</Fragment>
											))}
										</List>
									</Stack>
								</Box>
								<Box sx={{ width: '50%' }}>
									<Stack
										direction={'column'}
										alignItems={'center'}
										spacing={1}
										sx={{ padding: '10px' }}
									>
										<Typography variant="titleTab">{translate('user.returnDepots')}: </Typography>
										<List>
											{user.depots.map((depot, index) => (
												<Fragment key={index}>
													{depot && depot.type === 'RESA' && (
														<ListItem>
															<Typography variant="text">
																<strong>{depot.id}: </strong>
																{depot.description}
															</Typography>
														</ListItem>
													)}
												</Fragment>
											))}
										</List>
									</Stack>
								</Box>
							</Stack>
						</Card>
					</Stack>
				</Stack>
			</Stack>
			<Dialog
				open={open}
				onClose={handleCloseNotification}
				PaperProps={{ sx: { padding: '20px' } }}
				fullScreen={isMobile}
				TransitionComponent={Slide}
				TransitionProps={{ direction: 'up' }}
			>
				<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
					<Typography variant="titleSection">Canali di comunicazione</Typography>
					<IconButton onClick={handleCloseNotification}>
						<Close />
					</IconButton>
				</Stack>
				<DialogContent>
					<NotificationManager />
				</DialogContent>
			</Dialog>
		</Fragment>
	);
};

export default Me;
