import React from 'react';

const withColumns = WrappedComponent => {
	return props => {
		const visibleCols = ['cod', 'rag', 'delete'];
		return <WrappedComponent {...props} visibleCols={visibleCols} />;
	};
};

export default withColumns;
