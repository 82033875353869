import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Card from 'components/Card';
import NewCard from 'components7/Card';
import Import from './components/Import';
import AssignArticleBtn from './components/AssignArticleBtn';
import AssignUserGroup from './components/AssignUserGroup';
import { UserGroupsTableAbilitation } from './components/Table/UserGroupsTable';
import ItemsTable from './components/Table/ItemsTable';
import utils from 'components/utils';
import styles from './styles';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography';
import BackToButton from 'components/BackToButton';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const makeDatePickerStyles = makeStyles({
	root: {
		width: 180
	}
});

const EditorialsDetails = ({ classes }) => {
	const history = useHistory();
	const translate = utils.useI18N();
	const load = useStoreActions(dispatch => dispatch.editorials.details.load);
	const data = useStoreState(state => state.editorials.details.data);
	const loadingUpdate = useStoreState(state => state.editorials.update.loading);
	const update = useStoreActions(dispatch => dispatch.editorials.update.sendRequest);
	const params = useParams();
	const setName = useStoreActions(dispatch => dispatch.editorials.details.setName);
	const setEnabled = useStoreActions(dispatch => dispatch.editorials.details.setEnabled);
	const setStartDate = useStoreActions(dispatch => dispatch.editorials.details.setStartDate);
	const datePickerStyles = makeDatePickerStyles();
	const onSaveClick = _ => {
		update({
			history,
			sapId: params.id,
			data
		});
	};
	useEffect(
		_ => {
			load(params.id);
		},
		[load, params]
	);
	return (
		<Fragment>
			<Card type="primary" title={translate('editorials.title')} style={{ marginTop: 0 }}>
				<Grid item container xs={12} spacing={4}>
					{data && (
						<Fragment>
							<Grid item container xs={12} justifyContent="flex-start">
								<Grid container item xs={12}>
									<AssignUserGroup currentEditorial={data} />
									<Import id={data.id} />
									<AssignArticleBtn currentEditorial={data} />
									<BackToButton
										to={`/admin/editorials`}
										style={{ margin: '0 1 0 0' }}
										label={'backToEditorials'}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<NewCard backgroundColor="white" title={`${translate('promo.details.meta')}`}>
									<CardContent style={{ paddingBottom: 0 }}>
										<LocalizationProvider dateAdapter={AdapterMoment}>
											<Grid container spacing={1}>
												<Grid item container xs={12}>
													<Grid container item md={12} xs={12} justifyContent="flex-start">
														{data.description && (
															<Typography
																align={'left'}
																variant={'overline'}
																style={{ marginBottom: '2%', fontWeight: 'bold' }}
															>
																{translate('rankings.description') +
																	': ' +
																	data.description}
															</Typography>
														)}
													</Grid>
													<Grid
														container
														item
														sm={4}
														xs={12}
														className={classes.marginAttributes}
													>
														<TextField
															value={data.name}
															onChange={e => setName(e.target.value)}
															fullWidth={false}
															label={translate('editorials.name')}
														/>
													</Grid>
													<Grid
														container
														item
														sm={4}
														xs={12}
														className={classes.marginAttributes}
													>
														<FormControlLabel
															value="start"
															control={
																<Checkbox
																	color="primary"
																	size="medium"
																	checked={data.enabled}
																	onChange={e => setEnabled(e.target.checked)}
																	style={{ paddingBottom: 0 }}
																/>
															}
															label={translate('coupons.tables.status')}
															labelPlacement="start"
															style={{ margin: 0, alignItems: 'flex-end' }}
														/>
													</Grid>
													<Grid
														container
														item
														sm={4}
														xs={12}
														className={classes.marginAttributes}
													>
														<DateTimePicker
															className={datePickerStyles.root}
															label={translate('coupons.tables.startDate')}
															value={data.startDate ? moment(data.startDate) : null}
															onChange={setStartDate}
															autoOk
															ampm={false}
															format="D MMMM YYYY HH:mm"
															/*
														disabilito la possibilita di scegliere date passate
														ma non inserisco warning
														*/
															disablePast
															helperText=""
															error={false}
															slotProps={{
																textField: {
																	sx: {
																		width: '240px !important'
																	}
																}
															}}
														/>
													</Grid>
												</Grid>
												<Grid container item xs={12} justifyContent="flex-end">
													{loadingUpdate && <CircularProgress size={16} />}
													{!loadingUpdate && (
														<Button
															variant="contained"
															color="primary"
															onClick={onSaveClick}
														>
															{translate('common.save')}
														</Button>
													)}
												</Grid>
											</Grid>
										</LocalizationProvider>
									</CardContent>
								</NewCard>
							</Grid>
							<Grid item container spacing={2} xs={12}>
								<Grid item xs={12} md={6}>
									<NewCard backgroundColor="white" title="Gruppi di utenti assegnati">
										<CardContent style={{ padding: '8px 0' }}>
											<UserGroupsTableAbilitation />
										</CardContent>
									</NewCard>
								</Grid>
								<Grid item xs={12} md={6}>
									<NewCard backgroundColor="white" title="Articoli assegnati">
										<CardContent style={{ padding: '8px 0' }}>
											<ItemsTable />
										</CardContent>
									</NewCard>
								</Grid>
							</Grid>
						</Fragment>
					)}
				</Grid>
			</Card>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(EditorialsDetails);
