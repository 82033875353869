import { action, thunk } from 'easy-peasy';

export default {
	modalOpen: false,
	setModalOpen: action((state, payload) => {
		state.modalOpen = payload;
	}),
	loadingPromotions: false,
	setLoadingPromotions: action((state, payload) => {
		state.loadingPromotions = payload;
	}),
	promotions: [],
	setPromotions: action((state, payload) => {
		const selectNew = [{ sapPromoId: '_empty_', name: 'seleziona una promo', isSelected: true }];
		state.promotions = [...selectNew, ...payload];
	}),
	selectPromotion: action((state, payload) => {
		state.promotions = state.promotions.map(p => ({
			...p,
			isSelected: p.sapPromoId === payload
		}));
	}),
	getPromos: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingPromotions(true);
		services.promotions
			.getWishlist()
			.then(data => {
				actions.setPromotions(data);
			})
			.catch(e => {
				console.log(e);
				getStoreActions().error('wishlist.getPromos');
			})
			.finally(_ => {
				actions.setLoadingPromotions(false);
			});
	}),
	loadingUser: false,
	setLoadingUser: action((state, payload) => {
		state.loadingUser = payload;
	}),
	user: {},
	setUser: action((state, payload) => {
		state.user = payload;
	}),
	getUser: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingUser(true);
		services.users
			.getByUserCode(payload)
			.then(data => {
				actions.setUser(data);
			})
			.catch(e => {
				getStoreActions().error('wishlist.getUser');
			})
			.finally(_ => {
				actions.setLoadingUser(false);
			});
	}),
	loadingREC: false,
	setLoadingREC: action((state, payload) => {
		state.loadingREC = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingREC(true);
		return services.wishlist
			.requestExtraConditions(payload)
			.then(data => {
				getStoreActions().success('wishlist.rec');
				actions.setModalOpen(false);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('wishlist.rec');
			})
			.finally(_ => {
				actions.setLoadingREC(false);
			});
	})
};
