export default theme => ({
	tabPanel: {
		width: '100%'
	},
	searchButton: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center'
		}
	}
});
