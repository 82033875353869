import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import PaginationComp from 'components/Pagination';

const Pagination = _ => {
	const currentPage = useStoreState(state => state.catalog.search.pagination.current);
	const step = useStoreState(state => state.catalog.search.SEARCH_paginationStep);
	const pageCount = useStoreState(state => state.catalog.search.pagination.pageCount);
	const totalItems = useStoreState(state => state.catalog.search.pagination.totalItems);
	const setCurrentPage = useStoreActions(dispatch => dispatch.catalog.search.setPaginationCurrent);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);

	useEffect(() => {
		//Scroll top
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [currentPage]);

	return (
		<PaginationComp
			currentPage={currentPage}
			step={step}
			pageCount={pageCount}
			setCurrentPage={setCurrentPage}
			setStep={setStep}
			totalItems={totalItems}
		/>
	);
};

export default Pagination;
