import { thunk, action } from 'easy-peasy';

export default {
	labels: {},
	current: 'it',
	setCurrent: action((state, payload) => {
		if (state.list.includes(payload)) state.current = payload;
	}),
	save: action((state, payload) => {
		state.labels = payload.labels;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		services.i18n
			.loadLabels()
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('i18n.load');
			});
	})
};
