import React from 'react';
import Sortable from './Sortable';
import NotSortable from './NotSortable';
import Empty from './Empty';
import Selection from './Selection';

const allCols = [
	{
		id: 'type',
		comp: _ => <Sortable key="headCell_type_documents" label="documents.tables.type" />
	},
	{
		id: 'number',
		comp: _ => <Sortable id="ordered" key="headCell_number_documents" label="documents.tables.number" />
	},
	{
		id: 'documentNumber',
		comp: _ => (
			<NotSortable id="documentNumber" key="headCell_number_docs" label="documents.tables.documentNumber" />
		)
	},
	{
		id: 'date',
		comp: _ => <Sortable id="date" key="headCell_date_documents" label="documents.tables.date" />
	},
	{
		id: 'expirationDate',
		comp: _ => (
			<NotSortable id="expirationDate" key="headCell_expiration_date" label="documents.tables.expirationDate" />
		)
	},
	{
		id: 'issuingDate',
		comp: _ => <NotSortable id="issuingDate" key="headCell_issuing_date" label="documents.tables.issuingDate" />
	},
	{
		id: 'matchNumber',
		comp: _ => <NotSortable id="matchNumber" key="headCell_match_number" label="documents.tables.matchNumber" />
	},
	{
		id: 'customer',
		comp: _ => <Sortable id="customer" key="headCell_customer_documents" label="documents.tables.customer" />
	},
	{
		id: 'depot',
		comp: _ => <Sortable id="depot" key="headCell_depot_documents" label="documents.tables.depot" />
	},
	{
		id: 'amount',
		comp: _ => <Sortable id="amount" key="headCell_amount_documents" label="documents.tables.amount" />
	},
	{
		id: 'amount2',
		comp: _ => <NotSortable id="amount2" key="headCell_amount2_docs" label="documents.tables.amount" />
	},
	{
		id: 'invoice',
		comp: _ => <Sortable id="invoice" key="headCell_invoice_documents" label="documents.tables.invoice" />
	},
	{
		id: 'payment',
		comp: _ => <Sortable id="payment" key="headCell_payment_documents" label="documents.tables.payment" />
	},
	{
		id: 'selection',
		comp: _ => <Selection id="selection" key="headCell_selection_documents" />
	},
	{
		id: 'download',
		comp: _ => <Empty id="download" key="headCell_download_docs" />
	}
];

export default allCols;
