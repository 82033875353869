import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory, useParams } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers';
import { withStyles, makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Card from 'components/Card';
import NewCard from 'components7/Card';
import Import from './components/Import';
import AssignArticleBtn from './components/AssignArticleBtn';
import AssignUserGroup from './components/AssignUserGroup';
import { UserGroupsTableAbilitation } from './components/Table/UserGroupsTable';
import ItemsTable from './components/Table/ItemsTable';
import utils from 'components/utils';
import styles from './styles';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography';
import BackToButton from 'components/BackToButton';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const makeDatePickerStyles = makeStyles({
	root: {
		width: 180
	}
});

const RankingsDetails = ({ classes }) => {
	const history = useHistory();
	const translate = utils.useI18N();
	const load = useStoreActions(dispatch => dispatch.rankings.details.load);
	const data = useStoreState(state => state.rankings.details.data);
	const loadingUpdate = useStoreState(state => state.rankings.update.loading);
	const update = useStoreActions(dispatch => dispatch.rankings.update.sendRequest);
	const params = useParams();
	const setName = useStoreActions(dispatch => dispatch.rankings.details.setName);
	const setEnabled = useStoreActions(dispatch => dispatch.rankings.details.setEnabled);
	const setStartDate = useStoreActions(dispatch => dispatch.rankings.details.setStartDate);
	const setEndDate = useStoreActions(dispatch => dispatch.rankings.details.setEndDate);
	const datePickerStyles = makeDatePickerStyles();
	const now = moment();
	let isTodayExceeded = data ? now.isAfter(data.endDate) : null;
	const onSaveClick = _ => {
		update({
			history,
			sapId: params.id,
			data
		});
	};
	useEffect(
		_ => {
			load(params.id);
		},
		[load, params]
	);
	return (
		<Fragment>
			<Card type="primary" title={translate('rankings.title')} style={{ marginTop: 0 }}>
				<Grid item container xs={12} spacing={4}>
					{data && (
						<Fragment>
							<Grid item container xs={12} justifyContent="flex-start">
								<Grid container item xs={12}>
									<AssignUserGroup currentRanking={data} />
									<Import id={data.id} />
									<AssignArticleBtn currentRanking={data} />
									<BackToButton
										to={`/admin/rankings`}
										style={{ margin: '0 1 0 0' }}
										label={`backToRankings`}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<NewCard backgroundColor="white" title={`${translate('rankings.attributes')}`}>
									<Grid item container xs={12} alignItems={'center'}>
										<Grid container item md={12} xs={12} justifyContent="flex-start">
											{data.description && (
												<Typography
													align={'left'}
													variant={'overline'}
													style={{ marginBottom: '2%', fontWeight: 'bold' }}
												>
													{translate('coupons.description') + ': ' + data.description}
												</Typography>
											)}
										</Grid>
										<Grid container item sm={3} xs={6} className={classes.marginAttributes}>
											<TextField
												value={data.name}
												onChange={e => setName(e.target.value)}
												fullWidth={false}
												label={translate('rankings.name')}
											/>
										</Grid>
										<Grid container item sm={3} xs={6} className={classes.marginAttributes}>
											<FormControlLabel
												value="start"
												control={
													<Checkbox
														color="primary"
														size="medium"
														checked={data.enabled}
														onChange={e => setEnabled(e.target.checked)}
														style={{ paddingBottom: 0 }}
													/>
												}
												label={translate('coupons.tables.status')}
												labelPlacement="end"
												style={{ margin: 0, alignItems: 'flex-end' }}
											/>
										</Grid>
										<LocalizationProvider dateAdapter={AdapterMoment}>
											<Grid container item sm={3} xs={6}>
												<DateTimePicker
													className={datePickerStyles.root}
													label={translate('rankings.tables.startDate')}
													value={data.startDate ? moment(data.startDate) : null}
													onChange={setStartDate}
													autoOk
													ampm={false}
													format="D MMMM YYYY HH:mm"
													/*
													disabilito la possibilita di scegliere date passate
													ma non inserisco warning
													*/
													disablePast
													helperText=""
													error={false}
													slotProps={{
														textField: {
															sx: {
																width: '240px !important'
															}
														}
													}}
												/>
											</Grid>
											<Grid container item sm={3} xs={6}>
												<DateTimePicker
													className={datePickerStyles.root}
													label={translate('rankings.tables.endDate')}
													value={data.endDate ? moment(data.endDate) : null}
													onChange={setEndDate}
													autoOk
													ampm={false}
													format="D MMMM YYYY HH:mm"
													/*
													disabilito la possibilita di scegliere date passate
													ed inserisco warning con label
													*/
													disablePast
													error={isTodayExceeded}
													helperText={isTodayExceeded ? translate('common.dateExpired') : ''}
													slotProps={{
														textField: {
															sx: {
																width: '240px !important'
															}
														}
													}}
												/>
											</Grid>
										</LocalizationProvider>
									</Grid>
									<Grid container item xs={12} justifyContent="flex-end" style={{ marginTop: 24 }}>
										{loadingUpdate && <CircularProgress size={16} />}
										{!loadingUpdate && (
											<Button variant="contained" color="primary" onClick={onSaveClick}>
												{translate('common.save')}
											</Button>
										)}
									</Grid>
								</NewCard>
							</Grid>
							<Grid item container spacing={2}>
								<Grid item xs={12} md={6}>
									<NewCard backgroundColor="white" title="Gruppi di utenti assegnati">
										<CardContent style={{ padding: '8px 0' }}>
											<UserGroupsTableAbilitation />
										</CardContent>
									</NewCard>
								</Grid>
								<Grid item xs={12} md={6}>
									<NewCard backgroundColor="white" title="Articoli assegnati">
										<CardContent style={{ padding: '8px 0' }}>
											<ItemsTable />
										</CardContent>
									</NewCard>
								</Grid>
							</Grid>
						</Fragment>
					)}
				</Grid>
			</Card>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(RankingsDetails);
