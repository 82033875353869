import React, { memo } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import StyledTableCell from 'components/Table/StyledTableCell';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import MailIcon from '@mui/icons-material/Mail';
import Tooltip from '@mui/material/Tooltip';

const DownloadComp = ({
	docTypeDet,
	docUrl,
	docNumber,
	documentNumber,
	executionDate,
	identifier,
	reference,
	status,
	urlFCD,
	refNum,
	realTotal
}) => {
	const loading = useStoreState(state => state.documents.sendEmail.loading);
	const selectedType = useStoreState(state => state.documents.search.selectedType);
	const send = useStoreActions(dispatch => dispatch.documents.sendEmail.send);
	const openDetails = useStoreActions(dispatch => dispatch.documents.details.openModal);
	const openDetailsDepotStatement = useStoreActions(dispatch => dispatch.documents.details.openModalDepotStatement);
	const openDetailsSummaryInvoice = useStoreActions(dispatch => dispatch.documents.details.openModalSummaryInvoice);
	const getDetails = useStoreActions(dispatch => dispatch.documents.details.getDetails);
	const getDetailsRiba = useStoreActions(dispatch => dispatch.documents.details.getDetailsRiba);
	const saveNumber = useStoreActions(dispatch => dispatch.documents.search.saveNumber);
	const saveRealTotal = useStoreActions(dispatch => dispatch.documents.search.saveRealTotal);
	const saveRefNum = useStoreActions(dispatch => dispatch.documents.search.saveRefNum);
	const setStatus = useStoreActions(dispatch => dispatch.documents.details.setStatus);
	const setUrlFCD = useStoreActions(dispatch => dispatch.documents.details.setUrlFCD);
	const onSendClick = _ => {
		send(docUrl);
	};
	const onViewClick = _ => {
		const params = { type: selectedType.id, docNumber, refNum, docTypeDet };
		const paramsRiba = { executionDate, identifier, documentNumber };
		if (
			['ACCOMPANYING_INVOICES', 'CREDIT_NOTE', 'RIBA', 'BILL'].includes(selectedType.id) ||
			(selectedType.id === 'SUMMARY_INVOICES' && docTypeDet === 'ZFCL')
		) {
			openDetails(true);
		} else if (selectedType.id === 'SUMMARY_INVOICES') {
			openDetailsSummaryInvoice(true);
		} else {
			openDetailsDepotStatement(true);
		}
		saveRealTotal(realTotal);
		saveNumber(reference);
		saveRefNum(refNum);
		setUrlFCD(reference);
		setStatus(status);
		selectedType.id === 'RIBA' ? getDetailsRiba(paramsRiba) : getDetails(params);
	};
	return (
		<StyledTableCell style={{ textAlign: 'end' }}>
			<Tooltip title="dettaglio documento" placement="top">
				<IconButton onClick={onViewClick}>
					<EyeIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="scarica documento" placement="top">
				<IconButton href={docUrl} target="_blank">
					<GetAppOutlinedIcon />
				</IconButton>
			</Tooltip>
			{loading !== docUrl && (
				<Tooltip title="invia documenti tramite email" placement="top">
					<IconButton onClick={onSendClick}>
						<MailIcon />
					</IconButton>
				</Tooltip>
			)}
			{loading === docUrl && <CircularProgress size={16} />}
		</StyledTableCell>
	);
};

const Download = memo(
	({ item }) => (
		<DownloadComp
			docTypeDet={item.docTypeDet}
			refNum={item.reference}
			docUrl={item.docUrl}
			docNumber={item.docNumber}
			documentNumber={item.documentNumber}
			executionDate={item.executionDate}
			identifier={item.identifier}
			reference={item.docNum}
			status={item.statusfcd}
			urlFCD={item.urlFCD}
			realTotal={item.netAmount}
		/>
	),
	(p, n) =>
		p.item.docUrl === n.item.docUrl &&
		p.item.docNumber === n.item.docNumber &&
		p.item.docNum === n.item.docNum &&
		p.item.documentNumber === n.item.documentNumber &&
		p.item.executionDate === n.item.executionDate &&
		p.item.identifier === n.item.identifier &&
		p.item.reference === n.item.reference &&
		p.item.statusfcd === n.item.statusfcd &&
		p.item.urlFCD === n.item.urlFCD &&
		p.item.netAmount === n.item.netAmount
);

export default Download;
