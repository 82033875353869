import { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import utils from 'components/utils';

const OIDC = props => {
	const requestToken = useStoreActions(dispatch => dispatch.me.token.request);
	const {
		search: { code }
	} = props;
	useEffect(_ => {
		const codeVerifier = window.localStorage.getItem('codeVerifier');
		if (code && codeVerifier !== '') requestToken({ code, codeVerifier });
	}, []);
	return '';
};

export default utils.withParsedURL(OIDC);
