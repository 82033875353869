import React, { Fragment, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/lab/Alert';
import SearchUser from './SearchUser';
import utils from 'components/utils';
import styles from './styles';
import { Hidden } from '@mui/material';

const PersonificationSearch = ({
	classes,
	target,
	setTarget,
	strictSearch,
	setStrictSearch,
	onPersonifyCloseClick,
	resetAutocomplete,
	personify
}) => {
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const resetWishlist = useStoreActions(dispatch => dispatch.wishlist.listLight.resetList);
	const resetCart = useStoreActions(dispatch => dispatch.cart.listLight.resetList);
	const history = useHistory();
	const [error, setError] = useState(false);
	const doPersonification = _ => {
		resetAutocomplete();
		setTarget({});
		setStrictSearch(false);
		resetWishlist();
		resetCart();
		personify({ code: target.usercode, history });
	};
	return (
		<Fragment>
			<Grid item xs={12}>
				<FormGroup aria-label="position" row style={{ height: '100%', alignContent: 'flex-end' }}>
					<FormControlLabel
						checked={strictSearch}
						control={<Checkbox color="primary" onChange={e => setStrictSearch(e.target.checked)} />}
						label={
							<Typography style={{ fontSize: '18px' }}>{translate('personify.strictSearch')}</Typography>
						}
					/>
				</FormGroup>
			</Grid>
			<Grid item xs={12}>
				<SearchUser onChange={value => setTarget(value)} setError={setError} error={error} />
			</Grid>
			{target.usercode && (
				<Grid item xs={12} style={{ marginTop: 8 }}>
					{iCan('OPERATE_ON_MULTIPLE_CA') && (
						<Typography className={classes.left} variant="body1" component="p">
							<strong>{translate('personify.ca')}:</strong> {target.nameCA}
						</Typography>
					)}
					<Typography className={classes.left} variant="body1" component="p">
						<strong>{translate('personify.usercode')}:</strong> {target.usercode}
					</Typography>
					<Typography className={classes.left} variant="body1" component="p">
						<strong>{translate('personify.name')}:</strong> {target.username}
					</Typography>
					<Typography className={classes.left} variant="body1" component="p">
						<strong>{translate('personify.address')}:</strong> {utils.capitalizeAll(target.address)}
					</Typography>
				</Grid>
			)}
			<Grid item xs={12}>
				{error && (
					<Alert style={{ width: '100%', marginTop: '2%' }} variant="filled" severity="error">
						{translate('personify.error')}
					</Alert>
				)}
				<Divider style={{ margin: '20px 0' }} light />
			</Grid>
			<Grid item container xs={12}>
				<Grid item container xs={6} justify="flex-start">
					<Button
						size="large"
						onClick={doPersonification}
						disabled={!Boolean(target.usercode) || error}
						variant="contained"
						color="primary"
					>
						{translate('personify.confirm')}
					</Button>
				</Grid>
				<Hidden smDown>
					<Grid item container xs={6} justify="flex-end">
						<Button onClick={onPersonifyCloseClick} variant="outlined" color="primary" size="large">
							{translate('common.close')}
						</Button>
					</Grid>
				</Hidden>
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(PersonificationSearch);
