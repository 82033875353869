import { action, computed, actionOn, thunkOn } from 'easy-peasy';

export default {
	by: 'publicationDate',
	direction: 'desc',
	setOrder: action((state, payload) => {
		if (state.by === payload) {
			state.direction = state.direction === 'asc' ? 'desc' : 'asc';
		} else {
			state.direction = 'asc';
		}
		state.by = payload;
	}),
	setOrderNoTrigger: action((state, payload) => {
		if (state.by === payload) {
			state.direction = state.direction === 'asc' ? 'desc' : 'asc';
		} else {
			state.direction = 'asc';
		}
		state.by = payload;
	}),
	arePristine: computed(state => state.by === 'publicationDate' && state.direction === 'desc'),
	reset: action((state, payload) => {
		state.by = 'publicationDate';
		state.direction = 'desc';
	}),
	onSelectScope: actionOn(
		(actions, storeActions) => [storeActions.catalog.scopes.select],
		(state, target) => {
			const { payload } = target;
			if (payload === 'rankings') {
				state.by = 'ranking';
				state.direction = 'asc';
			}
			//Inserito per gestire la selezione "Catalogo completo" nei filtri
			if (payload === 'full') {
				state.by = 'publicationDate';
				state.direction = 'desc';
			}
		}
	),
	restoreDefaultOrderAfterSingleReset: actionOn(
		(actions, storeActions) => [storeActions.catalog.filters.resetSingle],
		(state, target) => {
			const { payload } = target;
			if (payload === 'selectedScope') {
				state.by = 'publicationDate';
				state.direction = 'desc';
			}
		}
	),
	restoreDefaultOrderAfterReset: actionOn(
		(actions, storeActions) => [storeActions.catalog.filters.reset],
		state => {
			state.by = 'publicationDate';
			state.direction = 'desc';
		}
	),
	resetOnPersonification: thunkOn(
		(actions, storeActions) => [storeActions.me.personify.sendRequest.successType],
		async (actions, target, helpers) => {
			actions.reset();
		}
	)
};
