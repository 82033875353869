import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Card from 'components/Card';
import Loader from 'components/Loader';
import utils from 'components/utils';
import TableHead from './components/Table/TableHead';
import TableBody from './components/Table/TableBody';
import SearchButton from './components/SearchButton';
import Filters from './components/Filters';
import styles from './styles';
import Drawer from '@mui/material/Drawer';
import CustomNoResult from './components/CustomNoResults';
import Chip from '@mui/material/Chip';
import { Popover, Button } from '@mui/material';

const Promotions = ({ classes }) => {
	const translate = utils.useI18N();
	const searchOpen = useStoreState(state => state.promotions.filters.searchOpen);
	const setSearchOpen = useStoreActions(dispatch => dispatch.promotions.filters.setSearchOpen);
	const loading = useStoreState(state => state.promotions.list.loading);
	const results = useStoreState(state => state.promotions.list.items);
	const load = useStoreActions(dispatch => dispatch.promotions.list.load);
	const resetToken = useStoreActions(dispatch => dispatch.promotions.filters.resetToken);
	const resetStatus = useStoreActions(dispatch => dispatch.promotions.filters.resetStatus);
	const resetStatusWishlist = useStoreActions(dispatch => dispatch.promotions.filters.resetStatusWishlist);
	const filters = useStoreState(state => state.promotions.filters.list);
	const leftGridMdWidth = searchOpen ? 8 : 12;
	const setSearchScope = useStoreActions(dispatch => dispatch.catalog.search.setScope);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = _ => {
		setAnchorEl(null);
	};
	// prettier-ignore
	useEffect(_ => {setSearchScope('admin.promotions');}, [setSearchScope]);
	useEffect(
		_ => {
			load();
		},
		[load]
	);
	return (
		<Card type="primary" title={translate('common.menu.promotions')} style={{ marginTop: 0 }}>
			<Grid container spacing={3}>
				<Grid item container xs={12}>
					<Button className={classes.buttonsMargin} variant="outlined" onClick={handleOpen}>
						{translate('promo.searchInPromo')}
					</Button>
					{filters.token !== '' && (
						<Chip
							size="small"
							label={filters.token}
							onDelete={_ => {
								resetToken();
								load();
							}}
						/>
					)}
					{filters.statusValue && (
						<Chip
							size="small"
							label={filters.statusValue ? translate('promotions.tables.status') + ' attivo' : ''}
							onDelete={_ => {
								resetStatus();
								load();
							}}
						/>
					)}
					{filters.statusWishlistValue && (
						<Chip
							size="small"
							label={
								filters.statusWishlistValue
									? translate('promotions.tables.statusWishlist') + ' attivo'
									: ''
							}
							onDelete={_ => {
								resetStatusWishlist();
								load();
							}}
						/>
					)}
				</Grid>
				<Grid item container xs={12}>
					{loading && (
						<Grid
							item
							container
							xs={12}
							justify="center"
							spacing={3}
							style={{ padding: '2px, 1px, 2px, 1px' }}
						>
							<Loader />
						</Grid>
					)}
					{/* SEARCH RESULTS */}
					{!loading && results.length > 0 && (
						<Grid item container xs={12} md={leftGridMdWidth}>
							{results.length > 0 && (
								<TableContainer component={Paper} style={{ maxHeight: 800 }}>
									<Table size="small" stickyHeader aria-label="promo table">
										<TableHead />
										<TableBody rows={results} />
									</Table>
								</TableContainer>
							)}
						</Grid>
					)}
					{/* NO RESULTS */}
					{!loading && results.length === 0 && (
						<Grid item container xs={12} md={leftGridMdWidth} justify="center">
							<CustomNoResult />
						</Grid>
					)}
					{/* DESKTOP FILTERS */}

					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						PaperProps={{ sx: { width: { xs: '350px', sm: '400px' } } }}
					>
						<Filters setAnchorEl={setAnchorEl} />
					</Popover>
				</Grid>
			</Grid>
		</Card>
	);
};

export default withStyles(styles, { withTheme: true })(Promotions);
