import React, { Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FileUploader from './FileUploader';
import utils from 'components/utils';
import { Link, useHistory } from 'react-router-dom';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	}
});

const ImportUserGroups = ({ classes, id }) => {
	const translate = utils.useI18N();
	const uploaderModalOpen = useStoreState(state => state.rankings.importt.uploaderModalOpen);
	const setUploaderModalOpen = useStoreActions(dispatch => dispatch.rankings.importt.setUploaderModalOpen);
	const history = useHistory();

	useEffect(
		_ => {
			if (
				history.location.pathname.includes('/admin/rankings/') &&
				history.location.pathname.includes('/import')
			) {
				setUploaderModalOpen(true);
			}
		},
		[history, setUploaderModalOpen]
	);
	const onClose = () => {
		setUploaderModalOpen(false);
		if (history.action === 'PUSH') history.goBack();
		else history.push(`/admin/rankings/${id}`);
	};

	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={uploaderModalOpen}
				onClose={_ => {
					onClose();
				}}
				closeAfterTransition
			>
				<div className="modal-content">
					<FileUploader id={id} onCloseModal={onClose} />
				</div>
			</Modal>
			<Button
				className={classes.buttonsMargin}
				variant="outlined"
				component={Link}
				to={`/admin/rankings/${id}/import`}
				onClick={_ => {
					setUploaderModalOpen(true);
				}}
			>
				{translate('rankings.buttons.importArticles')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(ImportUserGroups);
