import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Box } from '@mui/material';
import utils from 'components/utils';
import ProcessingOrdersInfoDetail from './ProcessingOrdersInfoDetail';
import ProcessingOrderShippings from './ProcessingOrderShippings';

const ProcessingOrdersAccordionDetails = props => {
	const { order } = props;

	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
			<Box sx={{ display: { xs: 'inherit', md: 'none', sm: 'inherit' } }}>
				<ProcessingOrdersInfoDetail order={order} />
			</Box>
			<ProcessingOrderShippings order={order} />
		</Stack>
	);
};

export default ProcessingOrdersAccordionDetails;
