import React, { useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Stack,
	Typography,
	Box,
	Button,
	Popover,
	Paper,
	Fab,
	Backdrop,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText
} from '@mui/material';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import utils from 'components/utils';
import CreateCart from './CreateCart';
import EvasionCart from './Evasion/EvasionCart';

const HeaderPage = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const openCreate = useStoreState(state => state.cart.create.openCreate);
	const setOpenCreate = useStoreActions(dispatch => dispatch.cart.create.setOpenCreate);
	const openEvasion = useStoreState(state => state.cart.evasion.openEvasion);
	const setOpenEvasion = useStoreActions(dispatch => dispatch.cart.evasion.setOpenEvasion);
	const availableDepots = utils.useFilteredDepots();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [anchorElCreate, setAnchorElCreate] = useState(null);
	const [anchorElEvasion, setAnchorElEvasion] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickCreate = event => {
		setAnchorElCreate(event.currentTarget);
		setOpenCreate(true);
	};

	const handleCloseCreate = event => {
		setAnchorElCreate(null);
		setOpenCreate(false);
	};

	const handleClickEvasion = event => {
		setAnchorElEvasion(event.currentTarget);
		setOpenEvasion(true);
	};

	const handleCloseEvasion = event => {
		setAnchorElEvasion(null);
		setOpenEvasion(false);
	};

	const buttonList = [
		{
			label: translate('common.import'),
			icon: <GetAppIcon />,
			condiction: iCan('BUY') && availableDepots.length > 0,
			href: '/import',
			onclick: null,
			type: 'light'
		},
		{
			label: translate('orders.continue'),
			icon: <CallMissedOutgoingIcon />,
			condiction: availableDepots.length > 0,
			href: '/fulfillment', //null, -> inserita pagina specifica per le evasioni
			onclick: null, // handleClickEvasion, -> inserita pagina specifica per le evasioni
			type: 'light'
		},
		{
			label: translate('orders.createCart'),
			icon: <AddIcon sx={{ color: theme.palette.custom.white }} />,
			condiction: iCan('OPERATE_ON_CARTS'),
			href: null,
			onclick: handleClickCreate,
			type: 'dark'
		}
	];

	return (
		<Fragment>
			<Stack
				direction={{ xs: 'row', md: 'row', sm: 'row' }}
				justifyContent="flex-stat"
				alignItems="center"
				spacing={1}
				variant="wrapperColor"
			>
				<Typography variant="titlePage">{translate('orders.orders')}</Typography>
				{/* Pulsanti su Desktop */}
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					spacing={1}
					sx={{ display: { xs: 'none', md: 'inherit', sm: 'none' }, width: '100%' }}
				>
					{buttonList.map(btn => (
						<Button
							key={btn.label}
							variant={btn.type}
							href={btn.href}
							onClick={btn.onclick}
							endIcon={btn.icon}
							sx={{ display: btn.condiction ? 'inherit' : 'none' }}
						>
							{btn.label}
						</Button>
					))}
				</Stack>
				{/* Pulsanti su Mobile */}
				<Box
					sx={{
						display: { xs: 'inherit', md: 'none', sm: 'inherit' },
						position: 'absolute',
						top: '10px',
						right: '10px'
					}}
				>
					<Backdrop open={open} />
					<Fab
						aria-label="Mobile function"
						onClick={handleClick}
						sx={{
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.custom.white,
							'&:hover': {
								backgroundColor: theme.palette.custom.hover
							}
						}}
						size="small"
					>
						<AddIcon />
					</Fab>
					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<Paper elevation={24}>
							<List sx={{ padding: '0' }}>
								{buttonList.map(btn => (
									<ListItemButton
										key={btn.label}
										variant="mobileList"
										href={btn.href}
										onClick={btn.onclick}
										sx={{
											display: btn.condiction ? 'flex' : 'none',
											backgroundColor:
												btn.type === 'dark'
													? theme.palette.primary.main
													: theme.palette.custom.white,
											'&:hover': {
												backgroundColor:
													btn.type === 'dark'
														? theme.palette.primary.main
														: theme.palette.custom.white
											}
										}}
									>
										<ListItemIcon>{btn.icon}</ListItemIcon>
										<ListItemText
											primary={btn.label}
											sx={{ color: btn.type == 'dark' ? theme.palette.custom.white : 'inherit' }}
										/>
									</ListItemButton>
								))}
							</List>
						</Paper>
					</Popover>
				</Box>
			</Stack>

			{/* Popover Create */}
			<Popover
				open={openCreate}
				anchorEl={anchorElCreate}
				onClose={handleCloseCreate}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<CreateCart onClose={handleCloseCreate} />
			</Popover>

			{/* Popover Evasione */}
			<Popover
				open={openEvasion}
				anchorEl={anchorElEvasion}
				onClose={handleCloseEvasion}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<EvasionCart onClose={handleCloseEvasion} />
			</Popover>
		</Fragment>
	);
};

export default HeaderPage;
