import k from 'k';
import utils from 'services/utils';

export default cart => {
	const requestOptions = {
		method: 'PUT',
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/cart/favourite/${cart}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
