import React, { useEffect, useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import {
	Stack,
	Box,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	InputBase,
	CircularProgress,
	Typography,
	Grid,
	Paper,
	IconButton,
	Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import utils from 'components/utils';
import Sortable from './Sortable';
import styles from './styles';
import TabsVariant from './Components/TabsVariant';
import OneColumnTab from './Components/OneColumnTab';
import { green, red } from '@mui/material/colors';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const UserGroupsSelector = ({
	classes,
	toggleSelectionVisualization,
	loadingListVisualization,
	getCbStatusVisualization,
	toggleSelectionAbilitation,
	loadingListAbilitation,
	getCbStatusAbilitation,
	oneColumn,
	closeModal
}) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const loading = useStoreState(state => state.userGroups.list.loadingList);
	const list = useStoreState(state => state.userGroups.list.items);
	const order = useStoreState(state => state.userGroups.list.orderDirection);
	const orderBy = useStoreState(state => state.userGroups.list.orderBy);
	const setModalOpen = useStoreActions(dispatch => dispatch.userGroups.list.setModalOpen);
	const load = useStoreActions(dispatch => dispatch.userGroups.list.load);
	const [token, setToken] = useState('');
	let rows = utils.stableSort(list, order, orderBy);
	if (token !== '') {
		rows = rows.filter(
			item =>
				item.name.toLowerCase().includes(token) ||
				(item.description ? item.description.toLowerCase().includes(token) : '')
		);
	}
	const handleClose = _ => {
		if (closeModal) closeModal();
		else setModalOpen(false);
	};

	useEffect(load, [load]);

	return (
		<Stack
			direction="column"
			justifyContent="center"
			alignItems="center"
			spacing={1}
			sx={{
				backgroundColor: theme.palette.custom.white,
				padding: '10px',
				width: { xs: '100%', md: '65%', sm: '100%' }
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					borderWidth: '0 0 1px 0',
					borderStyle: 'solid',
					borderColor: theme.palette.custom.black
				}}
			>
				<Typography variant="titleTab">{translate('admin.userGroupSelector.title')}</Typography>
				<IconButton onClick={handleClose}>
					<CloseIcon />
				</IconButton>
			</Stack>
			{loading && <CircularProgress size={30} />}
			{!loading && (
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={2}
					sx={{ width: '100%' }}
				>
					<InputBase
						variant="searchField"
						startAdornment={<SearchIcon />}
						placeholder="Cerca"
						value={token}
						onChange={e => setToken(e.target.value)}
						inputProps={{ 'aria-label': 'search' }}
						sx={{
							width: '100%',
							borderRadius: '5px',
							backgroundColor: theme.palette.custom.grey,
							borderWidth: 1,
							borderStyle: 'solid',
							borderColor: theme.palette.custom.greyborder
						}}
					/>
					<TableContainer sx={{ maxHeight: 500 }}>
						<Table stickyHeader aria-label="manage evasion" size="small">
							<TableHead>
								<TableRow>
									{!oneColumn ? (
										<Fragment>
											<TableCell>Abil.</TableCell>
											<TableCell>Visual.</TableCell>
										</Fragment>
									) : (
										<TableCell> Abil./Visual. </TableCell>
									)}
									<Sortable id="id" key="headCell_id" label="promo.tables.id" />
									<Sortable id="name" key="headCell_name" label="promo.tables.name" />
									<Sortable
										id="description"
										key="headCell_description"
										label="promo.tables.description"
									/>
									<Sortable id="type" key="headCell_type" label="promo.tables.type" />
									<Sortable id="enabled" key="headCell_enabled" label="promo.tables.Stato" />
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map(item => (
									<TableRow key={`usergroup_cell_${item.id}`}>
										{oneColumn && (
											<OneColumnTab
												toggleSelection={toggleSelectionAbilitation}
												loadingList={loadingListAbilitation}
												getCbStatus={getCbStatusAbilitation}
												item={item}
											/>
										)}
										{!oneColumn && (
											<TabsVariant
												toggleSelectionVisualization={toggleSelectionVisualization}
												loadingListVisualization={loadingListVisualization}
												getCbStatusVisualization={getCbStatusVisualization}
												toggleSelectionAbilitation={toggleSelectionAbilitation}
												loadingListAbilitation={loadingListAbilitation}
												getCbStatusAbilitation={getCbStatusAbilitation}
												item={item}
											/>
										)}
										<TableCell align="left">{item.id}</TableCell>
										<TableCell align="left">{item.name}</TableCell>
										<TableCell align="left">{item.description}</TableCell>
										<TableCell align="left">{item.type}</TableCell>
										<TableCell align="left">
											{item.enabled && (
												<CheckCircleOutlineRoundedIcon
													fontSize="small"
													style={{ color: green[300] }}
												/>
											)}
											{!item.enabled && (
												<HighlightOffRoundedIcon fontSize="small" style={{ color: red[300] }} />
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box sx={{ width: '100%', textAlign: 'right' }}>
						<Button variant="outlined" color="primary" onClick={handleClose}>
							{translate('common.close')}
						</Button>
					</Box>
				</Stack>
			)}
		</Stack>
	);
};
export default withStyles(styles, { withTheme: true })(UserGroupsSelector);
