import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Box, Accordion, AccordionDetails, AccordionSummary, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import utils from 'components/utils';
import ShippedOrdersAccordionSummary from './ShippedOrdersAccordionSummary';
import ShippedOrdersAccordionDetails from './ShippedOrdersAccordionDetails';

const ShippedOrders = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const shippedCount = useStoreState(state => state.cart.shipped.count);
	const shippedTotal = useStoreState(state => state.cart.shipped.total);
	const items = useStoreState(state => state.cart.shipped.items);
	const visibleItems = items.filter(item => item.visible);
	const [expanded, setExpanded] = useState([]);

	const title =
		translate('orders.orders') +
		' ' +
		translate('orders.orderShipped') +
		': ' +
		shippedCount +
		' ' +
		translate('orders.orders') +
		' - ' +
		utils.formatPrice(shippedTotal);
	/*
    const handleChange = index => (event, isExpanded) => {
        setExpanded(expanded.map((item, idx) => (idx === index ? isExpanded : false)));
    };
*/
	const handleChange = index => (event, isExpanded) => {
		setExpanded(isExpanded ? index : -1);
	};

	useEffect(_ => {
		setExpanded(null);
	}, []);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0.5}
			variant="wrapperSection"
			sx={{ padding: { xs: '20px 5px', md: '20px', sm: '20px 10px' } }}
		>
			<Typography variant="titleSection" sx={{ marginLeft: '20px' }}>
				{title}
			</Typography>
			<Box sx={{ width: '100%' }}>
				{visibleItems.map((item, index) => (
					<Accordion
						key={index}
						variant="listing"
						expanded={expanded == index}
						onChange={handleChange(index)}
						sx={{ borderColor: expanded == index ? theme.palette.custom.greyborder : 'none' }}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							id="panel-header"
							sx={{ flexDirection: 'row-reverse' }}
						>
							<ShippedOrdersAccordionSummary order={item} />
						</AccordionSummary>
						<AccordionDetails>
							{expanded === index && <ShippedOrdersAccordionDetails order={item} />}
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</Stack>
	);
};

export default ShippedOrders;
