import importt from './import';
import details from './details';
import list from './list';
import filters from './filters';
import search from './search';
import update from './update';
import userGroupsAbilitation from './user-groups-abilitation';
import assign from './assign';
import detailsItems from './details-items';
import detailsUserGroupsAbilitation from './details-user-groups-abilitation';
import add from './add';
import deleteCoupons from './delete-coupon';
import report from './report';

export default {
	importt,
	details,
	list,
	filters,
	search,
	update,
	userGroupsAbilitation,
	assign,
	detailsItems,
	detailsUserGroupsAbilitation,
	add,
	deleteCoupons,
	report
};
