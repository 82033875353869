import React, { Fragment, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Stack, Paper, Box, Button, Typography, Popover, LinearProgress } from '@mui/material';
import TabBar from './TabBar';
import Thema from './Thema';
import SeacrhCatalog from './SeacrhCatalog';
import Search from './Search';
import SearchByPromotion from './Search/ByPromotion';
import utils from 'components/utils';

const Catalog = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const history = useHistory();
	const selectedTab = useStoreState(state => state.catalog.thema.selectedTab);
	const setSelectedTab = useStoreActions(dispatch => dispatch.catalog.thema.setSelectedTab);
	const loadingThema = useStoreState(state => state.catalog.thema.loading);
	const results = useStoreState(state => state.catalog.search.results);

	useEffect(() => {
		setSelectedTab(0);
	}, []);

	useEffect(() => {
		//Se arrivo nella pagina dopo che è stata effettuata una ricerca devo andare in /catalog/seacrh
		if (results && results.length > 0) history.push(`/catalog/search`);
	}, []);

	return (
		<Switch>
			<Route path="/catalog" exact={true}>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={0}
					sx={{ minHeight: '100vh' }}
				>
					<Paper variant="stickyTop">
						<Stack
							direction={{ xs: 'row', md: 'row', sm: 'row' }}
							justifyContent="flex-stat"
							alignItems="flex-start"
							spacing={1}
							variant="wrapperColor"
							sx={{ width: '100%' }}
						>
							<Box sx={{ width: { xs: '70%', md: '90%', sm: '80%' } }}>
								<Typography variant="titlePage">{translate('common.menu.catalog')}</Typography>
							</Box>
						</Stack>
						<TabBar />
						{loadingThema && (selectedTab === 1 || selectedTab === 2) && (
							<Box sx={{ width: '100%', textAlign: 'center' }}>
								<LinearProgress sx={{ color: theme.palette.primary.main }} />
							</Box>
						)}
					</Paper>
					{selectedTab === 0 && <SeacrhCatalog />}
					{selectedTab === 1 && <Thema type="C" />}
					{selectedTab === 2 && <Thema type="Q" />}
				</Stack>
			</Route>
			<Route path="/catalog/search" exact={true}>
				<Search />
			</Route>
			<Route path="/catalog/search/details/:ean" exact={true}>
				<Search />
			</Route>
			<Route path="/catalog/search/by-promotion/:promoCode" exact={true}>
				<SearchByPromotion />
			</Route>
		</Switch>
	);
};

export default Catalog;
