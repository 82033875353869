import store from 'store';
import k from 'k';
import utils from 'services/utils';

const refreshToken = async () => {
	const state = store.getState();
	const refreshToken = state?.me?.data?.refreshToken;
	if (refreshToken) {
		const codeVerifier = window.localStorage.getItem('codeVerifier');
		const headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('Accept', 'application/json');
		const urlencoded = new URLSearchParams();
		urlencoded.append('client_id', 'fastbook');
		urlencoded.append('client_secret', k.clientSecret);
		urlencoded.append('grant_type', 'refresh_token');
		urlencoded.append('refresh_token', refreshToken);
		urlencoded.append('code_verifier', codeVerifier);

		const requestOptions = {
			method: 'POST',
			headers: headers,
			body: urlencoded,
			redirect: 'follow'
		};

		const response = await fetch(k.endpoints.refreshToken, requestOptions);
		if (response.status < 200 || response.status > 300) {
			return Promise.reject(utils.createError(response));
		}
		return await response.json();
	} else {
		throw new Error('Refresh token not found in local storage');
	}
};

export default refreshToken;
