import { action, actionOn, thunk, computed } from 'easy-peasy';
import utils from 'store/utils';

export default {
	viewDefaultCart: computed([utils.retrievePermission('VIEW_DEFAULT_CART')], d => d),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	loadedList: false,
	setLoadedList: action(state => {
		state.loadedList = true;
	}),
	resetList: action(state => {
		state.loadedList = false;
		state.items = [];
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.cart
			.getCartList()
			.then(data => {
				actions.setLoadedList();
				actions.save(data.items);
			})
			.catch(e => {
				getStoreActions().error('order.cartList');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	items: [],
	select: action((state, payload) => {
		state.items = state.items.map(s => ({
			...s,
			selected: s.id === payload
		}));
	}),
	save: action((state, payload) => {
		const available = payload.filter(item => item.type !== 'BOOK2C');
		const newItems = available.map(item => ({ ...item, selected: item.favourite }));
		if (state.viewDefaultCart) newItems.splice(0, 0, { id: -1, name: 'Carrello predefinito', selected: false });
		state.items = newItems;
		if (state.items.length > 0 && !state.items.some(item => item.selected)) {
			state.items[0].selected = true;
		}
	}),
	updateOnCreate: actionOn(
		(actions, storeActions) => [storeActions.cart.create.sendRequest.successType],
		(state, target) => {
			const {
				result: { item }
			} = target;
			item.selected = true;
			state.items = state.items
				.map(itm => ({
					...itm,
					selected: false
				}))
				.concat(item);
		}
	)
};
