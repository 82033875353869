import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import utils from 'components/utils';
import { Stack, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';

const Discount = props => {
	const { details } = props;
	const translate = utils.useI18N();
	const discountVisibility = utils.useUserData('discountVisibility', false);
	const discount =
		details.discount && discountVisibility && details.discount.baseDiscount1
			? details.discount.baseDiscount1.toFixed(2)
			: '';
	const displayedString =
		details.discount && !discountVisibility
			? details.discount.type === 0
				? translate('catalog.discountType.belowDiscount')
				: details.discount.type === 1
				? translate('catalog.discountType.standardDiscount')
				: details.discount.type === 2
				? translate('catalog.discountType.aboveDiscount')
				: ''
			: '';

	return (
		<Fragment>
			{details.discount && discountVisibility && details.discount.baseDiscount1 > 0 && (
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={0.5}
					sx={{ margin: '5px 0' }}
				>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						{translate('catalog.details.infoBox.discount') + ':'}
					</Typography>
					<Typography variant="body2">{discount}%</Typography>
				</Stack>
			)}
			{details.discount && !discountVisibility && details.discount.effectiveDiscount > 0 && (
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={0.5}
					sx={{ margin: '5px 0' }}
				>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						{translate('catalog.details.infoBox.discount') + ':'}
					</Typography>
					<Typography variant="text">{utils.capitalize(displayedString)}</Typography>
				</Stack>
			)}
		</Fragment>
	);
};

{
	/*
const DiscountWhenYouCanSeeItComp = props => {
	const { classes } = props;
	const details = useStoreState(state => state.catalog.details.data);
	const translate = utils.useI18N();
	const discount = details.discount.baseDiscount1 ? details.discount.baseDiscount1.toFixed(2) : '';
	return (
		<Fragment>
			{details.discount.baseDiscount1 > 0 && (
				<div className={classes.cardRows}>
					<Typography variant="subtitle2">{translate('catalog.details.infoBox.discount')}</Typography>
					<Typography variant="body2">: {discount}%</Typography>
				</div>
			)}
		</Fragment>
	);
};

const DiscountWhenYouCanSeeIt = withStyles(styles, { withTheme: true })(DiscountWhenYouCanSeeItComp);

const DiscountWhenYouCannotSeeItComp = props => {
	const { classes } = props;
	const details = useStoreState(state => state.catalog.details.data);
	const translate = utils.useI18N();
	let discountString;
	switch (details.discount.type) {
		case 0:
			discountString = translate('catalog.discountType.belowDiscount');
			break;
		case 1:
			discountString = translate('catalog.discountType.standardDiscount');
			break;
		case 2:
			discountString = translate('catalog.discountType.aboveDiscount');
			break;
		default:
			discountString = '';
	}
	const displayedString = `: ${discountString}`;
	return (
		<Fragment>
			{details.discount.effectiveDiscount > 0 && (
				<div className={classes.cardRows}>
					<Typography variant="subtitle2">{translate('catalog.details.infoBox.discount')}</Typography>
					<Typography variant="body2">{utils.capitalize(displayedString)}</Typography>
				</div>
			)}
		</Fragment>
	);
};

const DiscountWhenYouCannotSeeIt = withStyles(styles, { withTheme: true })(DiscountWhenYouCannotSeeItComp);
*/
}
export default Discount;
