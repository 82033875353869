import React, { Fragment, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import SearchBar from './components/SearchBar';
import utils from 'components/utils';

const styles = theme => ({
	root: {
		flexDirection: 'row'
	},
	treeContainer: {
		height: 500,
		overflowY: 'scroll',
		marginTop: '20px'
	}
});

const Label = ({ item }) => {
	const itemsWithChildrenSelected = useStoreState(state => state.catalog.thema.itemsWithChildrenSelected);
	const toggleSelection = useStoreActions(dispatch => dispatch.catalog.thema.toggleSelection);
	const setItemsSelected = useStoreActions(dispatch => dispatch.catalog.thema.setItemsSelected);
	const handleSelectionNode = useStoreActions(dispatch => dispatch.catalog.thema.handleSelectionNode);
	const circleColor = item.selected ? 'green' : itemsWithChildrenSelected.includes(item.code) ? '#E8BB09' : 'red';
	const countlabel = item.count && !item.hidecount ? ' (~' + item.count + ')' : '';
	const onClick = e => {
		//toggleSelection(item.code);
		//setItemsSelected();
		handleSelectionNode(item);
		e.preventDefault();
		e.stopPropagation();
	};
	return (
		<Typography>
			<CheckCircleIcon onClick={onClick} fontSize="small" style={{ paddingTop: 8, color: circleColor }} />
			{item.label.toUpperCase() + countlabel}
		</Typography>
	);
};

const Leaf = ({ item }) => <TreeItem nodeId={item.code} label={<Label item={item} />} />;

const Branch = ({ item }) => (
	<TreeItem nodeId={item.code} label={<Label item={item} />}>
		{item.children.map((item, i) => (
			<ThemaItem key={`${item.code}_${i}`} item={item} />
		))}
	</TreeItem>
);

const ThemaItem = ({ item }) => (
	<Fragment>{item.children && item.children.length > 0 ? <Branch item={item} /> : <Leaf item={item} />}</Fragment>
);

const Thema = ({ classes, type }) => {
	const translate = utils.useI18N();
	const items = useStoreState(state => state.catalog.thema.items);
	const loading = useStoreState(state => state.catalog.thema.loading);
	const load = useStoreActions(dispatch => dispatch.catalog.thema.load);
	const loadCode = useStoreActions(dispatch => dispatch.catalog.thema.loadCode);
	const token = useStoreState(state => state.catalog.themaAutocomplete.token);
	const itemsSelected = useStoreState(state => state.catalog.thema.itemsSelected);
	const resetItemsSelected = useStoreActions(dispatch => dispatch.catalog.thema.resetItemsSelected);
	const updateItemsSelected = useStoreActions(dispatch => dispatch.catalog.thema.updateItemsSelected);
	const expanded = useStoreState(state => state.catalog.thema.expanded);
	const setExpanded = useStoreActions(dispatch => dispatch.catalog.thema.setExpanded);
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const resetFilters = useStoreActions(dispatch => dispatch.catalog.filters.reset);
	const search = useStoreActions(dispatch => dispatch.catalog.search.performSearch);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const openModal = useStoreState(state => state.catalog.thema.openModal);
	const setOpenModal = useStoreActions(dispatch => dispatch.catalog.thema.setOpenModal);
	const setToken = useStoreActions(dispatch => dispatch.catalog.themaAutocomplete.setToken);
	const disabledButton = itemsSelected.length > 0 ? false : true;

	useEffect(() => {
		//Scroll top
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	useEffect(
		_ => {
			const word = null;
			load({ type, word });
		},
		[load]
	);

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds);
		//Richiamo la funzione di recupero del nodo solo se non ho fatto una selezione autocomplete
		if (nodeIds.length > expanded.length && !token) {
			loadCode(nodeIds[0]);
		}
	};

	const deselectAll = event => {
		resetItemsSelected();
	};

	const goTosearch = event => {
		resetFilters();
		setOpenModal(false); //Nel caso la ricerca sia lanciata dalla modale
		setToken('');
		setFilters({
			themaType: type,
			themaCodes: itemsSelected.toString(),
			themaRanking: true
		});
		//setOrder('publicationDate'); -> l'ordinamento per data di pubblicazione è impostato di default
		//search(); -> non chiamo search diretto,ma con setStep per impostare a 25 il numero di righe da mostrare
		setStep(25);
	};

	const closeModal = () => {
		setOpenModal(false);
		//Se ho deselezionato tutto resetto i filtri e richiamo la ricerca
		if (itemsSelected.length == 0) {
			resetFilters();
			//search(); -> non chiamo search diretto,ma con setStep per impostare a 25 il numero di righe da mostrare
			setStep(25);
		}
	};

	return (
		<Fragment>
			{/*loading && (
				<Grid item container xs={12} justify="center" spacing={3} className={classes.elements}>
					<Loader />
				</Grid>
			)*/}
			{!loading && (
				<Grid container sx={{ marginTop: '10px' }}>
					<Grid container item xs={12}>
						<Grid item xs={8}>
							<SearchBar type={type} />
						</Grid>
						<Grid item xs={4}>
							{!disabledButton && (
								<Button
									variant="dark"
									component={Link}
									to={'/catalog/search'}
									onClick={goTosearch}
									endIcon={<SearchIcon />}
									sx={{ marginLeft: '8px', height: '55px' }}
								>
									{translate('common.search.search')}
								</Button>
							)}
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: '20px' }}>
						<TreeView
							className={classes.root}
							defaultCollapseIcon={<ExpandMoreIcon />}
							defaultExpandIcon={<ChevronRightIcon />}
							onNodeToggle={handleToggle}
							expanded={expanded}
						>
							{items.map((item, i) => (
								<ThemaItem key={`${item.code}_${i}`} item={item} />
							))}
						</TreeView>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '20px' }}>
						{!disabledButton && (
							<Button onClick={deselectAll} variant="light" type="submit">
								{translate('common.deselectAll')}
							</Button>
						)}
						{openModal && (
							<Button
								onClick={_ => {
									closeModal();
								}}
								style={{ marginLeft: '10px' }}
								variant="light"
								type="submit"
							>
								{translate('common.close')}
							</Button>
						)}
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Thema);
