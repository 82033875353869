import { action, thunk } from 'easy-peasy';
import moment from 'moment';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	loadingPermissions: false,
	setLoadingPermissions: action((state, payload) => {
		state.loadingPermissions = payload;
	}),
	items: {
		SHIPPING: [],
		RETURN: []
	},
	setShippingItems: action((state, payload) => {
		state.items.SHIPPING = payload;
	}),
	setReturnItems: action((state, payload) => {
		state.items.RETURN = payload;
	}),
	data: null,
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.users
			.getById(payload)
			.then(data => actions.save(data.item))
			.catch(e => {
				getStoreActions().error('users.getDetails');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	save: action((state, payload) => {
		const lastAccessDate = moment.unix(payload.lastAccessDate).format('DD-MM-YYYY');
		const lastAccessHour = moment.unix(payload.lastAccessDate).format('HH:mm');
		state.data = {
			...payload,
			lastAccessDate: `${lastAccessDate} ore: ${lastAccessHour}`,
			abilitationStatus: payload.abilitationDesc ? `${payload.abilitationDesc} (${payload.abilitationCode})` : '',
			userProfile: payload.profileDescription ? `${payload.profileDescription} (${payload.userType})` : '',
			userCategory: payload.categoryDescription
				? `${payload.categoryDescription} (${payload.category})`
				: `(${payload.category})`,
			referentName: payload.referent ? `${payload.referent.name} (${payload.referent.code})` : '',
			availableDepots: payload.availableDepots.map(ad => ({
				...ad,
				code: ad.id,
				name: ad.description
			})),
			returnDepots: payload.returnDepots.map(rd => ({
				...rd,
				code: rd.id,
				name: rd.description
			}))
		};
	}),
	permissions: null,
	loadPermissions: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoadingPermissions(true);
		services.users
			.getPermissions(payload)
			.then(data => {
				actions.savePermissions(data);
			})
			.catch(e => {
				getStoreActions().error('user.loadPermissionError');
			})
			.finally(_ => {
				actions.setLoadingPermissions(false);
			});
	}),
	savePermissions: action((state, payload) => {
		state.permissions = payload;
	})
};
