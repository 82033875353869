export default theme => ({
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	modalPosition: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
});
