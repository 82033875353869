export default theme => ({
	root: {
		display: 'flex'
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	elements: {
		padding: `${theme.spacing(0, 1, 0, 1)} !important`
	}
});
