import React from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import utils from 'components/utils';
import styles from './styles';
import ListItemLink from './ListItemLink';
import Tooltip from '@mui/material/Tooltip';

const Orders = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const open = useStoreState(state => state.menu.mainOpen);
	return (
		<ListItemLink to="/orders/list" className={classes.listItem} button>
			{!open && (
				<Tooltip title={translate('common.menu.orders')} placement="right">
					<ListItemIcon className={classes.listIcon}>
						<AddShoppingCartIcon />
					</ListItemIcon>
				</Tooltip>
			)}
			{open && (
				<ListItemIcon className={classes.listIcon}>
					<AddShoppingCartIcon />
				</ListItemIcon>
			)}
			<ListItemText primary={translate('common.menu.orders')} />
		</ListItemLink>
	);
};

export default withStyles(styles, { withTheme: true })(Orders);
