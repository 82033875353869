import k from 'k';
import utils from 'services/utils';

export default ({ name, note }) => {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify({
			name,
			note
		}),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/wishlist`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
