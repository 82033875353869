import { action, actionOn, thunk, thunkOn, debug, computed } from 'easy-peasy';
import pick from 'lodash/pick';
import clone from 'lodash/clone';
import utils from './utils';
import storeUtils from 'store/utils';

export default {
	results: [],
	token: '',
	performed: false,
	setToken: action((state, payload) => {
		state.token = payload;
	}),
	setPerformed: action((state, payload) => {
		state.performed = payload;
	}),
	last: null,
	setLast: action((state, payload) => {
		state.last = clone(pick(payload, ['token', 'filters', 'step', 'order']));
	}),
	scope: '',
	setScope: action((state, payload) => {
		state.scope = payload;
	}),
	clear: action(state => {
		state.results = [];
		state.token = '';
		state.performed = false;
	}),
	clearTokenOnResetAll: actionOn(
		(actions, storeActions) => [storeActions.catalog.filters.reset],
		state => {
			state.token = '';
		}
	),
	clearOnFiltersReset: actionOn(
		(actions, storeActions) => [
			storeActions.catalog.filters.reset,
			storeActions.catalog.filters.resetSingle,
			storeActions.catalog.filters.resetSingleDewey
		],
		state => {
			state.results = [];
			state.performed = false;
			if (debug(state).scope !== 'catalog') state.token = '';
		}
	),
	saveResults: action((state, payload) => {
		state.results = payload.items.map(item => ({
			...item,
			depots: item.depots.filter(r => r.enable),
			isSelected: false
		}));
	}),
	resetResultsOnNewSearch: actionOn(
		actions => [actions.performSearch.startType],
		state => {
			state.results = [];
		}
	),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	searchByPromoError: false,
	setSearchByPromoError: action((state, payload) => {
		state.searchByPromoError = payload;
	}),
	...storeUtils.createPagination({ step: 25, paginationTrigger: 'saveResults', loadFn: 'performSearch' }),
	SEARCH_paginationStep: computed(
		[
			(state, storeState) => {
				if (storeState.me.personify.target.id !== '') return storeState.me.personify.target.catalogRows;
				else return storeState.me.data.catalogRows;
			}
		],
		d => d
	),
	SEARCH_setPaginationStep: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		services.me
			.updateRows(payload)
			.then(data => {
				if (getStoreState().me.personify.target.id !== '') {
					getStoreActions().me.personify.save({
						...getStoreState().me.personify.target,
						catalogRows: data
					});
				} else {
					getStoreActions().me.save({ catalogRows: data });
				}
			})
			.catch(e => console.log(e));
	}),
	SEARCH_resetCurrentPageOnStepChange: actionOn(
		(actions, storeActions) => [actions.SEARCH_setPaginationStep],
		(state, target) => {
			state.pagination.current = 1;
		}
	),
	SEARCH_loadOnPaginationCurrentChange: thunkOn(
		(actions, storeActions) => [actions.setPaginationCurrent],
		async (actions, target, helpers) => {
			//actions.performSearch(actions, null, helpers); -> tolto per evitare la doppia chiamata a search
		}
	),
	SEARCH_loadOnPaginationStepChange: thunkOn(
		(actions, storeActions) => [actions.SEARCH_setPaginationStep],
		async (actions, target, helpers) => {
			actions.performSearch({ forceStep: target.payload });
		}
	),
	performSearchListenerFilters: thunkOn(
		(actions, storeActions) => [
			storeActions.catalog.order.setOrder,
			storeActions.catalog.filters.resetSingle,
			storeActions.catalog.filters.resetSingleDewey
		],
		async (actions, target, helpers) => {
			actions.performSearch(actions, {}, helpers);
		}
	),
	performSearch: thunk(utils.search),
	searchByPromo: thunk((actions, payload, { getStoreState, getState, injections, getStoreActions }) => {
		getStoreActions().catalog.filters.reset_();
		actions.setToken('');
		getStoreActions().catalog.promotions.select(payload);
		return actions
			.performSearch(actions, payload, { getStoreState, getState, injections, getStoreActions })
			.catch(e => {
				actions.setSearchByPromoError(true);
			});
	}),
	toggleSelection: action((state, payload) => {
		const i = state.results.findIndex(itm => itm.ean === payload);
		if (i === -1) return;
		const item = state.results[i];
		item.isSelected = !item.isSelected;
		state.results = state.results
			.slice(0, i)
			.concat(item)
			.concat(state.results.slice(i + 1));
	}),
	selectItemFromUrl: action((state, payload) => {
		const i = state.results.findIndex(itm => itm.ean === payload);
		if (i === -1) return;
		const item = state.results[i];
		item.isSelected = true;
		state.results = state.results
			.slice(0, i)
			.concat(item)
			.concat(state.results.slice(i + 1));
	}),
	selectAllOrdinable: action(state => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: item.ordinable === 2
		}));
	}),
	selectAllAdvanced: action((state, payload) => {
		const { paymentTypeId, useEpipoli } = payload;
		state.results = state.results.map(item => ({
			...item,
			isSelected:
				item.ordinable === 2 &&
				!(paymentTypeId === 1 && item.type === 100) &&
				(item.type !== 200 || (item.type === 200 && useEpipoli)) &&
				item.depots.filter(d => d.enable).length > 0
		}));
	}),
	selectAll: action(state => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: true
		}));
	}),
	deselectAll: action(state => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: false
		}));
	}),
	deselectAfterClear: actionOn(
		(actions, storeActions) => [
			storeActions.selection.resetForAll,
			storeActions.selection.remove,
			storeActions.order.createMultiple.sendRequest.successType,
			storeActions.wishlist.add.sendRequest.successType
		],
		state => {
			state.results = state.results.map(item => ({
				...item,
				isSelected: false
			}));
		}
	),
	mergeStockResults: action((state, payload) => {
		state.results = state.results.map(item => ({
			...item,
			inCart: payload[item.ean] ? payload[item.ean] : 0
		}));
	}),
	incrementItemQuantity: action((state, payload) => {
		state.results = state.results?.map(item => {
			if (item.ean === payload?.ean) {
				const parsedQty = item.inCart?.split('/');
				if (parsedQty?.length > 0)
					item.inCart =
						(payload?.newQuantity
							? String(Number(parsedQty[0]) + payload.newQuantity)
							: String(Number(parsedQty[0]) + 1)) + (parsedQty[1] ? `/${parsedQty[1]}` : '');
			}
			return item;
		});
	}),
	stock: '',
	setStock: action((state, payload) => {
		state.stock = payload;
	}),
	loadingGetStock: false,
	setLoadingGetStock: action((state, payload) => {
		state.loadingGetStock = payload;
	}),
	getStock: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const listCA = getStoreState().ca.list.items;
		actions.setLoadingGetStock(true);

		services.order
			.getStockForList({
				codes: [payload], // needs array
				ca: listCA.length > 0 ? listCA.find(i => i.selected).id : null
			})
			.then(data => {
				actions.setStock(data[payload]);
			})
			.finally(() => actions.setLoadingGetStock(false));
	}),
	getStockOnSearch: thunkOn(
		(actions, storeActions) => [actions.performSearch.successType, storeActions.ca.list.select],
		async (actions, target, helpers) => {
			actions.setLoadingGetStock(true);
			// prettier-ignore
			const { getStoreState, injections: { services } } = helpers;
			const results = getStoreState().catalog.search.results;
			const listCA = getStoreState().ca.list.items;

			let itemsToSend = [];
			try {
				const {
					result: { items }
				} = target;
				itemsToSend = items;
			} catch (e) {
				itemsToSend = results;
			}
			services.order
				.getStockForList({
					codes: itemsToSend.map(itm => itm.ean),
					ca: listCA.length > 0 ? listCA.find(i => i.selected).id : null
				})
				.then(data => {
					actions.mergeStockResults(data);
				})
				.finally(_ => {
					actions.setLoadingGetStock(false);
				});
		}
	),
	resetOnPersonification: thunkOn(
		(actions, storeActions) => [storeActions.me.personify.sendRequest.successType],
		async (actions, target, helpers) => {
			actions.clear();
		}
	)
};
