import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import WlTransformUI from './components/WlTransformUI';
import utils from 'components/utils';
import styles from './styles';

const TransformButton = ({ classes, item }) => {
	const translate = utils.useI18N();
	const modalOpen = useStoreState(state => state.wishlist.transform.modalOpen);
	const data = useStoreState(state => state.wishlist.details.data);
	const setModalOpen = useStoreActions(dispatch => dispatch.wishlist.transform.setModalOpen);

	if (!data[item.id] || data[item.id].length === 0) return '';
	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={modalOpen}
				onClose={_ => {
					setModalOpen(false);
				}}
				closeAfterTransition
			>
				<WlTransformUI item={item} />
			</Modal>
			<Button variant="light" onClick={_ => setModalOpen(true)}>
				{translate('wishlist.transform')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(TransformButton);
