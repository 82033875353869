import React, { useEffect, Fragment, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from 'components/Select';

const CASelector = ({ className }) => {
	const loading = useStoreState(state => state.ca.list.loading);
	const list = useStoreState(state => state.ca.list.items);
	const select = useStoreActions(dispatch => dispatch.ca.list.select);
	const [selectedCA, setSelectedCA] = useState('');
	useEffect(
		_ => {
			if (list.length > 0) setSelectedCA(list.find(i => i.selected).id);
		},
		[list]
	);
	return (
		<Fragment>
			{!loading && list.length > 1 && (
				<Fragment>
					<Grid container item xs={8} justify="flex-end">
						<Typography variant="body2" style={{ alignSelf: 'center', marginRight: 8 }}>
							Seleziona una CA:
						</Typography>
					</Grid>
					<Grid container item xs={4} justify="flex-end">
						<FormControl variant="outlined" className={className} style={{ maxWidth: 120 }}>
							<Select value={selectedCA} onChange={event => select(event.target.value)}>
								{list.map((item, index) => (
									<MenuItem key={index} value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Fragment>
			)}
			{!loading && list.length === 1 && (
				<Typography variant="body2" style={{ marginLeft: 16, alignSelf: 'center' }}>
					CA: {list[0].name}
				</Typography>
			)}
			{!loading && list.length === 0 && (
				<Typography variant="body2" style={{ marginRight: 16, alignSelf: 'center' }}>
					Nessuna CA
				</Typography>
			)}
		</Fragment>
	);
};

export default CASelector;
