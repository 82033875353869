import { action, computed, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: [],
	returnDepots: computed(state => state.data.filter(item => item.isEnableToReturn)),
	shippingDepots: computed(state => state.data.filter(item => item.enableToShipping)),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		actions.reset();
		services.depots
			.list(payload)
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('admin.depots');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	reset: action((state, payload) => {
		state.data = [];
	}),
	save: action((state, payload) => {
		state.data = payload.items.map(item => ({
			...item,
			enableToShipping: item.isEnableToShipping || item.isEnableToDroppoint || item.isEnableToBanco
		}));
	})
};
