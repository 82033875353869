export default theme => ({
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	},
	notSticky: {
		height: 35,
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(2.5)
		}
	},
	hideButton: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			justifyContent: 'center'
		}
	},
	sticky: {
		top: 64,
		left: 57,
		borderBottom: '1px solid #e0e0e0',
		zIndex: 1101,
		position: 'fixed',
		backgroundColor: '#fafafa',
		width: `calc(100% - 57px)`,
		padding: theme.spacing(1.5, 2.5, 1.5, 3),
		boxShadow: '0px 2px 9px -2px rgba(179,179,179,0.7)',
		[theme.breakpoints.down('sm')]: {
			top: 64,
			left: theme.spacing(1),
			borderBottom: 'none',
			padding: theme.spacing(1),
			width: `calc(100% - 16px)`,
			textAlign: 'center',
			justifyContent: 'center'
		}
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(0, 3, 3, 3),
		minWidth: 350
	},
	modalLabels: {
		marginBottom: theme.spacing()
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	}
});
