import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const CustomerComp = ({ customer, customerName }) => {
	return <StyledTableCell>{`${customer} - ${customerName}`}</StyledTableCell>;
};

const Customer = memo(
	({ item }) => <CustomerComp customer={item.customer} customerName={item.customerName} />,
	(p, n) => p.item.customer === n.item.customer && p.item.customerName === n.item.customerName
);

export default Customer;
