import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const withColumns = WrappedComponent => {
	const desktopCols = ['selection', 'position', 'ean', 'author', 'title', 'serie', 'publisher', 'publicationDate'];
	const mobileCols = ['selection', 'position', 'title'];
	return props => {
		const theme = useTheme();
		let colCount = mobileCols;
		if (useMediaQuery(theme.breakpoints.up('sm'))) colCount = desktopCols;
		return <WrappedComponent {...props} visibleCols={colCount} />;
	};
};

export default withColumns;
