const getDepot = (item, depots) => {
	const { mainDepot, availableDepots } = depots;
	//Inserito anche tipo 200 => Epipoli
	if (item.type === 100 || item.type === 200) {
		return {
			id: 'FS01',
			description: 'fast hub'
		};
	}
	if (mainDepot && availableDepots.some(avDepot => avDepot.id === mainDepot.id)) {
		const mainDepotInItems = item.depots.find(d => d.id === mainDepot.id);
		mainDepot.fes = mainDepotInItems ? mainDepotInItems.fes : false;
		mainDepot.stock = mainDepotInItems ? mainDepotInItems.stock : 999;
		return mainDepot;
	}
	const itemHasDepots = item.depots.length > 0;
	const isDepotAvailable = itemHasDepots && availableDepots.some(avDepot => avDepot.id === item.depots[0].id);
	if (isDepotAvailable) return item.depots[0];
	return availableDepots[0].id;
};

const fesOnDepot = item => {
	const activeFESOnDepots = item.depots.find(d => d.fes);
	return activeFESOnDepots ? 1 : 0;
};
const getWarningFES = item => {
	const filteredFESDepots = fesOnDepot(item);
	return filteredFESDepots === 1 ? true : false;
};
export default ({ item, depots }) => {
	const warnFES = getWarningFES(item);
	return {
		...item,
		selection: {
			warningExceeding: false,
			warningFES: warnFES,
			warning1000: false,
			quantity: getDepot(item, depots).stock === 0 && warnFES === true ? 0 : 1,
			depot: getDepot(item, depots)
		}
	};
};
