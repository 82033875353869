import k from 'k';
import utils from 'services/utils';

export default selectedBudget => {
	const requestOptions = {
		method: 'PUT',
		body: JSON.stringify({ selected: selectedBudget.id }),
		headers: utils.createHeaders()
	};

	return utils
		.fetch(`${k.endpoints.apis}/me/budget/selected`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
