import keys from 'lodash/keys';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

export default (params, pick = true) => {
	const iteratee = pick ? pickBy(params, identity) : params;
	return (
		keys(iteratee)
			//.map(key => `${key}=${get(params, key)}`)
			.map(key => {
				const value = get(params, key);
				if (key === 'keyword') {
					return `${key}=${value.replace('&', '%26')}`;
				}
				return `${key}=${get(params, key)}`;
			})
			.join('&')
	);
};
