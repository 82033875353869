import k from 'k';
import utils from 'services/utils';

export default ({ filters }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = {
		page: 0,
		rows: 2000
	};
	if (filters.token) params.keyword = filters.token;
	if (filters.status) params.enabled = filters.statusValue;
	const qParams = utils.createQueryParams(params, false);
	return utils
		.fetch(`${k.endpoints.apis}/centralPurchasing/filtered?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
