import k from 'k';
import utils from 'services/utils';

export default ({ wishlistItemId, quantity, depotCode, depotDescription, notes }) => {
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify({ quantity, depotCode, depotDescription, notes }),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/wishlist/item/${wishlistItemId}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
