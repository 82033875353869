import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';
import styles from './styles';

const WishlistSumCost = ({ item }) => {
	const translate = utils.useI18N();
	return (
		<Grid container item justify="space-evenly" xs={5} sm={2} md={2} direction="column">
			<Grid item>
				<Typography variant="body2" align="right">
					<strong>{translate('wishlist.summary.quantity')}: </strong>
					{item.quantity}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body2" align="right">
					<strong>{translate('wishlist.summary.totalValue')}: </strong>
					{utils.formatPrice(item.amount)}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(WishlistSumCost);
