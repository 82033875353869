import React from 'react';
import TableBodyMUI from '@mui/material/TableBody';
import StyledTableRow from 'components/Table/StyledTableRow';
import allCols from './cols';
import utils from 'components/utils';
import withColumns from '../columns';

const TableBody = props => {
	const iCan = utils.usePermissions();
	const { rows, visibleCols } = props;
	const cells = visibleCols.map(col => allCols.find(c => c.id === col));
	const rowsWithoutOnlySpecial = rows.filter(row => row.type !== 100);
	const onlySpecialFilteredRows = iCan('SHOW_SPECIAL_FILTER') ? rows : rowsWithoutOnlySpecial;
	const getStyle = type => {
		if (type === 100) {
			return {
				backgroundColor: '#D4AF37'
			};
		}
	};

	return (
		<TableBodyMUI>
			{onlySpecialFilteredRows.map((item, index) => (
				<StyledTableRow style={getStyle(item.type)} key={`row_${index}`}>
					{cells.map(cell => cell.comp({ item }))}
				</StyledTableRow>
			))}
		</TableBodyMUI>
	);
};

export default withColumns(TableBody);
