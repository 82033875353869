import store from 'store';

export default _ => {
	const {
		me: {
			data: { bearer },
			personify: {
				target: { id }
			}
		}
	} = store.getState();
	const h = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${bearer}`
	};
	if (id) h.PersonifiedUserCode = id;
	return h;
};
