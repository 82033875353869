import React from 'react';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import utils from 'components/utils';
import styles from './styles';

const CustomNoResult = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const theme = useTheme();

	return (
		<Stack
			direction="column"
			justifyContent="center"
			alignItems="center"
			spacing={1}
			sx={{ width: '100%', margin: '20px auto !important' }}
		>
			<ReportProblemIcon sx={{ fontSize: '36px', color: theme.palette.custom.red }} />
			<Typography variant="titleSection">{translate('wishlist.noConditionRequests')}</Typography>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(CustomNoResult);
