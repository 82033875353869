import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const EanComp = ({ ean }) => {
	return <StyledTableCell>{ean}</StyledTableCell>;
};

const Ean = memo(
	({ item }) => <EanComp ean={item.ean} />,
	(p, n) => p.item.ean === n.item.ean
);

export default Ean;
