export default theme => ({
	noResults: {
		marginTop: `calc(1em + ${theme.spacing(4)}px)`,
		maxWidth: '400px'
	},
	cardMessageContent: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	}
});
