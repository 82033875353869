import React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

const HomeLink = ({ text, icon, to, isEnabled, linkStyle, boxStyle }) => {
	if (isEnabled)
		return (
			<Link to={to} className={linkStyle}>
				<CardContent className={boxStyle}>
					<Box>{icon}</Box>
					<Typography variant="h6">{text}</Typography>
				</CardContent>
			</Link>
		);
	return (
		<CardContent className={boxStyle}>
			<Box color="text.disabled">{icon}</Box>
			<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
				{text}
			</Typography>
		</CardContent>
	);
};

export default HomeLink;
