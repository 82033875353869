import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const TypeComp = ({ type }) => {
	return <StyledTableCell>{type}</StyledTableCell>;
};

const Type = memo(
	({ item }) => <TypeComp type={item.type} />,
	(p, n) => p.item.type === n.item.type
);

export default Type;
