import { alpha } from '@mui/material/styles';

export default theme => ({
	filtersRoot: {
		[theme.breakpoints.down('sm')]: {
			maxHeight: 600
		}
	},
	negateMargin: {
		marginTop: -41,
		[theme.breakpoints.down('sm')]: {
			marginTop: -20
		}
	},
	margin: {
		marginTop: 0
	},
	closeIcon: {
		padding: 0
	},
	elements: {
		padding: `${theme.spacing(1, 1, 3, 1)} !important`
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.black, 0.25)
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(0),
			width: '100%'
		}
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch'
		}
	},
	baseGrid: {
		alignContent: 'baseline'
	}
});
