import utils from 'utils';

export default theme => ({
	modalPaper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(0, 3, 3, 3),
		width: '90%',
		overflowY: 'auto',
		maxHeight: 600,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: 560
		}
	},
	buttonsMargin: {
		marginTop: theme.spacing(2)
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	title: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2)
	},
	dividerColor: {
		backgroundColor: `rgba(${utils.colors.hexToRgbString(theme.palette.primary.main)}, .62)`,
		margin: theme.spacing(2, 0, 2, 0)
	}
});
