import list from './list';
import details from './details';
import update from './update';
import deletee from './delete';
import create from './create';

export default {
	list,
	details,
	update,
	deletee,
	create
};
