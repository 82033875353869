import React from 'react';
import { useTheme } from '@mui/material/styles';
import { TableCell, Tooltip } from '@mui/material';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import utils from 'components/utils';

const Epipoli = props => {
	const { item } = props;
	const theme = useTheme();
	const translate = utils.useI18N();

	return (
		<TableCell>
			{item.type === 200 && (
				<Tooltip title={translate('catalog.details.typology.epipoli')}>
					<CardGiftcardIcon sx={{ color: theme.palette.primary.main, fontSize: '24px' }} />
				</Tooltip>
			)}
		</TableCell>
	);
};

export default Epipoli;
