import { action, thunk } from 'easy-peasy';
import { satisfies } from 'semver';

export default {
	selectedTab: '1',
	setSelectedTab: action((state, payload) => {
		state.selectedTab = payload;
	}),
	openModal: false,
	setOpenModal: action((state, payload) => {
		state.openModal = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	save: action((state, payload) => {
		state.items = payload.map(item => ({
			...item,
			selected: state.itemsSelected.includes(item.code),
			hidecount: true
		}));
	}),
	themaType: '',
	setThemaType: action((state, payload) => {
		state.themaType = payload;
	}),
	expanded: [],
	setExpanded: action((state, payload) => {
		//Eventuale nodo chiuso
		const nodeClosed = state.expanded.filter(f => !payload.includes(f));
		state.expanded = payload;
		//Se è stato chiuso un nodo chiudo anche i figli
		if (nodeClosed && nodeClosed.length > 0)
			state.expanded = state.expanded.filter(f => !f.startsWith(nodeClosed[0]));
	}),
	itemSelectedLink: [],
	setItemSelectedLink: action((state, payload) => {
		state.itemSelectedLink = payload;
	}),
	itemsDeselected: [],
	itemsReselected: [],
	toggleSelection: action((state, payload) => {
		/* Se il figlio è deselezionato il padre non viene più deselezionato
		const toggleFather = (node,value) => {
			if(node.parent){				
				//Se il figlio è stato deselezionato regitro il parent nell'elenco dei nodi da deselezionare
				if(!value)				
					state.itemsDeselected.push(node.parent.code);
				//Se il figlio è stato selezionato registro il parent nell'elenco dei nodi da selezionare
				if(value && !state.itemsSelected.includes(node.parent.code))
					state.itemsReselected.push(node.parent.code);
				toggleFather(node.parent,value);
			};
		};
		*/
		const toggleChildren = node => {
			node.children.map(c => {
				//c.selected = !c.selected; -> modificato perchè i figli sono selezionati se lo è il padre
				c.selected = node.selected;
				//Se il nodo è stato deselezionato lo registro tra i nodi deselezionati
				state.itemsDeselected = state.itemsDeselected.filter(f => f != c.code);
				if (!c.selected) {
					state.itemsDeselected.push(c.code);
					state.itemSelectedInfo = state.itemSelectedInfo.filter(f => f.code != c.code);
				}
				toggleChildren(c);
			});
			//Se il nodo è una foglia gestisco anche la gerarchia dei padri
			/* -> se deselezioni il figlio il padre resta invatiato
			if(node.children.length == 0)			
				toggleFather(node,node.selected);
			*/
		};
		const selectChildren = (item, payload) => {
			item.children.map(c => {
				if (c.code === payload) {
					c.selected = !c.selected;
					//Se il nodo è stato deselezionato lo registro tra i nodi deselezionati
					state.itemsDeselected = state.itemsDeselected.filter(f => f != c.code);
					if (!c.selected) {
						state.itemsDeselected.push(c.code);
						state.itemSelectedInfo = state.itemSelectedInfo.filter(f => f.code != c.code);
					}
					toggleChildren(c);
				} else selectChildren(c, payload);
			});
		};

		const newItems = [];
		state.items.map(item => {
			if (item.code == payload) {
				//Toggle del nodo
				item.selected = !item.selected;
				//Se il nodo è stato deselezionato lo registro tra i nodi deselezionati
				state.itemsDeselected = state.itemsDeselected.filter(f => f != item.code);
				if (!item.selected) {
					state.itemsDeselected.push(item.code);
					state.itemSelectedInfo = state.itemSelectedInfo.filter(f => f.code != item.code);
				}
				/* Selezionando il padre devono essere selezionati anche tutti i figli */
				toggleChildren(item);
			} else selectChildren(item, payload);
			newItems.push(item);
			state.items = newItems;
		});
	}),
	itemsWithChildrenSelected: [],
	setParentWithChildrenSelected: action((state, payload) => {
		const setParentChildrenSelected = item => {
			if (item.parent) {
				//Se il nodo è selezionato e il padre non lo è lo aggiungo alla lista dei nodi con figli selezionati
				if (
					state.itemsSelected.includes(item.code) &&
					!state.itemsSelected.includes(item.parent.code) &&
					!state.itemsWithChildrenSelected.includes(item.parent.code)
				)
					state.itemsWithChildrenSelected.push(item.parent.code);
				//Se il nodo non è selezionato e il padre non lo è e non ha altri figli selezionati lo tolgo dalla lista dei nodi con figli selezionati
				if (
					!state.itemsSelected.includes(item.code) &&
					!state.itemsSelected.includes(item.parent.code) &&
					state.itemsWithChildrenSelected.includes(item.code) &&
					!state.itemsWithChildrenSelected.includes(item.parent.code)
				)
					state.itemsWithChildrenSelected.push(item.parent.code);

				if (
					!state.itemsSelected.includes(item.code) &&
					!state.itemsSelected.includes(item.parent.code) &&
					!state.itemsWithChildrenSelected.includes(item.code)
				)
					state.itemsWithChildrenSelected = state.itemsWithChildrenSelected.filter(
						i => i != item.parent.code
					);
				setParentChildrenSelected(item.parent);
			}
		};
		//Se seleziono un nodo che prima era tra i nodi padre che hanno figli selezionati lo devo rimuovere
		if (state.itemsSelected.includes(payload.code) && state.itemsWithChildrenSelected.includes(payload.code))
			state.itemsWithChildrenSelected = state.itemsWithChildrenSelected.filter(i => i != payload.code);
		if (payload.parent) {
			if (
				state.itemsSelected.includes(payload.code) &&
				!state.itemsSelected.includes(payload.parent.code) &&
				!state.itemsWithChildrenSelected.includes(payload.parent.code)
			) {
				state.itemsWithChildrenSelected.push(payload.parent.code);
			}
			if (!state.itemsSelected.includes(payload.code) && !state.itemsSelected.includes(payload.parent.code)) {
				//if(payload.parent.children.filter(c => state.itemsSelected.includes(c.code)).length > 0){
				if (state.itemsSelected.filter(c => c.startsWith(payload.parent.code)).length > 0)
					state.itemsWithChildrenSelected.push(payload.parent.code);
				else
					state.itemsWithChildrenSelected = state.itemsWithChildrenSelected.filter(
						i => i != payload.parent.code
					);
			}
			setParentChildrenSelected(payload.parent);
		}
	}),
	itemSelectedInfo: [],
	itemsSelected: [],
	setItemsSelected: action((state, payload) => {
		const getSelectedChildren = item => {
			item.children.map(c => {
				/* -> non è più gestita la deselezione del padre se deseleziono il figlio 
				//Se il nodo è da riselezionare lo inserisco nei selezionati				
				if (!c.selected && state.itemsReselected.includes(c.code) && 
					!state.itemsSelected.includes(c.code) && c.children.filter(ch => ch.selected) === c.children.length)
					c.selected = true;
				*/

				if (c.selected && !state.itemsSelected.includes(c.code)) {
					state.itemsSelected.push(c.code);
					state.itemSelectedInfo.push({ code: c.code, label: c.label });
				}
				if (!c.selected && state.itemsSelected.includes(c.code)) {
					state.itemsSelected = state.itemsSelected.filter(i => i != c.code);
					state.itemSelectedInfo = state.itemSelectedInfo.filter(i => i.code != c.code);
				}
				getSelectedChildren(c);
			});
		};
		state.items.map(item => {
			/* -> non è più gestita la deselezione del padre se deseleziono il figlio
			//Se il nodo è da riselezionare lo inserisco nei selezionati
			if (!item.selected && state.itemsReselected.includes(item.code) && 
				!state.itemsSelected.includes(item.code) && item.children.filter(c => c.selected) === item.children.length)
				item.selected = true;
			*/
			if (item.selected && !state.itemsSelected.includes(item.code)) {
				state.itemsSelected.push(item.code);
				state.itemSelectedInfo.push({ code: item.code, label: item.label });
			}
			if (!item.selected && state.itemsSelected.includes(item.code)) {
				state.itemsSelected = state.itemsSelected.filter(i => i != item.code);
				state.itemSelectedInfo = state.itemSelectedInfo.filter(i => i.code != item.code);
			}
			getSelectedChildren(item);
		});
		//Reset dei nodi da deselzionare
		//state.itemsDeselected = [];
		state.itemsReselected = [];
	}),
	updateItemsSelected: action((state, payload) => {
		const updateSelectChildren = item => {
			item.children.forEach(i => {
				updateSelectChildren(i);
				i.selected = state.itemsSelected.includes(i.code); //-> modificato perchè i figli sono selezionati se il padre lo è
				//i.selected = item.selected; //imposto come il padre
				//se il nodo non risulta selezionato ma il padre lo è significa che non è ancora nell'elenco dei nodi selezionati, quindi lo inserisco, a meno che non sia stato deselezionato in precedenza o che non sia il link da scheda dettaglio
				if (
					item.code == payload &&
					state.itemsSelected.includes(item.code) &&
					!state.itemsDeselected.includes(i.code) &&
					!state.itemsSelected.includes(i.code) &&
					!state.itemSelectedLink.includes(payload) //Selezioni i figli se il nodo non è il link di scheda dettaglio
				) {
					state.itemsSelected.push(i.code);
					i.selected = true;
				}
			});
		};
		state.items.map(item => {
			updateSelectChildren(item);
			item.selected = state.itemsSelected.includes(item.code);
		});
	}),
	resetItemsSelected: action((state, payload) => {
		const resetSelectedChildren = item => {
			item.children.forEach(i => {
				i.selected = false;
				resetSelectedChildren(i);
			});
		};
		state.itemsSelected = [];
		state.items.map(item => {
			item.selected = false;
			resetSelectedChildren(item);
		});
		state.itemSelectedInfo = []; //Resetto le info dei nodi selezionati
		state.itemsWithChildrenSelected = []; //Resetto l'elenco dei nodi con figli selezionati
		state.itemSelectedLink = []; //Resetto eventuali click sui link di scheda dettaglio
	}),
	deleteItemSelected: action((state, payload) => {
		state.itemsSelected = state.itemsSelected.filter(item => item != payload);
		state.itemSelectedInfo = state.itemSelectedInfo.filter(item => item.code != payload);
		state.itemsDeselected.push(payload);
		//Verifico se il nodo che elimino ha dei figli selezionati
		if (state.itemsSelected.filter(i => i.startsWith(payload)).length > 0)
			state.itemsWithChildrenSelected.push(payload);
	}),
	addItemSelectedFromLink: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		services.catalog
			.themaCode(payload)
			.then(data => {
				getState().itemsSelected.push(data[0].code);
				getState().itemSelectedInfo.push({ code: data[0].code, label: data[0].label });
				getState().itemSelectedLink.push(payload); //Registro il click sul sul link del nodo cliccato
				actions.setParentWithChildrenSelected(data[0]);
			})
			.catch(e => {
				getStoreActions().error('catalog.themaCode');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	load: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { type, word } = payload;
		//if (getState().items.length > 0) return; -> sulla cancellazione dell'autocomplete si deve ricaricare tutto
		actions.setLoading(true);
		getState().expanded = [];
		actions.setThemaType(type);
		services.catalog
			.thema(type, word)
			.then(data => {
				actions.save(data);
				//Se ho caricato l'alberatura dalla modale dei risultati di ricerca aggiorno l'elenco dei selezionati
				if (getState().openModal) {
					actions.updateItemsSelected();
				}
			})
			.catch(e => {
				getStoreActions().error('catalog.thema');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	loadTree: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { type, value } = payload;
		actions.setLoading(true);
		services.catalog
			.thema(type, value)
			.then(data => {
				actions.save(data);
				actions.updateItemsSelected();
			})
			.catch(e => {
				getStoreActions().error('catalog.thema');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	loadCode: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		//actions.setLoading(true);
		services.catalog
			.themaCode(payload)
			.then(data => {
				actions.update(data);
				//Aggiorno i selezionati
				actions.updateItemsSelected(payload);
			})
			.catch(e => {
				getStoreActions().error('catalog.themaCode');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	handleSelectionNode: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		//Carico i nodi figli se il nodo è stato selezionato, o se sono nella modale e se non è stata fatta una ricerca tra i nodi
		if ((!payload.selected || getState().openModal) && !getStoreState().catalog.themaAutocomplete.token) {
			services.catalog
				.themaCode(payload.code)
				.then(data => {
					//Cancello eventuali click sul link di scheda dettaglio
					actions.setItemSelectedLink([]);
					//Aggiorno lo stato dei nodi
					actions.update(data);
					//Selezione nodo
					actions.toggleSelection(payload.code);
					//Aggiorno i selezionati
					actions.setItemsSelected();
					//Aggiorno la lista dei nodi non selezionati con figli selezionati
					actions.setParentWithChildrenSelected(payload);
				})
				.catch(e => {
					getStoreActions().error('catalog.themaCode');
				})
				.finally(_ => {
					actions.setLoading(false);
				});
		} else {
			actions.toggleSelection(payload.code);
			actions.setItemsSelected();
			//Aggiorno la lista dei nodi non selezionati con figli selezionati
			actions.setParentWithChildrenSelected(payload);
		}
	}),
	themaEan: '',
	loadEan: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		services.catalog
			.themaEan(payload)
			.then(data => {
				getState().themaEan = data;
			})
			.catch(e => {
				getStoreActions().error('catalog.themaEan');
			})
			.finally(_ => {});
	}),
	update: action((state, payload) => {
		if (payload.length == 0) return;
		const updateChildren = (item, node) => {
			let finded = false;
			const children = [];
			item.children.forEach(i => {
				if (i.code === node.code) {
					node.selected = i.selected;
					/* Non è detto che selezionando il padre debbano essere selezionati anche tutti i figli */

					node.children = node.children.map(c => ({
						...c,
						selected: item.selected
					}));
					children.push(node);
					finded = true;
				} else children.push(i);
			});
			item.children = children;
			if (!finded) {
				item.children.map(c => {
					updateChildren(c, node);
				});
			}
		};
		const newItems = [];
		let finded = false;
		state.items.map(item => {
			if (item.code == payload[0].code) {
				payload[0].selected = item.selected;
				payload[0].hidecount = item.hidecount;
				/* Non è detto che selezionando il padre debbano essere selezionati anche tutti i figli
				payload[0].children = payload[0].children.map(c => ({
					...c,
					selected: item.selected
				}));
				*/
				newItems.push(payload[0]);
				finded = true;
			} else newItems.push(item);
			state.items = newItems;
		});
		if (!finded) {
			state.items.map(item => {
				updateChildren(item, payload[0]);
			});
		}
	})
};
