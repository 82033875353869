import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams, Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import styles from './styles';
import NewCard from 'components7/Card';
import Card from 'components/Card';
import {
	Button,
	CircularProgress,
	Grid,
	TextField,
	FormControlLabel,
	Checkbox,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableCell,
	TableSortLabel,
	TableRow,
	InputBase,
	Icon,
	CardContent,
	Stack
} from '@mui/material';
import { DeleteForever, Search, ReportProblem, Group } from '@mui/icons-material';
import utils from 'components/utils';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import stableSort from 'components/utils/stable-sort';
import ImportUsers from './ImportUsers';
import BackToButton from 'components/BackToButton';
import { useTheme } from '@mui/material/styles';

const UserGroupsDetails = ({ classes }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const data = useStoreState(state => state.userGroups.detailsUserGroup.data);
	const users = useStoreState(state => state.userGroups.detailsUserGroup.users);
	const load = useStoreActions(dispatch => dispatch.userGroups.detailsUserGroup.load);
	const params = useParams();
	const setName = useStoreActions(dispatch => dispatch.userGroups.detailsUserGroup.setName);
	const setDescription = useStoreActions(dispatch => dispatch.userGroups.detailsUserGroup.setDescription);
	const setEnabled = useStoreActions(dispatch => dispatch.userGroups.detailsUserGroup.setEnabled);
	const update = useStoreActions(dispatch => dispatch.userGroups.updateUserGroup.sendRequest);
	const loadingUpdate = useStoreState(state => state.userGroups.updateUserGroup.loading);
	const loading = useStoreState(state => state.userGroups.detailsUserGroup.loading);
	const remove = useStoreActions(dispatch => dispatch.userGroups.detailsUserGroup.remove);
	const viewInfo = params.type == 'ASSIGNMENT';
	const order = useStoreState(state => state.userGroups.detailsUserGroup.orderDirection);
	const orderBy = useStoreState(state => state.userGroups.detailsUserGroup.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.userGroups.detailsUserGroup.setOrder);
	const allUsers = useStoreState(state => state.userGroups.detailsUserGroup.allUsers);
	const setUsers = useStoreActions(dispatch => dispatch.userGroups.detailsUserGroup.setUsers);
	const [currentPage, setCurrentPage] = useState(1);
	const pageCount = users.length % 25 === 0 ? Math.floor(users.length / 25) : Math.floor(users.length / 25) + 1;
	useEffect(
		_ => {
			if (params.id) load(params.id);
		},
		[params.id]
	);
	const [token, setToken] = useState('');
	const onClickSearch = _ => {
		if (token.length >= 3 || token === '') {
			setCurrentPage(1);
			if (viewInfo) {
				setUsers(
					allUsers.filter(
						user =>
							user.usercode.toLowerCase().includes(token) ||
							user.name.toLowerCase().includes(token) ||
							user.usercode.includes(token) ||
							user.name.includes(token)
					)
				);
			} else setUsers(allUsers.filter(user => user.toLowerCase().includes(token) || user.includes(token)));
		}
	};
	const onClickOrder = id => {
		setOrder(id);
		setUsers(stableSort(users, order, orderBy));
	};
	const onSaveClick = _ => {
		const user = {
			name: data.name,
			description: data.description,
			enabled: data.enabled
		};
		update({
			id: params.id,
			user
		});
	};
	const Sortable = ({ id, label, width }) => {
		return (
			<TableCell width={width} align="left" sortDirection={orderBy === id ? order : false}>
				<TableSortLabel
					disabled={!viewInfo}
					active={orderBy === id}
					direction={orderBy === id ? order : 'asc'}
					onClick={e => onClickOrder(id)}
				>
					{translate(label)}
				</TableSortLabel>
			</TableCell>
		);
	};
	const handleSearchOnClick = event => {
		if (event.key === 'Enter' && token.length >= 3) onClickSearch();
	};
	const onClickReset = _ => {
		setUsers(allUsers);
		setToken('');
	};
	return (
		<Fragment>
			<Card style={{ marginTop: 0 }} type="primary" title={loading ? '' : data.name}>
				{loading && (
					<Grid item container xs={12} justify="center" spacing={3} style={{ padding: '2px, 1px, 2px, 1px' }}>
						<Loader />
					</Grid>
				)}
				{!loading && (
					<Fragment>
						<Grid item container xs={12} className={classes.buttonsAlignment}>
							<Button
								disabled={!viewInfo}
								className={classes.buttonsMargin}
								component={Link}
								variant="outlined"
								to={`/admin/usergroups/${data.id}/${data.type}/assign`}
							>
								{translate('userGroups.button.assignUsers')}
							</Button>
							<ImportUsers id={params.id} disabled={!viewInfo} />
							<BackToButton
								to={`/admin/usergroups`}
								style={{ margin: '0 1 0 0' }}
								label={`backToUsergroups`}
							/>
						</Grid>
						<Grid item container xs={12}>
							{data && (
								<Stack
									direction={'column'}
									spacing={4}
									width={'100%'}
									sx={{ marginTop: theme.spacing(4) }}
								>
									<NewCard title={translate('userGroups.common.attributes')} backgroundColor="white">
										<Grid container spacing={1}>
											<Grid item container xs={12}>
												<Grid
													container
													item
													md={4}
													xs={12}
													justify="center"
													style={{ alignContent: 'flex-end' }}
												>
													<TextField
														id={`${data.id}_name`}
														value={data.name}
														onChange={e => setName(e.target.value)}
														fullWidth={false}
														label={`${translate('userGroups.common.name')} *`}
														InputLabelProps={{
															shrink: true
														}}
													/>
												</Grid>
												<Grid
													container
													item
													md={4}
													xs={12}
													justify="center"
													style={{ alignContent: 'flex-end' }}
												>
													<TextField
														id={`${data.id}_description`}
														value={data.description ? data.description : ''}
														onChange={e => setDescription(e.target.value)}
														fullWidth={false}
														label={translate('userGroups.common.description')}
														InputLabelProps={{
															shrink: true
														}}
													/>
												</Grid>
												<Grid
													container
													item
													md={4}
													xs={12}
													justify="center"
													style={{ alignContent: 'flex-end' }}
												>
													<FormControlLabel
														value={data.enabled ? data.enabled : ''}
														control={
															<Checkbox
																style={{ paddingBottom: 0 }}
																color="primary"
																size="medium"
																checked={data.enabled ? data.enabled : false}
																onChange={e => setEnabled(e.target.checked)}
															/>
														}
														label={translate('common.enabled')}
														labelPlacement="start"
														style={{ alignItems: 'flex-end' }}
													/>
												</Grid>
											</Grid>
											<Grid container item xs={12} justifyContent="flex-end">
												{loadingUpdate && <CircularProgress size={16} />}
												{!loadingUpdate && (
													<Button
														variant="contained"
														color="primary"
														onClick={onSaveClick}
														disabled={data.name === ''}
													>
														{translate('common.save')}
													</Button>
												)}
											</Grid>
										</Grid>
									</NewCard>
									<NewCard
										backgroundColor="white"
										title={`${translate('userGroups.common.assignedUsers')} (${
											data.count == '*' ? translate('userGroups.common.every') : data.count
										})`}
									>
										{allUsers.length === 0 && token === '' && (
											<Card className={classes.noResults} variant="outlined">
												<CardContent className={classes.cardMessageContent}>
													<Icon component="div" fontSize="large">
														{params.id == 1 ? (
															<Group fontSize="large" />
														) : (
															<ReportProblem color="error" fontSize="large" />
														)}
													</Icon>
													{params.id == 1
														? translate('userGroups.common.allUsers')
														: translate('userGroups.common.noUsers')}
												</CardContent>
											</Card>
										)}

										{allUsers.length > 0 && (
											<Fragment>
												<Fragment>
													<Grid container>
														<Grid item xs={12} md={6}>
															<div className={classes.search}>
																<div className={classes.searchIcon}>
																	<Search />
																</div>
																<InputBase
																	placeholder={translate('common.search.search')}
																	classes={{
																		root: classes.inputRoot,
																		input: classes.inputInput
																	}}
																	value={token}
																	onChange={e => setToken(e.target.value)}
																	inputProps={{
																		'aria-label': 'search'
																	}}
																	onKeyPress={event => handleSearchOnClick(event)}
																/>
															</div>
														</Grid>
														<Grid item container md={6} className={classes.gridSpacing}>
															<Button
																className={classes.searchButton}
																disabled={token.length < 3}
																disableElevation
																color="primary"
																variant="contained"
																onClick={_ => onClickSearch()}
															>
																{translate('common.search.search')}
															</Button>
															<Button
																className={classes.searchButton}
																disableElevation
																color="primary"
																variant="contained"
																onClick={_ => onClickReset()}
															>
																{translate('common.reset')}
															</Button>
														</Grid>
													</Grid>
												</Fragment>
												{users.length > 0 && (
													<Fragment>
														<Grid style={{ margin: '20px 0' }}>
															<Pagination
																currentPage={currentPage}
																setCurrentPage={setCurrentPage}
																step={25}
																pageCount={pageCount}
																totalItems={users.length}
																listStep={[25]}
															/>
														</Grid>
														<TableContainer>
															<Table
																stickyHeader
																aria-labelledby="items"
																size="small"
																padding="none"
																aria-label="result table"
															>
																<TableHead>
																	<TableRow style={{ height: 40 }}>
																		<Sortable
																			id="usercode"
																			key="userCode"
																			label={translate(
																				'userGroups.common.userCode'
																			)}
																			width={viewInfo ? '30%' : '100%'}
																		/>
																		{viewInfo && (
																			<Sortable
																				id="name"
																				key="businessName"
																				label={translate(
																					'userGroups.common.businessName'
																				)}
																			/>
																		)}
																		<TableCell align="left">
																			<span
																				className="MuiButtonBase-root"
																				aria-disabled="true"
																			>
																				&nbsp;
																			</span>
																		</TableCell>
																	</TableRow>
																</TableHead>

																<TableBody>
																	{users
																		.slice(
																			(currentPage - 1) * 25,
																			(currentPage - 1) * 25 + 25
																		)
																		.map((user, index) => (
																			<TableRow
																				style={{ height: 35 }}
																				key={`row_${index}`}
																			>
																				<TableCell>
																					{viewInfo ? user.usercode : user}
																				</TableCell>
																				{viewInfo && (
																					<TableCell>
																						{user.name
																							? user.name.toUpperCase()
																							: user.name}
																					</TableCell>
																				)}
																				{viewInfo && (
																					<TableCell align="right">
																						<DeleteForever
																							onClick={() =>
																								remove({
																									id: params.id,
																									user: user.usercode
																								})
																							}
																						/>
																					</TableCell>
																				)}
																			</TableRow>
																		))}
																</TableBody>
															</Table>
														</TableContainer>
													</Fragment>
												)}
												{users.length === 0 && (
													<Card className={classes.noResults} variant="outlined">
														<CardContent className={classes.cardMessageContent}>
															<Icon component="div" fontSize="large" color="error">
																<ReportProblem fontSize="large" />
															</Icon>
															{translate('common.search.noResults')}
														</CardContent>
													</Card>
												)}
											</Fragment>
										)}
									</NewCard>
								</Stack>
							)}
						</Grid>
					</Fragment>
				)}
			</Card>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(UserGroupsDetails);
