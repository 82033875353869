import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Tooltip } from '@mui/material';
import utils from 'components/utils';
import moment from 'moment';

const Validity = props => {
	const { details } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const formatDate = date => {
		return moment(date).format('L');
	};

	return (
		<Fragment>
			{details.isPromo && (
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={0.5}
					sx={{ margin: '5px 0' }}
				>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						{translate('catalog.details.infoBox.validita') + ':'}
					</Typography>
					<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
						{details.promo.map(promotion => (
							<Stack
								direction="row"
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={0.5}
								key={promotion.id}
							>
								<Tooltip title={promotion.name}>
									<Typography variant="text">
										{utils.useEllipsis(promotion.name, 30, '...')}
									</Typography>
								</Tooltip>
								<Typography variant="text" sx={{ color: theme.palette.secondary.main }}>
									{`dal ${formatDate(promotion.startDate)} al ${formatDate(promotion.endDate)}`}
								</Typography>
							</Stack>
						))}
					</Stack>
				</Stack>
			)}
		</Fragment>
	);
};

export default Validity;
