import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const GrossComp = ({ text }) => {
	return <StyledTableCell>{`${text}€`}</StyledTableCell>;
};

const Gross = memo(
	({ item }) => <GrossComp text={item.grossprui} />,
	(p, n) => p.item.grossprui === n.item.grossprui
);

export default Gross;
