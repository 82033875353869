export default theme => ({
	root: {
		display: 'flex'
	},
	icon: {
		padding: 0,
		'&:hover': {
			background: 'none'
		}
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	card: {
		maxWidth: 300,
		margin: 'auto',
		transition: '0.3s'
	},
	content: {
		textAlign: 'left',
		'&:last-child': {
			paddingBottom: 0
		},
		'& a': {
			paddingLeft: theme.spacing(1.5)
		}
	},
	iconButton: {
		paddingLeft: 0
	},
	iconsDivider: {
		margin: theme.spacing(0, 2)
	}
});
