import React, { Fragment } from 'react';
import { useStoreActions } from 'easy-peasy';
import Chip from '@mui/material/Chip';

const DeweyChips = ({ filter }) => {
	const resetSingleDewey = useStoreActions(dispatch => dispatch.catalog.filters.resetSingleDewey);
	return (
		<Fragment>
			{filter.value.map(f => (
				<Chip
					size="small"
					key={`chip.dewey.${f.id}`}
					label={f.name}
					onDelete={_ => {
						resetSingleDewey(f.id);
					}}
				/>
			))}
		</Fragment>
	);
};

export default DeweyChips;
