import React from 'react';

const withColumns = WrappedComponent => {
	return props => {
		const visibleCols = ['id', 'description', 'name', 'type'];
		return <WrappedComponent {...props} visibleCols={visibleCols} />;
	};
};

export default withColumns;
