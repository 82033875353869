import { action, computed } from 'easy-peasy';
import isEqual from 'lodash/isEqual';
import filtersInitialState from './filters-initial-state';

export default {
	list: {
		...filtersInitialState
	},
	searchOpen: false,
	arePristine: computed(state => isEqual(state.list, filtersInitialState)),
	setSearchOpen: action((state, payload) => {
		state.searchOpen = payload;
	}),
	setToken: action((state, payload) => {
		state.list.token = payload;
	}),
	setStatus: action((state, payload) => {
		state.list.status = payload;
	}),
	resetToken: action((state, payload) => {
		state.list.token = '';
	}),
	resetStatus: action((state, payload) => {
		state.list.statusValue = false;
	}),
	setStatusValue: action((state, payload) => {
		state.list.statusValue = payload;
	}),
	setStatusWishlist: action((state, payload) => {
		state.list.statusWishlist = payload;
	}),
	setStatusWishlistValue: action((state, payload) => {
		state.list.statusWishlistValue = payload;
	}),
	reset: action((state, payload) => {
		state.list = {
			...filtersInitialState
		};
	})
};
