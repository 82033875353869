import k from 'k';
import utils from 'services/utils';

export default ({ numItems, numPackages, weight, sumCost, enableService, cart }) => {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify({
			pieces: numItems,
			packages: numPackages,
			weight,
			amount: sumCost,
			flagAdhesionServiceReturn: enableService,
			cart: cart
		}),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/returns`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
