import { action, thunk, computed } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: {},
	modalOpen: false,
	setModalOpen: action((state, payload) => {
		state.modalOpen = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.catalog
			.getDetails(payload)
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('catalog.detail');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	availableDepots: computed(
		[
			(state, storeState) => {
				if (storeState.me.personify.target.id !== '') return storeState.me.personify.target.availableDepots;
				else return storeState.me.data.availableDepots;
			}
		],
		d => d
	),
	save: action((state, payload) => {
		const { item } = payload;
		state.data = {
			...item,
			depots: item.depots.map(d => {
				const depot = state.availableDepots.find(avD => avD.id === d.id);
				const depotToMerge = depot ? depot : {};
				return {
					...depotToMerge,
					...d,
					warningExceeding: false,
					warningFES: d.fes,
					warning1000: false,
					quantity: 1
				};
			})
		};
	}),
	selectQuantity: action((state, payload) => {
		const { id, quantity } = payload;
		const target = state.data.depots.find(itm => itm.id === id);
		if (!target) return;
		const warning1000 = quantity >= 1000;
		const realQt = Math.min(quantity, 999);
		if (target.fes) {
			target.warningExceeding = false;
			target.warningFES = true;
			target.warning1000 = warning1000;
			target.quantity = Math.min(target.stock, realQt);
		} else {
			target.warningFES = false;
			target.warning1000 = warning1000;
			target.warningExceeding = realQt > target.stock;
			target.quantity = realQt;
		}
	})
};
