import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const WithdrawalServiceComp = ({ flagAdhesionServiceReturn }) => {
	const translate = utils.useI18N();
	const displayedValue = flagAdhesionServiceReturn ? translate('common.yes') : translate('common.no');
	return <StyledTableCell>{displayedValue}</StyledTableCell>;
};

const WithdrawalService = memo(
	({ item }) => <WithdrawalServiceComp flagAdhesionServiceReturn={item.flagAdhesionServiceReturn} />,
	(p, n) => p.item.flagAdhesionServiceReturn === n.item.flagAdhesionServiceReturn
);

export default WithdrawalService;
