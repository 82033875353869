import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const DiscountComp = ({ text }) => {
	return <StyledTableCell>{text + '%'}</StyledTableCell>;
};

const Discount = memo(
	({ item }) => <DiscountComp text={item} />,
	(p, n) => p.item === n.item
);

export default Discount;
