import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel } from '@mui/material';
import utils from 'components/utils';
import headCells from './headCells';

const OpenOrdersItemsTableHead = props => {
	const { cart } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const user = utils.useUserData('', null);
	const personify = useStoreState(state => state.me.personify);
	const items = useStoreState(state => state.cart.open.items);
	const selectAll = useStoreActions(dispatch => dispatch.cart.open.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.cart.open.deselectAll);
	const orderItems = items
		.filter(i => i.idForSelection == cart.idForSelection)[0]
		.orderItems.filter(el => el.deletable);
	const itemSelected = orderItems.filter(i => i.isSelected);
	const order = useStoreState(state => state.cart.open.orderDirection);
	const orderBy = useStoreState(state => state.cart.open.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.cart.open.setOrder);
	const isBook2C = cart.ordertype === 'BOOK2C';
	const checkboxEnabled =
		(!isBook2C && personify.target.id !== '') || (personify.target.id === '' && user.userType !== '02');

	const onSelectAll = event => {
		if (event.target.checked) selectAll(cart.id);
		else deselectAll(cart.id);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell sx={{ background: theme.palette.custom.greytable, fontWeight: '600', padding: '0 0 0 4px' }}>
					{checkboxEnabled && !isBook2C && orderItems.length > 0 && (
						<Checkbox
							indeterminate={itemSelected.length > 0 && itemSelected.length < orderItems.length}
							checked={itemSelected.length > 0 && orderItems.length === itemSelected.length}
							onChange={onSelectAll}
							inputProps={{ 'aria-label': 'select all items' }}
						/>
					)}
					{(!checkboxEnabled || isBook2C || orderItems.length == 0) && <span>&nbsp;</span>}
				</TableCell>
				<TableCell sx={{ background: theme.palette.custom.greytable, padding: '0 0 0 4px' }}>&nbsp;</TableCell>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align="left"
						sortDirection={orderBy === headCell.id ? order : false}
						style={headCell.sx}
						sx={{ background: theme.palette.custom.greytable, fontWeight: '600', padding: '0 0 0 4px' }}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={e => setOrder(headCell.id)}
							sx={{ lineHeight: '18px' }}
						>
							{headCell.label && translate(headCell.label)}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default OpenOrdersItemsTableHead;
