import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { Card, CircularProgress, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import utils from 'components/utils';
import CASelector from 'components/CASelector';
import HistoryTable from './HistoryTable';

const OrderHistory = ({ classes }) => {
	const iCan = utils.usePermissions();
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.order.singleHistory.loading);
	const data = useStoreState(state => state.order.singleHistory.data);
	const load = useStoreActions(dispatch => dispatch.order.singleHistory.load);
	const listCA = useStoreState(state => state.ca.list.items);
	const params = useParams();
	const isMultipleCA = iCan('OPERATE_ON_MULTIPLE_CA');
	const [expanded, setExpanded] = useState(false);

	const onAccordionChange = (event, isExpanded) => {
		setExpanded(isExpanded);
	};

	useEffect(() => {
		if (expanded && params.ean) {
			if (isMultipleCA) {
				if (listCA && listCA.length > 0) {
					load({
						ean: params.ean,
						ca: listCA.find(i => i.selected).id
					});
				}
			} else {
				load({ ean: params.ean });
			}
		}
	}, [load, params, listCA, expanded, isMultipleCA]);
	return (
		<Card sx={{ marginTop: '16px', width: '100%' }}>
			<Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={onAccordionChange}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						{translate('catalog.details.orderHistory')}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box display="block" width={1}>
						{isMultipleCA && (
							<Box display="flex" mb={2} ml="auto" width={400}>
								<CASelector />
							</Box>
						)}
						<Box display="block" textAlign="center">
							{loading && <CircularProgress size={24} />}
							{!loading && data && data[params.ean]?.length > 0 && (
								<HistoryTable data={data[params.ean]} />
							)}
							{!loading && (!data || !data[params.ean] || data[params.ean].length === 0) && (
								<Typography variant="text" sx={{ fontWeight: '600' }}>
									{translate('orders.noHistory')}
								</Typography>
							)}
						</Box>
					</Box>
				</AccordionDetails>
			</Accordion>
		</Card>
	);
};

export default OrderHistory;
