import React from 'react';
import { useStoreState } from 'easy-peasy';
import StyledTableCell from 'components/Table/StyledTableCell';

const Depot = ({ item }) => {
	const selectedDepot = useStoreState(state => state.importt.depot);
	const depot = item.depots.find(d => d.id === selectedDepot);
	return <StyledTableCell>{depot.description}</StyledTableCell>;
};

export default Depot;
