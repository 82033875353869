import { action, computed, thunk, thunkOn } from 'easy-peasy';
import groupBy from 'lodash/groupBy';
const paymentOnlineEnabled = process.env.REACT_APP_PAYMENTONLINE_ENABLED;

export default {
	modalOpen: false,
	setEvasionModalOpen: action((state, payload) => {
		if (payload) state.showEvasionButton = true;
		state.modalOpen = payload;
	}),
	showEvasionButton: true,
	setShowEvasionButton: action((state, payload) => {
		state.showEvasionButton = payload;
	}),
	openEvasion: false,
	setOpenEvasion: action((state, payload) => {
		state.openEvasion = payload;
	}),
	stepEvasion: -1,
	setStepEvasion: action((state, payload) => {
		state.stepEvasion = payload;
	}),
	incrementStepEvasion: action((state, payload) => {
		state.stepEvasion += payload;
	}),
	paymentType: -1,
	setPaymentType: action((state, payload) => {
		state.paymentType = payload;
	}),
	paymentCond: -1,
	setPaymentCond: action((state, payload) => {
		state.paymentCond = payload;
	}),
	paymentMethod: -1,
	setPaymentMethod: action((state, payload) => {
		state.paymentMethod = payload;
	}),
	mainDepot: computed(
		[
			(state, storeState) => {
				if (storeState.me.personify.target.id !== '') return storeState.me.personify.target.mainDepot;
				if (state.caUsers.length > 0) {
					const selected = state.caUsers.find(i => i.selected);
					if (!selected) return storeState.me.data.mainDepot;
					return selected.mainDepot;
				}
				return storeState.me.data.mainDepot;
			}
		],
		d => d
	),
	availableDepots: computed(
		[
			(state, storeState) => {
				if (storeState.me.personify.target.id !== '') return storeState.me.personify.target.availableDepots;
				if (state.caUsers.length > 0) {
					const selected = state.caUsers.find(i => i.selected);
					if (!selected) return storeState.me.data.availableDepots;
					return selected.availableDepots;
				}
				return storeState.me.data.availableDepots;
			}
		],
		d => d
	),
	items: [],
	setItems: action((state, payload) => {
		state.items = state.computedItems.map(item => ({
			...item,
			selected: false
		}));
	}),
	computedItems: computed(
		[
			(state, storeState) => {
				let items = storeState.cart.open.items.filter(item => item.resumeOrder.sumCostProcessable > 0);
				if (state.caUsers.length > 0) {
					const selected = state.caUsers.find(i => i.selected);
					if (!selected) return items;
					const selectedUserId = selected.id;
					items = items.filter(item => item.orderItems[0].realUserCode === selectedUserId);
				}
				return items;
			}
		],
		results => results
	),
	toggleSelection: action((state, payload) => {
		const item = state.items.find(item => item.idForSelection === payload);
		if (!item) return;
		item.selected = !item.selected;
	}),
	selectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			selected: true
		}));
	}),
	deselectAll: action(state => {
		state.items = state.items.map(item => ({
			...item,
			selected: false
		}));
	}),
	computedDepots: computed(state => {
		let list = [];
		let grouped = [];
		const carts = state.items.filter(item => item.selected);
		carts.forEach(items => {
			list = [...list, ...items.orderItems];
			grouped = groupBy(list, item => item.depot);
		});
		const deposit = [];
		Object.values(grouped).forEach(items => {
			const agg = items.map(item => item.price * item.quantityEvadible).reduce((a, b) => a + b, 0);
			const obj = {
				total: agg,
				name: items[0].depotDesc,
				id: items[0].depot
			};
			deposit.push(obj);
		});
		return deposit;
	}),
	depots: [],
	setDepots: action((state, payload) => {
		const d = state.computedDepots.map(item => ({
			...item,
			deliveries: [],
			deliveriesIsLoaded: false,
			loadingDeliveries: false,
			responseMessage: null,
			selected: false,
			showDeliveries: false,
			showSelectionCb: true
		}));
		const mainDepotIndex = state.mainDepot ? d.findIndex(d => d.name === state.mainDepot.description) : -1;
		const orderedDepots =
			mainDepotIndex !== -1
				? [d[mainDepotIndex], ...d.slice(0, mainDepotIndex).concat(d.slice(mainDepotIndex + 1))]
				: d;
		const depotIdsAvailable = state.availableDepots.map(item => item.id);
		const visibleDepots = orderedDepots.filter(d => depotIdsAvailable.includes(d.id) && d.total !== 0);
		state.depots = visibleDepots;
	}),
	toggleSelectionDepot: action((state, payload) => {
		const item = state.depots[payload];
		if (!item) return;
		item.showDeliveries = !item.showDeliveries;
		item.selected = !item.selected;
	}),
	userPaymentType: null,
	userPaymentAssigned: thunkOn(
		(actions, storeActions) => [storeActions.cart.open.save],
		async (actions, target, helpers) => {
			const [userPayment] = Object.keys(helpers.getStoreState().cart.open.userPaymentAssigned);
			helpers.getStoreState().cart.evasion.userPaymentType = userPayment
				? helpers.getStoreState().cart.open.userPaymentAssigned[userPayment].userPayment.code
				: '';
		}
	),
	saveDeliveryList: action((state, payload) => {
		const { id, items } = payload;
		const selectedDepot = state.depots.find(d => d.id === id);
		if (!selectedDepot) return;
		const allDeliveries = items.map(item => ({
			...item,
			shippingType: item.shippingRule.desc
		}));
		const filteredDeliveries = allDeliveries.filter(item =>
			state.userPaymentType === '8' //contrassegno
				? item.shippingType !== 'Drop point'
				: state.userPaymentType === '1' //contanti
				? item.shippingType !== 'Drop point' && item.shippingType !== 'Spedizione'
				: item
		);
		const depotFilterDeliveries = filteredDeliveries.filter(item =>
			state.mainDepot.id === 'FS01' ? item.shippingType !== 'Drop point' : item
		);
		selectedDepot.deliveries = depotFilterDeliveries.map((item, index) => ({
			...item,
			selected: index === 0
		}));
		selectedDepot.deliveriesIsLoaded = true;
	}),
	setDeliveryListLoading: action((state, payload) => {
		const { id, status } = payload;
		const selectedDepot = state.depots.find(d => d.id === id);
		if (!selectedDepot) return;
		selectedDepot.loadingDeliveries = status;
	}),
	selectDelivery: action((state, payload) => {
		const { depotId, deliveryId } = payload;
		const selectedDepot = state.depots.find(d => d.id === depotId);
		if (!selectedDepot) return;
		selectedDepot.deliveries = selectedDepot.deliveries.map(d => ({
			...d,
			selected: d.id === deliveryId
		}));
	}),
	totalEvadable: computed(state => state.depots.reduce((acc, cur) => acc + cur.total, 0)),
	total: computed(state => state.items.reduce((acc, cur) => acc + cur.resumeOrder.sumCostProcessable, 0)),
	getDelivery: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setDeliveryListLoading({ id: payload, status: true });
		services.cart
			.getDeliveryList(payload)
			.then(data => {
				actions.saveDeliveryList({ id: payload, items: data.items });
			})
			.catch(_ => {
				getStoreActions().error('order.deliveryList');
			})
			.finally(_ => {
				actions.setDeliveryListLoading({ id: payload, status: false });
			});
	}),
	loadingEvasion: false,
	setLoadingEvasion: action((state, payload) => {
		state.loadingEvasion = payload;
	}),
	payloadFulfillment: null,
	setPayloadFulfillment: action((state, payload) => {
		state.payloadFulfillment = payload;
	}),
	sended: null,
	setSended: action((state, payload) => {
		state.sended = payload;
	}),
	parseEvasionResponseErrors: action((state, payload) => {
		const { items } = payload;
		state.depots = state.depots.map(d => {
			const errorItem = items.find(item => item.depotCode === d.id && item.status === 'KO');
			return {
				...d,
				showDeliveries: false,
				showSelectionCb: false,
				responseMessage: errorItem ? errorItem.detail : null
			};
		});
	}),
	checkEvasion: thunk((actions, payload, { injections, getStoreActions, getState }) => {
		const { services } = injections;
		actions.setLoadingEvasion(true);
		actions.setPaymentMethod(-1);
		actions.setSended(false); //Reset dell'invio evasione. Se sto facendo il check è una nuova evasione
		getStoreActions().stripe.setRefunded(null); //Reset del flag di refunding. Se sto facendo il check è una nuova evasione
		services.cart
			.checkEvasion(payload)
			.then(data => {
				const hasErrors = data.items.some(item => item.status === 'KO');
				if (hasErrors) {
					actions.setShowEvasionButton(false);
					actions.parseEvasionResponseErrors(data);
				} else {
					//actions.sendEvasion(payload);
					//Registro condizioni e tipo pagamento
					const response_pyamentCond = data.items[0].userPaymentCond ? data.items[0].userPaymentCond : -1;
					const response_paymentType = data.items[0].userPaymentType ? data.items[0].userPaymentType : -1;
					actions.setPaymentCond(response_pyamentCond);
					actions.setPaymentType(response_paymentType);
					//Incremento lo step per passare alla selezione del metodo di pagamento o al riepilogo
					//Se il pagamento online è disabilitato salto la schermata
					//se l'utente non ha le condizioni "pagamento anciticipato". In questo caso deve essere visualizzato un errore
					if (response_pyamentCond === '1' && response_paymentType === 'H') return;
					actions.incrementStepEvasion(
						paymentOnlineEnabled == 'true' &&
							response_pyamentCond === '1' &&
							(response_paymentType === '1' || response_paymentType === '8')
							? 1
							: 2
					);
				}
				// return Promise.resolve(data);
			})
			.catch(e => {
				console.log(e);
				actions.setEvasionModalOpen(false);
				getStoreActions().error('cart.sendEvasion');
			})
			.finally(_ => {
				actions.setLoadingEvasion(false);
			});
	}),
	sendEvasion: thunk((actions, payload, { injections, getStoreActions, getState }) => {
		const { services } = injections;
		actions.setLoadingEvasion(true);
		//Imposto il metodo di pagamento per recuperarlo nella pagina dell'esito
		actions.setPaymentMethod(payload && payload.paymentType ? Number(payload.paymentType) : -1);
		services.cart
			.sendEvasion(payload)
			.then(data => {
				const hasErrors = data.items.some(item => item.status === 'KO');
				console.log('sendEvasion hasErrors', hasErrors);
				if (hasErrors) {
					//actions.setShowEvasionButton(false); Non serve più gestire modale e pulsante
					actions.parseEvasionResponseErrors(data);
					actions.setSended('KO');
					//Se c'è stato un refunding mostro l'errore (non è stata salvata l'evasione su db)
					const hasRefund = data.items.some(item => item.refunding);
					const hasRefundExecute = data.items.some(item => item.refundingExecute);
					console.log('sendEvasion hasRefund, hasRefundExecute', hasRefund, hasRefundExecute);
					if (hasRefund && hasRefundExecute) getStoreActions().stripe.setRefunded(true);
					if (hasRefund && !hasRefundExecute) getStoreActions().stripe.setRefunded(false);
				} else {
					/* Non serve più gestire modale e pulsante
					actions.setEvasionModalOpen(false);
					actions.setOpenEvasion(false); //Chiudo la modale dell'evasione
					actions.setShowEvasionButton(false); //Nascondo "continua" anche se l'evasione è ok
					*/
					//getStoreActions().cart.open.load();
					//getStoreActions().cart.processing.load();

					actions.setSended('OK'); //Evasione inviata
					//getStoreActions().success('cart.sendEvasion');
				}
				return Promise.resolve(data);
			})
			.catch(e => {
				console.log(e);
				actions.setEvasionModalOpen(false);
				actions.setSended('KO'); //Evasione non inviata
				//getStoreActions().error('cart.sendEvasion');
			})
			.finally(_ => {
				actions.setLoadingEvasion(false);
			});
	}),
	reset: action(state => {
		state.items = [];
		state.depots = [];
	}),
	caUsers: [],
	resetCAUsers: action((state, payload) => {
		state.caUsers = [];
	}),
	setCAUsers: action((state, payload) => {
		state.caUsers = payload.map(item => ({
			...item,
			selected: false
		}));
	}),
	selectCA: action((state, payload) => {
		state.caUsers = state.caUsers.map(item => ({
			...item,
			selected: item.id === payload ? !item.selected : false
		}));
	}),
	getCAUsers: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.resetCAUsers();
		services.cart
			.getCAUsers(payload)
			.then(data => actions.setCAUsers(data.items))
			.catch(e => {
				getStoreActions().error('cart.retrieve');
			});
	})
};
