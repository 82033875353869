import { action, thunk, computed, actionOn } from 'easy-peasy';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import utils from './utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	detailsModalOpen: false,
	setDetailsModalOpen: action((state, payload) => {
		state.detailsModalOpen = payload;
	}),
	trackingModalOpen: false,
	setTrackingModalOpen: action((state, payload) => {
		state.trackingModalOpen = payload;
	}),
	openFromURl: true,
	setOpenFromUrl: action((state, payload) => {
		state.openFromURl = payload;
	}),
	selectedDetails: null,
	setSelectedDetails: action((state, payload) => {
		state.selectedDetails = payload;
		state.detailsModalOpen = true;
	}),
	selectedTrackingDetails: null,
	setSelectedTrackingDetails: action((state, payload) => {
		state.selectedTrackingDetails = payload;
		state.trackingModalOpen = true;
	}),
	selectedDate: moment().subtract(4, 'weeks'),
	setSelectedDate: action((state, payload) => {
		state.selectedDate = payload;
	}),
	items: [],
	count: computed(state => state.items.reduce((acc, cur) => acc + utils.getTotalItemsInCart(cur), 0)),
	total: computed(state =>
		state.items
			.map(i1 =>
				i1.orderItems
					.filter(i2 => i2.visible)
					.map(i3 => i3.netPrice * i3.quantityRequired)
					.reduce((a, b) => a + b, 0)
			)
			.reduce((a, b) => a + b, 0)
	),
	load: thunk(utils.getShipped),
	save: action((state, payload) => {
		state.items = payload.items.map(item => ({
			...item,
			visible: true,
			isDefault: item.id === null && item.purchaseNumber === '',
			isSAP: item.name !== '' && item.id === null,
			orderItems: item.orderItems.map(orderItem => ({
				...orderItem,
				visible: true,
				date: moment(orderItem.date, 'DD-MM-YYYY hh:mm:ss')
			}))
		}));
	}),
	filters: computed([(state, storeState) => storeState.cart.search.filters], f => f),
	search: actionOn(
		(actions, storeActions) => [storeActions.cart.search.performSearch],
		(state, target) => {
			const originalItems = cloneDeep(state.items);
			state.items = utils.searchShipped(originalItems, state.filters);
		}
	),
	resetSearch: actionOn(
		(actions, storeActions) => [storeActions.cart.search.resetSearch],
		(state, target) => {
			state.items = utils.resetSearch(state.items);
		}
	),
	orderBy: 'shippingType',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	resetOnPersonification: actionOn(
		(actions, storeActions) => [storeActions.me.personify.sendRequest.successType],
		(state, target) => {
			state.loading = false;
			state.items = [];
			state.orderBy = 'shippingType';
			state.orderDirection = 'desc';
		}
	)
};
