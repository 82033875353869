import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

const styles = theme => ({});

const Delivering = props => {
	return <Grid container></Grid>;
};

export default withStyles(styles, { withTheme: true })(Delivering);
