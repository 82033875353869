import React from 'react';
import { withStyles } from '@mui/styles';
import CardBody from './CardBody';
import { cardStyles } from './style.js';

const HeadlessCard = props => {
	const { classes, children, style, bodyStyle } = props;
	return (
		<div className={classes.card} style={style}>
			<CardBody bodyStyle={bodyStyle}>{children}</CardBody>
		</div>
	);
};

export default withStyles(cardStyles, { withTheme: true })(HeadlessCard);
