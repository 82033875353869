import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const NewsComp = ({ newsItem }) => {
	return <StyledTableCell>{utils.capitalize(newsItem)}</StyledTableCell>;
};

const NewsItem = memo(
	({ item }) => <NewsComp newsItem={item.newsItem} />,
	(p, n) => p.item.newsItem === n.item.newsItem
);

export default NewsItem;
