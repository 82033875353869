import { action, actionOn, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	modalOpen: false,
	setModalOpen: action((state, payload) => {
		state.modalOpen = payload;
	}),
	anchorCartEl: null,
	setAnchorCartEl: action((state, payload) => {
		state.anchorCartEl = payload;
	}),
	closeCreateCartPopoverOnSave: actionOn(
		(actions, storeActions) => [storeActions.cart.create.sendRequest.successType],
		(state, target) => {
			state.anchorCartEl = null;
		}
	),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.wishlist
			.transform(payload)
			.then(data => {
				getStoreActions().success('wishlist.transform');
				actions.setModalOpen(false);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('wishlist.transform');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
