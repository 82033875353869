import k from 'k';
import utils from 'services/utils';

export default ({ userCode, permission, value }) => {
	const requestOptions = {
		method: value ? 'PATCH' : 'DELETE',
		headers: utils.createHeaders()
	};
	//console.log("************* AGGIORNO " + permission + " DI " + userCode + " CON VALUE " + value ,requestOptions);
	return utils
		.fetch(`${k.endpoints.apis}/permission/users/${userCode}/${permission}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
