import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { InputLabel, OutlinedInput, Select, MenuItem, FormControl, TextField, ListItemText } from '@mui/material';
import utils from 'components/utils';
import { useTheme } from '@mui/material/styles';

const DepotSelector = ({ item }) => {
	const theme = useTheme();
	const currentDetail = useStoreState(state => state.returns.currentDetail);
	const setCurrentDetailDepot = useStoreActions(dispatch => dispatch.returns.setCurrentDetailDepot);
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const showDropdown = iCan('MANAGE_RETURNS') && currentDetail.type === 'pending' && item.availableDepots.length > 0;
	let depotLabel = translate('catalog.noDepot');
	if (currentDetail.type === 'accepted' && item.returnedDepot) depotLabel = item.returnedDepot.depotDesc;
	if (currentDetail.type === 'refused' && item.depot) depotLabel = item.depot.depotDesc;

	return (
		<Fragment>
			{showDropdown && (
				<FormControl style={{ width: '100%' }}>
					<InputLabel
						sx={{
							fontFamily: ['"Poppins", sans-serif'].join(','),
							fontSize: '14px',
							marginTop: '-5px'
						}}
					>
						{translate('catalog.depot')}
					</InputLabel>
					<Select
						value={item.depot.depotCode}
						onChange={event => setCurrentDetailDepot(event.target.value)}
						sx={{
							height: '40px',
							fontFamily: ['"Poppins", sans-serif'].join(','),
							fontSize: '14px',
							fontWeight: '400',
							backgroundColor: theme.palette.custom.white,
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: item.depot.depotCode.length > 0 ? theme.palette.primary.main : '#cccdce'
						}}
						input={<OutlinedInput label={translate('catalog.depot')} />}
					>
						{item.availableDepots.map((item, index) => (
							<MenuItem key={`filter_promotion_${item.depotCode}`} value={item.depotCode}>
								<ListItemText primary={item.depotDesc} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			{!showDropdown && (
				<TextField disabled label={translate('catalog.depot')} size={'small'} value={depotLabel || ''} />
			)}
		</Fragment>
	);
};

export default DepotSelector;
