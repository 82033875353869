import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Stack,
	IconButton,
	TextField,
	Button,
	MenuItem,
	FormControl,
	Box,
	Typography,
	FormControlLabel,
	Grid,
	Switch
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Select from 'components/Select';
import utils from 'components/utils';

const Filters = ({ onClose }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const filters = useStoreState(state => state.conditionRequest.filters.list);
	const promotions = useStoreState(state => state.conditionRequest.filters.promotions);
	const setIntoCart = useStoreActions(dispatch => dispatch.conditionRequest.filters.setIntoCart);
	const inCartChecked = useStoreState(state => state.conditionRequest.filters.inCartChecked);
	const setInCartChecked = useStoreActions(dispatch => dispatch.conditionRequest.filters.setInCartChecked);
	const notInCartChecked = useStoreState(state => state.conditionRequest.filters.notInCartChecked);
	const setNotInCartChecked = useStoreActions(dispatch => dispatch.conditionRequest.filters.setNotInCartChecked);
	const setUserId = useStoreActions(dispatch => dispatch.conditionRequest.filters.setUserId);
	const selectPromotion = useStoreActions(dispatch => dispatch.conditionRequest.filters.selectPromotion);
	const loadPromotions = useStoreActions(dispatch => dispatch.conditionRequest.filters.loadPromotions);
	const reset = useStoreActions(dispatch => dispatch.conditionRequest.filters.reset);
	const search = useStoreActions(dispatch => dispatch.conditionRequest.filters.performSearch);
	const disabledButton =
		filters.promoId === '_empty_' && filters.userId === '' && !inCartChecked && !notInCartChecked;

	// prettier-ignore
	useEffect(_ => { 
		loadPromotions() 
	}, [loadPromotions]);

	useEffect(() => {
		if (inCartChecked && notInCartChecked) setIntoCart('');
		else if (inCartChecked && !notInCartChecked) setIntoCart(true);
		else if (!inCartChecked && notInCartChecked) setIntoCart(false);
	}, [inCartChecked, notInCartChecked]);

	const handleClose = event => {
		onClose(event);
	};

	const handleReset = () => {
		reset();
		setInCartChecked(true);
		setNotInCartChecked(true);
	};

	const handleSearch = () => {
		search();
		handleClose();
	};

	const handleInCartChecked = event => {
		setInCartChecked(event.target.checked);
	};
	const handleNotInCartChecked = event => {
		setNotInCartChecked(event.target.checked);
	};

	console.log('prima di return filters.promoId ', filters.promoId);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={2}
			sx={{
				width: '100%',
				maxWidth: { xs: '340px', md: '540px', sm: '540px' },
				minWidth: { xs: '340px', md: '450px', sm: '450px' },
				padding: theme.spacing(2)
			}}
		>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={2}
				sx={{
					width: '100%',
					borderStyle: 'solid',
					borderWidth: '0 0 1px 0',
					borderColor: theme.palette.primary.main
				}}
			>
				<Box sx={{ width: '90%' }}>
					<Typography
						variant="titleSection"
						sx={{ color: theme.palette.primary.main, textTransform: 'capitalize' }}
					>
						{translate('common.search.search')}
					</Typography>
				</Box>
				<Box sx={{ width: '10%' }}>
					<IconButton onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</Stack>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={3}
				sx={{ width: '100%' }}
			>
				<TextField
					size="small"
					value={filters.userId}
					onChange={e => setUserId(e.target.value)}
					label="Codice cliente"
					sx={{ width: '100%' }}
				/>
				<FormControl variant="outlined" sx={{ width: '100%' }}>
					<Select
						value={filters.promoId}
						displayEmpty
						onChange={event => selectPromotion(event.target.value)}
					>
						{promotions.map((item, index) => (
							<MenuItem key={item.sapPromoId} value={item.sapPromoId}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Grid container>
					<Grid item sm={6} xs={12}>
						<FormControlLabel
							label={translate('wishlist.transformedIntoCart')}
							control={
								<Switch
									checked={inCartChecked}
									onChange={handleInCartChecked}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<FormControlLabel
							label={translate('wishlist.transformedNotIntoCart')}
							control={
								<Switch
									checked={notInCartChecked}
									onChange={handleNotInCartChecked}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							}
						/>
					</Grid>
				</Grid>
			</Stack>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={1}
				sx={{ width: '100%', marginTop: '40px !important' }}
			>
				<Button disabled={disabledButton} variant="dark" endIcon={<SearchIcon />} onClick={handleSearch}>
					{translate('common.search.search')}
				</Button>
				<Button variant="light" endIcon={<RestartAltIcon />} onClick={handleReset}>
					reset
				</Button>
				<Button
					variant="light"
					endIcon={<CloseIcon />}
					onClick={onClose}
					sx={{ marginLeft: { xs: '30px !important', md: '80px !important', sm: '70px !important' } }}
				>
					{translate('common.close')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default Filters;
