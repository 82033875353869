import React from 'react';
import TextField from './TextField';
import Checkbox from './Checkbox';

const Id = props => <TextField {...props} path="id" />;
const UserType = props => <TextField {...props} path="userProfile" />;
const Category = props => <TextField {...props} path="userCategory" />;
const BankCredit = props => <TextField {...props} path="bankCredit" />;
const ShowDiscount = props => <Checkbox {...props} path="discountVisibility" />;
const OneClick = props => <Checkbox {...props} path="flagOneClick" />;
const EnableStatus = props => <TextField {...props} path="abilitationDesc" />;
const CommercialEmail = props => <TextField {...props} path="email" />;
const AdministrativeEmail = props => <TextField {...props} path="administrativeEmail" />;
const Phone = props => <TextField {...props} path="phone" />;
const Fax = props => <TextField {...props} path="fax" />;
const Mobile = props => <TextField {...props} path="mobile" />;
const CompanyName = props => <TextField {...props} path="displayName" />;
const VatID = props => <TextField {...props} path="vatCode" />;
const InvoiceCode = props => <TextField {...props} path="invoiceCode" />;
const TransferType = props => <TextField {...props} path="transferType" />;
const Address = props => <TextField {...props} path="address.street" />;
const City = props => <TextField {...props} path="address.city" />;
const PostalCode = props => <TextField {...props} path="address.postalCode" />;
const County = props => <TextField {...props} path="address.county" />;
const Country = props => <TextField {...props} path="address.country" />;
const Password = props => <TextField {...props} path="password" />;
const MainDepot = props => <TextField {...props} path="mainDepot.name" />;
const Referral = props => <TextField {...props} path="referentName" />;
const LastAccessDate = props => <TextField {...props} path="lastAccessDate" />;
const Epipoli = props => <Checkbox {...props} path="epipoli" />;

export {
	Id,
	UserType,
	Category,
	BankCredit,
	CommercialEmail,
	AdministrativeEmail,
	Phone,
	Fax,
	Mobile,
	CompanyName,
	VatID,
	InvoiceCode,
	TransferType,
	Address,
	City,
	PostalCode,
	County,
	Country,
	Password,
	MainDepot,
	ShowDiscount,
	OneClick,
	EnableStatus,
	Referral,
	LastAccessDate,
	Epipoli
};
