import React, { Fragment } from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { Stack, Grid, Box } from '@mui/material';
import Checkbox from './Checkbox';
import ScopeSelector from './ScopeSelector';
import PromotionSelector from './PromotionSelector';
import { DatePickerFrom, DatePickerTo } from './DatePickers';
import styles from './styles';
import Autocomplete from './Autocomplete';
import Dewey from './Dewey';
import utils from 'components/utils';
import TextField from './TextField';

const FilterUI = ({ classes, variant }) => {
	const iCan = utils.usePermissions();
	// const translate = utils.useI18N();
	const stateFilters = useStoreState(state => state.catalog.filters.list);
	const cbSize = variant === 'compact' ? 6 : 3;
	const dw = iCan('FILTER_DEWEY') ? 1 : 0;
	const bncf = iCan('FILTER_BNCF') ? 2 : 0;
	const size = [6, 3, 3, 2][dw + bncf];

	//In caso di ricerca thema-tica non mostro il pulsante Dewey
	const showDewey = !stateFilters.themaRanking;

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={3}
			sx={{ width: '100%' }}
		>
			<ScopeSelector cbSize={cbSize} />
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'column' }}
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={{ xs: 3, md: 1, sm: 3 }}
				sx={{ width: '100%' }}
			>
				<Stack
					direction={{ xs: 'column', md: 'row', sm: 'row' }}
					justifyContent="flex-start"
					alignItems="center"
					spacing={{ xs: 3, md: 1, sm: 3 }}
					sx={{ width: { xs: '100%', md: '50%', sm: '100%' } }}
				>
					<Autocomplete path="author" />
					<TextField path="volume" />
				</Stack>
				<Stack
					direction={{ xs: 'column', md: 'row', sm: 'row' }}
					justifyContent="flex-start"
					alignItems="center"
					spacing={{ xs: 3, md: 1, sm: 3 }}
					sx={{ width: { xs: '100%', md: '50%', sm: '100%' } }}
				>
					<Autocomplete path="publisher" />
					<Autocomplete path="serie" />
				</Stack>
			</Stack>
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'row' }}
				justifyContent="flex-start"
				alignItems="center"
				spacing={{ xs: 3, md: 1, sm: 3 }}
				sx={{ width: '100%' }}
			>
				<Box sx={{ width: { xs: '100%', md: '75%', sm: '50%' } }}>
					<Autocomplete path="synopsis" />
				</Box>
				<Box sx={{ width: { xs: '100%', md: '25%', sm: '50%' } }}>
					<Autocomplete path="illustratore" />
				</Box>
			</Stack>
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'row' }}
				justifyContent="flex-start"
				alignItems="center"
				spacing={3}
				sx={{ width: '100%' }}
			>
				<Box sx={{ width: { xs: '100%', md: '25%', sm: '50%' } }}>
					<DatePickerFrom />
				</Box>
				<Box sx={{ width: { xs: '100%', md: '25%', sm: '50%' } }}>
					<DatePickerTo />
				</Box>
			</Stack>
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'column' }}
				justifyContent="flex-start"
				alignItems="center"
				spacing={{ xs: 3, md: 2, sm: 3 }}
				sx={{ width: '100%' }}
			>
				<Stack
					direction={{ xs: 'column', md: 'row', sm: 'row' }}
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					sx={{ width: { xs: '100%', md: '50%', sm: '100%' } }}
				>
					<Autocomplete path="genre" />
					<PromotionSelector />
				</Stack>
				<Stack
					direction={{ xs: 'column', md: 'row', sm: 'row' }}
					justifyContent="flex-start"
					alignItems="center"
					spacing={{ xs: 3, md: 2, sm: 2 }}
					sx={{ width: { xs: '100%', md: '50%', sm: '100%' } }}
				>
					{iCan('FILTER_BNCF') && (
						<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
							<Autocomplete path="bncf" />
						</Box>
					)}
					{iCan('FILTER_DEWEY') && showDewey && <Dewey />}
				</Stack>
			</Stack>
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'column' }}
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={2}
				sx={{ width: '100%' }}
			>
				<Stack
					direction={{ xs: 'column', md: 'row', sm: 'row' }}
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={2}
					sx={{ width: { xs: '100%', md: '50%', sm: '100%' } }}
				>
					<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
						<Checkbox path="excludeOutOfList" />
					</Box>
					<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
						<Checkbox path="onlyNew" />
					</Box>
				</Stack>
				<Stack
					direction={{ xs: 'column', md: 'row', sm: 'row' }}
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={2}
					sx={{ width: { xs: '100%', md: '50%', sm: '100%' } }}
				>
					{iCan('SHOW_SPECIAL_FILTER') && (
						<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
							<Checkbox path="onlySpecial" />
						</Box>
					)}
					<Box sx={{ width: { xs: '100%', md: '50%', sm: '50%' } }}>
						<Checkbox path="allWords" />
					</Box>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(FilterUI);
