import React from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import Title from './Title';
import Author from './Author';
import Serie from './Serie';
import Publisher from './Publisher';
import PublicationDate from './PublicationDate';
import Selection from './Selection';
import Position from './Position';

const allCells = [
	{
		id: 'selection',
		comp: ({ item }) => <Selection key={`bodyCell_selection_${item.userCode}`} item={item} />
	},
	{
		id: 'position',
		comp: ({ item }) => <Position key={`bodyCell_position_${item.userCode}`} item={item} />
	},
	{
		id: 'ean',
		comp: ({ item }) => (
			<StyledTableCell key={`bodyCell_ean_${item.userCode}`} component="th" scope="row">
				{item.userCode}
			</StyledTableCell>
		)
	},
	{
		id: 'author',
		comp: ({ item }) => <Author key={`bodyCell_author_${item.userCode}`} item={item} />
	},
	{
		id: 'title',
		comp: ({ item }) => <Title key={`bodyCell_title_${item.userCode}`} item={item} />
	},
	{
		id: 'serie',
		comp: ({ item }) => <Serie key={`bodyCell_serie_${item.userCode}`} item={item} />
	},
	{
		id: 'publisher',
		comp: ({ item }) => <Publisher key={`bodyCell_publisher_${item.userCode}`} item={item} />
	},
	{
		id: 'publicationDate',
		comp: ({ item }) => <PublicationDate key={`bodyCell_publicationDate_${item.userCode}`} item={item} width={93} />
	}
];

export default allCells;
