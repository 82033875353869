import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FileUploaderPdf from './FileUploaderPdf';
import utils from 'components/utils';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	}
});

const ImportPDF = ({ classes, id }) => {
	const translate = utils.useI18N();
	const uploaderPdfModalOpen = useStoreState(state => state.coupons.importt.uploaderPdf);
	const setUploaderPdfModalOpen = useStoreActions(dispatch => dispatch.coupons.importt.setUploaderPdf);
	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={uploaderPdfModalOpen}
				onClose={_ => {
					setUploaderPdfModalOpen(false);
				}}
				closeAfterTransition
			>
				<div className="modal-content">
					<Fragment>
						<FileUploaderPdf id={id} />
					</Fragment>
				</div>
			</Modal>
			<Button
				className={classes.buttonsMargin}
				variant="outlined"
				onClick={_ => {
					setUploaderPdfModalOpen(true);
				}}
			>
				{translate('coupons.buttons.importPdf')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(ImportPDF);
