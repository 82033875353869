import { action, computed, thunk } from 'easy-peasy';

export default {
	items: [],
	save: action((state, payload) => {
		state.items = payload.map((s, i) => ({ ...s, selected: i === 0 }));
	}),
	select: action((state, payload) => {
		state.items = state.items.map(s => ({
			...s,
			selected: s.id === payload
		}));
	}),
	CAMembers: computed(state => {
		const selected = state.items.find(item => item.selected);
		return selected ? selected.usersCentralPurchasing : [];
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		if (getStoreState().ca.list.items.length > 0) return Promise.resolve(getStoreState().ca.list.items);
		actions.setLoading(true);
		return services.ca
			.getList()
			.then(data => {
				actions.save(data.items);
				return Promise.resolve(data);
			})
			.catch(e => {})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
