import { action, thunk } from 'easy-peasy';

export default {
	loadingList: [],
	addToLoadingList: action((state, payload) => {
		state.loadingList.push(payload);
	}),
	removeFromLoadingList: action((state, payload) => {
		const i = state.loadingList.indexOf(payload);
		if (i === -1) return;
		state.loadingList = state.loadingList.slice(0, i).concat(state.loadingList.slice(i + 1));
		state.loadingList.push(payload);
	}),
	add: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.addToLoadingList(payload);
		const { id, userGroupId } = payload;
		return services.coupons
			.addUserGroupAbilitation({ id, userGroupId })
			.then(data => {
				getStoreActions().success('coupons.addUserGroup');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('coupons.errorAddUserGroup');
			})
			.finally(_ => {
				actions.removeFromLoadingList(payload);
			});
	}),
	remove: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.addToLoadingList(payload);
		const { id, userGroupId } = payload;
		return services.coupons
			.removeUserGroupAbilitation({ id, userGroupId })
			.then(data => {
				getStoreActions().success('coupons.removeUserGroup');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('coupons.removeUserGroup');
			})
			.finally(_ => {
				actions.removeFromLoadingList(payload);
			});
	})
};
