import { action, thunk } from 'easy-peasy';

export default {
	open: false,
	setOpen: action((state, payload) => {
		state.open = payload;
	}),
	dateFrom: null,
	dateTo: null,
	loading: false,
	saveDateFrom: action((state, payload) => {
		state.dateFrom = payload;
	}),
	saveDateTo: action((state, payload) => {
		state.dateTo = payload;
	}),
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.purchasings
			.createPurchasing(payload)
			.then(data => {
				getStoreActions().success('purchasings.add');
				getStoreActions().purchasings.list.load();
				getStoreActions().purchasings.add.setOpen(false);
				return Promise.resolve(data.items);
			})
			.catch(e => {
				getStoreActions().error('purchasings.errorAdd');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
