import getImages from './get-images';
import uploadImage from './upload-image';
import getUserInfo from './get-user-info';
import updateCondictions from './update-condictions';

export default {
	getImages,
	uploadImage,
	getUserInfo,
	updateCondictions
};
