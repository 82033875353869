import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Box,
	Tooltip,
	IconButton,
	Popover,
	List,
	ListItemButton,
	ListItemText,
	ListItemIcon,
	Paper,
	Typography,
	CircularProgress
} from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import utils from 'components/utils';

const ShippedOrdersItemsDownload = props => {
	const { item } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const exportPdf = useStoreActions(dispatch => dispatch.order.exportt.shippedOrderPdf);
	const exportXls = useStoreActions(dispatch => dispatch.order.exportt.shippedOrderXls);
	const loadingExportPdf = useStoreState(state => state.order.exportt.loadingPdf);
	const loadingExportXls = useStoreState(state => state.order.exportt.loadingXls);
	const prevLoadPdf = utils.usePrevious(loadingExportPdf);
	const prevLoadXls = utils.usePrevious(loadingExportXls);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	//Chiusura pop up export dopo il dowbload
	useEffect(
		_ => {
			if (prevLoadPdf && !loadingExportPdf) setAnchorEl(null);
		},
		[loadingExportPdf, prevLoadPdf]
	);
	useEffect(
		_ => {
			if (prevLoadXls && !loadingExportXls) setAnchorEl(null);
		},
		[loadingExportXls, prevLoadXls]
	);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onPdfClick = event => {
		exportPdf({ item });
		event.preventDefault();
	};
	const onXlsClick = event => {
		exportXls({ item });
		event.preventDefault();
	};

	return (
		<Box>
			<Tooltip
				variant="light"
				arrow
				followCursor
				placement="top"
				title={<Typography variant="tooltip">{translate('catalog.downloadResults')}</Typography>}
			>
				<IconButton sx={{ color: theme.palette.primary.main }} onClick={handleClick}>
					<DownloadForOfflineIcon sx={{ fontSize: '1.2em' }} />
				</IconButton>
			</Tooltip>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
			>
				<Paper elevation={24}>
					<List sx={{ padding: '0' }}>
						<ListItemButton variant="mobileList" onClick={onPdfClick}>
							{loadingExportPdf && <CircularProgress size={14} sx={{ marginRight: '40px !important' }} />}
							{!loadingExportPdf && (
								<ListItemIcon>
									<PictureAsPdfIcon />
								</ListItemIcon>
							)}
							<ListItemText primary={translate('catalog.pdfFormat')} />
						</ListItemButton>
						<ListItemButton variant="mobileList" onClick={onXlsClick}>
							{loadingExportXls && <CircularProgress size={14} sx={{ marginRight: '40px !important' }} />}
							{!loadingExportXls && (
								<ListItemIcon>
									<BackupTableIcon />
								</ListItemIcon>
							)}
							<ListItemText>{translate('catalog.xlsFormat')}</ListItemText>
						</ListItemButton>
					</List>
				</Paper>
			</Popover>
		</Box>
	);
};

export default ShippedOrdersItemsDownload;
