import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import utils from 'components/utils';
import ProcessigOrdersAccordionSummary from './ProcessigOrdersAccordionSummary';
import ProcessingOrdersAccordionDetails from './ProcessingOrdersAccordionDetails';

const ProcessingOrders = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const processingCount = useStoreState(state => state.cart.processing.count);
	const processingTotal = useStoreState(state => state.cart.processing.total);
	const items = useStoreState(state => state.cart.processing.items);
	const expanded = useStoreState(state => state.cart.processing.expanded);
	const setExpanded = useStoreActions(dispatch => dispatch.cart.processing.setExpanded);
	const visibleItems = items.filter(item => item.visible);

	const title =
		translate('orders.orders') +
		' ' +
		translate('orders.orderProcessing') +
		': ' +
		processingCount +
		' ' +
		translate('orders.orders') +
		' - ' +
		utils.formatPrice(processingTotal);

	const handleChange = index => (event, isExpanded) => {
		setExpanded(isExpanded ? index : -1);
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0.5}
			variant="wrapperSection"
			sx={{ padding: { xs: '20px 5px', md: '20px', sm: '20px 10px' } }}
		>
			<Typography variant="titleSection" sx={{ marginLeft: '20px' }}>
				{title}
			</Typography>
			<Box sx={{ width: '100%' }}>
				{visibleItems.map((item, index) => (
					<Accordion
						key={index}
						variant="listing"
						expanded={expanded == index}
						onChange={handleChange(index)}
						sx={{ borderColor: expanded == index ? theme.palette.custom.greyborder : 'none' }}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							id="panel-header"
							sx={{ flexDirection: 'row-reverse' }}
						>
							<ProcessigOrdersAccordionSummary order={item} />
						</AccordionSummary>
						<AccordionDetails>
							{expanded == index && <ProcessingOrdersAccordionDetails order={item} />}
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</Stack>
	);
};

export default ProcessingOrders;
