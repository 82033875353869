import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import Alert from '@mui/lab/Alert';
import Search from '.';

const ByPromotion = props => {
	const searchByPromo = useStoreActions(dispatch => dispatch.catalog.search.searchByPromo);
	//const setSearchByPromoError = useStoreActions(dispatch => dispatch.catalog.search.setSearchByPromoError);
	const error = useStoreState(state => state.catalog.search.searchByPromoError);
	const params = useParams();
	useEffect(
		_ => {
			if (params.promoCode) searchByPromo(params.promoCode);
		},
		[params.promoCode]
	);

	/* -> tolto perchè manda in loop la pagina perchè c'è searchByPromo nell'array degli elementi e quindi riesegue searchByPromo in loop
	useEffect(
		_ => {
			if (params.promoCode) searchByPromo(params.promoCode);
		},
		[searchByPromo, params]
	);
	useEffect(
		_ => {
			setSearchByPromoError(false);
		},
		[setSearchByPromoError]
	);
	*/

	return (
		<Fragment>
			{error && (
				<Alert severity="error" variant="filled" style={{ marginTop: 16 }}>
					Non sei abilitato a visualizzare questa promozione
				</Alert>
			)}
			{!error && <Search />}
		</Fragment>
	);
};

export default ByPromotion;
