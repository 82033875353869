export default theme => ({
	themaIcon: {
		color: '#ff0000',
		padding: '0px 10px'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalPaper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '65%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			maxHeight: 560,
			overflowY: 'auto',
			padding: '30px 10px'
		}
	},
	filterLink: {
		height: '35px',
		lineHeight: '35px',
		display: 'block',
		textAlign: 'right'
	},
	wrapperChips: {
		'& > *': {
			margin: theme.spacing(0.5)
		}
	}
});
