import React, { Fragment } from 'react';
import { useStoreState } from 'easy-peasy';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import { SliderArrowBack, SliderArrowForward } from './components/SliderArrow';
import { CtaDetails } from './components/CtaDetails';
import styles from './styles';

const Carousel = ({ classes, autoplay, dots, arrows }) => {
	const loadingSlide = useStoreState(state => state.cms.promotionSlide.loading);
	const slides = useStoreState(state => state.cms.promotionSlide.data);
	const settings = {
		autoplay: autoplay,
		dots: dots,
		prevArrow: arrows && <SliderArrowBack className="slick-prev" />,
		nextArrow: arrows && <SliderArrowForward className="slick-next" />,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};
	const slidesStyle = {
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		height: 'inherit',
		paddingTop: '49%'
	};
	const footerStyle = {
		height: 100,
		alignContent: 'center',
		padding: 20
	};
	return (
		<Fragment>
			{!loadingSlide && slides && (
				<Grid item xs={12} className={classes.container}>
					<Slider {...settings}>
						{slides.map((slide, index) => (
							<Grid container key={`slick-slide_${index}`}>
								<Grid item xs={12}>
									<Card style={{ maxWidth: '100%' }}>
										<CardMedia style={{ ...slidesStyle }} image={slide.image.url} />
									</Card>
								</Grid>
								<Grid
									container
									style={{ ...footerStyle, backgroundColor: `${slide.textBackgroundColor.hex}` }}
								>
									<Grid item xs={9}>
										<Typography
											variant="h6"
											className={classes.footerText}
											dangerouslySetInnerHTML={{ __html: slide.text }}
											style={{ color: '#00529b', paddingTop: 2 }}
										></Typography>
									</Grid>
									<Grid item container xs={3} justify="flex-end" style={{ alignContent: 'center' }}>
										{slide.ctaEnabled && <CtaDetails slide={slide} />}
									</Grid>
								</Grid>
							</Grid>
						))}
					</Slider>
				</Grid>
			)}
		</Fragment>
	);
};
export default withStyles(styles, { withTheme: true })(Carousel);
