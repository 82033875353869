import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import parse from 'html-react-parser';
import { Select, MenuItem, Typography, Checkbox, Stack, Box } from '@mui/material';
import SelectDelivery from './SelectDelivery';
import utils from 'components/utils';

const SelectDepots = props => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const stateDepots = useStoreState(state => state.cart.evasion.depots);
	const setDepots = useStoreActions(dispatch => dispatch.cart.evasion.setDepots);
	const toggleSelectionDepot = useStoreActions(dispatch => dispatch.cart.evasion.toggleSelectionDepot);
	const totalEvadable = useStoreState(state => state.cart.evasion.totalEvadable);
	const paymentTypeId = utils.useUserData('paymentTypeId', [], false);
	const [depots, setDepotsLocal] = useState(null);
	const [disabled, setDisabled] = useState(false);

	const selectDepot = (item, index) => {
		toggleSelectionDepot(index);
	};

	useEffect(_ => {
		setDepots();
	}, []);

	useEffect(
		_ => {
			if (!stateDepots) return;
			const ds = paymentTypeId === 1 ? stateDepots.filter(d => d.id !== 'FS01') : stateDepots;
			setDepotsLocal(ds);
			setDisabled(ds.every(d => d.deliveriesIsLoaded && d.deliveries.length === 0));
		},
		[stateDepots, paymentTypeId]
	);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			<Box sx={{ width: '100%', padding: '10px 0 0 16px !important' }}>
				<Typography variant="text" sx={{ fontWeight: '600' }}>
					{translate('orders.evasion.totalEvadible') + ': ' + utils.formatPrice(totalEvadable)}
				</Typography>
			</Box>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{ width: '100%', padding: '5px !important' }}
			>
				<Box sx={{ width: '50%', textAlign: 'left' }}>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						{translate('orders.filters.depot')}
					</Typography>
				</Box>
				<Box sx={{ width: '50%', textAlign: 'right' }}>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						{translate('orders.evasion.deliveryMode')}
					</Typography>
				</Box>
			</Stack>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{ width: '100%', padding: '5px !important' }}
			>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={4}
					sx={{ width: '100%' }}
				>
					{depots &&
						depots.map((item, index) => (
							<Stack
								key={index}
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
								spacing={2}
								sx={{ width: '100%' }}
							>
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={0}
									sx={{ width: '50%' }}
								>
									<Checkbox
										checked={item.showDeliveries}
										disabled={disabled}
										onChange={_ => selectDepot(item, index)}
										inputProps={{ 'aria-labelledby': item.id }}
										sx={{ display: item.showSelectionCb ? 'inherit' : 'none' }}
									/>
									<Stack
										direction="column"
										justifyContent="flex-start"
										alignItems="flex-start"
										spacing={0}
									>
										<Typography
											variant="text"
											sx={{ fontWeight: '600', textTransform: 'capitalize' }}
										>
											{item.name}
										</Typography>
										<Typography variant="text">
											{translate('orders.processable') + ': ' + utils.formatPrice(item.total)}
										</Typography>
									</Stack>
								</Stack>
								<Stack
									direction="row"
									justifyContent="flex-end"
									alignItems="flex-end"
									spacing={0}
									sx={{ width: '50%' }}
								>
									{item.showDeliveries && <SelectDelivery depot={item} />}
									{item.responseMessage && (
										<Typography variant="text" sx={{ color: theme.palette.custom.orange }}>
											{parse(item.responseMessage)}
										</Typography>
									)}
								</Stack>
							</Stack>
						))}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default SelectDepots;
