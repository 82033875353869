import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';
import styles from './styles';
import { useStoreState } from 'easy-peasy';
import { CircularProgress } from '@mui/material';

const InCart = props => {
	const { classes, inCart } = props;
	const loadingGetStock = useStoreState(state => state.catalog.search.loadingGetStock);
	const translate = utils.useI18N();
	return (
		<div className={classes.cardRows}>
			<Typography variant="text" sx={{ fontWeight: '600' }}>
				{translate('catalog.tableHeaders.cart')}
			</Typography>
			<Typography variant="text">: {loadingGetStock ? <CircularProgress /> : inCart}</Typography>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(InCart);
