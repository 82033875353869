import k from 'k';
import utils from 'services/utils';

export default id => {
	const requestOptions = {
		method: 'DELETE',
		headers: utils.createHeaders()
	};
	return utils.fetch(`${k.endpoints.apis}/user/budgets/${id}`, requestOptions);
};
