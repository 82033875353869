const headCells = [
	/*{
		id: 'catalog',
		label: 'catalog.tableHeaders.ean'
	},
    */
	{
		id: 'author',
		label: 'catalog.tableHeaders.author'
	},
	{
		id: 'epipoli',
		label: ''
	},
	{
		id: 'title',
		label: 'catalog.tableHeaders.title',
		sx: { width: '250px' }
	},
	/*
	{
		id: 'volume',
		label: 'catalog.tableHeaders.volume',
		sx: { maxWidth: 50 }
	},
    */
	{
		id: 'publisher',
		label: 'catalog.tableHeaders.publisher'
	},
	{
		id: 'price',
		label: 'catalog.tableHeaders.price'
	},
	/*
	{
		id: 'promo'
	},
    */
	{
		id: 'depot',
		label: 'catalog.tableHeaders.depot'
	},
	{
		id: 'date',
		label: 'catalog.tableHeaders.orderDate',
		sx: { width: '115px' }
	},

	{
		id: 'requestQty',
		label: 'catalog.tableHeaders.requestQty',
		sx: { textAlign: 'center' }
	},

	{
		id: 'evadibleQty',
		label: 'catalog.tableHeaders.evadibleQty',
		//label: 'catalog.tableHeaders.evadibleQtyOnTot',
		sx: { textAlign: 'center' }
	},
	{
		id: 'newsItem',
		label: 'catalog.tableHeaders.newsItem'
	},
	{
		id: 'slaDesc',
		label: 'catalog.tableHeaders.info'
	},
	{
		id: 'notes',
		label: 'catalog.tableHeaders.note'
	}
];

export default headCells;
