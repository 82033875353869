import React from 'react';
import { useHistory } from 'react-router-dom';
import CartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';

const OrdersButton = ({ classes }) => {
	const history = useHistory();
	const onOrdersClick = _ => {
		history.push('/orders/list');
	};
	return (
		<IconButton onClick={onOrdersClick}>
			<CartIcon />
		</IconButton>
	);
};

export default OrdersButton;
