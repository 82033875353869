import React from 'react';
import TableCell from '@mui/material/TableCell';

const defaultStyles = {
	paddingLeft: 0,
	paddingRight: 0
};

const Empty = props => {
	const { additionalStyles } = props;
	return (
		<TableCell align="left" style={{ ...defaultStyles, ...additionalStyles }}>
			<span className="MuiButtonBase-root" aria-disabled="true">
				&nbsp;
			</span>
		</TableCell>
	);
};

export default Empty;
