import utils from 'utils';

export default theme => ({
	cardRows: {
		display: 'flex',
		flexDirection: 'row'
	},
	printButton: {
		height: 40,
		alignSelf: 'flex-end'
	},
	adjustMargin: {
		marginTop: theme.spacing(1)
	},
	borderedRow: {
		padding: theme.spacing(2),
		borderRadius: theme.shape.borderRadius,
		border: `1px solid rgba(${utils.colors.hexToRgbString(theme.palette.primary.main)}, .20)`,
		marginTop: theme.spacing(2)
	},
	alignGridContent: {
		alignSelf: 'center',
		justifyContent: 'flex-end'
	},
	popover: {
		padding: theme.spacing(1),
		border: `1px solid rgb(${utils.colors.hexToRgbString(theme.palette.secondary.main)})`,
		borderRadius: theme.shape.borderRadius
	},
	textArea: {
		width: '100%',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1)
		}
	},
	componentCentered: {
		alignSelf: 'center'
	},
	elements: {
		alignSelf: 'center',
		alignItems: 'center',
		justifyContent: 'space-evenly'
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	noDepots: {
		height: 150,
		alignContent: 'center'
	},
	custom: {
		'&:hover': {
			background: 'none'
		}
	},
	centerSelector: {
		alignItems: 'center',
		justifyContent: 'center'
	},
	centerMobile: {
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center'
		}
	},
	colsWidth: {
		maxWidth: 180,
		width: 180,
		minWidth: 180,
		padding: 8,
		[theme.breakpoints.down('xs')]: {
			maxWidth: 60,
			width: 60,
			minWidth: 60
		}
	},
	cellsAlign: {
		textAlign: 'left',
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
			padding: 0
		}
	},
	wrapperBadge: {
		padding: '0',
		margin: '5px',
		'&:hover': {}
	},
	icoImage: {
		//	border: '1px solid',
		//	borderRadius: '100%',
		padding: '1px'
	}
});
