import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import Slider from 'react-slick';
import { IconButton, Paper, Typography, Modal, Button, Box, Dialog, Stack, Skeleton } from '@mui/material';
import { Close } from '@mui/icons-material';
import '../styles.css';

const SliderComponent = props => {
	const loading = useStoreState(state => state.cms.promotionSlide.loading);
	const slides = useStoreState(state => state.cms.promotionSlide.data);
	const loadPromoSlide = useStoreActions(dispatch => dispatch.cms.promotionSlide.load);
	const targetUser = useStoreState(state => state.me.personify.target);
	const [openModal, setOpenModal] = useState(false);
	const [openedSlide, setOpenedSlide] = useState(false);
	const [dimensions, setDimensions] = useState([]);
	const theme = useTheme();
	const settings = {
		accessibility: true,
		autoplay: true,
		autoplaySpeed: 13000,
		arrows: false,
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dotsClass: 'slideDots'
	};

	const visibleSlides = slides ? slides.filter(slide => slide.ctaType.title !== 'banner') : [];

	useEffect(
		_ => {
			loadPromoSlide();
		},
		[targetUser]
	);

	const toggleModal = _ => {
		setOpenModal(!openModal);
	};

	return (
		<Box sx={{ height: { xs: '210px', md: '400px', sm: '450px' } }}>
			{loading && <Skeleton sx={{ height: '600px', position: 'relative', top: '-110px' }} />}
			{!loading && (
				<Slider {...settings}>
					{visibleSlides.map((slide, index) => {
						const handleClick = slide => {
							setOpenedSlide(slide);
							switch (slide.ctaType.title) {
								case 'url_link': {
									window.open(slide.ctaTypeValue, '_blank');
									break;
								}

								case 'buy_product': {
									window.open(`/catalog/search/details/${slide.ctaTypeValue}`, '_self');
									break;
								}

								case 'promotion': {
									window.open(`/catalog/search/by-promotion/${slide.ctaTypeValue}`, '_self');
									break;
								}
								case 'details_text': {
									toggleModal();
									break;
								}
								case 'banner_slide': {
									window.open(`${slide.ctaTypeValue}`, '_blank');
									break;
								}
							}
						};
						return (
							<li key={index}>
								{/* <img src={slide.image.url} sx={{ height: '100%', width: '100%', padding: '0 2px' }} /> */}
								<Box
									sx={{
										width: '100%',
										//height: '400px',
										background: `url('${slide.image.url}')`,
										//backgroundSize: 'cover',
										//backgroundPosition: 'left'
										height: { xs: '200px', md: '400px', sm: '400px' },
										/*backgroundPosition: 'top center',
										backgroundSize: { xs: '100% 200px', ms: '100% 400px', sm: '100% 400px' }*/
										backgroundRepeat: 'no-repeat',
										backgroundPositionX: 'left',
										backgroundPositionY: 'center',
										backgroundSize: 'contain'
									}}
								>
									<Box
										sx={{
											position: 'absolute',
											bottom: '10%',
											width: '100%',
											paddingLeft: '10px'
										}}
									>
										<Typography id="slide-title" sx={{ color: slide.textBackgroundColor.hex }}>
											{slide.title}
										</Typography>
										<Typography id="slide-text" sx={{ color: slide.textBackgroundColor.hex }}>
											{slide.text}
										</Typography>
										{/* Pulsante spostato sotto lo slider
										 slide.ctaEnabled && (
											<Button id="slide-button" onClick={_ => handleClick(slide)}>
												{slide.ctaText}
											</Button>
										)*/}
									</Box>
								</Box>
								{slide.ctaEnabled && (
									<Button id="slide-button" onClick={_ => handleClick(slide)}>
										{slide.ctaText}
									</Button>
								)}
							</li>
						);
					})}
				</Slider>
			)}
			<Dialog
				open={openModal}
				onClose={toggleModal}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
				PaperProps={{ sx: { padding: '16px 32px 24px', width: '50%', height: '300' } }}
			>
				<Stack direction={'column'}>
					<Stack direction={'row'} justifyContent={'space-between'}>
						<Typography variant="titleSection">{openedSlide.title}</Typography>
						<IconButton onClick={toggleModal}>
							<Close />
						</IconButton>
					</Stack>
					<Typography>{openedSlide.ctaTypeValue}</Typography>
				</Stack>
			</Dialog>
		</Box>
	);
};

export default SliderComponent;
