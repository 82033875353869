import React, { Fragment, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import styles from './styles';
import utils from 'components/utils';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Loader from 'components/Loader';
import Typography from '@mui/material/Typography';

const TableToolbar = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const clearAll = useStoreActions(dispatch => dispatch.rankings.assign.clearAll);
	const items = useStoreState(state => state.rankings.assign.detailsItems);
	const loading = useStoreState(state => state.rankings.assign.loading);
	const loadingReport = useStoreState(state => state.rankings.report.loading);
	const report = useStoreState(state => state.rankings.report.data);
	const [createdBy, setCreatedBy] = useState('');
	const [lastDate, setLastDate] = useState('');
	const clear = _ => {
		const data = items.map(a => a.ean);
		const obj = {
			eansToInsert: null,
			eansToDelete: data.join(',')
		};
		clearAll(obj);
	};
	utils.useUpdatedData(setCreatedBy, setLastDate, report);
	return (
		<Fragment>
			{!loading && (
				<Fragment>
					{loadingReport && <CircularProgress size={24} />}
					{!loadingReport && (
						<Grid container>
							<Grid item xs={12}>
								<Typography>
									{translate('coupons.lastUpload') + ' : ' + createdBy + ' il ' + lastDate}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>{translate('coupons.totalCount') + ' : ' + items.length}</Typography>
							</Grid>
						</Grid>
					)}
					<Button
						color="primary"
						variant="contained"
						onClick={() => clear()}
						style={{ float: 'right', marginBottom: '1%' }}
						startIcon={<DeleteIcon />}
					>
						{translate('common.clearAll')}
					</Button>
				</Fragment>
			)}
			{loading && (
				<Grid item container xs={12} justify="center" spacing={3} className={classes.elements}>
					<Loader />
				</Grid>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(TableToolbar);
