import { action, thunkOn } from 'easy-peasy';

export default {
	filters: {
		token: '',
		scopes: {
			waiting: false,
			pending: false,
			evadible: false
		},
		depots: [],
		resetToken: false
	},
	selectDepotsValue: [],
	setSelectDepotsValue: action((state, payload) => {
		state.selectDepotsValue = payload;
	}),
	selectScopeValue: [],
	setSelectScopeValue: action((state, payload) => {
		state.selectScopeValue = payload;
	}),
	isSearching: false,
	setToken: action((state, payload) => {
		state.filters.token = payload.toLowerCase();
	}),
	resetToken: action((state, payload) => {
		state.filters.resetToken = payload;
		state.filters.token = '';
	}),
	setWaiting: action((state, payload) => {
		state.filters.scopes.waiting = payload;
	}),
	setPending: action((state, payload) => {
		state.filters.scopes.pending = payload;
	}),
	setEvadible: action((state, payload) => {
		state.filters.scopes.evadible = payload;
	}),
	toggleDepot: action((state, payload) => {
		const i = state.filters.depots.findIndex(item => item === payload);
		if (i === -1) state.filters.depots.push(payload);
		else state.filters.depots.splice(i, 1);
	}),
	performSearch: action((state, payload) => {
		if (!payload.isReset) state.isSearching = true;
	}),
	searchListenerOnFilters: thunkOn(
		(actions, storeActions) => [
			actions.setWaiting,
			actions.setPending,
			actions.setEvadible,
			actions.toggleDepot,
			actions.resetToken
		],
		(actions, target, helpers) => {
			actions.performSearch({ isReset: false });
		}
	),
	searchListenerOnReset: thunkOn(
		(actions, storeActions) => [actions.resetSearch],
		(actions, target, helpers) => {
			actions.performSearch({ isReset: true });
		}
	),
	searchListenerForToken: thunkOn(
		(actions, storeActions) => [actions.setToken],
		(actions, target, helpers) => {
			const { getState } = helpers;
			if (getState().filters.token.length > 3 || getState().filters.token.length === 0) {
				actions.performSearch({ isReset: false });
			} else {
				actions.performSearch({ isReset: true });
			}
		}
	),
	resetSearch: action((state, payload) => {
		state.filters.token = '';
		state.filters.scopes.waiting = false;
		state.filters.scopes.pending = false;
		state.filters.scopes.evadible = false;
		state.filters.depots = [];
		state.isSearching = false;
	})
};
