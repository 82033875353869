import React from 'react';
import { withStyles } from '@mui/styles';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import { cardStyles } from './style.js';

const Card = ({ classes, children, type, title, subtitle, endAdornment, style, bodyStyle }) => (
	<div className={classes.card} style={style}>
		<CardHeader type={type} title={title} subtitle={subtitle} endAdornment={endAdornment} />
		<CardBody bodyStyle={bodyStyle}>{children}</CardBody>
	</div>
);

export default withStyles(cardStyles, { withTheme: true })(Card);
