import k from 'k';
import utils from 'services/utils';

export default wishlistId => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders(),
		responseType: 'blob'
	};
	return utils
		.fetch(`${k.endpoints.apis}/wishlist/export/pdf/${wishlistId}`, requestOptions)
		.then(response => response.blob())
		.then(response => {
			const file = new Blob([response], { type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			console.log(error);
		});
};
