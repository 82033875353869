import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import tail from 'lodash/tail';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import utils from 'components/utils';
import styles from './styles';
import { useStoreState } from 'easy-peasy';
import clsx from 'clsx';

const PageTitle = ({ classes }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const location = useLocation();
	const menuOpen = useStoreState(state => state.menu.mainOpen);
	const isSmall = useMediaQuery(theme.breakpoints.down('md'));
	if (location.pathname === '/') return '';
	let parts = tail(location.pathname.split('/')).map(p => translate('titles.' + p));
	if (isSmall) parts = parts.map(p => utils.useEllipsis(p, 15, '...'));
	const firstLine = parts.slice(0, 2).join(' / ');
	const secondLine = parts.slice(2).join(' / ');
	return (
		<Fragment>
			<Grid
				item
				xs
				className={clsx(classes.titleContainer, {
					[classes.menuOpen]: menuOpen && isSmall,
					[classes.withPaddingTop8]: secondLine !== '',
					[classes.withPaddingTop20]: secondLine === ''
				})}
			>
				<Typography noWrap variant="subtitle2">
					{firstLine}
				</Typography>
			</Grid>
			{secondLine !== '' && (
				<Grid item xs className={clsx(classes.titleContainer, { [classes.menuOpen]: menuOpen && isSmall })}>
					<Typography noWrap variant="subtitle2">
						{secondLine}
					</Typography>
				</Grid>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(PageTitle);
