import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const CityComp = ({ sendUserCity }) => {
	return <StyledTableCell>{utils.capitalize(sendUserCity)}</StyledTableCell>;
};

const City = memo(
	({ item }) => <CityComp sendUserCity={item.sendUserCity} />,
	(p, n) => p.item.sendUserCity === n.item.sendUserCity
);

export default City;
