import React, { memo } from 'react';
import { useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

const SelectionComp = ({ userCode, active, hasAnother }) => {
	const toggleSelection = useStoreActions(dispatch => dispatch.purchasings.assignUsers.toggleSelection);
	return (
		<TableCell padding="checkbox">
			{hasAnother ? (
				<Tooltip title={'Utente associato ad un altra centrale acquisto'}>
					<ErrorIcon />
				</Tooltip>
			) : (
				<Checkbox
					color="primary"
					onChange={_ => toggleSelection(userCode)}
					checked={active}
					inputProps={{ 'aria-labelledby': userCode }}
				/>
			)}
		</TableCell>
	);
};

const Selection = memo(
	({ item }) => (
		<SelectionComp userCode={item.userCode} active={item.isSelected} hasAnother={item.associatedWithAnotherCA} />
	),
	(p, n) =>
		p.item.userCode === n.item.userCode &&
		p.item.isSelected === n.item.isSelected &&
		p.item.associatedWithAnotherCA === n.item.associatedWithAnotherCA
);

export default Selection;
