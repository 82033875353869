import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useStoreActions } from 'easy-peasy';

const DeleteComp = ({ clientCode }) => {
	const update = useStoreActions(dispatch => dispatch.purchasings.assignUsers.remove);
	return (
		<StyledTableCell>
			<DeleteForeverIcon onClick={() => update(clientCode)} />
		</StyledTableCell>
	);
};

const Delete = memo(
	({ item }) => <DeleteComp clientCode={item.clientCode} />,
	(p, n) => p.item.clientCode === n.item.clientCode
);

export default Delete;
