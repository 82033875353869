import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Grid, CircularProgress } from '@mui/material';
import Card from 'components7/Card';
import utils from 'components/utils';
import Badge from './Badge';

const BadgeList = ({ classes, id_ean, size }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const load = useStoreActions(dispatch => dispatch.catalog.badges.load);
	const loading = useStoreState(state => state.catalog.badges.loading);
	const items = useStoreState(state => state.catalog.badges.items);
	const personify = useStoreState(state => state.me.personify);
	const userData = useStoreState(state => state.me.data);
	const province = personify.target.id
		? personify.target.address.county
			? personify.target.address.county.toUpperCase()
			: 'XX'
		: userData.address.county
		? userData.address.county.toUpperCase()
		: 'XX';

	useEffect(
		_ => {
			if (id_ean) {
				load({ id_ean, province });
			}
		},
		[load, id_ean, province]
	);

	//Creo l'array degli item comprensivi di eventuali posizioni vuote
	const itemsAll = [];
	if (items.length > 0) {
		for (var i = 1; i <= 6; i++) {
			const el = items.find(e => e.sort === i);
			if (el) itemsAll.push(el);
			else itemsAll.push({ id: 0, active: false, sort: 0 });
		}
	}

	return (
		<Card
			title={translate(`common.menu.badgeRankings`)}
			margin={{ xs: '15px 0 0 0 !important', md: '15px 0 0 20px !important', sm: '15px 0 0 0 !important' }}
			width={{ xs: '100%', md: '390px', sm: '70%' }}
			backgroundColor={theme.palette.custom.white}
		>
			{loading && <CircularProgress />}
			{!loading && (
				<Grid
					container
					item
					spacing={0}
					xs={12}
					sx={{ marginLeft: { xs: '-15px !important', md: '0 !important', sm: '0 !important' } }}
				>
					{itemsAll.map((item, index) => (
						<Grid item xs={item.sort !== 0 ? 2 : 0} md={item.sort !== 0 ? 4 : 0} key={index}>
							{item.sort !== 0 && <Badge item={item} size={size} />}
							{item.sort === 0 && <span>&nbsp;</span>}
						</Grid>
					))}
				</Grid>
			)}
		</Card>
	);
};

export default BadgeList;
