import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import styles from './styles';
import { useStoreState } from 'easy-peasy';

const Videos = ({ classes }) => {
	const loadingVideos = useStoreState(state => state.cms.videos.loading);
	const videos = useStoreState(state => state.cms.videos.data);
	return (
		<Fragment>
			{!loadingVideos && videos && videos.length > 0 && (
				<Grid container item xs={12}>
					<Grid item container xs={12} className={classes.container}>
						{videos.map((video, index) => (
							<Grid key={index} className={classes.singleVideoContainer} container item xs={12}>
								<Paper key={index} className={classes.singleVideo}>
									<CardMedia
										style={{ height: '100%' }}
										component="iframe"
										src={`https://www.youtube.com/embed/${video.url}`}
									/>
								</Paper>
							</Grid>
						))}
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
};
export default withStyles(styles, { withTheme: true })(Videos);
