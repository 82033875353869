import React from 'react';
import NotSortable from './NotSortable';
import Empty from './Empty';

const allCols = [
	{
		id: 'id',
		comp: _ => <Empty id="uuidRegistration" key="headCell_uuidRegistration" label="sendNotification.table.id" />
	},
	{
		id: 'externalNotificationStatus',
		comp: _ => (
			<NotSortable
				id="externalNotificationStatus"
				key="headCell_externalNotificationStatus"
				label="sendNotification.table.externalNotificationStatus"
			/>
		)
	},
	{
		id: 'internalNotificationStatus',
		comp: _ => (
			<NotSortable
				id="internalNotificationStatus"
				key="headCell_internalNotificationStatus"
				label="sendNotification.table.internalNotificationStatus"
			/>
		)
	},
	{
		id: 'createdBy',
		comp: _ => <NotSortable id="createdBy" key="headCell_createdBy" label="sendNotification.table.createdBy" />
	},
	{
		id: 'title',
		comp: _ => <NotSortable id="title" key="headCell_title" label="sendNotification.table.title" />
	},
	{
		id: 'body',
		comp: _ => <NotSortable id="body" key="headCell_body" label="sendNotification.table.body" />
	},
	{
		id: 'groups',
		comp: _ => <NotSortable id="groups" key="headCell_groups" label="sendNotification.table.groups" />
	},
	{
		id: 'creationDate',
		comp: _ => (
			<NotSortable id="creationDate" key="headCell_creationDate" label="sendNotification.table.creationDate" />
		)
	}
];

export default allCols;
