import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import utils from 'components/utils';

const Sortable = ({ id, label }) => {
	const order = useStoreState(state => state.userGroups.list.orderDirection);
	const orderBy = useStoreState(state => state.userGroups.list.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.userGroups.list.setOrder);
	const translate = utils.useI18N();
	return (
		<TableCell align="left" sortDirection={orderBy === id ? order : false}>
			<TableSortLabel
				active={orderBy === id}
				direction={orderBy === id ? order : 'asc'}
				onClick={e => setOrder(id)}
			>
				{translate(label)}
			</TableSortLabel>
		</TableCell>
	);
};

export default Sortable;
