import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { makeStyles, withStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import StepLabel from '@mui/material/StepLabel';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Assistant from '@mui/icons-material/Assistant';
import LocalShipping from '@mui/icons-material/LocalShipping';
import Streetview from '@mui/icons-material/Streetview';
import EventNote from '@mui/icons-material/EventNote';
import KeyboardTab from '@mui/icons-material/KeyboardTab';
import WhereToVote from '@mui/icons-material/WhereToVote';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import styles from './styles';
import OrderTakenOver from './components/OrderTakenOver';
import OrderProcessor from './components/OrderProcessor';
import OrderUnderProcessing from './components/OrderUnderProcessing';
import DocumentIssuer from './components/DocumentIssuer';
import TakenByCourier from './components/TakenByCourier';
import Delivering from './components/Delivering';
import Delivered from './components/Delivered';
import utils from 'components/utils';
import moment from 'moment';
import Alert from '@mui/lab/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const TrackingStepper = ({ deliveryID }) => {
	const translate = utils.useI18N();

	const items = useStoreState(state => state.order.shippingStepper.items).sort(
		(a, b) => a.generalStatus - b.generalStatus
	);
	const activeStep = useStoreState(state => state.order.shippingStepper.activeStep);
	const loading = useStoreState(state => state.order.shippingStepper.loading);
	const tracking = useStoreActions(dispatch => dispatch.order.shippingStepper.sendRequest);
	// prettier-ignore
	useEffect(_ => { tracking(deliveryID);}, [tracking, deliveryID]);
	const [matches, setMatches] = useState(window.matchMedia('screen and (min-width:1024px)').matches);

	useEffect(_ => {
		window.matchMedia('screen and (min-width: 1024px)').addEventListener('change', e => setMatches(e.matches));
	}, []);

	if (loading) {
		return <CircularProgress size={14} />;
	}

	const strDelivery = function(trackingLink, delivery) {
		return (
			<a href={trackingLink} target="_blank" rel="noopener noreferrer">
				numero spedizione <br /> {delivery}
			</a>
		);
	};
	const strDateAndCourier = function(date, courier) {
		return '' + moment(date).format('L [\n][ore] HH:mm') + ' (' + courier + ') ';
	};

	const steps = [
		<OrderProcessor />,
		<OrderTakenOver />,
		<OrderUnderProcessing />,
		<DocumentIssuer />,
		<TakenByCourier />,
		<Delivering />,
		<Delivered />
	];
	const getActiveStepContent = _ => steps[activeStep];

	const useColorlibStepIconStyles = makeStyles({
		root: {
			backgroundColor: '#ccc',
			zIndex: 1,
			color: '#fff',
			width: 28,
			height: 28,
			display: 'flex',
			borderRadius: '50%',
			justifyContent: 'center',
			alignItems: 'center'
		},
		active: {
			backgroundImage:
				'linear-gradient( 136deg, rgba(0,82,155,255) 0%, rgba(0,82,155,255) 50%, rgba(0,82,155,255) 100%)',
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
		},
		completed: {
			backgroundImage:
				'linear-gradient( 136deg, rgba(0,82,155,255) 0%, rgba(0,82,155,255) 50%, rgba(0,82,155,255) 100%)'
		}
	});
	const CustomStepIcon = props => {
		const classes = useColorlibStepIconStyles();
		const { active, completed } = props;

		const stepIcons = {
			1: <MoreHoriz />,
			2: <Assistant />,
			3: <Streetview />,
			4: <EventNote />,
			5: <LocalShipping />,
			6: <KeyboardTab />,
			7: <WhereToVote />
		};

		//return <div>{stepIcons[String(props.icon)]}</div>;
		return (
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
					[classes.completed]: completed
				})}
			>
				{stepIcons[String(props.icon)]}
			</div>
		);
	};

	return (
		<Fragment>
			{!loading && items.length === 0 && (
				<Grid container spacing={1} justify="center" style={{ marginTop: '2%' }}>
					<Grid item xs={12}>
						<Alert style={{ width: '100%' }} variant="filled" severity="warning">
							{translate('orders.tables.noTracking')}
						</Alert>
					</Grid>
				</Grid>
			)}

			{items.length !== 0 && matches && (
				<Stepper activeStep={activeStep}>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 1)
										? moment(items.find(item => item.generalStatus === 1).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.processor')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 2)
										? moment(items.find(item => item.generalStatus === 2).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.takenover')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 3)
										? moment(items.find(item => item.generalStatus === 3).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.underprocessing')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 4)
										? moment(items.find(item => item.generalStatus === 4).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.docissuer')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 5)
										? strDateAndCourier(
												items.find(item => item.generalStatus === 5).statusDate,
												items.find(item => item.generalStatus === 5).courier
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.takenbycourier')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 6)
										? strDelivery(
												items.find(item => item.generalStatus === 6).trackingLink,
												items.find(item => item.generalStatus === 6).deliveryNumber
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.delivering')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 7)
										? moment(items.find(item => item.generalStatus === 7).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.delivered')}</Typography>
						</StepLabel>
					</Step>
				</Stepper>
			)}
			{items.length !== 0 && !matches && (
				<Stepper activeStep={activeStep} orientation="vertical">
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 1)
										? moment(items.find(item => item.generalStatus === 1).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.processor')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 2)
										? moment(items.find(item => item.generalStatus === 2).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.takenover')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 3)
										? moment(items.find(item => item.generalStatus === 3).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.underprocessing')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 4)
										? moment(items.find(item => item.generalStatus === 4).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.docissuer')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 5)
										? strDateAndCourier(
												items.find(item => item.generalStatus === 5).statusDate,
												items.find(item => item.generalStatus === 5).courier
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.takenbycourier')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 6)
										? strDelivery(
												items.find(item => item.generalStatus === 6).trackingLink,
												items.find(item => item.generalStatus === 6).deliveryNumber
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.delivering')}</Typography>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={CustomStepIcon}
							optional={
								<Typography variant="caption" style={{ whiteSpace: 'pre' }}>
									{items.find(item => item.generalStatus === 7)
										? moment(items.find(item => item.generalStatus === 7).statusDate).format(
												'L [\n][ore] HH:mm'
										  )
										: ''}
								</Typography>
							}
						>
							<Typography variant="caption">{translate('orders.stepper.delivered')}</Typography>
						</StepLabel>
					</Step>
				</Stepper>
			)}
			<Grid container>
				<Grid item xs={12}>
					{getActiveStepContent()}
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(TrackingStepper);
