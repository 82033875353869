import React from 'react';
import { useStoreActions } from 'easy-peasy';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import utils from 'components/utils';

const PubDateChip = ({ filter }) => {
	const translate = utils.useI18N();
	const resetSingleFilter = useStoreActions(dispatch => dispatch.catalog.filters.resetSingle);
	return (
		<Chip
			size="small"
			label={`${translate(`catalog.filters.${filter.label}`)}: ${moment(filter.value).format('L')}`}
			onDelete={_ => resetSingleFilter(filter.label)}
		/>
	);
};

export default PubDateChip;
