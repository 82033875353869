import React from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const getShippingTypeInfo = depot => {
	if (!depot || !depot.shippingTypes) return 'NO_INFO';
	const def = depot.shippingTypes.find(d => d.defaultValue);
	return def ? def.sla : 'NO_INFO';
};

const ShippingInfo = ({ item }) => {
	const translate = utils.useI18N();
	const depot = item.depots.find(d => d.id === item.selection.depot.id);
	return (
		<StyledTableCell style={{ minWidth: 70, maxWidth: 70, width: 70 }}>
			{translate('catalog.shippingTypes.' + getShippingTypeInfo(depot).toLowerCase())}
		</StyledTableCell>
	);
};

export default ShippingInfo;
