import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Box } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutStripeForm from './CheckoutStripeForm';
import utils from 'components/utils';

const CheckoutStripe = props => {
	const { totalFulfillment, payload } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const loading = useStoreState(state => state.stripe.loading);
	const title = useStoreState(state => state.stripe.title);
	const stripeError = useStoreState(state => state.stripe.stripeError);
	const errorMessage = useStoreState(state => state.stripe.errorMessage);
	const stripePromise = useStoreState(state => state.stripe.stripePromise);
	const loadStripePromise = useStoreActions(dispatch => dispatch.stripe.loadStripePromise);
	const createPaymentIntent = useStoreActions(dispatch => dispatch.stripe.createPaymentIntent);
	const clientSecret = useStoreState(state => state.stripe.clientSecret);

	const appearance = {
		theme: 'stripe',
		variables: {
			fontFamily: 'Poppins, sans-serif',
			colorPrimary: '#0570de',
			colorBackground: '#ffffff',
			colorText: '#000',
			colorDanger: '#830e25'
		}
	};

	const options = {
		clientSecret,
		appearance,
		locale: 'it'
	};

	//Se non ho ancora caricato l'oggetto stripe lo carico
	useEffect(() => {
		if (!stripePromise) {
			loadStripePromise();
		}
	}, []);

	useEffect(() => {
		if (stripePromise) {
			createPaymentIntent(totalFulfillment);
		}
	}, [stripePromise]);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ width: '100%' }}
		>
			<Box sx={{ paddingLeft: '10px !important', marginBottom: '10px !important' }}>
				<Typography variant="titleTab">{translate(title)}</Typography>
			</Box>

			{!loading && stripeError && (
				<Typography variant="titleSection" sx={{ color: theme.palette.custom.red }}>
					{translate(errorMessage)}
				</Typography>
			)}

			{!loading && clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<CheckoutStripeForm payload={payload} />
				</Elements>
			)}
		</Stack>
	);
};

export default CheckoutStripe;
