import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const EanComp = ({ text }) => {
	return <StyledTableCell>{text}</StyledTableCell>;
};

const Ean = memo(
	({ item }) => <EanComp text={item.catalog} />,
	(p, n) => p.item.catalog === n.item.catalog
);

export default Ean;
