import k from 'k';
import utils from 'services/utils';

export default ({ page, filters, step }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = {
		page,
		rows: step
	};
	if (filters.promoId) params.sapPromoId = filters.promoId;
	if (filters.userId) params.userCode = filters.userId;
	if (filters.intoCart !== '') params.intoCart = filters.intoCart;
	const qParams = utils.createQueryParams(params, false);
	return utils
		.fetch(`${k.endpoints.apis}/wishlist/conditions/?status=PENDING&${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
