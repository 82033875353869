import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	openModalDelete: null,
	setOpenModalDelete: action((state, payload) => {
		state.openModalDelete = payload;
	}),
	sendDelete: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);

		const client = getStoreState()
			.cart.open.items.flatMap(i => i.orderItems)
			.filter(io => io.code === payload.code)
			.reduce((a, c) => c).client;
		payload.ownerUserCode = client;

		return services.order
			.remove(payload)
			.then(data => {
				getStoreActions().success('order.delete');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('order.delete');
			})
			.finally(_ => {
				actions.setLoading(false);
				actions.setOpenModalDelete(null);
			});
	}),
	deleteItems: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const codes = payload.codeSelected.map(item => item).join();
		const client = getStoreState()
			.cart.open.items.flatMap(i => i.orderItems)
			.filter(io => codes.includes(io.code))
			.reduce((a, c) => c).client;
		payload.ownerUserCode = client;

		return services.order
			.removeItems(payload)
			.then(data => {
				getStoreActions().success('cart.deleteItems');
				//Asseggno i codici eliminati per il trigger store.cart.open
				data.item.code = codes;
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('cart.deleteItems');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
