import React from 'react';
import { withStyles } from '@mui/styles';
import { cardHeaderStyles } from './style.js';
import Grid from '@mui/material/Grid';

const CardHeader = props => {
	const { classes, title, subtitle, type, endAdornment } = props;
	return (
		<div className={`${classes.cardHeader} ${classes[type + 'CardHeader']}`} style={{ marginTop: '15px' }}>
			<Grid container justifyContent="space-between">
				<Grid container item xs alignItems="center">
					<h4 className={`${classes.cardHeaderTitle} ${classes[type + 'CardHeaderTitle']}`}>{title}</h4>
				</Grid>
				{endAdornment && (
					<Grid container item xs justifyContent="flex-end">
						{endAdornment}
					</Grid>
				)}
			</Grid>
			{subtitle && <p className={classes.cardHeaderSubtitle}>{subtitle}</p>}
		</div>
	);
};

export default withStyles(cardHeaderStyles, { withTheme: true })(CardHeader);
