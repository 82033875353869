import React, { memo } from 'react';
import { TableCell } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import ListItemLink from '../../../layouts/Default/components/Menu/components/ListItemLink';
import utils from 'components/utils';

const EditComp = ({ id, type }) => {
	const translate = utils.useI18N();
	return (
		<TableCell>
			<ListItemLink
				to={`/admin/usergroups/usergroup/${id}/${type}`}
				button
				disableRipple
				style={{ background: 'none' }}
			>
				<Tooltip title={translate('common.edit')} placement="top">
					<IconButton size="small" disableRipple style={{ padding: 8, background: 'none' }}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
					</IconButton>
				</Tooltip>
			</ListItemLink>
		</TableCell>
	);
};
const Edit = memo(
	({ item }) => <EditComp id={item.id} type={item.type} />,
	(p, n) => (p.item.id === n.item.id, p.item.type === n.item.type)
);

export default Edit;
