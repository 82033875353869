import React, { Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import utils from 'components/utils';
import PersonificationResume from './PersonificationResume';
import PersonificationSearch from './PersonificationSearch';

const styles = theme => ({
	divider: {
		margin: `${theme.spacing(3)}px 0`
	}
});

const Personification = ({ target, setTarget, onPersonifyCloseClick }) => {
	const [iAmPersonifying] = utils.usePersonification();
	const strictSearch = useStoreState(state => state.me.personify.strictSearch);
	const personify = useStoreActions(dispatch => dispatch.me.personify.sendRequest);
	const resetAutocomplete = useStoreActions(dispatch => dispatch.me.personify.resetAutocomplete);
	const setStrictSearch = useStoreActions(dispatch => dispatch.me.personify.setStrictSearch);
	const loading = useStoreState(state => state.me.personify.loading);
	return (
		<Fragment>
			<Typography
				variant="overline"
				color="primary"
				component="p"
				style={{ textAlign: 'center', fontSize: '15px' }}
			>
				personificazione
			</Typography>
			<Divider style={{ margin: 8 }} light />
			<Grid item xs={12} style={{ marginBottom: 16 }}>
				{iAmPersonifying && <PersonificationResume />}
				{loading && <CircularProgress size={14} />}
			</Grid>
			<PersonificationSearch
				target={target}
				setTarget={setTarget}
				strictSearch={strictSearch}
				setStrictSearch={setStrictSearch}
				resetAutocomplete={resetAutocomplete}
				personify={personify}
				onPersonifyCloseClick={onPersonifyCloseClick}
			/>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Personification);
