import k from 'k';
import utils from 'services/utils';

export default _ => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = {
		rows: 3,
		page: 0
	};
	const qParams = utils.createQueryParams(params);
	return utils
		.fetch(`${k.endpoints.apis}/notification/notRead/?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
