import k from 'k';
import utils from 'services/utils';

export default (data, status) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders(),
		responseType: 'blob'
	};
	const qParams = utils.createQueryParams(data);
	return utils
		.fetch(`${k.endpoints.apis}/cart/export/pdf/${status}?${qParams}`, requestOptions)
		.then(response => response.blob())
		.then(response => {
			const file = new Blob([response], { type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			console.log(error);
		});
};
