import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import utils from 'components/utils';
import styles from './styles';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Drawer from '@mui/material/Drawer';
import { Hidden } from '@mui/material';
import ModalDetails from '../BestSellers/components/ModalDetails';
import { Link } from 'react-router-dom';

const BestSellers = ({ classes, id_ean, refParameter }) => {
	const loading = useStoreState(state => state.cms.bestSellers.loading);
	const list = useStoreState(state => state.cms.bestSellers.data);
	const modalOpen = useStoreState(state => state.cms.bestSellers.modalOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.cms.bestSellers.setModalOpen);
	const history = useHistory();
	const setOpenFromUrl = useStoreActions(dispatch => dispatch.cms.bestSellers.setOpenFromUrl);
	const [selectedItem, setSelectedItem] = useState(null);
	const params = useParams();
	const [scrollPosition, setScrollPosition] = useState(0);

	useEffect(
		_ => {
			if (history.location.pathname.includes('/home/bestsellers') && params.id) {
				if (list) {
					const item = list.find(value => value.ean === params.id);
					if (item) {
						setSelectedItem(item);
						setModalOpen(true);
						setScrollPosition(refParameter);
					}
				}
			} else if (modalOpen === true) {
				setModalOpen(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[params, history, list]
	);

	const onOpenBestSellers = item => {
		setModalOpen(true);
		setSelectedItem(item);
		setOpenFromUrl(false);
	};
	const style = {
		width: 'calc(100% - 57px)',
		height: 'calc(100% - 64px)',
		top: '64px !important',
		left: '57px !important',
		display: 'inline !important',
		focus: {
			outline: 'none'
		}
	};
	const onClose = () => {
		if (history.location.pathname.includes('/home/bestsellers')) {
			setModalOpen(false);

			if (history.action === 'PUSH') {
				window.history.replaceState(null, '', '/');
				window.scrollTo(0, refParameter);
			} else history.push('/');
		}
	};
	return (
		<Fragment>
			{!loading && (
				<Grid item container xs={12} className={classes.container}>
					<Grid item xs={12} className={classes.promotionTitle}>
						<Typography style={{ fontSize: 20 }} variant="button" color="error">
							I pi&ugrave; venduti
						</Typography>
						<Typography variant="body1">
							Per te la selezione dei libri più venduti dai nostri clienti che operano nella tua zona
						</Typography>
					</Grid>
					{list.map((item, index) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							lg={2}
							key={`bestsellers_${index}`}
							style={{ marginBottom: 16 }}
							container
							justify="center"
						>
							<Paper elevation={3} className={classes.singlePromotion}>
								<Link to={`/home/bestsellers/${item.ean}`}>
									<CardMedia
										style={{ height: '100%' }}
										onClick={_ => {
											onOpenBestSellers(item, true);
										}}
										image={item.coverImageURL}
										title={item.title}
									/>
								</Link>
							</Paper>
							<Grid item container xs={12} justify="center" style={{ marginTop: 16 }}>
								<Typography variant="subtitle2" color="primary" style={{ cursor: 'pointer' }}>
									{utils.useEllipsis(item.title, 30, '...')}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
			)}
			<Hidden smDown>
				<Modal style={style} open={modalOpen} onClose={onClose}>
					<div className="modal-content">
						<Fragment>
							{modalOpen && (
								<Fade in={modalOpen}>
									{
										<ModalDetails
											modalSize100
											scrollPosition={scrollPosition}
											selectedItem={selectedItem}
											onClose={onClose}
										/>
									}
								</Fade>
							)}
						</Fragment>
					</div>
				</Modal>
			</Hidden>
			<Hidden mdUp>
				<Drawer anchor="bottom" open={modalOpen} onClose={onClose}>
					{<ModalDetails selectedItem={selectedItem} onClose={onClose} />}
				</Drawer>
			</Hidden>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(BestSellers);
