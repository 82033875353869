const openOrderCols = [
	'ean',
	'author',
	'title',
	'volume',
	'publisher',
	'price',
	'promo',
	'depot',
	'quantityRequired',
	'withdrawalTypes',
	'note'
];

export default openOrderCols;
