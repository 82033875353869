import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import utils from 'components/utils';
import { Stack, Typography } from '@mui/material';

const BootstrapInput = withStyles(theme => ({
	root: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: -5
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 14,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: 6
	}
}))(InputBase);

const StepSelector = ({ currentStep, setStep, listStep }) => {
	const translate = utils.useI18N();
	const paginationStep = listStep ? listStep : [25, 50, 75, 100, 500];
	const stepOnChangeSelector = event => {
		setStep(event.target.value);
	};
	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
			<Typography variant="text">{translate('common.resultsPerPagePrepend')}</Typography>
			{listStep ? (
				'25'
			) : (
				<FormControl>
					<Select value={currentStep} onChange={stepOnChangeSelector} input={<BootstrapInput />}>
						{paginationStep.map(item => (
							<MenuItem key={`filter_step_${item}`} value={item}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			<Typography variant="text">{translate('common.resultsPerPageAppend')}</Typography>
		</Stack>
	);
};

export default StepSelector;
