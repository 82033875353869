import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const Amount2Comp = ({ text, currency }) => {
	let field = ``;
	if (text !== '' && currency !== '') field = `${text} ${currency}`;
	return <StyledTableCell>{utils.formatPrice(field)}</StyledTableCell>;
};

const Amount2 = memo(
	({ item }) => <Amount2Comp text={item.paidAmountInCurrency} currency={item.currency} />,
	(p, n) => p.item.paidAmountInCurrency === n.item.paidAmountInCurrency && p.item.currency === n.item.currency
);
// da controllare con la divisa
export default Amount2;
