import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: null,
	load: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.editorials
			.getDetails(payload)
			.then(data => {
				actions.save(data.item);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('editorials.getDetails');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	save: action((state, payload) => {
		state.data = payload;
	}),
	setName: action((state, payload) => {
		state.data.name = payload;
	}),
	setEnabled: action((state, payload) => {
		state.data.enabled = payload;
	}),
	setStartDate: action((state, payload) => {
		state.data.startDate = payload;
	}),
	setEndDate: action((state, payload) => {
		state.data.endDate = payload;
	})
};
