import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Checkbox } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { red } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';
import styles from './styles';

const EditModal = props => {
	const { classes, onClose, item } = props;
	const translate = utils.useI18N();
	const redTheme = createTheme({ palette: { primary: red } });
	const [warningMessage, setWarningMessage] = useState(item.warning);
	const [warningEnabled, setWarningEnabled] = useState(Boolean(item.warningEnabled));
	const update = useStoreActions(dispatch => dispatch.shippingType.update.sendRequest);
	const remove = useStoreActions(dispatch => dispatch.shippingType.remove.sendRequest);
	const loadingUpdate = useStoreState(state => state.shippingType.update.loading);
	const loadingRemove = useStoreState(state => state.shippingType.remove.loading);
	const onSaveClick = _ => {
		update({
			depotCode: item.depotCode,
			shippingCode: item.shippingRule.code,
			warning: warningMessage,
			warningEnabled
		});
	};
	const onDeleteClick = _ => {
		remove(item.id);
	};
	return (
		<Paper className={classes.modalPaper}>
			<Grid container spacing={0} justifyContent="space-between">
				<Grid item xs={10}>
					<Typography color="inherit" variant="h4">
						{translate('common.edit')}
					</Typography>
				</Grid>
				<Grid container item xs={1} justifyContent="flex-end">
					<IconButton className={classes.noRipple} onClick={onClose} style={{ paddingRight: 0 }}>
						<CloseIcon />
					</IconButton>
				</Grid>
			</Grid>
			<Divider classes={{ root: classes.dividerColor }} />
			<Grid item container style={{ marginTop: 40 }}>
				<Grid item xs={12}>
					<Typography color="inherit" variant="body1">
						<strong>{translate('admin.withdrawTypes.descType')}:</strong> {item.shippingRule.desc}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography color="inherit" variant="body1">
						<strong>{translate('admin.withdrawTypes.codeType')}:</strong> {item.shippingRule.code}
					</Typography>
				</Grid>
			</Grid>
			<Divider className={classes.dividerAria} light />
			<Grid item container spacing={1}>
				<Grid item container xs={12}>
					<Typography color="inherit" variant="body1">
						{translate('admin.withdrawTypes.warningMessage')}
					</Typography>
				</Grid>
				<Grid item container xs={12}>
					<TextareaAutosize
						rowsMax={5}
						rowsMin={5}
						style={{ width: '100%', resize: 'none' }}
						aria-label="warning message"
						placeholder={translate('admin.withdrawTypes.warningMessagePlaceholder')}
						defaultValue={warningMessage}
						onChange={e => {
							setWarningMessage(e.target.value);
						}}
					/>
				</Grid>
				<Grid item container xs={12}>
					<FormControlLabel
						value={warningEnabled}
						control={
							<Checkbox
								disableRipple
								checked={warningEnabled}
								color="primary"
								onChange={_ => {
									setWarningEnabled(!warningEnabled);
								}}
							/>
						}
						label={translate('admin.withdrawTypes.warningIsEnabled')}
						labelPlacement="end"
					/>
				</Grid>
			</Grid>
			<Grid item container xs={12} style={{ marginTop: 40 }}>
				<Grid item xs={8}>
					{loadingUpdate !== null && <CircularProgress size={24} />}
					{loadingUpdate === null && (
						<Button disableRipple type="submit" variant="contained" color="primary" onClick={onSaveClick}>
							{translate('common.save')}
						</Button>
					)}
					<ThemeProvider theme={redTheme}>
						{loadingRemove && <CircularProgress size={24} />}
						{!loadingRemove && (
							<Button
								style={{ marginLeft: 20 }}
								disableRipple
								type="submit"
								variant="contained"
								color="primary"
								onClick={onDeleteClick}
							>
								{translate('common.delete')}
							</Button>
						)}
					</ThemeProvider>
				</Grid>
				<Grid item xs style={{ textAlign: 'right' }}>
					<Button disableRipple variant="outlined" color="primary" onClick={onClose}>
						{translate('catalog.closeFilters')}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};
export default withStyles(styles, { withTheme: true })(EditModal);
