import React, { Fragment, useState, useEffect, memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import { Popover } from '@mui/material';

const GroupsComp = ({ groups }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = _ => {
		setAnchorEl(null);
	};

	return (
		<StyledTableCell>
			{groups.length > 0 && (
				<Fragment>
					<IconButton style={{ padding: 4 }} aria-label="on approval" onClick={handleOpen}>
						<EyeIcon fontSize="small" />
					</IconButton>
					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<Paper
							style={{
								backgroundColor: '#FFF',
								border: '2px solid #000',
								boxShadow: '2px',
								padding: 16,
								overflowY: 'scroll',
								maxHeight: 400
							}}
						>
							{groups.map((item, index) => (
								<Typography key={index} variant="body2">
									{item.name}
								</Typography>
							))}
						</Paper>
					</Popover>
				</Fragment>
			)}
		</StyledTableCell>
	);
};

const Groups = memo(
	({ item }) => <GroupsComp groups={item.groups} />,
	(p, n) => p.item.groups === n.item.groups
);

export default Groups;
