import React, { Fragment, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import utils from 'components/utils';
import styles from './styles';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import BadgeRankingCard from 'components/BadgeRankingCard';

const BadgeRanking = props => {
	const { classes } = props;
	const mainOpen = useStoreState(state => state.menu.mainOpen);
	const previousMainOpen = utils.usePrevious(mainOpen);
	const translate = utils.useI18N();
	const label = translate(`common.menu.badgeRankings`);
	const popperOpen = useStoreState(state => state.catalog.badges.open);
	const setPopperOpen = useStoreActions(dispatch => dispatch.catalog.badges.setOpen);
	const [anchorEl, setAnchorEl] = useState(null);

	const onBadgesCloseClick = _ => {
		setPopperOpen(false);
	};
	useEffect(
		_ => {
			if (setPopperOpen && previousMainOpen !== mainOpen) {
				setPopperOpen(false);
			}
		},
		[mainOpen, previousMainOpen, setPopperOpen]
	);
	const togglePopperOpen = _ => {
		setPopperOpen(!popperOpen);
	};
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		togglePopperOpen();
	};

	return (
		<Fragment>
			<ListItem className={classes.listItem} button onClick={handleClick}>
				{!mainOpen && (
					<Tooltip title={label} placement="right">
						<ListItemIcon className={classes.listIcon}>
							<EmojiEvents />
						</ListItemIcon>
					</Tooltip>
				)}
				{mainOpen && (
					<ListItemIcon className={classes.listIcon}>
						<EmojiEvents />
					</ListItemIcon>
				)}
				<ListItemText primary={label} />
			</ListItem>
			<Hidden smDown>
				<Popper open={popperOpen} anchorEl={anchorEl} style={{ zIndex: 1400 }} placement="right-end" transition>
					<ClickAwayListener onClickAway={onBadgesCloseClick}>
						<Card className={classes.card} elevation={4}>
							<CardContent className={classes.contentSmall}>
								<BadgeRankingCard onBadgesCloseClick={onBadgesCloseClick} size="70px" />
							</CardContent>
						</Card>
					</ClickAwayListener>
				</Popper>
			</Hidden>
			<Hidden mdUp>
				<Drawer
					anchor="top"
					onClose={_ => {
						setPopperOpen(false);
					}}
					open={popperOpen}
				>
					<Card className={classes.card} elevation={4}>
						<CardContent className={classes.contentSmall}>
							<BadgeRankingCard onBadgesCloseClick={onBadgesCloseClick} size="66px" />
						</CardContent>
					</Card>
				</Drawer>
			</Hidden>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(BadgeRanking);
