import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import {
	Divider,
	Typography,
	Grid,
	CircularProgress,
	Button,
	Paper,
	TableContainer,
	Table,
	IconButton,
	Tooltip,
	Stack,
	Box
} from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import WarningIcon from '@mui/icons-material/Warning';
import DepotSelector from 'components/DepotSelector';
import CartSelector from 'components/CartSelector';
import CreateCart from 'routes/Orders7/CreateCart';
import WishlistSelector from 'components/WishlistSelector';
import NumericStepper from 'components/NumericStepper';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody';
import AddIcon from '@mui/icons-material/Add';
import WishlistCreate from 'components/WishlistCreate';
//import CreateCart from 'components/CreateCart';
import HeadlessCard from 'components/Card/HeadlessCard';
import Popover from 'components/Popover';
import styles from './styles';
import utils from 'components/utils';
import head from 'lodash.head';

const Selection = ({ classes, maxTableHeight, onCloseSelecionModal }) => {
	const theme = useTheme();
	const iCan = utils.usePermissions();
	const anchorWlEl = useStoreState(state => state.selection.anchorWlEl);
	const anchorCartEl = useStoreState(state => state.selection.anchorCartEl);
	const items = useStoreState(state => state.selection.items);
	const allFasthub = useStoreState(state => state.selection.allFasthub);
	const noType100 = useStoreState(state => state.selection.noType100);
	const noZ1 = useStoreState(state => state.selection.noZ1);
	const loadingCreateOrder = useStoreState(state => state.order.createMultiple.loading);
	const loadingAddToWishlist = useStoreState(state => state.wishlist.add.loading);
	const loadingWlLight = useStoreState(state => state.wishlist.listLight.loading);
	const loadingCartLight = useStoreState(state => state.cart.listLight.loading);
	const quantityForAll = useStoreState(state => state.selection.quantityForAll);
	const depotForAll = useStoreState(state => state.selection.depotForAll);
	const availableDepots = utils.useFilteredDepots();
	const competenceDepot = utils.useUserData('mainDepot', null);
	const mainDepotIndex = availableDepots.findIndex(d => d.description === competenceDepot.description);
	const mainDepot = availableDepots[mainDepotIndex];
	const orderedDepots = mainDepot
		? [mainDepot, ...availableDepots.slice(0, mainDepotIndex).concat(availableDepots.slice(mainDepotIndex + 1))]
		: availableDepots;
	const realUser = useStoreState(state => state.me.data);
	const setAnchorWlEl = useStoreActions(dispatch => dispatch.selection.setAnchorWlEl);
	const setAnchorCartEl = useStoreActions(dispatch => dispatch.selection.setAnchorCartEl);
	const selectQuantityForAll = useStoreActions(dispatch => dispatch.selection.selectQuantityForAll);
	const selectionResetForAll = useStoreActions(dispatch => dispatch.selection.resetForAll);
	const createOrder = useStoreActions(dispatch => dispatch.order.createMultiple.sendRequest);
	const addToWishlistAction = useStoreActions(dispatch => dispatch.wishlist.add.sendRequest);
	const setCartCreationScope = useStoreActions(dispatch => dispatch.cart.open.setScope);
	const incrementItemQuantity = useStoreActions(dispatch => dispatch.catalog.search.incrementItemQuantity);
	const loadCartList = useStoreActions(dispatch => dispatch.cart.listLight.load);
	const cartList = useStoreState(state => state.cart.listLight.items);
	const loadWlList = useStoreActions(dispatch => dispatch.wishlist.listLight.load);
	const wList = useStoreState(state => state.wishlist.listLight.items);
	const promoSearch = useStoreState(state => state.catalog.filters.promoSearch);
	const selectDepotForAll = useStoreActions(dispatch => dispatch.selection.selectDepotForAll);
	const [wlName, setWlName] = useState('');
	const [wlNotes, setWlNotes] = useState('');
	const history = useHistory();
	const params = useParams();

	const setSelectionModalOpen = useStoreActions(dispatch => dispatch.selection.setModalOpen);

	const onCloseSelecionModal2 = () => {
		setSelectionModalOpen(false);
	};

	if (history.location.pathname.includes('/catalog/search/details') && params.ean) {
		onCloseSelecionModal2();
	}
	useEffect(loadWlList, [loadWlList]);
	useEffect(loadCartList, [loadCartList]);
	// prettier-ignore
	useEffect(_ => { setCartCreationScope('selection'); }, [setCartCreationScope]);
	const openCart = Boolean(anchorCartEl);
	const openWl = Boolean(anchorWlEl);
	const translate = utils.useI18N();

	const total = items.map(item => item.fullPrice * item.selection.quantity).reduce((a, b) => a + b, 0);
	const title =
		items.length === 1
			? translate('catalog.selection.singleSelection')
			: translate('catalog.selection.multiSelection');
	const articleLabel =
		items.length === 1 ? translate('catalog.selection.article') : translate('catalog.selection.articles');
	const subtitle = `${translate('catalog.selection.numberSelected')} ${items.length} ${articleLabel}`;
	const onAddToCart = ({ fcd }) => {
		const cart = cartList.find(item => item.selected);
		const cartId = cart ? cart.id : null;
		const orders = items.map(item => {
			const selectedDepot = item.depots.find(itm => itm.id === item.selection.depot.id);

			const order = {
				catalog: item.ean,
				depot: item.selection.depot.id,
				cartId,
				quantity: item.selection.quantity,
				promoCode: promoSearch && item.promo && item.promo.length > 0 ? item.promo[0].sapId : null,
				realUserCode: realUser.id,
				totalPrice: total,
				sla: null,
				supplier: null,
				shippingType: null
			};

			if (selectedDepot && selectedDepot.shippingTypes && selectedDepot.shippingTypes.length) {
				const sla = selectedDepot.shippingTypes[0]?.sla;
				const supplier = selectedDepot.shippingTypes[0]?.supplier;
				const shippingType = selectedDepot.shippingTypes[0].code;

				order.sla = sla;
				order.supplier = supplier;
				order.shippingType = shippingType;
			}

			return order;
		});
		const successMessage = translate('catalog.selection.addToSelectedCart') + ' ' + cart.name;
		createOrder({
			orders,
			successMessage,
			history,
			fcd,
			callback: () => {
				orders.map(({ catalog, quantity }) => incrementItemQuantity({ ean: catalog, newQuantity: quantity }));
			}
		});
	};
	const addToWl = _ => {
		const wishlistItem = wList.find(item => item.selected);
		const wlistId = wishlistItem ? wishlistItem.id : null;
		const orders = items.map(item => ({
			ean: item.ean,
			depotCode: item.selection.depot.id,
			depotDescription: item.selection.depot.description,
			promotionSapId: promoSearch && item.promo && item.promo.length > 0 ? item.promo[0].sapId : null,
			quantity: item.selection.quantity,
			shippingType: item.depots.find(depot => depot.id === item.selection.depot.id).shippingTypes[0].code
		}));
		const wishlist = wlistId;
		const successMessage = translate('catalog.selection.addToSelectedWishlist') + ' ' + wishlistItem.name;
		addToWishlistAction({ orders, wishlist, successMessage, history, goBack: false });
	};
	const resetOnWlClose = _ => {
		setWlNotes('');
		setWlName('');
	};

	const fesOnDepot = depot => {
		const activeFESOnDepots = depot.find(d => d.fes);
		return {
			depotFes: activeFESOnDepots,
			fesActive: activeFESOnDepots ? 1 : 0
		};
	};
	const depotForAllFromDepotSelector = depotForAll ? depotForAll : head(orderedDepots).id;
	const fesOnDepAndQty = items.map(item => {
		let counter = 0;
		const depots = item.depots;
		const filteredFESDepots = fesOnDepot(depots);

		if (filteredFESDepots.fesActive) {
			if (depotForAllFromDepotSelector === item.selection.depot.id) {
				if (quantityForAll > item.selection.depot.stock) counter++;
			}
		}
		return counter;
	});
	const warningFESAndQty = fesOnDepAndQty.find(d => d === 1) ? true : false;

	//Verifico se tra gli elementi selezionati c'è almeno una card epipoli per gestire deposito e quantità per tutti
	const thereIsEpipoli = items.filter(item => item.type === 200).length > 0;
	//Se c'è almeno un elemento Epipoli selezioni Fast Hub come deposito per tutti
	useEffect(() => {
		if (thereIsEpipoli) {
			selectDepotForAll('FS01');
		}
	}, []);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={2}
			sx={{ width: '100%', padding: '10px 10px 20px 10px !important' }}
		>
			{/* Titolo e chiudi */}
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					borderWidth: '0 0 2px 0',
					borderStyle: 'solid',
					borderColor: theme.palette.primary.main
				}}
			>
				<Typography
					variant="titlePage"
					component="div"
					sx={{ width: '90%', color: theme.palette.primary.main }}
				>
					{title}
				</Typography>
				<IconButton
					className={classes.noRipple}
					onClick={_ => {
						onCloseSelecionModal();
						selectionResetForAll();
					}}
				>
					<CloseIcon />
				</IconButton>
			</Stack>
			<Typography variant="titleSection">
				{subtitle}. {translate('catalog.selection.chooseCart')}
			</Typography>
			{/* Tabella titoli selezionati */}
			<TableContainer
				sx={{
					maxHeight: maxTableHeight,
					borderStyle: 'solid',
					borderWidth: '0 0 1px 0',
					borderColor: theme.palette.custom.greyborder
				}}
			>
				<Table size="small" stickyHeader aria-label="sticky table">
					<TableHead />
					<TableBody />
				</Table>
			</TableContainer>
			<Typography
				variant="text"
				component="div"
				sx={{
					fontWeight: '600',
					textAlign: 'right',
					paddingRight: '10px !important',
					marginTop: '10px !important',
					width: '100%'
				}}
			>
				{`${translate('catalog.selection.totalSelection')}: ${utils.formatPrice(total)}`}
			</Typography>

			{/* Deposito per tutti */}
			<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ width: '100%' }}>
				<Typography variant="text">{translate('catalog.selection.depotForEveryone')}</Typography>
				<DepotSelector />
			</Stack>

			{/* Quantità per tutti */}
			<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ width: '100%' }}>
				<Typography variant="text">{translate('catalog.selection.quantityForEveryone')}</Typography>
				<Box sx={{ width: '90px' }}>
					<NumericStepper
						removeDot
						value={quantityForAll}
						onChange={numberAsValue => selectQuantityForAll(numberAsValue)}
						max={9999}
						precision={0}
						min={1}
						strict
					/>
				</Box>
			</Stack>
			{warningFESAndQty && (
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={1}
					sx={{ width: '100%' }}
				>
					<WarningIcon fontSize="small" sx={{ color: theme.palette.custom.orange }} />
					<Typography variant="text" sx={{ color: theme.palette.secondary.main }}>
						{translate('catalog.selection.warningTotalFES')}
					</Typography>
				</Stack>
			)}
			{/* Creazione carrello e creazione WL */}
			<Stack
				direction={{
					xs: 'column',
					md: 'row',
					sm: iCan('VIEW_FCD_BUTTON') && allFasthub && noType100 && noZ1 ? 'column' : 'row'
				}}
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				sx={{
					width: '100%',
					borderWidth: '1px 0 0 0',
					borderStyle: 'solid',
					borderColor: theme.palette.custom.greyborder,
					marginTop: '10px !important',
					padding: {
						xs: '10px 0 0 0 !important',
						md: '10px 5px 0 5px !important',
						sm:
							iCan('VIEW_FCD_BUTTON') && allFasthub && noType100 && noZ1
								? '10px 0 0 0 !important'
								: '10px 5px 0 5px !important'
					}
				}}
			>
				{/* Creazione carrello */}
				<Stack
					direction={{
						xs: iCan('VIEW_FCD_BUTTON') && allFasthub && noType100 && noZ1 ? 'column' : 'row',
						md: 'row',
						sm: 'row'
					}}
					justifyContent="flex-start"
					alignItems={{ xs: 'flex-start', md: 'center', sm: 'center' }}
					spacing={0.5}
					sx={{ width: { xs: '100%', md: '50%', sm: '100%' } }}
				>
					{(loadingCreateOrder || loadingCartLight) && <CircularProgress size={16} />}
					{!loadingCreateOrder && !loadingCartLight && (
						<Fragment>
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
								<CartSelector />
								<Tooltip title={translate('orders.createCart')} placement="top">
									<IconButton
										onClick={e => {
											setAnchorCartEl(e.currentTarget);
										}}
										sx={{ color: theme.palette.primary.main }}
									>
										<AddCircleIcon sx={{ fontSize: '1.2em' }} />
									</IconButton>
								</Tooltip>
							</Stack>
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
								<Button
									variant="dark"
									onClick={_ => onAddToCart({ fcd: false })}
									endIcon={<AddShoppingCartIcon />}
								>
									carrello
								</Button>
								{iCan('VIEW_FCD_BUTTON') && allFasthub && noType100 && noZ1 && (
									<Button
										variant="light"
										onClick={_ => onAddToCart({ fcd: true })}
										endIcon={<EyeIcon />}
									>
										conto visione
									</Button>
								)}
							</Stack>
							<Popover
								open={openCart}
								anchorEl={anchorCartEl}
								onClose={_ => {
									setAnchorCartEl(null);
								}}
							>
								<CreateCart
									showSave={true}
									showClose={true}
									onClose={_ => {
										setAnchorCartEl(null);
									}}
								/>
							</Popover>
						</Fragment>
					)}
				</Stack>

				{/* Creazione WL */}
				<Stack
					direction="row"
					justifyContent={{
						xs: 'flex-start',
						md: 'flex-end',
						sm: iCan('VIEW_FCD_BUTTON') && allFasthub && noType100 && noZ1 ? 'flex-start' : 'flex-end'
					}}
					alignItems="center"
					spacing={0.5}
					sx={{ width: { xs: '100%', md: '50%', sm: '100%' } }}
				>
					{(loadingAddToWishlist || loadingWlLight) && <CircularProgress size={16} />}
					{!loadingAddToWishlist && !loadingWlLight && (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							spacing={0.5}
							sx={{
								width: {
									xs: '100%',
									md: '50%',
									sm: iCan('VIEW_FCD_BUTTON') && allFasthub && noType100 && noZ1 ? '100%' : '90%'
								}
							}}
						>
							<WishlistSelector />
							<Tooltip title={translate('wishlist.createWl')} placement="top">
								<IconButton
									onClick={e => {
										setAnchorWlEl(e.currentTarget);
									}}
									sx={{ color: theme.palette.primary.main }}
								>
									<AddCircleIcon sx={{ fontSize: '1.2em' }} />
								</IconButton>
							</Tooltip>
							<Button
								variant="dark"
								onClick={addToWl}
								endIcon={<FavoriteIcon />}
								sx={{ padding: '7px 21px' }}
							>
								wishlist
							</Button>
							<Popover
								open={openWl}
								anchorEl={anchorWlEl}
								onClose={_ => {
									setAnchorWlEl(null);
									resetOnWlClose();
								}}
							>
								<WishlistCreate
									resetOnSave={resetOnWlClose}
									onClose={_ => {
										setAnchorWlEl(null);
										resetOnWlClose();
									}}
									onNoteChange={e => {
										setWlNotes(e.target.value);
									}}
									defaultNotesValue={wlNotes}
									defaultNameValue={wlName}
									onNameChange={e => {
										setWlName(e.target.value);
									}}
								/>
							</Popover>
						</Stack>
					)}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(Selection);
