import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import UserBox from './UserBox';
import utils from 'components/utils';
import Tooltip from '@mui/material/Tooltip';

const styles = theme => ({
	noRipple: {
		paddingRight: 0,
		'&:hover': {
			background: 'none'
		}
	},
	displayName: {
		margin: 'auto',
		left: 0,
		right: 0,
		textAlign: 'right'
	},
	outerDiv: {
		display: 'flex'
	},
	personifyingStyle: {
		border: '2px solid red',
		width: 55,
		height: 55,
		alignSelf: 'center',
		marginLeft: 5,
		paddingRight: 12
	}
});

const Identity = ({ classes }) => {
	const history = useHistory();
	const displayName = utils.useUserData('displayName', null);
	const id = utils.useUserData('id', null);
	const [iAmPersonifying] = utils.usePersonification();
	const [anchorEl, setAnchorEl] = useState(null);
	const popperOpen = useStoreState(state => state.me.popperOpen);
	const setPopperOpen = useStoreActions(dispatch => dispatch.me.setPopperOpen);
	useEffect(
		_ => {
			setPopperOpen(false);
		},
		[setPopperOpen, history]
	);
	useEffect(
		_ => {
			const onResize = _ => {
				if (popperOpen) setPopperOpen(false);
				setAnchorEl(null);
			};
			window.addEventListener('resize', onResize);
			window.addEventListener('scroll', onResize);
			return _ => {
				window.removeEventListener('resize', onResize);
				window.removeEventListener('scroll', onResize);
			};
		},
		[setPopperOpen, popperOpen, setAnchorEl]
	);
	return (
		<Fragment>
			<Tooltip title={displayName} placement="bottom">
				<div
					className={classes.outerDiv}
					onClick={e => {
						setAnchorEl(e.currentTarget);
						setPopperOpen(true);
					}}
				>
					{displayName && (
						<Typography className={classes.displayName} variant="body2" align="center" component="p">
							{utils.capitalizeAll(utils.useEllipsis(displayName, 20, '...'))}
							<br />
							<small>({id})</small>
						</Typography>
					)}
					<IconButton
						disableRipple
						className={clsx(classes.noRipple, { [classes.personifyingStyle]: iAmPersonifying })}
					>
						<Avatar size={25} forceLogged={true} />
					</IconButton>
				</div>
			</Tooltip>
			<Popper style={{ zIndex: 1101 }} open={popperOpen} anchorEl={anchorEl} placement="bottom-end" transition>
				<UserBox />
			</Popper>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Identity);
