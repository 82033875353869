import store from 'store';

const returnAccepted = history => {
	store.dispatch({
		type: '@action.returns.setTabSelected',
		payload: 2
	});
	history.push('/returns');
};

export default returnAccepted;
