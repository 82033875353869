import React, { memo } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import CartIcon from '@mui/icons-material/ShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import utils from 'components/utils';

const OneClickComp = ({ ordinable, ean, type }) => {
	const iCan = utils.usePermissions();
	const competenceDepot = utils.useUserData('mainDepot', null);
	const favouriteItem = useStoreState(state => state.cart.favourite.item);
	const isLoading = useStoreState(state => state.order.oneClick.loads[ean]);
	const add = useStoreActions(dispatch => dispatch.order.oneClick.add);
	const adding = useStoreState(state => state.order.oneClick.adding);
	const translate = utils.useI18N();
	const useEpioli = iCan('MANAGE_EPIPOLI'); //utils.useEpipoli();
	const isOrderable = ordinable === 2;
	const hasFavourite = favouriteItem && favouriteItem.item && favouriteItem.item.id !== null;
	const hasFavCart = hasFavourite
		? translate('catalog.oneClickWithFavouriteCart')
		: translate('catalog.oneClickWithNoFavouriteCart');
	const successMessage =
		hasFavCart +
		', ' +
		translate('catalog.selection.withDepot') +
		' ' +
		utils.capitalizeAll(type === 200 ? 'fast hub' : competenceDepot.description);
	const canEpipoli = type !== 200 || (type === 200 && useEpioli);
	const cannotOperateOneClick = !iCan('ONECLICK_WITHOUT_FAVOURITE') && !hasFavourite;
	const title = hasFavourite
		? translate('catalog.addToSelectedCart')
		: cannotOperateOneClick
		? translate('catalog.oneClickPaMessage')
		: translate('catalog.addToSelectedCartOneClick');
	const onClick = _ => {
		add({ ean, successMessage });
	};
	const type100WOFasthub = competenceDepot.id !== 'FS01' && type === 100;

	return (
		<TableCell padding="checkbox">
			{adding === ean && <CircularProgress size={16} />}
			{isOrderable && !isLoading && !type100WOFasthub && canEpipoli && !adding && (
				<Tooltip title={title} placement="left-end">
					<span>
						<IconButton
							disabled={cannotOperateOneClick}
							aria-label="add to cart"
							style={{ paddingLeft: 2 }}
							onClick={onClick}
						>
							<CartIcon fontSize="small" />
						</IconButton>
					</span>
				</Tooltip>
			)}
			{isOrderable && type100WOFasthub && canEpipoli && (
				<Tooltip
					title="Il deposito di competenza non è Fasthub, non puoi inserire un prodotto introvabile tramite One Click"
					placement="left-end"
				>
					<IconButton aria-label="product not orderable" style={{ paddingLeft: 2 }}>
						<RemoveShoppingCartIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			)}
			{isOrderable && isLoading && <CircularProgress color="inherit" size={14} />}
			{!isOrderable && (
				<Tooltip title={translate('catalog.fastAddUnable')} placement="left-end">
					<IconButton aria-label="product not orderable" style={{ paddingLeft: 2 }}>
						<RemoveShoppingCartIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			)}
		</TableCell>
	);
};

const OneClick = memo(
	({ item }) => <OneClickComp ordinable={item.ordinable} ean={item.ean} type={item.type} />,
	(p, n) => p.item.ordinable === n.item.ordinable && p.item.ean === n.item.ean && p.item.type === n.item.type
);

export default OneClick;
