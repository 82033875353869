import k from 'k';
import utils from 'services/utils';

export default ({
	catalog,
	depot,
	cartId,
	sla,
	source,
	shippingType,
	supplier,
	quantity,
	note,
	promoCode,
	realUserCode,
	fcd,
	totalPrice
}) => {
	const body = {
		catalog,
		depot,
		sla,
		source,
		shippingType,
		supplier,
		cartId,
		quantity,
		note,
		promoCode,
		realUserCode,
		totalPrice
	};
	if (fcd) body.transferType = 'FCD';
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(body),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/order`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
