import React, { Fragment, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory, useParams } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers';
import { withStyles, makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Card from 'components/Card';
import NewCard from 'components7/Card';
import Import from './components/Import';
import AssignArticleBtn from './components/AssignArticleBtn';
import AssignUserGroup from './components/AssignUserGroup';
import { UserGroupsTableVisualization, UserGroupsTableAbilitation } from './components/Table/UserGroupsTable';
import ItemsTable from './components/Table/ItemsTable';
import utils from 'components/utils';
import styles from './styles';
import Typography from '@mui/material/Typography';
import BackToButton from 'components/BackToButton';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTheme } from '@mui/material/styles';

const makeDatePickerStyles = makeStyles({
	root: {
		width: 180
	}
});

const PromotionsDetails = ({ classes }) => {
	const history = useHistory();
	const theme = useTheme();
	const translate = utils.useI18N();
	const load = useStoreActions(dispatch => dispatch.promotions.details.load);
	const data = useStoreState(state => state.promotions.details.data);
	const loadingUpdate = useStoreState(state => state.promotions.update.loading);
	const update = useStoreActions(dispatch => dispatch.promotions.update.sendRequest);
	const params = useParams();
	const setEnabled = useStoreActions(dispatch => dispatch.promotions.details.setEnabled);
	const setEnabledCatalog = useStoreActions(dispatch => dispatch.promotions.details.setEnabledCatalog);
	const setEnableWishlist = useStoreActions(dispatch => dispatch.promotions.details.setEnableWishlist);
	const setRequiredApproval = useStoreActions(dispatch => dispatch.promotions.details.setRequiredApproval);
	const setStartDate = useStoreActions(dispatch => dispatch.promotions.details.setStartDate);
	const setEndDate = useStoreActions(dispatch => dispatch.promotions.details.setEndDate);
	const datePickerStyles = makeDatePickerStyles();
	const now = moment();
	let isTodayExceeded = now.isAfter(data.endDate);
	const onSaveClick = _ => {
		update({
			history,
			sapId: params.id,
			data
		});
	};
	useEffect(
		_ => {
			load(params.id);
		},
		[load, params]
	);
	return (
		<Fragment>
			{data && data.id && (
				<Card
					type="primary"
					title={`${translate('promo.details.title')} / ${data.name}`}
					style={{ marginTop: 0 }}
				>
					<Grid item container xs={12} spacing={4}>
						<Grid item container xs={12} justifyContent="flex-start">
							<Grid container item xs={12}>
								<AssignUserGroup currentPromo={data} />
								<Import sapId={data.sapPromoId} />
								<AssignArticleBtn currentPromo={data} />
								<BackToButton
									to={`/admin/promotions`}
									style={{ margin: '0 1 0 0' }}
									label={`backToPromotions`}
								/>
							</Grid>
						</Grid>
						<Grid xs={12} item>
							<NewCard backgroundColor={'white'} title={`${translate('rankings.attributes')}`}>
								<CardContent>
									<Grid container spacing={1}>
										<Grid container item xs={12}>
											<Grid container item md={12} xs={12} justifyContent="flex-start">
												{data.description && (
													<Typography
														align="left"
														variant="overline"
														style={{ fontWeight: 'bold' }}
													>
														{translate('coupons.description') + ': ' + data.description}
													</Typography>
												)}
											</Grid>
											<Grid container item xs={12} md={6} className={classes.justifyAttributes}>
												<Grid item container xs={12} sm={6}>
													<FormControlLabel
														className={classes.labelPlacementStart}
														value="start"
														control={
															<Checkbox
																style={{ paddingBottom: 0 }}
																color="primary"
																size="medium"
																checked={data.enabled}
																onChange={e => setEnabled(e.target.checked)}
															/>
														}
														label={translate('promo.tables.enabled')}
														labelPlacement="end"
														style={{ alignItems: 'flex-end' }}
													/>
												</Grid>
												<Grid container item xs={12} sm={6}>
													<FormControlLabel
														className={classes.approvalLabel}
														value="start"
														control={
															<Checkbox
																style={{ paddingBottom: 0 }}
																color="primary"
																size="medium"
																checked={data.requiredApproval}
																onChange={e => setRequiredApproval(e.target.checked)}
															/>
														}
														label="Richiesta approvazione"
														labelPlacement="end"
													/>
												</Grid>
												<Grid item container xs={12} sm={6}>
													<FormControlLabel
														className={classes.labelPlacementStart}
														value="start"
														control={
															<Checkbox
																style={{ paddingBottom: 0 }}
																color="primary"
																size="medium"
																checked={data.enabledCatalog}
																onChange={e => setEnabledCatalog(e.target.checked)}
															/>
														}
														label="Stato Catalogo"
														labelPlacement="end"
														style={{ alignItems: 'flex-end' }}
													/>
												</Grid>
												<Grid container item xs={12} sm={6}>
													<FormControlLabel
														className={classes.labelPlacementStart}
														value="start"
														control={
															<Checkbox
																style={{ paddingBottom: 0 }}
																color="primary"
																size="medium"
																checked={data.enabledWishlist}
																onChange={e => setEnableWishlist(e.target.checked)}
															/>
														}
														label="Stato Wishlist"
														labelPlacement="end"
														style={{ alignItems: 'flex-end' }}
													/>
												</Grid>
											</Grid>
											<LocalizationProvider dateAdapter={AdapterMoment}>
												<Grid
													container
													item
													xs={12}
													md={6}
													className={classes.justifyAttributes}
													style={{ alignSelf: 'flex-end' }}
												>
													<Grid
														container
														item
														xs={12}
														sm={6}
														justifyContent="center"
														className={classes.pickerContainer}
													>
														<DateTimePicker
															// className={datePickerStyles.root}
															label={translate('promo.dateFrom')}
															value={data.startDate ? moment(data.startDate) : null}
															onChange={setStartDate}
															autoOk
															ampm={false}
															format="D MMMM YYYY HH:mm"
															/*
															disabilito la possibilita di scegliere date passate
															ma non inserisco warning
															*/
															disablePast
															helperText=""
															error={false}
														/>
													</Grid>
													<Grid
														container
														item
														xs={12}
														sm={6}
														className={classes.pickerContainer}
													>
														<DateTimePicker
															// className={datePickerStyles.root}
															label={translate('promo.dateTo')}
															value={data.endDate ? moment(data.endDate) : null}
															onChange={setEndDate}
															autoOk
															ampm={false}
															format="D MMMM YYYY HH:mm"
															/*
															disabilito la possibilita di scegliere date passate
															ed inserisco warning con label
															*/
															disablePast
															error={isTodayExceeded}
															helperText={
																isTodayExceeded ? translate('common.dateExpired') : ''
															}
														/>
													</Grid>
												</Grid>
											</LocalizationProvider>
										</Grid>
										<Grid
											container
											item
											xs={12}
											justifyContent="flex-end"
											style={{ marginTop: 24 }}
										>
											{loadingUpdate && <CircularProgress size={16} />}
											{!loadingUpdate && (
												<Button variant="contained" color="primary" onClick={onSaveClick}>
													{translate('common.save')}
												</Button>
											)}
										</Grid>
									</Grid>
								</CardContent>
							</NewCard>
						</Grid>
						<Grid item container spacing={2}>
							<Grid item lg={6} xs={12}>
								<NewCard backgroundColor={'white'} title="Gruppi di utenti assegnati (visualizzazione)">
									<CardContent>
										<UserGroupsTableVisualization />
									</CardContent>
								</NewCard>
							</Grid>
							<Grid item lg={6} xs={12}>
								<NewCard backgroundColor={'white'} title="Gruppi di utenti assegnati (abilitazione)">
									<CardContent>
										<UserGroupsTableAbilitation />
									</CardContent>
								</NewCard>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<NewCard backgroundColor={'white'} title="Articoli assegnati">
								<CardContent>
									<ItemsTable />
								</CardContent>
							</NewCard>
						</Grid>
					</Grid>
				</Card>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(PromotionsDetails);
