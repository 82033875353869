import k from 'k';
import utils from 'services/utils';

export default params => {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params),
		headers: utils.createHeaders()
	};
	return utils.fetch(`${k.endpoints.apis}/cart`, requestOptions).then(response => utils.handleResponse({ response }));
};
