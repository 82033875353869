import React, { useEffect, useState, useCallback } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, ListItemText, Checkbox, InputLabel, OutlinedInput } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import utils from 'components/utils';

const SelectScope = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const waiting = useStoreState(state => state.cart.search.filters.scopes.waiting);
	const pending = useStoreState(state => state.cart.search.filters.scopes.pending);
	const evadible = useStoreState(state => state.cart.search.filters.scopes.evadible);
	const setWaiting = useStoreActions(dispatch => dispatch.cart.search.setWaiting);
	const setPending = useStoreActions(dispatch => dispatch.cart.search.setPending);
	const setEvadible = useStoreActions(dispatch => dispatch.cart.search.setEvadible);
	const selectScopeValue = useStoreState(state => state.cart.search.selectScopeValue);
	const setSelectScopeValue = useStoreActions(dispatch => dispatch.cart.search.setSelectScopeValue);
	const [scopeValue, setScopeValue] = useState([]);

	const scopes = [
		{ id: 0, label: translate('orders.filters.waiting'), checked: waiting },
		{ id: 1, label: translate('orders.filters.pending'), checked: pending },
		{ id: 2, label: translate('orders.filters.evadible'), checked: evadible }
	];

	const handleChange = event => {
		const targetValue = event.target.value;
		const value = targetValue.filter(val => typeof val !== 'string');
		if (selectScopeValue.some(d => d == value[0].label))
			setSelectScopeValue(selectScopeValue.filter(d => d !== value[0].label));
		else setSelectScopeValue([...selectScopeValue, value[0].label]);
		switch (value[0].id) {
			case 0:
				setWaiting(!waiting);
				break;
			case 1:
				setPending(!pending);
				break;
			case 2:
				setEvadible(!evadible);
				break;
		}
	};

	return (
		<FormControl sx={{ width: '250px' }}>
			<InputLabel
				id="filter-select-scope"
				sx={{
					fontFamily: ['"Poppins", sans-serif'].join(','),
					fontSize: '14px',
					marginTop: '-5px'
				}}
			>
				{translate('orders.filters.status')}
			</InputLabel>
			<Select
				labelId="filter-select-scope"
				id="select-scope"
				multiple
				value={selectScopeValue}
				onChange={handleChange}
				input={<OutlinedInput label={translate('orders.filters.status')} />}
				renderValue={selected => selected.join(', ')}
				sx={{
					height: '40px',
					fontFamily: ['"Poppins", sans-serif'].join(','),
					fontSize: '14px',
					fontWeight: '400',
					backgroundColor: theme.palette.custom.white,
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: selectScopeValue.length > 0 ? theme.palette.primary.main : '#cccdce'
				}}
			>
				{scopes.map(item => (
					<MenuItem key={item.id} value={{ id: item.id, label: item.label }}>
						<Checkbox checked={item.checked} value={item.id} />
						<ListItemText
							primary={item.label}
							sx={{
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								fontWeight: '400'
							}}
						/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectScope;
