import React, { Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { Hidden } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import CtaModal from './CtaModal';
import styles from '../styles';

const CtaUrl = withStyles(styles, { withTheme: true })(({ classes, banner }) => {
	return (
		<a href={banner.ctaTypeValue} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
			<Button
				style={{ color: `${banner.textBackgroundColor.hex}` }}
				className={classes.cta}
				disableRipple
				variant="contained"
				color="secondary"
			>
				{banner.ctaText}
			</Button>
		</a>
	);
});
const CtaPopUp = withStyles(styles, { withTheme: true })(({ classes, banner }) => {
	const modalOpen = useStoreState(state => state.cms.banners.modalOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.cms.banners.setModalOpen);
	const style = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	};

	return (
		<Fragment>
			<Hidden smDown>
				<Modal
					style={style}
					open={modalOpen}
					onClose={_ => {
						setModalOpen(false);
					}}
				>
					<div className="modal-content">
						<Fragment>
							<CtaModal modalMessage={banner.ctaTypeValue} modalColor={banner.popupBackgroundColor.hex} />
						</Fragment>
					</div>
				</Modal>
			</Hidden>
			<Hidden mdUp>
				<Drawer anchor="bottom" open={modalOpen} onClose={_ => setModalOpen(false)}>
					<CtaModal
						modalMessage={banner.ctaTypeValue}
						modalColor={banner.popupBackgroundColor.hex}
						onClose={_ => setModalOpen(false)}
					/>
				</Drawer>
			</Hidden>
			<Button
				style={{ color: `${banner.textBackgroundColor.hex}` }}
				disableRipple
				className={classes.cta}
				variant="contained"
				color="secondary"
				onClick={_ => {
					setModalOpen(true);
				}}
			>
				{banner.ctaText}
			</Button>
		</Fragment>
	);
});

export const CtaDetails = ({ banner }) => {
	return (
		<Fragment>
			{banner.ctaType.title === 'url_link' && <CtaUrl banner={banner} />}
			{banner.ctaType.title === 'details_text' && <CtaPopUp banner={banner} />}
		</Fragment>
	);
};
