import { alpha } from '@mui/material/styles';

export default theme => ({
	card: {
		width: 400,
		margin: 'auto',
		transition: '0.3s',
		boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
		'&:hover': {
			boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
		}
	},
	content: {
		textAlign: 'center',
		padding: theme.spacing(),
		paddingTop: theme.spacing(3),
		'&:last-child': {
			paddingBottom: theme.spacing()
		}
	},
	divider: {
		margin: `${theme.spacing()}px 0`
	},
	heading: {
		marginTop: `${theme.spacing(2)}px`,
		fontWeight: 'bold'
	},
	token: {
		wordBreak: 'break-all'
	},
	search: {
		display: 'flex',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.black, 0.25)
		},
		width: '100%'
	},
	searchIcon: {
		padding: theme.spacing(1, 0, 0, 1),
		height: '100%',
		pointerEvents: 'none'
	},
	autocompleteInput: {
		'&:first-child': {
			paddingTop: 4
		}
	},
	left: {
		textAlign: 'left'
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	}
});
