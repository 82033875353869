import React from 'react';
import Sortable from './Sortable';
import Empty from './Empty';
import Selection from './Selection';

const allCols = [
	{
		id: 'selection',
		comp: _ => <Selection key="headCell_selection_editorial" />
	},
	{
		id: 'id',
		comp: _ => <Sortable id="id" key="headCell_id_editorial" label="editorials.tables.id" />
	},
	{
		id: 'sapName',
		comp: _ => <Sortable id="nameOrder" key="headCell_sapName_editorial" label="editorials.tables.name" />
	},
	{
		id: 'status',
		comp: _ => <Sortable id="enabled" key="headCell_status_editorial" label="editorials.tables.status" />
	},
	{
		id: 'startDate',
		comp: _ => <Sortable id="startDate" key="headCell_startDate_coupon" label="coupons.tables.startDate" />
	},
	{
		id: 'edit',
		comp: _ => <Empty id="endDate" key="headCell_edit_editorial" label="editorials.tables.edit" />
	}
];

export default allCols;
