import React from 'react';
import Empty from './Empty';
import Sortable from './Sortable';
import Selection from './Selection';

const allCols = [
	{
		id: 'selection',
		comp: ({ type, status }) => <Selection key="headCell_selection" wltype={type} status={status} />
	},
	{
		id: 'deletee',
		comp: _ => <Empty id="delete" key="headCell_delete" additionalStyles={{ width: 30 }} />
	},
	{
		id: 'catalog',
		comp: _ => <Sortable id="ean" key="headCell_catalog" label="wishlist.tables.ean" />
	},
	{
		id: 'author',
		comp: _ => <Sortable id="author" key="headCell_author" label="wishlist.tables.author" />
	},
	{
		id: 'epipoli',
		comp: _ => <Empty key="headCell_preload" additionalStyles={{ width: 20 }} />
	},
	{
		id: 'title',
		comp: _ => (
			<Sortable
				id="title"
				key="headCell_title"
				label="catalog.tableHeaders.title"
				additionalStyles={{ maxWidth: 200 }}
			/>
		)
	},
	{
		id: 'volume',
		comp: _ => (
			<Sortable
				id="volume"
				key="headCell_volume"
				label="catalog.tableHeaders.volume"
				additionalStyles={{ maxWidth: 50 }}
			/>
		)
	},
	{
		id: 'series',
		comp: _ => <Sortable id="serie" key="headCell_series" label="wishlist.tables.serie" />
	},
	{
		id: 'publisher',
		comp: _ => <Sortable id="publisher" key="headCell_publisher" label="wishlist.tables.publisher" />
	},
	{
		id: 'price',
		comp: _ => (
			<Sortable
				id="fullPrice"
				key="headCell_price"
				label="catalog.tableHeaders.price"
				additionalStyles={{ width: 100 }}
			/>
		)
	},
	{
		id: 'promo',
		comp: _ => <Empty key="headCell_promo" additionalStyles={{ width: 30 }} />
	},
	{
		id: 'news',
		comp: _ => <Sortable id="newsItem" key="headCell_newsItem" label="wishlist.tables.news" />
	},
	{
		id: 'depot',
		comp: _ => <Sortable id="selectedDepot" key="headCell_depot" label="wishlist.tables.depot" />
	},
	{
		id: 'evadibleQty',
		comp: _ => <Sortable id="depots" key="headCell_evadibleQty" label="wishlist.tables.availableQty" />
	},
	{
		id: 'quantity',
		comp: _ => <Sortable id="quantity" key="headCell_quantity" label="wishlist.tables.quantity" />
	},
	{
		id: 'edit',
		comp: _ => <Empty id="edit" key="headCell_edit" additionalStyles={{ width: 50 }} />
	}
];

export default allCols;
