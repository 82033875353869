import k from 'k';
import utils from 'services/utils';

export default sapId => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	return utils
		.fetch(
			`${k.endpoints.apis}/backoffice/catalog-groups/promotions/${sapId}/abilitation/user-groups`,
			requestOptions
		)
		.then(response => utils.handleResponse({ response }));
};
