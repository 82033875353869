export default theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalPaper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		width: 750
	},
	modalLabels: {
		marginBottom: theme.spacing()
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0)
	},
	dividerColored: {
		backgroundColor: '#00529b',
		marginBottom: theme.spacing(3)
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	}
});
