import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const opts = {
	parseNumbers: true,
	parseBooleans: true
};

export default WrappedComponent => {
	return props => {
		const location = useLocation();
		const search = queryString.parse(location.search, opts);
		const hash = queryString.parse(location.hash, opts);
		return <WrappedComponent {...props} search={search} hash={hash} />;
	};
};
