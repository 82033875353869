import React, { useState, useEffect, Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import clone from 'lodash/clone';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { TextField, Stack, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NumericStepper from 'components/NumericStepper';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const styles = theme => ({
	custom: {
		'&:hover': {
			background: 'none'
		}
	},
	card: {
		maxWidth: 450,
		margin: 'auto',
		transition: '0.3s'
	},
	content: {
		textAlign: 'left',
		padding: theme.spacing(0),
		'&:last-child': {
			paddingBottom: 0
		},
		'& a': {
			paddingLeft: theme.spacing(1.5)
		}
	},
	modalPosition: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
});

const Edit = props => {
	const { itemOrder, currentCart, type } = props;
	const translate = utils.useI18N();
	const theme = useTheme();
	const loading = useStoreState(state => state.order.update.loading);
	const save = useStoreActions(dispatch => dispatch.order.update.sendRequest);
	const [localItem, setLocalItem] = useState(clone(itemOrder));
	const [warning1000, setWarning1000] = useState(type === 200 ? localItem.quantityRequired >= 20 : false);
	const iCan = utils.usePermissions();
	const [disableButton, setDisableButton] = useState();

	useEffect(
		_ => {
			if (localItem.statusDesc === 'IN_AGGIORNAMENTO' || currentCart.type === 'BOOK2C') {
				setDisableButton(true);
			} else {
				setDisableButton(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[localItem]
	);

	const onStepperChange = valueAsNumber => {
		setLocalItem({
			...localItem,
			quantityRequired: valueAsNumber
		});
		if (!localItem.fes) setWarning1000(valueAsNumber === 999);
		if (type === 200) setWarning1000(valueAsNumber === 20);
	};
	const getMaxValue = _ => {
		if (type === 200) return 20;
		if (localItem.fes) return localItem.stock;
		if (!localItem.fes) return 999;
	};
	const onNoteChange = event => {
		setLocalItem({
			...localItem,
			notes: event.target.value
		});
	};
	const onSave = event => {
		save({
			orderCode: localItem.code,
			quantity: localItem.quantityRequired,
			note: localItem.notes,
			cartId: localItem.cartId,
			amca: iCan('OPERATE_ON_MULTIPLE_CA')
		});
	};

	if (itemOrder.fes === null && itemOrder.stock === null) {
		return (
			<StyledTableCell>
				<Tooltip title="Non puoi modificare le quantità di questo prodotto">
					<IconButton aria-label="warning evasion" style={{ padding: 0 }}>
						<EditIcon fontSize="small" disabled={true} />
					</IconButton>
				</Tooltip>
			</StyledTableCell>
		);
	}
	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'row' }}
				justifyContent="flex-start"
				alignItems={{ xs: 'flex-start', md: 'center', sm: 'center' }}
				spacing={1}
				sx={{ width: '100%' }}
			>
				<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
					<Typography variant="text">{translate('common.quantity')}</Typography>
					<Box sx={{ maxWidth: '90px' }}>
						<NumericStepper
							min={1}
							removeDot
							precision={0}
							strict
							max={getMaxValue}
							step={1}
							value={localItem.quantityRequired}
							onChange={onStepperChange}
						/>
					</Box>
				</Stack>
				{warning1000 && (
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={1}
						sx={{ width: '100%' }}
					>
						<WarningIcon fontSize="small" sx={{ color: theme.palette.custom.orange }} />
						<Typography variant="text" sx={{ color: theme.palette.secondary.main }}>
							{translate('catalog.selection.orderableLimit') + getMaxValue()}
						</Typography>
					</Stack>
				)}
			</Stack>
			{localItem.fes && (
				<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
					<WarningIcon fontSize="small" sx={{ color: theme.palette.custom.orange }} />
					<Typography variant="text" sx={{ color: theme.palette.secondary.main }}>
						{translate('catalog.selection.warningFES')}
					</Typography>
				</Stack>
			)}
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{ width: '100%' }}
			>
				<TextField
					multiline
					maxRows={5}
					rows={3}
					sx={{ width: '70%', resize: 'none' }}
					aria-label="warning message"
					placeholder="note"
					defaultValue={localItem.notes}
					onChange={onNoteChange}
				/>
				{loading && <CircularProgress size={14} style={{ marginLeft: 90, marginBottom: 30 }} />}
				{!loading && (
					<Button onClick={onSave} variant="dark" disabled={disableButton} type="submit">
						{translate('common.save')}
					</Button>
				)}
			</Stack>
		</Stack>
	);
};

export default Edit;
