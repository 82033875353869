import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Tooltip } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WhereToVote from '@mui/icons-material/WhereToVote';
import keys from 'lodash/keys';
import utils from 'components/utils';
import CartInfo from '../CartInfo';
import ProcessingOrdersInfoDetail from './ProcessingOrdersInfoDetail';

const ProcessigOrdersAccordionSummary = props => {
	const { order } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const price = order.resumeOrder && order.resumeOrder.sumCostProcessable;
	const mappedItems = order.orderItems.map(item => Boolean(item.promoCode));
	const hasPromo = mappedItems.some(item => item);
	const shippingNumber = order.resumeOrder.shippingResumeOrder
		? keys(order.resumeOrder.shippingResumeOrder).filter(k => k !== 'NO_SHIPPING_CODE').length > 0
			? keys(order.resumeOrder.shippingResumeOrder)
					.filter(k => k !== 'NO_SHIPPING_CODE')
					.toString()
			: null
		: null;
	const tooltipText = translate('orders.tables.shippingTrackingTitle') + ' ' + shippingNumber;

	return (
		<Stack
			direction={{ xs: 'row', nd: 'row', sm: 'row' }}
			justifyContent="flex-start"
			alignItems="center"
			spacing={1}
			sx={{ width: '100%' }}
		>
			{/* Nome e data aggiornamento */}
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{ width: { xs: '60%', md: '30%', sm: '70%' } }}
			>
				<Typography
					variant="titleAccordion"
					sx={{
						color: order.type === 'BOOK2C' ? theme.palette.custom.book2c : theme.palette.primary.black
					}}
				>
					{order.name !== '' ? order.name : translate('orders.defaultCart')}
				</Typography>
				<Typography variant="text" sx={{ fontSize: '13px' }}>
					{order.fullfilTime.slice(0, -3)}
				</Typography>
			</Stack>

			{/* Icone info */}
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={0}
				sx={{
					display: { xs: 'none', md: 'inherit', sm: 'none' },
					width: { xs: '0%', md: '30%', sm: '0%' }
				}}
			>
				{iCan('OPERATE_PA_CARTS') && !order.isSAP && !order.isDefault && order.type !== 'BOOK2C' && (
					<Tooltip variant="light" arrow followCursor placement="top" title={<CartInfo order={order} />}>
						<AssignmentIcon sx={{ fontSize: '28px' }} />
					</Tooltip>
				)}
				<ProcessingOrdersInfoDetail order={order} />

				{/*order.fcd && ( -> inserite chips per esteso al posto delle icone
					<Tooltip
						variant="light"
						arrow
						followCursor
						placement="top"
						title={<Typography variant="tooltip">fcd</Typography>}
					>
						<Typography variant="chip" sx={{ backgroundColor: theme.palette.custom.black }}>
							FCD
						</Typography>
					</Tooltip>
				)*/}
				{/*hasPromo && <SellIcon sx={{ color: theme.palette.custom.green }} />*/}
			</Stack>

			{/* Totale */}
			<Stack
				direction="column"
				justifyContent={{ xs: 'center', md: 'center', sm: 'center' }}
				alignItems={{ xs: 'center', md: 'center', sm: 'center' }}
				spacing={0}
				sx={{ width: { xs: '50%', md: '30%', sm: '30%' } }}
			>
				<Typography variant="text" sx={{ fontWeight: '600' }}>
					{order.resumeOrder.sumOfProcessableCatalog + ' / ' + utils.formatPrice(price)}
				</Typography>
				<Typography variant="text" sx={{ fontSize: '13px' }}>
					{translate('orders.articles') + ' / ' + translate('orders.tables.total')}
				</Typography>
			</Stack>

			{/* Icone funzionalità */}
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={0}
				sx={{ display: { xs: 'none', md: 'inherit', sm: 'inherit' } }}
			>
				{shippingNumber && (
					<Tooltip variant="light" arrow followCursor placement="top" title={tooltipText}>
						<WhereToVote />
					</Tooltip>
				)}
			</Stack>
		</Stack>
	);
};

export default ProcessigOrdersAccordionSummary;
