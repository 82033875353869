import pick from 'lodash/pick';
import k from 'k';
import utils from 'services/utils';

export default data => {
	const propsToPick = ['name', 'cig', 'amount', 'checkImmediatelly', 'validityDateFrom', 'validityDateTo', 'note'];
	const d = pick(data, propsToPick);
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(d),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/user/budgets`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
