import { alpha } from '@mui/material/styles';

export default theme => ({
	search: {
		display: 'flex',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.black, 0.25)
		},
		width: '100%'
	},
	searchIcon: {
		padding: theme.spacing(1, 0, 0, 1),
		height: '100%',
		pointerEvents: 'none'
	},
	elements: {
		padding: `${theme.spacing(2, 1, 2, 1)} !important`
	},
	associate: {
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(2, 0)
		}
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	title: {
		flex: '1 1 100%'
	},
	cardMessageContent: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	noResults: {
		marginTop: `calc(1em + ${theme.spacing(4)}px)`,
		maxWidth: '400px'
	}
});
