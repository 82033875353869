import React, { useEffect, useState, useCallback } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import debounce from 'lodash/debounce';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, InputBase, Paper, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import utils from 'components/utils';
import serviceUtils from 'services/utils';

const FilterBarSearch = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const token = useStoreState(state => state.cart.search.filters.token);
	const setToken = useStoreActions(dispatch => dispatch.cart.search.setToken);
	const [value, setValue] = useState(token);

	const debounced = useCallback(
		debounce(value => setToken(value), 400),
		[value, setToken]
	);

	const handleChange = e => {
		if (e.target.value.length >= 4) {
			debounced(e.target.value);
		} else {
			setToken(e.target.value);
		}
		setValue(e.target.value);
	};

	return (
		<Paper variant="searchField" sx={{ borderColor: token ? theme.palette.primary.main : '#cccdce' }}>
			<SearchIcon sx={{ color: theme.palette.primary.main, marginRight: '10px' }} />
			<InputBase
				variant="searchField"
				placeholder={translate('common.search.search')}
				value={value}
				onChange={e => handleChange(e)}
				inputProps={{ 'aria-label': 'search', 'data-testid': 'order.search' }}
				sx={{ width: '100%' }}
			/>
		</Paper>
	);
};

export default FilterBarSearch;
