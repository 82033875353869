import React from 'react';
import { withStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import FileUploader from './components/FileUploader';
import InvalidFile from './components/InvalidFile';
import styles from './styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	backButton: {
		marginRight: theme.spacing(1)
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	}
}));

const Import = _ => {
	const classes = useStyles();
	const steps = [<FileUploader />, <InvalidFile />];
	const getActiveStepContent = _ => steps[0];

	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item xs={12}>
					{getActiveStepContent()}
				</Grid>
			</Grid>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(Import);
