import React, { Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Paper, LinearProgress } from '@mui/material';
import ActionButtons from './components/ActionButtons';
import Create from './components/Create';
import styles from './styles';
import Wishlist from './components/Wishlist';
import { useHistory } from 'react-router-dom';
import HeaderPage from './HeaderPage';

const List = ({ classes }) => {
	const history = useHistory();
	const createOpen = useStoreState(state => state.wishlist.create.open);
	const setOpenCreate = useStoreActions(dispatch => dispatch.wishlist.create.setOpen);
	const loading = useStoreState(state => state.wishlist.list.loading);

	const toggleCreate = _ => {
		setOpenCreate(!createOpen);
	};

	useEffect(
		_ => {
			if (history.location.pathname.includes('/wishlist/list/create')) {
				setOpenCreate(true);
			}
		},
		[history, setOpenCreate]
	);

	useEffect(() => {
		//Scroll top
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	const leftGridMdWidth = createOpen ? 7 : 12;

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				<HeaderPage />
				{loading && <LinearProgress />}
			</Paper>
			<Box sx={{ width: '100%', padding: { xs: '20px 5px', md: '20px', sm: '20px 10px' } }}>
				<Wishlist />
			</Box>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(List);
