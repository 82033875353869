import { alpha } from '@mui/material/styles';

export default theme => ({
	root: {
		flexDirection: 'row'
	},
	modalPaper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '50%'
	},
	title: {
		margin: theme.spacing(2, 0)
	},
	subtitle: {
		marginBottom: theme.spacing(1)
	},
	elements: {
		padding: `${theme.spacing(2, 1, 2, 1)} !important`,
		alignSelf: 'center'
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.black, 0.25)
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(0),
			width: '100%'
		},
		padding: theme.spacing(0, 2)
	},
	searchIcon: {
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit',
		width: '100%'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%'
	}
});
