export default theme => ({
	root: {
		display: 'flex'
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	modalPosition: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
});
