import k from 'k';
import utils from 'services/utils';

export default cols => {
	const requestOptions = {
		method: 'PUT',
		body: JSON.stringify(cols),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/me/catalog/columns`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
