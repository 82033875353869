import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const AmountComp = ({ text }) => {
	return <StyledTableCell>{utils.formatPrice(text)}</StyledTableCell>;
};

const Amount = memo(
	({ item }) => <AmountComp text={item.netAmount} />,
	(p, n) => p.item.netAmount === n.item.netAmount
);

export default Amount;
