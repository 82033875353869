import React from 'react';
import { useStoreState } from 'easy-peasy';

const withColumns = WrappedComponent => {
	return props => {
		const searchOpen = useStoreState(state => state.promotions.list.searchOpen);
		const cols = [
			'id',
			'sapId',
			'sapName',
			'enabled',
			'enabledCatalog',
			'enabledWishlist',
			'approvalRequest',
			'startDate',
			'endDate',
			'edit'
		];
		const colCount = searchOpen ? 4 : 10;
		const visibleCols = cols.slice(0, colCount);
		return <WrappedComponent {...props} visibleCols={visibleCols} />;
	};
};

export default withColumns;
