import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ResponsiveSideContent from 'components/ResponsiveSideContent';
import WishlistCreate from 'components/WishlistCreate';
import styles from './styles';
import { useHistory } from 'react-router-dom';

const Create = ({ classes, showCloseIcon }) => {
	const openFromUrl = useStoreState(state => state.wishlist.create.openFromUrl);
	const openCreate = useStoreState(state => state.wishlist.create.open);
	const setOpenCreate = useStoreActions(dispatch => dispatch.wishlist.create.setOpen);
	const [name, setName] = useState('');
	const [notes, setNotes] = useState('');
	const history = useHistory();

	const onCloseCreate = () => {
		setOpenCreate(false);
		if (!openFromUrl) history.goBack();
	};

	return (
		<ResponsiveSideContent
			idToCheck={'simple-tabpanel-0'}
			style={{ right: 0 }}
			onClose={_ => onCloseCreate()}
			open={openCreate}
		>
			{showCloseIcon && (
				<Grid
					container
					item
					xs={12}
					justifyContent="flex-end"
					sx={{ display: { xs: 'inherit', sm: 'inherit', md: 'none' } }}
				>
					<IconButton
						onClick={_ => {
							onCloseCreate();
						}}
						className={classes.closeIcon}
					>
						<CloseIcon />
					</IconButton>
				</Grid>
			)}

			<WishlistCreate
				onClose={_ => {
					onCloseCreate();
				}}
				onNoteChange={e => {
					setNotes(e.target.value);
				}}
				defaultNotesValue={notes}
				defaultNameValue={name}
				onNameChange={e => {
					setName(e.target.value);
				}}
			/>
		</ResponsiveSideContent>
	);
};

export default withStyles(styles, { withTheme: true })(Create);
