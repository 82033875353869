import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Tooltip, Box, CircularProgress, IconButton } from '@mui/material';
import utils from 'components/utils';
import OpenOrdersInfoDetail from './OpenOrdersInfoDetail';
import OpenOrdersItemsTable from './OpenOrdersItemsTable';
import OpenOrderDepotsInfo from './OpenOrderDepotsInfo';

const OpenOrdersAccordionDetails = props => {
	const { order } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();

	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
			<Box sx={{ display: { xs: 'inherit', md: 'none', sm: 'inherit' } }}>
				<OpenOrdersInfoDetail order={order} />
			</Box>
			{order.orderItems.length == 0 && (
				<Box sx={{ textAlign: 'center', width: '100%' }}>
					<Typography variant="titleSection" sx={{ textTransform: 'inherit' }}>
						{translate('orders.emptyCart')}
					</Typography>
				</Box>
			)}
			{order.orderItems.length > 0 && <OpenOrderDepotsInfo order={order} />}
			{order.orderItems.length > 0 && <OpenOrdersItemsTable order={order} />}
		</Stack>
	);
};

export default OpenOrdersAccordionDetails;
