import React, { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { TimePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import utils from 'components/utils';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const makeDatePickerStyles = makeStyles({
	root: {
		width: 180
	}
});

const DayDetail = ({ item }) => {
	const translate = utils.useI18N();
	const update = useStoreActions(dispatch => dispatch.BOspecialDelivery.update.sendRequest);
	const loading = useStoreState(state => state.BOspecialDelivery.update.loading);
	const [enabled, setEnabled] = useState(item.enabled);
	const [startTime, setStartTime] = useState(moment(item.startTime, 'HH:mm'));
	const [endTime, setEndTime] = useState(moment(item.endTime, 'HH:mm'));
	const formatStartTime = startTime.format('HH:mm:ss');
	const formatEndTime = endTime.format('HH:mm:ss');

	const datePickerStyles = makeDatePickerStyles();
	const onSave = _ => {
		update({
			enabled,
			startTime: formatStartTime,
			endTime: formatEndTime,
			day: item.day
		});
	};
	return (
		<Grid container spacing={3}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Grid item container style={{ marginBottom: 20, width: 200 }}>
					<FormControlLabel
						value="start"
						control={
							<Checkbox
								color="primary"
								size="medium"
								checked={enabled}
								onChange={e => setEnabled(e.target.checked)}
							/>
						}
						label={translate('coupons.tables.status')}
						labelPlacement="start"
					/>
				</Grid>
				<Grid item container style={{ marginBottom: 20, width: 350 }}>
					<TimePicker
						className={datePickerStyles.root}
						label="Ora inizio"
						value={startTime}
						onChange={setStartTime}
						autoOk
						ampm={false}
						format="HH:mm"
					/>
				</Grid>
				<Grid item container style={{ marginBottom: 20, width: 350 }}>
					<TimePicker
						className={datePickerStyles.root}
						label="Ora fine"
						value={endTime}
						onChange={setEndTime}
						autoOk
						ampm={false}
						format="HH:mm"
					/>
				</Grid>
				<Grid container item xs={12} justifyContent="flex-end">
					{loading && <CircularProgress size={24} />}
					{!loading && (
						<Button onClick={onSave} variant="contained" color="primary" type="submit">
							{translate('common.save')}
						</Button>
					)}
				</Grid>
			</LocalizationProvider>
		</Grid>
	);
};

export default DayDetail;
