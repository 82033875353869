import { action, thunk } from 'easy-peasy';
import utils from './utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	totalResults: 0,
	save: action((state, payload) => {
		state.items = payload.items.map(item => ({
			...item,
			nameOrder: item.name.toLowerCase().trim(),
			isSelected: false
		}));
		state.totalResults = payload.totalItems;
	}),
	load: thunk(utils.search),
	orderBy: 'id',
	orderDirection: 'desc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	})
};
