import { action, thunk, computed, actionOn } from 'easy-peasy';
import merge from 'lodash/merge';

export default {
	iAmPersonifying: computed([state => state.target.id], id => id !== ''),
	target: {
		id: ''
	},
	save: action((state, payload) => {
		state.target = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	waitImpersonification: false,
	setWaitImpersonification: action((state, payload) => {
		state.waitImpersonification = payload;
	}),
	sendRequest: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		const { code, history, funclose } = payload;
		actions.setLoading(true);
		actions.setWaitImpersonification(true);
		return services.me
			.personify(code)
			.then(data => {
				actions.save(data);
				//Inserita questa funzione per evitare che l'utente faccia refresh (che annulla l'impersonificazione)
				funclose();
				/*
				history.push('/');				
				return Promise.resolve(data);
				*/
			})
			.catch(e => {
				actions.setWaitImpersonification(false);
				getStoreActions().error('me.personify');
			})
			.finally(_ => {
				//	actions.setLoading(false);
			});
	}),
	stop: action((state, payload) => {
		state.target = { id: '' };
		state.waitImpersonification = true;
	}),
	resetAutocomplete: action((state, payload) => {
		state.autocompleteToken = '';
		state.autocompleteLoading = false;
		state.autocompleteList = [];
	}),

	autocompleteLoading: false,
	setAutocompleteLoading: action((state, payload) => {
		state.autocompleteLoading = payload;
	}),
	autocompleteToken: '',
	setAutocompleteToken: action((state, payload) => {
		state.autocompleteToken = payload;
	}),
	autocompleteList: [],
	saveAutocompleteList: action((state, payload) => {
		state.autocompleteList = payload;
	}),
	strictSearch: '',
	setStrictSearch: action((state, payload) => {
		state.strictSearch = payload;
	}),
	loadUsers: thunk((actions, payload, { injections, getStoreState, getStoreActions }) => {
		const { services } = injections;
		const token = getStoreState().me.personify.autocompleteToken;
		const strictSearch = getStoreState().me.personify.strictSearch;
		actions.setAutocompleteLoading(true);
		services.me
			.listPersonifyUsers(token, strictSearch)
			.then(data => {
				actions.saveAutocompleteList(data);
			})
			.catch(e => {
				getStoreActions().error('me.personify');
			})
			.finally(_ => {
				actions.setAutocompleteLoading(false);
			});
	}),
	onUpdateSapInfo: actionOn(
		(actions, storeActions) => [storeActions.me.updateSapInfo.sendRequest.successType],
		(state, target) => {
			if (state.iAmPersonifying) state.target.showSapInfo = target.result;
		}
	),
	onUpdateAvatar: actionOn(
		(actions, storeActions) => [
			storeActions.me.avatar.update.successType,
			storeActions.me.avatar.deletee.successType
		],
		(state, target) => {
			if (state.iAmPersonifying) state.target.avatar = target.result;
		}
	),
	onUpdateMe: actionOn(
		(actions, storeActions) => [storeActions.me.update.performUpdate.successType],
		(state, target) => {
			if (state.iAmPersonifying) merge(state.target, target.result);
		}
	)
};
