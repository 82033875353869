import React from 'react';
import NotSortable from './NotSortable';

const allCols = [
	{
		id: 'id',
		comp: _ => <NotSortable id="id" key="headCell_id" label="rankings.tables.id" />
	},
	{
		id: 'description',
		comp: _ => <NotSortable id="description" key="headCell_description" label="rankings.tables.description" />
	},
	{
		id: 'name',
		comp: _ => <NotSortable id="name" key="headCell_name" label="rankings.tables.name" />
	},
	{
		id: 'type',
		comp: _ => <NotSortable id="type" key="headCell_type" label="rankings.tables.type" />
	}
];

export default allCols;
