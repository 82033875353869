import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import TableHeadMUI from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import allCols from './cols';
import withColumns from '../columns';
import utils from 'components/utils';

const TableHead = props => {
	const { visibleCols, type, status } = props;
	const theme = useTheme();
	const user = utils.useUserData('', null);
	const personify = useStoreState(state => state.me.personify);
	const isDeleted = status === 'INITIAL';
	const checkboxEnabled =
		(type !== 'BOOK2C' && isDeleted && personify.target.id !== '') ||
		(personify.target.id === '' && user.userType !== '02');
	const cells = visibleCols.map(col => allCols.find(c => c.id === col));

	return (
		<TableHeadMUI>
			<TableRow style={{ height: theme.spacing(5) }}>
				{cells
					.filter(c => checkboxEnabled || (!checkboxEnabled && c.id !== 'selection'))
					.map(cell => cell.comp({ type, status }))}
			</TableRow>
		</TableHeadMUI>
	);
};

export default withColumns(TableHead);
