import React from 'react';
import { Stack } from '@mui/material';
import Accept from './components/Accept';
import Edit from './components/Edit';
import Reject from './components/Reject';

const HandleWishlistButtons = ({ item }) => {
	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5} sx={{ width: '100%' }}>
			<Accept item={item} />
			<Edit item={item} />
			<Reject item={item} />
		</Stack>
	);
};

export default HandleWishlistButtons;
