import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import utils from 'components/utils';
import styles from './styles';
import ListItem from '@mui/material/ListItem';
import ListItemLink from './ListItemLink';
import Tooltip from '@mui/material/Tooltip';

const Admin = ({ classes }) => {
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const adminOpen = useStoreState(state => state.menu.adminOpen);
	const mainOpen = useStoreState(state => state.menu.mainOpen);
	const toggleAdminOpen = useStoreActions(dispatch => dispatch.menu.toggleAdminOpen);
	const openMain = useStoreActions(dispatch => dispatch.menu.openMain);
	const onClick = _ => {
		toggleAdminOpen();
		openMain();
	};
	return (
		<Fragment>
			<ListItem button onClick={onClick} className={classes.listItem}>
				{!mainOpen && (
					<Tooltip title={translate('common.menu.admin')} placement="right">
						<ListItemIcon className={classes.listIcon}>
							<SettingsIcon />
						</ListItemIcon>
					</Tooltip>
				)}
				{mainOpen && (
					<ListItemIcon className={classes.listIcon}>
						<SettingsIcon />
					</ListItemIcon>
				)}
				<ListItemText primary={translate('common.menu.admin')} />
				{adminOpen ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={adminOpen} timeout="auto" unmountOnExit>
				<List component="nav" disablePadding>
					{iCan('MANAGE_NOTIFICATIONS') && (
						<ListItemLink to="/admin/send-notification" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.notifications')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_USERS') && (
						<ListItemLink to="/admin/users" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.anagraphic')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_GROUPS') && (
						<ListItemLink to="/admin/usergroups" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.userGroups')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_CA') && (
						<ListItemLink to="/admin/purchasingcenters" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.purchasingGroups')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_CALENDAR_RETURNS') && (
						<ListItemLink to="/admin/return-requests" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.returns')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_PROMOTIONS') && (
						<ListItemLink to="/admin/promotions" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.promotions')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_COUPONS') && (
						<ListItemLink to="/admin/coupons" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.coupons')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_RANKING') && (
						<ListItemLink to="/admin/rankings" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.rankings')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_EDITORIAL_UNLOCK') && (
						<ListItemLink to="/admin/editorials" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.editorials')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_SPECIAL_SHIPMENTS') && (
						<ListItemLink to="/admin/withdraw-types" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.evasions')}
							/>
						</ListItemLink>
					)}
					{iCan('MANAGE_SPECIAL_DELIVERY') && (
						<ListItemLink to="/admin/special-delivery" button className={classes.nested}>
							<ListItemText
								className={classes.subMenuItems}
								secondary={translate('common.menu.specialDelivery')}
							/>
						</ListItemLink>
					)}
				</List>
			</Collapse>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Admin);
