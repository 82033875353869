import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';

const Loader = props => (
	<Fragment>
		<Grid item container xs={12} justifyContent="center">
			<CircularProgress size={40} />
		</Grid>
		<Grid item container xs={12} justifyContent="center">
			<Typography align="center" variant="h5">
				{utils.useI18N()('common.loading')}
			</Typography>
		</Grid>
	</Fragment>
);

export default Loader;
