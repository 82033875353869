export default theme => ({
	marginTop: {
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1, 0, 0, 0)
		}
	},
	marginBottom: {
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(0, 0, 1, 0)
		}
	}
});
