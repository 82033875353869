import React, { Fragment, memo } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

const FavIconComp = ({ id, favourite }) => {
	const theme = useTheme();
	const loading = useStoreState(state => state.wishlist.favourite.loading);
	const setFavourite = useStoreActions(dispatch => dispatch.wishlist.favourite.sendRequest);
	const successMessage = favourite ? 'Wishlist preferita rimossa' : 'Wishlist Preferita impostata';
	const onChange = e => {
		e.stopPropagation();
		setFavourite({ id, successMessage });
	};
	const onClick = e => {
		e.stopPropagation();
	};
	const isLoading = loading === id;
	return (
		<Fragment>
			{isLoading && <CircularProgress size={16} sx={{ marginLeft: '0px' }} />}
			{!isLoading && (
				<FormControlLabel
					aria-label="Acknowledge"
					control={
						<Checkbox
							onClick={onClick}
							onChange={onChange}
							icon={
								<StarIcon
									sx={{
										color: favourite ? theme.palette.primary.main : theme.palette.custom.disabled
									}}
								/>
							}
							checked={favourite}
							disableRipple={true}
							checkedIcon={
								<StarIcon
									sx={{
										color: favourite ? theme.palette.primary.main : theme.palette.custom.disabled
									}}
								/>
							}
						/>
					}
				/>
			)}
		</Fragment>
	);
};

const FavIcon = memo(
	({ item }) => <FavIconComp id={item.id} favourite={item.favourite} />,
	(p, n) => p.item.id === n.item.id && p.item.favourite === n.item.favourite
);

export default FavIcon;
