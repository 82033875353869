import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Logo from './components/Logo';
import PageTitle from './components/PageTitle';
import Countdown from './components/Countdown';
import WishlistButton from './components/WishlistButton';
import OrdersButton from './components/OrdersButton';
import NotificationsButton from './components/NotificationsButton';
import BudgetPreview from './components/BudgetPreview';
import Identity from './components/Identity';
import utils from 'components/utils';

const styles = theme => ({
	logo: {
		width: 190
	},
	pageTitle: {
		flexDirection: 'column',
		alignContent: 'flex-start'
	},
	countdown: {
		width: 82
	},
	wishlist: {
		width: 48
	},
	orders: {
		width: 48
	},
	notifications: {
		width: 48
	},
	budget: {
		width: 200
	},
	identity: {
		width: 185,
		justifyContent: 'flex-end'
	}
});

const Desktop = ({ classes }) => {
	const iCan = utils.usePermissions();
	return (
		<Grid container>
			<Grid item className={classes.logo}>
				<Logo />
			</Grid>
			<Grid container item xs className={classes.pageTitle}>
				<PageTitle />
			</Grid>
			<Grid container item className={classes.countdown}>
				<Countdown />
			</Grid>
			{iCan('WISHLIST') && (
				<Grid container item className={classes.wishlist}>
					<WishlistButton />
				</Grid>
			)}
			{iCan('ORDERS') && (
				<Grid container item className={classes.orders}>
					<OrdersButton />
				</Grid>
			)}
			<Grid container item className={classes.notifications}>
				<NotificationsButton />
			</Grid>
			{iCan('BUDGET') && (
				<Grid container item className={classes.budget}>
					<BudgetPreview />
				</Grid>
			)}
			<Grid container item className={classes.identity}>
				<Identity />
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(Desktop);
