import React, { Fragment, useState, useEffect, forwardRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { useHistory, useParams } from 'react-router-dom';
import {
	Stack,
	Paper,
	Box,
	Typography,
	Tooltip,
	IconButton,
	Modal,
	Button,
	LinearProgress,
	Dialog,
	Link
} from '@mui/material';
import Slide from '@mui/material/Slide';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import Settings from './components/Settings';
import CatalogDownload from '../CatalogDownload';
import SearchBar from './components/SearchBar';
import Filters from './components/Filters';
import AppliedFilters from './components/AppliedFilters';
import Pagination from './components/Pagination';
import SearchTable from './SearchTable';
import Reset from './Reset';
import Details from '../Details';
import WarningModal from 'components/WarningModal';
import Selection from 'components/Selection';
import utils from 'components/utils';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Search = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const history = useHistory();
	const params = useParams();
	const modalOpen = useStoreState(state => state.catalog.details.modalOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.catalog.details.setModalOpen);
	const selection = useStoreState(state => state.selection.items);
	const selectionModalOpen = useStoreState(state => state.selection.modalOpen);
	const setSelectionModalOpen = useStoreActions(dispatch => dispatch.selection.setModalOpen);
	const resetWarning = useStoreActions(dispatch => dispatch.selection.resetWarning);
	const selectionResetForAll = useStoreActions(dispatch => dispatch.selection.resetForAll);
	const showFullScreenFilters = useStoreState(state => state.catalog.filters.showFullScreen);
	const loadingSearch = useStoreState(state => state.catalog.search.loading);
	const dirtyResults = useStoreState(state => state.catalog.search.results);
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const filtersOpen = useStoreState(state => state.catalog.filters.open);
	const setFiltersOpen = useStoreActions(dispatch => dispatch.catalog.filters.setOpen);
	const setFilters = useStoreActions(dispatch => dispatch.catalog.filters.save);
	const searchPerformed = useStoreState(state => state.catalog.search.performed);
	const token = useStoreState(state => state.catalog.search.token);
	const setSearchScope = useStoreActions(dispatch => dispatch.catalog.search.setScope);
	const setCondOfSaleScope = useStoreActions(dispatch => dispatch.users.conditionsOfSale.setScope);
	const setWishlistScope = useStoreActions(dispatch => dispatch.wishlist.list.setScope);
	const setWarningPromo = useStoreActions(dispatch => dispatch.selection.setWarningPromo);
	const loadCA = useStoreActions(dispatch => dispatch.ca.list.load);
	const filtersArePristine = useStoreState(state => state.catalog.filters.arePristine);
	const openThemaModal = useStoreState(state => state.catalog.thema.openModal);
	const itemsSelected = useStoreState(state => state.catalog.thema.itemsSelected);
	const promoCode = useStoreState(state => state.catalog.filters.list.selectedPromotion);
	const warningPromo = useStoreState(state => state.selection.warningPromo);
	const slides = useStoreState(state => state.cms.promotionSlide.data);
	const loadPromoSlide = useStoreActions(dispatch => dispatch.cms.promotionSlide.load);
	const results = iCan('SHOW_SPECIAL_FILTER') ? dirtyResults : dirtyResults.filter(item => item.type !== 100);
	const showToggleButton = !loadingSearch && searchPerformed;
	const showAppliedFilters = !loadingSearch && searchPerformed;
	const showPagination = !loadingSearch && results.length > 0;
	const showNoResults = !loadingSearch && results.length === 0 && searchPerformed;
	const showResults = !loadingSearch && results.length > 0;
	const [settingsOpen, setSettingsOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [catalogBanner, setCatalogBanner] = useState(null);

	useEffect(
		_ => {
			setCondOfSaleScope('search');
		},
		[setCondOfSaleScope]
	);

	useEffect(
		_ => {
			setWishlistScope('search');
		},
		[setWishlistScope]
	);

	useEffect(
		_ => {
			setSearchScope('catalog');
		},
		[setSearchScope]
	);

	useEffect(
		_ => {
			setFilters({ allWords: true });
		},
		[setFilters]
	);

	useEffect(
		_ => {
			if (iCan('OPERATE_ON_MULTIPLE_CA')) {
				loadCA();
			}
		},
		[loadCA, iCan]
	);

	useEffect(
		_ => {
			//Scroll top
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

			if (history.location.search) {
				const hasPromo = selection.some(item => item.isPromo);
				setSelectionModalOpen(true);
				if (hasPromo) setWarningPromo(true);
			}

			loadPromoSlide();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history]
	);

	//Gestione del refresh della pagina di ricerca per mostrare la thema
	useEffect(() => {
		//Riporto a catalog se non ci sono risultati e non è visualizzato un dettaglio
		if (
			showFullScreenFilters &&
			!showResults &&
			!history.location.pathname.includes('/catalog/search/details') &&
			!params.ean
		)
			history.push(`/catalog/`);
	}, []);

	useEffect(
		_ => {
			const onKeyDown = e => {
				/* modificato l'if perché !filtersArePristine non fa partire la ricerca sul tasto ENTER
				const canDoSearch = !filtersArePristine && !selectionModalOpen;				
				if (e.key === 'Enter' && canDoSearch && !openThemaModal && !settingsOpen) {
				*/
				if (e.key === 'Enter' && !selectionModalOpen && !openThemaModal && !settingsOpen) {
					setStep(25);
					closeFilters();
				}
				/*Se ho premuto ESC, la modale dettaglio è aperta e si è arrivati direttemente alla 
				pagina di dettaglio senza passare dalla pagina catalogo si ritorna alla pagina catalogo */
				if (e.key === 'Escape' && showFullScreenFilters && !showResults) history.push(`/catalog/`);

				//Se ho premuto ESC chiudo la modale filtri
				if (e.key === 'Escape') {
					setSettingsOpen(false);
					resetWarning(false);
					selectionResetForAll();
					closeFilters();
				}
			};
			document.addEventListener('keydown', onKeyDown);

			return _ => {
				document.removeEventListener('keydown', onKeyDown);
			};
		},
		[token, filtersArePristine, selectionModalOpen, setStep, openThemaModal, itemsSelected]
	);

	useEffect(() => {
		const bannerList = slides.filter(
			banner => banner.ctaType.title === 'banner' || banner.ctaType.title === 'banner_slide'
		);
		if (bannerList && bannerList.length > 0)
			setCatalogBanner({ image: bannerList[0].image.url, link: bannerList[0].ctaTypeValue });
	}, [slides]);

	const onCloseSelecionModal = () => {
		setSelectionModalOpen(false);
		history.push(`/catalog/search/`);
	};

	const onClose = () => {
		if (history.location.pathname.includes('/catalog/search/details') && params.ean) {
			setModalOpen(false);
			//history.push(`/catalog/search/`);
			onCloseSelecionModal();
		}
	};

	const onModalClick = e => {
		//Gestione click fuori dalla modale per ritornare a catalog
		if (e.target.className && e.target.className.length == 0 && !showResults) history.push(`/catalog/`);
	};

	const closeFilters = _ => {
		setFiltersOpen(false);
	};

	const openFIlters = event => {
		setFiltersOpen(true);
	};

	useEffect(
		_ => {
			if (history.location.pathname.includes('/catalog/search/details') && params.ean) {
				setSelectedItem(params.ean);
				setModalOpen(true);
			}
		},
		[params, history, results]
	);

	return (
		<Fragment>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{ minHeight: '100vh' }}
			>
				<Paper variant={catalogBanner && catalogBanner.image ? 'inherit' : 'stickyTop'} sx={{ zIndex: 3 }}>
					<Stack
						direction="column"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={0.5}
						variant="wrapperColor"
						sx={{ width: '100%' }}
					>
						{/* Titolo */}
						<Stack
							direction={{ xs: 'row', md: 'row', sm: 'row' }}
							justifyContent="flex-stat"
							alignItems="center"
							spacing={1}
							variant="wrapperColor"
							sx={{ width: '100%' }}
						>
							<Typography variant="titlePage">{translate('common.search.fromHome')}</Typography>
						</Stack>
						{/* Searchbar, filtri, reset, settings, download */}
						<Stack
							direction={{ xs: 'column', md: 'row', sm: 'row' }}
							justifyContent="space-between"
							alignItems="center"
							spacing={1}
							sx={{ width: { xs: '100%', md: '100%', sm: '100%', marginTop: '10px !important' } }}
						>
							{catalogBanner && catalogBanner.image && (
								<Box sx={{ width: { xs: '100%', md: '40%', sm: '40%' } }}>
									<Link href={catalogBanner.link ? catalogBanner.link : '#'} target="_blank">
										<img
											src={catalogBanner.image}
											style={{
												maxWidth: '100%',
												height: { xs: '0px', md: '160px', sm: '120px' }
											}}
										/>
									</Link>
								</Box>
							)}
							<Stack
								direction={{
									xs: 'column',
									md: catalogBanner && catalogBanner.image ? 'column' : 'row',
									sm: catalogBanner && catalogBanner.image ? 'column' : 'row'
								}}
								justifyContent="space-between"
								alignItems="center"
								spacing={1.5}
								sx={{
									width: {
										xs: '100%',
										md: catalogBanner && catalogBanner.image ? '60%' : '100%',
										sm: catalogBanner && catalogBanner.image ? '60%' : '100%'
									}
								}}
							>
								{/* Searchbar e reset */}
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={0.5}
									sx={{
										width: {
											xs: '100%',
											md: catalogBanner && catalogBanner.image ? '100%' : '80%',
											sm: catalogBanner && catalogBanner.image ? '100%' : '80%'
										}
									}}
								>
									<SearchBar path="search" />
									<Button
										variant="dark"
										component={Link}
										to={'/catalog/search'}
										disabled={loadingSearch || openThemaModal}
										onClick={_ => {
											setStep(25);
										}}
										endIcon={<SearchIcon />}
										sx={{
											height: '55px',
											padding: '7px 16px !important',
											backgroundColor:
												loadingSearch || openThemaModal
													? theme.palette.custom.disabled
													: theme.palette.primary.main
										}}
									>
										{translate('common.search.search')}
									</Button>
									<Reset />
								</Stack>
								{showToggleButton && (
									<Stack
										direction="row"
										justifyContent="flex-end"
										alignItems="center"
										spacing={0.5}
										sx={{
											width: {
												xs: '100%',
												md: catalogBanner && catalogBanner.image ? '100%' : '20%',
												sm: catalogBanner && catalogBanner.image ? '100%' : '20%'
											}
										}}
									>
										{/* Filtri */}
										<Tooltip title={translate('catalog.openFilters')}>
											<IconButton onClick={_ => setFiltersOpen(true)}>
												<TuneIcon sx={{ fontSize: '1.2em' }} />
											</IconButton>
										</Tooltip>
										{/* Settings */}
										{results.length > 0 && (
											<Tooltip title={translate('common.options')}>
												<IconButton onClick={_ => setSettingsOpen(true)}>
													<SettingsIcon />
												</IconButton>
											</Tooltip>
										)}
										{/* Download */}
										{results.length > 0 && <CatalogDownload />}
									</Stack>
								)}
							</Stack>
						</Stack>
					</Stack>
					{/* Loader Linearprogress */}
					{loadingSearch && (
						<Box sx={{ width: '100%', textAlign: 'center' }}>
							<LinearProgress sx={{ color: theme.palette.primary.main }} />
						</Box>
					)}
				</Paper>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					sx={{ width: '100%', paddingTop: '10px !Important' }}
				>
					{/* Filtri applicati */}
					{showAppliedFilters && <AppliedFilters />}
					{/* Paginazione */}
					{showPagination && (
						<Box sx={{ marginTop: '10px !important', width: '100%' }}>
							<Pagination />
						</Box>
					)}
					{/* Nessun risultato */}
					{showNoResults && (
						<Box sx={{ width: '100%', textAlign: 'center' }}>
							<Typography variant="titleSection">{translate('common.search.noResults')}</Typography>
						</Box>
					)}
					{/* Tabella risultati */}
					{showResults && <SearchTable rows={results} />}
					{/* Paginazione */}
					{showPagination && (
						<Box sx={{ marginTop: '20px !important', width: '100%' }}>
							<Pagination />
						</Box>
					)}
				</Stack>
			</Stack>

			{/* Modale colonne, filtri e selezione */}
			<Modal
				aria-labelledby="modal-column-selection"
				aria-describedby="modal-column-selection"
				closeAfterTransition
				open={settingsOpen || filtersOpen}
				onClose={_ => {
					setSettingsOpen(false);
					//onCloseSelecionModal();
					resetWarning(false);
					selectionResetForAll();
					setFiltersOpen(false);
				}}
			>
				<Box className="modal-content">
					{settingsOpen && <Settings onClose={_ => setSettingsOpen(false)} />}
					{filtersOpen && (
						<Box
							sx={{
								padding: '10px',
								backgroundColor: theme.palette.custom.white,
								width: { xs: '95%', md: '70%', sm: '95%' },
								height: { xs: '100%', md: 'auto', sm: 'auto' },
								overflowY: 'auto'
							}}
						>
							<Filters close={closeFilters} showCloseIcon={true} />
						</Box>
					)}
				</Box>
			</Modal>

			{/* Modale continua */}
			<Dialog
				fullScreen
				open={selectionModalOpen}
				onClose={_ => {
					onCloseSelecionModal();
					selectionResetForAll();
				}}
				TransitionComponent={Transition}
			>
				{selectionModalOpen && !warningPromo ? (
					<Selection
						maxTableHeight={{ xs: '300px', md: '400px', sm: '350px' }}
						onCloseSelecionModal={onCloseSelecionModal}
					/>
				) : selectionModalOpen && promoCode ? (
					<Selection
						showCloseIcon={true}
						showTitle={true}
						maxTableHeight={{ xs: '300px', md: '350px', sm: '350px' }}
						addToCart={true}
						addToWishlist={true}
						createWishlist={false}
						onCloseSelecionModal={onCloseSelecionModal}
					/>
				) : (
					<WarningModal onCloseSelecionModal={onCloseSelecionModal} />
				)}
			</Dialog>

			{/* Dualog dettaglio */}
			<Dialog
				fullScreen
				open={modalOpen}
				onClose={onClose}
				onClick={onModalClick}
				TransitionComponent={Transition}
			>
				<Details id_ean={selectedItem} showClose={true} urlComponet="/catalog/search" />
			</Dialog>
		</Fragment>
	);
};

export default Search;
