import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Tooltip, Box } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SellIcon from '@mui/icons-material/Sell';
import utils from 'components/utils';
import CartInfo from '../CartInfo';
import ShippedOrdersInfoDetail from './ShippedOrdersInfoDetail';

const ShippedOrdersAccordionSummary = props => {
	const { order } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const mappedItems = order.orderItems.map(item => Boolean(item.promoCode));
	const hasPromo = mappedItems.some(item => item);

	return (
		<Stack
			direction={{ xs: 'row', nd: 'row', sm: 'row' }}
			justifyContent="flex-start"
			alignItems="center"
			spacing={1}
			sx={{ width: '100%' }}
		>
			{/* Nome e data aggiornamento */}
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0}
				sx={{ width: { xs: '60%', md: '30%', sm: '70%' } }}
			>
				<Typography
					variant="titleAccordion"
					sx={{
						color: order.type === 'BOOK2C' ? theme.palette.custom.book2c : theme.palette.primary.black
					}}
				>
					{order.name !== '' ? order.name : translate('orders.defaultCart')}
				</Typography>
				<Typography variant="text" sx={{ fontSize: '13px' }}>
					{order.fullfilTime.slice(0, -3)}
				</Typography>
			</Stack>

			{/* Icone info */}
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={0}
				sx={{
					display: { xs: 'none', md: 'inherit', sm: 'none' },
					width: { xs: '0%', md: '30%', sm: '0%' }
				}}
			>
				{iCan('OPERATE_PA_CARTS') && !order.isSAP && !order.isDefault && order.type !== 'BOOK2C' && (
					<Tooltip variant="light" arrow followCursor placement="top" title={<CartInfo order={order} />}>
						<AssignmentIcon sx={{ fontSize: '28px' }} />
					</Tooltip>
				)}
				<ShippedOrdersInfoDetail order={order} />
				{/*hasPromo && <SellIcon sx={{ color: theme.palette.custom.green }} /> -> inserite chips per esteso al posto delle icone*/}
			</Stack>

			{/* Totale */}
			<Stack
				direction="column"
				justifyContent={{ xs: 'center', md: 'center', sm: 'center' }}
				alignItems={{ xs: 'center', md: 'center', sm: 'center' }}
				spacing={0}
				sx={{ width: { xs: '50%', md: '30%', sm: '30%' } }}
			>
				<Typography variant="text" sx={{ fontWeight: '600' }}>
					{order.resumeClosedOrder.sumOfProcessedCatalog +
						' / ' +
						utils.formatPrice(order.resumeClosedOrder.sumCostProcessedCatalog)}
				</Typography>
				<Typography variant="text" sx={{ fontSize: '13px' }}>
					{translate('orders.articles') + ' / ' + translate('orders.tables.total')}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default ShippedOrdersAccordionSummary;
