import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useStoreActions } from 'easy-peasy';

const DeleteComp = ({ ean }) => {
	const update = useStoreActions(dispatch => dispatch.editorials.assign.remove);
	return (
		<StyledTableCell>
			<DeleteForeverIcon onClick={() => update(ean)} />
		</StyledTableCell>
	);
};

const Delete = memo(
	({ item }) => <DeleteComp ean={item.ean} />,
	(p, n) => p.item.ean === n.item.ean
);

export default Delete;
