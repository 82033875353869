import React, { Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardMedia from '@mui/material/CardMedia';
import { Hidden } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const styles = theme => ({
	text: {
		'& p': {
			margin: 0
		}
	},
	modalPaper: {
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '80%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	}
});

const ModalDetails = ({ classes, selectedItem, modalColor }) => {
	const theme = useTheme();
	const setModalOpen = useStoreActions(dispatch => dispatch.cms.promotions.setModalOpen);
	const openFromURl = useStoreState(state => state.cms.promotions.openFromURl);
	const history = useHistory();

	const onCloseModal = () => {
		setModalOpen(false);

		if (openFromURl) history.push('/');
		else history.goBack();
	};

	return (
		<Fragment>
			{selectedItem && (
				<Paper className={classes.modalPaper} style={{ backgroundColor: modalColor }}>
					<Grid container item xs={12} justify="flex-end" style={{ maxHeight: 24 }}>
						<IconButton
							size="small"
							style={{ right: theme.spacing(-2) }}
							className={classes.noRipple}
							onClick={_ => {
								onCloseModal();
							}}
						>
							<CloseIcon />
						</IconButton>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h4" color="primary" style={{ marginBottom: 24, textAlign: 'center' }}>
							{selectedItem.visibleTitle}
						</Typography>
					</Grid>
					<Grid container>
						<Hidden smDown>
							<Grid container item xs={2}>
								&nbsp;
							</Grid>
						</Hidden>
						<Grid container item xs={12} md={3}>
							<Paper style={{ width: 320, height: 440, padding: 10 }}>
								<CardMedia
									image={selectedItem.image.url}
									title={selectedItem.image.title}
									style={{ height: '100%', backgroundSize: 'contain' }}
								/>
							</Paper>
						</Grid>
						<Grid container item xs>
							<Typography variant="body1" style={{ marginLeft: 36 }}>
								<span
									className={classes.text}
									dangerouslySetInnerHTML={{ __html: selectedItem.popupText }}
								></span>
							</Typography>
						</Grid>
						<Hidden smDown>
							<Grid container item xs={2}>
								&nbsp;
							</Grid>
						</Hidden>
					</Grid>
					<Grid container item xs={12} justify="center" style={{ marginTop: 24 }}>
						{selectedItem.posterCtaEnabled && (
							<a
								href={selectedItem.poster.url}
								rel="noopener noreferrer"
								target="_blank"
								style={{ textDecoration: 'none' }}
							>
								<Button style={{ marginRight: 16 }} variant="outlined" color="primary">
									Scarica la locandina
								</Button>
							</a>
						)}
						{selectedItem.promotionCtaEnabled && (
							<Button
								component={Link}
								to={`/catalog/search/by-promotion/${selectedItem.promotionCode}`}
								disableRipple
								variant="contained"
								color="primary"
								onClick={() => {
									setModalOpen(false);
								}}
							>
								Vai alla promozione
							</Button>
						)}
					</Grid>
				</Paper>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(ModalDetails);
