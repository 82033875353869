import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import styles from './styles';
import utils from 'components/utils';
import { useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';

const Create = ({ classes, toggleCreate }) => {
	const translate = utils.useI18N();
	const setOpenFromUrl = useStoreActions(dispatch => dispatch.wishlist.create.setOpenFromUrl);

	const onCreateButton = () => {
		toggleCreate();
		setOpenFromUrl(false);
	};
	return (
		<Fragment>
			<Button
				style={{ margin: '0 8px' }}
				variant="outlined"
				component={Link}
				to={`/wishlist/list/create`}
				onClick={_ => {
					onCreateButton();
				}}
			>
				{translate('wishlist.create')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Create);
