import React, { Fragment, useEffect } from 'react';
import utils from 'components/utils';
import styles from './styles';
import { withStyles } from '@mui/styles';
import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomNoResult from './components/CustomNoResults';
import Loader from 'components/Loader';
// import Results from './components/Results';
import Pagination from './components/Pagination';
import TableHead from './components/Results/Table/TableHead';
import TableBody from './components/Results/Table/TableBody';
import DetailsDefault from './components/Details/Default';
import DetailsDepotStatement from './components/Details/DepotStatement';
import DetailsSummaryInvoice from './components/Details/SummaryInvoice';
import {
	Stack,
	Grid,
	Button,
	Paper,
	Typography,
	MenuItem,
	OutlinedInput,
	Select,
	InputLabel,
	FormControl,
	Box,
	LinearProgress,
	TableContainer,
	Table
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Search = props => {
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const theme = useTheme();
	const formattedDateFrom = useStoreState(state => state.documents.search.formattedDateFrom);
	const searching = useStoreState(state => state.documents.search.searching);
	const results = useStoreState(state => state.documents.search.items);
	const items = results.filter(r => r.isSelected);
	const exportFiles = _ => {
		items.forEach((item, i) => {
			setTimeout(() => {
				window.location.href = item.docUrl;
			}, i * 1000);
		});
	};
	const loading = useStoreState(state => state.documents.search.loading);
	const startSearch = useStoreActions(dispatch => dispatch.documents.search.startSearch);
	const goSearch = useStoreActions(dispatch => dispatch.documents.search.goSearch);
	const allTypes = useStoreState(state => state.documents.search.types);
	const selectType = useStoreActions(dispatch => dispatch.documents.search.selectType);
	const periods = useStoreState(state => state.documents.search.periods);
	const selectPeriod = useStoreActions(dispatch => dispatch.documents.search.selectPeriod);
	const setCondOfSaleScope = useStoreActions(dispatch => dispatch.users.conditionsOfSale.setScope);
	const setWishlistScope = useStoreActions(dispatch => dispatch.wishlist.list.setScope);
	const resetSearch = useStoreActions(dispatch => dispatch.documents.search.resetSearch);
	const searchDocs = _ => {
		goSearch(true);
		startSearch(formattedDateFrom);
	};
	const selectedType = useStoreState(state => state.documents.search.selectedType);
	const selectedPeriod = useStoreState(state => state.documents.search.selectedPeriod);
	const searchDisabled = selectedType.id === 'SELECT' || searching;
	const reset = useStoreActions(dispatch => dispatch.documents.search.resetItems);
	// prettier-ignore
	useEffect(_ => { resetSearch() }, [resetSearch]);
	// prettier-ignore
	useEffect(_ => { setCondOfSaleScope('documents'); }, [setCondOfSaleScope]);
	// prettier-ignore
	useEffect(_ => { setWishlistScope('documents'); }, [setWishlistScope]);
	const restrictedTypes = ['SELECT', 'BILL', 'ACCOMPANYING_INVOICES', 'SUMMARY_INVOICES', 'CREDIT_NOTE', 'RIBA'];
	const types = iCan('VIEW_ALL_DOCS') ? allTypes : allTypes.filter(f => restrictedTypes.includes(f.id));

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				<Stack
					direction={{ xs: 'row', md: 'row', sm: 'row' }}
					justifyContent="space-between"
					alignItems="center"
					spacing={1}
					variant="wrapperColor"
				>
					<Typography variant="titlePage">{translate('common.menu.documents')}</Typography>
					{!loading && searching && results.length > 0 && selectedType.id !== 'RIBA' && (
						<Button
							variant="light"
							onClick={_ => {
								exportFiles();
							}}
						>
							{translate('documents.buttons.export')}
						</Button>
					)}
				</Stack>
				<Stack
					direction={{ xs: 'row', md: 'row', sm: 'row' }}
					justifyContent="flex-start"
					alignItems="center"
					spacing={1}
					variant="wrapperColor"
				>
					<FormControl sx={{ width: '250px' }}>
						<InputLabel
							id="filter-select-depots"
							sx={{
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								marginTop: '-5px'
							}}
						>
							{translate('documents.typeOfDocuments')}
						</InputLabel>
						<Select
							id="select-period"
							value={selectedType.id === 'SELECT' ? '' : selectedType.id}
							onChange={event => selectType(event.target.value)}
							input={<OutlinedInput label={translate('documents.typeOfDocuments')} />}
							sx={{
								height: '40px',
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								fontWeight: '400',
								backgroundColor: theme.palette.custom.white
							}}
							disabled={searching}
						>
							{types.map(
								(item, index) =>
									item.id !== 'SELECT' && (
										<MenuItem key={`docs_${item.id}`} value={item.id}>
											{translate(item.i18nKey)}
										</MenuItem>
									)
							)}
						</Select>
					</FormControl>
					<FormControl sx={{ width: '250px' }}>
						<InputLabel
							id="filter-select-depots"
							sx={{
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								marginTop: '-5px'
							}}
						>
							{translate('documents.rangeDate')}
						</InputLabel>
						<Select
							id="select-period"
							value={selectedPeriod.id}
							onChange={event => {
								selectPeriod(event.target.value);
							}}
							input={<OutlinedInput label={translate('documents.rangeDate')} />}
							sx={{
								height: '40px',
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								fontWeight: '400',
								backgroundColor: theme.palette.custom.white
							}}
							disabled={searching}
						>
							{periods.map((item, index) => (
								<MenuItem key={`docs_${item.id}`} value={item.id}>
									{translate(item.i18nKey)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Button variant="dark" onClick={searchDocs} disabled={searchDisabled}>
						{translate('common.search.search')}
					</Button>
					<Button
						variant="light"
						onClick={_ => {
							selectType('');
							reset();
							goSearch(false);
						}}
					>
						Reset
					</Button>
				</Stack>
				{loading && (
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<LinearProgress sx={{ color: theme.palette.primary.main }} />
					</Box>
				)}
			</Paper>
			{!loading && results.length === 0 && searching && (
				<Grid item container xs={12} justifyContent={'center'} style={{ marginTop: 50 }}>
					<CustomNoResult />
				</Grid>
			)}
			{/* {!loading && searching && results.length > 0 && <Results />} */}
			{!loading && searching && results.length > 0 && (
				<Fragment>
					{selectedType.id !== 'RIBA' && (
						<Grid item container xs={12} sx={{ marginTop: theme.spacing(2) }}>
							<Pagination />
						</Grid>
					)}
					<TableContainer sx={{ marginTop: theme.spacing(2), maxHeight: 800 }}>
						<Table aria-label="documents table" sx={{ position: 'sticky' }}>
							<TableHead />
							<TableBody rows={results} />
						</Table>
					</TableContainer>
				</Fragment>
			)}
			<DetailsDefault />
			<DetailsDepotStatement />
			<DetailsSummaryInvoice />
		</Stack>
	);
};

export default Search;
