import React, { memo } from 'react';
import { green, yellow, red } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PrintIcon from '@mui/icons-material/Print';
import utils from 'components/utils';

const styles = theme => ({
	custom: {
		'&:hover': {
			background: 'none'
		}
	}
});

const AvailabilityComp = ({ classes, availability, width }) => {
	const translate = utils.useI18N();
	const availabilityMap = {
		POOR: red[500],
		FAIR: yellow[700],
		GOOD: green[300],
		EXCELLENT: green[800]
	};
	const text = availability ? translate(`catalog.availability.${availability.toLowerCase()}`) : '';
	const textDesc = availability
		? text + '. ' + translate(`catalog.availabilityDesc.${availability.toLowerCase()}`)
		: '';
	const w = {
		width,
		maxWidth: width,
		minWidth: width
	};

	return (
		<TableCell style={w} sx={{ padding: '16px 16px 16px 0 !important' }}>
			{availability && availability !== 'PRINT_ON_DEMAND' && (
				<Tooltip title={textDesc}>
					<IconButton
						className={classes.custom}
						aria-label={text}
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						<CheckCircleRoundedIcon fontSize="small" style={{ color: availabilityMap[availability] }} />
					</IconButton>
				</Tooltip>
			)}
			{availability && availability === 'PRINT_ON_DEMAND' && (
				<Tooltip title={textDesc}>
					<IconButton
						className={classes.custom}
						aria-label={text}
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						<PrintIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			)}
		</TableCell>
	);
};

const StyledAvailabilityComp = withStyles(styles, { withTheme: true })(AvailabilityComp);

const Availability = memo(
	({ item, width }) => <StyledAvailabilityComp availability={item.availability} width={width} />,
	(p, n) => p.item.availability === n.item.availability
);

export default Availability;
