import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Logo from './components/Logo';
import MobileHeaderMenu from './components/MobileHeaderMenu';

const styles = theme => ({
	mobileHeaderMenu: {
		width: 60,
		justifyContent: 'flex-end'
	}
});

const Mobile = ({ classes }) => {
	return (
		<Grid container>
			<Grid item xs>
				<Logo />
			</Grid>
			<Grid container item className={classes.mobileHeaderMenu}>
				<MobileHeaderMenu />
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(Mobile);
