import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import RejectModal from '../../RejectModal';
import utils from 'components/utils';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		minWidth: 350
	},
	modalLabels: {
		marginBottom: theme.spacing()
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0)
	}
});

const Reject = ({ classes, item }) => {
	const modalOpen = useStoreState(state => state.conditionRequest.reject.modalOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.conditionRequest.reject.setModalOpen);
	const reject = useStoreActions(dispatch => dispatch.conditionRequest.reject.sendRequest);
	const translate = utils.useI18N();
	const onReject = notes => {
		reject({
			id: item.id,
			notes
		});
	};
	return (
		<Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={modalOpen}
				onClose={_ => {
					setModalOpen(false);
				}}
				closeAfterTransition
			>
				<RejectModal items={[item]} onReject={onReject} onClose={_ => setModalOpen(false)} />
			</Modal>
			<Button variant="important" onClick={_ => setModalOpen(true)}>
				{translate('wishlist.refuse')}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Reject);
