import React, { Fragment, forwardRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Snackbar from '@mui/material/Snackbar';
import Grow from '@mui/material/Grow';
import MuiAlert from '@mui/material/Alert';
import utils from 'components/utils';

const GrowTransition = props => <Grow {...props} />;
const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notifications = _ => {
	const translate = utils.useI18N();
	const stack = useStoreState(state => state.appNotifications.stack);
	const dismiss = useStoreActions(dispatch => dispatch.appNotifications.dismiss);
	const notification = stack.find(msg => !msg.dismissed);
	const onClose = _ => {
		dismiss(notification.id);
	};
	return (
		<Fragment>
			{notification && (
				<Snackbar
					style={{ height: '100%' }}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
					open={true}
					onClose={onClose}
					// TransitionComponent={GrowTransition}
					autoHideDuration={3000}
				>
					<Alert onClose={onClose} severity={notification.type} style={{ fontSize: 20 }}>
						{!notification.skipI18N && translate(notification.message)}
						{notification.skipI18N && <span>{notification.message}</span>}
					</Alert>
				</Snackbar>
			)}
		</Fragment>
	);
};

export default Notifications;
