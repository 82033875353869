import React, { Fragment, useRef, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import {
	Typography,
	Stack,
	Popover,
	Icon,
	ButtonBase,
	Slide,
	Paper,
	Tooltip,
	alpha,
	useMediaQuery
} from '@mui/material';
import {
	AccountBalanceWallet,
	AccountBalanceWalletOutlined,
	AddShoppingCart,
	AddShoppingCartOutlined,
	AssignmentReturn,
	AssignmentReturnOutlined,
	ClearAll,
	ClearAllOutlined,
	EmojiEvents,
	EmojiEventsOutlined,
	Favorite,
	FavoriteBorder,
	Home,
	HomeOutlined,
	Help,
	HelpOutline,
	InsertDriveFile,
	InsertDriveFileOutlined,
	MenuBook,
	MenuBookOutlined,
	PostAdd,
	PostAddOutlined,
	School,
	SchoolOutlined,
	Settings,
	SettingsOutlined,
	SupervisorAccount,
	SupervisorAccountOutlined
} from '@mui/icons-material';
import utils from 'components/utils';
import k from 'k';
import BadgeRankingCard from 'components/BadgeRankingCard';
import Personification from './components/Personification';
import SupportList from './components/SupportList';
import AdminList from './components/AdminList';
import { grey } from '@mui/material/colors';

const fastSalesUrl =
	'https://bi.messaggerie.it/t/Fastlink/views/DashboardLibrerie/Cockpit?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';

const Sidebar = props => {
	const { distanceLeftSideToChildren, sideBarWidth } = props;

	const fastsales = utils.useFastSales();
	const [iAmPersonifying] = utils.usePersonification();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const theme = useTheme();
	const location = useLocation();
	const personify = useStoreState(state => state.me.personify);
	const userData = useStoreState(state => state.me.data);
	const abilitationCode = personify.iAmPersonifying ? personify.target.abilitationCode : userData.abilitationCode;
	const [openedPopover, setOpenedPopover] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const relativePath = location.pathname.replace(process.env.PUBLIC_URL, '');
	const mainPathFromRelativePath = relativePath
		.split('/')
		.splice(1, 1)
		.pop();
	const open = Boolean(anchorEl);
	const id = open ? 'button-popover' : undefined;

	/** ------------- METHODS ------------- */
	const handleOpen = (event, popover) => {
		setOpenedPopover(popover);
		setAnchorEl(event);
	};
	const handleOpenWindow = href => {
		window.open(href, '_blank');
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	/** ------------- STYLING ------------- */
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const widthButton = 70;
	const responsiveWidthButton = isMobile ? widthButton * 2 : widthButton;
	const heightButton = 55;
	const hoverColor = grey[200];

	const secondaryStyle = {
		borderRadius: 5, //px
		margin: 5, //px
		opacity: 0.3, //@mui/material/alpha
		transition: 0.3, //s
		fontSize: 15 //px
	};
	const leftBorderStyle = {
		border: `3px solid ${theme.palette.primary.main}`,
		position: 'absolute',
		left: 0,
		borderRadius: `${secondaryStyle.borderRadius}px`,
		height: '20px'
	};
	const allSubMenuStyles = {
		singleItem: {
			'&:hover': {
				backgroundColor: alpha(theme.palette.primary.main, secondaryStyle.opacity),
				transition: `${secondaryStyle.transition}s`
			},
			borderRadius: `${secondaryStyle.borderRadius}px`,
			marginTop: `${secondaryStyle.margin}px`,
			width: '100%'
		},
		title: {
			fontSize: `${secondaryStyle.fontSize}px`,
			fontWeight: 600,
			textAlign: 'center'
		},
		divider: {
			color: grey[900],
			marginBottom: `${secondaryStyle.margin}px`,
			width: '80%'
		}
	};

	/** ------------- SIDEBAR MENU ------------- */
	const menuItems = [
		{
			id: ['home'], // id is array so i can operate with find() method on the submenu (i.e. help)
			label: 'Home',
			icon: {
				filled: <Home />,
				outlined: <HomeOutlined />
			},
			to: '/',
			iCan: true
		},
		{
			id: ['catalog'],
			label: 'catalog',
			icon: {
				filled: <MenuBook />,
				outlined: <MenuBookOutlined />
			},
			to: '/catalog',
			iCan: iCan('CATALOGS')
		},
		{
			id: ['badgeranking'],
			label: 'badgeRankings',
			icon: {
				filled: <EmojiEvents />,
				outlined: <EmojiEventsOutlined />
			},
			popover: <BadgeRankingCard setAnchorEl={setAnchorEl} size="66px" />,
			iCan: iCan('CATALOGS')
		},
		{
			id: ['scholastic'],
			label: 'scholastic',
			icon: {
				filled: <School />,
				outlined: <SchoolOutlined />
			},
			openWindow: k.scholasticUrl,
			iCan: iCan('VIEW_SCHOLASTIC_MENU') && abilitationCode != 1
		},
		{
			id: ['wishlist'],
			label: 'wishlist',
			icon: {
				filled: <Favorite />,
				outlined: <FavoriteBorder />
			},
			to: iCan('MANAGE_WISHLIST') ? '/wishlist/condition-request' : '/wishlist/list',
			iCan: iCan('MANAGE_WISHLIST') || iCan('WISHLIST')
		},
		{
			id: ['orders'],
			label: 'orders',
			icon: {
				filled: <AddShoppingCart />,
				outlined: <AddShoppingCartOutlined />
			},
			to: '/orders/list',
			iCan: iCan('ORDERS')
		},
		{
			id: ['proposals'],
			label: 'proposals',
			icon: {
				filled: <ClearAll />,
				outlined: <ClearAllOutlined />
			},
			to: '/proposals',
			iCan: iCan('PROPOSALS')
		},
		{
			id: ['returns'],
			label: 'returns',
			icon: {
				filled: <AssignmentReturn />,
				outlined: <AssignmentReturnOutlined />
			},
			to: '/returns',
			iCan: iCan('RETURNS')
		},
		{
			id: ['budget'],
			label: 'budget',
			icon: {
				filled: <AccountBalanceWallet />,
				outlined: <AccountBalanceWalletOutlined />
			},
			to: '/budget',
			iCan: iCan('BUDGET')
		},
		{
			id: ['documents'],
			label: 'documents',
			icon: {
				filled: <InsertDriveFile />,
				outlined: <InsertDriveFileOutlined />
			},
			to: '/documents',
			iCan: iCan('DOCUMENTS')
		},
		{
			id: ['fastsales'],
			label: 'fastsales',
			icon: {
				filled: <PostAdd />,
				outlined: <PostAddOutlined />
			},
			openWindow: fastSalesUrl,
			iCan: fastsales
		},

		// LASCIATO SOLO NELLA MODALE CHE SI APRE QUANDO PREMI SULL'AVATAR

		// {
		// 	id: ['profile',
		// 	label: 'profile',
		// 	icon: <PersonIcon />,
		// 	to: '/me',
		// 	iCan: true
		// },

		{
			id: ['personify'],
			label: 'Personifica',
			icon: {
				filled: <SupervisorAccount />,
				outlined: <SupervisorAccountOutlined sx={{ color: iAmPersonifying && theme.palette.secondary.main }} />
			},
			popover: <Personification setAnchorEl={setAnchorEl} anchorEl={anchorEl} />,
			iCan: iAmPersonifying || iCan('PERSONIFY_USER')
		},
		{
			id: ['admin'],
			label: 'admin',
			icon: {
				filled: <Settings />,
				outlined: <SettingsOutlined />
			},
			popover: (
				<AdminList
					setAnchorEl={setAnchorEl}
					allSubMenuStyles={allSubMenuStyles}
					secondaryStyle={secondaryStyle}
					relativePath={relativePath}
				/>
			),
			iCan: iCan('BACKOFFICE')
		},
		{
			id: ['support', 'contact'],
			label: 'help',
			icon: {
				filled: <Help />,
				outlined: <HelpOutline />
			},
			popover: (
				<SupportList
					setAnchorEl={setAnchorEl}
					allSubMenuStyles={allSubMenuStyles}
					secondaryStyle={secondaryStyle}
					relativePath={relativePath}
				/>
			),
			iCan: true
		}
	];
	return (
		<Stack
			direction="column"
			justifyContent={{ xs: 'flex-start', md: 'center', sm: 'flex-start' }}
			alignItems="center"
			sx={{ marginLeft: `${distanceLeftSideToChildren - sideBarWidth}px` }}
		>
			{menuItems.map((menuItem, index) => {
				const isSelectedPath =
					relativePath === menuItem.to ||
					mainPathFromRelativePath === menuItem.id.find(element => element === mainPathFromRelativePath);
				const handleClick = event => {
					if (menuItem.openWindow) handleOpenWindow(menuItem.openWindow);
					else if (menuItem.popover) handleOpen(event.currentTarget, menuItem.popover);
					else if (menuItem.to === relativePath) window.location.reload();
				};
				return (
					menuItem.iCan && (
						<Fragment key={index}>
							<Stack direction="row">
								<Tooltip title={translate(`common.menu.${menuItem.label}`)} placement="right">
									<span>
										<ButtonBase
											aria-describedby={id}
											component={menuItem.to && Link}
											sx={{
												margin: '2px',
												height: `${heightButton}px`,
												borderRadius: `${secondaryStyle.borderRadius}px`,
												width: `${responsiveWidthButton}px`,
												color: isSelectedPath ? theme.palette.primary.main : grey[700],
												'&:hover': {
													backgroundColor: hoverColor,
													transition: `${secondaryStyle.transition}s`,
													color: !isSelectedPath && grey[900]
												},
												backgroundColor: isSelectedPath && hoverColor
											}}
											onClick={handleClick}
											to={menuItem.to}
										>
											<Slide direction="right" in={isSelectedPath} unmountOnExit>
												<span style={leftBorderStyle}></span>
											</Slide>
											<Stack
												direction={{ xs: 'row', md: 'column', sm: 'row' }}
												alignItems="center"
												sx={{
													width: '80%',
													marginLeft: isMobile && `${secondaryStyle.margin * 2}px`,
													marginRight: isMobile && `${secondaryStyle.margin * 2}px`
												}}
											>
												<Icon>
													{isSelectedPath ? menuItem.icon.filled : menuItem.icon.outlined}
												</Icon>

												{isMobile && (
													<Typography
														alignItems="center"
														sx={{
															marginTop: `${secondaryStyle.margin}px`,
															marginLeft: `${secondaryStyle.margin * 2}px`,
															fontSize: `${secondaryStyle.fontSize}px`,
															textAlign: 'left'
														}}
														variant="subtitle1"
													>
														{translate(`common.menu.${menuItem.label}`)}
													</Typography>
												)}
											</Stack>
										</ButtonBase>
									</span>
								</Tooltip>
							</Stack>

							{/** ------------- POPOVER ------------- */}
							{menuItem.popover && (
								<Popover
									id={id}
									open={open}
									anchorEl={anchorEl}
									onClose={handleClose}
									anchorOrigin={{
										vertical: 'center',
										horizontal: 'right'
									}}
									transformOrigin={{
										vertical: 'center',
										horizontal: 'left'
									}}
									elevation={3}
								>
									<Paper>{openedPopover}</Paper>
								</Popover>
							)}
						</Fragment>
					)
				);
			})}
		</Stack>
	);
};

export default Sidebar;
