import { action, thunk } from 'easy-peasy';

export default {
	users: [],
	save: action((state, payload) => {
		state.users = payload.map(user => ({
			...user,
			isAlreadySelected: state.group.utente && state.group.utente.includes('.' + user.usercode + '.'),
			isSelected: state.group.utente && state.group.utente.includes('.' + user.usercode + '.')
		}));
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	token: '',
	setToken: action((state, payload) => {
		state.token = payload;
	}),
	group: null,
	setGroup: action((state, payload) => {
		state.group = payload;
	}),
	search: thunk((actions, payload, { getStoreState, getStoreActions, getState, injections }) => {
		const { services } = injections;
		const keyword = getState().token;
		actions.setLoading(true);
		actions.setGroup(getStoreState().userGroups.detailsUserGroup.data);
		services.userGroups
			.listAssignment(keyword)
			.then(data => {
				actions.setSearchPerformed(true);
				actions.save(data);
			})
			.catch(e => {
				getStoreActions().error('userGroups.errorListAssign');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	searchPerformed: false,
	setSearchPerformed: action((state, payload) => {
		state.searchPerformed = payload;
	}),
	resetSearch: action((state, payload) => {
		state.searchPerformed = false;
		state.users = [];
		state.token = '';
	}),
	orderBy: 'usercode',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	toggleSelection: action((state, payload) => {
		const i = state.users.findIndex(usr => usr.usercode === payload);
		if (i === -1) return;
		const user = state.users[i];
		user.isSelected = !user.isSelected;
		state.users = state.users
			.slice(0, i)
			.concat(user)
			.concat(state.users.slice(i + 1));
	}),
	selectAll: action(state => {
		state.users = state.users.map(user => ({
			...user,
			isSelected: true
		}));
	}),
	deselectAll: action(state => {
		state.users = state.users.map(user => ({
			...user,
			isSelected: false
		}));
	}),
	updatingUsers: {
		usersToInsert: null,
		usersToDelete: null
	},
	update: thunk((actions, payload, { injections, getStoreActions, getStoreState, getState }) => {
		const { services } = injections;
		actions.setLoading(true);
		const selected = getState().users.filter(user => user.isSelected);
		const deleted = getState().users.filter(user => !user.isSelected && user.isAlreadySelected);
		let usersToInsert = [];
		let usersToDelete = [];
		if (selected.length > 0) {
			selected.forEach(user => usersToInsert.push(user.usercode));
		}
		if (deleted.length > 0) {
			deleted.forEach(user => usersToDelete.push(user.usercode));
		}
		getState().updatingUsers.usersToInsert = usersToInsert.join('.');
		getState().updatingUsers.usersToDelete = usersToDelete.join('.');
		return services.userGroups
			.assignToUserGroup(getState().updatingUsers, getStoreState().userGroups.detailsUserGroup.data.id)
			.then(data => {
				getStoreActions().success('userGroups.usersAssociated');
				return Promise.resolve(data);
			})
			.catch(_ => {
				getStoreActions().error('userGroups.errorAssociated');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
