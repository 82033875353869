import search from './search';
import getById from './get-by-id';
import getByUserCode from './get-by-user-code';
import updateById from './update-by-id';
import updateDepots from './update-depots';
import conditionsOfSale from './conditions-of-sale';
import getPermissions from './get-permissions';
import updatePermission from './update-permission';

export default {
	search,
	getById,
	getByUserCode,
	updateById,
	updateDepots,
	conditionsOfSale,
	getPermissions,
	updatePermission
};
