import React from 'react';
import { useStoreState } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';

const Empty = ({ additionalStyles }) => {
	const searchLast = useStoreState(state => state.catalog.search.last);
	const promoSearch = searchLast && searchLast.filters ? searchLast.filters.selectedPromotion : false;
	const defaultStyles = {
		paddingLeft: 0,
		paddingRight: 0,
		backgroundColor: promoSearch ? '#e67272' : '#fafafa'
	};
	return (
		<TableCell align="left" style={{ ...defaultStyles, ...additionalStyles }}>
			<span className="MuiButtonBase-root" aria-disabled="true">
				&nbsp;
			</span>
		</TableCell>
	);
};

export default Empty;
