import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import HeadlessCard from 'components/Card/HeadlessCard';
import styles from './styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import Card from 'components/Card';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField/TextField';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePickerFrom } from './components/DatePickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const AddNewEditorial = ({ classes, close, showCloseIcon }) => {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [active, setActive] = useState(false);
	const addEditorial = useStoreActions(dispatch => dispatch.editorials.add.sendRequest);
	const pubDateFrom = useStoreState(state => state.editorials.add.dateFrom);
	const setAddOpen = useStoreActions(dispatch => dispatch.editorials.add.setOpen);
	const translate = utils.useI18N();

	const onClick = _ => {
		const data = {
			companyId: 0,
			name: name,
			description: description,
			enabled: active,
			startDate: pubDateFrom,
			attachFileId: 0
		};

		addEditorial(data);
	};
	const onClose = _ => {
		setAddOpen(false);
	};

	return (
		<div
			className={clsx(classes.negateMargin, {
				[classes.margin]: showCloseIcon
			})}
		>
			<HeadlessCard>
				<Grid container spacing={2}>
					{showCloseIcon && (
						<Grid container item xs={12} justifyContent="flex-end">
							<IconButton onClick={close} className={classes.closeIcon}>
								<CloseIcon />
							</IconButton>
						</Grid>
					)}
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<Box sx={{ padding: '16px' }}>
							<Grid container spacing={2}>
								<Grid container item xs={12}>
									<TextField
										value={name}
										onChange={e => setName(e.target.value)}
										fullWidth={true}
										label={translate('editorials.name') + '*'}
									/>
								</Grid>
								<Grid container item xs={12}>
									<TextField
										value={description}
										onChange={e => setDescription(e.target.value)}
										fullWidth={true}
										label={translate('editorials.description')}
									/>
								</Grid>
								<Grid className={classes.baseGrid} item xs={12} sm={6} container>
									<DatePickerFrom />
								</Grid>
								<Grid className={classes.baseGrid} item xs={12} sm={12} container>
									<Typography>{translate('editorials.tables.status')}</Typography>
									<Checkbox
										onChange={() => setActive(!active)}
										value={active}
										color={'primary'}
										style={{ padding: 0 }}
									/>
								</Grid>
								<Grid container item xs={6}>
									<Button onClick={onClose} variant="outlined" color="primary" type="submit">
										{translate('common.close')}
									</Button>
								</Grid>
								<Grid container item xs={6} justifyContent="flex-end">
									<Button
										onClick={onClick}
										variant="contained"
										color="primary"
										type="submit"
										disabled={name === ''}
									>
										{translate('common.save')}
									</Button>
								</Grid>
							</Grid>
						</Box>
					</LocalizationProvider>
				</Grid>
			</HeadlessCard>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(AddNewEditorial);
