import React from 'react';
import utils from 'components/utils';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import styles from './styles';

const Restock = ({ classes, value }) => {
	const translate = utils.useI18N();
	let text = translate('catalog.futureDisp.futureDispHigh');
	if (value < 1000) text = translate('catalog.futureDisp.futureDisp1000');
	if (value < 500) text = translate('catalog.futureDisp.futureDisp500');
	if (value < 100) text = translate('catalog.futureDisp.futureDisp100');
	if (value < 10) text = translate('catalog.futureDisp.futureDisp10');
	if (!value || value === 0) return '';
	return (
		<Tooltip title={text}>
			<IconButton className={classes.custom} aria-label={text}>
				<LocalShippingIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
};

export default withStyles(styles, { withTheme: true })(Restock);
