import React, { Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useParams, useHistory, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import { green, red } from '@mui/material/colors';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Loader from 'components/Loader';
import Card from 'components/Card';
import utils from 'components/utils';
import HeadlessCard from 'components/Card/HeadlessCard';
import AddButton from '../components/AddButton';
import EditModal from './components/EditModal';
import AddModal from './components/AddModal';
import styles from './styles';
import NoResults from 'components/NoResults';

const WithdrawTypes = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const params = useParams();
	const loading = useStoreState(state => state.shippingType.list.loading);
	const depots = useStoreState(state => state.depots.data);
	const editModalOpen = useStoreState(state => state.shippingType.editModalOpen);
	const list = useStoreState(state => state.shippingType.list.items);
	const load = useStoreActions(dispatch => dispatch.shippingType.list.load);
	const openFromUrl = useStoreActions(state => state.shippingType.list.openFromUrl);
	const setOpenFromUrl = useStoreActions(dispatch => dispatch.shippingType.list.setOpenFromUrl);
	const setEditModalOpen = useStoreActions(dispatch => dispatch.shippingType.setEditModalOpen);
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [depotName, setDepotName] = useState('');
	const [depotCode, setDepotCode] = useState('');
	const [currentEditingItem, setCurrentEditingItem] = useState({});
	const history = useHistory();

	useEffect(
		_ => {
			if (params.code) load(params.code);
		},
		[load, params]
	);

	useEffect(
		_ => {
			if (list && list.length > 0 && params.id) {
				const currentItem = list.find(value => value.id === params.id);
				if (currentItem) {
					setCurrentEditingItem(currentItem);
					setEditModalOpen(true);
				}
			}
		},
		[list, params.id, setEditModalOpen]
	);

	const closeModal = _ => {
		setAddModalOpen(false);
		setEditModalOpen(false);
	};

	const closeEditModal = () => {
		setEditModalOpen(false);
		if (!openFromUrl) history.goBack();
	};
	useEffect(
		_ => {
			const currentDepot = depots.find(d => d.code === params.code);
			if (!currentDepot) return;
			setDepotName(currentDepot.name + ' - ' + currentDepot.code);
			setDepotCode(currentDepot.code);
		},
		// eslint-disable-line react-hooks/exhaustive-deps
		[depots, setDepotName, params]
	);
	const getWarningTooltipText = enabled =>
		enabled
			? translate('admin.withdrawTypes.warningIsEnabled')
			: translate('admin.withdrawTypes.warningIsNotDisabled');
	return (
		<Fragment>
			<Modal
				className={classes.modalPosition}
				open={addModalOpen || editModalOpen}
				onClose={closeModal}
				aria-labelledby="shipping-types-modal"
				aria-describedby="shipping-types-modal"
			>
				<div className="modal-content">
					<Fragment>
						{addModalOpen && <AddModal onClose={closeModal} depotCode={depotCode} />}
						{editModalOpen && <EditModal onClose={closeEditModal} item={currentEditingItem} />}
					</Fragment>
				</div>
			</Modal>

			{loading && (
				<Grid item container xs={12} justifyContent="center" spacing={3} className={classes.elements}>
					<Loader />
				</Grid>
			)}
			{!loading && (
				<Grid item xs={12}>
					<Card type="primary" title={depotName}>
						<Grid item container sm={12}>
							<Grid item xs={12}>
								<HeadlessCard style={{ margin: '20px 0' }} bodyStyle={{ padding: 0 }}>
									<AddButton
										onClick={_ => {
											setAddModalOpen(true);
										}}
									/>
								</HeadlessCard>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									{list.length < 1 ? (
										<Grid item xs={12} align="center" justifyContent="center" alignItems="center">
											<NoResults text="Non ci sono tipi di ritiro assegnati a questo deposito" />
										</Grid>
									) : (
										<TableContainer component={Paper}>
											<Table aria-label="manage evasion" size="small">
												<TableHead>
													<TableRow>
														<TableCell>
															{translate('admin.withdrawTypes.codeType')}
														</TableCell>
														<TableCell
															sx={{
																display: {
																	sm: 'none',
																	xs: 'inherit',
																	md: 'inherit'
																}
															}}
														>
															{translate('admin.withdrawTypes.descType')}
														</TableCell>
														<TableCell
															style={{ width: 20, paddingLeft: 0, paddingRight: 0 }}
														>
															&nbsp;
														</TableCell>
														<TableCell>Warning</TableCell>
														<TableCell>&nbsp;</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{list.map((item, index) => (
														<TableRow key={`depot_cell_${item.shippingRule.code}`}>
															<TableCell align="left">{item.shippingRule.code}</TableCell>
															<TableCell
																align="left"
																sx={{
																	display: {
																		sm: 'none',
																		xs: 'inherit',
																		md: 'inherit'
																	}
																}}
															>
																{item.shippingRule.desc}
															</TableCell>
															<TableCell
																align="left"
																style={{ width: 20, paddingLeft: 0, paddingRight: 0 }}
															>
																<Tooltip
																	title={getWarningTooltipText(item.warningEnabled)}
																	placement="top"
																>
																	<IconButton
																		disableRipple
																		className={classes.noRipple}
																		aria-label={getWarningTooltipText(
																			item.warningEnabled
																		)}
																		style={{ paddingLeft: 0, paddingRight: 0 }}
																	>
																		{item.warningEnabled && (
																			<CheckCircleOutlineRoundedIcon
																				fontSize="small"
																				style={{ color: green[300] }}
																			/>
																		)}
																		{!item.warningEnabled && (
																			<HighlightOffRoundedIcon
																				fontSize="small"
																				style={{ color: red[300] }}
																			/>
																		)}
																	</IconButton>
																</Tooltip>
															</TableCell>
															<TableCell align="left">{item.warning}</TableCell>
															<TableCell align="left" style={{ width: 25 }}>
																<Link
																	to={`/admin/withdraw-types/${params.code}/${item.id}`}
																>
																	<Tooltip
																		title={translate('common.edit')}
																		placement="top"
																	>
																		<IconButton
																			size="small"
																			style={{ padding: 8 }}
																			disableRipple
																			className={classes.noRipple}
																			onClick={_ => {
																				setCurrentEditingItem(item);
																				setEditModalOpen(true);
																				setOpenFromUrl(false);
																			}}
																		>
																			<EditIcon />
																		</IconButton>
																	</Tooltip>
																</Link>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(WithdrawTypes);
