import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import styles from './styles';

import Button from '@mui/material/Button';
import utils from 'components/utils';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const Delete = ({ classes, close, showCloseIcon }) => {
	const results = useStoreState(state => state.editorials.list.items);
	const deleteEditorial = useStoreActions(dispatch => dispatch.editorials.deleteEditorials.deleteEditorial);
	const items = results.filter(r => r.isSelected);
	const setModalOpen = useStoreActions(dispatch => dispatch.editorials.deleteEditorials.openModalDelete);
	const translate = utils.useI18N();

	const onClick = _ => {
		items.forEach(function(item) {
			deleteEditorial(item.id);
			setModalOpen(false);
		});
	};

	return (
		<Card>
			<div style={{ padding: '5%' }}>
				<Typography align={'center'}>{translate('editorials.delete')}</Typography>
				<Button align={'center'} onClick={() => onClick()}>
					si
				</Button>
				<Button style={{ float: 'right' }} onClick={() => setModalOpen(false)}>
					{translate('editorials.return')}
				</Button>
			</div>
		</Card>
	);
};

export default withStyles(styles, { withTheme: true })(Delete);
