import { action, thunk, thunkOn } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: {},
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.conditionRequest
			.getDetails(payload)
			.then(data => actions.save({ data, id: payload }))
			.catch(e => {
				getStoreActions().error('wishlist.retrieveConditionDetails');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	save: action((state, payload) => {
		const { data, id } = payload;
		state.data[id] = {
			status: data.item.status,
			deleted: data.item.deleted,
			wishlistItem: data.item.wishlistItems
		};
	}),
	reset: action((state, payload) => {
		state.data = {};
	}),
	clearCacheOnWishlistLoad: thunkOn(
		(actions, storeActions) => [
			storeActions.conditionRequest.pendingList.load.successType,
			storeActions.conditionRequest.acceptedList.load.successType,
			storeActions.conditionRequest.refusedList.load.successType
		],
		async (actions, target, helpers) => {
			actions.reset();
		}
	)
};
