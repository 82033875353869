import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const SapIdComp = ({ sapPromoId }) => {
	return <StyledTableCell>{sapPromoId}</StyledTableCell>;
};

const SapId = memo(
	({ item }) => <SapIdComp sapPromoId={item.sapPromoId} />,
	(p, n) => p.item.sapPromoId === n.item.sapPromoId
);

export default SapId;
