export default (actions, payload, { getStoreState, injections, getStoreActions }) => {
	const { services } = injections;
	actions.setLoading(true);
	const filters = getStoreState().rankings.filters.list;
	const params = { filters };
	return services.rankings
		.search(params)
		.then(data => {
			getStoreActions().rankings.list.save({ data });
			return Promise.resolve(data.items);
		})
		.catch(e => {
			getStoreActions().error('rankings.search');
		})
		.finally(_ => {
			actions.setLoading(false);
		});
};
