import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const CreatedByComp = ({ createdBy }) => {
	return <StyledTableCell>{createdBy}</StyledTableCell>;
};

const CreatedBy = memo(
	({ item }) => <CreatedByComp createdBy={item.createdBy} />,
	(p, n) => p.item.createdBy === n.item.createdBy
);

export default CreatedBy;
