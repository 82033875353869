import React from 'react';
import { useStoreState } from 'easy-peasy';

const withColumns = WrappedComponent => {
	return props => {
		const searchOpen = useStoreState(state => state.editorials.list.searchOpen);
		const cols = ['selection', 'id', 'sapName', 'status', 'startDate', 'edit'];
		const colCount = searchOpen ? 4 : 9;
		const visibleCols = cols.slice(0, colCount);
		return <WrappedComponent {...props} visibleCols={visibleCols} />;
	};
};

export default withColumns;
