import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

let theme = createTheme({
	palette: {
		primary: {
			main: '#00529b'
		},
		secondary: {
			main: '#FA3E41'
		},
		custom: {
			black: '#000',
			blacklight: '#4e4e4e',
			body: '#f5f5f5',
			disabled: '#a1a1a1',
			green: '#006600',
			greenlight: '#009D00',
			grey: '#FAFAFA',
			greyback: '#f6f6f6',
			greyborder: '#cccdce',
			greytable: '#ececec',
			hover: '#0075DF',
			hoversecondary: '#ff595b',
			yellow: '#fbc02d',
			orange: '#E85C00',
			red: '#C40000',
			seleted: '#0075DF',
			white: '#FFFFFF',
			book2c: '#39ace9',
			chip: '#183149',
			menu: '#f3f6fc'
		},
		table: {
			white: '#ffffff',
			yellow: '#ffffd8',
			green: '#d2ffd2',
			red: '#ffdcdc'
		}
	}
});

const fontFamily = ['"Poppins", sans-serif'].join(',');
theme = createTheme(theme, {
	components: {
		MuiTypography: {
			variants: [
				{
					props: { variant: 'titlePage' },
					style: {
						fontFamily: fontFamily,
						fontSize: '20px',
						fontWeight: '600',
						'&::first-letter': {
							textTransform: 'uppercase'
						}
					}
				},
				{
					props: { variant: 'titleTab' },
					style: {
						fontFamily: fontFamily,
						fontSize: '13px',
						fontWeight: '600',
						textTransform: 'uppercase',
						marginLeft: '5px',
						lineHeight: '1.4em',
						textAlign: 'left',
						[theme.breakpoints.down('md')]: {
							fontSize: '13px'
						}
					}
				},
				{
					props: { variant: 'titleSection' },
					style: {
						fontFamily: fontFamily,
						fontSize: '16px',
						fontWeight: '600',
						lineHeight: '1.4em',
						'&::first-letter': {
							textTransform: 'uppercase'
						}
					}
				},
				{
					props: { variant: 'titleAccordion' },
					style: {
						fontFamily: fontFamily,
						fontSize: '15px',
						fontWeight: '600',
						lineHeight: '1.3em',
						textTransform: 'lowercase',
						'&::first-letter': {
							textTransform: 'uppercase'
						},
						[theme.breakpoints.down('md')]: {
							fontSize: '14px'
						}
					}
				},
				{
					props: { variant: 'text' },
					style: {
						fontFamily: fontFamily,
						fontSize: '15px',
						fontWeight: '400',
						lineHeight: '1.2em',
						[theme.breakpoints.down('md')]: {
							fontSize: '14px'
						}
					}
				},
				{
					props: { variant: 'textTable' },
					style: {
						fontFamily: fontFamily,
						fontSize: '14px',
						fontWeight: '500',
						lineHeight: '1.2em',
						[theme.breakpoints.down('md')]: {
							fontSize: '13px'
						},
						'&::first-letter': {
							textTransform: 'uppercase'
						}
					}
				},
				{
					props: { variant: 'tooltip' },
					style: {
						fontFamily: fontFamily,
						fontSize: '11px',
						fontWeight: '400',
						lineHeight: '1.1em'
					}
				},
				{
					props: { variant: 'chip' },
					style: {
						fontFamily: ['"Roboto", sans-serif'].join(','),
						fontSize: '14px',
						fontWeight: '500',
						color: theme.palette.custom.white,
						backgroundColor: theme.palette.custom.chip,
						borderRadius: '5px',
						padding: '2px 4px',
						marginLeft: '3px'
					}
				},
				{
					props: { variant: 'titleStyled' },
					style: {
						fontFamily: ['"Open Sans", sans-serif'].join(','),
						fontSize: '30px',
						fontWeight: '600',
						lineHeight: '1em',
						color: theme.palette.custom.black
					}
				},
				{
					props: { variant: 'textStyled' },
					style: {
						fontFamily: ['"Open Sans", sans-serif'].join(','),
						fontSize: '18px',
						fontWeight: '400',
						lineHeight: '1.2em',
						textAlign: 'justify',
						color: theme.palette.custom.black
					}
				}
			]
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontFamily: fontFamily,
					fontSize: '0.95em',
					backgroundColor: theme.palette.custom.white,
					color: theme.palette.custom.black,
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: theme.palette.custom.greyborder,
					maxWidth: '300px'
				},
				arrow: {
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: theme.palette.custom.greyborder,
					color: theme.palette.custom.white
				}
			}
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'light' },
					style: {
						fontFamily: fontFamily,
						fontSize: '14px',
						fontWeight: '600',
						textTransform: 'capitalize',
						borderWidth: '1px',
						borderStyle: 'solid',
						borderRadius: '8px',
						borderColor: theme.palette.custom.greyborder,
						backgroundColor: theme.palette.custom.white,
						padding: '7px 14px',
						'&:hover': {
							backgroundColor: theme.palette.custom.hover,
							color: theme.palette.custom.white
						},
						[theme.breakpoints.down('md')]: {
							fontSize: '13px'
						}
					}
				},
				{
					props: { variant: 'dark' },
					style: {
						fontFamily: fontFamily,
						fontSize: '14px',
						fontWeight: '600',
						textTransform: 'capitalize',
						borderWidth: '1px',
						borderStyle: 'solid',
						borderRadius: '8px',
						padding: '7px 14px',
						[theme.breakpoints.down('md')]: {
							fontSize: '13px'
						},
						borderColor: theme.palette.custom.greyborder,
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.custom.white,
						'&:hover': {
							backgroundColor: theme.palette.custom.hover,
							color: theme.palette.custom.white
						},
						'&:disabled': {
							backgroundColor: theme.palette.custom.disabled,
							color: theme.palette.custom.white
						},
						[theme.breakpoints.down('md')]: {
							fontSize: '13px'
						}
					}
				},
				{
					props: { variant: 'textAction' },
					style: {
						fontFamily: ['"Poppins", sans-serif'].join(','),
						fontSize: '14px',
						fontWeight: '600',
						textTransform: 'capitalize',
						border: '0',
						backgroundColor: theme.palette.custom.white,
						color: theme.palette.primary.main,
						padding: '0',
						'&:hover': {
							backgroundColor: theme.palette.custom.white,
							color: theme.palette.custom.hover
						},
						[theme.breakpoints.down('md')]: {
							fontSize: '13px'
						}
					}
				},
				{
					props: { variant: 'important' },
					style: {
						fontFamily: ['"Poppins", sans-serif'].join(','),
						fontSize: '14px',
						fontWeight: '600',
						textTransform: 'capitalize',
						borderWidth: '1px',
						borderStyle: 'solid',
						borderRadius: '8px',
						padding: '7px 14px',
						[theme.breakpoints.down('md')]: {
							fontSize: '13px'
						},
						backgroundColor: theme.palette.secondary.main,
						color: theme.palette.custom.white,
						'&:hover': {
							backgroundColor: theme.palette.custom.hoversecondary,
							color: theme.palette.custom.white
						},
						'&:disabled': {
							backgroundColor: theme.palette.custom.disabled,
							color: theme.palette.custom.grey
						}
					}
				}
			]
		},
		MuiToggleButton: {
			variants: [
				{
					props: { variant: 'tab' },
					style: {
						fontFamily: fontFamily,
						fontSize: '14px',
						fontWeight: '600',
						border: '0 !important',
						background: 'none !important',
						padding: '0 24px 0 0 !important',
						[theme.breakpoints.down('md')]: {
							padding: '0 8px 0 0 !important'
						}
					}
				}
			]
		},
		MuiStack: {
			variants: [
				{
					props: { variant: 'wrapperColor' },
					style: {
						padding: '15px 20px',
						width: '100%',
						backgroundColor: theme.palette.custom.grey,
						borderBottom: '1px solid #ECEEEF',
						[theme.breakpoints.down('md')]: {
							padding: '20px 10px'
						}
					}
				},
				{
					props: { variant: 'wrapperSection' },
					style: {
						padding: '20px 0',
						width: '100%'
						/*backgroundColor: theme.palette.custom.white*/
					}
				}
			]
		},
		MuiInputBase: {
			variants: [
				{
					props: { variant: 'searchField' },
					style: {
						fontFamily: fontFamily,
						fontSize: '14px',
						fontWeight: '400'
					}
				},
				{
					props: { variant: 'textBox' },
					style: {
						fontFamily: fontFamily,
						fontSize: '14px',
						fontWeight: '400',
						border: `1px solid ${grey[400]}`,
						borderRadius: '3px',
						padding: '5px',
						'&:hover': {
							border: `1px solid ${theme.palette.primary.main}`
						},
						'&.Mui-focused': {
							border: `2px solid ${theme.palette.primary.main}`
						},
						'&.Mui-disabled': {
							border: `1px solid ${grey[400]}`
						}
					}
				}
			]
		},
		MuiInputLabel: {
			variants: [
				{
					props: { variant: 'standard' },
					style: {
						fontFamily: fontFamily,
						color: grey[500],
						position: 'relative',
						left: '10px',
						fontSize: '12px',
						fontWeight: '400',
						'&::first-letter': {
							textTransform: 'uppercase'
						}
					}
				}
			]
		},
		MuiPaper: {
			variants: [
				{
					props: { variant: 'searchField' },
					style: {
						display: 'flex',
						alignItems: 'center',
						boxShadow: 'none',
						borderWidth: '1px',
						borderStyle: 'solid',
						borderRadius: '5px',
						borderColor: theme.palette.custom.greyborder,
						padding: '4px',
						width: '300px'
					}
				},
				{
					props: { variant: 'stickyTop' },
					style: {
						width: '100% !important',
						position: 'sticky',
						top: '58px',
						zIndex: '1'
					}
				}
			]
		},
		MuiAccordion: {
			variants: [
				{
					props: { variant: 'listing' },
					style: {
						marginTop: '8px',
						borderStyle: 'solid',
						borderWidth: '1px',
						borderColor: theme.palette.custom.white,
						'&:before': {
							backgroundColor: theme.palette.custom.white
						}
					}
				}
			]
		},
		MuiListItemButton: {
			variants: [
				{
					props: { variant: 'mobileList' },
					style: {
						'& svg': {},
						'& span': {
							fontFamily: fontFamily,
							fontSize: '16px',
							fontWeight: '600'
						},
						'&.MuiListItemIcon-root': {
							minWidth: '35px',
							border: '1px solid red'
						}
					}
				}
			]
		},
		MuiDatePicker: {
			variants: [
				{
					props: { variant: 'selectDate' },
					style: {
						backgroundColor: theme.palette.custom.white,
						border: '1px solid red !important'
					}
				}
			]
		},
		MuiFormControlLabel: {
			variants: [
				{
					props: { variant: 'checkbox' },
					style: {
						fontFamily: fontFamily,
						':hover': {
							backgroundColor: grey[200],
							transition: '0.3s'
						},
						'&.Mui-disabled': {
							backgroundColor: 'transparent'
						}
					}
				}
			]
		}
	}
});

export default theme;
