import { action, actionOn, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: actionOn(
		actions => [actions.subscribe.startType, actions.unsubscribe.startType],
		state => {
			state.loading = true;
		}
	),
	unsetLoading: actionOn(
		actions => [actions.subscribe.type, actions.unsubscribe.type],
		state => {
			state.loading = false;
		}
	),
	showAlert: false,
	setShowAlert: action((state, payload) => {
		state.showAlert = payload;
	}),
	subscribe: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setShowAlert(false);
		return services.me
			.subscribeChannelToMessageType(payload)
			.then(data => {
				getStoreActions().success('me.channelMessageSubscription');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('me.channelMessageSubscription');
			});
	}),
	unsubscribe: thunk((actions, payload, { injections, getStoreActions }) => {
		const { services } = injections;
		actions.setShowAlert(false);
		return services.me
			.unsubscribeChannelToMessageType(payload)
			.then(data => {
				getStoreActions().success('me.channelMessageSubscription');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('me.channelMessageSubscription');
			});
	})
};
