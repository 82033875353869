import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import utils from 'components/utils';

const PromoInfo = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const promoCode = useStoreState(state => state.catalog.filters.list.selectedPromotion);
	const promoList = useStoreState(state => state.catalog.promotions.list);
	const promo = promoList.find(item => item.sapPromoId === promoCode);

	return (
		<Fragment>
			{promo && (
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={0.5}
					variant="wrapperColor"
					sx={{
						width: '100%',
						borderColor: theme.palette.secondary.main,
						borderWidth: '1px !important',
						borderStyle: 'solid'
					}}
				>
					<Typography variant="titleSection" sx={{ color: theme.palette.secondary.main, fontWeight: '400' }}>
						{translate('catalog.details.promo.buyWithPromo')}
					</Typography>
					<Typography variant="titleSection" sx={{ color: theme.palette.secondary.main }}>
						{promo.name}
					</Typography>
				</Stack>
			)}
		</Fragment>
	);
};

export default PromoInfo;
