import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItem, Typography, ListItemText, ListItemIcon, Switch, Stack } from '@mui/material';
import utils from 'components/utils';

const SelectCart = props => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const ordersOpen = useStoreState(state => state.cart.open.items);
	const loadOpen = useStoreActions(dispatch => dispatch.cart.open.load);
	const loadingOpen = useStoreState(state => state.cart.open.loading);
	const items = useStoreState(state => state.cart.evasion.items);
	const setItems = useStoreActions(dispatch => dispatch.cart.evasion.setItems);
	const toggleSelection = useStoreActions(dispatch => dispatch.cart.evasion.toggleSelection);
	const selectAll = useStoreActions(dispatch => dispatch.cart.evasion.selectAll);
	const deselectAll = useStoreActions(dispatch => dispatch.cart.evasion.deselectAll);
	const allSelected = items.every(i => i.selected);

	const toggleAllSelection = _ => {
		if (allSelected) deselectAll();
		else selectAll();
	};

	//Se non sono ancora stati caricati gli ordini aperti li carico, perchè servono per visualizzare i carrelli evadibil
	useEffect(() => {
		if (!ordersOpen || ordersOpen.length === 0) loadOpen();
	}, []);

	//Se non sono ancora stati i carrelli evdibili li carico
	useEffect(() => {
		if (!items || items.length == 0) setItems();
	}, [ordersOpen]);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			{!loadingOpen && (
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={0.5}
					sx={{
						width: '80%',
						borderBottom: '1px solid #ccc',
						margin: '0 0 -10px 16px !important'
					}}
				>
					<Switch
						onChange={toggleAllSelection}
						checked={allSelected}
						//indeterminate={items.filter(item => item.selected).length > 0}
						sx={{ color: theme.palette.primary.main }}
					/>
					<Typography
						variant="text"
						sx={{
							fontWeight: '600',
							cursor: 'pointer',
							color: allSelected ? theme.palette.primary.main : 'inherit'
						}}
						onClick={toggleAllSelection}
					>
						{//allSelected ? translate('common.deselectAll') : translate('common.selectAll')
						translate('common.selectAll')}
					</Typography>
				</Stack>
			)}
			<List sx={{ maxWidth: '100%' }}>
				{items.map((item, index) => (
					<ListItem key={index}>
						<ListItemButton
							onClick={e => toggleSelection(item.idForSelection)}
							sx={{ padding: '0 20px 0 0' }}
						>
							<ListItemIcon>
								<Switch checked={item.selected} inputProps={{ 'aria-labelledby': item.name }} />
							</ListItemIcon>
							<ListItemText
								primary={
									<Stack
										direction="row"
										justifyContent="flex-start"
										alignItems="center"
										spacing={0.5}
									>
										{item.fcd && (
											<Typography
												variant="chip"
												sx={{ backgroundColor: theme.palette.custom.black }}
											>
												fcd
											</Typography>
										)}
										<Typography
											variant="text"
											sx={{
												fontWeight: '600',
												color: item.selected ? theme.palette.primary.main : 'inherit'
											}}
										>
											{!item.isDefault ? item.name : translate('orders.defaultCart')}
										</Typography>
									</Stack>
								}
								secondary={
									<Typography variant="text">
										{translate('orders.evasion.totalEvadible') +
											': ' +
											utils.formatPrice(item.resumeOrder.sumCostProcessable)}
									</Typography>
								}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Stack>
	);
};

export default SelectCart;
