import React from 'react';
import Id from './Id';
import SapName from './SapName';
import Status from './Status';
import StartDate from './StartDate';
import EndDate from './EndDate';
import Edit from './Edit';
import Selection from './Selection';
import Attachment from './Attachment';

const allCells = [
	{
		id: 'selection',
		comp: ({ item }) => <Selection key={`bodyCell_selection_coupons_${item.selection}`} item={item} />
	},
	{
		id: 'id',
		comp: ({ item }) => <Id key={`bodyCell_id_coupons_${item.id}`} item={item} />
	},
	{
		id: 'name',
		comp: ({ item }) => <SapName key={`bodyCell_sapName_coupons_${item.sapName}`} item={item} />
	},
	{
		id: 'status',
		comp: ({ item }) => <Status key={`bodyCell_status_coupons_${item.status}`} item={item} />
	},
	{
		id: 'startDate',
		comp: ({ item }) => <StartDate key={`bodyCell_startDate_coupons_${item.startDate}`} item={item} />
	},
	{
		id: 'endDate',
		comp: ({ item }) => <EndDate key={`bodyCell_endDate_coupons_${item.endDate}`} item={item} />
	},
	{
		id: 'attachment',
		comp: ({ item }) => <Attachment key={`bodyCell_attach_coupons_${item.attachment}`} item={item} />
	},
	{
		id: 'edit',
		comp: ({ item }) => <Edit key={`bodyCell_edit_coupons_${item.edit}`} item={item} />
	}
];

export default allCells;
