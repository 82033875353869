import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import head from 'lodash/head';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AcceptButton from './components/AcceptButton';
import RejectButton from './components/RejectButton';
import DepotSelector from './components/DepotSelector';
import TextArea from './components/TextArea';
import utils from 'components/utils';
import styles from './styles';
import DownloadPdf from './components/DownloadPdf';
import { useHistory } from 'react-router';
import { SwipeableDrawer, Stack, Dialog, useMediaQuery, Slide, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Detail = ({ classes, showCloseIcon }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const currentDetail = useStoreState(state => state.returns.currentDetail);
	const detailOpen = useStoreState(state => state.returns.detailOpen);
	const accepted = useStoreState(state => state.returns.accepted.list);
	const tabSelected = useStoreState(state => state.returns.tabSelected);
	const pending = useStoreState(state => state.returns.pending.list);
	const refused = useStoreState(state => state.returns.refused.list);
	const setDetailOpen = useStoreActions(dispatch => dispatch.returns.setDetailOpen);
	const translate = utils.useI18N();
	const cardTitle = translate('returns.detailTitle') + ' ' + currentDetail.id;
	const detailMap = { accepted, pending, refused };
	const history = useHistory();
	const item = head(detailMap[currentDetail.type].filter(itm => itm.number === currentDetail.id));
	if (!item) return '';

	const onCloseModal = () => {
		setDetailOpen(false);
		history.push(`/returns`);
	};

	return (
		<SwipeableDrawer
			// idToCheck={`simple-tabpanel-${tabSelected}`}
			anchor="right"
			onClose={_ => {
				onCloseModal();
			}}
			onOpen={_ => {
				setDetailOpen(true);
			}}
			open={detailOpen}
			PaperProps={{ sx: { md: { width: '40%' }, xs: { width: '70%' } } }}
		>
			<Stack
				direction="column"
				spacing={3}
				// justifyContent={'cespacnter'}
				alignItems={'flex-start'}
				sx={{ margin: theme.spacing(3) }}
			>
				<Stack
					direction={'row'}
					spacing={2}
					alignItems={'center'}
					justifyContent={'space-between'}
					width={'100%'}
				>
					<Typography variant="titleSection">{cardTitle}</Typography>
					<DownloadPdf item={item} />
					<IconButton onClick={_ => onCloseModal()} sx={{ padding: 0 }}>
						<CloseIcon />
					</IconButton>
				</Stack>
				<Divider sx={{ width: '100%' }} />
				<Typography variant="body1">
					<strong>{translate('returns.owner')}:</strong> {utils.capitalizeAll(item.destUserBusinessName)}
				</Typography>

				<Typography variant="body1">
					<strong>{translate('returns.customer')}:</strong> {item.sendUserCode} - {item.sendUserBusinessName}
				</Typography>
				<Typography variant="body1">
					<strong>{translate('returns.detail.numPackages')}:</strong> {item.packages}
				</Typography>
				<Typography variant="body1">
					<strong>{translate('returns.detail.numItems')}:</strong> {item.pieces}
				</Typography>
				<Typography variant="body1">
					<strong>{translate('returns.detail.ddt')}:</strong> {item.ddt}
				</Typography>
				<Typography variant="body1">
					<strong>{translate('returns.detail.dataDdt')}:</strong>
					{item.dataDdt}
				</Typography>
				<Typography variant="body1">
					<strong>{translate('returns.detail.weight')}:</strong> {item.weight} Kg
				</Typography>
				<Typography variant="body1">
					<strong>{translate('returns.detail.sumCost')}:</strong> {utils.formatPrice(item.amount)}
				</Typography>
				<DepotSelector item={item} />
				<FormGroup aria-label="position" row>
					<FormControlLabel
						checked={item.flagAdhesionServiceReturn}
						disabled={true}
						control={<Checkbox color="primary" />}
						label={translate('returns.detail.enableService')}
						labelPlacement="end"
					/>
				</FormGroup>
				<TextArea notes={item.notes} />
				<Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'} alignItems={'center'}>
					<AcceptButton item={item} />
					<RejectButton item={item} />
				</Stack>
				{/* <Grid item container xs={6} justify="flex-end">
						<Button
							disableElevation
							variant="outlined"
							color="primary"
							onClick={_ => {
								onCloseModal();
							}}
						>
							{translate('common.close')}
						</Button>
					</Grid> */}
			</Stack>
		</SwipeableDrawer>
	);
};

export default withStyles(styles, { withTheme: true })(Detail);
