import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';

const styles = theme => ({
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	}
});

const SearchButton = ({ classes, setAnchorEl }) => {
	const translate = utils.useI18N();
	const searchOpen = useStoreState(state => state.promotions.filters.searchOpen);
	const setSearchOpen = useStoreActions(dispatch => dispatch.promotions.filters.setSearchOpen);
	return (
		<Button
			className={classes.buttonsMargin}
			variant="outlined"
			onClick={event => setAnchorEl(event.currentTarget)}
		>
			{translate('promo.searchInPromo')}
		</Button>
	);
};

export default withStyles(styles, { withTheme: true })(SearchButton);
