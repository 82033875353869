import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const DocNumberComp = ({ documentNumber }) => {
	return <StyledTableCell>{documentNumber}</StyledTableCell>;
};

const DocNum = memo(
	({ item }) => <DocNumberComp documentNumber={item.documentNumber} />,
	(p, n) => p.item.documentNumber === n.item.documentNumber
);

export default DocNum;
