import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItem, Typography, Checkbox, ListItemText } from '@mui/material';
import utils from 'components/utils';

const SelectUsers = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const caUsers = useStoreState(state => state.cart.evasion.caUsers);
	const listCA = useStoreState(state => state.ca.list.items);
	const getCAUsers = useStoreActions(dispatch => dispatch.cart.evasion.getCAUsers);
	const selectCA = useStoreActions(dispatch => dispatch.cart.evasion.selectCA);

	useEffect(
		_ => {
			if (listCA.length > 0) getCAUsers(listCA.find(i => i.selected).id);
		},
		[listCA]
	);

	return (
		<List sx={{ maxWidth: '100%' }}>
			{caUsers.map((item, index) => (
				<ListItem key={index}>
					<ListItemButton onClick={_ => selectCA(item.id)}>
						<Checkbox checked={item.selected} inputProps={{ 'aria-labelledby': item.id }} />
					</ListItemButton>
					<ListItemText primary={<Typography variant="text">{item.name}</Typography>} />
				</ListItem>
			))}
		</List>
	);
};

export default SelectUsers;
