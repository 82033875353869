import React from 'react';
import Sortable from './Sortable';
import Empty from './Empty';
import Selection from './Selection';

const allCols = [
	{
		id: 'selection',
		comp: _ => <Selection key="headCell_selection_coupon" />
	},
	{
		id: 'id',
		comp: _ => <Sortable id="id" key="headCell_id_coupon" label="coupons.tables.id" />
	},
	{
		id: 'name',
		comp: _ => <Sortable id="nameOrder" key="headCell_sapName_coupon" label="coupons.tables.name" />
	},
	{
		id: 'status',
		comp: _ => <Sortable id="status" key="headCell_status_coupon" label="coupons.tables.status" />
	},
	{
		id: 'startDate',
		comp: _ => <Sortable id="startDate" key="headCell_startDate_coupon" label="coupons.tables.startDate" />
	},
	{
		id: 'endDate',
		comp: _ => <Sortable id="endDate" key="headCell_endDate_coupon" label="coupons.tables.endDate" />
	},
	{
		id: 'attachment',
		comp: _ => <Sortable id="attachment" key="headCell_attachment_coupon" label="coupons.tables.attachment" />
	},
	{
		id: 'edit',
		comp: _ => <Empty id="edit" key="headCell_edit_coupon" label="coupons.tables.edit" />
	}
];

export default allCols;
