import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DropzoneArea } from 'react-mui-dropzone';
import utils from 'components/utils';

const styles = theme => ({
	modalLabels: {
		marginBottom: theme.spacing()
	}
});

const FileUploader = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const setBase = useStoreActions(dispatch => dispatch.coupons.importt.setBase);
	const setFileName = useStoreActions(dispatch => dispatch.coupons.importt.setFileName);
	const fileName = useStoreState(state => state.coupons.importt.fileName);
	const getFileLimitExceedMessage = filesLimit =>
		translate('upload.fileLimitExceedMessage').replace('_NUM_', filesLimit);
	const getFileAddedMessage = fileName => translate('upload.fileAddedMessage').replace('_NAME_', fileName);
	const getFileRemovedMessage = fileName => {
		setFileName('');
		translate('upload.fileRemovedMessage').replace('_NAME_', fileName);
	};
	const getDropRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) =>
		translate('upload.dropRejectMessage').replace('_NAME_', rejectedFile.name);
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography className={classes.modalLabels} variant="h5">
					{translate('upload.selectFile')}
				</Typography>
			</Grid>
			{fileName !== '' && (
				<Grid item xs={12}>
					<Typography className={classes.modalLabels} variant="body1">
						<strong>{translate('upload.fileAddedMessageSubtitle')}:</strong> {fileName}
					</Typography>
				</Grid>
			)}
			<Grid item xs={12}>
				<DropzoneArea
					acceptedFiles={['.pdf']}
					showAlerts={['error']}
					filesLimit={1}
					maxFileSize={3000000}
					dropzoneText=""
					getFileLimitExceedMessage={getFileLimitExceedMessage}
					getFileAddedMessage={getFileAddedMessage}
					getFileRemovedMessage={getFileRemovedMessage}
					getDropRejectMessage={getDropRejectMessage}
					alertSnackbarProps={{
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'top'
						},
						autoHideDuration: 2000
					}}
					previewGridProps={{
						container: { spacing: 0, justify: 'center' },
						item: { xs: 4 }
					}}
					onDrop={async ([file]) => {
						var reader = new FileReader();
						reader.onload = function(e) {
							var contents = e.target.result;
							setFileName(file.name);
							setBase(contents);
						};
						reader.readAsDataURL(file);
					}}
					inputProps={{
						'data-testid': 'dropzone.area'
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(FileUploader);
