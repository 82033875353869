import React, { Fragment, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { TextField, Box, CircularProgress, Autocomplete } from '@mui/material';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import styles from './styles';

const SearchBar = props => {
	const { classes, path, isSearchFromHome } = props;
	const translate = utils.useI18N();
	const testId = `${path}.field`;
	const loading = useStoreState(state => state.catalog.autocomplete.loading);
	const open = useStoreState(state => state.catalog.autocomplete.open);
	const token = useStoreState(state => state.catalog.search.token);
	const realm = useStoreState(state => state.catalog.autocomplete.realm);
	const load = useStoreActions(dispatch => dispatch.catalog.autocomplete.load);
	const setOpen = useStoreActions(dispatch => dispatch.catalog.autocomplete.setOpen);
	const list = useStoreState(state => state.catalog.autocomplete.list);
	const setToken = useStoreActions(dispatch => dispatch.catalog.search.setToken);
	const setRealm = useStoreActions(dispatch => dispatch.catalog.autocomplete.setRealm);
	const [mustLoadAC, setMustLoadAC] = useState(false);
	const debouncedLoad = useCallback(
		debounce(_ => {
			load();
		}, 400),
		[]
	);
	useEffect(
		_ => {
			if (realm === 'token' && list && list.length > 0) setOpen('token');
		},
		[list, setOpen, realm]
	);
	useEffect(
		_ => {
			if (mustLoadAC) {
				debouncedLoad();
			}
		},
		[token, debouncedLoad, mustLoadAC]
	);
	const onInputChange = (event, value, reason) => {
		if (realm !== 'token') setRealm('token');
		if (reason !== 'reset') {
			if (!mustLoadAC) setMustLoadAC(true);
			setToken(value);
		}
		if (value === '') setRealm('');
	};
	const onChange = (event, value, reason) => {
		setMustLoadAC(false);
		setToken(value);
		setOpen(null);
	};
	const onClose = (event, reason) => {
		if (reason === 'blur') setOpen(null);
	};
	const showLoader = loading && realm === 'token';

	return (
		<Box sx={{ width: '100%' }}>
			<Autocomplete
				freeSolo
				id="catalog-search-autocomplete"
				sx={{ width: '100%' }}
				disableClearable
				open={open === 'token'}
				inputValue={token}
				options={list}
				loading={showLoader}
				onChange={onChange}
				onInputChange={onInputChange}
				onClose={onClose}
				renderInput={params => (
					<TextField
						{...params}
						label=""
						placeholder={
							isSearchFromHome
								? translate('common.search.fromHome')
								: translate('common.search.placeholder')
						}
						// classes={{ root: classes.autocompleteInput }}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{showLoader ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</Fragment>
							),
							'data-testid': testId
						}}
					/>
				)}
				renderOption={(props, option) => (
					<Box component="li" {...props}>
						{option}
					</Box>
				)}
			/>
		</Box>
	);
};

export default withStyles(styles, { withTheme: true })(SearchBar);
