import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import styles from './styles';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import Typography from '@mui/material/Typography';
import Delete from './Delete';

const DeleteCoupons = ({ classes, close, showCloseIcon }) => {
	const deleteCoupon = useStoreActions(dispatch => dispatch.coupons.deleteCoupons.deleteCoupon);
	const modalOpen = useStoreState(state => state.coupons.deleteCoupons.modalDeleteOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.coupons.deleteCoupons.openModalDelete);
	const translate = utils.useI18N();

	const onClick = _ => {
		deleteCoupon();
	};

	return (
		<div
			className={clsx(classes.negateMargin, {
				[classes.margin]: showCloseIcon
			})}
		>
			<Modal
				className={classes.modalPosition}
				open={modalOpen}
				onClose={_ => setModalOpen(false)}
				aria-labelledby="shipping-types-modal"
				aria-describedby="shipping-types-modal"
			>
				<div className="modal-content">
					<Fragment>{modalOpen && <Delete />}</Fragment>
				</div>
			</Modal>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(DeleteCoupons);
