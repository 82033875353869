import React, { memo } from 'react';
import { useStoreState } from 'easy-peasy';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import utils from 'components/utils';

const CartComp = ({ inCart, width }) => {
	const loading = useStoreState(state => state.catalog.search.loadingGetStock);
	const translate = utils.useI18N();
	const w = {
		width,
		maxWidth: width,
		minWidth: width
	};
	return (
		<TableCell align="center" style={w}>
			{loading && <CircularProgress size={16} />}
			{!loading && (
				<Tooltip title={translate('catalog.inCart')}>
					<Typography>{inCart}</Typography>
				</Tooltip>
			)}
		</TableCell>
	);
};

const Cart = memo(
	({ item, width }) => <CartComp inCart={item.inCart} width={width} />,
	(p, n) => p.item.inCart === n.item.inCart
);

export default Cart;
