import React, { memo } from 'react';
import moment from 'moment';
import StyledTableCell from 'components/Table/StyledTableCell';

const ExpirationDateComp = ({ date }) => {
	return <StyledTableCell>{moment(date).format('L')}</StyledTableCell>;
};

const ExpirationDate = memo(
	({ item }) => <ExpirationDateComp date={item.effectExpirationDate} />,
	(p, n) => p.item.effectExpirationDate === n.item.effectExpirationDate
);

export default ExpirationDate;
