import k from 'k';
import utils from 'services/utils';

export default ({ userId, type, depots }) => {
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify(depots.map(d => d.code)),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/users/${userId}/depots?type=${type}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
