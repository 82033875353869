import k from 'k';
import keys from 'lodash/keys';
import utils from 'services/utils';

export default ({ filters, status }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = {};
	if (filters.promoId) params.sapPromoId = filters.promoId;
	if (filters.userId) params.userCode = filters.userId;
	const qParams = keys(params).length > 0 ? '&' + utils.createQueryParams(params, false) : '';
	return utils
		.fetch(
			`${k.endpoints.apis}/wishlist/conditions/count/?status=${status}&wishlistStatus=INTO_CART${qParams}`,
			requestOptions
		)
		.then(response => utils.handleResponse({ response }));
};
