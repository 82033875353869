import k from 'k';
import pick from 'lodash/pick';
import utils from 'services/utils';

export default ({ user }) => {
	const decoratedUser = !user.flagOneClick ? { ...user, flagOneClick: false } : user;
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify(
			pick(decoratedUser, ['mobile', 'phone', 'fax', 'email', 'flagOneClick', 'discountVisibility'])
		),
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/users/${user.id}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
