import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		payload.cart = getStoreState().returns.getCarts.selectedCart;
		actions.setLoading(true);
		return services.returns
			.create(payload)
			.then(data => {
				getStoreActions().success('returns.create');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('returns.create');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
