import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';
import Grid from '@mui/material/Grid';

const renderDepot = d => (
	<Typography variant="body2">
		{utils.capitalizeAll(d.description)}: {d.stock ? d.stock.toString() : 0}
	</Typography>
);

const EvadibleQtyComp = ({ stock, depots }) => {
	const mainDepot = utils.useUserData('mainDepot', null);
	const availableDepots = utils.useUserData('availableDepots', []);
	const [firstDepot, secondDepot] = utils.extractDepots({
		mainDepot,
		bookDepots: depots,
		userDepots: availableDepots
	});
	return (
		<StyledTableCell>
			{stock > 0 && (
				<Grid container style={{ flexDirection: 'column' }}>
					{firstDepot && renderDepot(firstDepot)}
					{secondDepot && renderDepot(secondDepot)}
				</Grid>
			)}
			{stock === 0 && <Typography variant="body2">Nessuna disponibilità</Typography>}
		</StyledTableCell>
	);
};

const EvadibleQty = memo(
	({ item }) => <EvadibleQtyComp depots={item.depots} stock={item.stock} />,
	(p, n) => p.item.depots === n.item.depots && p.item.stock === n.item.stock
);

export default EvadibleQty;
