import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const PublisherComp = ({ publisher }) => {
	return <StyledTableCell>{utils.capitalizeAll(publisher)}</StyledTableCell>;
};

const Publisher = memo(
	({ item }) => <PublisherComp publisher={item.publisher} />,
	(p, n) => p.item.publisher === n.item.publisher
);

export default Publisher;
