import forEach from 'lodash/forEach';
import some from 'lodash/some';
import values from 'lodash/values';

const activeFiltersMap = {
	open: {
		scope: true,
		depot: true
	},
	processing: {
		scope: false,
		depot: true
	},
	shipped: {
		scope: false,
		depot: true
	}
};

const searchToken = (item, token) =>
	(item.author && item.author.toLowerCase().includes(token)) ||
	(item.title && item.title.toLowerCase().includes(token)) ||
	(item.publisher && item.publisher.toLowerCase().includes(token)) ||
	(item.notice && item.notice.toLowerCase().includes(token)) ||
	(item.series && item.series.toLowerCase().includes(token)) ||
	(item.catalog && item.catalog.toLowerCase().includes(token)) ||
	(item.slaDesc && item.slaDesc.toLowerCase().includes(token)) ||
	(item.notes && item.notes.toLowerCase().includes(token)) ||
	(item.note && item.note.toLowerCase().includes(token));

const searchScope = (item, scopes) =>
	(scopes.waiting && item.status === 1) ||
	(scopes.pending && item.status === 2) ||
	(scopes.evadible && item.status === 3);

const applyFiltersToOrderItem = (item, filters, type) => {
	const tokenFilterActive = Boolean(filters.token);
	const activeFiltersPerType = activeFiltersMap[type];
	const scopeFilterActive = activeFiltersPerType.scope && some(values(filters.scopes).map(s => s));
	const depotFilterActive = activeFiltersPerType.depot && filters.depots.length > 0;

	// if no filter is active we pass the search
	if (!tokenFilterActive && !scopeFilterActive && !depotFilterActive) return true;

	const active = [];

	if (tokenFilterActive) active.push(searchToken(item, filters.token));
	if (scopeFilterActive) active.push(searchScope(item, filters.scopes));
	if (depotFilterActive) active.push(filters.depots.indexOf(item.depot) !== -1);
	// filters are in AND - so every active filter must pass
	return active.every(v => v);
};

const doSearch = (items, filters, type) => {
	//Recupero anche qui i filtri attivi per gestire il caso in cui il testo da filtrare è vuoto e ho carrelli vuoti
	const tokenFilterActive = Boolean(filters.token);
	const activeFiltersPerType = activeFiltersMap[type];
	const scopeFilterActive = activeFiltersPerType.scope && some(values(filters.scopes).map(s => s));
	const depotFilterActive = activeFiltersPerType.depot && filters.depots.length > 0;

	const result = [];
	forEach(items, item => {
		let atLeastOneOrderItemIsFound = false;
		const orderItems = [];
		forEach(item.orderItems, orderItem => {
			const isFound = applyFiltersToOrderItem(orderItem, filters, type);
			orderItem.visible = isFound;
			if (isFound) atLeastOneOrderItemIsFound = true;
			orderItems.push(orderItem);
		});
		//Inserito nel visible anche l'and tra i filtri attivi per gestire la visibilita dei carrelli vuoti
		item.visible = (!tokenFilterActive && !scopeFilterActive && !depotFilterActive) || atLeastOneOrderItemIsFound;
		result.push(item);
	});
	return result;
};

export const searchOpen = (items, filters) => doSearch(items, filters, 'open');

export const searchProcessing = (items, filters) => doSearch(items, filters, 'processing');

export const searchShipped = (items, filters) => doSearch(items, filters, 'shipped');

export const resetSearch = items =>
	items.map(item => ({
		...item,
		visible: true,
		orderItems: item.orderItems.map(orderItem => ({
			...orderItem,
			visible: true
		}))
	}));
