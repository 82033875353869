import React, { memo } from 'react';
import { useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

const SelectionComp = ({ docNum, active }) => {
	const toggleSelection = useStoreActions(dispatch => dispatch.documents.search.toggleSelection);

	return (
		<TableCell padding="checkbox">
			<Checkbox
				color="primary"
				onChange={_ => toggleSelection(docNum)}
				checked={active}
				inputProps={{ 'aria-labelledby': docNum }}
			/>
		</TableCell>
	);
};

const Selection = memo(
	({ item }) => <SelectionComp docNum={item.docNum} active={item.isSelected} />,
	(p, n) => p.item.docNum === n.item.docNum && p.item.isSelected === n.item.isSelected
);

export default Selection;
