import { action, thunk, thunkOn } from 'easy-peasy';
import moment from 'moment';

const load = (actions, payload, { getStoreState, injections, getStoreActions }) => {
	const { services } = injections;
	actions.setLoading(true);
	services.returns
		.list('REFUSED')
		.then(data => actions.save(data.items))
		.catch(e => {
			getStoreActions().error('returns.refused');
		})
		.finally(_ => {
			actions.setLoading(false);
		});
};

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	list: [],
	save: action((state, payload) => {
		state.list = payload.map(item => ({
			...item,
			date: moment(item.updatedDate, 'YYYYMMDDHHmmss')
		}));
	}),
	load: thunk(load),
	loadOnResponse: thunkOn(
		(actions, storeActions) => [
			storeActions.returns.respond.accept.successType,
			storeActions.returns.respond.reject.successType
		],
		async (actions, target, helpers) => {
			load(actions, null, helpers);
		}
	),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	})
};
