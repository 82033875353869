import k from 'k';
import utils from 'services/utils';

export default ({ type, reference }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = { type, reference };
	const qParams = utils.createQueryParams(params);
	// return Promise.resolve({"pdf":true,"xls":true,"formats":{"marc":[{"code":"marc21","label":"Marc 21"},{"code":"unimarc","label":"Unimarc"},{"code":"marcxml","label":"Marc XML"},{"code":"marcbig","label":"Marc Big"},{"code":"html","label":"html"}],"dublin":[{"code":"rdfcd","label":"Rdf simple Dublin"},{"code":"oaicd","label":"Oai simple Dublin"},{"code":"srwdc","label":"Srw simple Dublin"}],"mods":[{"code":"mods3","label":"Mods 3.0"},{"code":"mods3-1","label":"Mods 3.1"},{"code":"mods3-2","label":"Mods 3.2"},{"code":"mods3-3","label":"Mods 3.3"},{"code":"mods3-4","label":"Mods 3.4"},{"code":"mods3-5","label":"Mods 3.5"},{"code":"mods3-6","label":"Mods 3.6"}]}})
	return utils
		.fetch(`${k.endpoints.apis}/document/detail/export/availableFormat?${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
