export default theme => ({
	card: {
		width: 400,
		margin: 'auto',
		transition: '0.3s',
		boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
		'&:hover': {
			boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
		}
	},
	content: {
		textAlign: 'center',
		padding: theme.spacing(3)
	},
	notificationItem: {
		textAlign: 'left',
		marginBottom: theme.spacing()
	},
	divider: {
		margin: `${theme.spacing(3)}px 0`
	}
});
