const defaultCols = [
	'catalog',
	'title',
	'author',
	'editorDesc',
	'quantity',
	'grossprui',
	'discount1',
	'discount2',
	'netprui',
	'note'
];

const ribaCols = ['documentNumber', 'documentDate', 'type', 'amount', 'giveOrTake'];

const summaryInvoiceCols = ['number', 'date', 'customer', 'amountSI', 'summaryInvoice'];

const depotStatementCols = [
	'catalog',
	'title',
	'author',
	'editorDesc',
	'quantity',
	'grossprui',
	'discount1',
	'discount2',
	'netprui',
	'note'
];

const checkinCols = [
	'catalog',
	'title',
	'author',
	'editorDesc',
	'quantityEvadible',
	'quantityIndeliveryNote',
	'grossprui',
	'netprui',
	'note'
];

const checkoutCols = [
	'catalog',
	'title',
	'author',
	'editorDesc',
	'quantityToKeep',
	'quantityReceived',
	'grossprui',
	'netprui',
	'note'
];

export { defaultCols, ribaCols, summaryInvoiceCols, depotStatementCols, checkinCols, checkoutCols };
