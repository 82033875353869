import React from 'react';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Facebook, Instagram, Mail, LinkedIn } from '@mui/icons-material';

const Footer = _ => {
	return (
		<Grid container alignItems="center" sx={{ marginTop: '20px' }}>
			<Divider style={{ color: '#000', width: '100%' }} />
			<Grid item xs={6}>
				<Typography style={{ color: '#545454de', fontSize: '14px' }}>Copyright @Fastlink 2023</Typography>
			</Grid>
			<Grid item xs={6}>
				<Grid container justifyContent="flex-end">
					<Tooltip title={'Facebook'}>
						<IconButton onClick={_ => window.open('https://www.facebook.com/fastbookspa.it')}>
							<Facebook fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title={'Instagram'}>
						<IconButton onClick={_ => window.open('https://www.instagram.com/fastbook_italia/')}>
							<Instagram fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title={'Email'}>
						<IconButton onClick={_ => window.open('mailto:assistenza.clienti@fastbookspa.it')}>
							<Mail fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title={'Linkedin'}>
						<IconButton
							onClick={_ => window.open('https://www.linkedin.com/company/fastbook-mf-ingrosso/')}
						>
							<LinkedIn fontSize="small" />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Footer;
