import React, { forwardRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import { Link as RouterLink } from 'react-router-dom';

const styles = theme => ({
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		}
	}
});

const ButtonContent = forwardRef((props, ref) => <RouterLink {...props} />);

const AddPurchasingCenter = ({ classes }) => {
	const translate = utils.useI18N();
	const addOpen = useStoreState(state => state.purchasings.add.open);
	const setAddOpen = useStoreActions(dispatch => dispatch.purchasings.add.setOpen);
	const setSearchOpen = useStoreActions(dispatch => dispatch.purchasings.filters.setSearchOpen);
	const add = !addOpen ? 'add' : '';

	return (
		<Button
			className={classes.buttonsMargin}
			variant="outlined"
			component={ButtonContent}
			to={`/admin/purchasingcenters/${add}`}
			onClick={_ => {
				setSearchOpen(false);
				setAddOpen(!addOpen);
			}}
		>
			{translate('purchasings.buttons.addPurchasingCenter')}
		</Button>
	);
};

export default withStyles(styles, { withTheme: true })(AddPurchasingCenter);
