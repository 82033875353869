const getShipped = (actions, payload, { getStoreState, injections, getStoreActions }) => {
	const { services } = injections;
	actions.setLoading(true);
	const date = getStoreState().cart.shipped.selectedDate;
	const formattedDate = date.format('yyyy-MM-DD');
	services.cart
		.getShipped(formattedDate)
		.then(data => actions.save(data))
		.catch(e => {
			if (e.name !== 'AbortError') getStoreActions().error('cart.retrieve');
		})
		.finally(_ => {
			actions.setLoading(false);
		});
};

export default getShipped;
