import React, { Fragment, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import styles from './styles';
import utils from 'components/utils';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from 'components/Loader';
import Typography from '@mui/material/Typography';
import SearchBar from '../../../SearchBar';

const TableToolbar = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const clearAll = useStoreActions(dispatch => dispatch.purchasings.assignUsers.clearAll);
	const search = useStoreActions(dispatch => dispatch.purchasings.assignUsers.search);
	const reset = useStoreActions(dispatch => dispatch.purchasings.assignUsers.reset);
	const items = useStoreState(state => state.purchasings.detailsItems.data);
	const loading = useStoreState(state => state.purchasings.detailsItems.loading);
	const loadingReport = useStoreState(state => state.purchasings.report.loading);
	const token = useStoreState(state => state.purchasings.assignUsers.token);
	const setToken = useStoreActions(dispatch => dispatch.purchasings.assignUsers.setToken);
	const report = useStoreState(state => state.purchasings.report.data);
	const [createdBy, setCreatedBy] = useState('');
	const [lastDate, setLastDate] = useState('');
	const clear = _ => {
		const data = items.map(a => a.clientCode);
		const obj = {
			usersToInsert: null,
			usersToDelete: data.join(',')
		};
		clearAll(obj);
	};
	utils.useUpdatedData(setCreatedBy, setLastDate, report);
	return (
		<Fragment>
			{!loading && (
				<Fragment>
					<Grid container>
						<Grid item xs={12} md={6}>
							<SearchBar />
						</Grid>
						<Grid
							item
							container
							xs={6}
							md={3}
							sx={{ marginTop: { xs: '10px !important', md: '0 !Important', sm: '10px !important' } }}
						>
							<Grid item container md={12} className={classes.gridSpacing}>
								<Button
									className={classes.findButton}
									disableElevation
									variant="contained"
									color="primary"
									disabled={token.length < 3}
									onClick={_ => search()}
								>
									{translate('common.search.search')}
								</Button>
								<Button
									className={classes.resetButton}
									disableElevation
									variant="contained"
									color="primary"
									onClick={_ => {
										setToken('');
										reset();
									}}
								>
									{translate('common.search.reset')}
								</Button>
							</Grid>
						</Grid>
						<Grid
							item
							xs={6}
							md={3}
							className={classes.gridSpacing}
							sx={{ marginTop: { xs: '10px !important', md: '0 !Important', sm: '10px !important' } }}
						>
							<Button
								color="primary"
								variant="contained"
								onClick={() => clear()}
								style={{ float: 'right', marginBottom: '1%' }}
								startIcon={<DeleteIcon />}
							>
								{translate('common.clearAll')}
							</Button>
						</Grid>
					</Grid>
					{loadingReport && <CircularProgress size={24} />}
					{!loadingReport && (
						<Typography style={{ padding: '2%' }}>
							{translate('coupons.lastUpload') + ' : ' + createdBy + ' il ' + lastDate}
						</Typography>
					)}
				</Fragment>
			)}
			{loading && (
				<Grid item container xs={12} justify="center" spacing={3} className={classes.elements}>
					<Loader />
				</Grid>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(TableToolbar);
