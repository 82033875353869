import React, { useState, Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import clone from 'lodash/clone';
import NumericStepper from 'components/NumericStepper';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import {
	Stack,
	IconButton,
	CircularProgress,
	Tooltip,
	TextField,
	FormControl,
	Typography,
	Box,
	Button,
	MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import Select from 'components/Select';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const styles = theme => ({
	custom: {
		'&:hover': {
			background: 'none'
		}
	},
	card: {
		maxWidth: 450,
		margin: 'auto',
		transition: '0.3s'
	},
	content: {
		textAlign: 'left',
		padding: theme.spacing(0),
		'&:last-child': {
			paddingBottom: 0
		},
		'& a': {
			paddingLeft: theme.spacing(1.5)
		}
	},
	modalPosition: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
});

/*
IMPORTANT!
To have an idea of what's going on there look at this document
https://docs.google.com/spreadsheets/d/11a1fTSEdOw_lgReRRSLkBJpUyaT_97XbiaRb8sHHOoM
*/
const getScenario = (item, paymentTypeId, availableDepots) => {
	const depotIsFasthub = item.depotCode === 'FS01' ? 1 : 0;
	const money = paymentTypeId === 1 ? 2 : 0;
	const onlyFastHub = availableDepots.length === 1 && availableDepots[0].id === 'FS01' ? 4 : 0;
	const itemHasDepot = item.depots.find(d => d.id === item.depotCode) ? 8 : 0;
	return depotIsFasthub + money + onlyFastHub + itemHasDepot;
};

const Edit = props => {
	const { itemWishlist, type } = props;
	const translate = utils.useI18N();
	const theme = useTheme();
	const paymentTypeId = utils.useUserData('paymentTypeId', [], false);
	const availableDepots = utils.useUserData('availableDepots', []);
	const loading = useStoreState(state => state.wishlist.update.loading);
	const save = useStoreActions(dispatch => dispatch.wishlist.update.updateItem);
	const [localItem, setLocalItem] = useState(clone(itemWishlist));
	const selectedDepot = localItem.depots.find(d => d.id === localItem.depotCode);
	const [warning1000, setWarning1000] = useState(type === 200 ? localItem.quantity >= 20 : false);
	const data = useStoreState(state => state.wishlist.details.data);
	const items = useStoreState(state => state.wishlist.list.items);

	const currentWL = items.find(item => item.id === parseInt(Object.getOwnPropertyNames(data)));
	const disableButton =
		currentWL !== undefined &&
		((currentWL.type === null && (currentWL.status === 'APPROVED' || currentWL.status === 'DRAFT_AGENT')) ||
			currentWL.type === 'BOOK2C')
			? true
			: false;

	const onStepperChange = valueAsNumber => {
		setLocalItem({
			...localItem,
			quantity: valueAsNumber
		});
		if (!selectedDepot.fes) {
			setWarning1000(valueAsNumber === 999);
			if (type === 200) setWarning1000(valueAsNumber === 20);
		}
	};
	const getMaxValue = _ => {
		if (type === 200) return 20;
		if (!selectedDepot || !selectedDepot.fes) return 999;

		return selectedDepot.stock;
	};
	const onNoteChange = event => {
		setLocalItem({
			...localItem,
			notes: event.target.value
		});
	};
	const onDepotSelect = event => {
		const depotCode = event.target.value;
		const selected = depotList.find(d => d.id === depotCode);
		if (!selected) return;
		setLocalItem({
			...localItem,
			depotCode,
			depotDescription: selected.description
		});
	};
	const onSave = event => {
		save({
			wishlistItemId: localItem.id,
			quantity: localItem.quantity,
			depotCode: localItem.depotCode,
			depotDescription: localItem.depotDescription,
			notes: localItem.notes
		});
	};
	const fes = selectedDepot ? selectedDepot.fes : false;
	const scenario = getScenario(itemWishlist, paymentTypeId, availableDepots);
	if ([6, 7, 14, 15].includes(scenario) || (fes === null && itemWishlist.stock === null)) {
		return (
			<StyledTableCell>
				<Tooltip title="Non puoi modificare le quantità di questo prodotto">
					<IconButton aria-label="warning wishlist" style={{ padding: 0 }}>
						<EditIcon fontSize="small" disabled={true} />
					</IconButton>
				</Tooltip>
			</StyledTableCell>
		);
	}
	let depotList = localItem.depots;
	if ([10, 3, 11, 1, 5].includes(scenario)) depotList = depotList.filter(d => d.id !== 'FS01');
	if ([0, 4].includes(scenario)) depotList = depotList.filter(d => d.id !== itemWishlist.depotCode);
	if (scenario === 2) depotList = depotList.filter(d => d.id !== itemWishlist.depotCode && d.id !== 'FS01');
	const depotMenuValue = depotList.find(d => d.id === localItem.depotCode)
		? localItem.depotCode
		: depotList.length > 0
		? depotList[0].id
		: '';
	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			<Stack
				direction={{ xs: 'column', md: 'row', sm: 'row' }}
				justifyContent="flex-start"
				alignItems={{ xs: 'flex-start', md: 'center', sm: 'center' }}
				spacing={1}
				sx={{ width: '100%' }}
			>
				<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
					<Typography variant="text">{translate('common.quantity')}</Typography>
					<Box sx={{ maxWidth: '90px' }}>
						<NumericStepper
							removeDot
							precision={0}
							min={1}
							strict
							max={getMaxValue}
							step={1}
							value={localItem.quantity}
							onChange={onStepperChange}
						/>
					</Box>
				</Stack>
				{warning1000 && (
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={1}
						sx={{ width: '100%' }}
					>
						<WarningIcon fontSize="small" sx={{ color: theme.palette.custom.orange }} />
						<Typography variant="text" sx={{ color: theme.palette.secondary.main }}>
							{translate('catalog.selection.orderableLimit') + getMaxValue()}
						</Typography>
					</Stack>
				)}
			</Stack>
			{selectedDepot && selectedDepot.fes && (
				<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
					<WarningIcon fontSize="small" sx={{ color: theme.palette.custom.orange }} />
					<Typography variant="text" sx={{ color: theme.palette.secondary.main }}>
						{translate('catalog.selection.warningFES')}
					</Typography>
				</Stack>
			)}

			<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
				<Typography variant="text">Deposito</Typography>
				{itemWishlist.type === 100 && <Typography variant="text">Fast Hub</Typography>}
				{itemWishlist.type !== 100 && (
					<FormControl variant="outlined">
						<Select value={depotMenuValue} onChange={onDepotSelect}>
							{depotList.map((item, index) => (
								<MenuItem key={item.id} value={item.id}>
									{item.description}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
			</Stack>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{ width: '100%' }}
			>
				<TextField
					multiline
					maxRows={5}
					rows={3}
					sx={{ width: '70%', resize: 'none' }}
					aria-label="warning message"
					placeholder="note"
					value={localItem.notes}
					onChange={onNoteChange}
				/>
				{loading && <CircularProgress size={14} style={{ marginLeft: 90, marginBottom: 30 }} />}
				{!loading && (
					<Button onClick={onSave} variant="dark" type="submit" disabled={disableButton}>
						{translate('common.save')}
					</Button>
				)}
			</Stack>
		</Stack>
	);
};

export default Edit;
