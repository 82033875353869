import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Stack, Paper, Box, Button, Typography, Popover, LinearProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Pending from './components/Pending';
import Accepted from './components/Accepted';
import Refused from './components/Refused';
import TabBar from './components/TabBar';
import Filters from './components/Filters';
import styles from './styles';
import utils from 'components/utils';

const ConditionRequest = ({ classes }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const tabSelected = useStoreState(state => state.conditionRequest.tabSelected);
	const setTabSelected = useStoreActions(dispatch => dispatch.conditionRequest.setTabSelected);
	const filtersOpen = useStoreState(state => state.conditionRequest.filters.open);
	const setOpenFilters = useStoreActions(dispatch => dispatch.conditionRequest.filters.setOpen);
	const [anchorEl, setAnchorEl] = useState(null);
	const loadingPending = useStoreState(state => state.conditionRequest.pendingList.loading);
	const loadingAccepted = useStoreState(state => state.conditionRequest.acceptedList.loading);
	const loadingRefused = useStoreState(state => state.conditionRequest.refusedList.loading);
	const loading = loadingAccepted || loadingPending || loadingRefused;
	const open = Boolean(anchorEl);
	const onChange = (event, newValue) => {
		setTabSelected(newValue);
	};

	const toggleFilters = _ => {
		setOpenFilters(!filtersOpen);
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = event => {
		setAnchorEl(null);
	};

	const leftGridMdWidth = filtersOpen ? 7 : 12;

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				<Stack
					direction={{ xs: 'row', md: 'row', sm: 'row' }}
					justifyContent="flex-stat"
					alignItems="flex-start"
					spacing={1}
					variant="wrapperColor"
					sx={{ width: '100%' }}
				>
					<Box sx={{ width: { xs: '70%', md: '90%', sm: '80%' } }}>
						<Typography variant="titlePage">
							{translate('titles.wishlist') + ' / ' + translate('titles.condition-request')}
						</Typography>
					</Box>
					<Box sx={{ width: { xs: '30%', md: '10%', sm: '20%' } }}>
						<Button variant="light" endIcon={<SearchIcon />} onClick={handleClick}>
							{translate('common.search.search')}
						</Button>
					</Box>
				</Stack>
				<TabBar />
				{loading && <LinearProgress />}
			</Paper>
			{tabSelected === 0 && <Pending />}
			{tabSelected === 1 && <Accepted />}
			{tabSelected === 2 && <Refused />}

			{/* Popover ricerca */}
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
			>
				<Filters onClose={handleClose} />
			</Popover>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(ConditionRequest);
