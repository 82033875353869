import React, { Fragment, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import utils from 'components/utils';
import styles from './styles';

const PromotionSelector = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const theme = useTheme();
	const promotions = useStoreState(state => state.catalog.promotions.list);
	const loadingPromotions = useStoreState(state => state.catalog.promotions.loading);
	const getPromotionList = useStoreActions(dispatch => dispatch.catalog.promotions.retrieve);
	const selectPromotion = useStoreActions(dispatch => dispatch.catalog.promotions.select);
	const [promotionSelected, setPromotionSelected] = useState('');
	useEffect(getPromotionList, [getPromotionList]);
	useEffect(
		_ => {
			if (promotions.length === 0) return;
			const selected = promotions.find(s => s.selected);
			setPromotionSelected(selected ? selected.sapPromoId : '');
		},
		[promotions]
	);

	return (
		<Fragment>
			{!loadingPromotions && promotions.length > 0 && (
				<FormControl sx={{ width: '100%' }}>
					<Select
						value={promotionSelected}
						onChange={event => selectPromotion(event.target.value)}
						displayEmpty
						sx={{
							fontFamily: ['"Poppins", sans-serif'].join(','),
							fontSize: '14px',
							fontWeight: '400',
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: promotionSelected ? theme.palette.primary.main : '#cccdce'
						}}
					>
						<MenuItem key={`filter_promotion_empty`} value="">
							{translate('catalog.promotionsDefault')}
						</MenuItem>
						{promotions.map((item, index) => (
							<MenuItem key={`filter_promotion_${item.sapPromoId}`} value={item.sapPromoId}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</Fragment>
	);
};

export default PromotionSelector;
