export default theme => ({
	mainImage: {
		backgroundImage: `url(${process.env.PUBLIC_URL}/home-md.jpg)`,
		height: 500,
		backgroundPosition: 'bottom',
		backgroundSize: 'cover',
		position: 'relative',
		margin: theme.spacing(),
		[theme.breakpoints.up('xs')]: {
			backgroundImage: `url(${process.env.PUBLIC_URL}/home-xs.jpg)`,
			height: 280
		},
		[theme.breakpoints.up('sm')]: {
			backgroundImage: `url(${process.env.PUBLIC_URL}/home-sm.jpg)`,
			height: 350
		},
		[theme.breakpoints.up('md')]: {
			backgroundImage: `url(${process.env.PUBLIC_URL}/home-md.jpg)`,
			height: 500
		}
	},
	counter: {
		position: 'absolute',
		right: 8,
		bottom: 8,
		color: '#fff',
		width: 240
	},
	centerBox: {
		textAlign: 'center'
	},
	heightBox: {
		height: '100%'
	},
	linkStyle: {
		textDecoration: 'none',
		color: 'rgba(0, 0, 0, 0.87)'
	},
	borderBox: {
		borderRadius: '4px'
	},
	fontSize: {
		fontSize: 40,
		'@media (min-width:600px)': {
			fontSize: 80
		}
	},
	h1Font: {
		color: '#FFFFFF',
		fontSize: '4rem',
		fontWeight: 'bold',
		[theme.breakpoints.up('xs')]: {
			fontSize: '2rem'
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: '3rem'
		},
		[theme.breakpoints.up('md')]: {
			fontSize: '4rem'
		}
	},
	h2Font: {
		color: '#FFFFFF',
		fontSize: '4rem',
		[theme.breakpoints.up('xs')]: {
			fontSize: '1rem'
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: '2rem'
		},
		[theme.breakpoints.up('md')]: {
			fontSize: '3rem'
		}
	},
	singlePromotion: {
		height: 330,
		width: 220,
		cursor: 'pointer'
	},
	promotionTitle: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center'
		}
	},
	promotionsContainer: {
		maxWidth: 950,
		marginBottom: 16
	}
});
