import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const WeightComp = ({ weight }) => {
	return <StyledTableCell>{`${weight} Kg`}</StyledTableCell>;
};

const Weight = memo(
	({ item }) => <WeightComp weight={item.weight} />,
	(p, n) => p.item.weight === n.item.weight
);

export default Weight;
