import React, { useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import {
	Stack,
	Box,
	Backdrop,
	Fab,
	Typography,
	Button,
	Popover,
	Paper,
	List,
	ListItemIcon,
	ListItemButton,
	ListItemText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import utils from 'components/utils';
import WishlistCreate from 'components/WishlistCreate';

const HeaderPage = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const openCreate = useStoreState(state => state.wishlist.create.open);
	const setOpenCreate = useStoreActions(dispatch => dispatch.wishlist.create.setOpen);
	const availableDepots = utils.useFilteredDepots();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [anchorElCreate, setAnchorElCreate] = useState(null);
	const [name, setName] = useState('');
	const [notes, setNotes] = useState('');

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickCreate = event => {
		setAnchorElCreate(event.currentTarget);
		setOpenCreate(true);
	};

	const handleCloseCreate = event => {
		setAnchorElCreate(null);
		setOpenCreate(false);
	};

	const buttonList = [
		{
			label: translate('wishlist.import'),
			icon: <GetAppIcon />,
			condiction: availableDepots.length > 0,
			href: '/import',
			onclick: null,
			type: 'light'
		},
		{
			label: translate('wishlist.createWl'),
			icon: <AddIcon sx={{ color: theme.palette.custom.white }} />,
			condiction: iCan('OPERATE_ON_CARTS'),
			href: null,
			onclick: handleClickCreate,
			type: 'dark'
		}
	];

	return (
		<Fragment>
			<Stack
				direction={{ xs: 'row', md: 'row', sm: 'row' }}
				justifyContent="flex-stat"
				alignItems="flex-start"
				spacing={1}
				variant="wrapperColor"
			>
				<Typography variant="titlePage">{translate('titles.wishlist')}</Typography>
				{/* Pulsanti su Desktop */}
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					spacing={1}
					sx={{ display: { xs: 'none', md: 'inherit', sm: 'none' }, width: '100%' }}
				>
					{buttonList.map(btn => (
						<Button
							key={btn.label}
							variant={btn.type}
							href={btn.href}
							onClick={btn.onclick}
							endIcon={btn.icon}
							sx={{ display: btn.condiction ? 'inherit' : 'none' }}
						>
							{btn.label}
						</Button>
					))}
				</Stack>
				{/* Pulsanti su Mobile */}
				<Box
					sx={{
						display: { xs: 'inherit', md: 'none', sm: 'inherit' },
						position: 'absolute',
						top: '10px',
						right: '10px'
					}}
				>
					<Backdrop open={open} />
					<Fab
						aria-label="Mobile function"
						onClick={handleClick}
						sx={{
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.custom.white,
							'&:hover': {
								backgroundColor: theme.palette.custom.hover
							}
						}}
						size="small"
					>
						<AddIcon />
					</Fab>
					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<Paper elevation={24}>
							<List sx={{ padding: '0' }}>
								{buttonList.map(btn => (
									<ListItemButton
										key={btn.label}
										variant="mobileList"
										href={btn.href}
										onClick={btn.onclick}
										sx={{
											display: btn.condiction ? 'flex' : 'none',
											backgroundColor:
												btn.type === 'dark'
													? theme.palette.primary.main
													: theme.palette.custom.white,
											'&:hover': {
												backgroundColor:
													btn.type === 'dark'
														? theme.palette.primary.main
														: theme.palette.custom.white
											}
										}}
									>
										<ListItemIcon>{btn.icon}</ListItemIcon>
										<ListItemText
											primary={btn.label}
											sx={{ color: btn.type == 'dark' ? theme.palette.custom.white : 'inherit' }}
										/>
									</ListItemButton>
								))}
							</List>
						</Paper>
					</Popover>
				</Box>
			</Stack>

			{/* Popover Create */}
			<Popover
				open={openCreate}
				anchorEl={anchorElCreate}
				onClose={handleCloseCreate}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<WishlistCreate
					onClose={handleCloseCreate}
					onNoteChange={e => {
						setNotes(e.target.value);
					}}
					defaultNotesValue={notes}
					defaultNameValue={name}
					onNameChange={e => {
						setName(e.target.value);
					}}
				/>
			</Popover>
		</Fragment>
	);
};

export default HeaderPage;
