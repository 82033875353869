import hexToRgbString from './hex-to-rgb-string';
import mockAction from './mock-action';
import goToLogin from './go-to-login';
import logout from './logout';
import pkce from './pkce';

export default {
	colors: {
		hexToRgbString
	},
	mockAction,
	goToLogin,
	logout,
	pkce
};
