import k from 'k';
import utils from 'services/utils';

export default data => {
	const requestOptions = {
		method: 'POST',
		headers: utils.createHeaders(),
		body: JSON.stringify(data)
	};
	return utils
		.fetch(`${k.endpoints.apis}/notification/serviceCommunication/send`, requestOptions)
		.then(response => utils.handleResponse({ response }))
		.catch(error => error.response.json());
};
