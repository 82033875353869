import k from 'k';

export default _ => {
	const codeChallenge = window.localStorage.getItem('codeChallenge');
	const loginUrl = `${k.endpoints.login}?
						client_id=fastbook&
						redirect_uri=${k.redirectURLS.login}&
						response_type=code&
						code_challenge=${codeChallenge}&
						code_challenge_method=S256&
						scope=openid%20profile%20authorization_api_scope%20offline_access`;
	window.location.href = loginUrl;
};
