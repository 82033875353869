import React from 'react';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import styles from './styles';
import clsx from 'clsx';
import { useStoreState } from 'easy-peasy';
import Create from './components/Create';
import ImportButton from './components/ImportButton';

const ActionButtons = ({ classes, toggleCreate, isSticky }) => {
	const menuOpen = useStoreState(state => state.menu.mainOpen);
	const availableDepots = utils.useFilteredDepots();
	return (
		<Grid
			container
			item
			xs={12}
			className={clsx(classes.notSticky, {
				[classes.stickyMenuClose]: isSticky && !menuOpen,
				[classes.stickyMenuOpen]: isSticky && menuOpen
			})}
		>
			<Create toggleCreate={toggleCreate} />
			{availableDepots.length > 0 && <ImportButton toggleCreate={toggleCreate} />}
		</Grid>
	);
};

const StickyButtons = utils.withSticky(ActionButtons, 90);

export default withStyles(styles, { withTheme: true })(StickyButtons);
