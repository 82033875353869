import React from 'react';
import { Stack, Typography } from '@mui/material';

const CardRow = props => {
	const { label, value } = props;

	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} sx={{ margin: '5px 0' }}>
			<Typography variant="text" sx={{ fontWeight: '600' }}>
				{label + ':'}
			</Typography>
			<Typography variant="text">{value === 'null' ? '' : value}</Typography>
		</Stack>
	);
};

export default CardRow;
