import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import utils from 'components/utils';

const Sortable = ({ id, label, additionalStyles }) => {
	const order = useStoreState(state => state.purchasings.assignUsers.orderDirection);
	const orderBy = useStoreState(state => state.purchasings.assignUsers.orderBy);
	const setOrder = useStoreActions(dispatch => dispatch.purchasings.assignUsers.setOrder);
	const translate = utils.useI18N();
	const defaultStyles = {
		paddingLeft: 4,
		paddingRight: 4
	};
	return (
		<TableCell
			align="left"
			style={{ ...defaultStyles, ...additionalStyles }}
			sortDirection={orderBy === id ? order : false}
		>
			<TableSortLabel
				active={orderBy === id}
				direction={orderBy === id ? order : 'asc'}
				onClick={_ => setOrder(id)}
			>
				{translate(label)}
			</TableSortLabel>
		</TableCell>
	);
};

export default Sortable;
