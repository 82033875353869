import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import moment from 'moment';

const DocumentDateComp = ({ date }) => {
	return <StyledTableCell>{moment(date).format('L')}</StyledTableCell>;
};

const DocDate = memo(
	({ item }) => <DocumentDateComp date={item.documentDate} />,
	(p, n) => p.item.documentDate === n.item.documentDate
);

export default DocDate;
