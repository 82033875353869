import React, { Fragment, useEffect, useRef } from 'react';
import { useStoreActions } from 'easy-peasy';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Link } from 'react-router-dom';
import { Stack, Typography, IconButton, Button, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import FilterUI from './components/FilterUI';
import utils from 'components/utils';
import styles from './styles';

const Filters = ({ classes, variant, closeAndSearch, close, showCloseIcon }) => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const setStep = useStoreActions(dispatch => dispatch.catalog.search.SEARCH_setPaginationStep);
	const search = _ => {
		closeAndSearch();
	};

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={2}
			sx={{ width: '100%' }}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
				sx={{
					width: '100%',
					borderWidth: showCloseIcon ? '0 0 1px 0' : '0',
					borderStyle: 'solid',
					borderColor: theme.palette.primary.main
				}}
			>
				<Box sx={{ width: '100%' }}>
					<Typography variant="titleSection">{translate('common.filter')}</Typography>
				</Box>
				{showCloseIcon && (
					<Box>
						<IconButton onClick={close}>
							<CloseIcon />
						</IconButton>
					</Box>
				)}
			</Stack>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<FilterUI variant={variant} />
			</LocalizationProvider>
			<Stack
				direction="row"
				justifyContent="space-around"
				alignItems="center"
				spacing={2}
				sx={{ width: '100%', padding: '10px' }}
			>
				<Button
					variant="dark"
					onClick={_ => {
						setStep(25);
						if (showCloseIcon) close();
					}}
					component={Link}
					to={'/catalog/search'}
					endIcon={<SearchIcon />}
				>
					{translate('common.search.search')}
				</Button>
				{showCloseIcon && (
					<Button variant="light" onClick={close} endIcon={<CloseIcon />}>
						{translate('common.close')}
					</Button>
				)}
			</Stack>
		</Stack>
	);
};

export default withStyles(styles, { withTheme: true })(Filters);
