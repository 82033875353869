import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { Typography, TextField, CircularProgress, Divider, Box, Stack, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import debounce from 'lodash/debounce';
import serviceUtils from 'services/utils';
import utils from 'components/utils';

const Personification = props => {
	const { setAnchorEl, anchorEl } = props;
	const theme = useTheme();
	const history = useHistory();
	const translate = utils.useI18N();
	const setToken = useStoreActions(dispatch => dispatch.me.personify.setAutocompleteToken);
	const listUsers = useStoreState(state => state.me.personify.autocompleteList);
	const loadUsers = useStoreActions(dispatch => dispatch.me.personify.loadUsers);
	const loading = useStoreState(state => state.me.personify.autocompleteLoading);
	const resetAutocomplete = useStoreActions(dispatch => dispatch.me.personify.resetAutocomplete);
	const doPersonification = useStoreActions(dispatch => dispatch.me.personify.sendRequest);
	const stopPersonification = useStoreActions(dispatch => dispatch.me.personify.stop);
	const resetWishlist = useStoreActions(dispatch => dispatch.wishlist.listLight.resetList);
	const resetCart = useStoreActions(dispatch => dispatch.cart.listLight.resetList);
	const resetCartFilter = useStoreActions(dispatch => dispatch.cart.search.resetSearch);
	const setLoading = useStoreActions(dispatch => dispatch.me.personify.setLoading);
	const setWaitImpersonification = useStoreActions(dispatch => dispatch.me.personify.setWaitImpersonification);
	const width = 300;
	const getColor = id => ['yellow', 'green', 'red', 'gray'][id - 1];
	const [, personifiedUser] = utils.usePersonification();
	const [iAmPersonifying] = utils.usePersonification();
	const [target, setTarget] = useState({});
	const { unsubscribe } = serviceUtils.abort;

	/**
	 * Il debounce serve per ritardare la chiamata al loaduser,
	 * in modo da non fare una chiamata per ogni lettera quando l'utente scrive,
	 * ma invece si attende un secondo e si chiama la funzione con il token più completo
	 * */
	const debouncedLoadUsers = useCallback(
		debounce(_ => {
			/**
			 * unsubscribe serve per interrompere la chiamata precedente.
			 * Serve nel caso sia partita una chiamata, ma l'utente sta continuando a scrivere.
			 * Così parte solo la chiamata con il token completo
			 */
			unsubscribe(['personify-search']);
			loadUsers();
		}, 1000),
		[]
	);

	const onInputChange = (event, newInputValue) => {
		if (!newInputValue || newInputValue.trim() === '') return;
		setToken(newInputValue);
		if (newInputValue.trim().length > 3 && event.type == 'change') debouncedLoadUsers();
		if (newInputValue.trim().length <= 3) resetAutocomplete();
	};

	const onChange = (event, newValue) => {
		if (!newValue) {
			resetAutocomplete();
			return;
		}
		setTarget(newValue);
	};

	const onClickStopPersonification = _ => {
		stopPersonification();
		resetAutocomplete();
		resetWishlist();
		resetCart();
		resetCartFilter();
		history.push('/');
		setTarget({});
		setAnchorEl(null);
		//Dopo 3 secondi richiamo la funzione di completamento operazioni (per evitare il refresh da parte dell'utente)
		setTimeout(completeImpersonification, 3000);
	};

	const onClickDoPersonification = _ => {
		resetCartFilter(); //Resetto l'eventuale ricerca sugli ordini
		doPersonification({ code: target.usercode, history, funclose: closePersonification });
		history.push('/');
		setAnchorEl(null);
	};

	const closePersonification = _ => {
		//Dopo 3 secondi richiamo la funzione di completamento operazioni (per evitare il refresh da parte dell'utente)
		setTimeout(completeImpersonification, 3000);
	};

	const completeImpersonification = _ => {
		//Imposto i parametri di attesa completamento impersonificazione a false per chiudere il backdrop che c'è nell'header
		setLoading(false);
		setWaitImpersonification(false);
	};

	return (
		<Box
			sx={{
				width: {
					xs: `${width}px`,
					sm: `${width * (4 / 3)}px`,
					md: `${width * (5 / 3)}px`,
					lg: `${width * 2}px`
				}
			}}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={3}
				sx={{ width: '100%', height: '100%', padding: '22px' }}
				divider={<Divider variant="middle" sx={{ color: 'black', width: '100%' }} />}
			>
				<Typography variant="overline" color="primary" textAlign="center" fontSize="15px">
					{translate('personify.personify')}
				</Typography>

				{iAmPersonifying && (
					<Fragment>
						<Stack direction="row" justifyContent="space-between" alignItems="center">
							<Typography>
								{personifiedUser.id} ({personifiedUser.displayName})
							</Typography>
							<Button onClick={onClickStopPersonification}>stop</Button>
						</Stack>
					</Fragment>
				)}
				<Fragment>
					<Autocomplete
						freeSolo
						autoComplete
						id="personify-search"
						sx={{ width: '100%' }}
						options={listUsers}
						loadingText="Caricamento"
						loading={loading}
						getOptionLabel={option =>
							`${option.usercode} - ${option.username} - ${option.address} - ${option.city}`
						}
						onInputChange={(event, newInputValue) => onInputChange(event, newInputValue)}
						onChange={(event, newValue) => onChange(event, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<Fragment>
											{loading ? (
												<CircularProgress
													sx={{ color: theme.palette.primary.main }}
													size={20}
												/>
											) : null}
											{params.InputProps.endAdornment}
										</Fragment>
									)
								}}
								label={translate('titles.search-user')}
							/>
						)}
						filterOptions={options => options}
						renderOption={(props, option) => (
							<Box component="li" {...props}>
								<FiberManualRecordIcon
									style={{ color: getColor(option.abilitationCode), height: '0.6em' }}
								/>
								{option.usercode} - {option.username} - {option.address} - {option.city} -{' '}
								{option.province}
							</Box>
						)}
					/>
					{!utils.isObjectEmpty(target) && (
						<Stack direction="column" alignItems="center" justifyContent="flex-start">
							<Typography>
								<strong>{translate('personify.usercode')}</strong> {target.usercode}{' '}
							</Typography>
							<Typography>
								<strong>{translate('user.companyName')}</strong> {target.username}{' '}
							</Typography>
							<Typography>
								<strong>{translate('personify.address')}</strong> {target.address} ({target.province}){' '}
							</Typography>
							<Button onClick={onClickDoPersonification}>{translate('common.confirm')}</Button>
						</Stack>
					)}
				</Fragment>
			</Stack>
		</Box>
	);
};

export default Personification;
