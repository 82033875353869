import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { ToggleButtonGroup, ToggleButton, Stack, Typography, Box } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import AdjustIcon from '@mui/icons-material/Adjust';
import utils from 'components/utils';
import FilterBarSapInfo from './FilterBarSapInfo';

const TabBar = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const tabSelected = useStoreState(state => state.cart.tabSelected);
	const setTabSelected = useStoreActions(dispatch => dispatch.cart.setTabSelected);
	const loadingOpen = useStoreState(state => state.cart.open.loading);
	const openCount = useStoreState(state => state.cart.open.count);
	const openTotal = useStoreState(state => state.cart.open.total);
	const loadingProcessing = useStoreState(state => state.cart.processing.loading);
	const processingCount = useStoreState(state => state.cart.processing.count);
	const processingTotal = useStoreState(state => state.cart.processing.total);
	const loadingShipped = useStoreState(state => state.cart.shipped.loading);
	const shippedCount = useStoreState(state => state.cart.shipped.count);
	const shippedTotal = useStoreState(state => state.cart.shipped.total);
	const listCA = useStoreState(state => state.ca.list.items);
	const openLabel =
		iCan('OPERATE_ON_MULTIPLE_CA') && listCA.length > 0
			? translate('orders.orderOpen') - listCA.find(i => i.selected).name
			: translate('orders.orderOpen');
	const colorSelected = theme.palette.primary.main;
	const colorNotSelected = theme.palette.custom.greyborder;
	const handleTab = (event, tab) => {
		setTabSelected(tab);
	};

	const listTab = [
		{
			tab: 0,
			label: openLabel,
			count: openCount + ' | ' + utils.formatPrice(openTotal),
			loading: loadingOpen
		},
		{
			tab: 1,
			label: translate('orders.orderProcessing'),
			count: processingCount + ' | ' + utils.formatPrice(processingTotal),
			loading: loadingProcessing
		},
		{
			tab: 2,
			label: translate('orders.orderShipped'),
			count: shippedCount + ' | ' + utils.formatPrice(shippedTotal),
			loading: loadingShipped
		}
	];

	useEffect(() => {
		//Scroll top -> rimosso per evitare lo scroll se si cancella un titolo da un ordine
		//	window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [openCount, processingCount, shippedCount]);

	return (
		<Stack
			direction={{ xs: 'column', md: 'row', sm: 'row' }}
			justifyContent="flex-stat"
			alignItems="center"
			spacing={1}
			variant="wrapperColor"
			sx={{ padding: { xs: '10px 0 !important', md: '10px 20px !important', sm: '10px !important' } }}
		>
			<Box sx={{ width: { xs: '100%', md: '80%', sm: '100%' } }}>
				<ToggleButtonGroup value={tabSelected} exclusive onChange={handleTab}>
					{listTab.map(item => (
						<ToggleButton
							key={item.tab}
							variant="tab"
							value={item.tab}
							disabled={item.loading || tabSelected == item.tab}
							sx={{ display: !iCan('VIEW_ONLY_OPEN_ORDERS') || item.tab == 0 ? 'inherit' : 'none' }}
						>
							{tabSelected == item.tab && (
								<AdjustIcon
									sx={{
										color: !item.loading ? colorSelected : colorNotSelected,
										fontSize: { xs: '20px', md: '24px', sm: '24px' }
									}}
								/>
							)}
							{tabSelected != item.tab && (
								<Brightness1Icon
									sx={{ color: colorNotSelected, fontSize: { xs: '20px', md: '24px', sm: '24px' } }}
								/>
							)}
							{/* {!item.loading && ( */}
							<Stack
								direction={{ xs: 'column', md: 'row', sm: 'row' }}
								spacing={0}
								justifyContent="flex-start"
								alignItems="flex-start"
							>
								<Typography
									variant="titleTab"
									sx={{ color: tabSelected == item.tab ? colorSelected : theme.palette.custom.black }}
								>
									{item.label}
								</Typography>
								<Typography
									variant="titleTab"
									sx={{ color: tabSelected == item.tab ? colorSelected : theme.palette.custom.black }}
								>
									{!item.loading ? item.count : '0 | 0 €'}
								</Typography>
							</Stack>
							{/* })} */}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Box>
			<Box sx={{ display: { xs: 'none', md: 'inherit', sm: 'none' }, textAlign: 'right' }}>
				{tabSelected === 0 && <FilterBarSapInfo />}
			</Box>
		</Stack>
	);
};

export default TabBar;
