import { action, thunk } from 'easy-peasy';

export default {
	items: [],
	save: action((state, payload) => {
		state.items = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.shipping
			.getAllItems(payload)
			.then(data => {
				actions.save(data.items);
			})
			.catch(e => {
				getStoreActions().error('shippingTypes.list');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
