import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: [],
	token: '',
	setData: action((state, payload) => {
		state.data = payload;
	}),
	resetDetailsItems: action((state, payload) => {
		state.data = [];
	}),
	load: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		const id = payload;
		const keyword = getStoreState().purchasings.assignUsers.token;
		const params = { keyword };
		actions.setLoading(true);
		services.purchasings
			.getCaUsers(id, params)
			.then(data => {
				actions.setData(data.items);
				getStoreActions().purchasings.assignUsers.resetSearch();
			})
			.catch(e => {
				getStoreActions().error('promotions.getItems');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
