import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const SummaryInvoiceComp = ({ docNumRif, docDateRif }) => {
	return <StyledTableCell>{`${docNumRif} - ${docDateRif}`}</StyledTableCell>;
};

const SummaryInvoice = memo(
	({ item }) => <SummaryInvoiceComp docNumRif={item.docNumRif} docDateRif={item.docDateRif} />,
	(p, n) => p.item.docNumRif === n.item.docNumRif && p.item.docDateRif === n.item.docDateRif
);

export default SummaryInvoice;
