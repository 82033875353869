import React from 'react';
import { useTheme } from '@mui/material/styles';
import { TableCell } from '@mui/material';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

const Epipoli = props => {
	const { item } = props;
	const theme = useTheme();

	return (
		<TableCell sx={{ padding: '6px 0 !important' }}>
			{item.type === 200 && <CardGiftcardIcon sx={{ color: theme.palette.primary.main, fontSize: '26px' }} />}
		</TableCell>
	);
};

export default Epipoli;
