export default theme => ({
	container: {
		maxWidth: 'calc(100vw - 110px)',
		margin: theme.spacing(4, 0),
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100vw'
		}
	},
	footerText: {
		lineHeight: '32px',
		[theme.breakpoints.down('sm')]: {
			lineHeight: '18px'
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1rem'
		}
	}
});
