import k from 'k';
import utils from 'services/utils';

export default ca => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	return utils
		.fetch(`${k.endpoints.apis}/cart/order/open/centralPurchasing/users?idCa=${ca}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
