import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import TableBodyMUI from '@mui/material/TableBody';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PromoIcon from 'components/PromoIcon';
import StyledTableRow from 'components/Table/StyledTableRow';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';
import Delete from './table/Delete';
import Depot from './table/Depot';
import Quantity from './table/Quantity';
import ShippingInfo from './table/ShippingInfo';
import NewsItem from './table/NewsItem';
import Stock from './table/Stock';

const TableBody = props => {
	const theme = useTheme();
	const items = useStoreState(state => state.selection.items);
	const scope = useStoreState(state => state.order.createMultiple.scope);
	const getStyle = type => {
		if (type === 100) {
			return {
				backgroundColor: '#D4AF37'
			};
		}
	};
	//Verifico se tra gli elementi selezionati c'è almeno una card epipoli per gestire il deposito selezionato di default
	const thereIsEpipoli = items.filter(item => item.type === 200).length > 0;

	return (
		<TableBodyMUI>
			{items.map((item, index) => (
				<StyledTableRow key={`selectionmodal_row_${item.ean}`} style={getStyle(item.type)}>
					<StyledTableCell>
						<Delete item={item} />
					</StyledTableCell>
					{scope === 'order.import' && (
						<StyledTableCell style={{ minWidth: 50, maxWidth: 140, width: 140 }}>
							{item.ean}
						</StyledTableCell>
					)}
					<StyledTableCell style={{ minWidth: 50, maxWidth: 140, width: 140 }}>
						{utils.capitalizeAll(item.author)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 10, maxWidth: 20, width: 20 }}>
						{item.type === 200 && (
							<CardGiftcardIcon
								sx={{ color: theme.palette.primary.main, fontSize: '20px', marginRight: '4px' }}
							/>
						)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 50, maxWidth: 240, width: 240 }}>
						{utils.capitalize(item.title)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 70, maxWidth: 70, width: 70 }}>
						{utils.capitalize(item.volume)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 100, maxWidth: 100, width: 100 }}>
						{utils.capitalize(item.serie)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 90, maxWidth: 90, width: 90 }}>
						{utils.capitalize(item.publisher)}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 24, maxWidth: 50, width: 50 }}>
						{item.isPromo && <PromoIcon color="error" style={{ fontSize: 20, margin: '0px 8px' }} />}
					</StyledTableCell>
					<StyledTableCell style={{ minWidth: 70, maxWidth: 70, width: 70 }}>
						{utils.formatPrice(item.fullPrice)}
					</StyledTableCell>
					<NewsItem item={item} />
					<Depot item={item} epipoli={thereIsEpipoli} />
					{scope !== 'order.import' && <Stock item={item} />}
					<ShippingInfo item={item} />
					<Quantity item={item} id={index} depots={items[index].depots} />
				</StyledTableRow>
			))}
		</TableBodyMUI>
	);
};

export default TableBody;
