import React, { Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ProgressBar from '@ramonak/react-progress-bar';
import { Link } from 'react-router-dom';
import {
	Box,
	Popover,
	Stack,
	Typography,
	ButtonBase,
	Slide,
	Card,
	Grid,
	CircularProgress,
	Button,
	Skeleton
} from '@mui/material';
import utils from 'components/utils';
import { grey } from '@mui/material/colors';
import keys from 'lodash/keys';
import head from 'lodash/head';
import { useTheme } from '@mui/material/styles';

const BudgetPreview = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const { style } = props;
	const budgetStyle = {
		...style,
		typographyFontSize: '12px',
		width: '150px',
		progressBarlabelSize: '12px',
		progressBarheight: '15px',
		boxBorderRadius: '5px',
		padding: '5px',
		marginBottom: '1px',
		hoverStyle: {
			backgroundColor: grey[100],
			transition: '0.3s'
		},
		labelColor: grey[900]
	};
	const selectedBudget = utils.useUserData('selectedBudget', null);
	const updateSelectedBudget = useStoreActions(dispatch => dispatch.me.updateSelectedBudget.performUpdate);
	const loading = useStoreState(state => state.budget.list.loading);
	const items = useStoreState(state => state.budget.list.items);
	const load = useStoreActions(dispatch => dispatch.budget.list.load);
	const [selected, setSelected] = useState(null);
	const [allBudgets, setAllBudgets] = useState(null);
	const [progressBarPerc, setProgressBarPerc] = useState(0);
	const [progressBarColor, setProgressBarColor] = useState('#fff');
	const [progressBarLabelAlignment, setProgressBarLabelAlignment] = useState('right');
	// const [progressBarLabelColor, setProgressBarLabelColor] = useState('#fff');
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = _ => {
		setAnchorEl(null);
	};
	const handleclick = budget => {
		setSelected(budget);
		updateSelectedBudget(budget);
		setAnchorEl(null);
	};
	useEffect(_ => {
		load();
	}, []);
	useEffect(
		_ => {
			const key = head(keys(items));
			if (!items[key]) return;
			const budgetList = items[key].userBudgets;
			const firstBudget = head(budgetList);
			const foundBudget = budgetList.find(budget => budget.id === parseInt(selectedBudget, 10));
			const budget = foundBudget ? foundBudget : firstBudget;
			setSelected(budget);
			setAllBudgets(budgetList);
			if (budget) {
				const bPerc = Math.min(100 - Math.ceil((100 * budget.residual) / budget.amount), 100);
				setProgressBarPerc(bPerc);
				setProgressBarColor(bPerc === 100 ? '#f44336' : '#ffffff');
				setProgressBarLabelAlignment(bPerc > 10 ? 'right' : 'outside');
				// setProgressBarLabelColor(bPerc > 10 ? '#00529b' : '#fff');
			}
		},
		[items, selectedBudget]
	);

	return (
		<Fragment>
			{loading && <Skeleton sx={{ width: '150px' }} />}
			{!loading && !selected && (
				<Typography variant="text" align="center" sx={{ alignSelf: 'center' }}>
					{translate('common.noBudgets')}
				</Typography>
			)}
			{!loading && selected && (
				<Fragment>
					<Box
						sx={{
							width: budgetStyle.width,
							borderRadius: budgetStyle.boxBorderRadius,
							padding: budgetStyle.padding,
							':hover': { ...budgetStyle.hoverStyle }
						}}
						component={ButtonBase}
						onClick={handleOpen}
					>
						<Stack direction="column" sx={{ width: '100%' }}>
							<Typography
								variant="text"
								sx={{
									fontSize: budgetStyle.typographyFontSize,
									marginBottom: budgetStyle.marginBottom
								}}
							>
								{selected.cig} <strong>{utils.formatPrice(selected.residual)}</strong>
							</Typography>
							<ProgressBar
								width="100%"
								height={budgetStyle.progressBarheight}
								labelSize={budgetStyle.progressBarlabelSize}
								bgColor={progressBarColor}
								completed={progressBarPerc}
								labelColor={budgetStyle.labelColor}
								labelAlignment={progressBarLabelAlignment}
							/>
						</Stack>
					</Box>
					<Popover
						TransitionComponent={Slide}
						disableScrollLock
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						elevation={budgetStyle.elevation}
						anchorOrigin={{
							vertical: style.anchorOrigin.vertical,
							horizontal: style.anchorOrigin.horizontal
						}}
						transformOrigin={{
							vertical: style.transformOrigin.vertical,
							horizontal: 'center'
						}}
					>
						<Stack direction="column" spacing={1} sx={{ padding: '10px' }}>
							{allBudgets.map((budget, index) => {
								const effectivePerc = 100 - Math.ceil((100 * budget.residual) / budget.amount);
								const perc = Math.min(effectivePerc, 100);
								// setProgressBarColor(perc === 100 ? '#f44336' : '#00529b');
								// setProgressBarLabelAlignment(perc > 15 ? 'right' : 'outside');
								// setProgressBarLabelColor(perc > 15 ? '#fff' : '#00529b');
								return (
									<Box
										key={index}
										sx={{
											width: budgetStyle.width,
											borderRadius: budgetStyle.boxBorderRadius,
											padding: budgetStyle.padding,
											':hover': { ...budgetStyle.hoverStyle }
										}}
										component={ButtonBase}
										onClick={_ => handleclick(budget)}
									>
										<Stack direction="column" sx={{ width: '100%' }}>
											<Typography
												variant="text"
												sx={{
													fontSize: budgetStyle.typographyFontSize
												}}
											>
												{budget.cig}: <strong>{utils.formatPrice(budget.residual)}</strong>
											</Typography>
											<ProgressBar
												width="100%"
												height={budgetStyle.progressBarheight}
												labelSize={budgetStyle.progressBarlabelSize}
												bgColor={progressBarColor}
												completed={perc}
												labelColor={budgetStyle.labelColor}
												labelAlignment={progressBarLabelAlignment}
											/>
										</Stack>
									</Box>
								);
							})}
							<Button component={Link} to={'/budget'} variant="light" size="small">
								{translate('common.gotobudget')}
							</Button>
						</Stack>
					</Popover>
				</Fragment>
			)}
		</Fragment>
	);
};

export default BudgetPreview;
