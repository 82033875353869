import React from 'react';
import { useStoreState } from 'easy-peasy';
import TableBodyMUI from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import utils from 'components/utils';
import allCols from './cols';

const TableBody = ({ rows, visibleCols, isCheckout }) => {
	const cells = visibleCols.map(col => allCols.find(c => c.id === col));
	const order = useStoreState(state => state.documents.details.orderDirection);
	const orderBy = useStoreState(state => state.documents.details.orderBy);
	const sortedRows = utils.stableSort(rows, order, orderBy);
	return (
		<TableBodyMUI>
			{sortedRows.map((item, index) => (
				<TableRow
					key={
						item.attribution !== null ? `row_${item.attribution}_${index}` : `row_${item.catalog}_${index}`
					}
					style={{ backgroundColor: item.color, height: 40 }}
				>
					{cells.map(cell => {
						if (item.attribution !== null) return cell.comp({ item });
						return cell.comp({ item, isCheckout });
					})}
				</TableRow>
			))}
		</TableBodyMUI>
	);
};

export default TableBody;
