import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Details from 'routes/Catalog/Details';
//import Details from '../../../../../Catalog/Details/index_OLD';
import { useStoreState } from 'easy-peasy';

const styles = theme => ({
	text: {
		'& p': {
			margin: 0
		}
	},
	modalPaper100: {
		width: '100%',
		height: '100%',
		padding: theme.spacing(2, 4, 3),

		overflow: 'scroll',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	modalPaper80: {
		width: '80%',
		height: '80%',
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),

		overflow: 'scroll',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	coverImg: {
		height: '100%',
		width: '100%',
		objectFit: 'contain',
		objectPosition: 'top'
	},
	secondGrid: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: `${theme.spacing(2)}px !important`
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1)
		}
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	}
});

const ModalDetails = ({ classes, scrollPosition, selectedItem, modalColor, modalSize100 = false }) => {
	const openMenu = useStoreState(state => state.menu.mainOpen);

	return (
		<Fragment>
			{selectedItem && (
				<Paper
					className={`${modalSize100 ? classes.modalPaper100 : classes.modalPaper80}`}
					style={{ backgroundColor: modalColor, marginLeft: openMenu === true ? '144px' : '0px' }}
				>
					<Details scrollPosition={scrollPosition} id_ean={selectedItem.ean} urlComponet="/" />
				</Paper>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(ModalDetails);
