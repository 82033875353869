import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import utils from 'components/utils';
import Link from '@mui/material/Link';

const Synopsis = props => {
	const { details } = props;
	const translate = utils.useI18N();
	const [showFullSynopsis, setShowFullSynopsis] = useState(false);
	const readMore = showFullSynopsis
		? `(${translate('catalog.details.hideSynopsis')})`
		: `(${translate('catalog.details.showSynopsis')})`;
	const text =
		details &&
		details.synopsis &&
		(showFullSynopsis ? details.synopsis : utils.useEllipsis(details.synopsis, 150, '...') + ' ');
	const toggleSynopsis = event => {
		event.preventDefault();
		setShowFullSynopsis(!showFullSynopsis);
	};
	return details.synopsis ? (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0.5}
			sx={{ margin: '5px 0' }}
		>
			<Typography variant="text" sx={{ fontWeight: '600' }}>
				{translate('catalog.details.infoBox.synopsis') + ':'}
			</Typography>
			<Typography variant="text">
				{text}
				<Link href="#" onClick={toggleSynopsis}>
					{readMore}
				</Link>
			</Typography>
		</Stack>
	) : (
		''
	);
};

export default Synopsis;
