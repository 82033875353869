import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import styles from './styles';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import utils from 'components/utils';
import Typography from '@mui/material/Typography';
import Delete from './Delete';

const DeleteEditorials = ({ classes, close, showCloseIcon }) => {
	const deleteEditorial = useStoreActions(dispatch => dispatch.editorials.deleteEditorials.deleteEditorial);
	const modalOpen = useStoreState(state => state.editorials.deleteEditorials.modalDeleteOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.editorials.deleteEditorials.openModalDelete);
	const translate = utils.useI18N();

	const onClick = _ => {
		deleteEditorial();
	};

	return (
		<div
			className={clsx(classes.negateMargin, {
				[classes.margin]: showCloseIcon
			})}
		>
			<Fragment>
				<Modal
					className={classes.modalPosition}
					open={modalOpen}
					onClose={_ => setModalOpen(false)}
					aria-labelledby="shipping-types-modal"
					aria-describedby="shipping-types-modal"
				>
					<div className="modal-content">{modalOpen && <Delete />}</div>
				</Modal>
			</Fragment>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(DeleteEditorials);
