import { action, thunk, thunkOn } from 'easy-peasy';
import utils from './utils';

export default {
	items: [],
	save: action((state, payload) => {
		state.items = payload;
	}),
	openFromUrl: true,
	setOpenFromUrl: action((state, payload) => {
		state.openFromUrl = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	load: thunk(utils.loadList),
	reloadOnUpdate: thunkOn(
		(actions, storeActions) => [storeActions.shippingType.update.sendRequest.successType],
		async (actions, target, helpers) => {
			// prettier-ignore
			const { result: { item: { depotCode } } } = target;
			utils.loadList(actions, depotCode, helpers);
		}
	),
	reloadOnDelete: thunkOn(
		(actions, storeActions) => [storeActions.shippingType.remove.sendRequest.successType],
		async (actions, target, helpers) => {
			const { result: depot } = target;
			utils.loadList(actions, depot.depotCode, helpers);
		}
	)
};
