import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import keys from 'lodash/keys';
import { Grid, Paper, Typography, Table, TableContainer, CircularProgress, Box } from '@mui/material';
import TableHead from './Table/TableHead';
import TableBody from './Table/TableBody';
import utils from 'components/utils';
import TransformAlert from './Transform/components/TransformAlert';

const WishlistResume = ({ id, status, type }) => {
	const theme = useTheme();
	const load = useStoreActions(dispatch => dispatch.wishlist.details.load);
	const loading = useStoreState(state => state.wishlist.details.loading);
	const data = useStoreState(state => state.wishlist.details.data);
	const setWishlistExpanded = useStoreActions(dispatch => dispatch.wishlist.details.setWishlistExpanded);
	const translate = utils.useI18N();
	useEffect(() => {
		if (keys(data).indexOf(id.toString()) === -1) {
			load(id);
		}
	}, [load, data, id]);

	const items = data[id]
		? data[id].map(d => ({
				key: d.id,
				fatherId: id,
				isPromoActive: d.isPromoActive,
				promotionSapId: d.promotionSapId,
				promoDesc: d.promoDesc,
				promoSingleItem: d.promotionDesc,
				id: d.id,
				quantity: d.quantity,
				depotCode: d.depotCode,
				notes: d.notes,
				selectedDepot: d.depotDescription,
				isSelected: d.isSelected,
				...d.catalog
		  }))
		: [];

	useEffect(() => {
		setWishlistExpanded(id);
	}, [items]);

	const promoDesc = items.length > 0 ? items[0].promoDesc : null;

	return (
		<TableContainer component={Paper}>
			{loading && (
				<Grid container item justify="center" sx={{ margin: '10px 0' }}>
					<CircularProgress size={40} sx={{ color: theme.palette.primary.main }} />
				</Grid>
			)}
			{(status === 'APPROVED' || status === 'DRAFT_BO') && <TransformAlert id={id} promoDesc={promoDesc} />}
			{!loading && items.length > 0 && (
				<Table size="small" stickyHeader aria-label="sticky table">
					<TableHead type={type} status={status} />
					<TableBody rows={items} status={status} type={type} />
				</Table>
			)}
			{!loading && items.length === 0 && (
				<Box sx={{ width: '100%', textAlign: 'center', padding: '10px 0' }}>
					<Typography variant="text" sx={{ fontWeight: '600' }}>
						{translate('wishlist.noWishlist')}
					</Typography>
				</Box>
			)}
		</TableContainer>
	);
};

export default WishlistResume;
