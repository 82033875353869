import React from 'react';
import { withStyles } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import styles from '../styles';
import moment from 'moment';
import utils from 'components/utils';

const HistoryTable = ({ data }) => {
	const translate = utils.useI18N();
	const formatDate = item => {
		const newDate = moment(item, 'DD-MM-YYYY HH:mm:ss');
		return moment(newDate).format('L');
	};
	return (
		<TableContainer component={Paper}>
			<Table aria-label="search results table" size="small">
				<TableHead>
					<TableRow>
						<TableCell>{translate('catalog.details.client')}</TableCell>
						<TableCell>{translate('catalog.details.companyName')}</TableCell>
						<TableCell>{translate('catalog.details.order')}</TableCell>
						<TableCell>{translate('catalog.details.date')}</TableCell>
						<TableCell>{translate('catalog.details.depot')}</TableCell>
						<TableCell>{translate('catalog.details.ordered')}</TableCell>
						<TableCell>{translate('catalog.details.fulfilment')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data &&
						data.map((item, index) => (
							<TableRow key={`bodyCell_${index}`}>
								<TableCell align="left">{item.client}</TableCell>
								<TableCell align="left">{item.clientDesc}</TableCell>
								<TableCell align="left">{item.code}</TableCell>
								<TableCell align="left">{formatDate(item.date)}</TableCell>
								<TableCell align="left">{item.depotDesc}</TableCell>
								<TableCell align="left">{item.quantityRequired}</TableCell>
								<TableCell align="left">{item.shippingCode}</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default withStyles(styles, { withTheme: true })(HistoryTable);
