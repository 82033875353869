import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const NotLogged = props => {
	const load = useStoreActions(dispatch => dispatch.notifications.count.load);
	useEffect(_ => load(), [load]);
	return (
		<Container>
			<Typography variant="h2">Caricamento in corso...</Typography>
		</Container>
	);
};

export default NotLogged;
