import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const AuthorComp = ({ text }) => {
	return <StyledTableCell>{text}</StyledTableCell>;
};

const Author = memo(
	({ item }) => <AuthorComp text={item.author} />,
	(p, n) => p.item.author === n.item.author
);

export default Author;
