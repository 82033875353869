import React, { memo } from 'react';
import { useStoreActions } from 'easy-peasy';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import StyledTableCell from 'components/Table/StyledTableCell';
import NumericStepper from 'components/NumericStepper';
import Tooltip from '@mui/material/Tooltip';

const QuantityEvadibleComp = ({ amount, catalog, checked, isAdded, isCheckout, delivered }) => {
	const setEvadibleQt = useStoreActions(dispatch => dispatch.documents.details.setEvadibleQt);
	const checkRow = useStoreActions(dispatch => dispatch.documents.details.checkRow);
	const zeroRow = useStoreActions(dispatch => dispatch.documents.details.zeroRow);
	const greenTheme = createTheme({ palette: { primary: green } });
	const redTheme = createTheme({ palette: { primary: red } });
	const onStepperChange = (valueAsNumber, valueAsString, input) => {
		setEvadibleQt({ amount: valueAsNumber, catalog });
	};
	if (isAdded) return <StyledTableCell>{amount}</StyledTableCell>;
	return (
		<StyledTableCell style={{ width: 150 }}>
			<Grid container item xs={12}>
				<Grid item xs={3}>
					<ThemeProvider theme={greenTheme}>
						<Tooltip title="Conferma" placement="top">
							<IconButton color="primary" size="small" onClick={_ => checkRow(catalog)}>
								<CheckIcon />
							</IconButton>
						</Tooltip>
					</ThemeProvider>
				</Grid>
				<Grid item xs={3}>
					<ThemeProvider theme={redTheme}>
						<Tooltip title="Azzera" placement="top">
							<IconButton color="primary" size="small" onClick={_ => zeroRow(catalog)}>
								<ClearIcon />
							</IconButton>
						</Tooltip>
					</ThemeProvider>
				</Grid>
				<Grid item xs={6}>
					<NumericStepper
						step={1}
						min={0}
						max={isCheckout ? delivered : 999}
						removeDot
						precision={0}
						value={amount}
						onChange={onStepperChange}
						strict
					/>
				</Grid>
			</Grid>
		</StyledTableCell>
	);
};

const QuantityEvadible = memo(
	({ item, isCheckout }) => (
		<QuantityEvadibleComp
			isCheckout={isCheckout}
			amount={item.evadibleQt}
			delivered={item.deliveredQuantityEff}
			catalog={item.catalog}
			checked={item.checked}
			isAdded={item.isAdded}
		/>
	),
	(p, n) =>
		p.item.evadibleQt === n.item.evadibleQt &&
		p.item.deliveredQuantityEff === n.item.deliveredQuantityEff &&
		p.item.checked === n.item.checked &&
		p.item.isAdded === n.item.isAdded &&
		p.isCheckout === n.isCheckout
);

export default QuantityEvadible;
