import { action, thunk, thunkOn } from 'easy-peasy';
import storeUtils from 'store/utils';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	save: action((state, payload) => {
		state.items = payload.items;
	}),
	updateOnSendingChange: thunkOn(
		(actions, storeActions) => [storeActions.BOnotifications.send.sendRequest.successType],
		async (actions, target, helpers) => {
			actions.load(actions, null, helpers);
		}
	),
	load: thunk((actions, payload, { getState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const page = getState().pagination.current - 1;
		const step = getState().pagination.step;
		const params = {
			page,
			step
		};
		return services.BOnotifications.list(params)
			.then(data => {
				actions.save(data);
			})
			.catch(e => {
				getStoreActions().error('BOnotifications.list');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	...storeUtils.createPagination({ step: 25, paginationTrigger: 'save', loadFn: 'load' })
};
