import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const TitleComp = ({ ean, title, inCart }) => {
	const theme = useTheme();
	const paramCart = inCart || '';

	return (
		<StyledTableCell>
			<Link to={`/catalog/search/details/${ean}?ic=${paramCart}`} style={{ textDecoration: 'none' }}>
				<div style={{ maxWidth: 200, color: theme.palette.primary.main }}>{utils.capitalize(title)}</div>
			</Link>
		</StyledTableCell>
	);
};

const Title = memo(
	({ item }) => <TitleComp ean={item.ean} title={item.title} inCart={item.inCart} />,
	(p, n) => p.item.title === n.item.title && p.item.ean === n.item.ean && p.item.inCart === n.item.inCart
);

export default Title;
