import React, { useEffect, useState } from 'react';
import {
	Stack,
	Paper,
	Typography,
	Breadcrumbs,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	OutlinedInput,
	Button,
	Box,
	LinearProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from '@mui/material/styles';
import { useStoreState, useStoreActions } from 'easy-peasy';
import utils from 'components/utils';
import ConditionsData from './components/ConditionsData';

const Conditions = _ => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const user = utils.useUserData('', null);
	const loadingConditionsOfSale = useStoreState(state => state.users.conditionsOfSale.loading);
	const data = useStoreState(state => state.users.conditionsOfSale.data);
	const loadCond = useStoreActions(dispatch => dispatch.users.conditionsOfSale.load);
	const loadShipping = useStoreActions(dispatch => dispatch.shippingType.list.load);
	const setScope = useStoreActions(dispatch => dispatch.users.conditionsOfSale.setScope);
	const depotList = user.depots.filter(depot => depot.type === 'PRIMARIO');
	const [selectedDepot, setSelectedDepot] = useState('');
	const shippings = useStoreState(state => state.shippingType.list.items);
	const [selectedShipping, setSelectedShipping] = useState('');
	useEffect(
		_ => {
			setScope('conditions-of-sale');
		},
		[setScope]
	);
	useEffect(
		_ => {
			selectedDepot !== '' && loadShipping(selectedDepot);
		},
		[selectedDepot]
	);
	const handleSelectDepot = event => {
		setSelectedDepot(event.target.value);
		setSelectedShipping('');
	};
	const handleSelectShipping = event => {
		setSelectedShipping(event.target.value);
	};
	const onSearch = _ => {
		loadCond({
			depot: selectedDepot,
			shipping: selectedShipping
		});
	};
	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0}
			sx={{ minHeight: '100vh' }}
		>
			<Paper variant="stickyTop">
				<Stack
					direction={{ xs: 'row', md: 'row', sm: 'row' }}
					justifyContent="space-between"
					alignItems="center"
					spacing={1}
					variant="wrapperColor"
				>
					<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
						<MuiLink component={Link} to={'/me'} underline="hover">
							<Typography variant="titlePage" sx={{ color: 'black' }}>
								Profilo
							</Typography>
						</MuiLink>
						<Typography variant="titlePage" sx={{ color: 'black' }}>
							Condizioni di vendita
						</Typography>
					</Breadcrumbs>
				</Stack>
				<Stack
					direction={{ xs: 'row', md: 'row', sm: 'row' }}
					justifyContent="flex-start"
					alignItems="center"
					spacing={1}
					variant="wrapperColor"
				>
					<FormControl sx={{ width: '250px' }}>
						<InputLabel
							id="filter-select-depots"
							sx={{
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								marginTop: '-5px'
							}}
						>
							{translate('orders.filters.depot')}
						</InputLabel>
						<Select
							labelId="filter-select-depots"
							id="select-depots"
							value={selectedDepot}
							onChange={handleSelectDepot}
							input={<OutlinedInput label={translate('orders.filters.depot')} />}
							sx={{
								height: '40px',
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								fontWeight: '400',
								backgroundColor: theme.palette.custom.white
							}}
						>
							{depotList.map((item, index) => (
								<MenuItem key={`${item.id}_${index}`} value={item.id}>
									{item.description}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: '250px' }}>
						<InputLabel
							id="filter-select-depots"
							sx={{
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								marginTop: '-5px'
							}}
						>
							{'Tipo di spedizione'}
						</InputLabel>
						<Select
							disabled={selectedDepot === ''}
							labelId="filter-select-shipping"
							id="select-shipping"
							value={selectedShipping}
							onChange={handleSelectShipping}
							input={<OutlinedInput label={'Tipo di spedizione'} />}
							sx={{
								height: '40px',
								fontFamily: ['"Poppins", sans-serif'].join(','),
								fontSize: '14px',
								fontWeight: '400',
								backgroundColor: theme.palette.custom.white
							}}
						>
							{shippings.map((item, index) => (
								<MenuItem key={`${item.id}_${index}`} value={item.shippingRule.code}>
									{item.shippingRule.desc}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Button
						variant="dark"
						onClick={onSearch}
						disabled={selectedShipping === '' || loadingConditionsOfSale}
					>
						Cerca
					</Button>
				</Stack>
				{loadingConditionsOfSale && (
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<LinearProgress sx={{ color: theme.palette.primary.main }} />
					</Box>
				)}
			</Paper>
			{!loadingConditionsOfSale && data && <ConditionsData />}
		</Stack>
	);
};

export default Conditions;
