const searcResultsCols = [
	'cover',
	'ean',
	'author',
	'title', // will always be with preload and availability
	'volume',
	'serie',
	'publisher',
	'publicationDate',
	'promo',
	'price',
	'cart',
	'newsItem',
	'stock'
];

export default searcResultsCols;
