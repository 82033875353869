import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: [],
	open: false,
	setOpen: action((state, payload) => {
		state.open = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { id_ean, province } = payload;
		actions.setLoading(true);
		services.catalog
			.getBadges(id_ean, province)
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('catalog.badges');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	loadBadges: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		const { province } = payload;
		actions.setLoading(true);
		services.catalog
			.getAllBadges(province)
			.then(data => actions.save(data))
			.catch(e => {
				getStoreActions().error('catalog.allbadges');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	save: action((state, payload) => {
		state.items = payload.items;
	})
};
