import { action, thunk } from 'easy-peasy';

export default {
	loading: -1,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendRequest: thunk((actions, payload, { injections, getStoreActions, getStoreState }) => {
		const { services } = injections;
		const { id, successMessage } = payload;
		actions.setLoading(id);
		return services.wishlist
			.updateFavourite(id)
			.then(data => {
				getStoreActions().successCustom(successMessage);
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('wishlist.setFavourite');
			})
			.finally(_ => {
				actions.setLoading(-1);
			});
	})
};
