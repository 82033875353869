export default theme => ({
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 0, 0, 0)
		}
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
});
