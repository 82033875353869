import k from 'k';
import utils from 'services/utils';

export default ({ user }) => {
	const requestOptions = {
		method: 'PATCH',
		body: JSON.stringify(user),
		headers: utils.createHeaders()
	};
	return utils.fetch(`${k.endpoints.apis}/me`, requestOptions).then(response => utils.handleResponse({ response }));
};
