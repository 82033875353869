import { action, thunk, thunkOn } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	scope: '',
	setScope: action((state, payload) => {
		state.scope = payload;
	}),
	data: null,
	save: action((state, payload) => {
		state.data = payload;
	}),
	reset: action((state, payload) => {
		state.data = null;
	}),
	performSearchListenerPagination: thunkOn(
		(actions, storeActions) => [storeActions.users.conditionsOfSale.setScope],
		async (actions, target, helpers) => {
			const { scope } = helpers.getStoreState().users.conditionsOfSale;
			if (scope === 'conditions-of-sale') actions.reset(actions, null, helpers);
		}
	),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.users
			.conditionsOfSale(payload)
			.then(data => {
				actions.save(data.item);
			})
			.catch(e => {
				getStoreActions().error('conditionsOfSale.search');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
