import React from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import utils from 'components/utils';
import styles from './styles';
import ListItemLink from './ListItemLink';
import Tooltip from '@mui/material/Tooltip';

const Returns = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const open = useStoreState(state => state.menu.mainOpen);
	return (
		<ListItemLink to="/returns" className={classes.listItem} button>
			{!open && (
				<Tooltip title={translate('common.menu.returns')} placement="right">
					<ListItemIcon className={classes.listIcon}>
						<AssignmentReturnIcon />
					</ListItemIcon>
				</Tooltip>
			)}
			{open && (
				<ListItemIcon className={classes.listIcon}>
					<AssignmentReturnIcon />
				</ListItemIcon>
			)}
			<ListItemText primary={translate('common.menu.returns')} />
		</ListItemLink>
	);
};

export default withStyles(styles, { withTheme: true })(Returns);
