import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Stack, List, ListItem, ListItemText, Typography, Button } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import utils from 'components/utils';

const PromoList = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const promo = useStoreState(state => state.catalog.details.data.promo);
	const setModalOpen = useStoreActions(dispatch => dispatch.catalog.details.setModalOpen);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={0.5}
			variant="wrapperColor"
			sx={{
				width: '100%',
				borderColor: theme.palette.secondary.main,
				borderWidth: '1px !important',
				borderStyle: 'solid'
			}}
		>
			<Typography variant="titleSection" sx={{ color: theme.palette.secondary.main, fontWeight: '400' }}>
				{translate('catalog.details.promo.nameIntroMessage')}
			</Typography>
			<Typography variant="titleSection" sx={{ color: theme.palette.secondary.main, fontWeight: '400' }}>
				{translate('catalog.details.promo.redirectMessage')}
			</Typography>
			<List dense sx={{ padding: '10px', width: { xs: '100%', md: '60%', sm: '60%' } }}>
				{promo.map((item, index) => (
					<ListItem
						key={index}
						secondaryAction={
							<Button
								variant="textAction"
								component={RouterLink}
								disabled={item.isOnlyVisualization}
								endIcon={<KeyboardDoubleArrowRightIcon />}
								to={'/catalog/search/by-promotion/' + item.sapId}
								onClick={() => setModalOpen(false)}
								sx={{
									backgroundColor: theme.palette.custom.grey + ' !important',
									'&:hover': {
										backgroundColor: theme.palette.custom.grey + ' !important'
									}
								}}
							>
								{translate('catalog.details.promo.searchButton')}
							</Button>
						}
						sx={{
							borderStyle: 'solid',
							borderWidth: '0 0 1px 0',
							borderColor: theme.palette.custom.greyborder
						}}
					>
						<ListItemText
							primary={
								<Typography variant="text" sx={{ fontWeight: '600' }}>
									{item.name}
								</Typography>
							}
							secondary={item.isOnlyVisualization ? translate('catalog.details.promo.notAllowed') : null}
						/>
					</ListItem>
				))}
			</List>
		</Stack>
	);
};

export default PromoList;
