import { action, thunk, thunkOn, computed } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	items: computed(state => {
		const items = [];
		const getSingle = item => {
			items.push({
				...item,
				children: state.flatItems
					.filter(i => i.parent === item.id)
					.map(inner => ({
						...inner,
						children: state.flatItems.filter(i => i.parent === inner.id)
					}))
			});
		};
		state.flatItems.filter(i => !i.parent).forEach(getSingle);
		return items;
	}),
	flatItems: [],
	save: action((state, payload) => {
		const itms = [];
		const traverse = (item, parent, grandParent) => {
			const singleItem = {
				id: item.id,
				name: item.name,
				selected: false,
				parent,
				grandParent
			};
			itms.push(singleItem);
			if (item.children && item.children.length > 0) {
				item.children.forEach(i => traverse(i, item.id, parent));
			}
		};
		payload.items.forEach(i => traverse(i, null, null));
		state.flatItems = itms;
	}),
	toggleSelection: action((state, payload) => {
		const clickedItem = state.flatItems.find(i => i.id === payload);
		const sel = clickedItem.selected;
		state.flatItems.forEach(i => {
			if (
				i.id === clickedItem.id || // SET CURRENT
				i.parent === clickedItem.id || // SET ITS CHILDREN
				i.grandParent === clickedItem.id // SET ITS GRANDCHILDREN
			)
				i.selected = !sel;
		});
		// GET ALL MY SIBLINGS
		const siblings = state.flatItems.filter(i => i.parent === clickedItem.parent);
		if (siblings.some(s => !s.selected)) {
			// IF AT LEAST ONE IS NOT SELECTED...
			state.flatItems.forEach(i => {
				if (i.id === clickedItem.parent) i.selected = false; // ...DESELECT THE PARENT
			});
		}
		if (siblings.every(s => s.selected)) {
			// IF ALL ARE SELECTED...
			state.flatItems.forEach(i => {
				if (i.id === clickedItem.parent) i.selected = true; // ...SELECT THE PARENT
			});
		}
		if (!clickedItem.grandParent) return;
		// GET ALL MY PARENT'S SIBLINGS
		const parentSiblings = state.flatItems.filter(i => i.parent === clickedItem.grandParent);
		if (parentSiblings.some(s => !s.selected)) {
			// IF AT LEAST ONE IS NOT SELECTED...
			state.flatItems.forEach(i => {
				if (i.id === clickedItem.grandParent) i.selected = false; // ...DESELECT THE PARENT
			});
		}
		if (parentSiblings.every(s => s.selected)) {
			// IF ALL ARE SELECTED...
			state.flatItems.forEach(i => {
				if (i.id === clickedItem.grandParent) i.selected = true; // ...SELECT THE PARENT
			});
		}
	}),
	deselectAll: action((state, payload) => {
		state.flatItems = state.flatItems.map(item => ({ ...item, selected: false }));
	}),
	deselectOnFiltersReset: thunkOn(
		(actions, storeActions) => [storeActions.catalog.filters.reset],
		async (actions, target, helpers) => {
			actions.deselectAll();
		}
	),
	load: thunk((actions, payload, { getState, getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		if (getState().items.length > 0) return;
		actions.setLoading(true);
		services.catalog
			.dewey()
			.then(data => {
				actions.save(data);
			})
			.catch(e => {
				getStoreActions().error('catalog.dewey');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
