import React, { Fragment, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { DropzoneArea } from 'react-mui-dropzone';
import { Button, Stack, CircularProgress } from '@mui/material';
import Avatar from 'components/Avatar';
import utils from 'components/utils';
import { useTheme } from '@mui/material/styles';

const Picture = _ => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const loadingUpdate = useStoreState(state => state.me.avatar.loadingUpdate);
	const loadingDelete = useStoreState(state => state.me.avatar.loadingDelete);
	const upload = useStoreActions(dispatch => dispatch.me.avatar.update);
	const deletee = useStoreActions(dispatch => dispatch.me.avatar.deletee);
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const onConfirm = _ => {
		upload({ file, fileName });
	};
	const onDelete = _ => {
		deletee();
	};
	const getFileLimitExceedMessage = filesLimit =>
		translate('upload.fileLimitExceedMessage').replace('_NUM_', filesLimit);
	const getFileAddedMessage = fileName => translate('upload.fileAddedMessage').replace('_NAME_', fileName);
	const getFileRemovedMessage = fileName => translate('upload.fileRemovedMessage').replace('_NAME_', fileName);
	const getDropRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) =>
		translate('upload.dropRejectMessage').replace('_NAME_', rejectedFile.name);
	return (
		<Fragment>
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				justifyContent={'space-between'}
				alignItems={{ md: 'start', xs: 'center' }}
				spacing={2}
			>
				<Avatar size={128} />
				<DropzoneArea
					acceptedFiles={['.jpg', '.jpeg', '.png']}
					showAlerts={['error']}
					filesLimit={1}
					maxFileSize={3000000}
					dropzoneText="Clicca per inserire o trascina immagine JPG, JPEG o PNG "
					getFileLimitExceedMessage={getFileLimitExceedMessage}
					getFileAddedMessage={getFileAddedMessage}
					getFileRemovedMessage={getFileRemovedMessage}
					getDropRejectMessage={getDropRejectMessage}
					alertSnackbarProps={{
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'top'
						},
						autoHideDuration: 2000
					}}
					previewGridProps={{
						container: { spacing: 0, justify: 'center' },
						item: { xs: 4 }
					}}
					onDrop={async ([file]) => {
						var reader = new FileReader();
						reader.onload = function(e) {
							var contents = e.target.result;
							setFile(contents);
							setFileName(file.name);
						};
						reader.readAsDataURL(file);
					}}
					inputProps={{
						'data-testid': 'dropzone.area'
					}}
				/>
			</Stack>
			<Stack
				direction={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
				sx={{ marginTop: theme.spacing(2) }}
			>
				{loadingDelete && <CircularProgress size={24} />}
				{!loadingDelete && (
					<Button variant="important" onClick={onDelete}>
						Elimina
					</Button>
				)}
				{loadingUpdate && <CircularProgress size={24} />}
				{!loadingUpdate && (
					<Button variant="dark" onClick={onConfirm}>
						Conferma
					</Button>
				)}
			</Stack>
		</Fragment>
	);
};

export default Picture;
