import React from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import Stock from './Stock';
import Title from './Title';
import Availability from './Availability';
import Preload from './Preload';
import Promo from './Promo';
import Price from './Price';
import OneClick from './OneClick';
import Cover from './Cover';
import Author from './Author';
import Serie from './Serie';
import Publisher from './Publisher';
import PublicationDate from './PublicationDate';
import NewsItem from './NewsItem';
import Cart from './Cart';
import Selection from './Selection';
import Ranking from './Ranking';
import Volume from './Volume';
import Epipoli from './Epipoli';

const allCells = [
	{
		id: 'selection',
		comp: ({ item }) => <Selection key={`bodyCell_selection_${item.ean}`} item={item} />
	},
	{
		id: 'cover',
		comp: ({ item }) => <Cover key={`bodyCell_cover_${item.ean}`} item={item} />
	},
	{
		id: 'ean',
		comp: ({ item }) => (
			<StyledTableCell key={`bodyCell_ean_${item.ean}`} component="th" scope="row">
				{item.ean}
			</StyledTableCell>
		)
	},
	{
		id: 'author',
		comp: ({ item }) => <Author key={`bodyCell_author_${item.ean}`} item={item} />
	},
	{
		id: 'preload',
		comp: ({ item }) => <Preload key={`bodyCell_preload_${item.ean}`} item={item} width={50} />
	},
	{
		id: 'availability',
		comp: ({ item }) =>
			item.type === 200 ? (
				<Epipoli key={`bodyCell_epipoli_${item.ean}`} item={item} width={24} />
			) : (
				<Availability key={`bodyCell_availability_${item.ean}`} item={item} width={24} />
			)
	},
	{
		id: 'title',
		comp: ({ item }) => <Title key={`bodyCell_title_${item.ean}`} item={item} inCart={item.inCart} />
	},
	{
		id: 'volume',
		comp: ({ item }) => <Volume key={`bodyCell_volume_${item.ean}`} item={item} />
	},
	{
		id: 'serie',
		comp: ({ item }) => <Serie key={`bodyCell_serie_${item.ean}`} item={item} />
	},
	{
		id: 'publisher',
		comp: ({ item }) => <Publisher key={`bodyCell_publisher_${item.ean}`} item={item} />
	},
	{
		id: 'publicationDate',
		comp: ({ item }) => <PublicationDate key={`bodyCell_publicationDate_${item.ean}`} item={item} width={93} />
	},
	{ id: 'price', comp: ({ item }) => <Price key={`bodyCell_price_${item.ean}`} item={item} /> },
	{ id: 'promo', comp: ({ item }) => <Promo key={`bodyCell_promo_${item.ean}`} item={item} /> },
	{
		id: 'newsItem',
		comp: ({ item }) => <NewsItem key={`bodyCell_newsItem_${item.ean}`} item={item} />
	},
	{ id: 'stock', comp: ({ item }) => <Stock key={`bodyCell_stock_${item.ean}`} item={item} /> },
	{
		id: 'cart',
		comp: ({ item }) => <Cart key={`bodyCell_cart_${item.ean}`} item={item} width={40} />
	},
	{
		id: 'oneClick',
		comp: ({ item }) => <OneClick key={`bodyCell_oneClick_${item.ean}`} item={item} />
	},
	{
		id: 'ranking',
		comp: ({ item }) => <Ranking key={`bodyCell_ranking_${item.ean}`} item={item} />
	}
];

export default allCells;
