import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import styles from './styles';
import clsx from 'clsx';
import utils from 'components/utils';
import Delete from './Delete';

const DeletePurchasingCenters = ({ classes, close, showCloseIcon }) => {
	const deletePurchasingCenter = useStoreActions(
		dispatch => dispatch.purchasings.deletePurchasings.deletePurchasings
	);
	const modalOpen = useStoreState(state => state.purchasings.deletePurchasings.modalDeleteOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.purchasings.deletePurchasings.openModalDelete);
	const items = useStoreState(state => state.purchasings.list.items);
	const translate = utils.useI18N();

	return (
		<div
			className={clsx(classes.negateMargin, {
				[classes.margin]: showCloseIcon
			})}
		>
			<Fragment>
				<Modal
					className={classes.modalPosition}
					open={modalOpen}
					onClose={_ => setModalOpen(false)}
					aria-labelledby="shipping-types-modal"
					aria-describedby="shipping-types-modal"
				>
					<div className="modal-content">{modalOpen && <Delete />}</div>
				</Modal>
			</Fragment>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(DeletePurchasingCenters);
