import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	base: null,
	setBase: action((state, payload) => {
		state.base = payload;
	}),
	fileName: null,
	setFileName: action((state, payload) => {
		state.fileName = payload;
	}),
	results: [],
	uploaderModalOpen: false,
	uploaderPdfModalOpen: false,
	infoUploader: '',
	setUploaderModalOpen: action((state, payload) => {
		state.fileName = null;
		state.base = null;
		state.uploaderModalOpen = payload;
	}),
	uploaderPdf: false,
	setUploaderPdf: action((state, payload) => {
		state.uploaderPdf = payload;
		state.fileName = null;
		state.uploaderModalOpen = payload;
	}),
	setUploaderPdfModalOpen: action((state, payload) => {
		state.fileName = null;
		state.base = null;
		state.uploaderPdfModalOpen = payload;
	}),
	setInfo: action((state, payload) => {
		state.infoUploader = 'test';
	}),
	sendRequest: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const base = getStoreState().coupons.importt.base;
		const fileName = getStoreState().coupons.importt.fileName;
		const id = payload;
		return services.coupons
			.importt(base, id, fileName)
			.then(data => {
				actions.save(data.items);
				getStoreActions().coupons.detailsItems.setData(data.items);
				getStoreActions().success('coupons.import');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().success('coupons.import');
				if (getStoreState().coupons.details.data)
					getStoreActions().coupons.details.load(getStoreState().coupons.details.data.id);
				getStoreActions().coupons.importt.setUploaderModalOpen(false);
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	sendRequestArticles: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		return services.coupons
			.importArticles(payload)
			.then(data => {
				actions.setUploaderModalOpen(false);
				if (data.items.length === 0) {
					getStoreActions().error('coupons.importError');
				} else {
					getStoreActions().success('coupons.import');
					actions.save(data.items);
					getStoreActions().coupons.detailsItems.setData(data.items);
					actions.getReport();
				}
				return Promise.resolve(data);
			})
			.catch(e => {})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	download: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const id = payload;
		return services.coupons
			.download(id)
			.then(data => {})
			.catch(e => {
				getStoreActions().error('coupons.noPdf');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	removePdf: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		const id = payload;
		return services.coupons
			.removePdf(id)
			.then(data => {
				getStoreActions().success('coupons.pdfRemoved');
			})
			.finally(_ => {
				actions.setLoading(false);
				getStoreActions().success('coupons.pdfRemoved');
				getStoreActions().coupons.details.load(getStoreState().coupons.details.data.id);
			});
	}),
	save: action((state, payload) => {
		state.results = payload.map(item => ({
			...item,
			isSelected: false
		}));
	}),
	toggleSelection: action((state, payload) => {
		const i = state.results.findIndex(itm => itm.id === payload);
		if (i === -1) return;
		const item = state.results[i];
		item.isSelected = !item.isSelected;
		state.results = state.results
			.slice(0, i)
			.concat(item)
			.concat(state.results.slice(i + 1));
	}),
	selectAll: action((state, payload) => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: true
		}));
	}),
	deselectAll: action((state, payload) => {
		state.results = state.results.map(item => ({
			...item,
			isSelected: false
		}));
	})
};
