import React, { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import RichTextEditor from 'react-rte';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import UserGroupsSelector from './components/UserGroupsSelector';
import utils from 'components/utils';
import TextEditor from 'components/TextEditor';
import styles from './styles';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';

const Send = ({ classes }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const loading = useStoreState(state => state.BOnotifications.send.loading);
	const modalOpen = useStoreState(state => state.BOnotifications.send.open);
	const setModalOpen = useStoreActions(dispatch => dispatch.BOnotifications.send.setOpen);
	const send = useStoreActions(dispatch => dispatch.BOnotifications.send.sendRequest);
	const [selected, setSelected] = useState([]);
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState(RichTextEditor.createEmptyValue());
	const toggleSelection = (id, checked) => {
		if (checked) {
			setSelected(selected.concat(id));
		} else {
			setSelected(selected.filter(s => s !== id));
		}
	};
	const onSend = _ => {
		send({
			title: subject,
			body: message.toString('html'),
			groupIds: selected
		});
	};
	return (
		<Paper sx={{ margin: theme.spacing(8), padding: theme.spacing(0, 2) }}>
			<Grid container spacing={2} justifyContent="space-between">
				<Grid container item>
					<Grid container item xs={12} justifyContent="flex-end" style={{ maxHeight: 24 }}>
						<IconButton
							size="small"
							style={{ marginTop: 5 }}
							// className={classes.noRipple}
							onClick={_ => {
								setModalOpen(!modalOpen);
							}}
						>
							<CloseIcon />
						</IconButton>
					</Grid>
					<Grid item xs={12}>
						<Typography color="inherit" variant="h4">
							Invia una notifica ai gruppi di utenti selezionati
						</Typography>
						<Divider classes={{ root: classes.dividerColor }} />
					</Grid>
				</Grid>
				<Grid item xs={12} md={6}>
					<UserGroupsSelector selected={selected} toggleSelection={toggleSelection} />
				</Grid>
				<Grid container item xs={12} md={6} alignContent="flex-start">
					<Grid item xs={12}>
						<Typography color="inherit" variant="caption">
							<em>
								L’utilizzo delle quadre <strong>[[]]</strong> è esclusivo per l’inserimento delle
								variabili
							</em>
						</Typography>
						<Divider classes={{ root: classes.dividerColor }} />
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={subject}
							onChange={e => {
								setSubject(e.target.value);
							}}
							fullWidth={true}
							label={'Soggetto'}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.title} color="inherit" variant="h6">
							{translate('sendNotification.message')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextEditor value={message} onChange={value => setMessage(value)} />
					</Grid>
					<Grid container item xs={12} justify="flex-end">
						{loading && <CircularProgress size={14} />}
						{!loading && (
							<Button
								disabled={selected.length === 0}
								variant="contained"
								color="primary"
								className={classes.buttonsMargin}
								onClick={onSend}
							>
								{translate('sendNotification.send')}
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles, { withTheme: true })(Send);
