import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles';

const DocumentsResume = ({ classes }) => {
	const loading = useStoreState(state => state.me.preview.loading);
	const data = useStoreState(state => state.me.preview.data);
	const load = useStoreActions(dispatch => dispatch.me.preview.load);
	// prettier-ignore
	useEffect(_ => { load(); }, [load]);
	return (
		<Grid container>
			{loading && (
				<Grid container item xs={12} style={{ marginTop: 8 }} justify="center">
					<CircularProgress size={24} />
				</Grid>
			)}
			{!loading && (
				<Grid container item xs={12} style={{ marginTop: 8, display: 'block', textAlign: 'left' }}>
					{data.lastBill && (
						<Typography variant="body2" component="p">
							<strong>Ultima bolla:</strong> {data.lastBill.date}
						</Typography>
					)}
					{!data.lastBill && (
						<Typography variant="body2" component="p">
							Al momento non ci sono bolle
						</Typography>
					)}
					{data.lastInvoice && (
						<Typography variant="body2" component="p">
							<strong>Ultima fattura:</strong> {data.lastInvoice.date}
						</Typography>
					)}
					{!data.lastInvoice && (
						<Typography variant="body2" component="p">
							Al momento non ci sono fatture
						</Typography>
					)}
					{data.lastCreditNote && (
						<Typography variant="body2" component="p">
							<strong>Ultima nota di credito:</strong> {data.lastCreditNote.date}
						</Typography>
					)}
					{!data.lastCreditNote && (
						<Typography variant="body2" component="p">
							Al momento non ci sono note di credito
						</Typography>
					)}
					{data.lastAccompanyingInvoice && (
						<Typography variant="body2" component="p">
							<strong>Ultima fattura accompagnatoria:</strong> {data.lastAccompanyingInvoice.date}
						</Typography>
					)}
					{!data.lastAccompanyingInvoice && (
						<Typography variant="body2" component="p">
							Al momento non ci sono fatture accompagnatorie
						</Typography>
					)}
				</Grid>
			)}
			<Grid container item xs={12} style={{ marginTop: 24 }} justify="center">
				<Button type="submit" variant="outlined" color="primary" component={Link} to="/documents">
					vedi tutti
				</Button>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(DocumentsResume);
