import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Link as MaterialLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import styles from './styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Search from '@mui/icons-material/Search';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HomeLink from './components/HomeLink';
import Promotions from './components/Promotions';

const HomeNoCMS = ({ classes }) => {
	const count = useStoreState(state => state.catalog.count.value);
	const loadCount = useStoreActions(dispatch => dispatch.catalog.count.load);
	const iCan = utils.usePermissions();
	useEffect(loadCount, [loadCount]);
	const formattedCount = Intl.NumberFormat('it-IT').format(count.quantity);
	return (
		<Grid container spacing={3}>
			<Grid xs={12} item className={classes.mainImage}>
				<Box component="div" m={1}>
					<Box component="div" m={1}>
						<Typography variant="h1" className={classes.h1Font}>
							Benvenuto nel nuovo Fastlink
						</Typography>
					</Box>
					<Box component="div" m={1}>
						<Typography variant="h2" className={classes.h2Font}>
							Tra tradizione e innovazione
						</Typography>
					</Box>
				</Box>
				<Box component="div" m={1} className={classes.counter}>
					<Typography align="right">I titoli disponibili nei nostri magazzini sono al momento:</Typography>
					<Typography align="right">
						<Box component="span" fontWeight="fontWeightBold" fontSize={24}>
							{count.quantity > 0 && formattedCount}
						</Box>
					</Typography>
				</Box>
			</Grid>
			<Grid xs={12} item>
				<Promotions />
			</Grid>
			<Grid xs={12} sm={3} item>
				<Card className={classes.heightBox}>
					<HomeLink
						text="Ricerca e ordina dal nostro catalogo"
						to="/catalog"
						linkStyle={classes.linkStyle}
						boxStyle={classes.centerBox}
						iconStyle={classes.fontSize}
						icon={<Search className={classes.fontSize} />}
						isEnabled={iCan('CATALOGS')}
					/>
				</Card>
			</Grid>
			<Grid xs={12} sm={3} item>
				<Card className={classes.heightBox}>
					<HomeLink
						text="Consulta il tuo portafoglio ordini"
						to="/orders/list"
						linkStyle={classes.linkStyle}
						boxStyle={classes.centerBox}
						iconStyle={classes.fontSize}
						icon={<ShoppingCart className={classes.fontSize} />}
						isEnabled={iCan('ORDERS')}
					/>
				</Card>
			</Grid>
			<Grid xs={12} sm={3} item>
				<Card className={classes.heightBox}>
					<HomeLink
						text="Visualizza e scarica i tuoi documenti contabili"
						to="/documents"
						linkStyle={classes.linkStyle}
						boxStyle={classes.centerBox}
						iconStyle={classes.fontSize}
						icon={<ListAltIcon className={classes.fontSize} />}
						isEnabled={iCan('DOCUMENTS')}
					/>
				</Card>
			</Grid>
			<Grid xs={12} sm={3} item>
				<Card className={classes.heightBox}>
					<CardContent className={classes.centerBox}>
						<Box>
							<MaterialLink href="https://www.facebook.com/fastbookspa.it" target="_blank">
								<FacebookIcon className={classes.fontSize} />
							</MaterialLink>
							<MaterialLink href="https://www.instagram.com/fastbook_italia/" target="_blank">
								<InstagramIcon className={classes.fontSize} />
							</MaterialLink>
							<MaterialLink href="mailto:assistenza.clienti@fastbookspa.it" target="_blank">
								<MailOutlineIcon className={classes.fontSize} />
							</MaterialLink>
						</Box>
						<Typography variant="h6">Vicini alla tua attività</Typography>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(HomeNoCMS);
