import { action, thunk } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: {},
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.order
			.getSingleHistory(payload)
			.then(data => actions.save({ ean: payload.ean, data }))
			.catch(e => {
				getStoreActions().error('catalog.detail');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	save: action((state, payload) => {
		state.data[payload.ean] = payload.data.items?.sort((i1, i2) => {
			let rt = 0;
			const name1 = i1?.clientDesc?.toUpperCase();
			const name2 = i2?.clientDesc?.toUpperCase();
			if (name1 < name2) {
				rt = -1;
			} else if (name1 > name2) {
				rt = 1;
			}

			return rt;
		});
	})
};
