const getRowColor = item => {
	if (item.expirationDate !== null && item.status !== 2) return 'red';
	switch (item.status) {
		case 1:
			return 'white';
		case 2:
			return 'yellow';
		case 3:
			return 'green';
		default:
			return 'white';
	}
};

export default getRowColor;
