import React from 'react';
import TableCell from '@mui/material/TableCell';
import utils from 'components/utils';

const defaultStyles = {
	paddingLeft: 4,
	paddingRight: 4
};

const NotSortable = props => {
	const { label, additionalStyles } = props;
	const translate = utils.useI18N();
	return (
		<TableCell align="left" style={{ ...defaultStyles, ...additionalStyles }}>
			<span className="MuiButtonBase-root" aria-disabled="true">
				{translate(label)}
			</span>
		</TableCell>
	);
};

export default NotSortable;
