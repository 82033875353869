import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Users from './Users';
import UsersDetails from './UsersDetails';
import WithdrawTypes from './WithdrawTypes';
import WithdrawalDetails from './WithdrawalDetails';
import ReturnRequests from './ReturnRequests';
import Promotions from './Promotions';
import PromotionsDetails from './PromotionsDetails';
import PromotionsAssign from './PromotionsAssign';
import Coupons from './Coupons';
import CouponsDetails from './CouponsDetails';
import CouponsAssign from './CouponsAssign';
import utils from 'components/utils';
import RankingsDetails from './RankingDetails';
import RankingsAssign from './RankingAssign';
import Rankings from './Rankings';
import PurchasingDetails from './PurchasingDetails';
import PurchasingCenters from './PurchasingCenters';
import PurchasingAssign from './PurchasingAssign';
import EditorialsDetails from './EditorialsDetails';
import EditorialsAssign from './EditorialsAssign';
import Editorials from './Editorials';
import SendNotification from './SendNotification';
import SpecialDelivery from './SpecialDelivery';
import DocumentTitle from 'react-document-title';
import UserGroups from './UserGroups';
import UserGroupsDetails from './UserGroupsDetails';
import UserGroupsAssign from './UserGroupsAssign';
import Health from './Health';

const Admin = _ => {
	const iCan = utils.usePermissions();
	const translate = utils.useI18N();
	return (
		<Switch>
			{iCan('MANAGE_USERS') && (
				<Route path="/admin/users" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.users')}>
						<Users />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_USERS') && (
				<Route path="/admin/users/:id" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.users')}>
						<UsersDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_GROUPS') && (
				<Route path="/admin/usergroups" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.userGroups')}>
						<UserGroups />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_GROUPS') && (
				<Route path="/admin/usergroups/usergroup/:id/:type" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.userGroups')}>
						<UserGroupsDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_GROUPS') && (
				<Route path="/admin/usergroups/:id/:type/assign" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.userGroups')}>
						<UserGroupsAssign />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_SPECIAL_SHIPMENTS') && (
				<Route path="/admin/withdraw-types" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.withdraw')}>
						<WithdrawTypes />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_SPECIAL_SHIPMENTS') && (
				<Route path="/admin/withdraw-types/:code" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.withdrawDetails')}>
						<WithdrawalDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_SPECIAL_SHIPMENTS') && (
				<Route path="/admin/withdraw-types/:code/:id" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.withdrawDetails')}>
						<WithdrawalDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_CALENDAR_RETURNS') && (
				<Route path="/admin/return-requests" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.returns')}>
						<ReturnRequests />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_PROMOTIONS') && (
				<Route path="/admin/promotions" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.promotions')}>
						<Promotions />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_PROMOTIONS') && (
				<Route path="/admin/promotions/:id" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.promotions')}>
						<PromotionsDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_PROMOTIONS') && (
				<Route path="/admin/promotions/:id/import" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.promotions')}>
						<PromotionsDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_PROMOTIONS') && (
				<Route path="/admin/promotions/:id/assign" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.promotions')}>
						<PromotionsAssign />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_COUPONS') && (
				<Route path="/admin/coupons" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.coupons')}>
						<Coupons />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_COUPONS') && (
				<Route path="/admin/coupons/:id" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.coupons')}>
						<CouponsDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_COUPONS') && (
				<Route path="/admin/coupons/:id/import" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.coupons')}>
						<CouponsDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_COUPONS') && (
				<Route path="/admin/coupons/:id/assign" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.coupons')}>
						<CouponsAssign />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_EDITORIAL_UNLOCK') && (
				<Route path="/admin/editorials" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.editorials')}>
						<Editorials />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_EDITORIAL_UNLOCK') && (
				<Route path="/admin/editorials/:id" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.editorials')}>
						<EditorialsDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_EDITORIAL_UNLOCK') && (
				<Route path="/admin/editorials/:id/import" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.editorials')}>
						<EditorialsDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_EDITORIAL_UNLOCK') && (
				<Route path="/admin/editorials/:id/assign" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.editorials')}>
						<EditorialsAssign />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_RANKING') && (
				<Route path="/admin/rankings" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.rankings')}>
						<Rankings />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_RANKING') && (
				<Route path="/admin/rankings/:id" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.rankings')}>
						<RankingsDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_RANKING') && (
				<Route path="/admin/rankings/:id/import" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.rankings')}>
						<RankingsDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_RANKING') && (
				<Route path="/admin/rankings/:id/assign" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.rankings')}>
						<RankingsAssign />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_CA') && (
				<Route path="/admin/purchasingcenters" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.purchasingGroups')}>
						<PurchasingCenters />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_CA') && (
				<Route path="/admin/purchasingcenters/search" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.purchasingGroups')}>
						<PurchasingCenters />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_CA') && (
				<Route path="/admin/purchasingcenters/add" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.purchasingGroups')}>
						<PurchasingCenters />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_CA') && (
				<Route path="/admin/purchasingcenters/purchase/:id" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.purchasingGroups')}>
						<PurchasingDetails />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_CA') && (
				<Route path="/admin/purchasingcenters/:id/assign" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.purchasingGroups')}>
						<PurchasingAssign />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_SPECIAL_DELIVERY') && (
				<Route path="/admin/special-delivery" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.specialDelivery')}>
						<SpecialDelivery />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_NOTIFICATIONS') && (
				<Route path="/admin/send-notification" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.sendNotification')}>
						<SendNotification />
					</DocumentTitle>
				</Route>
			)}
			{iCan('MANAGE_HEALTH') && (
				<Route path="/admin/fastlink-health" exact={true}>
					<DocumentTitle title={'Fastlink - ' + translate('common.menu.health')}>
						<Health />
					</DocumentTitle>
				</Route>
			)}
		</Switch>
	);
};

export default Admin;
