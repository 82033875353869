import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography, CircularProgress, TextField, Stack, Paper } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'components/utils';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		width: 800
	},
	modalLabels: {
		marginBottom: theme.spacing()
	}
});

const RejectModal = ({ classes, items, onClose, onReject }) => {
	const loading = useStoreState(state => state.conditionRequest.reject.loading);
	const [notes, setNotes] = useState('');
	const translate = utils.useI18N();
	const [textAreaCount, settextAreaCount] = React.useState(100);
	const recalculate = e => {
		const charCount = e.target.value.length;
		const charLeft = 100 - charCount;
		settextAreaCount(charLeft);
	};
	return (
		<Paper className={classes.root}>
			<Grid container justify="space-between" style={{ marginTop: 16 }}>
				<Grid item xs={12}>
					<Typography variant="titleSection">{translate('wishlist.rejectTitle')}</Typography>
				</Grid>
				{items.map(itm => (
					<Grid key={itm.id} item xs={12}>
						<Typography className={classes.modalLabels}>
							{itm.id} - {itm.wishlistName}: {itm.promotionName}
						</Typography>
					</Grid>
				))}
				<Grid item xs={12}>
					<TextField
						multiline
						maxRows={5}
						rows={3}
						maxLength={100}
						style={{ width: '100%', resize: 'none' }}
						aria-label="warning message"
						placeholder="note"
						defaultValue={notes}
						onChange={e => {
							setNotes(e.target.value);
							recalculate(e);
						}}
					/>
					<p>Inserire al massimo {textAreaCount} caratteri</p>
				</Grid>
				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ width: '100%' }}>
					<Button variant="light" endIcon={<CloseIcon />} onClick={onClose}>
						{translate('common.close')}
					</Button>
					<Button
						variant="dark"
						onClick={_ => onReject(notes)}
						endIcon={loading ? <CircularProgress size={16} /> : <SaveIcon />}
					>
						{translate('common.save')}
					</Button>
				</Stack>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles, { withTheme: true })(RejectModal);
