import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Box, Typography, Link, Button } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import utils from 'components/utils';

const SendFulfillment = props => {
	const { payload } = props;
	const theme = useTheme();
	const translate = utils.useI18N();
	const loadingEvasion = useStoreState(state => state.cart.evasion.loadingEvasion);
	const sendEvasion = useStoreActions(dispatch => dispatch.cart.evasion.sendEvasion);
	const sended = useStoreState(state => state.cart.evasion.sended);
	const paymentMethod = useStoreState(state => state.cart.evasion.paymentMethod);
	const refunded = useStoreState(state => state.stripe.refunded);

	useEffect(() => {
		//Se non ho fatto l'evasione la invio
		if (!sended && payload) {
			//Ripulisco la sessione per evitare di reinviare l'evasione con il refresh della pagina
			sessionStorage.clear();
			//Invio l'evasione
			sendEvasion(payload);
		}
	}, []);

	return (
		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={1}
			sx={{ width: '100%' }}
		>
			{loadingEvasion && (
				<Box sx={{ width: '100%', textAlign: 'center' }}>
					<Typography variant="titleTab">{translate('orders.evasion.sendingMessage')}</Typography>
				</Box>
			)}
			{!loadingEvasion && sended === 'KO' && (
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={1}
					sx={{ width: '100%' }}
				>
					<ErrorIcon sx={{ color: theme.palette.custom.red, fontSize: '120px' }} />
					<Typography variant="titlePage" sx={{ color: theme.palette.custom.red }}>
						{translate('orders.evasion.sendingKO')}
					</Typography>
					<Typography variant="titleSection">{translate('orders.evasion.sendingKOdetail')}</Typography>
					{paymentMethod == 2 && refunded && (
						<Typography variant="titleSection" sx={{ color: theme.palette.primary.main }}>
							{translate('orders.evasion.sendingKOpaymentRefund')}
						</Typography>
					)}
					{(paymentMethod != 2 || (paymentMethod == 2 && refunded)) && (
						<Button variant="dark" href="fulfillment" sx={{ marginTop: '16px !important' }}>
							{translate('orders.evasion.sendingKOretry')}
						</Button>
					)}
					{paymentMethod == 2 && (
						<Link href="support" sx={{ textAlign: 'center' }}>
							<Typography variant="titleSection" sx={{ color: theme.palette.primary.main }}>
								{translate('orders.evasion.sendingKOpayment')}
							</Typography>
						</Link>
					)}
				</Stack>
			)}
			{!loadingEvasion && sended === 'OK' && (
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={1}
					sx={{ width: '100%' }}
				>
					<TaskAltIcon sx={{ color: theme.palette.custom.green, fontSize: '120px' }} />
					<Typography
						variant="titlePage"
						sx={{ color: theme.palette.custom.green, textTransform: 'uppercase' }}
					>
						{translate('success.cart.sendEvasion')}
					</Typography>
					<Typography
						variant="titleTab"
						sx={{ color: theme.palette.custom.green, textTransform: 'uppercase', fontSize: '16px' }}
					>
						{translate('orders.evasion.orderNotUpdatableDesc')}
					</Typography>

					<Button
						variant="light"
						startIcon={<KeyboardDoubleArrowLeftIcon />}
						href="orders/list"
						sx={{ marginTop: '20px !important' }}
					>
						{translate('orders.evasion.goToOrders')}
					</Button>
				</Stack>
			)}
		</Stack>
	);
};

export default SendFulfillment;
