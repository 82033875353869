const returnCols = [
	'codeNumber',
	'client',
	'companyName',
	'city',
	'withdrawalService',
	'bundleNumber',
	'itemNumber',
	'weight',
	'amount',
	'date'
];

export default returnCols;
