import { useStoreState } from 'easy-peasy';
import usePersonification from './use-permissions';

const useType = _ => {
	const user = useStoreState(state => state.me.data);
	const personify = useStoreState(state => state.me.personify);
	const iAmPersonifying = personify.target.id !== '';
	const target = personify.target.id !== '' ? personify.target : null;
	const profile = iAmPersonifying ? target.profile : user.profile;

	return !profile
		? ''
		: profile.BO
		? 'BO'
		: profile.AGENT
		? 'AGENT'
		: profile.CLIENT && profile.CLIENT === 'STANDARD'
		? 'CLIENT'
		: profile.CLIENT && profile.CLIENT === 'PA'
		? 'CLIENT'
		: profile.WAREHOUSE_MANAGER
		? 'WAREHOUSE_MANAGER'
		: '';
};

export default useType;
