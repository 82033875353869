import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import styles from './styles';
import logo from './fastbook-logo.png';

const BookDetails = props => {
	const { classes } = props;
	const translate = utils.useI18N();
	const details = useStoreState(state => state.catalog.details.data);
	const load = useStoreActions(dispatch => dispatch.catalog.details.load);
	const params = useParams();
	const [authorfontSize, setAuthorFontSize] = useState(26);
	const [titlefontSize, setTitleFontSize] = useState(28);
	const [bodyfontSize, setBodyFontSize] = useState(14);
	useEffect(
		_ => {
			if (params.ean) load(params.ean);
		},
		[load, params]
	);
	return (
		<Fragment>
			<Grid container className={classes.noPrint}>
				<Grid container item xs={6}>
					<IconButton
						className={classes.fontButtons}
						color="primary"
						aria-label="decrease font size"
						onClick={_ => {
							setAuthorFontSize(authorfontSize - 1);
							setTitleFontSize(titlefontSize - 1);
							setBodyFontSize(bodyfontSize - 1);
						}}
					>
						<RemoveCircleRoundedIcon />
					</IconButton>
					<IconButton
						className={classes.fontButtons}
						color="primary"
						aria-label="increase font size"
						onClick={_ => {
							setAuthorFontSize(authorfontSize + 1);
							setTitleFontSize(titlefontSize + 1);
							setBodyFontSize(bodyfontSize + 1);
						}}
					>
						<AddCircleOutlineRoundedIcon />
					</IconButton>
				</Grid>
				<Grid container item xs={6} justify="flex-end">
					<Button
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						onClick={_ => window.print()}
						startIcon={<PrintIcon />}
					>
						print / save
					</Button>
				</Grid>
			</Grid>
			{details && (
				<Container id="bookprint" className={classes.root}>
					<Grid container>
						<Grid item xs={12}>
							<img src={logo} alt="Fastbook Logo" title="Fastbook Logo" />
						</Grid>
						<Grid item xs={12} className={classes.title}>
							<Typography variant="h5" style={{ fontSize: titlefontSize, color: '#999' }}>
								{utils.capitalizeAll(details.author)}
							</Typography>
							<Typography variant="h3" style={{ fontSize: titlefontSize }}>
								{utils.capitalizeAll(details.title)}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<img className={classes.coverImg} src={details.coverImageURL} alt={details.title} />
						</Grid>
						<Grid item xs={8} className={classes.infoContainer} style={{ fontSize: bodyfontSize }}>
							<p style={{ marginTop: 0 }}>
								<strong>{translate('catalog.details.infoBox.serie')}: </strong>
								<br />
								{utils.capitalize(details.serie)}
							</p>
							<p>
								<strong>{translate('catalog.details.infoBox.publisher')}: </strong>
								<br />
								{utils.capitalize(details.publisher)}
							</p>
							<p>
								<strong>{translate('catalog.details.infoBox.genre')}: </strong>
								<br />
								{utils.capitalize(details.genre)}
							</p>
							<p>
								<strong>{translate('catalog.details.infoBox.ean')}: </strong>
								<br />
								{utils.capitalize(details.ean)}
							</p>
							<p>
								<strong>{translate('catalog.details.infoBox.publicationDate')}: </strong>
								<br />
								{moment(details.publicationDate).format('L')}
							</p>
							<p>
								<strong>{translate('catalog.details.infoBox.synopsis')}: </strong>
								<br />
								{details.synopsis}
							</p>
						</Grid>
					</Grid>
				</Container>
			)}
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(BookDetails);
