import { action, thunk } from 'easy-peasy';

export default {
	loading: -1,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	sendDelete: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(payload);
		return services.cart
			.remove(payload)
			.then(data => {
				//getStoreActions().success('cart.delete');
				return Promise.resolve(data);
			})
			.catch(e => {
				getStoreActions().error('cart.delete');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	})
};
