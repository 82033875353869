import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import utils from 'components/utils';
import ResetButton from './ResetButton';

const styles = theme => ({
	modalLabels: {
		marginBottom: theme.spacing()
	}
});

const NotOrdinableItems = ({ classes }) => {
	const items = useStoreState(state => state.importt.notOrdinable);
	const stepBack = useStoreActions(dispatch => dispatch.importt.stepBack);
	const setActiveStep = useStoreActions(dispatch => dispatch.importt.setActiveStep);
	const translate = utils.useI18N();
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="titleSection">{translate('import.notOrdinablesTitle')}</Typography>
			</Grid>
			{items.map((item, i) => (
				<Grid item xs={12} sm={6} md={4} lg={3} key={item.title + i} className={classes.modalLabels}>
					<Typography>
						<strong style={{ textTransform: 'uppercase' }}>{item.title}</strong> ({item.ean})
					</Typography>
					<Typography variant="caption">{item.newsItem}</Typography>
				</Grid>
			))}
			<Grid item xs={12} container justify="center" style={{ marginTop: 16 }}>
				<Grid item xs={6}>
					<Button variant="light" onClick={_ => setActiveStep(4)}>
						{translate('common.continue')}
					</Button>
				</Grid>
				<Grid item container xs={6} justify="flex-end">
					<ResetButton stepBack={stepBack} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(NotOrdinableItems);
