import React, { Fragment } from 'react';
import { useStoreState } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Card from 'components/Card';
import utils from 'components/utils';
import styles from './styles';
import AssignUsers from './components/AssignUsers';
import Alert from '@mui/lab/Alert';
import ItemsTable from './components/Table/ItemsTable';
import Import from './components/Import';
import Attributes from './components/Attributes';
import BackToButton from 'components/BackToButton';

const PurchasingDetails = ({ classes }) => {
	const translate = utils.useI18N();
	const data = useStoreState(state => state.purchasings.detailsItems.data);
	const details = useStoreState(state => state.purchasings.details.data);
	const errorImportUsers = useStoreState(state => state.purchasings.importt.errorImportUsers);

	return (
		<Fragment>
			<Card type="primary" title={details ? details.name : 'purchasings.title'}>
				<Grid item container xs={12} className={classes.buttonsAlignment}>
					<AssignUsers currentCa={details ? details.id : ''} />
					<Import sapId={details ? details.id : ''} />
					<BackToButton
						to={'/admin/purchasingcenters'}
						style={{ margin: '0 1 0 0' }}
						label={'backToPurchasingCenters'}
					/>
				</Grid>
				{errorImportUsers &&
					errorImportUsers.map((item, index) => (
						<Grid key={index} item container xs={12} style={{ margin: '8px 0px 0px 0px' }}>
							<Alert severity="error">
								<span>{translate('purchasings.errorImport') + ' : ' + item}</span>
							</Alert>
						</Grid>
					))}
				<Grid item container xs={12}>
					{data && (
						<Fragment>
							<Attributes details={details} />
							<ItemsTable />
						</Fragment>
					)}
				</Grid>
			</Card>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(PurchasingDetails);
