import React from 'react';
import TableHeadMUI from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import allCols from './cols';
import withColumns from '../columns';

const TableHead = ({ visibleCols }) => {
	const cells = visibleCols.map(col => allCols.find(c => c.id === col));
	return (
		<TableHeadMUI>
			<TableRow style={{ height: 40 }}>{cells.map(cell => cell.comp())}</TableRow>
		</TableHeadMUI>
	);
};

export default withColumns(TableHead);
