import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import moment from 'moment';

const CreationDateComp = ({ creationDate }) => {
	return <StyledTableCell>{moment(creationDate).format('L')}</StyledTableCell>;
};

const CreationDate = memo(
	({ item }) => <CreationDateComp creationDate={item.creationDate} />,
	(p, n) => p.item.creationDate === n.item.creationDate
);

export default CreationDate;
