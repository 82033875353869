import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const CoverComp = ({ ean, title, thumbnailURL }) => {
	return (
		<StyledTableCell key={`bodyCell_cover_${ean}`} style={{ width: 45 }}>
			{thumbnailURL && (
				<img
					src={thumbnailURL}
					style={{ width: 40, height: 60, objectFit: 'cover', paddingTop: 5 }}
					alt={title}
				/>
			)}
		</StyledTableCell>
	);
};

const Cover = memo(
	({ item }) => <CoverComp ean={item.ean} title={item.title} thumbnailURL={item.thumbnailURL} />,
	(p, n) => p.item.title === n.item.title && p.item.ean === n.item.ean && p.item.thumbnailURL === n.item.thumbnailURL
);

export default Cover;
