import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import utils from 'components/utils';

const AuthorComp = ({ author }) => {
	return <StyledTableCell>{utils.capitalizeAll(author)}</StyledTableCell>;
};

const Author = memo(
	({ item }) => <AuthorComp author={item.author} />,
	(p, n) => p.item.author === n.item.author
);
export default Author;
