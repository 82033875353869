import React, { useState, useEffect, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TreeItem from '@mui/lab/TreeItem';
import utils from 'components/utils';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalPaper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '75%',
		height: 600
	},
	treeContainer: {
		height: 500,
		overflowY: 'scroll'
	}
});

const Label = ({ item }) => {
	const toggleSelection = useStoreActions(dispatch => dispatch.catalog.dewey.toggleSelection);
	const onClick = e => {
		toggleSelection(item.id);
		e.preventDefault();
		e.stopPropagation();
	};
	return (
		<Typography>
			{item.selected && (
				<CheckCircleIcon onClick={onClick} fontSize="small" style={{ paddingTop: 8, color: 'green' }} />
			)}
			{!item.selected && (
				<CheckCircleIcon onClick={onClick} fontSize="small" style={{ paddingTop: 8, color: 'red' }} />
			)}
			{item.name}
		</Typography>
	);
};

const Leaf = ({ item }) => <TreeItem nodeId={item.id.toString()} label={<Label item={item} />} />;

const Branch = ({ item }) => (
	<TreeItem nodeId={item.id.toString()} label={<Label item={item} />}>
		{item.children.map((item, i) => (
			<DeweyItem key={`${item.id}_${i}`} item={item} />
		))}
	</TreeItem>
);

const DeweyItem = ({ item }) => (
	<Fragment>{item.children && item.children.length > 0 ? <Branch item={item} /> : <Leaf item={item} />}</Fragment>
);

const DeweyModal = withStyles(styles, { withTheme: true })(({ classes, onClose }) => {
	const items = useStoreState(state => state.catalog.dewey.items);
	const load = useStoreActions(dispatch => dispatch.catalog.dewey.load);
	const deselectAll = useStoreActions(dispatch => dispatch.catalog.dewey.deselectAll);
	const apply = useStoreActions(dispatch => dispatch.catalog.filters.applyDeweyFilters);
	useEffect(load, [load]);
	const translate = utils.useI18N();
	const onApply = _ => {
		apply(items);
		onClose && onClose();
	};
	return (
		<Paper sx={{ padding: '10px' }}>
			<Grid container>
				<Grid item xs={12} className={classes.treeContainer}>
					<TreeView
						className={classes.root}
						defaultCollapseIcon={<ExpandMoreIcon />}
						defaultExpandIcon={<ChevronRightIcon />}
					>
						{items.map((item, i) => (
							<DeweyItem key={`${item.id}_${i}`} item={item} />
						))}
					</TreeView>
				</Grid>
				<Grid item xs={6} style={{ marginTop: 16 }}>
					<Button
						onClick={onClose}
						variant="outlined"
						color="primary"
						type="submit"
						style={{ marginRight: 8 }}
					>
						{translate('common.close')}
					</Button>
					<Button onClick={_ => deselectAll()} variant="outlined" color="primary" type="submit">
						deseleziona tutti
					</Button>
				</Grid>
				<Grid container item xs={6} justify="flex-end" style={{ marginTop: 16 }}>
					<Button onClick={onApply} variant="contained" color="primary" type="submit">
						{translate('common.apply')}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
});

const Dewey = ({ classes }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const deweyCount = useStoreState(state => state.catalog.filters.deweyCount);
	const getLabel = _ => {
		if (deweyCount === 0) return 'dewey';
		if (deweyCount > 0) return `dewey (${deweyCount} applicati)`;
	};
	return (
		<Fragment>
			<Modal open={modalOpen} closeAfterTransition className={classes.modal} onClose={_ => setModalOpen(false)}>
				<DeweyModal onClose={_ => setModalOpen(false)} />
			</Modal>
			<Button onClick={_ => setModalOpen(true)} color="primary" variant="contained">
				{getLabel()}
			</Button>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(Dewey);
