import list from './list';
import listAssignment from './list-assignment';
import getDetailsAssignment from './get-details-assignment';
import createUserGroup from './create-usergroup';
import deleteUserGroups from './delete-usegroup';
import updateUserGroup from './update-usergroup';
import getDetailsQuery from './get-details-query';
import deleteUser from './delete-user';
import assignToUserGroup from './assign-usergroup';
import deleteAllUsers from './delete-all-users';
import importUsers from './import';

export default {
	assignToUserGroup,
	createUserGroup,
	deleteUserGroups,
	getDetailsAssignment,
	deleteUser,
	getDetailsQuery,
	updateUserGroup,
	list,
	listAssignment,
	deleteAllUsers,
	importUsers
};
