import k from 'k';
import utils from 'services/utils';

export default ({ proposalId, pdfOption }) => {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(pdfOption),
		headers: utils.createHeaders(),
		responseType: 'blob'
	};
	return utils
		.fetch(
			`${k.endpoints.apis}/backoffice/catalog-groups/proposals/export/pdf?groupId=${proposalId}`,
			requestOptions
		)
		.then(response => response.blob())
		.then(response => {
			const file = new Blob([response], { type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			console.log(error);
		});
};
