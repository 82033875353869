import React, { Fragment, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/lab/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';
import styles from './styles';

const SearchBar = ({ classes }) => {
	const translate = utils.useI18N();
	const loading = useStoreState(state => state.catalog.autocomplete.loading);
	const open = useStoreState(state => state.catalog.autocomplete.open);
	const token = useStoreState(state => state.rankings.assign.token);
	const realm = useStoreState(state => state.catalog.autocomplete.realm);
	const load = useStoreActions(dispatch => dispatch.catalog.autocomplete.load);
	const setOpen = useStoreActions(dispatch => dispatch.catalog.autocomplete.setOpen);
	const list = useStoreState(state => state.catalog.autocomplete.list);
	const setToken = useStoreActions(dispatch => dispatch.rankings.assign.setToken);
	const setRealm = useStoreActions(dispatch => dispatch.catalog.autocomplete.setRealm);
	const [mustLoadAC, setMustLoadAC] = useState(false);
	const debouncedLoad = useCallback(
		debounce(_ => {
			load();
		}, 400),
		[]
	);
	useEffect(
		_ => {
			if (realm === 'token' && list && list.length > 0) setOpen('token');
		},
		[list, setOpen, realm]
	);
	useEffect(
		_ => {
			if (mustLoadAC) {
				debouncedLoad();
			}
		},
		[token, debouncedLoad, mustLoadAC]
	);
	const onInputChange = (event, value, reason) => {
		if (realm !== 'token') setRealm('token');
		if (reason !== 'reset') {
			if (!mustLoadAC) setMustLoadAC(true);
			setToken(value);
		}
	};
	const onChange = (event, value) => {
		setMustLoadAC(false);
		setToken(value);
		setOpen(null);
	};
	const onClose = (event, reason) => {
		if (reason === 'blur') setOpen(null);
	};
	const showLoader = loading && realm === 'token';
	return (
		<Autocomplete
			id="catalog-search-autocomplete"
			style={{ width: '100%' }}
			freeSolo
			disableClearable
			open={open === 'token'}
			inputValue={token}
			options={list}
			loading={showLoader}
			onChange={onChange}
			onInputChange={onInputChange}
			onClose={onClose}
			renderInput={params => (
				<TextField
					{...params}
					label=""
					size="small"
					placeholder={translate('common.search.placeholder')}
					classes={{ root: classes.autocompleteInput }}
					InputProps={{
						...params.InputProps,
						startAdornment: <SearchIcon />,
						endAdornment: (
							<Fragment>
								{showLoader ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</Fragment>
						)
						// disableUnderline: true
					}}
				/>
			)}
			renderOption={(option, { inputValue }) => {
				const matches = match(option, inputValue);
				const parts = parse(option, matches);
				return (
					<div>
						{parts.map((part, index) => (
							<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
								{part.text}
							</span>
						))}
					</div>
				);
			}}
		/>
	);
};

export default withStyles(styles, { withTheme: true })(SearchBar);
