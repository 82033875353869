import k from 'k';
import utils from 'services/utils';
import moment from 'moment';

export default ({ filters }) => {
	const requestOptions = {
		method: 'GET',
		headers: utils.createHeaders()
	};
	const params = {
		page: 0,
		rows: 2000
	};
	if (filters.token) params.keyword = filters.token;
	if (filters.status) params.enabledCatalog = filters.statusValue;
	if (filters.statusWishlist) params.enabledWishlist = filters.statusWishlistValue;
	if (filters.dateFrom) params.dateFrom = filters.dateFrom.format('YYYY-MM-DD');
	if (filters.dateTo) params.dateTo = filters.dateTo.format('YYYY-MM-DD');

	const qParams = utils.createQueryParams(params, false);
	return utils
		.fetch(`${k.endpoints.apis}/backoffice/catalog-groups?type=PROMOTION&${qParams}`, requestOptions)
		.then(response => utils.handleResponse({ response }));
};
