export default theme => ({
	root: {
		display: 'flex'
	},
	noRipple: {
		'&:hover': {
			background: 'none'
		}
	},
	buttonsAlignment: {
		margin: theme.spacing(1, 0),
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center'
		}
	},
	elements: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	dividerAria: {
		margin: theme.spacing(2, 2)
	}
});
