import React, { Fragment, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Button, Popover, Typography, CircularProgress, Box, Stack, IconButton } from '@mui/material';
import utils from 'components/utils';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteButton = ({ id, name, classes }) => {
	const translate = utils.useI18N();
	const theme = useTheme();
	const loading = useStoreState(state => state.wishlist.remove.loading);
	const remove = useStoreActions(dispatch => dispatch.wishlist.remove.sendDelete);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const onClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const onClose = _ => {
		setAnchorEl(null);
	};

	const onConfirm = _ => {
		remove(id);
	};

	return (
		<Fragment>
			<Button variant="important" onClick={onClick}>
				{translate('wishlist.delete')}
			</Button>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={onClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<Stack
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={0}
					sx={{ width: '100%', padding: '10px' }}
				>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={2}
						sx={{ width: '100%' }}
					>
						<Box sx={{ width: '90%' }}>
							<Typography
								variant="titleSection"
								sx={{ color: theme.palette.primary.main, textTransform: 'capitalize' }}
							>
								{translate('wishlist.delete')}
							</Typography>
						</Box>
						<Box sx={{ width: '10%' }}>
							<IconButton onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</Box>
					</Stack>
					<Stack
						direction="column"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={1}
						sx={{
							width: '100%',
							borderWidth: '1px 0 0 0',
							borderStyle: 'solid',
							borderColor: theme.palette.primary.main,
							paddingTop: '10px',
							marginBottom: '15px'
						}}
					>
						<Typography variant="text" sx={{ fontWeight: '600' }}>
							{translate('wishlist.confirmRemove')}
						</Typography>
						<Typography variant="text">{name}</Typography>
					</Stack>
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={2}
						sx={{ width: '100%', marginTop: '10px !important' }}
					>
						<Button variant="light" endIcon={<CloseIcon />} onClick={onClose}>
							{translate('common.close')}
						</Button>
						<Button
							variant="important"
							onClick={onConfirm}
							endIcon={
								loading ? (
									<CircularProgress size={24} sx={{ color: theme.palette.custom.white }} />
								) : (
									<DeleteIcon sx={{ color: theme.palette.custom.white }} />
								)
							}
						>
							{translate('common.confirm')}
						</Button>
					</Stack>
				</Stack>
			</Popover>
		</Fragment>
	);
};

export default DeleteButton;
