import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import utils from 'components/utils';

const styles = theme => ({
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1, 0, 0)
		},
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1, 0, 0, 0),
			width: '100%'
		}
	}
});

const NewRequestButton = ({ classes }) => {
	const translate = utils.useI18N();
	const requestOpen = useStoreState(state => state.returns.requestOpen);
	const dayList = useStoreState(state => state.returns.calendar.items);
	const setRequestOpen = useStoreActions(dispatch => dispatch.returns.setRequestOpen);
	const setDetailOpen = useStoreActions(dispatch => dispatch.returns.setDetailOpen);
	const getDays = useStoreActions(dispatch => dispatch.returns.calendar.getDays);
	const [isAvailable, setIsAvailable] = useState(false);
	useEffect(
		_ => {
			const now = moment();
			getDays({ month: now.month() + 1, year: now.year() });
		},
		[getDays]
	);
	useEffect(
		_ => {
			const now = moment();
			const formattedDay = now.format('DD/MM/YYYY');
			const d = dayList.find(item => item.day === formattedDay);
			setIsAvailable(d && d.available);
		},
		[dayList]
	);
	return (
		<Button
			variant="dark"
			disabled={!isAvailable}
			onClick={_ => {
				setDetailOpen(false);
				setRequestOpen(!requestOpen);
			}}
		>
			{translate('returns.request')}
		</Button>
	);
};

export default withStyles(styles, { withTheme: true })(NewRequestButton);
