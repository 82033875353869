export default theme => ({
	noResults: {
		maxWidth: '400px',
		maxHeight: 130
	},
	cardMessageContent: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	}
});
