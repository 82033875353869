import React, { Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
	Stack,
	Typography,
	IconButton,
	MenuItem,
	useMediaQuery,
	Slide,
	Popover,
	Grid,
	Box,
	Icon,
	Backdrop,
	CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Notification from './Components/Notification';
import BudgetPreview from './Components/BudgetPreview';
import Profile from './Components/Profile';
import { useTheme } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Logout from './../../../routes/Logout';
import Avatar from 'components/Avatar';
import { grey } from '@mui/material/colors';
import { Person, Logout, Notifications } from '@mui/icons-material';
import utils from 'components/utils';
import globalUtils from 'utils';

const Header = props => {
	const iCan = utils.usePermissions();
	const pathLogo = process.env.PUBLIC_URL + '/Logo.png';
	const translate = utils.useI18N();
	const history = useHistory();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [iAmPersonifying] = utils.usePersonification();
	const user = useStoreState(state => state.me.data);
	const waitImpersonification = useStoreState(state => state.me.personify.waitImpersonification);
	const setWaitImpersonification = useStoreActions(dispatch => dispatch.me.personify.setWaitImpersonification);
	const MESSAGE_HEADER = process.env.REACT_APP_MESSAGE_HEADER; //Messaggio in preprod
	const personifiedName = utils.useUserData('displayName', null);
	const personifiedId = utils.useUserData('id', null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const style = {
		// main color
		blueColorMainObject: theme.palette.primary.main,
		iconStyle: {
			color: grey[700],
			marginLeft: '5px'
		},
		// popover style
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'center'
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'right'
		},
		elevation: 3
	};

	useEffect(() => {
		//Se il parametri di completamento impersonificazione è ancora attivo dopo 3 secondi lo disabilito
		//Utile nel caso di refresh forzato dall'utente che manterebbe il backdrop aperto
		if (waitImpersonification) setTimeout(completeImpersonification, 3000);
	}, []);

	const completeImpersonification = _ => {
		//Imposto il parametri di attesa completamento impersonificazione a false per chiudere il backdrop
		setWaitImpersonification(false);
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const onExitClick = _ => {
		setAnchorEl(null);
		globalUtils.logout();
	};

	const allMenuItems = [
		{
			id: 'notification',
			label: translate('titles.notifications'),
			icon: <Notifications />,
			to: '/notifications',
			onClick: null
		},
		{
			id: 'profile',
			label: translate('common.menu.profile'),
			icon: <Person />,
			to: '/me',
			onClick: null
		},
		{
			id: 'logout',
			label: translate('common.menu.logout'),
			icon: <Logout />,
			onClick: onExitClick
		}
	];

	const PersonificationPreview = _ => {
		return (
			<Stack direction="column" justifyContent="center" alignItems="center">
				<Typography variant="titleSection" sx={{ color: theme.palette.secondary.main, fontSize: '12px' }}>
					{translate('common.youArePersonifying')}
				</Typography>
				<Typography
					variant="titleSection"
					sx={{ color: grey[800], fontSize: '14px' }}
				>{`${personifiedName} (${personifiedId})`}</Typography>
			</Stack>
		);
	};

	return (
		<Fragment>
			<Backdrop open={waitImpersonification} sx={{ zIndex: 2 }}>
				<CircularProgress sx={{ color: theme.palette.primary.main }} size={60} />
			</Backdrop>

			<img src={pathLogo} style={{ width: '140px' }} onClick={_ => window.open('/', '_parent')} />

			{/** ---------- DESKTOP ---------- */}
			{!isMobile && (
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems={'center'}
					spacing={2}
					sx={{
						width: '100%'
					}}
				>
					{/* Messaggio di test solo in preprod */}
					{MESSAGE_HEADER && MESSAGE_HEADER !== '' && (
						<Box
							sx={{
								textAlign: 'center',
								width: '50%',
								backgroundColor: theme.palette.secondary.main
							}}
						>
							<Typography
								variant="titleSection"
								sx={{ color: theme.palette.custom.white, textTransform: 'uppercase' }}
							>
								{MESSAGE_HEADER}
							</Typography>
						</Box>
					)}

					{iAmPersonifying && <PersonificationPreview />}
					{iCan('BUDGET') && <BudgetPreview style={style} />}
					<Notification style={style} />
					<Profile style={style} />
				</Stack>
			)}

			{/** ---------- MOBILE ---------- */}
			{isMobile && (
				<Fragment>
					<IconButton
						onClick={handleClick}
						sx={{
							border: iAmPersonifying && `2px solid ${theme.palette.secondary.main}`,
							color: theme.palette.primary.main
						}}
					>
						<MoreVertIcon />
					</IconButton>
					<Popover
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						TransitionComponent={Slide}
						anchorOrigin={{
							vertical: style.anchorOrigin.vertical,
							horizontal: style.anchorOrigin.horizontal
						}}
						transformOrigin={{
							vertical: style.transformOrigin.vertical,
							horizontal: style.transformOrigin.horizontal
						}}
					>
						<Box sx={{ width: '250px', padding: '10px' }}>
							<Grid container sx={{ marginBottom: '10px' }}>
								<Grid item xs={2}>
									<Avatar size={40} forceLogged={true} />
								</Grid>
								<Grid item xs={10}>
									<Stack
										direction={'column'}
										justifyContent={'flex-start'}
										sx={{ width: '100%', marginLeft: '10px' }}
									>
										<Typography variant="titleSection">
											{`${utils.capitalizeAll(user.displayName)} (${utils.capitalizeAll(
												user.id
											)})`}
										</Typography>
										<Typography variant="text">
											{utils.capitalizeAll(user.profileDescription)}
										</Typography>
									</Stack>
								</Grid>
							</Grid>

							{iAmPersonifying && (
								<Box
									sx={{
										border: `2px solid ${theme.palette.secondary.main}`,
										borderRadius: '10px',
										margin: '10px 0',
										paddingTop: '10px',
										paddingBottom: '10px',
										marginTop: '10px',
										marginBottom: '10px'
									}}
								>
									<Stack direction={'column'} justifyContent="center" alignItems={'center'}>
										<Avatar size={40} />
										<Typography
											variant="titleSection"
											sx={{ color: grey[800], fontSize: '14px' }}
										>{`${personifiedName} (${personifiedId})`}</Typography>
									</Stack>
								</Box>
							)}

							{allMenuItems.map((menuItem, index) => (
								<MenuItem
									key={index}
									component={menuItem.to && Link}
									to={menuItem.to}
									onClick={menuItem.onClick && (_ => menuItem.onClick())}
									sx={{
										width: '250px',
										marginLeft: '-10px',
										borderTop: index === allMenuItems.length - 1 && `1px solid ${grey[300]}`
									}}
								>
									<Icon sx={{ color: style.iconStyle.color }}>{menuItem.icon}</Icon>
									<span style={{ marginLeft: style.iconStyle.marginLeft }} />
									<Typography sx={{ marginTop: '5px', fontSize: '14px' }}>
										{menuItem.label}
									</Typography>
								</MenuItem>
							))}
						</Box>
					</Popover>
				</Fragment>
			)}
		</Fragment>
	);
};

export default Header;
