import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';
import { green, red } from '@mui/material/colors';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const ApprovalRequestComp = ({ requiredApproval }) => {
	return (
		<StyledTableCell>
			{requiredApproval ? (
				<CheckCircleOutlineRoundedIcon fontSize="small" style={{ color: green[300] }} />
			) : (
				<HighlightOffRoundedIcon fontSize="small" style={{ color: red[300] }} />
			)}
		</StyledTableCell>
	);
};

const ApprovalRequest = memo(
	({ item }) => <ApprovalRequestComp requiredApproval={item.requiredApproval} />,
	(p, n) => p.item.requiredApproval === n.item.requiredApproval
);

export default ApprovalRequest;
