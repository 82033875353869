import { action } from 'easy-peasy';

export default {
	mainOpen: false,
	toggleMainOpen: action((state, payload) => {
		state.mainOpen = !state.mainOpen;
	}),
	openMain: action((state, payload) => {
		state.mainOpen = true;
	}),
	adminOpen: false,
	toggleAdminOpen: action((state, payload) => {
		state.adminOpen = !state.adminOpen;
	}),
	closeAdmin: action((state, payload) => {
		state.adminOpen = false;
	})
};
