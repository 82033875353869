import React, { useEffect, Fragment } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { ToggleButtonGroup, ToggleButton, Stack, Box, Typography, LinearProgress } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import AdjustIcon from '@mui/icons-material/Adjust';
import utils from 'components/utils';

const TabBar = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const selectedTab = useStoreState(state => state.catalog.thema.selectedTab);
	const setSelectedTab = useStoreActions(dispatch => dispatch.catalog.thema.setSelectedTab);
	const colorSelected = theme.palette.primary.main;
	const colorNotSelected = theme.palette.custom.greyborder;

	const handleTab = (event, tab) => {
		setSelectedTab(tab);
	};

	const listTab = [
		{
			tab: 0,
			label: translate('catalog.advancedSearchCardTitle'),
			count: null,
			loading: false
		},
		{
			tab: 1,
			label: translate('catalog.thematicaTitle'),
			count: null,
			loading: false
		},
		{
			tab: 2,
			label: translate('catalog.themaScopeTitle'),
			count: null,
			loading: false
		}
	];

	return (
		<Stack
			direction={{ xs: 'column', md: 'row', sm: 'row' }}
			justifyContent="flex-stat"
			alignItems="center"
			spacing={1}
			variant="wrapperColor"
			sx={{ padding: { xs: '10px 0 !important', md: '10px 20px !important', sm: '10px !important' } }}
		>
			<Box sx={{ width: { xs: '100%', md: '80%', sm: '100%' } }}>
				<ToggleButtonGroup value={selectedTab} exclusive onChange={handleTab}>
					{listTab.map(item => (
						<ToggleButton
							key={item.tab}
							variant="tab"
							value={item.tab}
							disabled={item.loading || selectedTab == item.tab}
						>
							{selectedTab == item.tab && (
								<AdjustIcon
									sx={{
										color: !item.loading ? colorSelected : colorNotSelected,
										fontSize: { xs: '20px', md: '24px', sm: '24px' }
									}}
								/>
							)}
							{selectedTab != item.tab && (
								<Brightness1Icon
									sx={{ color: colorNotSelected, fontSize: { xs: '20px', md: '24px', sm: '24px' } }}
								/>
							)}
							<Stack
								direction={{ xs: 'column', md: 'row', sm: 'row' }}
								spacing={0}
								justifyContent="flex-start"
								alignItems="flex-start"
							>
								<Typography
									variant="titleTab"
									sx={{ color: selectedTab == item.tab ? colorSelected : theme.palette.custom.black }}
								>
									{item.label}
								</Typography>
							</Stack>
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Box>
		</Stack>
	);
};

export default TabBar;
