export default theme => ({
	pageMenu: {
		paddingLeft: theme.spacing(1),
		flexGrow: 1,
		color: 'grey',
		fontSize: 15
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar
	},
	listItem: {
		paddingLeft: theme.spacing(2)
	},
	listIcon: {
		minWidth: 40
	},
	subMenuItems: {
		paddingLeft: theme.spacing(2)
	},
	content: {
		width: 690,
		margin: 'auto',
		transition: '0.3s',
		boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
		'&:hover': {
			boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
			width: '100%'
		}
	},
	contentSmall: {
		width: 480,
		margin: 'auto',
		padding: '10px !important',
		transition: '0.3s',
		boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
		'&:hover': {
			boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
			width: '100%'
		}
	}
});
