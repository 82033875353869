import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const RefCodeComp = ({ name }) => {
	return <StyledTableCell>{name}</StyledTableCell>;
};

const RefCode = memo(
	({ item }) => <RefCodeComp name={item.referentCode} />,
	(p, n) => p.item.referentCode === n.item.referentCode
);

export default RefCode;
