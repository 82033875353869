import React from 'react';
import CodeNumber from './CodeNumber';
import WithdrawalService from './WithdrawalService';
import BundleNumber from './BundleNumber';
import ItemNumber from './ItemNumber';
import Weight from './Weight';
import Amount from './Amount';
import Client from './Client';
import Datee from './Date';
import CompanyName from './CompanyName';
import City from './City';

const allCells = [
	{
		id: 'codeNumber',
		comp: ({ item, type }) => <CodeNumber key={`bodyCell_codeNumber_${item.id}`} item={item} type={type} />
	},
	{
		id: 'withdrawalService',
		comp: ({ item }) => <WithdrawalService key={`bodyCell_withdrawalService_${item.id}`} item={item} />
	},
	{
		id: 'bundleNumber',
		comp: ({ item }) => <BundleNumber key={`bodyCell_bundleNumber_${item.id}`} item={item} />
	},
	{
		id: 'itemNumber',
		comp: ({ item }) => <ItemNumber key={`bodyCell_itemNumber_${item.id}`} item={item} />
	},
	{
		id: 'weight',
		comp: ({ item }) => <Weight key={`bodyCell_weight_${item.id}`} item={item} />
	},
	{
		id: 'amount',
		comp: ({ item }) => <Amount key={`bodyCell_amount_${item.id}`} item={item} />
	},
	{
		id: 'date',
		comp: ({ item }) => <Datee key={`bodyCell_date_${item.id}`} item={item} />
	},
	{
		id: 'client',
		comp: ({ item }) => <Client key={`bodyCell_client_${item.id}`} item={item} />
	},
	{
		id: 'companyName',
		comp: ({ item }) => <CompanyName key={`bodyCell_companyName_${item.id}`} item={item} />
	},
	{
		id: 'city',
		comp: ({ item }) => <City key={`bodyCell_city_${item.id}`} item={item} />
	}
];

export default allCells;
