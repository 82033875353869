import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Box, Button, Popover, Paper, Backdrop } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import serviceUtils from 'services/utils';
import FilterBarSearch from './FilterBarSearch';
import SelectDepots from './SelectDepots';
import SelectScope from './SelectScope';
import SelectDate from './SelectDate';
import FilterBarSapInfo from './FilterBarSapInfo';
import utils from 'components/utils';
import { borderColor } from '@mui/system';

const FilterBar = props => {
	const theme = useTheme();
	const translate = utils.useI18N();
	const tabSelected = useStoreState(state => state.cart.tabSelected);
	const waiting = useStoreState(state => state.cart.search.filters.scopes.waiting);
	const pending = useStoreState(state => state.cart.search.filters.scopes.pending);
	const evadible = useStoreState(state => state.cart.search.filters.scopes.evadible);
	const depots = useStoreState(state => state.cart.search.filters.depots);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const filterButtonUp = waiting || pending || evadible || depots.length > 0 ? true : false;

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Stack
			direction={{ xs: 'column', md: 'row', sm: 'column' }}
			justifyContent="flex-stat"
			alignItems="flex-start"
			spacing={1}
			variant="wrapperColor"
		>
			<FilterBarSearch />
			{/* Desktop */}
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{ display: { xs: 'none', md: 'inherit', sm: 'none' } }}
			>
				{tabSelected == 0 && <SelectScope />}
				<SelectDepots />
				{tabSelected == 2 && <SelectDate />}
			</Stack>
			{/* Mobile */}
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
				sx={{ display: { xs: 'inherit', md: 'none', sm: 'inherit' }, width: '100%' }}
			>
				<Box sx={{ width: '50%' }}>
					<Backdrop open={open} />
					<Button
						aria-label="Mobile filter"
						variant="light"
						endIcon={
							<FilterListIcon sx={{ color: filterButtonUp ? theme.palette.primary.main : 'inherit' }} />
						}
						onClick={handleClick}
						sx={{
							color: filterButtonUp ? theme.palette.primary.main : 'inherit',
							borderColor: filterButtonUp ? theme.palette.primary.main : 'inherit'
						}}
					>
						{translate('common.filter')}
					</Button>
					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left'
						}}
					>
						<Paper elevation={24}>
							<Stack
								direction="column"
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={2}
								variant="wrapperColor"
							>
								{tabSelected == 0 && <SelectScope />}
								<SelectDepots />
								{tabSelected == 2 && <SelectDate />}
								<Button variant="light" onClick={handleClose}>
									{translate('common.apply')}
								</Button>
							</Stack>
						</Paper>
					</Popover>
				</Box>
				<Box sx={{ textAlign: 'right', width: '50%' }}>
					<FilterBarSapInfo />
				</Box>
			</Stack>
		</Stack>
	);
};

export default FilterBar;
