import React, { memo } from 'react';
import { useStoreActions } from 'easy-peasy';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import utils from 'components/utils';

const SelectionComp = ({ ean, active, ordinable, type, depots }) => {
	const iCan = utils.usePermissions();
	const toggleSelection = useStoreActions(dispatch => dispatch.catalog.search.toggleSelection);
	const paymentTypeId = utils.useUserData('paymentTypeId', [], false);
	const translate = utils.useI18N();
	const useEpipoli = iCan('MANAGE_EPIPOLI'); //utils.useEpipoli();
	const canEpipoli = type !== 200 || (type === 200 && useEpipoli);
	const isOrderable = ordinable === 2 && !(paymentTypeId === 1 && type === 100) && canEpipoli;
	const isDepotsEnabled = depots.filter(d => d.enable).length > 0;

	return (
		<TableCell padding="checkbox">
			{isOrderable && isDepotsEnabled && (
				<Checkbox
					color="primary"
					onChange={e => toggleSelection(ean)}
					checked={active}
					inputProps={{ 'aria-labelledby': ean }}
					disabled={!isOrderable}
				/>
			)}
			{(!isOrderable || !isDepotsEnabled) && (
				<Tooltip title={translate('catalog.fastAddUnable')}>
					<IconButton aria-label="product not orderable" style={{ paddingLeft: 2 }}>
						<RemoveShoppingCartIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			)}
		</TableCell>
	);
};

const Selection = memo(
	({ item }) => (
		<SelectionComp
			ean={item.ean}
			active={item.isSelected}
			ordinable={item.ordinable}
			type={item.type}
			depots={item.depots}
		/>
	),
	(p, n) =>
		p.item.ean === n.item.ean &&
		p.item.isSelected === n.item.isSelected &&
		p.selected === n.selected &&
		p.item.type === n.item.type &&
		p.item.ordinable === n.item.ordinable &&
		p.item.depots === n.item.depots
);

export default Selection;
