import { action, actionOn, thunk, thunkOn } from 'easy-peasy';

export default {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	data: {},
	wishlistExpanded: -1,
	openModal: false,
	setOpenModal: action((state, payload) => {
		state.openModal = payload;
	}),
	load: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
		const { services } = injections;
		actions.setLoading(true);
		services.wishlist
			.getDetail(payload)
			.then(data => actions.save({ data, id: payload }))
			.catch(e => {
				getStoreActions().error('order.wishlist');
			})
			.finally(_ => {
				actions.setLoading(false);
			});
	}),
	save: action((state, payload) => {
		const { data, id } = payload;
		const getDepot = item => {
			const sel = item.catalog.depots.find(a => a.id === item.depotCode);
			return sel ? sel.description : item.depotCode;
		};
		//Filtro le WL che hanno catalog null per evitare errore in apertura WL
		/* Per ora da non rilasciare
		const wishlistItems = data.item.wishlistItems.filter(i => i.catalog != null);
		const wishlistItemRemoved = data.item.wishlistItems.filter(i => i.catalog == null);
		*/
		state.data[id] = data.item.wishlistItems.map(item => ({
			...item,
			promoDesc: data.item.promoDesc,
			selectedDepot: getDepot(item),
			isSelected: false
		}));
	}),
	reset: action((state, payload) => {
		state.data = {};
	}),
	orderBy: 'title',
	orderDirection: 'asc',
	setOrder: action((state, payload) => {
		if (state.orderBy === payload) {
			state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
		} else {
			state.orderDirection = 'asc';
		}
		state.orderBy = payload;
	}),
	clearCacheOnWishlistUpdate: thunkOn(
		(actions, storeActions) => [
			storeActions.wishlist.add.sendRequest.successType,
			storeActions.wishlist.remove.sendDelete.successType,
			storeActions.wishlist.removeItem.sendDelete.successType,
			storeActions.wishlist.list.load.successType,
			storeActions.wishlist.update.updateItem.successType
		],
		async (actions, target, helpers) => {
			actions.reset();
		}
	),
	setWishlistExpanded: action((state, payload) => {
		state.wishlistExpanded = payload;
	}),
	toggleSelection: action((state, payload) => {
		const i = state.data[state.wishlistExpanded].findIndex(itm => itm.id === payload);
		if (i === -1) return;
		const item = state.data[state.wishlistExpanded][i];
		item.isSelected = !item.isSelected;
		state.data[state.wishlistExpanded] = state.data[state.wishlistExpanded]
			.slice(0, i)
			.concat(item)
			.concat(state.data[state.wishlistExpanded].slice(i + 1));
	}),
	selectAll: action(state => {
		state.data[state.wishlistExpanded] = state.data[state.wishlistExpanded].map(item => ({
			...item,
			isSelected: true
		}));
	}),
	deselectAll: action(state => {
		state.data[state.wishlistExpanded] = state.data[state.wishlistExpanded].map(item => ({
			...item,
			isSelected: false
		}));
	}),
	updateOnRemoveItems: actionOn(
		(actions, storeActions) => [storeActions.wishlist.remove.deleteItems.successType],
		(state, target) => {
			state.data[state.wishlistExpanded] = state.data[state.wishlistExpanded].filter(i => !i.isSelected);
		}
	)
};
