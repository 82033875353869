import React, { Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withStyles } from '@mui/styles';
import { Button, Popover } from '@mui/material';
import Modal from '@mui/material/Modal';
import RECModal from './Modal';
import utils from 'components/utils';
import { Link, useHistory } from 'react-router-dom';

const styles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		minWidth: 350
	},
	modalLabels: {
		marginBottom: theme.spacing()
	},
	buttonsMargin: {
		margin: theme.spacing(0, 1, 0, 0)
	}
});

const RequestExtraConditions = ({ classes, item }) => {
	const modalOpen = useStoreState(state => state.wishlist.requestExtraConditions.modalOpen);
	const setModalOpen = useStoreActions(dispatch => dispatch.wishlist.requestExtraConditions.setModalOpen);
	const translate = utils.useI18N();
	const history = useHistory();

	const onClose = () => {
		setModalOpen(false);
		if (history.action === 'PUSH') history.goBack();
		else history.push(`/wishlist/list`);
	};
	return (
		<Fragment>
			<Button
				color="primary"
				variant="light"
				component={Link}
				to={`/wishlist/list/extra-conditions/${item.id}`}
				onClick={_ => setModalOpen(true)}
			>
				{translate('wishlist.requestCondition')}
			</Button>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={classes.modal}
				open={modalOpen}
				onClose={_ => {
					onClose();
				}}
				closeAfterTransition
			>
				<div className="modal-content">
					<RECModal item={item} onClose={_ => onClose()} />
				</div>
			</Modal>
		</Fragment>
	);
};

export default withStyles(styles, { withTheme: true })(RequestExtraConditions);
