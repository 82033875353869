import React from 'react';
import Id from './Id';
import SapName from './SapName';
import Status from './Status';
import Edit from './Edit';
import Selection from './Selection';
import RefCode from './RefCode';
import RefName from './RefName';

const allCells = [
	{
		id: 'selection',
		comp: ({ item }) => <Selection key={`bodyCell_selection_purchasings_${item.selection}`} item={item} />
	},
	{
		id: 'id',
		comp: ({ item }) => <Id key={`bodyCell_id_purchasings_${item.id}`} item={item} />
	},
	{
		id: 'name',
		comp: ({ item }) => <SapName key={`bodyCell_sapName_purchasings_${item.sapName}`} item={item} />
	},
	{
		id: 'ref',
		comp: ({ item }) => <RefCode key={`bodyCell_status_purchasings_${item.referentUserCode}`} item={item} />
	},
	{
		id: 'nameRef',
		comp: ({ item }) => <RefName key={`bodyCell_status_purchasings_${item.referentUserName}`} item={item} />
	},
	{
		id: 'status',
		comp: ({ item }) => <Status key={`bodyCell_status_purchasings_${item.enabled}`} item={item} />
	},
	{
		id: 'edit',
		comp: ({ item }) => <Edit key={`bodyCell_edit_purchasings_${item.id}`} item={item} />
	}
];

export default allCells;
