import React, { Fragment } from 'react';
import { useStoreState } from 'easy-peasy';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import FavoriteIconMUI from '@mui/icons-material/Favorite';

const WishlistIcon = ({ onClick, depotId, enabled }) => {
	const loading = useStoreState(state => state.wishlist.add.loading);
	const isLoading = loading === depotId;
	return (
		<Fragment>
			{!isLoading && (
				<IconButton
					disabled={!enabled}
					style={{ padding: 0 }}
					variant="contained"
					color="primary"
					onClick={onClick}
				>
					<FavoriteIconMUI fontSize="small" />
				</IconButton>
			)}
			{isLoading && <CircularProgress size={16} />}
		</Fragment>
	);
};
export default WishlistIcon;
