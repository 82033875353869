import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import Avatar from 'components/Avatar';
import utils from 'components/utils';
import Resumes from './Resumes';
import CAInfo from './CAInfo';
import Logout from './Logout';
import styles from './styles';

const UserBox = ({ classes }) => {
	const translate = utils.useI18N();
	const iCan = utils.usePermissions();
	const [iAmPersonifying] = utils.usePersonification();
	const history = useHistory();
	const user = useStoreState(state => state.me.data);
	const setPopperOpen = useStoreActions(dispatch => dispatch.me.setPopperOpen);
	const getUserType = _ => {
		if (!user.profile) return '';
		if (user.profile.BO) return 'Admin';
		if (user.profile.AGENT) return 'Agente';
		if (user.profile.CA_ADMIN) return 'Amministratore centrale acquisto';
		if (user.profile.CLIENT && user.profile.CLIENT === 'STANDARD') return 'Cliente';
		if (user.profile.CLIENT && user.profile.CLIENT === 'PA') return 'Cliente PA';
		if (user.profile.WAREHOUSE_MANAGER) return 'Gestore magazzino';
		return '';
	};
	const formattedDate = moment.unix(user.lastAccessDate).format('L');
	const formattedHour = moment.unix(user.lastAccessDate).format('HH:mm');
	const onProfileClick = _ => {
		history.push('/me');
	};
	return (
		<ClickAwayListener onClickAway={_ => setPopperOpen(false)}>
			<Card className={classes.card}>
				<CardContent className={classes.content}>
					<Grid container>
						<Grid container justify="center" item xs={12}>
							<Typography variant="subtitle1">{utils.capitalizeAll(user.displayName)}</Typography>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 8, marginBottom: 8 }}>
							<Avatar size={100} forceLogged={true} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1">
								<strong>Ultimo accesso:</strong> {formattedDate} alle ore {formattedHour}
							</Typography>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 16 }}>
							<Typography variant="caption">
								<strong>{translate('user.companyName')}:</strong>{' '}
								{utils.capitalizeAll(user.displayName)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="caption">
								<strong>{translate('personify.usercode')}:</strong> {user.id}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="caption">
								<strong>Tipo cliente: </strong> {getUserType()}
							</Typography>
						</Grid>
						<CAInfo />
						{iCan('PREVIEW_DATA') && !iAmPersonifying && (
							<Grid container item xs={12}>
								<Resumes />
							</Grid>
						)}
						<Grid item xs={12} style={{ marginTop: 16, marginBottom: 16 }}>
							<Divider light />
						</Grid>
						<Grid container item xs={6} style={{ textAlign: 'left' }}>
							<Button onClick={onProfileClick} variant="outlined" color="primary">
								profilo
							</Button>
						</Grid>
						<Grid container item xs={6} justify="flex-end">
							<Logout />
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</ClickAwayListener>
	);
};

export default withStyles(styles, { withTheme: true })(UserBox);
