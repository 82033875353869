import React, { memo } from 'react';
import StyledTableCell from 'components/Table/StyledTableCell';

const ClientComp = ({ sendUserCode }) => {
	return <StyledTableCell>{sendUserCode}</StyledTableCell>;
};

const Client = memo(
	({ item }) => <ClientComp sendUserCode={item.sendUserCode} />,
	(p, n) => p.item.sendUserCode === n.item.sendUserCode
);

export default Client;
