import importt from './import';
import getList from './list';
import getDetails from './get-details';
import search from './search';
import update from './update';
import addUserGroupAbilitation from './add-user-group-abilitation';
import removeUserGroupAbilitation from './remove-user-group-abilitation';
import getUserGroupsAbilitation from './get-user-groups-abilitation';
import getItems from './get-items';
import updateAssignment from './update-assignment';
import createEditorial from './create-editorial';
import deleteEditorials from './delete-editorials';
import getReport from './report';

export default {
	importt,
	getList,
	getDetails,
	search,
	update,
	addUserGroupAbilitation,
	removeUserGroupAbilitation,
	getUserGroupsAbilitation,
	getItems,
	updateAssignment,
	createEditorial,
	deleteEditorials,
	getReport
};
